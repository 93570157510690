import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import clsx from "clsx";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { List } from "@material-ui/core";

import HeaderLogo from "common/Header/HeaderTitle/HeaderLogo";
import HeaderUser from "common/Header/HeaderUser";
import MenuManager from "./MenuManager";
import {isTablet} from 'react-device-detect';

import { getTransition } from "helpers";
import {
  mainMenuForUser,
  mainMenuForAdmin,
  managementMenuForAdmin,
  managementMenuForSuperAdmin,
  dashboardMenuItem,
  // 以下商品スコア用メニューアイテム
  mainMenuForUserProduct,
  mainMenuForAdminProduct,
  managementMenuForAdminProduct,
  dashboardMenuItemProduct,
  managementMenuForSuperAdminProduct,
} from "./constants";

// 商品スコア開発、選択中のアカウントが商品スコアであるか否かのフラグ取得用
import { Storage } from 'utils';

const propTypes = {
  user: PropTypes.object,
  dataInvalidate: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: 0,
      width: drawerWidth => drawerWidth,
      ...getTransition(theme, "open"),
      position: 'relative',
      overflowY: 'hidden',
    },
    menuBottom: {
      [theme.breakpoints.down("md")]: {
        display: 'none'
      }
    },
    navInfo:{
      position: 'relative',
      width: drawerWidth => drawerWidth,
      zIndex: 1,
    },
    menuClose: {
      ...getTransition(theme, "close")
    },
    mainMenu: {
      height: 'calc(100vh - 108px)',
      overflowY: 'scroll',
    },
    mainMenuCollapsed: {
      justifyContent: "space-between"
    },
    menuTop: {
      marginTop: theme.spacing(3),
      marginBottom: 150
    },
    listItems: {
      borderLeft: '4px solid rgba(0,0,0,0)',
      padding: '16px 25px',
      '&:hover':{
        backgroundColor: theme.palette.grayscale[100],
      }
    },
    activeItem: {
      borderLeft: '4px solid',
      borderLeftColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.brown.Light,
      '& .MuiTypography-root':{
        fontWeight: 700,
      },
    },
    itemInner: {
      textAlign: 'center'
    },
    smallItem: {
      borderLeft: 0,
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.brown.Light,
      height: "96px",
      display: 'block',
      padding: '12px 12px',
      '& svg':{
        color: theme.palette.primary.main,
      }
    },
    ActiveSmallItem: {
      backgroundColor: theme.palette.brown.Light,
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    smallText: {
      marginBottom: 0,
      textAlign: "center",
      "& .MuiTypography-root": {
        fontSize: "10px",
        fontWeight: 600
      }
    },
    smallIcon: {
      color: 'inherit',
    }
  }
});

const MenuList = ({ user, use_dashboard, dataInvalidate }) => {
  const isSuperAdmin = user.authority === "super_admin";
  const isAdmin = user.authority === "admin";
  // 商品スコア実装の為、メニュー出し分けのフラグを追加(boolean)survey_type=="1" =>商品スコアアカウント
  const isProductsScoreCompany = Storage.getSelectedCompany().survey_type==="1"


  const topMenuItems = isProductsScoreCompany?
  // 商品スコア用メニュー 
    isAdmin || isSuperAdmin ? mainMenuForAdminProduct : mainMenuForUserProduct
    
  // 店舗スコア用メニュー
    : isAdmin || isSuperAdmin ? mainMenuForAdmin : mainMenuForUser
  const [menuItems, setMenuItems] = useState([])

  React.useEffect(() => {
    isProductsScoreCompany?
    // 商品スコア用ダッシュボード
    setMenuItems(use_dashboard ? [dashboardMenuItemProduct, ...topMenuItems] : topMenuItems)
    // 店舗スコア用ダッシュボード
    : setMenuItems(use_dashboard ? [dashboardMenuItem, ...topMenuItems] : topMenuItems)
  }, [use_dashboard, topMenuItems, isProductsScoreCompany])

  let { drawerWidth, isMobile } = useSelector(state => state.common);
  drawerWidth = isMobile ? 240 : drawerWidth
  const dispatch = useDispatch();
  const classes = useStyles(drawerWidth);

  const collapsed = drawerWidth !== 240;

  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;

  const goToPage = href => {
    dataInvalidate();
    history.push(`/admin${href}`);
    isMobile && dispatch({ type: "TOGGLE_DRAWER" });
  };

  const renderItem = item => {
    const { href, icon, short } = item;
    const active = pathname === `/admin${href}`;
    return (
      <ListItem
        key={href}
        button
        onClick={() => goToPage(href)}
        divider={false}
        disableGutters = {true}
        className={clsx(
          classes.listItems,
          active && classes.activeItem,
          collapsed && classes.smallItem,
          active && collapsed && classes.ActiveSmallItem
        )}>
        <>
          <div className={clsx(classes.itemInner)}>
            {collapsed && (
              <ListItemIcon>
                {icon}
              </ListItemIcon>
            )}
          </div>
          <ListItemText
            primary={short}
            className={clsx(collapsed && classes.smallText)}
          />
        </>

      </ListItem>
    );
  };

  return (
    <List className={clsx(classes.root, collapsed && classes.closeMenu)}>
      <div className={classes.navInfo}>
        <HeaderLogo drawerWidth={drawerWidth} />
        <HeaderUser hideInfo={collapsed} userName={user.name}/>
      </div>
      <div
        className={clsx(
          classes.mainMenu,
          collapsed && classes.mainMenuCollapsed
        )}
      >
        <div className={classes.menuTop}>
          {(menuItems).map(renderItem)}
        </div>

        {((isAdmin || isSuperAdmin) && !!!isTablet) &&
          <div className={classes.menuBottom}>
            <MenuManager
              collapsed={collapsed}
              location={location}
              goToPage={goToPage}
              items={
                isProductsScoreCompany?
                  // 商品スコア用メニュー
                  isSuperAdmin ? managementMenuForSuperAdminProduct : managementMenuForAdminProduct
                :
                // 店舗スコア用メニュー
                isSuperAdmin ? managementMenuForSuperAdmin : managementMenuForAdmin
              }
              isMobile={isMobile}
            />
          </div>
        }
      </div>
    </List>
  );
};

MenuList.propTypes = propTypes;

export default connect(
  ({ global: { userData, selectedCompany: { use_dashboard } } }) => ({
    use_dashboard: use_dashboard === 'true',
    user: userData
  }),
  dispatch => ({
    dataInvalidate: () => {
      dispatch({ type: 'FETCH_FEEDBACKS_RESET' });
      dispatch({ type: 'FETCH_ANALYSIS_RESET' });
      dispatch({ type: 'FETCH_AGGREGATION_RESET' });
      dispatch({ type: 'FETCH_PROJECTS_RESET' });
      dispatch({ type: 'FETCH_SURVEYS_RESET' });
      dispatch({ type: 'FETCH_SHOPS_RESET' });
      dispatch({ type: 'FETCH_AREAS_RESET' });
      dispatch({ type: 'FETCH_GROUPS_RESET' });
      dispatch({ type: 'FETCH_USERS_RESET' });
      dispatch({ type: 'FETCH_COMPANIES_RESET' });
    },
  })
)(MenuList);
