export const themedStyles = theme => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: "0 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px"
    }
  },
  trButton: {
    padding: "20px 0px"
  },
  trNumber: {
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    borderRadius: "5px 0px 0px 5px",
    opacity: 1,
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    }    
  },
  trNumberActived: {
    background: "#EC681A 0% 0% no-repeat padding-box",
    borderRadius: "5px 0px 0px 5px",
    opacity: 1,
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    }  
  },
  thTable: {
    backgroundColor: "#F2EEEC",
    height: "36px",
    lineHeight: "36px",
    color: "#1A0707",
    fontSize: "12px",
    fontWeight: "bold",
    boxShadow: "0px 1px 3px #00000029"
  },
  paddingCollum: {
    padding: "25px 20px",
    display: "flex",
    alignItems: "center",
    borderRight: "1px solid #EFEFEF",
    wordBreak: "break-all",
    [theme.breakpoints.down("sm")]: {
      padding: "5px"
    }
  },
  Thborder: {
    borderRight: "1px solid #B7B7B7",
    font: "12px/14px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    padding: "12px 20px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      borderRight: "1px solid #B7B7B7",
      font: "6px/12px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      padding: "12px 5px",
      fontWeight: "600"
    }
  },
  ThButton: {
    font: "10px/14px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    padding: "12px 14px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      font: "6px/14px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      padding: "12px 5px",
      fontWeight: "600"
    }
  },
  tdTable: {
    textAlign: "left",
    fontSize: "12px",
    color: "#1A0707",
    marginTop: "10px",
    backgroundColor: "white",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px"
    }
  },
  Tdborder: {
    alignItems: "center",
    font: "12px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    opacity: "1",
    textAlign: "left",
    "& i": {
      fontSize: "40px",
      color: "#fff"
    },
    [theme.breakpoints.down("sm")]: {
      font: "9px/16px Noto Sans JP, Hiragino Sans"
    },
    [theme.breakpoints.down("xs")]: {
      "& i": {
        fontSize: "25px",
        color: "#fff"
      }
    }
  },
  noneBorder: {
    border: "none"
  }
});
