import React from "react";
import { IconFeed, IconNps, IconTotal } from '../../../assets/compicon/iconForMenu.js';
const size = 32;

export const dashboardMenuItem = {
  href: '/dashboard',
  text: 'ダッシュボード',
  short: 'ダッシュボード',
  icon: <IconTotal style={{ fozntSize: size }} />,
}

export const mainMenuForUser = [
  {
    href: "/feedbacks",
    text: "フィードバック一覧",
    short: "フィードバック一覧",
    icon: <IconFeed style={{ fontSize: size }} />
  },
  {
    href: "/analysis",
    text: "NPS分析", 
    short: "NPS分析",
    icon: <IconNps style={{ fontSize: size }} />
  },
  {
    href: "/aggregation",
    text: "アンケート集計",
    short: "アンケート集計",
    icon: <IconTotal style={{ fozntSize: size }} />
  }
];

export const mainMenuForAdmin = [
  ...mainMenuForUser,
  
];

export const managementMenuForAdmin = [
  {
    href: "/projects",
    text: "プロジェクト一覧",
    short: "プロジェクト管理",
  },
  {
    href: "/surveys",
    text: "アンケート一覧",
    short: "アンケート管理",
  },
  {
    href: "/shops",
    text: "店舗管理",
    short: "店舗管理"
  },
  {
    href: "/areas",
    text: "エリア管理",
    short: "エリア管理"
  },
  {
    href: "/groups",
    text: "グループ管理",
    short: "グループ管理"
  },
  {
    href: "/users",
    text: "スタッフ管理",
    short: "スタッフ管理"
  }
];

export const managementMenuForSuperAdmin = [
  ...managementMenuForAdmin,
  {
    href: "/companies",
    text: "アカウント管理",
    short: "アカウント管理"
  }
];
// 商品スコア用メニューアイテムを定義
export const dashboardMenuItemProduct = {
  href: '/products/dashboard',
  text: 'ダッシュボード',
  short: 'ダッシュボード',
  icon: <IconTotal style={{ fozntSize: size }} />,
}

export const mainMenuForUserProduct = [
  {
    href: "/products/feedbacks",
    text: "フィードバック一覧",
    short: "フィードバック一覧",
    icon: <IconFeed style={{ fontSize: size }} />
  },
  {
    href: "/products/analysis",
    text: "PXスコア分析", 
    short: "PXスコア分析",
    icon: <IconNps style={{ fontSize: size }} />
  },
  {
    href: "/products/aggregation",
    text: "アンケート集計",
    short: "アンケート集計",
    icon: <IconTotal style={{ fozntSize: size }} />
  }
];

export const mainMenuForAdminProduct = [
  ...mainMenuForUserProduct,
  
];

export const managementMenuForAdminProduct = [
  {
    href: "/products/projects",
    text: "プロジェクト一覧",
    short: "プロジェクト管理",
  },
  {
    href: "/products/surveys",
    text: "アンケート一覧",
    short: "アンケート管理",
  },
  {
    href: "/products/products",
    text: "商品一覧",
    short: "商品管理"
  },
  {
    href: "/products/categories",
    text: "カテゴリ管理",
    short: "カテゴリ管理"
  },
  {
    href: "/products/groups",
    text: "グループ管理",
    short: "グループ管理"
  },
  {
    href: "/products/users",
    text: "スタッフ管理",
    short: "スタッフ管理"
  }
];
export const managementMenuForSuperAdminProduct = [
  ...managementMenuForAdminProduct,
  {
    href: "/companies",
    text: "アカウント管理",
    short: "アカウント管理"
  }
];
