import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  Add,
} from '@material-ui/icons';
import QuestionChild from './QuestionChild'
import { QUESTION_TYPE_RADIO_BUTTON, QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_LIMIT_MAP } from './constants'
import { QUESTION_TYPE_SELECT_BUTTON, QUESTION_TYPE_NPS_QUESTION, QUESTION_TYPE_SELECT } from './constants';
import SwitchBtn from "../../../../components/SwitchBtn";

const useStyles = makeStyles(theme => ({
  formSection: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
    display: 'inline-block',
    '& label.anKetRadioButton:nth-child(1)': {
      marginLeft: 0,
    },
    '& label.anKetRadioButton:nth-child(2)': {
      marginLeft: 10,
    },
  },
  formLabel: {
    color: '#1A0707',
    fontWeight: 'bold',
    fontSize: 12,
    margin: "9px 35px",
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textField1: {
    height: 36,
    width: "100%",
    padding: "12px 20px",
    border: "1px solid #B7B7B7",
  },
  addButton: {
    width: "100%",
    height: "36",
    background: "#F2EEEC",
    marginTop: 10,
    marginBottom: 5,
    borderRadius: 5,
    color: "#EC681A",
  },
  itemDivider: {
    marginTop: 20,
    height: 1,
    background: "#B7B7B7",
  },
  deleteButton: {
    width: 36,
    height: 36,
    color: "#F02544",
    border: "1px solid #F02544",
    borderRadius: "5px",
    position: "absolute",
    right: 0,
    top: -1,
  },
  deleteIcon: {
    fontSize: "13px",
    fill: "#F02544",
  },
  visibleActiveIcon: {
    color: '#EC681A'
  },
  borderRadius5: {
    borderRadius: 5
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formRowLabel: {
    color: '#1A0707',
    fontWeight: 'bold',
    fontSize: 12,
    minWidth: 30,
  },
  formAction: {
    padding: '10px 0'
  },
  uploadZone: {
    width: 122,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addChild: {
    width: 'calc(100% - 36px)',
    [theme.breakpoints.down("xl")]: {
      width:"95%"
    },
    [theme.breakpoints.down("sm")]: {
      width:"100%"
    }
  },
  ml_20: {
    marginLeft: 20,
  },
  mt_10: {
    marginTop: 20,
  },
  textFieldSmall: {
    width: 98,
    marginLeft: 10,
  },
  switchBoxContainer: {
    justifyContent: "space-between",
    height: 41,
    width:"95%",
    background: "#f2eeec80",
    marginTop: "10px",
    borderRadius: "5px",
    "& >label": {
      minWidth: "100px",
      marginLeft: 0,
      [theme.breakpoints.down("sm")]: {
        width:"35%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width:"100%",
      display:"flex",
    }
  }
}));

const propTypes = {
  handleAddOption: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  setIsExclusive: PropTypes.func.isRequired,
  setIsFreeAnswer: PropTypes.func.isRequired,
  setIsAggregate: PropTypes.func.isRequired,
  setDiscardedAt: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  errors: PropTypes.array,
  option: PropTypes.string,
};

const Options = ({
  option,
  options,
  handleAddOption,
  setName,
  setValue,
  setIsExclusive,
  setIsFreeAnswer,
  setIsAggregate,
  setDiscardedAt,
  setOptionImage,
  errors,
}) => {
  const classes = useStyles()

  const renderListChild = () => {
    return (
      <>
        <div>
          <label className={classes.formLabel}>顧客接点の評価尺度</label>
          {options.map(({ id, name, value, is_exclusive, is_free_answer, is_aggregate, option_image, option_image_url, discarded_at, _destroy }, index) => (
            _destroy !== 1 && 
              <div key={`option-${index}`}>
                <QuestionChild
                  errors={errors[index] || {}}
                  discarded_at={discarded_at}
                  index={index}
                  name={name}
                  setName={setName}
                  value={value}
                  setValue={setValue}
                  setDiscardedAt={setDiscardedAt}
                  question_type={option}
                  image={option_image || (!!option_image_url ? { data: option_image_url, filename: '', content_type: '' } : { data: '', filename: '', content_type: '' }) }
                  setImage={setOptionImage}
                />
                {
                  option !== QUESTION_TYPE_NPS_QUESTION &&
                  <div className={classes.switchBoxContainer}>
                    {
                      option === QUESTION_TYPE_CHECK_BOX &&
                      <SwitchBtn label="排他選択肢" checked={!!is_exclusive} onChange={() => setIsExclusive(index, !!!is_exclusive)}/>
                    }
                    <SwitchBtn label="FA追加" checked={!!is_free_answer} onChange={() => setIsFreeAnswer(index, !!!is_free_answer)}/>
                    <SwitchBtn label="クロス集計" checked={!!is_aggregate} onChange={()=> setIsAggregate(index, !!!is_aggregate)}/>
                  </div>
                }
              </div>
            )
          )}
        </div>
        {(option !== QUESTION_TYPE_NPS_QUESTION) && !(option === QUESTION_TYPE_SELECT_BUTTON && (options.filter(i => i._destroy !== 1).length >= (QUESTION_TYPE_LIMIT_MAP[QUESTION_TYPE_SELECT_BUTTON] || 5000))) && (
          <div className={classes.addChild}>
            <IconButton onClick={e => { handleAddOption(e) }} component="span" className={classes.addButton}>
              <Add />
            </IconButton>
          </div>
        )}
      </>
    )
  }
  
  return (
    <div>
      {[
        QUESTION_TYPE_SELECT,
        QUESTION_TYPE_NPS_QUESTION,
        QUESTION_TYPE_RADIO_BUTTON,
        QUESTION_TYPE_CHECK_BOX,
        QUESTION_TYPE_SELECT_BUTTON,
      ].includes(option) && renderListChild()}
    </div>
  )
}

Options.propTypes = propTypes;

export default Options;
