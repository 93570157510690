import React from 'react';
import { Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import {
  dashboardMenuItem,
  mainMenuForAdmin,
  managementMenuForSuperAdmin,
  managementMenuForAdminProduct,
  managementMenuForSuperAdminProduct,
} from 'common/Menu/MenuList/constants';

const headerTexts = [
  {
    href: "/surveys/",
    text: "アンケート作成",
  },
  ...mainMenuForAdmin,
  ...managementMenuForSuperAdmin,
  ...managementMenuForAdminProduct,
  ...managementMenuForSuperAdminProduct,
  dashboardMenuItem,
]

const getText = (location) => {
  const pathname = location.pathname
  const header = headerTexts.find(({ href }) => pathname.match(href)) || {}
  return header.text
}

const HeaderTitle = () =>{
  const location = useLocation();
  const headerText = getText(location);
  return (
    <Typography variant="h6">
      {headerText}
    </Typography>
  )
}

export default HeaderTitle
