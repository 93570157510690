export const FETCH_PUBLIC_SURVEY_BEGIN   = 'FETCH_PUBLIC_SURVEY_BEGIN';
export const FETCH_PUBLIC_SURVEY_SUCCESS = 'FETCH_PUBLIC_SURVEY_SUCCESS';
export const FETCH_PUBLIC_SURVEY_FAILURE = 'FETCH_PUBLIC_SURVEY_FAILURE';

export const FETCH_PUBLIC_AREAS_BEGIN   = 'FETCH_PUBLIC_AREAS_BEGIN';
export const FETCH_PUBLIC_AREAS_SUCCESS = 'FETCH_PUBLIC_AREAS_SUCCESS';
export const FETCH_PUBLIC_AREAS_FAILURE = 'FETCH_PUBLIC_AREAS_FAILURE';

export const FETCH_PUBLIC_SHOPS_BEGIN   = 'FETCH_PUBLIC_SHOPS_BEGIN';
export const FETCH_PUBLIC_SHOPS_SUCCESS = 'FETCH_PUBLIC_SHOPS_SUCCESS';
export const FETCH_PUBLIC_SHOPS_FAILURE = 'FETCH_PUBLIC_SHOPS_FAILURE';

export const FETCH_PUBLIC_USERS_BEGIN = 'FETCH_PUBLIC_USERS_BEGIN';
export const FETCH_PUBLIC_USERS_SUCCESS = 'FETCH_PUBLIC_USERS_SUCCESS';
export const FETCH_PUBLIC_USERS_FAILURE = 'FETCH_PUBLIC_USERS_FAILURE';

export const PUBLIC_SURVEY_NEXT_PAGE = 'PUBLIC_SURVEY_NEXT_PAGE';
export const PUBLIC_SURVEY_PREV_PAGE = 'PUBLIC_SURVEY_PREV_PAGE';

export const PUBLIC_GO_TO_PAGE = 'PUBLIC_GO_TO_PAGE';

export const PUBLIC_SAVE_FEEDBACK_SUCCESS = 'PUBLIC_SAVE_FEEDBACK_SUCCESS';
export const PUBLIC_SET_PARAMS = 'PUBLIC_SET_PARAMS';
