export const dropdownSelectStyles = {
  control: {
    width: '464px !important',
    height: '36px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #B7B7B7',
    borderRadius: '5px',
    font: '500 14px/21px Noto Sans JP, Hiragino Sans',
    letterSpacing: '0.25px',
    color: '#cdcdcd',
    display: 'flex !important',
    justifyContent: 'space-between',
    padding: '7px 0 7px 16px',

    '&:after': {
      fontFamily: 'element-icons!important',
      content: '"\\e790"',
      borderLeft: '1px solid #cdcdcd',
      padding: '0 8px',
      fontSize: '16px',
    }
  },
  controlActive: {
    '&:after': {
      borderLeft: '1px solid #1A0707',
      color: '#1A0707',
      content: '"\\e78f"',
    }
  },
  menuList: {
    maxHeight: '380px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    overflowY: 'scroll',
    paddingRight: '10px',

    '&::-webkit-scrollbar': {
      width: "2px",
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      borderRadius: "1px",
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: "1px",
      background: "#B7B7B7 0% 0% no-repeat padding-box",
    },

    '& ul': {
      listStyle: 'none',
      paddingLeft: '15px',
    },

    '& li': {
      cursor: 'pointer',
      minHeight: '38px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderBottom: '0.5px solid #B7B7B7',
      width: '100%',
      position: 'relative',

      '& span.checkbox': {
        width: '60px',
        height: '24px',
        border: '1px solid #393939',
        borderRadius: '5px',
        textAlign: 'center',
        lineHeight: '24px',
        position: 'absolute',
        right: '0',
        top: '50%',
        transform: 'translateY(-50%)',
      },

      '& div.selectArea': {
        position: 'relative',
        width: 'calc(100% - 40px)',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 100px 8px 0',

        '&:hover': {
          background: '#deebff',
        },

        '&:active': {
          background: '#b2d4ff',
        },
      },
    },

    '& li[expanded="true"] > span.toggler': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',

      '&:after': {
        fontFamily: 'element-icons!important',
        content: '"\\e6df"',
        fontSize: '16px',
        color: '#707070',
        fontWeight: 'bold',
      }
    },

    '& li[expanded="false"] > span.toggler': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',

      '&:after': {
        fontFamily: 'element-icons!important',
        content: '"\\e6e0"',
        fontSize: '16px',
        color: '#707070',
        fontWeight: 'bold',
      }
    },

    '& li[expanded="false"] + ul': {
      display: 'none',
    },

    '& li.checked': {
      borderBottom: '0.5px solid #EC681A',
      color: '#EC681A',

      '& span.checkbox': {
        border: '1px solid #EC681A',
        background: '#EC681A 0% 0% no-repeat padding-box',
        color: 'white',

        '&:before': {
          fontFamily: 'element-icons!important',
          content: '"\\e6da"',
          fontSize: '16px',
          position: 'absolute',
          left: '-30px',
          color: '#EC681A',
          fontWeight: 'bold',
        }
      }
    }
  },
  hidden: {
    display: 'none',
  },
  paper: {
    paddingRight: '10px',
    width: '464px',
    boxShadow: '0px 3px 5px #B7B7B799',
    borderTop: '2px solid #9A877D'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: '#1a0707',
    fontWeight: '600',
    margin: '10px 0',
  }
}
