import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'components/Pagination';
import {
  Paper,
  CircularProgress,
  Grid,
  Box,
  makeStyles,
} from '@material-ui/core';
import { Done as DoneIcon, Clear as ClearIcon } from '@material-ui/icons';
import { PAGINATION_PER_PAGE } from '../constants';
import { themedStyles } from "./styles";
import clsx from "clsx";
import { useGAEffect } from '../../../ga';


const useStyles = makeStyles(themedStyles);

const propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  ga: PropTypes.object.isRequired,
};

const CompanyList = ({
  items,
  loading,
  openModal,
  pagination,
  page,
  setPage,
  ga
}) => {
  const classes = useStyles();

  useGAEffect(ga, null, "アカウント管理")


// pagenationボタンのクリック
  const handleClick = (v) => {
    setPage(v)
  }
// ローディング描写、props(初期値はstate(companies.loading)のboolean)で判定
  if (loading) {
    return (
      <Paper className={classes.root}>
        <div className={classes.loading}>
          <CircularProgress disableShrink />
        </div>
      </Paper>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <Grid>
          <Box display={{ sm: "block", xs: "block", md: "block", lg: "block" }}>
            <Grid container item className={classes.thTable} sm={12} md={12}>
              <Grid item sm={1} md={1} xs={1} className={classes.clNumber}>
                <Box className={classes.Thborder}>公開</Box>
              </Grid>
              <Grid sm={2} md={2} xs={2} item className={classes.clShop}>
                <Box className={classes.Thborder}>アカウントID</Box>
              </Grid>
              <Grid sm={5} md={5} xs={5} item className={classes.clBody}>
                <Box className={classes.Thborder}>アカウント名</Box>
              </Grid>
              <Grid sm={2} md={2} xs={2} item className={classes.clBody}>
                <Box className={classes.Thborder}>種別</Box>
              </Grid>
              <Grid sm={2} md={2} xs={2} item className={classes.clDate}>
                <Box className={clsx(classes.Thborder, classes.ThborderLast)}>アンケート数</Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {(items || []).map(company => (
            <Grid
              container
              item
              className={classes.tdTable}
              sm={12}
              display={{ sm: "none", xs: "none", md: "block", lg: "block" }}
              key={company.id}
              onClick={() => openModal(company)}
            >
              <Grid sm={1} xs={1} md={1} item className={company.discarded_at ? classes.trNumber : classes.trCheckBox}>
                <Box className={`${classes.Tdborder} ${classes.poiterTablet}`}>
                  {company.discarded_at ? <ClearIcon /> : <DoneIcon />}
                </Box>
              </Grid>
              <Grid sm={2} xs={2} md={2} item className={classes.trShop}>
                <Box className={classes.Tdborder}>{company.uid}</Box>
              </Grid>
              <Grid sm={5} xs={5} md={5} className={classes.trShop} item>
                <Box className={classes.Tdborder}>{company.name}</Box>
              </Grid>
              <Grid sm={2} xs={2} md={2} className={classes.trShop} item>
                <Box className={classes.Tdborder}>{company.survey_type === "1" ? "商品アンケート" : "店舗アンケート"}</Box>
              </Grid>
              <Grid sm={2} xs={2} md={2} className={classes.trShopLast} item>
                <Box className={classes.Tdborder}>{company.survey_count}</Box>
              </Grid>
            </Grid>
          ))}
      </div>
      <Pagination
        activePage={page}
        itemsCountPerPage={+pagination.perPage}
        pageRangeDisplayed={+pagination.offset}
        totalItemsCount={+pagination.total}
        offset={+pagination.offset}
        onChange={handleClick}
      /> 
    </div>
  )
};

CompanyList.propTypes = propTypes;

export default connect(
  ({ companies: { pagination, items, loading }, ga }) => ({
    items,
    loading,
    pagination: !!pagination
      ? pagination
      : {
          offset: 0,
          total: PAGINATION_PER_PAGE,
          perPage: PAGINATION_PER_PAGE
        },
    ga
  }),
)(CompanyList);

