import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  TextField,
  Button,
  makeStyles,
  Typography
} from '@material-ui/core';
import { QuestionTypeSelectButtonStyles } from './styles';
import CustomTooltip from '../CustomTooltip';
import HtmlRender from './HtmlRender';

const useStyles = makeStyles(QuestionTypeSelectButtonStyles);

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onInputValue: PropTypes.func.isRequired,
};

const QuestionTypeSelect = ({
  questionItem,
  answer,
  errors,
  onInputValue,
}) => {
  const classes = useStyles();
  const value = (answer.answer_options_attributes || []).map(({ question_option_id }) => question_option_id)[0] || "";
  const freeAnswerOptions = questionItem.question_options_attributes.filter(({ is_free_answer }) => is_free_answer === true);
  
  const [selectedValue, setSelectedValue] = useState(value);
  const [freeAnswerIsSelected, setFreeAnswerIsSelected] = useState(freeAnswerOptions.map((item) => +item.id).includes(!!selectedValue ? +selectedValue : null));
  const [faValue, setFaValue] = useState(answer.answer_entry || "");
  
  const selectHasError = !!errors.answer_options_attributes;
  const textHasError = !!errors.answer_entry;

  const selectChangeHandler = (value) => {
    const faIsSelected = freeAnswerOptions.map(({ id }) => id).includes(value);
    let answer_entry = '';

    if (faIsSelected) {
      answer_entry = faValue;
    } else {
      setFaValue(answer_entry);
    }

    setSelectedValue(value);
    onInputValue(questionItem, { selectValue: value, answer_entry });
    setFreeAnswerIsSelected(faIsSelected);
  };

  const textChangeHandler = ({ target: { value } }) => {
    setFaValue(value);
    onInputValue(questionItem, { selectValue: selectedValue, answer_entry: value });
  };

  const choices = (questionItem.question_options_attributes || []);

  return (
    <>
      <Typography className={classes.textAnswer}>
        <HtmlRender
          value = {questionItem.body}
        />
        {questionItem.is_required && (
          <span className="required">必須</span>
        )}
      </Typography>
      {!!questionItem.question_image_url && (
        <div className={classes.img}>
          <img src={questionItem.question_image_url} alt={''}/>
        </div>
      )}
      <div className={classes.tabWithNumber}>
        <div className={classes.flexContainer}>
          {
            choices.map((option, index) => {
              const dataLenght = questionItem.question_options_attributes.length;
              const styles = {
                width: `calc(100%/${dataLenght})`,
                minWidth: `calc(100%/${dataLenght})`,
                fontSize: questionItem.use_number ? 13 : 25
              }
              const { id, name, value } = option

              return (
                <CustomTooltip
                  open={selectedValue ? selectedValue === id : [0, choices.length - 1].includes(index)}
                  title={name}
                  style={styles}
                  key={id}
                >
                  <Button className={selectedValue !== id ? classes.btnIcon : classes.btnIconColor}
                    onClick={() => { selectChangeHandler(id) }}
                  >
                    {questionItem.use_number === true ? value : '○'}
                  </Button>
                </CustomTooltip>
              )
            })
          }
        </div>
      </div>
        {selectHasError && (
          <FormHelperText error={true}>{errors.answer_options_attributes.map(err => err)}</FormHelperText>
        )}
      {freeAnswerIsSelected && (
        <FormControl component="fieldset" fullWidth className={classes.form_control} error={textHasError}>
          <TextField
            variant="standard"
            value={faValue}
            onInput={textChangeHandler}
            placeholder="入力してください"
          />
          <div>{textHasError && (
            <FormHelperText error={true}>{errors.answer_entry.map(err => err)}</FormHelperText>
          )}</div>
        </FormControl>
      )}
    </>
  );
}

QuestionTypeSelect.propTypes = propTypes;

export default QuestionTypeSelect;
