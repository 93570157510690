import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Checkbox,
  Grid
} from "@material-ui/core";
import DialogModal from "components/DialogModal";
import { conditionsSetupModalStyles, conditionQuestionOptionsStyles } from "./styles";
import { QUESTION_TYPE_NPS_QUESTION, QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_SELECT, QUESTION_TYPE_RADIO_BUTTON, QUESTION_TYPE_SELECT_BUTTON } from "./questions/constants";
import { CREATE_OR_UPDATE_SURVEY_DISPLAY_CONDITIONS, DELETE_SURVEY_DISPLAY_CONDITIONS } from "../redux/actions";
import { deepCopy } from "utils";
import clsx from "clsx";
import ConditionShops from './ConditionShops'

const useStyles = makeStyles(conditionsSetupModalStyles);

const propTypes = {
  surveyPage: PropTypes.object,
  spIndex: PropTypes.number,
  closeConditions: PropTypes.func.isRequired,
  survey_pages_attributes: PropTypes.array.isRequired,
};

const ConditionsSetupModal = ({
  surveyPage,
  spIndex,
  closeConditions,
  survey_pages_attributes,
  areaShops,
}) => {
  const questionTypesWithOptions = [
    QUESTION_TYPE_NPS_QUESTION,
    QUESTION_TYPE_CHECK_BOX,
    QUESTION_TYPE_SELECT,
    QUESTION_TYPE_RADIO_BUTTON,
    QUESTION_TYPE_SELECT_BUTTON,
  ];
  const classes = useStyles();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const handleClose = () => {
    closeConditions();
  };

  const deleteConditions = () => {
    dispatch({
      type: DELETE_SURVEY_DISPLAY_CONDITIONS,
      payload: {
        spIndex,
      }
    });
    surveyPage.survey_page_display_conditions_attributes = surveyPage.survey_page_display_conditions_attributes.map(item => ({
      ...item,
      _destroy: 1,
    }));
    setRefresh(true);
  }

  const surveyPages = deepCopy(survey_pages_attributes).filter(page => !!!page.discarded_at&& page._destroy !== 1).splice(0, spIndex);

  if (!!!surveyPage) return null;

  return (
    <DialogModal open={!!surveyPages} onClose={handleClose}>
      <div className={classes.conditionsSetupModal}>
        <div className="underlinedTop" />
        <div className="conditionsSetupModalContent">
          <div className="header">
            <p>ページ{surveyPage.page_number}に表示条件を設定</p>
            <div>
              <button className="buttonSetup" onClick={deleteConditions}>条件を全部クリア</button>
              <button onClick={handleClose} className="buttonDel">
                閉じる
                <i className="el-icon-close"></i>
              </button>
            </div>
          </div>
          <div className="content">
            <p>
              このページが表示される条件として使用される選択肢をチェックしてください。
            </p>
            <ConditionShops areaShops={areaShops}
                            page={surveyPage} refresh={refresh}
                            setRefresh={setRefresh} spIndex={spIndex}/>
            {surveyPages
              .map((page, _spIndex) => (page.question_items || [])
                .filter(item => !!!item.discarded_at && item._destroy !== 1 && questionTypesWithOptions.includes(item.question_type))
                .map((question, piIndex) =>

                  <ConditionQuestionOptions
                    key={`condition-question-options-${_spIndex}-${piIndex}`}
                    question={question}
                    spIndex={spIndex}
                    surveyPage={surveyPage}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
              )
            )}
          </div>
        </div>
      </div>
    </DialogModal>
  );
};

ConditionsSetupModal.propTypes = propTypes;

export default connect(
  ({ surveys: { item: { survey_pages_attributes } } }) => ({
    survey_pages_attributes,
  }),
)(ConditionsSetupModal);

const useConditionQuestionOptionsStyles = makeStyles(conditionQuestionOptionsStyles);

const ConditionQuestionOptions = ({ question, surveyPage, spIndex, refresh, setRefresh }) => {
  const classes = useConditionQuestionOptionsStyles();

  return (
    <ExpansionPanel className="expansionPanel">
      <ExpansionPanelSummary
        expandIcon={<i className="el-icon-arrow-right"></i>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <p className={classes.heading}>
          {question.body}
        </p>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="expansionDetail">
        {question.question_options_attributes
          .filter(question_option_attribute => !!!question_option_attribute.discarded_at && question_option_attribute._destroy !== 1)
          .map((question_option_attribute, index) =>

          <ConditionOptionCheckbox
            key={`condition-option-checkbox-${index}`}
            question={question}
            surveyPage={surveyPage}
            spIndex={spIndex}
            question_option_attribute={question_option_attribute}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
};

const ConditionOptionCheckbox = ({ question, surveyPage, question_option_attribute, spIndex, refresh, setRefresh }) => {
  const classes = useConditionQuestionOptionsStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (refresh === true) {
      setChecked(false);
    }
    setChecked(!!surveyPage.survey_page_display_conditions_attributes.find(item => [null, undefined].includes(item._destroy) && +item.question_id === +question.id && +item.question_option_id === +question_option_attribute.id));
    // eslint-disable-next-line
  }, [refresh, surveyPage.survey_page_display_conditions_attributes])

  const dispatch = useDispatch();

  const updateSurveyCondition = ({ question_option_id, question_id, checked }) => {
    setChecked(checked);
    setRefresh(false);
    dispatch({
      type: CREATE_OR_UPDATE_SURVEY_DISPLAY_CONDITIONS,
      payload: {
        spIndex,
        survey_page_id: +surveyPage.id,
        question_id,
        question_option_id,
        checked,
      }
    })
  }

  return (
    <Grid item sm={12} className={clsx(classes.blockCheckbox, checked && classes.blockCheckboxChecked)}>
      <Checkbox
        value={question_option_attribute.id}
        icon={<i></i>}
        checkedIcon={<i className="el-icon-check"></i>}
        inputProps={{
          "aria-label": "primary checkbox"
        }}
        checked={checked}
        onChange={(e) => {
          updateSurveyCondition({ question_option_id: +e.target.value, question_id: +question.id, checked: e.target.checked })
        }}
      />
      <p>{question_option_attribute.name}</p>
    </Grid>
  );
}
