import {
  FETCH_AREAS_BEGIN,
  FETCH_AREAS_SUCCESS,
  FETCH_AREAS_FAILURE,
  CREATE_AREA_SUCCESS,
  UPDATE_AREA_SUCCESS,
  DELETE_AREA_SUCCESS,
  OPEN_AREA_FORM_MODAL,
  CLOSE_AREA_FORM_MODAL,
  FETCH_AREAS_RESET,
  CHANGE_AREA_ORDER,
  UPDATE_AREA_BEGIN,
  UPDATE_AREA_FAILURE,
} from './actions';
import { API_MODEL_TYPE_SHOP } from '../../shops/constants';
import { parseArea, traverseParse } from '../utils';


const initialState = {
  item: {},
  items: [],
  allItemsLoading: false,
  allItemsLoaded: false,
  error: null,
  isSubmitSuccess: false,
  doc: {
    parentName: '',
    parentId: null,
    name: '',
    shops: [],
  }
};

const parseCreatedBranch = ({ data, included, parentId, parentName}) => {
 return parseArea({
    id: +data.id,
    name: data.attributes.name,
    display_order: data.attributes.display_order,
    shops: { data: included.filter(el => el.type === API_MODEL_TYPE_SHOP) },
    children: [],
    parentId: parentId,
    parentName: parentName
  });
}

const traverseAdd = (areas, newBranch, parentBranchId, found=false) => {
  if (found) {
    return [areas, found];
  }

  for (const area of areas) {
    if (area.id === parentBranchId) {
      found = true;
      area.children.push(newBranch)
    } 

    [area.children, found] = traverseAdd(area.children, newBranch, parentBranchId, found);
  }

  return [areas, found];
}

const traverseUpdate = (areas, updatedData, parentBranchId) => {
  let found = false;

  areas = areas.map(area => {
    if (area.id === parentBranchId) {
      found = true;

      return {
        ...area,
        ...updatedData,
        children: area.children
      };
    }

    if (!found) {
      area.children = traverseUpdate(area.children, updatedData, parentBranchId);
    }
  
    return area;
  });

  return areas;
}

const traverseDelete = (areas, branchId) => {
  let found = false;

  return areas.map(area => {
    if (area.id === branchId) {
      found = true;

      return null;
    }
  
    if (!found) {
      area.children = traverseDelete(area.children, branchId);
    }
  
    return area;

  }).filter(el => el !== null);
}

export default (state=initialState, action) => {
  switch(action.type) {
    case FETCH_AREAS_BEGIN: {
      return {
        ...state,
        allItemsLoading: true,
        allItemsLoaded: false,
        error: null,
      };
    }
    case FETCH_AREAS_SUCCESS: {
      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: true,
        items: action.payload.items.map(item => traverseParse(item, null, 'root')),
      };
    }
    case FETCH_AREAS_FAILURE: {
      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: true,
        error: action.payload.error,
        items: [],
      };
    }
    case FETCH_AREAS_RESET: {
      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: false,
      };
    }
    case CREATE_AREA_SUCCESS: {
      let items = state.items || [];
      let found = false;
      const { item } = action.payload;
      const { parentId, data, included, parentName } = item;
      const parsedNewBranch = parseCreatedBranch({ data, included, parentId, parentName });

      [items, found] = traverseAdd(items, parsedNewBranch, parentId);
      if (!found) {
        items.push({
          ...parsedNewBranch,
          parentId: null,
          parentName: 'root',
        });
      }

      return {
        ...state,
        allItemsLoading: false,
        error: null,
        items: [...items],
        isSubmitSuccess: true,
        doc: parsedNewBranch,
      }
    }
    case UPDATE_AREA_BEGIN:
      return {
        ...state,
        isSubmitSuccess: false,
        error: null,
      }
    case UPDATE_AREA_SUCCESS: {
      const root = state.items || [];
      const { item } = action.payload;
      const { parentId, data, included } = item;
      const { id } = data;
      const parsedUpdatedBranch = parseCreatedBranch({ data, included, parentId });

      return {
        ...state,
        allItemsLoading: false,
        error: null,
        items: traverseUpdate(root, parsedUpdatedBranch, +id),
        doc: parsedUpdatedBranch,
        isSubmitSuccess: true,
      };
    }
    case UPDATE_AREA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isSubmitSuccess: false,
      }
    case DELETE_AREA_SUCCESS: {
      const items = state.items || [];
      const { deleteAreaId } = action.payload;

      return {
        ...state,
        allItemsLoading: false,
        error: null,
        items: traverseDelete(items, deleteAreaId)
      }
    }
    case OPEN_AREA_FORM_MODAL: {
      return {
        ...state,
        modalOpened: action.payload.modalOpened,
        doc: {...initialState.doc, ...action.payload.doc},
        isSubmitSuccess: false
      };
    }
    case CLOSE_AREA_FORM_MODAL: {
      return {
        ...state,
        modalOpened: action.payload.modalOpened,
        doc: initialState.doc,
        error: null,
        isSubmitSuccess: false
      };
    }
    case CHANGE_AREA_ORDER: {
      return {
        ...state,
        items: action.payload.items,
      };
    }
    default:
      return state;
  }
};
