import { DAYS_TILL_SESSION_EXPIRE } from '../modules/authentication/constants'
import { getQuestionQueryKeyValues } from '../modules/feedbacks/utils'

export default class Storage {
  static isAuthenticated() {
    if (+localStorage.getItem('OAUTH_EXPIRY') > 0) {
      return +localStorage.getItem('OAUTH_EXPIRY') - new Date().getTime() > 0;
    } else {
      return !!sessionStorage.getItem('OAUTH_ACCESS_TOKEN');
    }
  }

  static clear() {
    const storage = this.getStorage()
    const sensitiveInfo = [
      'OAUTH_ACCESS_TOKEN',
      'OAUTH_CLIENT',
      'OAUTH_EXPIRY',
      'OAUTH_UID',
      'USER_ID',
      'USER_NAME',
      'USER_AUTHORITY',
      'USER_FB_EXPORTABLE',
      'USER_PIN_FEEDBACK',
      'USER_FB_VIEWABLE',
      'USER_COMPANY_ID',
      'USER_COMPANY_NAME',
      'USER_COMPANY_USE_DASHBOARD',
      // 商品スコア開発、選択中アカウントのsurvey_type
      'USER_COMPANY_SURVEY_TYPE',
    ]
    sensitiveInfo.forEach(item => storage.removeItem(item))
  }

  static saveSession(saveSession, authData, dispatch) {
    const {
      token,
      client,
      uid,
      expiry
    } = authData;
    const apiExpiryTs = expiry * 1000;
    const clientExpiryTs = new Date().getTime() + (DAYS_TILL_SESSION_EXPIRE * 24 * 3600 * 1000);
    const expiryTs = (apiExpiryTs > clientExpiryTs) ? clientExpiryTs : apiExpiryTs;

    const storage = (saveSession) ? localStorage : sessionStorage;

    storage.setItem('OAUTH_ACCESS_TOKEN', token);
    storage.setItem('OAUTH_CLIENT', client);
    storage.setItem('OAUTH_UID', uid);
    storage.setItem('OAUTH_EXPIRY', expiryTs - 10000);
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getStorage() {
    return !!localStorage.getItem('OAUTH_ACCESS_TOKEN') ? localStorage : sessionStorage;
  }

  static storageSetter(key, value) {
    const storage = this.getStorage();
    const companyId = storage.getItem('USER_COMPANY_ID')
    try {
      const serialized = JSON.parse(storage.getItem(key) || "{}")
      if (value)
        serialized[companyId] = value
      else
        delete serialized[companyId]
      storage.setItem(key, JSON.stringify(serialized))
    } catch (e) {
      console.error(e)
      storage.setItem(key, '')
    }
  }

  static storageGetter(key) {
    const storage = this.getStorage();
    const companyId = storage.getItem('USER_COMPANY_ID')
    let serialized = {}
    try {
      serialized = JSON.parse(storage.getItem(key) || "{}")
    } catch (e) {
      console.error(e)
      storage.setItem(key, '')
    }
    return serialized[companyId]
  }

  static setUserData(user, dispatch) {
    // ログインユーザの情報（セッション他）をローカルストレージにセット
    const storage = this.getStorage();

    storage.setItem('USER_ID', user.id);
    storage.setItem('USER_NAME', user.name);
    storage.setItem('USER_AUTHORITY', user.authority);
    storage.setItem('USER_FB_EXPORTABLE', user.is_personal_info_exportable);
    storage.setItem('USER_PIN_FEEDBACK', user.is_feedback_pin);
    storage.setItem('USER_FB_VIEWABLE', user.is_personal_info_viewable);
    // ログインユーザの所属アカウントをデフォルトのselectedCompanyにセット
    user.company && this.setSelectedCompany({
      value: user.company.id,
      label: user.company.name,
      use_dashboard: user.company.use_dashboard,
      survey_type:user.company.survey_type
    })
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getUserData() {
    const storage = this.getStorage();

    return {
      id: storage.getItem('USER_ID'),
      name: storage.getItem('USER_NAME'),
      authority:  storage.getItem('USER_AUTHORITY'),
      fb_exportable: storage.getItem('USER_FB_EXPORTABLE'),
      pin_feedback: storage.getItem('USER_PIN_FEEDBACK'),
      fb_viewable: storage.getItem('USER_FB_VIEWABLE'),
    }
  }

  static setSelectedCompany({ label, value, use_dashboard,survey_type }, dispatch) {
    // 選択しているアカウントの情報をローカルストレージにセットし、stateを更新
    const storage = this.getStorage();
    storage.setItem('USER_COMPANY_NAME', label);
    storage.setItem('USER_COMPANY_ID', value);
    storage.setItem('USER_COMPANY_USE_DASHBOARD', use_dashboard);
    //商品スコア開発、 選択されたアカウントのsurvey_type
    storage.setItem('USER_COMPANY_SURVEY_TYPE', survey_type);
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getSelectedCompany() {
    const storage = this.getStorage();

    return {
      label: storage.getItem('USER_COMPANY_NAME'),
      value: storage.getItem('USER_COMPANY_ID'),
      use_dashboard: storage.getItem('USER_COMPANY_USE_DASHBOARD'),
      //商品スコア開発、 選択されたアカウントのsurvey_type
      survey_type: storage.getItem('USER_COMPANY_SURVEY_TYPE')
    }
  }

  static setGroupData({ label, value }, dispatch) {
    this.storageSetter('SELECTED_GROUP_LABEL', label);
    this.storageSetter('SELECTED_GROUP_VALUE', value);
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getGroupData = () => ({
    label: this.storageGetter('SELECTED_GROUP_LABEL'),
    value: this.storageGetter('SELECTED_GROUP_VALUE'),
  })

  static setShopData({ label, value }, dispatch) {
    this.storageSetter('SELECTED_SHOP_LABEL', label);
    this.storageSetter('SELECTED_SHOP_VALUE', value);
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getShopData = () => ({
    label: this.storageGetter('SELECTED_SHOP_LABEL'),
    value: this.storageGetter('SELECTED_SHOP_VALUE'),
  })

  static removeShopData = dispatch => this.setShopData({}, dispatch)

  static setProjectData({ label, value }, dispatch) {
    this.storageSetter('SELECTED_PROJECT_LABEL', label);
    this.storageSetter('SELECTED_PROJECT_VALUE', value);
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getProjectData = () => ({
    label: this.storageGetter('SELECTED_PROJECT_LABEL'),
    value: this.storageGetter('SELECTED_PROJECT_VALUE'),
  })

  static setSurveyData({ label, value }, dispatch) {
    this.storageSetter('SELECTED_SURVEY_LABEL', label)
    this.storageSetter('SELECTED_SURVEY_VALUE', value)
    this.setQuestion({})
    this.setScoreQuestion({})
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'})
  }

  static getSurveyData = () => ({
    label: this.storageGetter('SELECTED_SURVEY_LABEL'),
    value: this.storageGetter('SELECTED_SURVEY_VALUE'),
  })

  static removeSurveyData = dispatch => this.setSurveyData({}, dispatch)

  static setStartDate(startDate, dispatch) {
    this.storageSetter('SELECTED_START_DATE', startDate)
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'})
  }

  static getStartDate = () => this.storageGetter('SELECTED_START_DATE')

  static setEndDate(endDate, dispatch) {
    this.storageSetter('SELECTED_END_DATE', endDate)
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getEndDate = () => this.storageGetter('SELECTED_END_DATE')

  static setSelectedUsers(users, dispatch) {
    try {
      if (!Array.isArray(users))
        return
      const serialized = JSON.stringify(users.map(({label, value}) => ({label, value})));
      this.storageSetter('SELECTED_USERS', serialized);
    } catch(err) {
      console.error(err)
      this.getStorage().removeItem('SELECTED_USERS')
    }
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getSelectedUsers() {
    try {
      const serialized = this.storageGetter('SELECTED_USERS');
      if (serialized)
        return JSON.parse(serialized);
    } catch(err) {
      console.error(err)
      this.getStorage().removeItem('SELECTED_USERS')
    }
    return []
  }

  static setQuestion(question, dispatch) {
    this.storageSetter('SELECTED_QUESTION', question)
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getQuestion = () => this.storageGetter('SELECTED_QUESTION') || {}
  
  // 商品スコア、スコア設問の選択管理用state
  static setScoreQuestion(question, dispatch) {
    this.storageSetter('SELECTED_SCORE_QUESTION', question)
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getScoreQuestion = () => this.storageGetter('SELECTED_SCORE_QUESTION') || {}

  static getSearchParams() {
    return {
      project_id: this.getProjectData().value,
      survey_id: this.getSurveyData().value,
      group_id: this.getGroupData().value,
      shop_id: this.getShopData().value,
      sdate: this.getStartDate(),
      edate: this.getEndDate(),
    }
  }

  static setFeedback(doc, uniqueSurveyKey) {
    localStorage.setItem(uniqueSurveyKey, JSON.stringify(doc));
  }

  static getFeedback(uniqueSurveyKey) {
    return JSON.parse(localStorage.getItem(uniqueSurveyKey));
  }

  static setQuestionFilters(values, dispatch) {
    try {
      const serialized = JSON.stringify(values);
      this.storageSetter('QUESTION_FILTERS', serialized);
    } catch(err) {
      console.error(err)
      this.getStorage().removeItem('QUESTION_FILTERS')
    }
    dispatch && dispatch({ type: 'GLOBAL_VALUES_CHANGED'});
  }

  static getQuestionFilterQueryParams() {
    const existing = this.getQuestionFilters()
    let queryParams = {}
    if (Array.isArray(existing)) {
      existing.forEach(question => {
        const [key, values] = getQuestionQueryKeyValues(question, question.selected)
        queryParams = { ...queryParams, [key]: values }
      })
    }
    return queryParams
  }

  static getQuestionFilters() {
    try {
      const serialized = this.storageGetter('QUESTION_FILTERS');
      if (serialized)
        return JSON.parse(serialized);
    } catch(err) {
      console.error(err)
      this.getStorage().removeItem('QUESTION_FILTERS')
    }
    return []
  }
}
