import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import {
  Paper,
  TextField,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import IconCustom from './components/IconCustom';
import { themeSurveyListToolsStyles } from './styles';
import iconFilter from '../../../assets/icons/icon_filter.svg';
import iconFilterActive from '../../../assets/icons/icon_filter_active.svg';
import ElementIcon from '../../../common/icon/ElementIcon';
import {isMobile} from "react-device-detect";
import { loadProjects } from "../../../common/Header/HeaderForm/utils"
import NoOptionsMessage from "./components/NoOptionsMessage"

const useStyles = makeStyles(themeSurveyListToolsStyles);

const propTypes = {
  projectId: PropTypes.any,
  setProjectId: PropTypes.func.isRequired,
  q: PropTypes.string,
  setQ: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  setStatus: PropTypes.func.isRequired,
  openStatus: PropTypes.bool.isRequired,
  setOpenStatus: PropTypes.func.isRequired,
};

const SurveyListTools = ({
  projectId,
  setProjectId,
  q,
  setQ,
  status,
  setStatus,
  openStatus,
  setOpenStatus,
}) => {
  const classes = useStyles();
  const [projects, setProjects] = useState(null)
  const [keyword, setKeyword] = useState('');

  const searchName = (e) => {
    setQ(keyword);
  };

  const onProjectSelectFocused = async () => {
    setProjects(await loadProjects() || [])
  }

  const DropdownIndicator = props => {
    const { DropdownIndicator } = components
    return (
      <DropdownIndicator {...props}>
        { <ElementIcon name='caret-bottom'/> }
      </DropdownIndicator>
    )
  };

  const selectMenuStyle = (provided) => {
    const menuStyle =  {
      ...provided,
      position: 'unset',
      borderRadius: 0,
      boxShadow: 'unset',
      marginBottom: 0,
      marginTop: 0
    }
    return isMobile ? menuStyle : { ...provided }
  }

  const selectOptionStyle = (provided, {isSelected, isFocused}) => {
    const defaultStyle = {
      ...provided,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
    const optionStyle = {
      ...defaultStyle,
      color: isSelected ? '#EC681A' : 'black',
      backgroundColor: isFocused ? '#F2EEEC !important' : 'unset',
      padding: 10,
      borderBottom: `1px solid ${isSelected ? '#EC681A' : '#CCC' }`,
      display: 'block',
      alignItems: 'center',
      ':active': {
        backgroundColor: '#F2EEEC !important'
      }
    }
    return isMobile ? optionStyle : { ...defaultStyle }
  }

  const customStyles = {
    control: (base) => (isMobile
        ? {
          ...base,
          borderRadius: 0,
          borderStyle: 'none !important',
          borderBottom: '1px solid #EC681A !important',
          boxShadow: 'none !important'
        }
        : { ...base, background: '#EC681A', borderColor: 'white' }
    ),
    container: (provided) => ( isMobile ? { ...provided, position: 'unset' } : { ...provided } ),
    singleValue: (provided) => {
      const color = isMobile ? '#EC681A' : 'white';
      return { ...provided, color }
    },
    clearIndicator: (provided) => {
      const color = isMobile ? '#EC681A' : 'white';
      return { ...provided, color }
    },
    dropdownIndicator: (provided) => {
      const color = isMobile ? '#EC681A' : 'white';
      return { ...provided, color }
    },
    indicatorSeparator: (provided) => ( isMobile ? { display: 'none' } : { ...provided } ),
    menu: selectMenuStyle,
    option: selectOptionStyle
  };

  const styleSelectHover = {
    control: (base) => (isMobile
        ? {
          ...base,
          borderRadius: 0,
          borderStyle: 'none !important',
          borderBottom: '1px solid #ccc !important',
          boxShadow: 'none !important'
        }
        : {
          ...base,
          '&:hover': { background: '#EFEFEF' }
        }
    ),
    indicatorSeparator: (provided) => ( isMobile ? { display: 'none' } : { ...provided } ),
    menu: selectMenuStyle,
    option: selectOptionStyle,
    placeholder: (provided) => ({ ...provided, color: '#9f9797 !important' }),
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.left}>
        <IconCustom
          className={classes.leftIcon}
          src={(keyword.length > 0) ? iconFilterActive : iconFilter}
          setKeyword={setKeyword}
          setQ={setQ}
        />
      </div>
      <div className={classes.center}>
        <div className={classes.selectBox}>
          <Select
            isClearable={projectId}
            options={projects || []}
            className={classes.h30}
            placeholder="プロジェクトを選択"
            onChange={(v) => setProjectId(v)}
            onFocus={onProjectSelectFocused}
            components={{ DropdownIndicator, NoOptionsMessage: () => <NoOptionsMessage loading={projects===null}/> }}
            styles={projectId ? customStyles: styleSelectHover}
          />
        </div>
        <div className={classes.selectBox}>
          <TextField
            className={classes.input}
            variant='outlined'
            placeholder={'キーワード検索'}
            value={keyword}
            onInput={e => setKeyword(e.target.value)}
          />
        </div>
        <IconButton className={classes.searchButton} component="span" onClick={searchName}>
          <i className="material-icons">search</i>
        </IconButton>
      </div>

      <div className={classes.right}>
        <div className={!openStatus ? classes.buttonShowStore : `${classes.buttonShowStore} ${classes.buttonShowAcActive}`}  onClick={() => setOpenStatus(!openStatus)}>
          <div className="circle">
            <div />
          </div>
          非公開アンケートを表示
        </div>
        <div className={status ? classes.activeButtonArchive : classes.buttonShowAc}>
          <div onClick={() => setStatus(!status)}>
            <div />
            { status ? 'アーカイブを閲覧中' : 'アーカイブを見る' }
          </div>
        </div>
      </div>
    </Paper>
  );
};

SurveyListTools.propTypes = propTypes;

export default SurveyListTools
