import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Box,
  Grid,
  TextField,
  withStyles,
} from '@material-ui/core';
import { thunkFetchShopsByQs } from '../redux/thunk';
import { thunkExportItems } from 'common/csv/redux/thunk';
import ImportDialog from 'common/csv/ImportDialog';
import { ExportButton, ImportButton } from 'common/csv/CsvButton';
import { themedStyles } from './styles';
import IconFilter from 'assets/icons/icon_filter.svg';
import IconFilterActive from 'assets/icons/icon_filter_active.svg';

const propTypes = {
  total: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  exportCsv: PropTypes.func.isRequired,
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
  showInactiveItems: PropTypes.bool,
  setShowInactiveItems: PropTypes.func.isRequired,
  fetchShops: PropTypes.func.isRequired,
  getSearchParams: PropTypes.func.isRequired,
};

const ProductListTools = ({
  total,
  classes,
  exportCsv,
  name,
  setName,
  showInactiveItems,
  setShowInactiveItems,
  fetchShops,
  getSearchParams,
}) => {

  const [keyword, setKeyword] = useState(name);
  useEffect(() => setKeyword(name), [name]);
  const searchName = (e) => {
    setName(keyword);
  };

  const handleNameChange = ({ target: { value } }) => {
    setKeyword(value);
  };

  const handleShowInactive = (e) => {
    e.preventDefault();
    setShowInactiveItems(!!!showInactiveItems);
  };
  const handleClearSearch = () => {
    setKeyword('');
    setName('');
  }
  return (
    <Box className={classes.box}>
      <Grid className={classes.buttonShowAc}>
        <div className="blockSearch">
          <div className="icon">
            <img
              src={(keyword.length > 0) ? IconFilterActive : IconFilter}
              onClick={handleClearSearch}
              alt="filter"
              className={classes.filterIcon}
            />
          </div>
          <div className="formSearch">
            <div>
              <TextField
                name="name"
                placeholder="キーワード検索"
                variant="outlined"
                value={keyword}
                onChange={handleNameChange}
                className={classes.textBox}
              />
            </div>
            <div className="formIcon" onClick={searchName}>
              <i className="material-icons">search</i>
            </div>
          </div>
        </div>
        <div className={showInactiveItems ? "buttonShowStoreIsClick" : "buttonShowStore"} onClick={handleShowInactive}>
          <div>
            <div />
          </div>
          非公開の商品を表示
        </div>
      </Grid>
      <Grid className={classes.blockTotal}>
        <div className="txtTotal">
          <p>{total}商品</p>
        </div>
        <div className={"buttons"}>
          <ExportButton text="商品エクスポート" onClick={() => exportCsv(getSearchParams())} />
          <ImportDialog path={"shops"} onSuccess={fetchShops} title='商品インポート（CSV）'>
            <ImportButton text="商品インポート（CSV）"/>
          </ImportDialog>
        </div>
      </Grid>
    </Box>
  );
};

ProductListTools.propTypes = propTypes;

export default connect(
  ({ shops: { pagination: { total }} }) => {
    return {
      total: +total || 0,
    }
  },
  (dispatch) => ({
    fetchShops: () => dispatch(thunkFetchShopsByQs()),
    exportCsv: (params) => dispatch(thunkExportItems("shops", params))
  })
)(withStyles(themedStyles, { withTheme: true })(ProductListTools));
