export const themedStyles = theme => ({
  paper: {
    width: "640px",
    height: "100vh",
    padding: "32px 48px 42px 30px",
    overflow: "auto",
    flexGrow: "1",
    background: "#FFFFFF",
    boxShadow: "3px 0px 5px #B7B7B733",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  headerGood:{
    marginTop: 42,
    "& >p":{
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#1A0707",
      opacity: 1
    }
  },
  buttonDel: {
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    padding: "9px 20px",
    float: "right",
    "& span": {
      textAlign: "left",
      font: "bold 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro",
      letterSpacing: "0.25px",
      color: "#FFFFFF",
      opacity: 1,
      "& i": {
        foniSize: "20px"
      }
    }
  },
  txtColor: {
    color: "red"
  },
  headerFrom: {
    marginTop: "74px",
    position: "relative",
    "& >div": {
      textAlign: "left",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#1A0707",
      opacity: 1,
      display: "inline-block",
      "& >div":{
        "& >fieldset":{
          border: 0,
          borderBottom: "1px solid #b7b7b7",
          borderRadius: 0
        },
      },
      "& i": {
        fontSize: "16px",
        color: "#F02544",
        marginLeft: "10px",       
      }
    },
    "& >i": {
      float: "right",
      fontSize: "16px",
      color: "#B7B7B7",
      right: 0,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "50px",
      "& >div": {
        textAlign: "left",
        font: "500 10px/18px Noto Sans JP, Hiragino Sans",
        letterSpacing: 0,
        color: "#1A0707",
        opacity: 1,
        display: "inline-block",
        "& i": {
          fontSize: "14px",
          color: "#F02544",
          marginLeft: "10px"
        }
      },
      "& >i": {
        float: "right",
        fontSize: "16px",
        color: "#B7B7B7",
        position: "relative",
        top: "5px"
      }
    }
  },
  editName: {
    width: "100%",
    marginBottom: 5,
    "& input": {
      padding: "13px 10px 13px 10px",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#000",
      '& :placeholder': {
        color: '#B7B7B7',
      },
    },
    "& i": {
      marginTop: "-15px"
    },
    "&::before": {
      borderBottom: "1px solid #B7B7B7"
    }
  },
  inputGroup: {
    position: "relative",
    marginTop: "42px",
    "& p": {
      textAlign: "left",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0",
      color: "#1A0707",
      opacity: 1
    },
    "& >div": {
      width: "100%",
      "& >div": {
        "& >input": {
          padding: "10px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          border: "0px solid #B7B7B7",
          borderRadius: "5px",
          font: "400 12px/21px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.3px",
          opacity: 1,
          color: "#1A0707",
          "&::-webkit-input-placeholder": { /* Chrome/Opera/Safari */
            color: "#B7B7B7",
          },
          "&::-moz-placeholder": { /* Firefox 19+ */
            color: "#B7B7B7",
          },
          "&:-ms-input-placeholder": { /* IE 10+ */
            color: "#B7B7B7",
          },
          "&:-moz-placeholder": { /* Firefox 18- */
            color: "#B7B7B7"
          }
        }
      }
    }
  },
  switchButton: {
    borderStyle: "none",
    borderRadius: 5,
    paddingRight: 30,
    marginLeft: 0,
    marginRight: 0,
    display: "flex",
    alignItems: "center",
    "& >p": {
      color: "#fff"
    },
    "& >span:nth-child(1)": {
      height: 42,
      paddingLeft: 0,
      width: 50
    },
    "& >span": {
      "& >span:nth-child(1)": {
        color: "#fff",
        top: 4,
        left: -6,
        "& >span": {
          "& >span": {
            width: 16,
            height: 16
          }
        }
      }
    }
  },
  switchText: {
    font: "600 12px/21px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#B7B7B7 !important",
  },

  combobox: {
    display: "flex",
    width: 120,
    "& >div": {
      border: "1px solid #ccc",
      width: "100%",
      borderRadius: "5px",
      cursor: 'pointer',
      "&:before": {
        borderBottom: 0
      },
      "& >div": {
        padding: "10px 43px 10px 20px",
        textAlign: "left",
        font: "500 10px/21px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.25px",
        color: "#939393",
        opacity: 1,
        marginRight: "43px",
        borderRight: "1px solid #ccc",
        borderRadius: "5px 0 0 5px"
      },
      "& svg": {
        right: "10px"
      }
    }
  },
  switchButtonGroup: {
    display: "flex",
    "& p": {
      textAlign: "left",
      font: "400 12px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      marginLeft: "20px"
    }
  },
  formToggle: {
    padding: 20
  },
  inputGroup20: {
    position: "relative",
    marginTop: "20px",
    "& p": {
      textAlign: "left",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0",
      color: "#1A0707",
      opacity: 1
    },
    "& >div": {
      width: "100%",
      "& >div": {
        "& >input": {
          padding: "10px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          border: "0px solid #B7B7B7",
          borderRadius: "5px",
          font: "400 12px/21px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.3px",
          opacity: 1,
          color: "#1A0707",
          "&::-webkit-input-placeholder": { /* Chrome/Opera/Safari */
            color: "#B7B7B7",
          },
          "&::-moz-placeholder": { /* Firefox 19+ */
            color: "#B7B7B7",
          },
          "&:-ms-input-placeholder": { /* IE 10+ */
            color: "#B7B7B7",
          },
          "&:-moz-placeholder": { /* Firefox 18- */
            color: "#B7B7B7"
          }
        }
      }
    }
  },
  txtError:{
    position: "absolute",
    top: 0,
    right: 0,
    font: "400 10px/16px Noto Sans JP, Hiragino Sans !important",
    letterSpacing: "0.25px",
    color: "#F02544 !important",
    opacity: 1
  },
  buttonToggle: {
    background: "#EFEFEF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    padding: "11px 49px 11px 12px",
    "& span": {
      textAlign: "left",
      font: "bold 12px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      opacity: 1,
      "& label": {
        marginRight: "10px"
      }
    }
  }
});

export const switchCustomThemedStyles = theme => ({
  switchBase: {
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#EC681A",
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#EC681A",
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#B7B7B7",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  
  checked: {},
  focusVisible: {}
});
