import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, Paper, InputBase, Divider, IconButton } from '@material-ui/core';
import { SelectStyles } from './styles';

const propTypes = {
  value: PropTypes.string,
  isActived: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles(SelectStyles);

const SelectCustom = props => {  
  const classes = useStyles(); 
  return (
    <Paper className={props.isActived ? clsx(classes.root, classes.selectActived) : classes.root} onClick={props.handleClick}>
      <InputBase
        className={classes.input}
        placeholder={props.placeholder}
        readOnly
        value={props.value || ""}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton} aria-label="directions">
        <i className="el-icon-caret-bottom"></i>
      </IconButton>
    </Paper>
  )
}

SelectCustom.propTypes = propTypes;

export default SelectCustom;
