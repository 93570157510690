import React from "react"

const PinIcon = ({size = 22, color}) => {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 512 512"
      >
        <path fill={color}
            d="M178.229,376.467L135.562,333.8c-2.182-2.236-5.25-3.378-8.363-3.115  c-3.092,0.223-5.935,1.78-7.787,4.267l-117.269,160c-3.535,4.713-2.58,11.399,2.133,14.933c3.793,2.844,9.007,2.844,12.8,0  l160-117.333c4.752-3.481,5.783-10.156,2.302-14.908c-0.323-0.44-0.678-0.855-1.064-1.241L178.229,376.467z"/>
        <path fill={color}
            d="M 276.853,456.702 C 245.71524,455.32664 219.1801,435.88875 195.08225,417.96165 147.31639,379.1912 102.89064,334.87548 70.642029,282.1145 59.554151,262.09563 48.855909,236.66094 60.489406,214.62453 c 13.304281,-19.62536 36.197659,-31.17428 58.745254,-36.75296 30.19873,-6.14489 64.13924,-3.46653 89.2586,15.99405 14.057,9.88963 -6.35159,28.09513 -16.0517,14.61278 -23.11841,-14.68848 -53.02294,-15.02333 -78.49182,-6.82831 -13.23508,5.16788 -27.143907,12.2062 -35.230906,24.2582 -5.938212,16.97419 4.302046,34.1657 12.151166,48.73571 30.93874,48.66824 72.23026,89.9459 116.8359,126.1203 20.05441,14.78238 41.28704,31.47229 66.81793,34.47705 16.0091,1.90076 24.07746,-14.37093 30.69164,-26.04177 15.46171,-29.92522 13.22874,-68.0309 -5.70367,-95.86221 -5.08373,-16.68157 19.7654,-18.72144 23.3936,-3.80019 17.86085,31.67163 17.77113,71.9895 3.85792,105.11417 -9.27317,19.90199 -25.1292,43.12746 -49.91032,42.05065 z"/>
        <path fill={color}
            d="m 207.97827,211.09216 c -16.26651,2.8326 -14.20705,-17.36168 -13.10727,-18.32144 l 89.54787,-80.17429 c 2.81303,-2.44776 15.29561,10.74847 15.96947,14.89004 -5.27358,3.82417 -92.41007,83.60569 -92.41007,83.60569 z"/>
        <path fill={color}
            d="m 324.08582,13.167856 c 24.47435,1.094756 45.33102,16.566884 64.27198,30.836457 37.54406,30.860416 72.46279,66.134747 97.81026,108.131317 8.7151,15.93457 17.12394,36.18002 7.97999,53.72051 -10.4572,15.62135 -28.45143,24.81403 -46.17389,29.25453 -23.73627,4.8912 -47.15922,4.38647 -66.90308,-11.10372 -11.04883,-7.87193 7.15488,-19.40296 15.54625,-13.03396 18.17111,11.6917 32.50903,9.29279 52.52762,2.76974 10.4028,-4.11352 20.25038,-8.35988 26.60678,-17.95301 4.66745,-13.51107 -4.4662,-25.29675 -10.63562,-36.89415 C 440.79819,120.15673 410.24135,89.4702 375.18122,60.676182 359.41842,48.909737 345.71269,37.794511 325.64536,35.402789 c -12.58319,-1.512963 -19.74982,18.441182 -25.27993,25.720202 -7.49042,25.671655 -8.40872,33.701353 2.67509,56.668049 3.99582,13.27816 -15.55491,16.25784 -18.40669,4.38085 -14.03866,-25.209915 -10.71378,-49.166151 0.22203,-75.532651 7.28873,-15.841543 19.7516,-34.3285 39.22961,-33.471383 z"/>
        <path fill={color}
            d="m 312.58849,313.89445 c -11.0691,10.38894 -22.19907,-11.67076 -17.70946,-16.02035 l 86.09623,-84.96823 c 2.81303,-2.44776 16.2544,10.55671 16.92826,14.69828 -5.27358,3.82417 -85.31503,86.2903 -85.31503,86.2903 z"/>
      </svg>
  )
}

export default PinIcon
