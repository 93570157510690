import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NPS_COLORS } from 'modules/aggregation/constants';
import { themeTimeSeriesTooltipStyles } from "./styles";

const useStyles = makeStyles(themeTimeSeriesTooltipStyles);

export const TooltipCount = ({ payload, header }) => {
  const { blockTooltip, itemColor } = useStyles();
  if (!payload || payload.length === 0 || !payload[0].payload)
    return <></>

  const { label, nps_score, count } = payload[0].payload
  return (
    <div className={blockTooltip}>
      <div>{label}</div>
      <div className={itemColor}>NPS: {nps_score}</div>
      <div className={itemColor}>回答数: {count}</div>
    </div>
  )
}

export const TooltipNpsCategory = ({ payload }) => {
  const { blockTooltip, itemColor } = useStyles();
  if (!payload || payload.length === 0 || !payload[0].payload)
    return <></>
  
  const { label, nps_score, count, promoters, passives, detractors } = payload[0].payload
  return (
    <div className={blockTooltip}>
      <div>{label}</div>
      <div className={itemColor}>NPS: {nps_score}</div>
      <div className={itemColor}>回答数: {count}</div>
      <div style={{ color: NPS_COLORS.promoter }}>推奨者: {promoters}%</div>
      <div style={{ color: NPS_COLORS.passive }}>中立者: {passives}%</div>
      <div style={{ color: NPS_COLORS.detractor }}>批判者: {detractors}%</div>
    </div>
  )
}
