import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { makeStyles } from '@material-ui/core';

const QUESTION = 'QUESTION';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 15,
    flexGrow: 1,
    position: 'relative',
    opacity: 0,
  },
  reOrder: {
    position: 'absolute',
    left: '-29px',
    top: 0,
    width: "100%",
    height: 15,
    opacity: 0,
  },
}));

const propTypes = {
  spIndex: PropTypes.number.isRequired,
  moveCard: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

const QuestionDnD = ({
  spIndex,
  moveCard,
  show,
}) => {
  const classes = useStyles();
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: QUESTION,
    canDrop: () => false,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }

      const dragSpIndex = item.spIndex;
      const dragPiIndex = item.piIndex;
      const hoverSpIndex = spIndex;

      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (item.spIndex === spIndex && item.piIndex === 0) {
        return;
      }

      if (dragSpIndex <= hoverSpIndex && dragPiIndex < 0 && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragSpIndex > hoverSpIndex && dragPiIndex > 0 && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard({
        spIndex1: item.spIndex,
        piIndex1: item.piIndex,
        spIndex2: spIndex,
        piIndex2: 0,
      })

      item.spIndex = spIndex;
      item.piIndex = 0;
    },
  });

  drop(ref);

  return (
    <div className={classes.root} style={{ minHeight: show ? 15 : 0 }}>
      <div className={classes.reOrder} ref={ref} style={{ height: show ? 15 : 0 }}/>
    </div>
  )
}

QuestionDnD.propTypes = propTypes;

export default QuestionDnD;
