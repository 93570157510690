import React, { useState } from "react";
import { connect } from "react-redux";
import { 
  makeStyles,
  Button,
 } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { UPDATE_USER_ITEM } from "./redux/actions";
import DialogModal from "components/DialogModal";
import UserCreateForm from "./UserCreateForm";
import UserForm from "./UserForm";


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  addBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
}));

const UserAppBarBottom = ({ updateUserItem }) => {
  const classes = useStyles();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const handleOpenModal = () => {
    setDrawerIsOpen(true)
    updateUserItem({});
  };

  const handleClose = () => {
    setDialogIsOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Button
          variant="contained"
          className={classes.addBtn}
          startIcon={<AddIcon />}
          // onClick={() => setDialogIsOpen(true)}  // uncomment to enable create form
          onClick={() => handleOpenModal()}
        >
          新規追加
        </Button>
      </div>

      <DialogModal
        open={dialogIsOpen}
        onClose={handleClose}
      >
        <UserCreateForm
          dialogIsOpen={dialogIsOpen}
          onClose={handleClose}
        />
      </DialogModal>

      <UserForm modalOpen={drawerIsOpen} closeModal={() => setDrawerIsOpen(false)} item={{}} />
    </>
  );
};

export default connect(
  null,
  (dispatch) => ({
    updateUserItem: (item) => dispatch({ type: UPDATE_USER_ITEM, payload: { item } } ),
  })
)(UserAppBarBottom);
