import { default as axios } from "axios";
import { PAGINATION_PARAM_NO_PAGINATION } from '../../../../constants';
import {
  COPY_SURVEY_SUCCESS,
  FETCH_SURVEYS_BEGIN,
  FETCH_SURVEYS_SUCCESS,
  FETCH_SURVEYS_FAILURE,
  FETCH_ALL_SURVEYS_BEGIN,
  FETCH_ALL_SURVEYS_SUCCESS,
  FETCH_ALL_SURVEYS_FAILURE,
  FETCH_SURVEY_BEGIN,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAILURE,
  SAVE_SURVEY_BEGIN,
  SAVE_SURVEY_FAILURE,
  SAVE_SURVEY_SUCCESS,
} from './actions';
import { SURVEYS_API_PATH } from "../constants";
import { getRequestUrl, handleError } from "utils";
import { Storage } from "../../../../utils";


export const thunkFetchSurveys = (
  page,
  searchParams,
  actions={
    actionBegin: () => ({ type: FETCH_SURVEYS_BEGIN }),
    actionSuccess: (items, paginationParams, included) => ({
      type: FETCH_SURVEYS_SUCCESS, payload: { items, pagination: paginationParams, included }
    }),
    actionFailure: (error) => ({ type: FETCH_SURVEYS_FAILURE, payload: { error } }),
  }) => {
  searchParams = !!searchParams ? searchParams : {};

  return dispatch => {
    dispatch(actions.actionBegin());
    return axios
      .get(getRequestUrl(SURVEYS_API_PATH, page, searchParams))
      .then(({ data: { data, included }, headers }) => {
        const paginationParams = {
          perPage: headers["page-items"],
          total: headers["total-count"],
          currentPage: +headers["current-page"],
        };

        dispatch(actions.actionSuccess(data, paginationParams, included));
      })
      .catch(handleError(dispatch, actions.actionFailure));
  };
};

export const thunkFetchAllSurveys = (searchParams) => thunkFetchSurveys(
  PAGINATION_PARAM_NO_PAGINATION,
  searchParams,
  {
    actionBegin: () => ({ type: FETCH_ALL_SURVEYS_BEGIN }),
    actionSuccess: (items) => ({ type: FETCH_ALL_SURVEYS_SUCCESS, payload: { items } }),
    actionFailure: (error) => ({ type: FETCH_ALL_SURVEYS_FAILURE, payload: { error } }),
  }
);

export const thunkFetchSurvey = (surveyId) => {
  return dispatch => {
    dispatch({ type: FETCH_SURVEY_BEGIN });

    return axios
      .get(`${SURVEYS_API_PATH}/${surveyId}`)
      .then(res => {
        dispatch({
          type: FETCH_SURVEY_SUCCESS,
          payload: {
            survey: res.data,
          }
        });
      })
      .catch(handleError(dispatch, error => ({
        type: FETCH_SURVEY_FAILURE,
        payload: { error }
      })));
  };
};

export const thunkSaveSurvey = ({ id, ...params }) => dispatch => {
  dispatch({ type: SAVE_SURVEY_BEGIN })

  if (!!id) {
    axios
      .patch(`${SURVEYS_API_PATH}/${id}`, { survey: params })
      .then(({ data }) => {
        const item = data || { attributes: {} };

        dispatch({
          type: SAVE_SURVEY_SUCCESS, payload: { survey: data }
        })

        if (+Storage.getSurveyData().value === +item.id)
          Storage.setSurveyData({ value: item.id, label: item.attributes.name }, dispatch)
      })
      .catch((error) => {
        dispatch({ type: SAVE_SURVEY_FAILURE, payload: { itemSaveError: ((error.response || {}).data || {}).errors || {} } });
        console.error(error);
      });
  } else {
    axios
      .post(SURVEYS_API_PATH, { survey: params })
      .then(({ data }) => dispatch({ type: SAVE_SURVEY_SUCCESS, payload: { survey: data, newSurveyId: data.data.id }}))
      .catch((error) => {
        dispatch({ type: SAVE_SURVEY_FAILURE, payload: { itemSaveError: ((error.response || {}).data || {}).errors || {} } });
        console.error(error);
      });
  }
};


export const thunkDeleteSurvey = ({ id }) => dispatch => {
  axios.delete(`${SURVEYS_API_PATH}/${id}`)
    .then(() => {
      dispatch(thunkFetchSurveys());
    })
    .catch((error) => {
      console.error(error);
    });
}

export const thunkCopySurvey = ({ id }) => dispatch => {
  dispatch({ type: FETCH_SURVEY_BEGIN })
  axios.post(`${SURVEYS_API_PATH}/${id}/copy`)
    .then(({ data }) => dispatch({
        type: COPY_SURVEY_SUCCESS,
        payload: {
          survey: data,
        }
      })
    )
    .catch(handleError(dispatch, ({ type: FETCH_SURVEY_FAILURE })))
}

export const thunkDeleteSurveyFeedbacks = id => dispatch => {
  axios.delete(`${SURVEYS_API_PATH}/${id}/feedbacks/destroy_all`)
    .catch(error => console.error(error))
}
