import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  payload: PropTypes.arrayOf(PropTypes.object),
}

const TimeSeriesLegend = ({ payload }) => {
  return (
    <div style={{textAlign: "right"}} >
      {payload.map((line, i) => <div key={i} style={{display: "inline", }}>
        <div style={{
            height: 13,
            width: 13,
            borderRadius: "50%",
            borderStyle: "solid",
            borderColor: line.color,
            borderWidth: 2.5,
            display: "inline-block",
            marginRight: 10,
            marginLeft: 20
        }}/>{line.value}
      </div>)}
    </div>
  )
}

TimeSeriesLegend.propTypes = propTypes;

export default TimeSeriesLegend;
