export const themedStyles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#efefef"
  },
  list: {
    width: "70%",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  itemWrapper: {
    marginLeft: 10
  },
  itemContainer: {
    borderLeft: "4px solid #B7B7B7",
    marginLeft: 37,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 33
    }
  },
  itemActive: {
    borderLeft: "4px solid #EC681A"
  },
  expandMore: {
    width: `calc(100% - 44px - 10px - 37px)`,
    marginBottom: 10,
    marginLeft: 37,
    color: "white",
    padding: 4,
    backgroundColor: "#B7B7B7"
  }
})