import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FeedbackAnswerEditTag from './FeedbackAnswerEditTag';
import {TYPE_NPS_QUESTION, TYPE_NPS_REASON, TYPE_UPLOAD} from './constants';
import { getAnswerValue } from './utils';
import { connect } from 'react-redux';
import { OPEN_ANSWER_DIALOG } from './redux/actions/answer';
import { Button, Grid, Paper, CircularProgress } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import ElementIcon from "common/icon/ElementIcon";
import { useFeedbackAnserListStyle } from './styles';
import { renderHtmlWithoutTags } from '../../utils';
import { renderParams } from 'modules/utils';


const propTypes = {
  feedback: PropTypes.object,
  answers: PropTypes.arrayOf(PropTypes.object),
  openDialog: PropTypes.func,
  loading: PropTypes.bool,
  userAuthority: PropTypes.string,
};

const FeedbackAnswerList = ({ feedback, answers, openDialog, loading, userAuthority }) => {
  const [ answerEntry, setAnswerEntry ] = useState("")
  const [ answerSelected, setAnswerSelected ] = useState(null)
  const classes = useFeedbackAnserListStyle();
  const isAdmin = userAuthority === "super_admin" || userAuthority === "admin";

  const editAnswer = (e, answer, answerIndex, editableAnswerEntry) => {
    e.preventDefault();
    setAnswerEntry(editableAnswerEntry);
    setAnswerSelected(answerIndex);
    openDialog(answer);
  }

  const closeAnswerEditTag = () => {
    setAnswerSelected(null);
  }

  if (loading) {
    return <CircularProgress disableShrink />
  }
  return (
    <div className={classes.content}>
      {!!answers &&
        answers.map((answer, index) => {
          const { visible, editable } = getAnswerValue(feedback, answer)
          const answerClass = (answerSelected !== null && answerSelected !== index) ? classes.answerNotBeSelected : ''
          return (
            <Grid container key={index} alignItems='center' justify='center'>
              <Grid container item xs={12} direction='column' className={answerClass}>
                <Grid container className={classes.paper}>
                  <Grid item md={8} xs={11} className={classes.titleHeader}>
                    {answer && feedback && (renderHtmlWithoutTags(renderParams(answer.question.body, feedback.shop.name)))}
                  </Grid>
                  <Grid item md={3} xs={12} className={classes.tag}>
                    {!!answer.question.is_required && (
                      <div className={classes.colorRed}>必須</div>
                    )}
                    {answer.question_type === TYPE_NPS_REASON && (
                      <div className={classes.colorGreen}>NPS理由</div>
                    )}
                    {answer.question_type === TYPE_NPS_QUESTION && (
                      <div className={classes.colorOrange}>NPS</div>
                    )}
                  </Grid>
                </Grid>
                <Paper className={classes.paperBottom}>
                    {
                      (answerSelected === index) ?
                      <FeedbackAnswerEditTag feedback={feedback} answerEntry={answerEntry} closeEditTag={closeAnswerEditTag}/>
                      :
                      <Grid container>
                        <Grid item xs={12} md={9} className={classes.txtContent}>
                          {answer.question_type === TYPE_UPLOAD ?
                            <Button variant="contained" startIcon={<GetAppIcon/>}
                              href={answer.answer_image_url} download disabled={!!!visible}>
                              {`ダウンロード(${answer.answer_image_size})`}
                            </Button>
                          : visible
                          }
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.gridBtnDel}>
                          {isAdmin && editable !== undefined && editable !== null && !!answer.id && <Button
                            variant='outlined'
                            className={classes.txtBtnEdit}
                            onClick={e => editAnswer(e, answer, index, editable)}
                          >
                            <ElementIcon name={`edit-outline`} />
                            編集
                          </Button>
                          }
                        </Grid>
                      </Grid>
                    }
                </Paper>
              </Grid>
            </Grid>
          )
        })}
    </div>
  )
}

FeedbackAnswerList.propTypes = propTypes;

export default connect(
  ({ feedbacks: { feedback }, feedbackAnswers: { items, loading }, global: { userData } }) => ({
    answers: items,
    feedback,
    loading,
    userAuthority: userData.authority
  }),
  dispatch => ({
    openDialog: answer => dispatch({ type: OPEN_ANSWER_DIALOG, payload: { answer } }),
  }),
)(FeedbackAnswerList);
