export const themedStyles = theme => ({
  root: {
    width: '100%',
    backgroundColor: "#F7F7F7",
    padding: '0 calc((100vw - 600px) / 2)',
    fontSize: 13,
    overflowY: "scroll",
    height: '100vh',
    '& >*': {
      fontFamily: "'Noto Sans JP', sans-serif !important"
    },
  },
  headerPaper: props => ({
    position: "fixed",
    top: 0,
    zIndex: 10,
    width: "559px",
    minHeight: "auto",
    left: props.isIE ? 'calc(50vw - 25px)' : 'calc(50vw)',
    transform: "translateX(-50%)",
    padding: "10px 24px",
    wordBreak: "break-word",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0"
    }
  }),
  footer: {
    position: "fixed",
    bottom: 0,
    zIndex: 10,
    width: "559px",
    minHeight: "auto",
    margin: "0 20px",
    padding: "10px 24px",
    wordBreak: "break-word",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0"
    }
  },
  header: props => ({
    backgroundColor: 'white',
    font: "bold 14px/24px 'Noto Sans JP', sans-serif",
    padding: '10px',
    left: props.isIE ? 'calc(50vw - 25px)' : 'calc(50vw)',
    transform: "translateX(-50%)",
    position: 'fixed',
    width: 559,
    zIndex: 10,
    "& >*": {
      margin: "10px 0px 5px 14px",
      wordBreak: "break-all"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0"
    }
  }),
  headerShop: {
    fontSize: 10,
    maxHeight: 50,
    overflow: "hidden",
    wordBreak: "break-all",
    marginBottom: 0,
  },
  contentSurveyPage: {
    padding: "0 20px",
    paddingTop: "90px",
    wordBreak: "break-word",
    "& .Mui-error": {
      fontFamily: "'Noto Sans JP', sans-serif",
      backgroundColor: "#fee",
      border: "1px solid #f00",
      padding: "10px"
    },
    "& input, textarea": {
      fontFamily: "'Noto Sans JP', sans-serif"
    }
  },
  heading: {
    background: "#EFEFEF",
    textAlign: "center",
    padding: "20px 0px 30px 0px",
    fontSize: "16px"
  },
  selectAnswer: {
    margin: 'auto',
    paddingTop: '40px',
    background: "#F7F7F7",
    "& >*": {
      marginLeft: '20px',
      marginRight: '20px',
    }
  },
  buttonFixed: {
    background: "#F7F7F7",
    padding: "20px",
    marginBottom: "150px"
  },
  heightButton:{
    height: "50px"
  },
  buttonHandleNext: {
    font: '500 12px/24px Noto Sans JP, Hiragino Sans',
    fontFamily:"'Noto Sans JP', sans-serif",
    float: "right",
    textAlign: "center",
    borderRadius: "5px",
    color: "#FFFFFF",
    padding: "0px 25px",
    height: 42,
    border: "none",
    cursor: "pointer",
    display: "inline-block",
    backgroundColor: "#EC681A",
    position: 'relative',
    " & i": {
      position: "absolute",
      right: 10,
      top: '50%',
      transform: 'translateY(-50%)'
    }
  },
  buttonHandlePrev: {
    font: '500 12px/24px Noto Sans JP, Hiragino Sans',
    fontFamily: "'Noto Sans JP', sans-serif",
    textAlign: "center",
    borderRadius: "5px",
    color: "#FFFFFF",
    height: 42,
    padding: "0px 25px",
    border: "none",
    cursor: "pointer",
    display: "inline-block",
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    position: 'relative',
    " & i": {
      position: "absolute",
      left: 10,
      top: '50%',
      transform: 'translateY(-50%)'
    },
  },
  tabWithNumber: {
    padding: "20px 0px",
    textAlign: 'center',
    background: "#EFEFEF",
    " & .MuiAppBar-colorPrimary": {
      background: "#F7F7F7"
    },
    " & .MuiAppBar-root ": {
      width: "94%",
      [theme.breakpoints.down('xs')]: {
        width: "89%"
      },
    }
  },
  appBar: {
    background: "white",
  },
  tabWithoutNumber: {
    padding: "20px 0px 60px",
    textAlign: 'center',
    background: "#EFEFEF",
    "& >*": {
      marginLeft: '20px',
      marginRight: '20px',
    },
    " & .MuiAppBar-colorPrimary": {
      background: "#F7F7F7"
    },
    " & .MuiAppBar-root ": {
      width: "94%",
      [theme.breakpoints.down('xs')]: {
        width: "89%"
      },
    }
  },
  lineStyle: {
    background: "#F7F7F7",
    padding: "20px",
  },
  dividerStyle: {
    background: "#EC681A",
    width: "25px",
    margin: "0 auto",
    height: 3,
  },
  textAnswer: {
    background: "#F7F7F7",
    padding: "40px 10px 10px 20px",
    fontWeight: 'bold',
  },
  textAnswer1: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: 'bold'
  },
  snackbarAnchor: {
    right: "0 !important"
  },
  snackbarRoot: {
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    boxShadow: "-2px 3px 6px #B7B7B799",
    borderLeft: "solid 4px #EC681A",
    padding: "32px 26px !important"
  },
  snackbarRootError: {
    borderLeft: "solid 4px #F02544",
  },
  snackbarContainer: {
    display: "flex",
    alignItems: "center", 
    width: "100%",
  },
  checkIcon: {
    color: "#EC681A",
    fontSize: 32
  },
  errorIcon: {
    color: "#F02544",
    fontSize: 32
  },
  closeIcon: {
    color: "#939393",
    fontSize: 14,
    cursor: "pointer",
    marginLeft: 20
  },
  snackbarRightContent: {
    marginLeft: 20,
    paddingRight: 20,
    borderRight: "solid 1px #F2EEEC"
  },
  labelTitle: {
    font: "400 16px/27px 'Noto Sans JP', sans-serif",
    letterSpacing: "1.92px",
    color: "#1A0707",
    margin: "0"
  },
  labelContent: {
    font: "400 12px/27px 'Noto Sans JP', sans-serif",
    letterSpacing: "1.44px",
    color: "#796255",
    margin: 0
  }
});
