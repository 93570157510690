import React from "react";
import FadingDots from "../../../common/FadingDots";

const NoOptionsMessage = ({ loading }) => {
  const NoOption = () => (
    <div style={{ margin: 10, textAlign: "center", color: "gray" }}>
      <p>No options</p>
    </div>
  );

  return loading ? <FadingDots style={{ margin: 10 }} /> : <NoOption />;
};

export default NoOptionsMessage;
