export const themeDashboardStyles = theme => ({
  paperContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 12px',
    },
  },
  graphsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: 230,
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      height: 460,
    },
  },
})
