import {
  FETCH_GROUPS_BEGIN,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  FETCH_ALL_GROUPS_BEGIN,
  FETCH_ALL_GROUPS_SUCCESS,
  FETCH_ALL_GROUPS_FAILURE,
  CREATE_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
  OPEN_GROUP_FORM_MODAL,
  CLOSE_GROUP_FORM_MODAL,
  FETCH_GROUPS_RESET,
  CHANGE_GROUP_ORDER,
  UPDATE_GROUP_BEGIN,
  UPDATE_GROUP_FAILURE,
} from './actions';
import { API_MODEL_TYPE_SHOP } from '../../shops/constants';
import { parseGroup, traverseParse } from '../utils';


const initialState = {
  item: {},
  items: [],
  itemsLoading: false,
  itemsLoaded: false,
  allItems: [],
  allItemsError: null,
  allItemsLoading: false,
  allItemsLoaded: false,
  error: null,
  isSubmitSuccess: false,
  doc: {
    parentName: '',
    parentId: null,
    name: '',
    shops: [],
  }
};

const parseCreatedBranch = ({ data, included, parentId, parentName }) => 
  parseGroup({
    id: +data.id,
    name: data.attributes.name,
    display_order: data.attributes.display_order,
    shops: { data: included.filter(el => el.type === API_MODEL_TYPE_SHOP) },
    children: [],
    parentId: parentId,
    parentName: parentName
  });

const traverseAdd = (groups, newBranch, parentBranchId, found=false) => {
  if (found) {
    return [groups, found];
  }

  for (const group of groups) {
    if (group.id === parentBranchId) {
      found = true;
      group.children.push(newBranch)
    } 

    [group.children, found] = traverseAdd(group.children, newBranch, parentBranchId, found);
  }

  return [groups, found];
}

const traverseUpdate = (groups, updatedData, parentBranchId) => {
  let found = false;

  groups = groups.map(group => {
    if (group.id === parentBranchId) {
      found = true;

      return {
        ...group,
        ...updatedData,
        children: group.children
      };
    }

    if (!found) {
      group.children = traverseUpdate(group.children, updatedData, parentBranchId);
    }
  
    return group;
  });

  return groups;
}

const traverseDelete = (groups, branchId) => {
  let found = false;

  return groups.map(group => {
    if (group.id === branchId) {
      found = true;

      return null;
    }
  
    if (!found) {
      group.children = traverseDelete(group.children, branchId);
    }
  
    return group;

  }).filter(el => el !== null);
}

export default (state=initialState, action) => {
  switch(action.type) {
    case FETCH_GROUPS_BEGIN: {
      return {
        ...state,
        itemsLoading: true,
        itemsLoaded: false,
        error: null,
      }
    }
    case FETCH_GROUPS_SUCCESS: {
      return {
        ...state,
        itemsLoading: false,
        itemsLoaded: true,
        items: action.payload.items.map(item => traverseParse(item, null, 'root')),
      };
    }
    case FETCH_GROUPS_FAILURE: {
      return {
        ...state,
        itemsLoading: false,
        itemsLoaded: true,
        error: action.payload.error,
        items: [],
      };
    }
    case FETCH_ALL_GROUPS_BEGIN: {
      return {
        ...state,
        allItemsLoading: true,
        allItemsLoaded: false,
        allItemsError: null,
      }
    }
    case FETCH_ALL_GROUPS_SUCCESS: {
      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: true,
        allItems: action.payload.items.map(item => traverseParse(item, null, 'root', true)),
      };
    }
    case FETCH_ALL_GROUPS_FAILURE: {
      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: true,
        allItemsError: action.payload.error,
        allItems: [],
      };
    }
    case FETCH_GROUPS_RESET: {
      return {
        ...state,
        itemsLoading: false,
        itemsLoaded: false,
        allItemsLoading: false,
        allItemsLoaded: false,
        allItems: [],
      };
    }
    case CREATE_GROUP_SUCCESS: {
      let items = state.items || [];
      let found = false;
      const { item } = action.payload;
      const { parentId, data, included, parentName } = item;
      const parsedNewBranch = parseCreatedBranch({ data, included, parentId, parentName });

      [items, found] = traverseAdd(items, parsedNewBranch, parentId);
      if (!found) {
        items.push({
          ...parsedNewBranch,
          parentId: null,
          parentName: 'root',
        });
      }

      return {
        ...state,
        itemsLoading: false,
        error: null,
        items,
        isSubmitSuccess: true,
        doc: parsedNewBranch,
      }
    }
    case UPDATE_GROUP_BEGIN:
      return {
        ...state,
        isSubmitSuccess: false,
        error: null,
      }
    case UPDATE_GROUP_SUCCESS: {
      const root = state.items || [];
      const { item } = action.payload;
      const { parentId, data, included } = item;
      const { id } = data;
      const parsedUpdatedBranch = parseCreatedBranch({ data, included, parentId });

      return {
        ...state,
        itemsLoading: false,
        error: null,
        items: traverseUpdate(root, parsedUpdatedBranch, +id),
        doc: parsedUpdatedBranch,
        isSubmitSuccess: true,
      };
    }
    case UPDATE_GROUP_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isSubmitSuccess: false,
      }
    case DELETE_GROUP_SUCCESS: {
      const items = state.items || [];
      const { deleteGroupId } = action.payload;

      return {
        ...state,
        itemsLoading: false,
        itemsError: null,
        items: traverseDelete(items, deleteGroupId)
      }
    }
    case OPEN_GROUP_FORM_MODAL: {
      return {
        ...state,
        modalOpened: action.payload.modalOpened,
        doc: {...initialState.doc, ...action.payload.doc},
        isSubmitSuccess: false
      };
    }
    case CLOSE_GROUP_FORM_MODAL: {
      return {
        ...state,
        modalOpened: action.payload.modalOpened,
        doc: initialState.doc,
        error: null,
        isSubmitSuccess: false
      };
    }
    case CHANGE_GROUP_ORDER: {
      return {
        ...state,
        items: action.payload.items,
      };
    }
    default:
      return state;
  }
};
