import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import validate from "validate.js";
import { styles } from "./styles";
import { thunkSaveShop } from "../redux/thunk";


const useStyles = makeStyles(styles);

const ProductCreateForm = ({
  submitErrors,
  save,
  onClose,
  ...otherProps
}) => {
  const classes = useStyles(otherProps);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const errors = !!submitErrors ? submitErrors.detail : {};

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    }
  }, [submitErrors])

  const handleCancel = () => {
    onClose(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = { name: e.target.name.value };
    const shopConstraints = { name: { presence: { allowEmpty: false } } };
    const shopErrors = validate(params, shopConstraints) || {};
    setErrors(shopErrors);

    if (Object.keys(shopErrors).length === 0) save(params)
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent className={classes.root}>
        <p className={classes.title}>新しい商品を作成</p>
        <TextField
          className={classes.input}
          id="input-with-icon-textfield"
          placeholder="商品名を入力してください。"
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i className="fa fa-edit"></i>
              </InputAdornment>
            )
          }}
          error={!!errors.name}
          helperText={!!errors.name ? errors.name[0] : null}
        />
      </DialogContent>
      <DialogActions className={classes.btnContainer}>
        <Button
          type={"submit"}
          variant="contained"
          className={classes.btnOk}
        >
          作成
        </Button>
        <Button
          onClick={handleCancel}
          variant="outlined"
          className={classes.btnClose}
        >
          キャンセル
        </Button>
      </DialogActions>
    </form>
  );
}

export default connect(
  ({ companies: { errors } }) => ({
    submitErrors: errors,
  }),
  dispatch => ({
    save: (docFields) => dispatch(thunkSaveShop(docFields)),
  }),
)(ProductCreateForm);
