import { 
  UPLOAD_FILE_BEGIN,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  EXPORT_FILE_FAILURE,
  OPEN_MODAL,
  CLOSE_MODAL,
} from './actions'

const initialState = {
  modalOpen: false,
  loading: false,
  success: null,
  error: null,
}

export default (state=initialState, action) => {
  switch(action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modalOpen: true,
        error: null,
      }
    case UPLOAD_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      }
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        success: action.payload.response,
        error: null,
        loading: false,
      }
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null,
        loading: false,
      }
    case CLOSE_MODAL:
      return initialState
    case EXPORT_FILE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      }
    default:
      return state
  }
}