import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormLabel,
} from "@material-ui/core";

const styles = {
  form_control: {
    marginBottom: "10px",
  }
};

const propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  classes: PropTypes.object,
  component: PropTypes.string,
}

const FormField = ({ label, children, classes, ...otherProps }) => {
  const props = { className: classes.form_control, ...otherProps };

  return (
    <FormControl {...props}>
      <FormLabel component="legend">{ label || ''}</FormLabel>
      { children }
    </FormControl>
  )
};

FormField.propTypes = propTypes;

export default withStyles(styles)(FormField);
