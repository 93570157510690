export const themedStyles = theme => ({
  formContainer: {
    width: "100%",
    marginBottom: "30px"
  },
  formContain: {
    width: "100%",
    background: "#FFFFFF",
    zIndex: 10,
    height: '100%',
    position: 'absolute',
  },
  formLabel: {
    font: "500 14px/18px 'Noto Sans JP', sans-serif",
    color: '#1A0707',
    "&.Mui-focused": {
      color: "#EC681A !important"
    }
  },
  blockListUser: {
    marginTop: "20px",
    "& >div": {
      "& >.search": {
        display: "block",
        "& >p": {
          display: "inline-block",
          width: "15%",
          font: "500 14px/18px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          color: "#1A0707",
          opacity: 1
        },
        "& >div": {
          display: "inline-block",
          width: "75%",
          maxWidth: "253px"
        }
      },
      "& >.listUser": {
        background: "#F7F7F7 0% 0% no-repeat padding-box",
        opacity: 1,
        minHeight: "500px",
        padding: "20px 40px",
        marginTop: "10px",
        overflow: "auto",
        "& >div": {
          background: "#9A877D 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          opacity: 1,
          display: "block",
          padding: "12px 30px",
          marginTop: "10px",
          "& >p": {
            font: "600 12px/21px Noto Sans JP, Hiragino Sans",
            letterSpacing: "0.3px",
            color: "#FFFFFF",
            opacity: 1,
            display: "inline-block",
            margin: 0
          },
          "& >button": {
            float: "right",
            color: "#fff",
            padding: 0
          }
        }
      },
    }
  },
  ul: {
    "& >div": {
      font: "300 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      borderBottom: "0.6000000238418579px solid #C2C2C2",
      padding: "14px 0",
      "&:hover": {
        font: "300 10px/16px Noto Sans JP, Hiragino Sans",
        color: "#1A0707 !important",
        background: "#ec681a3b 0% 0% no-repeat padding-box",
        cursor: "pointer"
      }
    },
  },
});

