import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UPDATE_PROJECT_ITEM } from './redux/actions';
import ProjectList from './ProductProjectList';
import ProjectForm from './ProductProjectForm';
import ProjectListTools from './ProductProjectListTools';
import { thunkFetchProjects, thunkDeleteProject } from './redux/thunk'


const propTypes = {
  itemsLoading: PropTypes.bool.isRequired,
  itemsLoaded: PropTypes.bool.isRequired,
  updateProjectItem: PropTypes.func.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  deactivate: PropTypes.func.isRequired,
};

const ProductProjects = ({
  itemsLoading,
  itemsLoaded,
  updateProjectItem,
  fetchProjects,
  deactivate,
}) => {
  const [page, setPage] = useState(1);
  const [showInactiveItems, setShowInactiveItems] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const firstTimeRefresh = useRef(true);

  const getSearchParams = () => {
    const searchParams = {};
    if (showInactiveItems) searchParams.status = "inactive";
    return searchParams;
  }

  const fetchData = () => {
    fetchProjects(page, getSearchParams());
  }

  useEffect(() => {
    if (firstTimeRefresh.current === false) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [page, showInactiveItems]);

  useEffect(() => {
    if ((!itemsLoading && !itemsLoaded) || firstTimeRefresh.current === true) {
      fetchProjects();
      firstTimeRefresh.current = false;
    }

    // eslint-disable-next-line
  }, [itemsLoading, itemsLoaded]);

  const openDrawer = (item) => {
    updateProjectItem(item);
    setDrawerIsOpen(true);
  };

  const closeDrawer = () => {
    updateProjectItem(null)
    setDrawerIsOpen(false)
  }

  return (
    <Fragment>
      <ProjectListTools showInactiveItems={showInactiveItems} setShowInactiveItems={setShowInactiveItems} />
      <ProjectForm modalOpen={drawerIsOpen} closeModal={closeDrawer} deactivate={deactivate}/>
      <ProjectList page={page} setPage={setPage} openDrawer={openDrawer} showInactiveItems={showInactiveItems} deactivate={deactivate}/>
    </Fragment>
  )
}

ProductProjects.propTypes = propTypes;

export default connect(
  ({ projects: { loading, loaded } }) => ({
    itemsLoading: loading,
    itemsLoaded: loaded,
  }),
  (dispatch) => ({
    updateProjectItem: (item) => dispatch({ type: UPDATE_PROJECT_ITEM, payload: { item } } ),
    fetchProjects: (page=null, searchParams) => dispatch(thunkFetchProjects(page, searchParams)),
    deactivate: id => dispatch(thunkDeleteProject({ id })),
  })
)(ProductProjects);
