import {
  FETCH_PUBLIC_SURVEY_BEGIN,
  FETCH_PUBLIC_SURVEY_SUCCESS,
  FETCH_PUBLIC_SURVEY_FAILURE,

  FETCH_PUBLIC_AREAS_BEGIN,
  FETCH_PUBLIC_AREAS_SUCCESS,
  FETCH_PUBLIC_AREAS_FAILURE,

  FETCH_PUBLIC_SHOPS_BEGIN,
  FETCH_PUBLIC_SHOPS_SUCCESS,
  FETCH_PUBLIC_SHOPS_FAILURE,

  FETCH_PUBLIC_USERS_BEGIN,
  FETCH_PUBLIC_USERS_SUCCESS,
  FETCH_PUBLIC_USERS_FAILURE,

  PUBLIC_SURVEY_NEXT_PAGE,
  PUBLIC_SURVEY_PREV_PAGE,

  PUBLIC_GO_TO_PAGE,
  PUBLIC_SAVE_FEEDBACK_SUCCESS,

  PUBLIC_SET_PARAMS,
} from './actions';

import { parseSurvey } from 'modules/surveys/utils';
import { Storage, deepCopy } from 'utils';
import { searchCondition } from "../../surveys/form/utils";

const initialState = {
  uniqueSurveyKey: '',

  survey: null,
  surveyLoading: false,
  error: null,

  areas: [],
  areasLoading: false,

  shops: [],
  shopsLoading: false,

  currentPage: "start",
  visitedPages: ["start"],
  autovalidate: false,

  users: [],
  usersLoading: false,
  usersLoaded: false,
  usersLoadingError: null,

  doc: {},
  meta: {},
  isPreviewMode: false,
  dataState: 'not_saved',
  survey_type: '0',
  selectedParams: { name:'', imageUrl:'' }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PUBLIC_SURVEY_BEGIN: {
      return {
        ...state,
        survey: null,
        surveyLoading: true,
        error: null,
        uniqueSurveyKey: '',
      };
    }

    case FETCH_PUBLIC_SURVEY_SUCCESS: {
      const { survey, isPreviewMode } = action.payload;
      const uniqueSurveyKey = `${survey.data.id}-${window.location.href}` // survey_id + full url
      const storedDoc = !isPreviewMode ? Storage.getFeedback(uniqueSurveyKey) || {} : {};

      const surveyData = parseSurvey(survey, false);

      let currentPage = (storedDoc.meta || state).currentPage;
      let visitedPages = (storedDoc.meta || state).visitedPages;
      if (currentPage !== "start" && currentPage !== "end" && currentPage !== 'finish' && !!!surveyData.survey_pages_attributes[currentPage]) {
        currentPage = "start";
        visitedPages = ["start"];
      }

      const surveyPages = surveyData.survey_pages_attributes;

      for (let i = 0; i < surveyPages.length; i ++) {
        for (let j = 0; j < surveyPages[i].question_items.length; j ++) {
          const questionItem = surveyPages[i].question_items[j];
          questionItem.storedAnswer = (storedDoc.answers_attributes || []).find(item => item.question_id === questionItem.id);
        }
      }

      return {
        ...state,
        uniqueSurveyKey,
        surveyLoading: false,
        error: null,
        survey: surveyData,
        doc: storedDoc,
        autovalidate: (storedDoc.meta || {}).autovalidate || false,
        meta: {
          area: {
            id: (storedDoc.area || {}).id || '',
            name: (storedDoc.area || {}).name || '',
          },
          shop: {
            id: (storedDoc.shop || {}).id || '',
            uid: (storedDoc.shop || {}).uid || '',
            name: (storedDoc.shop || {}).name || '',
          },
        },
        currentPage,
        visitedPages,
        isPreviewMode,
      };
    }

    case FETCH_PUBLIC_SURVEY_FAILURE: {
      return {
        ...state,
        surveyLoading: false,
        error: action.payload.error,
        survey: null
      };
    }

    case FETCH_PUBLIC_AREAS_BEGIN: {
      return {
        ...state,
        areasLoading: true,
        error: null
      };
    }

    case FETCH_PUBLIC_AREAS_SUCCESS: {
      return {
        ...state,
        areas: action.payload.areas,
        areasLoading: false,
      }
    }

    case FETCH_PUBLIC_AREAS_FAILURE: {
      return {
        ...state,
        areasLoading: false,
        error: action.payload.error,
        areas: null
      };
    }

    case FETCH_PUBLIC_SHOPS_BEGIN: {
      return {
        ...state,
        shopsLoading: true,
        error: null
      };
    }

    case FETCH_PUBLIC_SHOPS_SUCCESS: {
      return {
        ...state,
        shops: action.payload.shops.data,
        shopsLoading: false,
      }
    }

    case FETCH_PUBLIC_SHOPS_FAILURE: {
      return {
        ...state,
        shopsLoading: false,
        error: action.payload.error,
        shops: null
      };
    }

    case FETCH_PUBLIC_USERS_BEGIN: {
      return {
        ...state,
        usersLoading: true,
        usersLoaded: false,
        usersLoadingError: null
      };
    }

    case FETCH_PUBLIC_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.items.data,
        usersLoading: false,
        usersLoaded: true,
        usersLoadingError: null,
      }
    }

    case FETCH_PUBLIC_USERS_FAILURE: {
      return {
        ...state,
        usersLoading: false,
        usersLoaded: false,
        usersLoadingError: action.payload.error,
        users: null
      };
    }

    case PUBLIC_SURVEY_NEXT_PAGE: {
      let visitedPages = state.visitedPages;

      const getNextPage = (nextPage) => {
        let answers_options_attributes = [];

        let visitedPageIdxs = deepCopy(state.visitedPages)
          .filter(item => item !== "start" && item !== "end" && item !== "finish")
          .map(item => +item)
          .sort((a, b) => a - b);

        visitedPageIdxs = visitedPageIdxs.splice(0, visitedPageIdxs.indexOf(+state.currentPage) + 1);

        let answeredQuestionIds = [];

        visitedPageIdxs.forEach(item => {
          const questionItems = ((state.survey || {}).survey_pages_attributes || [])[+item].question_items;
          const questionIds = questionItems.map(item => +item.id);
          answeredQuestionIds = answeredQuestionIds.concat(questionIds);
        });

        (state.doc.answers_attributes || [])
          .filter(answer => answeredQuestionIds.includes(+answer.question_id))
          .forEach(answers => {
            answers_options_attributes = answers_options_attributes.concat(answers.answer_options_attributes || []);
          });

        let canMoveToNextPage = false;

        do {
          if (nextPage === 'start') {
            nextPage = '0';
            break;
          } else if (nextPage === 'end') {
            nextPage = 'finish';
            break;
          } else {
            nextPage = parseInt(nextPage) >= (state.survey.survey_pages_attributes.length - 1) ? state.survey.use_confirm_page ? "end" : "finish" : String(parseInt(nextPage) + 1);

            if (nextPage === 'end' || nextPage === 'finish') break;
            const nextPageConditions = state.survey.survey_pages_attributes[nextPage].survey_page_display_conditions_attributes || [];

            if (nextPageConditions.length === 0) break;

            const conditionAnswers = answers_options_attributes
              .filter(({ question_id }) => !!nextPageConditions.find(condition => +question_id === +condition.question_id))
              .map(({ question_id, question_option_id }) => !!nextPageConditions.find(condition => +question_id === +condition.question_id && +question_option_id === +condition.question_option_id));
            
            canMoveToNextPage = conditionAnswers.filter(found => found).length > 0;

            // Check shops selection condition
            const shopConditions = nextPageConditions.filter(c => c.shop_id)
            if (shopConditions.length > 0) {
              let validConditionExist = false
              let conditionMet = false
              const areas = state.areas.length > 0 ? state.areas : [{ id: '', shops: { data: state.shops } }]
              for (const condition of shopConditions) {
                if (searchCondition(condition, areas)) {
                  validConditionExist = true
                  if (+condition.area_id === +state.doc.area.id && +condition.shop_id === +state.doc.shop.id) {
                    conditionMet = true
                    break
                  }
                }
              }
              if (validConditionExist) {
                canMoveToNextPage = conditionMet
              }
            }
            // eslint-disable-next-line
            visitedPages = !canMoveToNextPage && visitedPages.includes(nextPage) ? visitedPages.filter(item => item !== nextPage) : visitedPages;
          }
        } while (!canMoveToNextPage)

        return nextPage;
      }

      const nextPage = getNextPage(state.currentPage);
      visitedPages = !visitedPages.includes(nextPage) ? [...visitedPages, nextPage] : visitedPages;

      if (!state.isPreviewMode) {
        state.doc = state.doc || {};
        state.doc.meta = state.doc.meta || {};
        state.doc.meta.currentPage = nextPage;
        state.doc.meta.visitedPages = visitedPages;
        state.doc.meta.autovalidate = false;

        Storage.setFeedback(state.doc, state.uniqueSurveyKey);
      }

      if (!['start', 'finish', 'end'].includes(nextPage)) {
        const surveyPages = ((state.survey || {}).survey_pages_attributes || []);
        for (let j = 0; j < surveyPages[+nextPage].question_items.length; j ++) {
          const questionItem = surveyPages[+nextPage].question_items[j];
          questionItem.storedAnswer = (state.doc.answers_attributes || []).find(item => item.question_id === questionItem.id);
        }
      }

      return {
        ...state,
        currentPage: nextPage,
        visitedPages,
        autovalidate: false,
      };
    }

    case PUBLIC_SURVEY_PREV_PAGE: {
      const getPrevPage = (prevPage) => {
        let answers_options_attributes = [];
        
        let visitedPageIdxs = deepCopy(state.visitedPages)
          .filter(item => item !== "start" && item !== "end" && item !== "finish")
          .map(item => +item)
          .sort((a, b) => a - b);

        visitedPageIdxs = visitedPageIdxs.splice(0, visitedPageIdxs.indexOf(+state.currentPage) + 1);

        let answeredQuestionIds = [];

        visitedPageIdxs.forEach(item => {
          const questionItems = ((state.survey || {}).survey_pages_attributes || [])[+item].question_items;
          const questionIds = questionItems.map(item => +item.id);
          answeredQuestionIds = answeredQuestionIds.concat(questionIds);
        });

        (state.doc.answers_attributes || [])
          .filter(answer => answeredQuestionIds.includes(+answer.question_id))
          .forEach(answers => {
            answers_options_attributes = answers_options_attributes.concat(answers.answer_options_attributes || []);
          });

        let canMoveToPrevPage = false;

        do {
          prevPage = prevPage === 'end' ? String(state.survey.survey_pages_attributes.length - 1) : (prevPage !== '0' ? ("" + (parseInt(prevPage) - 1)) : "start");

          if (prevPage === 'start') break;
          const prevPageConditions = state.survey.survey_pages_attributes[prevPage].survey_page_display_conditions_attributes || [];

          if (prevPageConditions.length === 0) break;

          const conditionAnswers = answers_options_attributes
            .filter(({ question_id }) => !!prevPageConditions.find(condition => +question_id === +condition.question_id))
            .map(({ question_id, question_option_id }) => !!prevPageConditions.find(condition => +question_id === +condition.question_id && +question_option_id === +condition.question_option_id));

          canMoveToPrevPage = conditionAnswers.filter(found => found).length > 0;

        } while (!canMoveToPrevPage)

        return prevPage;
      }

      const prevPage = getPrevPage(state.currentPage);

      if (!state.isPreviewMode) {
        state.doc = state.doc || {};
        state.doc.meta = state.doc.meta || {};
        state.doc.meta.currentPage = prevPage
        state.doc.meta.autovalidate = false;

        Storage.setFeedback(state.doc, state.uniqueSurveyKey);
      }


      if (!['start', 'finish', 'end'].includes(prevPage)) {
        const surveyPages = ((state.survey || {}).survey_pages_attributes || []);

        for (let j = 0; j < surveyPages[+prevPage].question_items.length; j ++) {
          const questionItem = surveyPages[+prevPage].question_items[j];
          questionItem.storedAnswer = (state.doc.answers_attributes || []).find(item => item.question_id === questionItem.id);
        }
      }


      return {
        ...state,
        currentPage: prevPage,
        autovalidate: false,
      };
    }

    case PUBLIC_GO_TO_PAGE: {
      const page = String(action.payload.page);

      if (!state.isPreviewMode) {
        state.doc = state.doc || {};
        state.doc.meta = state.doc.meta || {};
        state.doc.meta.currentPage = page;
        state.doc.meta.autovalidate = false;
  
        Storage.setFeedback(state.doc, state.uniqueSurveyKey);
      }

      if (!['start', 'finish', 'end'].includes(page)) {
        const surveyPages = ((state.survey || {}).survey_pages_attributes || []);

        for (let j = 0; j < surveyPages[+page].question_items.length; j ++) {
          const questionItem = surveyPages[+page].question_items[j];
          questionItem.storedAnswer = (state.doc.answers_attributes || []).find(item => item.question_id === questionItem.id);
        }
      }


      return {
        ...state,
        currentPage: page,
        autovalidate: false,
      };
    }

    case 'UPDATE_FEEDBACK_DOC': {
      const { params } = action.payload;

      const updatedDoc = {
        ...state.doc,
        ...params,
        answers_attributes: state.doc.answers_attributes,
      };

      updatedDoc.answers_attributes = updatedDoc.answers_attributes || [];

      if (!!params.answers_attributes) {
        // remove updated answers from the doc
        for (let i = 0; i < params.answers_attributes.length; i ++) {
          updatedDoc.answers_attributes = updatedDoc.answers_attributes.filter(item => item.question_id !== params.answers_attributes[i].question_id);
        }

        // remove delete images
        params.answers_attributes = params.answers_attributes.filter(item => item.answer_image !== null);

        // append updated answers to doc
        updatedDoc.answers_attributes = [...updatedDoc.answers_attributes, ...params.answers_attributes];
      }

      for (let key of Object.keys(updatedDoc)) {
        if (!!!updatedDoc[key]) delete updatedDoc[key];
      }

      if (!state.isPreviewMode) {
        Storage.setFeedback(updatedDoc, state.uniqueSurveyKey);
      }

      return {
        ...state,
        doc: updatedDoc,
        autovalidate: (updatedDoc.meta || {}).autovalidate || false,
      };
    }

    case 'UPDATE_DOC_META': {
      const { params } = action.payload;

      return {
        ...state,
        doc: {
          ...state.doc,
          ...params,
        },
        meta: {
          ...params,
        }
      };
    }

    case 'SET_TO_AUTOVALIDATE': {
      state.autovalidate = true;

      if (!state.isPreviewMode) {
        Storage.setFeedback(state.doc, state.uniqueSurveyKey);
        state.doc.meta.autovalidate = state.autovalidate;
      }

      return state;
    }

    case PUBLIC_SAVE_FEEDBACK_SUCCESS: {
      if (!state.isPreviewMode) {
        Storage.setFeedback({}, state.uniqueSurveyKey);
      }

      !!state.doc && delete state.doc;

      return {
        ...state,
        dataState: 'saved',
      }
    }

    case PUBLIC_SET_PARAMS: {
      return {
        ...state,
        selectedParams: {
          name: action.payload.name,
          imageUrl: action.payload.imageUrl
        }
      }
    }
    default:
      return state;
  }

}
