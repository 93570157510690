import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import {
  Button,
  Typography,
  makeStyles,
  Dialog, DialogTitle, DialogContent, Paper, CircularProgress, Grid,
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import { thunkFetchAreas, thunkFetchShops } from '../../redux/thunk';
import { PUBLIC_SURVEY_NEXT_PAGE, PUBLIC_SET_PARAMS } from '../../redux/actions';
import { themedStyles } from "./styles";
import { traverseFindWithShop } from 'modules/areas/utils';
import { convertToListAndParse } from 'modules/utils';
import DialogTreeSelect from 'components/Select/DialogTreeSelect';
import { useGAEffect } from 'ga';
import { htmlCss } from "../utils";
import SurveyFooter from "../SurveyPage/SurveyFooter";
import { WrappedText } from '../../../feedbacks/FeedbackDetail'


const colorComon = "#EC681A";

const useStyles = makeStyles(themedStyles);

const cssCommon = `
  .textHeader {
    padding: 20px 10px;
    background-color: #EC681A;
    color: #fff;
    margin: 0;
    font-size: 13px;
  }
`;

const cssSettingStartPage = `
  .textHeader {
    padding: 20px 10px;
    background-color: #EC681A;
    color: #fff;
    margin: 0;
    font-size: 13px;
  }
`;

const propTypes = {
  survey: PropTypes.object.isRequired,
  areasR: PropTypes.object.isRequired,
  shopsR: PropTypes.object.isRequired,
  loadAreas: PropTypes.func.isRequired,
  loadShops: PropTypes.func.isRequired,
  startSurvey: PropTypes.func.isRequired,
  updateMeta: PropTypes.func.isRequired,
  setParams: PropTypes.func,
  area_id: PropTypes.string,
  stAreaId: PropTypes.any,
  shop_id: PropTypes.string,
  stShopId: PropTypes.any,
  companyUrlText: PropTypes.string,
  shortUrl: PropTypes.string,
  public_ga: PropTypes.object.isRequired,
}

const StartPage = ({
  survey,
  areasR,
  shopsR,
  loadAreas,
  loadShops,
  startSurvey,
  updateMeta,
  setParams,
  area_id,
  stAreaId,
  shop_id,
  stShopId,
  companyUrlText,
  shortUrl,
  public_ga,
  setValid,
}) => {
  const styleProps = { color: colorComon };
  const classes = useStyles(styleProps);

  const [areaId, setAreaId] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [uiState, setUIState] = useState({ areaSelect: true, shopSelect: true });
  const [visible, setVisible] = useState(null);
  const [areasFetched, setAreasFetched] = useState(false);
  const [shopsFetched, setShopsFetched] = useState(false);
  const [showAreasSection, setShowAreasSection] = useState(!!survey.area_id);
  const [areasLoaded, setAreasLoaded] = useState(false);
  const [shopsLoaded, setShopsLoaded] = useState(false);
  const [headerHeigh, setHeaderHeigh] = useState(0);
  const [titleHeight, setTitleHeigh] = useState(0);
  const shopOptions = useRef([]);
  const paramSelectedArea = useRef(null);
  const paramSelectedShop = useRef(null);
  const selectedArea = useRef(null);
  const selectedShop = useRef(null);
  const [enableLoading, setenableLoading] = useState(true);

  useGAEffect(public_ga, "start", "回答開始", null)

  useEffect(() => {
    if (areasR.allItemsLoading) {
      setAreasFetched(true);
    }
  }, [areasR.allItemsLoading]);

  useEffect(() => {
    if (shopsR.allItemsLoading) {
      setShopsFetched(true);
    }
  }, [shopsR.allItemsLoading]);

  useEffect(() => {
    if (!!survey.area_id) {
      loadAreas(companyUrlText, shortUrl);
      setUIState({ areaSelect: true, shopSelect: false });
    } else {
      loadShops(companyUrlText, shortUrl);
      setUIState({ areaSelect: false, shopSelect: true });
    }
    // eslint-disable-next-line
  }, [survey.area_id]);

  useEffect(() => {
    if (areasFetched && areasR.allItemsLoading === false) {
      setAreasLoaded(true);

      if (areasR.allItems.length === 0) {
        setShowAreasSection(false);
        loadShops(companyUrlText, shortUrl, survey.area_id);
        setUIState({ areaSelect: false, shopSelect: true });
      }

      // Check if area_id exists in belonging subareas
      const found = traverseFindWithShop(areasR.allItems, +area_id, shop_id)[0]
      const [_a, _s] = !!found ? found : [null, null];
      if (area_id) setValid(!!_a)
      if (shop_id !== undefined) {
        setValid(!!_s)
      } else {
        setValid(!!!survey.shop_required)
      }
    }
    // eslint-disable-next-line
  }, [areasFetched, areasR.allItemsLoading, areasR.allItems.length]);

  useEffect(() => {
    if (shopsFetched && shopsR.allItemsLoading === false) {
      setShopsLoaded(true);

      if (!showAreasSection) {
        shopOptions.current = shopsR.allItems;
      }

      // Check if shop_id exists in belonging shops
      if (shop_id !== undefined) {
        setValid(!!shopOptions.current.find(({ attributes: { uid } }) => uid === shop_id))
      } else {
        setValid(!!!survey.shop_required)
      }
    }
    // eslint-disable-next-line
  }, [shopsFetched, shopsR.allItemsLoading, showAreasSection]);


  useEffect(() => {
    if (areasLoaded || (showAreasSection === false && shopsLoaded)) {
      const found = showAreasSection ? traverseFindWithShop(areasR.allItems, +area_id, shop_id)[0] : [null, shopOptions.current.find(({ attributes: { uid } }) => uid === shop_id)];
      [paramSelectedArea.current, paramSelectedShop.current] = !!found ? found : [null, null];

      if (!!paramSelectedArea.current) {
        shopOptions.current = paramSelectedArea.current.shops.data;
        if (paramSelectedShop.current === null) {
          paramSelectedShop.current = shopOptions.current.find(({ attributes: { uid } }) => uid === shop_id);
        }
      }

      if ((!!paramSelectedArea.current && !!paramSelectedArea.current.id) || (!!paramSelectedShop.current && !!paramSelectedShop.current.attributes.uid)) {
        !!paramSelectedArea.current && !!paramSelectedArea.current.id && setAreaId(paramSelectedArea.current.id);
        !!paramSelectedShop.current && !!paramSelectedShop.current.attributes.uid && setShopId(paramSelectedShop.current.attributes.uid);
      } else {
        setAreaId(stAreaId);
        setShopId(stShopId);
      }
    }

    // eslint-disable-next-line
  }, [area_id, shop_id, areasLoaded, showAreasSection, shopsLoaded])

  useEffect(() => {
    if (areaId === null && shopId === null) return;

    if (areasLoaded || (showAreasSection === false && shopsLoaded)) {
      const found = showAreasSection ? traverseFindWithShop(areasR.allItems, areaId, shopId)[0] : [null, shopOptions.current.find(({ attributes: { uid } }) => uid === shopId)];
      [selectedArea.current, selectedShop.current] = !!found ? found : [null, null];

      if (!!selectedArea.current) {
        shopOptions.current = selectedArea.current.shops.data;
        if (selectedShop.current === null) {
          selectedShop.current = shopOptions.current.find(({ attributes: { uid } }) => uid === shopId);
        }
      }

      updateMeta({
        area: {
          id: !!selectedArea.current ? +selectedArea.current.id : '',
          name: !!selectedArea.current ? selectedArea.current.name : '',
        },
        shop: {
          id: !!selectedShop.current ? +selectedShop.current.id : '',
          name: !!selectedShop.current ? selectedShop.current.attributes.name : '',
          uid: !!selectedShop.current ? selectedShop.current.attributes.uid : '',
          image_url: !!selectedShop.current ? selectedShop.current.attributes.image_url : '',
        }
      });

      setUIState({
        areaSelect: !(!!paramSelectedArea.current && +paramSelectedArea.current.id === +areaId),
        shopSelect: !(!!paramSelectedShop.current && paramSelectedShop.current.attributes.uid === shopId && ((!!paramSelectedArea.current && paramSelectedArea.current.id === +areaId) || !showAreasSection))
      });
      setParams(!!selectedShop.current ?
          selectedShop.current.attributes.image_url? 
          {name: selectedShop.current.attributes.name , imageUrl: selectedShop.current.attributes.image_url}
          :
          {name: selectedShop.current.attributes.name , imageUrl: `${process.env.PUBLIC_URL}/noimg.png`}
        :{name:'', imageUrl: `${process.env.PUBLIC_URL}/noimg.png`}
      )
    }

    setenableLoading(false);

    // eslint-disable-next-line
  }, [areaId, shopId, areasLoaded, showAreasSection, shopsLoaded])

  const areaOptions = convertToListAndParse(areasR.allItems || [])

  const handleStartSurvey = () => {
    
    startSurvey();
  };


  const openDialog = type => setVisible(type);
  const closeDialog = () => setVisible(null);
  const handleSelectArea = ({ value }) => {
    setAreaId(value);
    setShopId(null);
    closeDialog();
  };
  const handleSelectShop = ({ value }) => {
    setShopId(value);
    closeDialog();
  };
  const renderBody = () => {
    if (!survey.use_start_page_html) {
      return (
        <>
          {survey.start_page_main_image_url && (
            <div className={classes.imgStyle}>
              <img className={classes.img} src={survey.start_page_main_image_url} alt={""} />
            </div>
          )}
          {renderHTML(htmlCss(survey.start_page_custom_css || cssSettingStartPage))}
        </>
      )
    } else {
      return (
        <>
          {renderHTML(survey.start_page_html)}
          {renderHTML(htmlCss(survey.start_page_custom_css || cssSettingStartPage))}
        </>
      );
    }
  }

  const renderAreaSection = () => {
    return (
      <>
        {uiState.areaSelect === true && !areasR.allItemsLoading && (
          <div className={classes.selectAnswer}>
            <Typography className={classes.txtLabel}>
              {survey.survey_type === '0'?
                <> エリアを選択してください。</>
                :
                <>カテゴリを選択してください。</>
              }
            </Typography>
            <Paper component="form" className={classes.root} onClick={() => openDialog("area")}>
              <InputBase
                className={classes.input}
                placeholder={survey.survey_type === '0'? "エリアを選択" : "カテゴリを選択"}
                readOnly
                value={!!selectedArea.current ? selectedArea.current.name : ''}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                <i className="el-icon-caret-bottom"></i>
              </IconButton>
            </Paper>
          </div>
        )}
        {uiState.areaSelect === false && !!selectedArea.current && (
          <div className={classes.selectAnswer}>
            <Typography className={classes.txtLabel}>
              {survey.survey_type === '0'?
                  <> エリアを選択してください。</>
                  :
                  <>カテゴリを選択してください。</>
                }
            </Typography>
            <Typography className={classes.txtLabel}>
              {selectedArea.current.name}
            </Typography>
          </div>
        )}
      </>
    );
  }

  const renderShopSection = () => {
    
    return (
      <>
        {uiState.shopSelect === true && (
          <div className={classes.selectAnswer}>
            <Typography className={classes.txtLabel}>
              {survey.survey_type === '0'?
                <> このたびご利用になった店舗はどちらでしょうか？</>
                :
                <>このたびご購入になった商品はどちらでしょうか？</>
              }
            </Typography>
            <Paper component="form" className={classes.root} onClick={() => openDialog("shop")}>
              <InputBase
                className={classes.input}
                placeholder={survey.survey_type === '0'? "店舗を選択" : "商品を選択"}
                readOnly
                value={selectedShop.current ? selectedShop.current.attributes.name : ""}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                <i className="el-icon-caret-bottom"></i>
              </IconButton>
            </Paper>
          </div>
        )}
        {uiState.shopSelect === false && !!selectedShop.current && (
          <div className={classes.selectAnswer}>
            <Typography className={classes.txtLabel}>
              {survey.survey_type === '0'?
                <> このたびご利用になった店舗はどちらでしょうか？</>
                :
                <>このたびご購入になった商品はどちらでしょうか？</>
              }
            </Typography>
            <Typography className={classes.txtLabel}>
              {selectedShop.current.attributes.name}
            </Typography>
          </div>
        )}

      </>
    );
  }

  if (enableLoading) {
    return (
      <div className={classes.iconLoading}>
        <Paper>
          <div className={classes.loading}>
            <CircularProgress disableShrink />
          </div>
        </Paper>
      </div>
    );
  }

  return (
    <div className={classes.rootAnswer}>
      <Paper
        className={classes.headerPaper}
        ref={el => {
          if (!el) return;
          setHeaderHeigh(el.getBoundingClientRect().height)
          const imgs = el.getElementsByTagName('img')
          imgs.forEach(img => {
            img.onload = () => {
              setHeaderHeigh(el.getBoundingClientRect().height)
            }
          })
        }}
      >
        <div className={classes.headerContent}>
          {renderHTML(survey.common_header)}
          {renderHTML(htmlCss(survey.common_css || cssCommon))}
        </div>
      </Paper>

      <div className={classes.textBox} style={{ marginTop: headerHeigh }}
        ref={element => {
          if (!element) return;
          setTitleHeigh(element.getBoundingClientRect().height)
        }}
      >
        <div className={classes.text}>
          {renderBody()}
          <p className={classes.textBegin}>{survey.page_title}</p>
          {!survey.use_start_page_html &&
            <div>
              <pre className={classes.preText}>
                {survey.start_page_main_text}
              </pre>
            </div>
          }
        </div>
      </div>

      <div
        className={titleHeight > 70 ? classes.slAnswer : clsx(classes.slAnswer, classes.centerPage)}
        style={{ height: titleHeight > 70 ? 'auto' : `calc(100% - ${titleHeight + headerHeigh}px)` }}
      >
        <div>
          {showAreasSection && renderAreaSection()}
          {renderShopSection()}
          <div className={classes.button}>
            {!!selectedShop.current &&
              <Button
                className={classes.bnAnswer}
                variant="outlined"
                onClick={handleStartSurvey}
              >
                <div>
                  {survey.start_page_button_name || "アンケートを開始する"}
                </div>
              </Button>
            }
          </div>
        </div>
      </div>

      {visible === "shop" && (
        <Dialog onClose={closeDialog} aria-labelledby="simple-dialog-title" open={true} className={classes.dialogContainer}>
          <DialogTitle id="simple-dialog-title">
            {survey.survey_type === '0'?
              <p className={classes.titleDialog}>店舗を選択してください。</p>
              :
              <p className={classes.titleDialog}>商品を選択してください。</p>
            }
          </DialogTitle>
          {survey.survey_type === '0'?
            <DialogContent>
              {shopOptions.current && shopOptions.current.length > 0 && shopOptions.current.map(shopEl => (
                <p
                  className={shopId === shopEl.attributes.uid ? clsx(classes.optionItem, classes.activedItem) : classes.optionItem}
                  key={shopEl.attributes.uid}
                  onClick={() => handleSelectShop({ value: shopEl.attributes.uid, label: shopEl.attributes.name})}
                >
                  <WrappedText>
                  {shopEl.attributes.name}
                  </WrappedText>
                </p>
              ))}
            </DialogContent>
            :
            <DialogContent>
              <Grid container spacing={2} alignItems="stretch">
                {shopOptions.current && shopOptions.current.length > 0 && shopOptions.current.map(shopEl => (
                  <Grid 
                    key={shopEl.attributes.uid}
                    item 
                    xs={6}
                    className={shopId === shopEl.attributes.uid ? clsx(classes.productOptionItem, classes.activedProductItem) : classes.productOptionItem}
                    onClick={() => handleSelectShop({ value: shopEl.attributes.uid, label: shopEl.attributes.name})}
                    >
                    {shopEl.attributes.image_url?
                      <img src= {shopEl.attributes.image_url} alt="shop_image" onError={(e) => e.target.src = `${process.env.PUBLIC_URL}/noimg.png`}/>
                      :
                      <img src= {`${process.env.PUBLIC_URL}/noimg.png`} alt="shop_image" onError={(e) => e.target.src = `${process.env.PUBLIC_URL}/noimg.png`}/>
                    }
                    <WrappedText>
                    {shopEl.attributes.name}
                    </WrappedText>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
          }
        </Dialog>
      )}
      {visible === "area" &&
        <DialogTreeSelect
          title= {survey.survey_type === '0'? "エリアを選択してください。" : "カテゴリを選択してください。" }
          itemSelected={selectedArea || {}}
          handleClose={closeDialog}
          handleSelect={handleSelectArea}
          options={areaOptions}
          disableSelectParent
        />
      }
      <SurveyFooter classes={classes} survey={survey} defaultCss={cssCommon}/>
    </div>
  )
}

StartPage.propTypes = propTypes;

export default withRouter(connect(
  ({
    public: {
      survey,
      surveyLoading,
      areas,
      areasLoading,
      shops,
      shopsLoading,
      doc,
    },
    public_ga,
  }, {
    area_id,
    shop_id,
    match,
  }) => {
    const { companyUrlText, shortUrl } = match.params;

    return {
      survey,
      surveyLoading,
      areasR: {
        allItems: areas,
        allItemsLoading: areasLoading,
      },
      shopsR: {
        allItems: shops,
        allItemsLoading: shopsLoading
      },
      area_id,
      stAreaId: (doc.area || {}).uid || '',
      shop_id,
      stShopId: (doc.shop || {}).uid || '',
      companyUrlText,
      shortUrl,
      public_ga,
    };
  },
  (dispatch) => ({
    loadAreas: (companyUrl, shortUrl) => {
      dispatch(thunkFetchAreas(companyUrl, shortUrl))
    },
    loadShops: (companyUrl, shortUrl, areaId) => {
      dispatch(thunkFetchShops(companyUrl, shortUrl, areaId))
    },
    startSurvey: () => dispatch({ type: PUBLIC_SURVEY_NEXT_PAGE, payload: {} }),
    updateMeta: (params) => {
      dispatch({ type: 'UPDATE_DOC_META', payload: { params } })
    },
    setParams: (params)=>{
      dispatch({ type: PUBLIC_SET_PARAMS, payload: params })
    }
  })
)(StartPage));
