import {
  FETCH_ANSWERS_BEGIN,
  FETCH_ANSWERS_SUCCESS,
  FETCH_ANSWERS_FAILURE,
  EDIT_ANSWER,
  OPEN_ANSWER_DIALOG,
  CLOSE_ANSWER_DIALOG,
} from '../actions/answer'

const initialState = {
  items: [],
  loading: false,
  error: null,
  dialogOpen: false,
  answer: null,
}

export default (state = initialState, action) => {
  switch(action.type) {
    case FETCH_ANSWERS_BEGIN: {
      return {
        ...state,
        loading: true,
        items: [],
        error: null,
      }
    }
    case FETCH_ANSWERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: action.payload.answers,
      }
    }
    case FETCH_ANSWERS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }
    case EDIT_ANSWER: {
      const { answer } = action.payload
      return {
        ...state,
        answer: null,
        items: state.items.map(item => item.id === answer.id ? answer : item),
        error: null,
        dialogOpen: false,
      }
    }
    case OPEN_ANSWER_DIALOG: {
      return {
        ...state,
        dialogOpen: true,
        answer: action.payload.answer,
        error: null,
      }
    }
    case CLOSE_ANSWER_DIALOG: {
      return {
        ...state,
        dialogOpen: false,
        answer: null,
      }
    }
    default:
      return state
  }
};
