import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { DialogSelectStyles } from './styles';
import HtmlRender from './HtmlRender';

const propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  itemSelected: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

const useStyles = makeStyles(DialogSelectStyles);

const DialogSelect = props => {
  const { title, options, itemSelected } = props;
  const classes = useStyles();

  const handleSelect = item => {
    props.handleSelect(item);
    closeDialog();
  }
  const closeDialog = () => props.handleClose();

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="simple-dialog-title" open={true}
      PaperProps={{
        classes: { root: classes.dialogRoot }
      }}      
    >
      <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>
        <p className={classes.titleDialog}>{title}</p>
      </DialogTitle>
      <DialogContent>
        {options.length > 0 && options.map(el => (
          <p
            className={(itemSelected && itemSelected.value === el.value) ? clsx(classes.optionItem, classes.activedItem) : classes.optionItem}
            key={el.id}
            onClick={() => handleSelect(el)}
          >
            <HtmlRender
              value = {el.label}
            />
          </p>
        ))}
      </DialogContent>
    </Dialog>
  )
}

DialogSelect.propTypes = propTypes;

export default DialogSelect;
