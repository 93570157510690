import React from 'react';
import PropTypes from 'prop-types';
import { Divider, makeStyles } from '@material-ui/core';
import HtmlRender from './HtmlRender';


const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: 40,
    background: "#EFEFEF",
    textAlign: "center",
    padding: "20px 15px 30px 15px",
    fontSize: "16px",
    backgroundColor:'#EFEFEF'
  },
  dividerStyle: {
    background: "#EC681A",
    width: "75px",
    margin: "0 auto",
    marginTop: "10px",
    height: "2px",
    [theme.breakpoints.down("md")]:{
      width: "50px"
    }
  },
}));

const propTypes = {
  questionItem: PropTypes.object.isRequired,
};

const QuestionHead = ({ questionItem }) =>  {
  const classes = useStyles();

  return (
    <div className={classes.heading}>
      <HtmlRender
        value = {questionItem.body}
      />
      <Divider variant="middle" className={classes.dividerStyle}/>
    </div>
  )
}

QuestionHead.propTypes = propTypes;

export default QuestionHead;
