import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  CircularProgress,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { conditionQuestionOptionsStyles } from './styles'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { UPDATE_PAGE_DISPLAY_CONDITIONS_ON_SHOPS } from '../redux/actions'

const useConditionQuestionOptionsStyles = makeStyles(conditionQuestionOptionsStyles)

const ShopCheckBox = ({ area_id, shop, refresh, page, spIndex, setRefresh }) => {
  const classes = useConditionQuestionOptionsStyles()
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (refresh === true) {
      setChecked(false)
    }
    setChecked(
      !!page.survey_page_display_conditions_attributes.find(
        item => !item._destroy && +item.area_id === +area_id && +item.shop_id === +shop.value
      )
    )
    // eslint-disable-next-line
  }, [refresh, page.survey_page_display_conditions_attributes])

  const updateCondition = checked => {
    setChecked(checked)
    setRefresh(false)
    dispatch({
      type: UPDATE_PAGE_DISPLAY_CONDITIONS_ON_SHOPS,
      payload: { survey_page_id: page.id, area_id, shop_id: shop.value, checked, spIndex },
    })
  }

  return (
    <Grid
      item
      sm={12}
      className={clsx(classes.blockCheckbox, checked && classes.blockCheckboxChecked)}>
      <Checkbox
        value={shop.value}
        icon={<i />}
        checkedIcon={<i className="el-icon-check" />}
        inputProps={{
          'aria-label': 'primary checkbox',
        }}
        checked={checked}
        onChange={e => updateCondition(e.target.checked)}
      />
      <p>{shop.label}</p>
    </Grid>
  )
}

const ConditionShops = ({ areaShops, page, refresh, setRefresh, spIndex }) => {
  if (!areaShops) return <CircularProgress />

  return (
    <>
      {areaShops.map((area, i) => (
        <ExpansionPanel key={i} className="expansionPanel">
          <ExpansionPanelSummary
            expandIcon={<i className="el-icon-arrow-right" />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <p>{area.label}</p>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails className="expansionDetail">
            {area.shops.map((shop, j) => (
              <ShopCheckBox
                key={j}
                shop={shop}
                area_id={area.value}
                refresh={refresh}
                page={page}
                spIndex={spIndex}
                setRefresh={setRefresh}
              />
            ))}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </>
  )
}

export default ConditionShops
