import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, TextField, MenuItem, Select } from '@material-ui/core';
import ElementIcon from "../../../common/icon/ElementIcon";
import { updateFeedbackAnswer } from './redux/thunk';
import { CLOSE_ANSWER_DIALOG } from './redux/actions/answer';
import {TYPE_NPS_REASON, TYPE_SELECT_USER, TYPE_TEXT_AREA} from './constants';
import {thunkFetchAllUsers} from "../users/redux/thunk";
import {updateFeedbackUser} from "./redux/actions/feedback";
import {extractValidationError} from "./utils";
import { feedbackAnswerEditTagStyle } from './styles';
import IconDelete from "../../../assets/icons/icon_delete_active.svg";

const propTypes = {
  answer: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  feedback: PropTypes.object,
  closeEditTag: PropTypes.func.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  answerEntry: PropTypes.string,
  error: PropTypes.object,
  searchUsers: PropTypes.func,
  updateUser: PropTypes.func,
};


const FeedbackAnswerEditTag = ({ answer, users, feedback, closeEditTag, updateAnswer, answerEntry, error, searchUsers, updateUser }) => {
  const [clean, setClean] = useState(true);
  const [answer_entry, setAnswerEntry] = useState(answerEntry);
  const { question_type, question } = answer;
  const { id, shop } = feedback || {};
  const option_users = [...users, { value: "", label: 'その他' }]
  const classes = feedbackAnswerEditTagStyle();

  useEffect(() => {
    setClean(true);
    setAnswerEntry(answerEntry);
  }, [answerEntry]);

  useEffect(() => {
    if (question_type === TYPE_SELECT_USER && shop){
      searchUsers(shop.id)
    }
  }, [shop, searchUsers, question_type]);

  const handleChange = e => {
    e.preventDefault();
    setClean(String(e.target.value) === String(answerEntry));
    setAnswerEntry(e.target.value);
  };

  const onSubmit = e => {
    e.preventDefault();
    if (answer.question_type === TYPE_SELECT_USER){
      updateAnswer({ id: answer.id, user_id: answer_entry });
      const user = option_users.find(({ value }) => value === answer_entry);
      if (user){
        updateUser(id, { id: user.value, name: user.label})
      }
    } else {
      updateAnswer({ id: answer.id, answer_entry });
    }
    closeEditTag();
  };

  const commonProps = {
    variant: "outlined",
    value: String(answer_entry),
    error: !!error,
    onChange: handleChange,
  };

  const textFieldProps = {
    ...commonProps,
    helperText: extractValidationError(error, 'answer_entry').join(', ')
  };
  
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {(question_type === TYPE_TEXT_AREA || question_type === TYPE_NPS_REASON) ?
          <TextField multiline rowsMax={question.line_number} rows={question.line_number} {...textFieldProps}/>
          : question_type === TYPE_SELECT_USER ?
          <Select {...commonProps}>
            {option_users.map((user, index) => <MenuItem key={index} value={user.value}>{user.label}</MenuItem>)}
          </Select>
          : question_type && <TextField {...textFieldProps} />
        }
      </div>
      <div className={classes.action}>
        <Button
          variant="outlined"
          onClick={onSubmit} 
          startIcon={<ElementIcon name='edit-outline' />}
          color="primary"
          disabled={clean} >
          変更
        </Button>
        <br/><br/>
        <Button
          variant="outlined"
          color="secondary"
          onClick={closeEditTag}
          startIcon={
            <img
              src={IconDelete}
              className={classes.iconDelete}
              alt="delete"
            />}
        >
          取消
        </Button>
      </div>
    </div>
  )
}

FeedbackAnswerEditTag.propTypes = propTypes;

export default connect(
  ({ feedbackAnswers: { answer, items, error },
     users,
   }) => ({
    answer: answer || {},
    comments: items,
    users: users.allItems || [],
    error
  }),
  (dispatch, { feedback }) => ({
    updateAnswer: answer => dispatch(updateFeedbackAnswer((feedback || {}).id, answer)),
    closeDialog: () => dispatch({ type: CLOSE_ANSWER_DIALOG }),
    searchUsers: shop_id => dispatch(thunkFetchAllUsers({ shop_id })),
    updateUser: (id, user) => dispatch(updateFeedbackUser(id, user)),
  })
)(FeedbackAnswerEditTag);
