import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import qs from 'query-string';
import { format } from 'date-fns';
import clsx from "clsx";
import {
  Typography,
  Box,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import {OPEN_FEEDBACK_MODAL, SET_TAB_INDEX,SET_WITHOUT_EMPTY_FLG} from './redux/actions/feedback';
import {
  thunkFetchFeedbacks,
  thunkFetchFeedbackLikes,
  thunkLikeFeedback,
  thunkPinFeedback,
  thunkDislikeFeedback
} from './redux/thunk';
import {PAGINATION_PER_PAGE, TAB_INDEX_COMMENTS} from './constants';
import Pagination from 'components/Pagination';
import { getNpsColorStyle, getScoreColorStyle, getNpsName, getFeedbackUserName, usePagingEffect } from './utils'
import ElementIcon from "common/icon/ElementIcon";
import { useFeedbackListStyle } from './styles'
import PinDialog from "../dashboard/goodvocs/PinDialog";
import LikeButton from "../dashboard/goodvocs/LikeButton";
import PinIcon from "../../../common/icon/PinIcon";
import { isMobile } from 'react-device-detect';
import FeedbackQuestions from './FeedbackQuestions'
import ScoreQuestions from './ScoreQuestions'
import { Storage } from '../../../utils'
import { WrappedText } from './FeedbackDetail'
import { LimitedLines } from '../../common/styles'
import SwitchBtnWithoutFont from "../../../components/SwitchBtnWithoutFont"

const propTypes = {
  feedbacks: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  setTabIndex: PropTypes.func,
  fetchFeedbacks: PropTypes.func.isRequired,
  pushPageToHistory: PropTypes.func.isRequired,
  searchParams: PropTypes.object,
  pinFeedback: PropTypes.func.isRequired,
};

const display = { xs: 'none', sm: 'none', md: 'block', lg: 'block' }

const FeedbackList = ({
  feedbacks,
  survey_id,
  likes,
  likeBegin,
  fetchFeedbacks,
  pagination,
  loading,
  pushPageToHistory,
  openModal,
  setTabIndex,
  searchParams,
  filters,
  can_pin_feedback,
  pinFeedback,
  fetchFeedbackLikes,
  likeFeedback,
  dislikeFeedback,
  setWithoutEmptyFlg,
}) => {
  const [page, setPage] = useState(
    new URLSearchParams(useLocation().search).get('page') || 1
  );
  const { url } = useRouteMatch();
  const classes = useFeedbackListStyle();
  const [activePage, setActivePage] = useState(1)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setPage(
      qs.parse(window.location.search, { ignoreQueryPrefix: true }).page || 1
    );
  });
  usePagingEffect((pageNumber, params) => {
    setActivePage(pageNumber)
    pushPageToHistory(url, pageNumber)
    if (!loading) {
      fetchFeedbacks(pageNumber, {
        ...searchParams,
        ...params,
        ...filters
      })
    }
  }, page, { ...searchParams, filters })

  pagination = !!pagination
    ? pagination
    : {
        offset: 0,
        total: feedbacks.length,
        perPage: PAGINATION_PER_PAGE
      };

  const onClick = offsetValue => {
    const pageNumber = offsetValue;
    pushPageToHistory(url, pageNumber);
    setActivePage(offsetValue)
  };

  const pinIt = (id, pinned) => {
    pinFeedback(id, {pinned})
  }

  const likeIt = (id, likeId) => {
    if (likeId)
      dislikeFeedback(id, likeId)
    else
      likeFeedback(id)
  }

  if (loading) {
    return <CircularProgress disableShrink />;
  }

  return (
    <div className={classes.root}>
      <Grid item={true}>
        {/* <Box display={{ sm: survey_id ? 'block' : 'none', xs: survey_id ? 'block' : 'none', md: 'block', lg: 'block' }}> */}
        <Grid container className={classes.thTable}>
        <Grid md={1} lg={1} item className={classes.clNumber}>
          <Box className={classes.Thborder} display={display}><ScoreQuestions /></Box>
        </Grid>
        <Grid md={2} lg={2} item className={classes.clShop}>
          <Box className={classes.Thborder} display={display}>担当スタッフ/商品</Box>
        </Grid>
        <Grid sm={12} md={4} xs={12} lg={4} item className={classes.clBody}>
        <Grid container className={classes.ThborderContainer}>
              <Grid className={classes.ThborderLeft} item sm={11} md={9} lg={9} xs={11}>
                <FeedbackQuestions />
            </Grid>
            <Grid className={classes.ThborderRight} item sm={11} md={3} lg={3} xs={11}>
              <SwitchBtnWithoutFont
                key={`checklist-option-woempty`}
                name={`without-freeanswer-empty`}
                label={'FAなしを除く'}
                checked={searchParams.without_empty}
                onChange={(e)=> {
                  setWithoutEmptyFlg(e.target.checked)
                }}
              />
            </Grid>
          </Grid>
          </Grid>
          <Grid md={2} lg={2} item className={classes.clDate}>
          <Box className={classes.Thborder} display={display}>回答日時</Box>
        </Grid>
        <Grid md={2} lg={2} item className={classes.clAction}>
          <Box className={classes.Thborder} display={display}>アクション</Box>
        </Grid>
        <Grid md={1} lg={1} item className={classes.clButton}>
        </Grid>
      </Grid>
        {/* </Box> */}
      {(feedbacks || []).map(feedback => (
        <Grid
          container
          item
          className={classes.tdTable}
          sm={12}
          display={{ sm: 'none', xs: 'none', md: 'block', lg: 'block' }}
          key={`fb:${feedback.id}`}
          onClick={() => openModal(feedback)}
          style={{ backgroundColor: !!feedback.pinned ? "#FEF9EB" : "white" }}
        >
          <Grid sm={2} xs={2} md={1} lg={1} item className={classes.trNumber} style={Storage.getScoreQuestion().id ? getScoreColorStyle(Storage.getScoreQuestion().maxScore, feedback.nps_score) : getNpsColorStyle(feedback.nps_score)}>
            <Box className={`${classes.Tdborder} ${classes.poiterTablet}`}>
              <Typography className={classes.score}>
                {feedback.nps_score}
              </Typography>
              <Typography className={classes.underScore}>{!Storage.getScoreQuestion().id ? getNpsName(feedback.nps_score) : ""}</Typography>
            </Box>
          </Grid>
          <Grid sm={8} xs={7} md={2} lg={2} item className={classes.trShop}>
            <Box className={classes.Tdborder}>
              <Typography className={classes.user}>
                {getFeedbackUserName(feedback)}
              </Typography>
              <WrappedText>
                <Typography component="div" className={classes.shop}>
                  <LimitedLines line={2}>
                  {feedback.shop.name}
                  </LimitedLines>
                </Typography>
              </WrappedText>
            </Box>
          </Grid>
          <Grid
            sm={2}
            xs={3}
            md={2}
            item
            className={`${classes.trShopMobile} ${classes.hideDesktop}`}
          >
            <Box className={classes.Tdborder}>
              <Typography className={classes.date}>
                {format(new Date(feedback.created_at), "yyyy/MM/dd")}
              </Typography>
              <Typography className={classes.hour}>
                {format(new Date(feedback.created_at), "HH:mm")}
              </Typography>
            </Box>
          </Grid>
          <Grid sm={12} xs={12} md={4} lg={4} item className={classes.trBody}>
            <Box className={(classes.Tdborder, classes.reason)}>
              {feedback.nps_reason}
            </Box>
          </Grid>
          <Grid sm={12} md={2} lg={2} item className={classes.trDate}>
            <Box className={classes.Tdborder}>
              <Typography className={classes.date}>
                {format(new Date(feedback.created_at), "yyyy/MM/dd")}
              </Typography>
              <Typography className={classes.hour}>
                {format(new Date(feedback.created_at), "HH:mm")}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={11} sm={11} md={2} lg={2} item className={classes.rightBorder}>
            <div className={classes.cellCenter}>
              <Box className={classes.likeButtonBox}>
                <LikeButton likes_count={feedback.likes_count} onHover={() => fetchFeedbackLikes(feedback.id)}
                          likes={likes} likeBegin={likeBegin} highlight={!!feedback.feedback_like_id}
                          onClick={() => likeIt(feedback.id, feedback.feedback_like_id)}/>
              </Box>
              <Button variant='outlined' className={clsx({
                [classes.btnComment]: true,
                [classes.btnCommentHover]: !isMobile,
              })} onClick={() => setTabIndex(TAB_INDEX_COMMENTS)}>
                <ElementIcon name={`chat-dot-round`} />
                <Box className={classes.scoreComment}>
                  {feedback.comments_count}
                </Box>
                </Button>
            </div>
          </Grid>
          <Grid xs={1} sm={1} md={1} lg={1} item className={classes.pinButtonCell}>
            {can_pin_feedback ?
              <PinDialog className={classes.pinButton} pinned={!!feedback.pinned}
                  onSubmit={() => pinIt(feedback.id, !!!feedback.pinned)}>
                <PinIcon size={13} color={!!feedback.pinned ? '#ec681a' : 'black'} />
              </PinDialog>
              :
              !!feedback.pinned && <PinIcon size={13} color={'#ec681a'} />
            }
          </Grid>
        </Grid>
      ))}
      <Pagination
        activePage={activePage}
        itemsCountPerPage={+pagination.perPage}
        pageRangeDisplayed={pagination.offset}
        totalItemsCount={+pagination.total}
        offset={pagination.offset}
        onChange={onClick}
      />
      </Grid>
    </div>
  );
};

FeedbackList.propTypes = propTypes;

export default connect(
  ({ feedbacks, global: { searchParams, userData, surveyData, filters }}) => ({
    feedbacks: feedbacks.items,
    pagination: feedbacks.pagination,
    loading: feedbacks.loading,
    likes: feedbacks.likes,
    likeBegin: feedbacks.likeBegin,
    searchParams: {
      ...searchParams,
      sort_key: feedbacks.sort_key,
      sort_val: feedbacks.sort_val,
      question_id: Storage.getQuestion().id,
      without_empty: feedbacks.without_empty,
      score_question_id: Storage.getScoreQuestion().id,
    },
    filters,
    can_pin_feedback: 'true' === userData.pin_feedback,
    survey_id: surveyData.value,
  }),
  (dispatch, { history }) => ({
    openModal: feedback =>
      dispatch({ type: OPEN_FEEDBACK_MODAL, payload: { feedback } }),
    setTabIndex: tabIndex => dispatch({ type: SET_TAB_INDEX, payload: { tabIndex }}),
    fetchFeedbacks: (page, searchParams) =>
      dispatch(thunkFetchFeedbacks(page, searchParams)),
    pushPageToHistory: (url, page) =>
      history.push(url + (+page > 0 ? `?page=${page}` : '')),
    pinFeedback: (id, pinned) => dispatch(thunkPinFeedback(id, pinned)),
    likeFeedback: id => dispatch(thunkLikeFeedback(id)),
    dislikeFeedback: (id, likeId) => dispatch(thunkDislikeFeedback(id, likeId)),
    fetchFeedbackLikes: id => dispatch(thunkFetchFeedbackLikes(id)),
    setWithoutEmptyFlg: value => dispatch({type: SET_WITHOUT_EMPTY_FLG, payload: {value}}),
  })
)(FeedbackList);
