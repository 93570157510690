import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine
} from 'recharts';
import { getNpsColor } from './utils';
import { scaleCorrelation, getMinMax } from 'modules/analysis/utils';
import { themeGraphTableStyles } from "./styles";
import { GRAY_COLOR, HEX_COLOR, TRIPLET_COLOR } from './constants';
import {isMobileOnly, isTablet} from 'react-device-detect';
import { replaceParams } from 'modules/utils';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  colNames: PropTypes.arrayOf(PropTypes.string),
  colKeys: PropTypes.arrayOf(PropTypes.string),
  activeGraph: PropTypes.string,
}

const useStyles = makeStyles(themeGraphTableStyles);

const GraphTable = ({ data=[], colNames=[], colKeys=[], activeGraph, nps }) => {
  const [graphHeight, setGraphHeight] = useState(0)
  const [paperWidth, setPaperWidth] = useState(0)
  const [heightTable, setHeightTable] = useState(0)
  const { cell, minCell, cellGraph, table, root, width30, width10, headerTable,Tbody, blockTableCell, floatLeft } = useStyles();
  const unit = activeGraph && activeGraph.includes("rate") ? "%" : ""
  const colKeyUnit = { rate: '%' }

  useEffect(() => {
    if (data.length > 0) {
      let height = data[0].label.length;
      for (let i = 0; i < data.length; i++ ) {
        if (height < data[i].label.length) {
          height = data[i].label.length
        }
      }

      if(isMobileOnly || isTablet) {
        setHeightTable(height*4.5)
      }
      else {
        setHeightTable(height*2.5 + 32)
      }
    }
  }, [data]);

  const [, max] = scaleCorrelation(getMinMax(data, [activeGraph]), [0, 10])

  const onPaperResize = width => {
    const row = document.getElementById("graph-question")
    if (!!row){
      setGraphHeight(row.clientHeight)
    }
    setPaperWidth(width)
  }

  return (
    <Paper className={root}>
      <Table className={table} aria-label="caption table" >
        <colgroup>
          <col className={width30}/>
          <col className={width10}/>
          <col className={width10}/>
          <col style={{width: paperWidth/3}}/>
          <col style={{width: paperWidth/3}}/>
        </colgroup>
        <TableHead>
          <TableRow className={headerTable}>
            {colNames.map((col, idx) => {
              const cellClasses = (idx === col.length ? [cell, minCell] : [cell]).join(' ')
              return <TableCell key={col} className={cellClasses}>{col}</TableCell>
            })}
            <TableCell className={cell} align="center">
              <div className={floatLeft}>0{unit}</div>
            </TableCell>
            <TableCell className={[cell, minCell].join(' ')} align="center">
              <div className={blockTableCell}>
                <div>{max/2}{unit}</div>
                <div>{max}{unit}</div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => <TableRow className={Tbody} key={index} height={heightTable}>
            {colKeys.map(colKey =>
              colKey==='label'?
              <TableCell key={colKey} id={colKey} className={cell} align="center">{replaceParams(item[colKey])}{colKeyUnit[colKey]}</TableCell>
              :
              <TableCell key={colKey} id={colKey} className={cell} align="center">{item[colKey]}{colKeyUnit[colKey]}</TableCell>
            )}
            {index === 0 &&
              <TableCell className={cellGraph} align="center" rowSpan={data.length} colSpan={2} id="graph-question">
                <BarChart data={data} layout="vertical" barSize={24} barCategoryGap="20%"
                  height={graphHeight} width={paperWidth*2/3}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }} >
                  <XAxis type="number" domain={[0, max]} hide={true} />
                  <YAxis dataKey="name" type="category" hide={true} />
                  <ReferenceLine x={max/4} stroke={GRAY_COLOR} />
                  <ReferenceLine x={max/2} stroke={GRAY_COLOR} />
                  <ReferenceLine x={max*3/4} stroke={GRAY_COLOR} />
                  <Tooltip cursor={{ fill: 'none', fontSize: 11 }}/>
                  <Bar dataKey={activeGraph} name={colNames[colKeys.indexOf(activeGraph)]} unit={unit}>
                    {data.map((_, i) => <Cell key={i} fill={nps ? getNpsColor(i) : HEX_COLOR} />)}
                  </Bar>
                  <ReferenceLine x={0} stroke={TRIPLET_COLOR} strokeWidth={2} />
                  <ReferenceLine x={max} stroke={TRIPLET_COLOR} strokeWidth={2} />
                </BarChart>
              </TableCell>
            }
          </TableRow>
          )}
        </TableBody>
      </Table>
      <ReactResizeDetector handleWidth onResize={onPaperResize} />
    </Paper>
  )
}

GraphTable.propTypes = propTypes;

export default GraphTable;
