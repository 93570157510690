import {
  FETCH_PROJECTS_BEGIN,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  FETCH_ALL_PROJECTS_BEGIN,
  FETCH_ALL_PROJECTS_SUCCESS,
  FETCH_ALL_PROJECTS_FAILURE,
  SAVE_PROJECT_BEGIN,
  SAVE_PROJECT_SUCCESS,
  SAVE_PROJECT_FAILURE,
  UPDATE_PROJECT_ITEM,
  DEACTIVATE_PROJECT,
  FETCH_PROJECTS_RESET,
} from "./actions";
import { parseProjectForSelect, parseProjectWithGoodvocs } from '../utils'
import { mergeItem } from "../../utils";


const initialState = {
  item: {},
  itemSubmitting: false,
  itemSubmitSuccess: false,
  errors: null,
  
  items: null,
  loading: false,
  loaded: false,
  error: null,
  pagination: {
    total: 0,
  },

  allItems: [],
  allItemsLoading: false,
  allItemsLoaded: false,
  allItemsError: null,
};

export default (state=initialState, action) => {
  switch(action.type) {
    case FETCH_PROJECTS_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        itemSubmitting: false,
      };

    case FETCH_PROJECTS_SUCCESS: {
      const { data } = action.payload.items;

      return {
        ...state,
        loading: false,
        loaded: true,
        items: data.map(parseProjectWithGoodvocs),
        pagination: action.payload.pagination,
      };
    }

    case FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
        items: [],
      };

    case FETCH_PROJECTS_RESET:
      return {
        ...state,
        loading: false,
        loaded: false,
        allItemsLoading: false,
        allItemsLoaded: false,
      };

    case FETCH_ALL_PROJECTS_BEGIN: {
      return {
        ...state,
        allItemsError: null,
        allItemsLoading: true,
        allItemsLoaded: false,
      };
    }

    case FETCH_ALL_PROJECTS_SUCCESS: {
      const { data } = action.payload.items;

      return {
        ...state,
        allItems: data.map(parseProjectForSelect),
        allItemsLoading: false,
        allItemsLoaded: true,
      };
    }

    case FETCH_ALL_PROJECTS_FAILURE: {
      return {
        ...state,
        allItemsError: action.payload.error,
        allItems: [],
        allItemsLoading: false,
        allItemsLoaded: true,
      };
    }

    case SAVE_PROJECT_BEGIN: {
      return {
        ...state,
        itemSubmitting: true,
        itemSubmitSuccess: false,
        errors: null,
      };
    }

    case SAVE_PROJECT_SUCCESS: {
      const { item } = action.payload
      return {
        ...state,
        itemSubmitting: false,
        itemSubmitSuccess: true,
        item,
        errors: null,
        items: mergeItem(item, state.items),
      }
    }

    case SAVE_PROJECT_FAILURE: {
      return {
        ...state,
        itemSubmitting: false,
        itemSubmitSuccess: false,
        errors: action.payload.errors,
      }
    }

    case UPDATE_PROJECT_ITEM: {
      const { item } = action.payload;

      return {
        ...state,
        item,
        itemSubmitSuccess: false,
      };
    }

    case DEACTIVATE_PROJECT: {
      return {
        ...state,
        items: state.items.filter(i => i.id !== action.payload.id),
        item: state.item ? { ...state.item, discarded_at: new Date() } : {},
      };
    }

    default:
      return state;
  }
}
