import { parseFeedback } from '../../feedbacks/utils'

export const FETCH_DASHBOARD_BEGIN = 'FETCH_DASHBOARD_BEGIN'
export const FETCH_GOODVOCS_BEGIN = 'FETCH_GOODVOCS_BEGIN'
export const FETCH_RANKS_SUCCESS = 'FETCH_RANKS_SUCCESS'
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS'
export const FETCH_GOODVOCS_SUCCESS = 'FETCH_GOODVOCS_SUCCESS'
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE'
export const SELECT_COMMON_RANK_DROPDOWN = 'SELECT_COMMON_RANK_DROPDOWN'
export const SET_COMMON_RANK_TRIM30 = 'SET_COMMON_RANK_TRIM30'
export const SELECT_USER_RANK_DROPDOWN = 'SELECT_USER_RANK_DROPDOWN'
export const PIN_GOODVOCS_FEEDBACK = 'PIN_GOODVOCS_FEEDBACK'
export const LIKE_GOODVOCS_FEEDBACK = 'LIKE_GOODVOCS_FEEDBACK'
export const DISLIKE_GOODVOCS_FEEDBACK = 'DISLIKE_GOODVOCS_FEEDBACK'
export const CLEAR_RANKS = 'CLEAR_RANKS'

export const fetchDashboardBegin = name => ({
  type: FETCH_DASHBOARD_BEGIN,
  payload: { name },
})

export const fetchGoodvocsBegin = () => ({
  type: FETCH_GOODVOCS_BEGIN,
})

export const fetchDashboardSuccess = ({ data }, name) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: { data, name },
})

export const fetchGoodvocsSuccess = ({ data, included }, page, totalPages) => ({
  type: FETCH_GOODVOCS_SUCCESS,
  payload: {
    feedbacks: data.map(element => parseFeedback({ ...element }, included)),
    page: data.length > 0 ? page : Math.max(1, page - 1),
    totalPages,
  },
})

export const fetchRanksSuccess = ({ data }, name) => ({
  type: FETCH_RANKS_SUCCESS,
  payload: {
    name,
    data: {
      feedbacks_top5: filterFields(data.feedbacks_top5, ['feedback_count', 'name', 'shops_name']),
      nps_score_top5: filterFields(data.nps_score_top5, ['nps_score', 'name', 'shops_name']),
    },
  },
})

export const fetchDashboardFailure = (error, name) => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: { error, name },
})

const filterFields = (array = [], fields = []) => {
  return array.map(element => {
    const temp = {}
    fields.forEach(field => (temp[field] = element[field]))
    return temp
  })
}
