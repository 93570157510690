export const FETCH_GROUPS_BEGIN = 'FETCH_GROUPS_BEGIN';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';
export const FETCH_ALL_GROUPS_BEGIN = 'FETCH_ALL_GROUPS_BEGIN';
export const FETCH_ALL_GROUPS_SUCCESS = 'FETCH_ALL_GROUPS_SUCCESS';
export const FETCH_ALL_GROUPS_FAILURE = 'FETCH_ALL_GROUPS_FAILURE';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const UPDATE_GROUP_BEGIN = 'UPDATE_GROUP_BEGIN';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const OPEN_GROUP_FORM_MODAL = 'OPEN_GROUP_FORM_MODAL';
export const CLOSE_GROUP_FORM_MODAL = 'CLOSE_GROUP_FORM_MODAL';
export const CHANGE_GROUP_ORDER = 'CHANGE_GROUP_ORDER'
export const FETCH_GROUPS_RESET = 'FETCH_GROUPS_RESET';


export const actionFetchGroupsBegin = () => ({
  type: FETCH_GROUPS_BEGIN,
});

export const actionFetchGroupsSuccess = items => ({
  type: FETCH_GROUPS_SUCCESS,
  payload: {
    items,
  }
});

export const actionFetchGroupsFailure = error => ({
  type: FETCH_GROUPS_FAILURE,
  payload: {
    error
  }
});

export const actionFetchAllGroupsBegin = () => ({
  type: FETCH_ALL_GROUPS_BEGIN,
});

export const actionFetchAllGroupsSuccess = items => ({
  type: FETCH_ALL_GROUPS_SUCCESS,
  payload: {
    items,
  }
});

export const actionFetchAllGroupsFailure = error => ({
  type: FETCH_ALL_GROUPS_FAILURE,
  payload: {
    error
  }
});

export const actionCreateGroup = ({ parentId, data, included, parentName }) => {
  return {
    type: CREATE_GROUP_SUCCESS,
    payload: {
      item: { parentId, data, included, parentName },
    }
  }
}

export const actionUpdateGroup = ({ parentId, data, included }) => {
  return {
    type: UPDATE_GROUP_SUCCESS,
    payload: {
      item: { parentId, data, included },
    }
  }
}

export const actionUpdateGroupFailure = error => ({
  type: UPDATE_GROUP_FAILURE,
  payload: { error}
})

export const actionDeleteGroup = (id) => {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: {
      deleteGroupId: id,
    }
  }
}

export const actionCloseGroupFormModal = () => {
  return {
    type: CLOSE_GROUP_FORM_MODAL,
    payload: {
      modalOpened: false,
    }
  }
}

export const actionChangeOrder = (items) => {
  return {
    type: CHANGE_GROUP_ORDER,
    payload: {
      items,
    }
  }
}
