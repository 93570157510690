
export const parseArea = ({ id, name, display_order, shops, children, parentId, parentName }) => ({
  id,
  name,
  display_order,
  shops: (shops.data || []).map(({ id }) => id),
  children,
  parentId,
  parentName,
});

export const parseAreaForSelect = ({ id, name, display_order, children, parentId, parentName, checkedIds }) => ({
  label: name,
  value: id,
  display_order,
  children,
  parentId,
  parentName,
  checked: checkedIds.includes(id),
});

export const getAreaFromIncluded = ({ id, included }) => {
  let itemData = null;
  
  for (let item of included) {
    if (item.id === id) {
      itemData = item;
      break;
    }
  }

  return itemData === null ? {} : parseArea(itemData);
};

export const parseTreeForSelect = (allItems, checkedIds) => {
  return [...allItems].map(item => traverseParseForSelect(item, null, 'root', checkedIds));
};

export const traverseParseForSelect = (parentBranch, parentBranchId, parentName, checkedIds) => {
  parentBranch.children = parentBranch.children.map(branch =>
    traverseParseForSelect(branch, parentBranch.id, parentBranch.name, checkedIds)
  );
  return parseAreaForSelect({ ...parentBranch, parentId: parentBranchId, parentName: parentName, checkedIds });
};

export const traverseParse = (parentBranch, parentBranchId, parentName) => {
  parentBranch.children = parentBranch.children.map(branch =>
    traverseParse(branch, parentBranch.id, parentBranch.name)
  );
  return parseArea({ ...parentBranch, parentId: parentBranchId, parentName: parentName });
};

export const traverseFindWithShop = (areas, branchId, shopId, parentBranchIds=[]) => {
  const filteredAreas = areas.map(area => {
    if ([...parentBranchIds, +area.id].includes(+branchId) && !!!shopId) {
      return [area, null];
    }

    if ([...parentBranchIds, +area.id].includes(+branchId) && !!area.shops.data.find(({attributes: { uid }}) => uid === shopId)) {
      const selectedShop = area.shops.data.find(({attributes: { uid }}) => uid === shopId) || ''
      return [area, selectedShop];
    }

    if (!!!branchId && !!area.shops.data.find(({attributes: { uid }}) => uid === shopId)) {
      const selectedShop = area.shops.data.find(({attributes: { uid }}) => uid === shopId) || ''
      return [area, selectedShop];
    }

    const children = traverseFindWithShop(area.children, branchId, shopId, [...parentBranchIds, +area.id]);
    if (children.length > 0) {
      return children[0]
    }
    
    return null;

  }).filter(el => el !== null);

  if (filteredAreas.length > 1) {
    return filteredAreas.filter(item => item[1] !== null)
  }

  return filteredAreas
};

export const cleanseForSelect = parent => {
  parent.children = parent.children.map(cleanseForSelect)
  let { id, name, children } = parent
  return { label: name, value: id, children }
}
