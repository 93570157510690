import React from 'react';
import {
  Divider, Popover, Paper,
  Typography, MenuList, MenuItem, ListItemIcon,
} from '@material-ui/core';
import {

  ArrowDropDownCircleOutlined,
  RadioButtonCheckedOutlined,
  StopRounded
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paperCreateMenu: {
    boxShadow: "none",
    width: 278,
    height: 156,
    border: "1px solid #B7B7B7",
    borderTop: "2px solid #9A877D",
    padding: 15,
    background: "#FFFFFF",
  },
  itemListCreatePopover: {
    paddingTop: 0,
  },
  itemCreatePopover: {
    height: 38,
    "& span.MuiTypography-root": {
      marginLeft: 10,
      fontSize: "10px",
      color: "#1A0707",
      letterSpacing: "0.25px",
    },
    "& div.MuiListItemIcon-root": {
      minWidth: 16
    },
  },
  iconMenuPopover: {
    width: 16,
    height: 16,
  },
}))

const QuestionPopover = ({
  addQuestion,
  addSurveyHeading,
  addPage,
  anchorEl,
  handleClose
}) => {
  const classes = useStyles();

  const onAddQuestion = () => {
    !!addQuestion && addQuestion();
    handleClose();
  }

  const onAddSurveyHeading = () => {
    !!addSurveyHeading && addSurveyHeading();
    handleClose();
  }

  const onAddPage = () => {
    !!addPage && addPage();
    handleClose();
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Paper className={classes.paperCreateMenu}>
        <MenuList className={classes.itemListCreatePopover}>
          <MenuItem className={classes.itemCreatePopover} onClick={onAddQuestion}>
            <ListItemIcon className={classes.iconMenuPopoverWraper}>
              <ArrowDropDownCircleOutlined />
            </ListItemIcon>
            <Typography variant="inherit">質問を追加</Typography>
          </MenuItem>
          <Divider />
          <MenuItem className={classes.itemCreatePopover} onClick={onAddSurveyHeading}>
            <ListItemIcon>
              <RadioButtonCheckedOutlined />
            </ListItemIcon>
            <Typography variant="inherit">見出しを追加</Typography>
          </MenuItem>
          <Divider />
          <MenuItem className={classes.itemCreatePopover} onClick={onAddPage}>
            <ListItemIcon>
              <StopRounded />
            </ListItemIcon>
            <Typography variant="inherit">ページを追加</Typography>
          </MenuItem>
          <Divider />
        </MenuList>
      </Paper>
    </Popover>
  )
}

export default QuestionPopover
