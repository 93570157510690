import {
  FETCH_SHOPS_BEGIN,
  FETCH_SHOPS_SUCCESS,
  FETCH_SHOPS_FAILURE,
  FETCH_ALL_SHOPS_BEGIN,
  FETCH_ALL_SHOPS_SUCCESS,
  FETCH_ALL_SHOPS_FAILURE,
  OPEN_SHOP_FORM_MODAL,
  CLOSE_SHOP_FORM_MODAL,
  SAVE_SHOP_BEGIN,
  SAVE_SHOP_SUCCESS,
  SAVE_SHOP_FAILURE,
  UPDATE_SHOP_ITEM,
  CHANGE_SHOP_ITEM_STATE,
  FETCH_SHOPS_RESET,
} from './actions';
import { parseShop } from '../utils';
import { mergeItem, parseToOptionValues} from 'modules/utils';


const initialState = {
  items: null,
  loading: false,
  loaded: false,
  error: null,
  pagination: {
    total: 0,
  },
  item: {},
  itemSaveError: null,
  itemError: null,
  itemSubmitting: false,
  itemSubmitSuccess: false,

  allItems: [],
  allItemsLoading: false,
  allItemsError: null,
  allItemsLoaded: false,
  modalOpened: false,
};
  
export default (state=initialState, action) => {
  switch(action.type) {
    case FETCH_SHOPS_BEGIN: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        itemSubmitSuccess: false,
      };
    }

    case FETCH_SHOPS_SUCCESS: {
      const { data } = action.payload.items;

      return {
        ...state,
        loading: false,
        loaded: true,
        items: data.map(parseShop),
        pagination: action.payload.pagination,
      };
    }

    case FETCH_SHOPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
        items: [],
      };
    }

    case FETCH_SHOPS_RESET: {
      return {
        ...state,
        loading: false,
        loaded: false,
        allItemsLoading: false,
        allItemsLoaded: false,
      };
    }

    case FETCH_ALL_SHOPS_BEGIN: {
      return {
        ...state,
        allItemsLoading: true,
        allItemsError: null,
        allItemsLoaded: false,
      };
    }

    case FETCH_ALL_SHOPS_SUCCESS: {
      const { data } = action.payload.items;

      return {
        ...state,
        allItemsLoading: false,
        allItems: parseToOptionValues(data, ({ id, attributes: { name } }) => ({ label: name, value: id })),
        allItemsLoaded: true,
      };
    }

    case FETCH_ALL_SHOPS_FAILURE: {
      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: true,
        allItemsError: action.payload.error,
        allItems: [],
      };
    }
    
    case OPEN_SHOP_FORM_MODAL: {
      return {
        ...state,
        modalOpened: true,
        item: action.payload.item || {},
      };
    }

    case CLOSE_SHOP_FORM_MODAL: {
      return {
        ...state,
        modalOpened: false,
      };
    }

    case SAVE_SHOP_BEGIN: {
      return {
        ...state,
        itemSubmitting: true,
        itemSubmitSuccess: false,
        itemSaveError: null,
      };
    }

    case SAVE_SHOP_SUCCESS: {
      const item = parseShop((action.payload.data || {}).data)
      return {
        ...state,
        itemSubmitting: false,
        itemSubmitSuccess: true,
        itemSaveError: null,
        item,
        items: mergeItem(item, state.items),
      };
    }

    case SAVE_SHOP_FAILURE: {
      return {
        ...state,
        itemSubmitting: false,
        itemSubmitSuccess: false,
        itemSaveError: action.payload.errors,
      };
    }

    case UPDATE_SHOP_ITEM: {
      const { item } = action.payload;

      return {
        ...state,
        item,
        itemSaveError: null,
      };
    }

    case CHANGE_SHOP_ITEM_STATE: {
      const { id, discarded_at } = action.payload

      return {
        ...state,
        items: state.items.map(item => item.id === id ? {
          ...item,
          discarded_at,
        } : item),
        item: {
          ...state.item,
          discarded_at,
        }
      }
    }

    default:
      return state;
  }
};
