import React , { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { VisibilityOutlined, InfoOutlined } from '@material-ui/icons';
import ElementIcon from "common/icon/ElementIcon";
import { surveyDetailHeaderStyles } from "./styles";
import { TextFieldWithError } from "./components";

const useStyles = makeStyles(surveyDetailHeaderStyles);

const SurveyDetailHeader = ({ item, itemSaveError, itemLoading, itemSubmitting }) => {
  const classes = useStyles();
  const [surveyName, setSurveyName] = useState('');

  useEffect(() => {
    setSurveyName(item.name || '');
    // eslint-disable-next-line
  }, [item.name])

  const submit = ({ withPreview }) => {
    const event = document.createEvent( 'CustomEvent' );
    event.initCustomEvent( 'submit', false, true, { "inputValue": surveyName, withPreview } );
    document.forms["survey-form"].dispatchEvent(event);
  }

  return (
    <Grid className={classes.root} container direction="row" justify="space-between" alignItems="center">
      <div className={classes.txtName}>
        <TextFieldWithError
          name={"name"}
          fieldLabel={"アンケート名"}
          value={surveyName}
          errors={itemSaveError.name}
          placeholder={'アンケート名を入力してください。'}
          onChange={e => setSurveyName(e.target.value) }
        />
        <button className="buttonShow"><ElementIcon name={`edit-outline`}/></button>
      </div>
      <Grid item>
        <Button
          disabled={itemLoading || itemSubmitting}
          startIcon={<VisibilityOutlined />}
          className={classes.previewBtn}
          onClick={() => submit({withPreview: true})}
        >
          保存してプレビュー
        </Button>

        <Button
          disabled={itemLoading || itemSubmitting}
          variant="outlined"
          startIcon={<InfoOutlined />}
          className={classes.savedBtn}
          onClick={() => submit({withPreview: false})}
        >
          すべての変更を保存
        </Button>
      </Grid>
    </Grid>
  )
}

export default connect(
  ({
    surveys: { item, itemSaveError, itemLoading, itemSubmitting },
  }) => ({
    item,
    itemSaveError,
    itemLoading,
    itemSubmitting,
  })
)(SurveyDetailHeader);
