export const FETCH_USERS_BEGIN = "FETCH_USERS_BEGIN";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const FETCH_ALL_USERS_BEGIN = "FETCH_ALL_USERS_BEGIN";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAILURE = "FETCH_ALL_USERS_FAILURE";
export const UPDATE_USER_ITEM = "UPDATE_USER_ITEM";

export const SAVE_USER_BEGIN = "SAVE_USER_BEGIN";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";
export const CHANGE_USER_ITEM_STATE = "CHANGE_USER_ITEM_STATE";

export const FETCH_USERS_RESET = "FETCH_USERS_RESET";
