import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  FormLabel,
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
  Button,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { components } from 'react-select';
import Switch from "react-input-switch";
import { thunkSaveUser } from "../redux/thunk";
import { AUTHORITIES, AUTHORITIES_FOR_ADMIN } from "../constants";
import { themedStyles } from "./styles";

import SaveBtn from "components/SaveBtn";
import SwitchBtn from "components/SwitchBtn";
import { parseTreeForSelect, traverseFind } from "modules/groups/utils";
import { deepCopy } from "utils";
import {SwipeableModal, UnSavedWarningDialog} from "../../../components";
import ElementIcon from '../../../common/icon/ElementIcon';
import DropdownSelect from "components/DropdownSelect";

import { thunkFetchAllGroups } from "modules/groups/redux/thunk";
import { thunkFetchAllShops } from "modules/shops/redux/thunk";
import clsx from 'clsx';
import Select from 'react-select';

const useStyles = makeStyles(themedStyles);

const propTypes = {
  item: PropTypes.object,
  itemSubmitSuccess: PropTypes.bool,
  itemSubmitting: PropTypes.bool,
  submitErrors: PropTypes.object,
  shopsOptions: PropTypes.array,
  shopsLoaded: PropTypes.bool.isRequired,
  groupsOptions: PropTypes.array,
  save: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
  userAuthority: PropTypes.string,
  fetchAllGroups: PropTypes.func.isRequired,
  fetchAllShops: PropTypes.func.isRequired,
}

const checkBoxes = [
  {
    code: "is_personal_info_viewable",
    label: "個人情報閲覧権限"
  },
  {
    code: "is_personal_info_exportable",
    label: "フィードバックエクスポート権限"
  },
  {
    code: "is_comment_notification",
    label: "コメント通知機能を利用する"
  },
  {
    code: "visible_negative_feedback",
    label: "3点以下のフィードバックを表示する"
  },
  {
    code: "is_feedback_notification",
    label: "フィードバックを通知する"
  },
  {
    code: "is_feedback_pin",
    label: "ピン留め権限"
  }
];

const UserForm = ({
  item,
  itemSubmitSuccess,
  itemSubmitting,
  submitErrors,
  shopsOptions,
  shopsLoaded,
  groupsOptions,
  save,
  closeModal,
  modalOpen,
  userAuthority,
  fetchAllGroups,
  fetchAllShops,
}) => {
  const dirtyFields = useRef([])
  const [value, setValue] = useState({})
  const [dirty, setDirty] = useState(false)
  const [open, setOpen] = useState(modalOpen)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState({})
  const [errors, setErrors] = useState({});
  const isSuperAdmin = userAuthority === "super_admin";
  const classes = useStyles();

  const [duplicateShops, setDuplicateShops] = useState(null);
  const loadOnce1 = useRef(true);
  const [duplicateShops2, setDuplicateShops2] = useState(null);
  const loadOnce2 = useRef(true);
  const userViewingGroups = useRef(null);

  useEffect(() => {
    setOpen(modalOpen)
    setDirty(false)
    setErrors({})
    setValue(item)
    if (modalOpen) {
      fetchAllGroups();
      fetchAllShops();
    }
    setSelectedOptions({
      shop_ids: (item.shops || []).map(({ id }) => id),
      users_viewing_shop_ids: (item.users_viewing_shops || []).map(({ id }) => id),
      users_viewing_group_ids: (item.users_viewing_groups || []).map(({ id }) => +id),
    })
    loadOnce1.current = true;
    loadOnce2.current = true;
    // eslint-disable-next-line
  }, [modalOpen]);

  useEffect(() => {
    if (itemSubmitSuccess) {
      setDirty(null)
      dirtyFields.current = []
      setErrors({})
      setValue(item)
      setSelectedOptions({
        shop_ids: (item.shops || []).map(({ id }) => id),
        users_viewing_shop_ids: (item.users_viewing_shops || []).map(({ id }) => id),
        users_viewing_group_ids: (item.users_viewing_groups || []).map(({ id }) => +id),
      })
    }
    // eslint-disable-next-line
  }, [itemSubmitSuccess])

  useEffect(() => {
    if (!!submitErrors) setErrors(submitErrors.detail);
  }, [submitErrors])

  const handleSubmit = (e) => {
    e.preventDefault();

    let doc = item.id ? { id: item.id } : {};
    dirtyFields.current.forEach(field => {
      doc[field] = value[field]
    })
    save(doc);
  }

  const fieldHasError = code => (errors[code] || []).length > 0;

  const renderTextField = ({ type, code, label, onInput, ...otherProps }) => {
    const hasError = fieldHasError(code);
    return (
      <FormControl fullWidth className={classes.inputGroup} error={hasError}>
        { label !== 'パスワード確認' && <FormLabel component="p">{label}</FormLabel> }
        <TextField
          type={type}
          name={code}
          placeholder={label}
          variant={"outlined"}
          error={hasError}
          helperText={hasError ? `${label}${errors[code][0]}` : null}
          onInput={e => (onInput ? onInput(e.target.value) : null)}
          defaultValue={item[code] || ''}
          { ...otherProps }
          className={classes.textFieldInput}
          onChange={({ target={} }) => onSingleValueChange(target.name, target.value)}
        />
      </FormControl>
    );
  }

  const renderSelectField = ({ code, label, options, onChange}) => {
    const hasError = fieldHasError(code)
    const DropdownIndicator = props => {
      const { DropdownIndicator } = components
      return (
        <DropdownIndicator {...props}>
           <ElementIcon name='caret-bottom'/>
        </DropdownIndicator>
      )
    }
    const customStyles = {
      control: (provided, {isFocused}) => ({
        ...provided,
        '&:hover': {
          borderColor: isFocused ? 'none' : '#1A0707',
        }
      }),
      dropdownIndicator: (provided, {isFocused}) => ({
        ...provided,
        color: isFocused ? 'black' : '#ccc',
        '& > i::before': {
          content: isFocused ? '"\\e78f"' : '"\\e790"'
        }
      }),
      indicatorSeparator: (provided, {isFocused}) => ({
        ...provided,
        backgroundColor: isFocused ? 'black' : '#ccc',
      })
    }
    return (
      <FormControl fullWidth className={classes.inputGroup} error={hasError}>
        <FormLabel component="p">{label}</FormLabel>
        <Select
          name={code}
          placeholder="操作権限を選択"
          onChange={({value}) => onChange(value)}
          className={classes.selectField}
          options={options}
          components={{ DropdownIndicator }}
          styles={customStyles}
          defaultValue={options.find(option => option.value === item.authority)}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#EC681A'
            },
          })}
        />
        {hasError && <FormHelperText>{`${label}${errors[code][0]}`}</FormHelperText>}
      </FormControl>
    )
  }

  const renderCheckList = options => (
    <FormControl fullWidth className={classes.inputGroup}>
      {options.map(({ code, label }, i) => (
        <SwitchBtn
          key={`checklist-${code}-option-${i}`}
          name={code}
          checked={(value || {})[code] || false}
          label={label}
          onChange={e => onCheckValueChange(code, e.target.checked)}
        />
      ))}
    </FormControl>
  )
  const onDiscard = () => {
    handleDirt('discarded_at', !!item.discarded_at === !!value.discarded_at)
    setValue(previous => ({...previous, discarded_at: value.discarded_at ? null : new Date()}));
  };

  // tree 1
  useEffect(() => {
    if (!loadOnce1.current) return;

    if (shopsLoaded && !!selectedOptions.shop_ids) {
      const selectedShops = shopsOptions
        .filter(shop => (selectedOptions.shop_ids || [])
          .map(item => +item)
          .includes(+shop.value));
      
      setDuplicateShops(selectedShops.map(item => ({
        type: 'shop',
        field_name: 'shop_ids',
        label: item.label,
        value: +item.value,
      })));
      loadOnce1.current = false;
    }

    // eslint-disable-next-line
  }, [shopsLoaded, selectedOptions.shop_ids]);

  const groupsOptions1 = parseTreeForSelect(deepCopy(groupsOptions || []), duplicateShops || [], { withShops: true, hideGroupSelector: true });

  useEffect(() => {
    if (!loadOnce2.current) return;

    if (shopsLoaded && !!selectedOptions.users_viewing_shop_ids) {
      const selectedUserViewingShops = shopsOptions
        .filter(shop => (selectedOptions.users_viewing_shop_ids || [])
          .map(item => +item).includes(+shop.value));

      setDuplicateShops2(selectedUserViewingShops.map(item => ({
        type: 'shop',
        field_name: 'users_viewing_shop_ids',
        label: item.label,
        value: +item.value
      })));

      loadOnce2.current = false;
    }

    // eslint-disable-next-line
  }, [shopsLoaded, selectedOptions.users_viewing_shop_ids]);

  userViewingGroups.current = traverseFind(groupsOptions, selectedOptions.users_viewing_group_ids || []).map(item => ({ type: 'group', field_name: 'users_viewing_group_ids', value: item.id, label: item.name }));

  const groupsOptions2 = parseTreeForSelect(deepCopy(groupsOptions || []), [...(userViewingGroups.current || []), ...(duplicateShops2 || [])], { withShops: true, hideGroupSelector: false });

  const handleDirt = (name, isDirty) => {
    if (isDirty)
      dirtyFields.current = dirtyFields.current.concat([name])
    else
      dirtyFields.current = dirtyFields.current.filter(field => field !== name)
    setDirty(dirtyFields.current.length > 0)
  }

  const closeConfirmationDialog = () => setIsConfirmationOpen(false)

  const onModalClose = () => {
    if (dirty) {
      setIsConfirmationOpen(true);
    } else {
      resetForm();
      closeModal && closeModal();
    }
  }

  const onSingleValueChange = (name, newValue) => {
    setValue(previous => ({ ...previous, [name]: newValue}))
    handleDirt(name, (item[name] || '') !== newValue)
  }

  const onCheckValueChange = (name, check) => {
    setValue(previous => ({ ...previous, [name]: check}))
    handleDirt(name, !!item[name] !== check)
  }

  const checkClean = (name, incoming) => {
    const selectedOptionsName = {
      'shop_ids': 'shops',
      'users_viewing_group_ids': 'users_viewing_groups',
      'users_viewing_shop_ids': 'users_viewing_shops'
    }[name];
  
    const existing = (item[selectedOptionsName] || []).map(item => +item.id).sort();

    return existing.length === incoming.length && existing.every((element, index) => element === incoming[index]);
  }

  const deleteItem = (value, name) => {
    const incoming = selectedOptions[name].filter(item => +item !== +value).map(item => +item).sort();
    if (name === 'shop_ids') {
      setDuplicateShops(duplicateShops.filter(item => +item.value !== +value));
    } else if (name === 'users_viewing_shop_ids') {
      setDuplicateShops2(duplicateShops2.filter(item => +item.value !== +value));
    }

    const clean = checkClean(name, incoming);

    setValue(previous => ({ ...previous, [name]: incoming }));
    setSelectedOptions(previous => ({ ...previous, [name]: incoming }));
    handleDirt(name, !clean);
  }

  const renderOptionLabels = (items) => (
    <div className="listUser">
      {items.map((item, index) =>
        <div key={`label-${index}`}>
          <p>{item.label}</p>
          <i onClick={() => deleteItem(item.value, item.field_name)} className="material-icons">close</i>
        </div>
      )}
    </div>
  );

  const onSelectControlChanged = (values) => {
    let tmpValue = { };
    let tmpSelectedOptions = {};

    for (let [name, newSelections=[]] of values) {
      const incoming = newSelections.map(item => +item).sort();
      const clean = checkClean(name, incoming);
      handleDirt(name, !clean)
      tmpSelectedOptions = { ...tmpSelectedOptions, [name]: newSelections };
      tmpValue = { ...tmpValue, [name]: newSelections };
    }

    setSelectedOptions(previous => ({  ...previous, ...tmpSelectedOptions }));
    setValue(previous => ({ ...previous, ...tmpValue }));
  };

  const resetForm = () => {    
    userViewingGroups.current = null;
    setDuplicateShops(null);
    setDuplicateShops2(null);
  };

  return (
    <SwipeableModal modalOpened={open} closeModal={onModalClose}>
      { shopsLoaded && <>
      <form onSubmit={handleSubmit}>
        <div className={classes.formContain}>
          <FormControl fullWidth>
            <div>
              <SaveBtn type="submit" dirty={dirty} itemSubmitting={dirty === null ? true : false}/>
              <Button className={classes.buttonToggle} onClick={onDiscard}>
                <Switch
                  styles={{
                    trackChecked: {
                      backgroundColor: "#EC681A"
                    }
                  }}
                  on="yes"
                  off="no"
                  value={!!!value.discarded_at ? "yes" : "no"}
                />
                {value.discarded_at ? "停止中" : "使用中"}
              </Button>
              <Button className={classes.buttonDel} variant="outlined" onClick={onModalClose}>
                閉じる
                <i className="material-icons">clear</i>
              </Button>
            </div>
          </FormControl>
          <div className={classes.headerFrom}>
            <TextField
              fullWidth={true}
              name={"name"}
              placeholder={"スタッフ名"}
              defaultValue={item['name'] || ''}
              className={classes.editName}
              error={!!errors.name}
              helperText={!!errors.name && `スタッフ名${errors.name[0]}`}
              onChange={({ target={} }) => onSingleValueChange(target.name, target.value)}
            />
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
          </div>

          {renderTextField({
            type: "email",
            code: "email",
            label: "メールアドレス",
          })}

          {renderTextField({
            type: "text",
            code: "ucode",
            label: "スタッフ番号",
          })}

          {renderTextField({
            type: "text",
            code: "login_id",
            label: "ログインID",
            autoComplete: "off",
          })}

          {renderTextField({
            type: "password",
            code: "password",
            label: "パスワード",
            placeholder: "変更する場合はパスワードを入力",
            autoComplete: "new-password",
          })}

          {renderTextField({
            type: "password",
            code: "password_confirmation",
            label: "パスワード確認",
            placeholder: "変更するパスワードの確認",
          })}

          {renderSelectField({
            code: "authority",
            label: "操作権限",
            options: isSuperAdmin || item.authority === 'super_admin' ? AUTHORITIES : AUTHORITIES_FOR_ADMIN,
            onChange: val => {
              if ((val === 'staff' || val === 'manager')){
                onCheckValueChange('is_personal_info_exportable', false)
              }
              onSingleValueChange('authority', val)
            }
          })}

          {renderCheckList(checkBoxes.slice(0, 2))}

          {renderCheckList(checkBoxes.slice(5, 6))}

          <Grid item xs={12} className={clsx(classes.blockListUser, classes.hiddenGroupSelect)}>
            <div>
              <FormControl
                className="search"
                error={!!errors.shop_ids}
              >
                <DropdownSelect
                  label={<div className={classes.labelSelect}>所属店舗</div>}
                  placeholder={"所属店舗を選択"}
                  data={groupsOptions1} 
                  onChange={(selectedNodes) => {
                    const selectedShops = selectedNodes.filter(item => item.type !== 'group');

                    const selected = selectedShops.map(item => ({
                      type: 'shop',
                      field_name: 'shop_ids',
                      label: item.label,
                      value: +item.value,
                      owner_group_id: item.owner_group_id,
                    }))
                    setDuplicateShops(selected);

                    onSelectControlChanged([
                      ['shop_ids', [...new Set(selected.map(item => item.value))]],
                    ]);
                  }}
                />
                {!!errors.shop_ids && (
                  <FormHelperText>{`users${errors.shop_ids[0]}`}</FormHelperText>
                )}
              </FormControl>
              {renderOptionLabels(getUnique(duplicateShops || []))}
            </div>
          </Grid>

          <Grid item xs={12} className={classes.blockListUser}>
            <div>
              <FormControl
                className="search"
              >
                <DropdownSelect
                  label={<div className={classes.labelSelect}>閲覧範囲</div>}
                  placeholder={"閲覧範囲を選択"}
                  data={groupsOptions2} 
                  onChange={(selectedNodes) => {
                    const tmpSelectedNodes = selectedNodes.map(item => ({
                      type: item.type,
                      label: item.label,
                      value: +item.value,
                      owner_group_id: item.owner_group_id,
                      field_name: item.type === 'group' ? 'users_viewing_group_ids' : 'users_viewing_shop_ids'
                    }));

                    userViewingGroups.current = tmpSelectedNodes.filter(item => item.type === 'group');
                    const selectedShops = tmpSelectedNodes.filter(item => item.type !== 'group');
                    setDuplicateShops2(selectedShops);

                    onSelectControlChanged([
                      ['users_viewing_group_ids', [...new Set(userViewingGroups.current.map(item => +item.value))]],
                      ['users_viewing_shop_ids', [...new Set(selectedShops.map(item => +item.value))]],
                    ]);
                  }}
                />
              </FormControl>
              {renderOptionLabels(getUnique([...(userViewingGroups.current || []), ...(duplicateShops2 || [])]))}
            </div>
          </Grid>

          {renderCheckList(checkBoxes.slice(2, 3))}

          {item.company_visible_negative_feedback !== true && renderCheckList(checkBoxes.slice(3, 4))}

          {renderCheckList(checkBoxes.slice(4, 5))}
        </div>
      </form>
      <UnSavedWarningDialog
        open={isConfirmationOpen}
        onConfirm={() => {
          closeConfirmationDialog();
          resetForm();
          closeModal && closeModal();
        }}
        onCancel={() => closeConfirmationDialog()}
      />
      </>}
      { !shopsLoaded &&
        <div className={classes.formContain}>
          <Paper className={classes.root}>
            <div className={classes.loading}>
              <CircularProgress disableShrink />
            </div>
          </Paper>
        </div>
      }
    </SwipeableModal>
  );
}

UserForm.propTypes = propTypes;

export default connect(
  ({ users: { item, itemSubmitSuccess, errors, itemSubmitting }, shops, groups, global: { userData } }) => ({
    item,
    itemSubmitting,
    itemSubmitSuccess,
    submitErrors: errors,
    shopsOptions: shops.allItems || [],
    shopsLoaded: shops.allItemsLoaded || false,
    groupsOptions: groups.allItems,
    userAuthority: userData.authority
  }),
  dispatch => ({
    save: (docFields) => dispatch(thunkSaveUser(docFields)),
    fetchAllGroups: () => dispatch(thunkFetchAllGroups()),
    fetchAllShops: () => dispatch(thunkFetchAllShops()),
  })
)(UserForm);

const getUnique = (items) => {
  const includedTypeValues = [];

  return items.map(item => {
    const typeValue = `${item.type}-${item.value}`;

    if (includedTypeValues.includes(typeValue)) {
      return null;
    }

    includedTypeValues.push(typeValue);
    return item;
  }).filter(el => el !== null);
};
