import React from 'react'
import styled from 'styled-components'

const Span = styled.span`
  display: block;
  height: 72%;
  border-radius: 2px;
  background-color: #ff5f00;
  position: absolute;
  overflow: hidden;
  top: 1px;
  left: 1px;
`

const Container = styled.div`
  height: 10px;
  position: relative;
  width: 100%;
  border: 1px solid #ff5f00;
  border-radius: 3px;
  margin: 5px auto;
  overflow: hidden;
`

const ProgressBar = ({ progress = 0 }) => {
  const width = Math.min(Math.max(0, progress), 0.995) * 100 + '%'
  return (
    <Container>
      <Span style={{ width }} />
    </Container>
  )
}

export default ProgressBar
