import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { UPDATE_SHOP_ITEM } from "./redux/actions";
import DialogModal from "components/DialogModal";
import ShopCreateForm from "./ProductCreateForm";
import ShopForm from "./ProductForm";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  addBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
}));

const ShopAppBarBottom = ({ updateShopItem }) => {
  const classes = useStyles();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const handleOpenModal = () => {
    setDrawerIsOpen(true)
    updateShopItem({});
  };

  const handleClose = () => {
    setDialogIsOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Button
          variant="contained"
          className={classes.addBtn}
          startIcon={<AddIcon />}
          onClick={() => handleOpenModal()}
        >
          新規追加
        </Button>
      </div>

      <DialogModal
        open={dialogIsOpen}
        onClose={handleClose}
      >
        <ShopCreateForm
          dialogIsOpen={dialogIsOpen}
          onClose={handleClose}
        />
      </DialogModal>
      
      <ShopForm modalOpen={drawerIsOpen} closeModal={() => setDrawerIsOpen(false)} item={{}} />
    </>
  );
};

export default connect(null,
  (dispatch) => ({
    updateShopItem: (item) => dispatch({ type: UPDATE_SHOP_ITEM, payload: { item } } ),
  })
)(ShopAppBarBottom);
