import React from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import IconPicture from 'assets/icons/icon_picture.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: '2px',
    borderRadius: '2px',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    background: '#EFEFEF 0% 0% no-repeat padding-box',
    boxShadow: '0px 5px 6px #B7B7B733',
    opacity: 1,
    height: '36px',
    justifyContent: 'center',
    '& > div': {
      display: 'flex'
    }
  },
  textBold: {
    fontWeight: "bold",
    fontSize: 12,
    margin: '0 !important'
  },
  icon: {
    width: 16,
    filter: 'invert(57%) sepia(7%) saturate(15%) hue-rotate(12deg) brightness(100%) contrast(93%)',
    marginRight: 20
  }
}))

const Dropzone = ({ onChange }) => {
  const classes = useStyles();

  const {
    getRootProps,
    getInputProps,
    // isDragActive,
    // isDragAccept,
    // isDragReject
  } = useDropzone({ accept: 'image/*' });

  return (
    <div className={classes.root} {...getRootProps()}>
      <input
        {...getInputProps()}
        onChange={onChange}
      />
      <div>
        <img src={IconPicture} className={classes.icon} alt="" />
        <p className={classes.textBold}>画像アップロード</p>
      </div>
    </div>
  );
}

export default Dropzone;
