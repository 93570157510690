import { useEffect, useLayoutEffect, useRef } from 'react'
import { NPS_COLORS } from 'modules/aggregation/constants'

export const sign = number => {
  if (+number > 0) {
    return `+${number}`
  } else {
    return number
  }
}

export const getNpsColor = green => (green ? NPS_COLORS.promoter : NPS_COLORS.detractor)

export const useGroupEffect = (effect, header_group, header_shop, group, others) => {
  const groupChanged = useRef(false)
  const shopChanged = useRef(false)
  const didMount = useRef(false)

  useLayoutEffect(() => {
    groupChanged.current = false
    shopChanged.current = false
  })

  useEffect(() => {
    if (header_group !== group)
      groupChanged.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header_group])

  useEffect(() => {
    shopChanged.current = true
  }, [header_shop])

  useEffect(() => {
    if (!groupChanged.current && !shopChanged.current)
      effect && effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, header_shop, header_group])

  useEffect(() => {
    if (shopChanged.current && !groupChanged.current)
      effect && effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header_shop])

  useEffect(() => {
    if (didMount.current)
      effect && effect()
    didMount.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, others)
}

export const useUserRankEffect = (effect, header_group, header_shop, group, shop, others) => {
  const groupChanged = useRef(false)
  const shopChanged = useRef(false)
  const didMount = useRef(false)

  useLayoutEffect(() => {
    groupChanged.current = false
    shopChanged.current = false
  })

  useEffect(() => {
    if (header_group !== group)
      groupChanged.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header_group])

  useEffect(() => {
    if (header_shop !== shop)
      shopChanged.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header_shop])

  useEffect(() => {
    if (!groupChanged.current && !shopChanged.current)
      effect && effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, shop, header_shop, header_group])

  useEffect(() => {
    if (didMount.current)
      effect && effect()
    didMount.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, others)
}
