import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {
  Paper,
  makeStyles,
  Typography,
  Box,
  CircularProgress,
} from '@material-ui/core';
import qs from 'query-string';
import { thunkFetchSurvey } from '../redux/thunk';
import FeedbackStartPage from './StartPage';
import FeedbackEndPage from './EndPage';
import FeedbackSurveyPage from './SurveyPage';
import ConfirmPage from './ConfirmPage';
import { themedStyles } from "./styles";
import { Helmet } from 'react-helmet';
import ProgressBar from "./SurveyPage/ProgressBar";
import { isIE } from 'react-device-detect'


function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const colorComon = "#EC681A";

const useStyles = makeStyles(themedStyles);

const propTypes = {
  survey: PropTypes.object,
  currentPage: PropTypes.string.isRequired,
  surveyPage: PropTypes.object,
  surveyPageCount: PropTypes.number.isRequired,
  surveyCurrentPage: PropTypes.number.isRequired,
  error: PropTypes.object,
  match: PropTypes.object.isRequired,
  loadSurvey: PropTypes.func.isRequired,
}

const PublicFeedback = ({
  survey,
  currentPage,
  surveyPage,
  surveyPageCount,
  surveyCurrentPage,
  error,
  match,
  loadSurvey,
}) => {
  const [headerHeigh, setHeaderHeigh] = useState(0);
  const [valid, setValid] = useState(true)
  const styleProps = {color: colorComon, isIE, isConfirm: currentPage === "end" };
  const classes = useStyles(styleProps);
  const { companyUrlText, shortUrl } = match.params;

  const q = qs.parse(window.location.search, {ignoreQueryPrefix: true});
  const staff_id = q.staff_id;

  const isPreviewMode = match.path.includes('/s/preview/')

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'GA_INIT', payload: { userId: null } });

    const root = document.getElementById("root")
    root && root.setAttribute("style", "overflow-x: hidden;");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadSurvey(companyUrlText, shortUrl, isPreviewMode);
    // eslint-disable-next-line
  }, [companyUrlText, shortUrl]);

  const updateHeaderHeight = height => setHeaderHeigh(height)

  if (!!error || !valid) {
    return <>
     <div className={classes.textError}>
      <div className={classes.errorContent}>
          <p className="errorTitle">Webフォームを開けません、以下の可能性があります</p>
          <br />
          <ul className="errorMessage">
            <li>フォームのURLが間違っているため</li>
            <li>フォームが受付開始前のため</li>
            <li>フォームが受付終了しているため</li>
          </ul>
      </div>
     </div>
    </>;
  }

  if (!!!survey) {
    return (
      <div className={classes.root}>
        <Paper>
          <div className={classes.loading}>
            <CircularProgress disableShrink />
          </div>
        </Paper>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {
        !!survey.page_title &&
        <Helmet>
          <title>{survey.page_title}</title>
        </Helmet>
      }
      {
        currentPage !== null && currentPage !== "start" && currentPage !== "finish" &&
        <div className={classes.blockTab} style={{ top: headerHeigh }}>
          <div className={classes.progressBarContainer}>
            <div>{(surveyCurrentPage * 100/surveyPageCount).toFixed(0)}%</div>
            <div>{currentPage === "end" ? '確認' : surveyCurrentPage + '/' + surveyPageCount + ' ページ'}</div>
          </div>
          <ProgressBar progress={surveyCurrentPage/surveyPageCount}/>
        </div>
      }

      <Paper className={classes.paper}>
        {(currentPage === "start") && (
          <TabPanel index={"start"} value={currentPage}>
            <FeedbackStartPage shop_id={q.shop_id} area_id={q.area_id} setValid={setValid}/>
          </TabPanel>
        )}
        {(currentPage !== "start" && currentPage !== "end" && currentPage !== 'finish') && surveyPage !== null && (
          <TabPanel index={currentPage} value={currentPage}>
            <FeedbackSurveyPage
              surveyPage={surveyPage}
              currentPage={currentPage}
              staff_id={!!staff_id ? +staff_id : null}
              companyUrlText={companyUrlText} shortUrl={shortUrl}
              updateHeaderHeight={updateHeaderHeight}
              top={headerHeigh}
            />
          </TabPanel>
        )}
        {(currentPage === "finish") && (
          <FeedbackEndPage
            companyUrlText={companyUrlText}
            shortUrl={shortUrl}
          />
        )}
        {(currentPage === "end") && (
          <ConfirmPage
            updateHeaderHeight={updateHeaderHeight}
            top={headerHeigh}
          />
        )}
      </Paper>

    </div>
  );
}

PublicFeedback.propTypes = propTypes;

export default connect(
  ({
    public: { survey, currentPage, error },
  }) => {
    const surveyPageLength = ((survey || {}).survey_pages_attributes || []).length;
    return {
      survey,
      surveyPageCount: surveyPageLength + ((!!survey && survey.use_confirm_page === true) ? 1 : 0),
      surveyCurrentPage: (isNaN(+currentPage) ? surveyPageLength : + currentPage) + 1,
      error,
      currentPage,
      surveyPage: !!survey ? survey.survey_pages_attributes[currentPage] : null,
    }
  },
  (dispatch) => ({
    loadSurvey: (companyText, shortUrl, isPreviewMode) => {
      dispatch(thunkFetchSurvey(companyText, shortUrl, isPreviewMode))
    },
  })
)(withRouter(PublicFeedback));
