import React from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { makeStyles } from '@material-ui/core/styles'
import { themeGraphStyles } from './styles'
import { getNpsColor, sign } from '../utils'
import { isMobile } from 'react-device-detect'

const CustomTooltip = ({ active, payload = [] }) => {
  if (!active) return null
  return (
    <div style={{ border: '1px solid #f7f7f7', padding: 10, backgroundColor: 'white' }}>
      {payload.map(({ fill, name, value }, i) => (
        <div key={i}>
          <span style={{ color: fill, marginRight: 15 }}>{name}</span>
          <span style={{ fontSize: 11, fontWeight: 'bold' }}>{value}</span>
        </div>
      ))}
    </div>
  )
}

const RadialLegend = ({
  promoters,
  passives,
  detractors,
  promoters_count,
  passives_count,
  detractors_count,
  feedback_count,
  delta_promoters,
  delta_passives,
  delta_detractors,
  delta_feedback_count,
}) => {
  const classes = makeStyles(themeGraphStyles)()
  const data = [{ promoters_count, passives_count, detractors_count }]

  const npsProps = bottom => ({
    className: classes.nps3Values,
    style: {
      bottom: bottom + (isMobile ? 5 : 0),
    },
  })

  const deltaProps = (value, bottom) => ({
    className: classes.npsDelta,
    style: {
      bottom: bottom + (isMobile ? 5 : 0),
      color: getNpsColor(+value >= 0),
    },
  })

  return (
    <div className={classes.legendWrapper}>
      <div>
        <p className={classes.legendHeader}>回答数</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={classes.legendNumber}>{feedback_count}</span>
          <span
            className={classes.legendDelta}
            style={{ color: getNpsColor(delta_feedback_count >= 0) }}>
            {sign(delta_feedback_count)}
          </span>
        </div>
        <p className={classes.legendSubheader}>NPSカテゴリ</p>
      </div>
      <ResponsiveContainer width="70%" height={100}>
        <BarChart
          data={data}
          layout="vertical"
          barGap="13"
          margin={{ right: 8, left: 0, top: 0, bottom: 0 }}>
          <Tooltip cursor={{ fill: 'none' }} content={<CustomTooltip />} />
          <XAxis type="number" hide tick={false} />
          <YAxis type="category" hide tick={false} />
          <Bar dataKey="promoters_count" fill="#1FA371" name="推奨者" />
          <Bar dataKey="passives_count" fill="#FFCE00" name="中立者" />
          <Bar dataKey="detractors_count" fill="#F02544" name="批判者" />
        </BarChart>
      </ResponsiveContainer>
      <div {...npsProps(71)}>{promoters}</div>
      <div {...npsProps(40)}>{passives}</div>
      <div {...npsProps(9)}>{detractors}</div>
      <div {...deltaProps(delta_promoters, 72)}>{sign(delta_promoters)}</div>
      <div {...deltaProps(delta_passives, 41)}>{sign(delta_passives)}</div>
      <div {...deltaProps(delta_detractors, 10)}>{sign(delta_detractors)}</div>
    </div>
  )
}

export default connect(
  ({
    dashboard: {
      nps_satisfactions: {
        promoters,
        passives,
        detractors,
        promoters_count,
        passives_count,
        detractors_count,
        feedback_count,
        delta_promoters,
        delta_passives,
        delta_detractors,
        delta_feedback_count,
      },
    },
  }) => ({
    promoters: +(promoters || 0),
    passives: +(passives || 0),
    detractors: +(detractors || 0),
    promoters_count: +(promoters_count || 0),
    passives_count: +(passives_count || 0),
    detractors_count: +(detractors_count || 0),
    feedback_count: +(feedback_count || 0),
    delta_promoters: +(delta_promoters || 0),
    delta_passives: +(delta_passives || 0),
    delta_detractors: +(delta_detractors || 0),
    delta_feedback_count: +(delta_feedback_count || 0),
  })
)(RadialLegend)
