export const themedQuestionsStyles = theme => ({
  root: {
    width: 770,
    margin: '0 auto',
    [theme.breakpoints.down("md")]: {
      width: "auto"
    }
  },
  radio: {
    '&$checked': {
      color: '#EC681A'
    }
  },
  checked: {},
  buttonExcel: {
    width: 145,
    height: 36,
    marginLeft: '10px !important',
    border: '1px solid #B7B7B7 !important',
    borderRadius: '18px !important',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    '& svg': {
      color: '#B7B7B7',
    },
    '& > span.MuiButton-label': {
      fontSize: 10,
      letterSpacing: 0.25,
    },
  },
  anketRadioButton: {
    border: '1px solid #B7B7B7',
    borderRadius: 5,
    backgroundColor: 'white',
    marginLeft: '0 !important',
    padding: '10px 20px 10px 16px',
    width: 160,
    height: 36,
    '& span.MuiIconButton-label': {
      width: 14,
      height: 14,
    },
    '& > span:nth-child(1)': {
      padding: 0,
    },
    '& > span:nth-child(2)': {
      marginLeft: 15,
      fontSize: 10,
      letterSpacing: 0.25,
    },
  },
  headerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    width: 770,
    margin: '0 auto',
    [theme.breakpoints.down("md")]: {
      width: "auto",
    }
  },
  tab3Item: {
    background: "#FFFFFF",
    display: "block",
    marginTop: 10,
  },
  icon: {
    margin: 0,
  }
})
export const questionChildStyles = theme => ({
  itemChildWrapper: {
    marginTop: 5,
    position: "relative",
    display: "block",
    height: 36,
  },
  itemIconChild: {
    height: 22,
    width: 22,
    "& svg": {
      fontSize: 20,
      color: "#EC681A",
    }
  },
  itemChildLeft: {
    width: 34,
    height: 34,
    position: "absolute",
    borderRight: "1px solid #B7B7B7",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    background: '#F7F7F7',
    transform: 'translateX(1px)',
    borderRadius: '4px',
  },
  itemChildRight: {
    right: 52,
    top: -1,
    width: 36,
    height: 36,
    position: "absolute",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #b7b7b7',
    borderBottom: '1px solid #b7b7b7',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    [theme.breakpoints.down("xl")]: {
      right:36,
    },
    [theme.breakpoints.down("md")]: {
      right:30,
    },
    [theme.breakpoints.down("sm")]: {
      right:2,
    }
  },
  allItemError: {
    height: '20px',
  },
  itemError: {
    color: "red",
    position: "absolute",
    width: 654,
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      "& span:nth-child(1)": {
        marginLeft: 14,
        letterSpacing: 0.3,
        color: "#1A0707",
        fontSize: 12,
        lineHeight: "36px"
      },
    }
  },
  itemErrorInput: {
    color: "red",
    position: "absolute",
    width: 654,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      "& span:nth-child(1)": {
        marginLeft: 14,
        letterSpacing: 0.3,
        color: "#1A0707",
        fontSize: 12,
        lineHeight: "36px"
      },
    }
  },
  itemChildCenter: {
    padding: "0 36px",
    position: "absolute",
    border: "1px solid #B7B7B7",
    borderRadius: 5,
    top: -1,
    left: 0,
    width: "95%",
    height: 36,
    display: "flex",
    justifyContent: "space-between",
    color: 'red',
    alignItems: 'center',
    "& span":{
      display:"flex",
    },
    "& span:nth-child(1)": {
      marginLeft: 20,
      letterSpacing: 0.3,
      color: "#1A0707",
      fontSize: 12,
      lineHeight: "36px"
    },
    "& span:nth-child(2) span": {
      marginRight: 14,
      color: "#1A0707",
      fontSize: 13,
    },
    [theme.breakpoints.down("md")]: {
      width:"auto",
      minWidth: "95%",
      "& span:nth-child(1)": {
        marginLeft: 14,
        letterSpacing: 0.3,
        color: "#1A0707",
        fontSize: 12,
        lineHeight: "36px"
      },
    }
  },
  itemChildInput: {
    height: 28,
    width: 54,
    border: "1px solid #B7B7B7",
    fontSize: 13,
    margin: "5px 10px 0px 0px",
    padding: "9px 15px",
  },
  addButton: {
    width: "100%",
    height: "36",
    background: "#F2EEEC",
    marginTop: 10,
    borderRadius: 5,
    color: "#EC681A",
  },
  deleteButton: {
    width: 36,
    height: 36,
    color: "#F02544",
    border: "1px solid #F02544",
    borderRadius: "5px",
    position: "absolute",
    right: 0,
    top: -1,
  },
  deleteIcon: {
    fontSize: "13px",
    fill: "#F02544",
  },
  visibleActiveIcon: {
    color: '#EC681A',
    cursor: 'pointer',
    width: 16
  },
  visibleInActiveIcon: {
    color: '#B7B7B7',
    cursor: 'pointer',
    width: 16
  },
  mainInput: {
    padding: '0 10px',
    width: '80%',
  }
})

export const questionListStyles = theme => ({
  root: {
    width: '100%',
    height: 'auto',
    minHeight: 86,
    flexGrow: 1,
    position: 'relative',
  },
  topTitle: {
    padding: '0 10px',
    marginBottom: 10,
    cursor: 'pointer'
  },
  inactiveTitle: {
    borderBottom: '2px solid #B7B7B7',
  },
  activeTitle: {
    borderBottom: '2px solid #EC681A'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  settingBtn: {
    color: 'black',
    fontWeight: 'bold',
    borderLeft: '1px solid #B7B7B7',
    borderRadius: 0
  },
  rightSetting: {
    display: 'flex'
  },
  iconSetting: {
    color: '#EC681A'
  },
  iconDelete: {
    width: 36,
    height: 34,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& img": {
      width: 16,
      height: 16,
      cursor: 'pointer'
    }
  },
  createButtonWrapper: {
    position: "relative",
  },
  createButton: {
    width: 68,
    height: 68,
    position: "absolute",
    bottom: -10,
    right: -115,
    zIndex: 100,
    borderRadius: "50%",
    background: "#FFFFFF",
    color: "#EC681A",
    boxShadow: "0px 3px 5px #B7B7B733",
    [theme.breakpoints.down("md")]: {
      right: -48,
    }
  }
})
