export const themedStyles = theme => ({
  root: {
    overflowY: 'auto',
    width: '100vw',
    backgroundColor: "#F7F7F7",
    flexGrow: 1,
    "& .MuiPaper-root": {
      height: 'auto'
    },
    "& .MuiTypography-root": {
      height: '100%'
    },
    "& .MuiBox-root": {
      height: '100%',
      padding: '0'
    }
  },
  textError: {
    width: "100%",
    height: "365px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
  errorContent: {
    textAlign: "left",
    letterSpacing: "0.35px",
    color: "#1A0707",
    "& .errorTitle": {
      font: "600 14px/24px 'Noto Sans JP', sans-serif"
    },
    "& .errorMessage": {
      font: "600 10px/20px 'Noto Sans JP', sans-serif"
    }
  },
  tabs: props => ({
    display: "block",
    paddingLeft: 20,
    background: "#fff",
    minHeight: "auto",
    margin: "0 auto",
    "& .MuiTab-root": {
      display: "inline-block",
      width: 7,
      height: 7,
      margin: "0 4px",
      borderRadius: "50%",
      // background: "#b7b7b7",
      minWidth: "auto",
      minHeight: "auto",
      padding: 0,
    },
    "& .Mui-selected": {
      background: props.color,
    },
  }),
  indicator: {
    backgroundColor: "transparent",
    color: "transparent",
  },
  blockTab: props => ({
    width: "559px",
    display: "flex",
    position: "fixed",
    flexDirection: "column",
    justifyContent: 'center',
    padding: '0 20px',
    background: "rgb(255, 255, 255)",
    zIndex: 2,
    marginLeft: props.isIE ? 'calc(50vw - 25px)' : 'calc(50vw)',
    transform: props.isIE && props.isConfirm ? "translateX(-48%)" : "translateX(-50%)",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }),
  paper: {
    background: "#F7F7F7"
  },
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#ec681a',
    font: 'Hiragino Sans CNS',
    fontSize: 12,
    fontWeight: 600,
  }
});
