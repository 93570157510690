import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import SurveyListTools from './SurveyListTools';
import SurveyList from './SurveyList';
import { thunkFetchSurveys } from './redux/thunk';
import { useGAEffect } from '../../ga';


const useStyles = makeStyles({
  container: {
    flex: 1
  }
})

const propTypes = {
  fetchSurveys: PropTypes.func.isRequired,
  surveysR: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  ga: PropTypes.object.isRequired,
};

const Surveys = ({
  fetchSurveys,
  surveysR,
  currentPage,
  ga,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(currentPage);
  const [q, setQ] = useState(null);
  const [projectId, setProjectId] = useState('');
  const [status, setStatus] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const firstTimeRefresh = useRef(true);

  useGAEffect(ga, null, "アンケート一覧")

  useEffect(() => {
    if (firstTimeRefresh.current === false) {
      const searchParams = {};

      if (!!projectId && !!projectId.value) searchParams.project_id = projectId.value;
      if (!!q) searchParams.q = q;
      if (!!status) searchParams.status = "inactive";
      if (!!openStatus) searchParams.open_status = "with_inactive";

      fetchSurveys(page, searchParams);
    }

    // eslint-disable-next-line
  }, [page, projectId, q, status, openStatus]);

  useEffect(() => {
    if ((!surveysR.loading && !surveysR.loaded) || firstTimeRefresh.current === true) {
      fetchSurveys(currentPage);
      firstTimeRefresh.current = false;
    }
    // eslint-disable-next-line
  }, [surveysR.loading, surveysR.loaded]);

  return (
    <Grid container>
      <div className={classes.container}>
        <SurveyListTools
          projectId={projectId}
          setProjectId={setProjectId}
          q={q}
          setQ={setQ}
          status={status}
          setStatus={setStatus}
          openStatus={openStatus}
          setOpenStatus={setOpenStatus}
        />
        <SurveyList page={page} setPage={setPage}/>
      </div>
    </Grid>
  );
};

Surveys.propTypes = propTypes;

export default connect(
  ({
    surveys,
    ga,
  }) => ({
    surveysR: {
      loading: surveys.loading,
      loaded: surveys.loaded,
      itemSubmitSuccess: surveys.itemSubmitSuccess
    },
    currentPage: (surveys.pagination || {}).currentPage || 1,
    ga
  }),
  dispatch => ({
    fetchSurveys: (page, searchParams) => dispatch(thunkFetchSurveys(page, searchParams)),
  })
)(Surveys);
