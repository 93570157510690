import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import HTML5Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Users from "./modules/users/Users";
import Surveys from "./modules/surveys/Surveys";
import SurveyDetail from "./modules/surveys/SurveyDetail";
import Projects from "./modules/projects/Projects";
import Companies from "./modules/companies/Companies";
import Areas from "./modules/areas";
import Groups from "./modules/groups";
import Shops from "./modules/shops/Shops";
import Feedbacks from "./modules/feedbacks/Feedbacks";
import Analysis from "./modules/analysis/Analysis";
import Aggregation from "./modules/aggregation/Aggregation";
import Dashboard from "./modules/dashboard/Dashboard";

// 商品スコア用コンポーネント
import ProductProjects from "./modules/products/projects/ProductProjects";
import ProductSurveys from "./modules/products/surveys/Surveys";
import ProductSurveyDetail from "./modules/products/surveys/SurveyDetail";
import Products from "./modules/products/products/Products";
import Categories from "./modules/products/categories";
import ProductGroups from "./modules/products/groups";
import ProductFeedbacks from "./modules/products/feedbacks/Feedbacks";
import ProductUsers from "./modules/products/users/Users";
import ProductAnalysis from "./modules/products/analysis/Analysis";
import ProductAggregation from "./modules/products/aggregation/Aggregation";
import ProductDashboard from "./modules/products/dashboard/Dashboard";


import Header from "common/Header";
import { useSelector } from "react-redux";
import { Container, Grid, makeStyles } from "@material-ui/core";

// material-uiのスタイル定義
const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: "column",
    width: drawerWidth => `calc(100vw - ${drawerWidth}px)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "98px",
    },
    [theme.breakpoints.up(768)]: {
      marginLeft: "0",
    },
  },
  content: {
    flexGrow: 1,
    height: "calc(100vh - 108px)",
    width: "100%",
    overflow: "auto",
    overflowX: 'hidden',
    display: 'block',
    scrollbarColor: "#B7B7B7 #F2EEEC",
    scrollbarWidth:"10px",
    '&::-webkit-scrollbar': {
      width: "10px",
      background: "#F2EEEC 0% 0% no-repeat padding-box",
    },
    '&::-webkit-scrollbar-thumb': {
      background: "#B7B7B7 0% 0% no-repeat padding-box",
    },
    [theme.breakpoints.between("sm", 768)]: {
      marginLeft: "98px",
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '140px',
      position: 'relative',
      height: 'auto',
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: "110px",
    position: "relative",
    minHeight: "calc(100% - 110px)",
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 200,
    },

    '& > div > div': {
      left: '100%',
    }
  }
}));


const propTypes = {
  user: PropTypes.object
};

const Routes = ({ user, use_dashboard,isProductSurvey }) => {
  // ログインユーザ(user)の権限が管理者か否かを判定 => userにはstateのuserDataをマッピング
  const isAdminOrSuperAdmin = user.authority === "admin" || user.authority === "super_admin";
  const dispatch = useDispatch();

  // propのuser.idが代わった場合
  useEffect(() => {
    if (!!user.id) dispatch({ type: 'GA_INIT', payload: { userId: user.id } });

    //eslint-disable-next-line
  }, [user.id]);

  let drawerWidth = useSelector(state => state.common.drawerWidth);
  const isMobile = useSelector(state => state.common.isMobile);

  if (isMobile) drawerWidth = 0;
  const classes = useStyles(drawerWidth);
  
  // react-router-domがv5の為Brouser Routerのpath完全一致の場合はexactの引数が必要
  // https://v5.reactrouter.com/web/api/Route/exact-bool
  
  // 対象画面の表示に判定が必要な場合はラッパーのAuthorizedRouteでルートを定義
  // admin/dashboard　=> ダッシュボード画面は選択中のアカウントでダッシュボード利用のフラグが設定されているか{use_dashboard}
  // admin/projects => プロジェクト一覧　ログインユーザが管理者(isAdminOrSuperAdmin)
  // admin/surveys => アンケート一覧　ログインユーザが管理者(isAdminOrSuperAdmin)
  // admin/shops => 店舗一覧　ログインユーザが管理者(isAdminOrSuperAdmin)
  // admin/users => スタッフ一覧　ログインユーザが管理者(isAdminOrSuperAdmin)
  // admin/companies => アカウント一覧　ログインユーザが管理者(isAdminOrSuperAdmin)

  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.content} id='root-content'>
        <Switch>
          {!isProductSurvey?
            <Route path={[
                '/admin/feedbacks/:id/feedback_comments',
                '/admin/feedbacks/:id/answers',
                '/admin/feedbacks',
              ]}
              component={Feedbacks}
            />
            :
            <Route path={[
                '/admin/products/feedbacks/:id/feedback_comments',
                '/admin/products/feedbacks/:id/answers',
                '/admin/products/feedbacks',
            ]}
              component={ProductFeedbacks}
            />
          }
          <Route path="*" >
            <Container className={classes.container}>
              <Grid container spacing={3}>
                {!isProductSurvey?
                  <Switch>
                    <Route path={'/admin/analysis'} component={Analysis} />
                    <Route path={'/admin/aggregation'} component={Aggregation} />
                    <AuthorizedRoute path={'/admin/dashboard'} component={Dashboard} auth={use_dashboard}/>
                    <DndRoute path={'/admin/areas'} component={Areas} auth={isAdminOrSuperAdmin}/>
                    <DndRoute path={'/admin/groups'} component={Groups} auth={isAdminOrSuperAdmin}/>
                    <DndRoute path={'/admin/surveys/:survey_id'} component={SurveyDetail} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedRoute path={'/admin/projects'} component={Projects} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedRoute path={'/admin/surveys'} exact component={Surveys} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedRoute path={'/admin/shops'} component={Shops} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedRoute path={'/admin/users'} component={Users} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedRoute path={'/admin/companies'} component={Companies} auth={isAdminOrSuperAdmin}/>
                    <Route path="*" >
                      <Redirect to={'/admin/feedbacks'} />
                    </Route>
                  </Switch>
                :                
                  <Switch>
                    {/* 商品スコア用routes */}
                    <Route path={'/admin/products/analysis'} component={ProductAnalysis} />
                    <Route path={'/admin/products/aggregation'} component={ProductAggregation} />
                    <AuthorizedProductRoute path={'/admin/products/dashboard'} component={ProductDashboard} auth={use_dashboard}/>
                    <DndRoute path={'/admin/products/categories'} component={Categories} auth={isAdminOrSuperAdmin}/>
                    <DndRoute path={'/admin/products/groups'} component={ProductGroups} auth={isAdminOrSuperAdmin}/>
                    <DndRoute path={'/admin/products/surveys/:survey_id'} component={ProductSurveyDetail} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedProductRoute path={'/admin/products/projects'} component={ProductProjects} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedProductRoute path={'/admin/products/surveys'} exact component={ProductSurveys} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedProductRoute path={'/admin/products/products'} component={Products} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedProductRoute path={'/admin/products/users'} component={ProductUsers} auth={isAdminOrSuperAdmin}/>
                    <AuthorizedProductRoute path={'/admin/companies'} component={Companies} auth={isAdminOrSuperAdmin}/>
                    <Route path="*" >
                      <Redirect to={'/admin/products/feedbacks'} />
                    </Route>
                  </Switch>
                }
              </Grid>
            </Container>
          </Route>
        </Switch>
      </main>
    </div>
  );
};



// 表示に判定が必要な画面のラッパーコンポーネント
// 条件に沿わない場合はフィードバック一覧にリダイレクト

const AuthorizedRoute = ({ component: Component, auth, ...rest }) => {
  const redirect = '/admin/feedbacks';
  return <Route {...rest} render={props => auth ? <Component {...props} /> : <Redirect to={redirect} />}/>
}
const AuthorizedProductRoute = ({ component: Component, auth, ...rest }) => {
  const redirect = '/admin/products/feedbacks';
  return <Route {...rest} render={props => auth ? <Component {...props} /> : <Redirect to={redirect} />}/>
}

// ドラッグ＆ドロップを仕様する画面のラッパーコンポーネント
const DndRoute = props => (
  <DndProvider backend={HTML5Backend}>
    <AuthorizedRoute {...props} />
  </DndProvider>
)

Routes.propTypes = propTypes;

// StateをPropにマッピング
// use_dashboard => 選択したアカウントのダッシュボード利用フラグ(selectedCompany.use_dashboard)
// user => ログインユーザーの情報(userData)

export default connect(
  ({ global: { userData, selectedCompany } }) => ({
    use_dashboard: selectedCompany.use_dashboard === 'true',
    user: userData,
    isProductSurvey:selectedCompany.survey_type==="1",
  }),
  null
)(Routes);
