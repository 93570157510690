import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  saveBtn: {
    backgroundColor: "#EC681A",
    width: "127px",
    height: "42px",
    color: "white",
    justifyContent: "space-evenly",
    "&:hover": {
      backgroundColor: "#EC681A",
      opacity: "0.5"
    }
  },
  saved: {
    backgroundColor: "#b7b7b7",
    width: "127px",
    height: "42px",
    color: "white",
    justifyContent: "space-evenly",
    "&:hover": {
      backgroundColor: "#EC681A",
      opacity: "0.5"
    }
  },
  buttonSave: {
    background: "#EC681A 0% 0% no-repeat padding-box",
    border: "1px solid #B7B7B7",
    borderRadius: "5px",
    opacity: "1",
    padding: "9px 57px 9px 15px",
    marginRight: 20,
    "& span": {
      textAlign: "left",
      font: "500 12px/24px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#FFF",
      opacity: 1,
      "& i": {
        color: "#FFF",
        fontSize: "16px",
        marginRight: "15px"
      }
    },
    "&:disabled": {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      "& span":{
        color: "#EC681A",
      },
      "& i": {
        color: "#EC681A",
        fontSize: "16px",
        marginRight: "15px"
      }
    },
  },
  buttonSaveDirty: {
    background: "#EC681A 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: "1",
    padding: "9px 57px 9px 15px",
    marginRight: 20,
    "& span": {
      textAlign: "left",
      font: "500 12px/24px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#FFFFFF",
      opacity: 1,
      "& i": {
        color: "#fff",
        fontSize: "16px",
        marginRight: "15px"
      }
    },
    "&:hover": {
      background: "#EC681A 0% 0% no-repeat padding-box",
      opacity: "0.5"
    },
    "&:disabled": {
      background: "#B7B7B7 0% 0% no-repeat padding-box",
    },
  },
});

const SaveBtn = ({ itemSubmitting, dirty, ...others}) => {
  const { buttonSave, buttonSaveDirty } = useStyles();

  return (
    <Button
      className={!itemSubmitting ? buttonSaveDirty : buttonSave}
      disabled={!dirty}
      {...others}
    >
      <i className="material-icons">{dirty === null ? 'check' : 'error_outline'}</i>
      {dirty === null ? '保存済み' : '保存'}
    </Button>
  )
};
export default SaveBtn;
