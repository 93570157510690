import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { treeHeaderButtonsStyle } from "./styles";
import AntSwitch from "common/buttons/AntSwitch";
import { ExportButton, ImportButton } from 'common/csv/CsvButton';
import { thunkExportItems } from 'common/csv/redux/thunk';
import ImportDialog from "common/csv/ImportDialog";

const propTypes = {
  path: PropTypes.string,
  isVisibleAll: PropTypes.func,
  toggleVisibleAll: PropTypes.func,
  exportCsv: PropTypes.func,
  fetchItems: PropTypes.func,
  openCreateModal: PropTypes.func,
  exportButtonText: PropTypes.string,
  importButtonText: PropTypes.string,
};

const TreeHeaderButtons = ({
  path,
  isVisibleAll,
  toggleVisibleAll,
  exportCsv,
  fetchItems,
  openCreateModal,
  exportButtonText,
  importButtonText,
}) => {
  const classes = treeHeaderButtonsStyle();

  return (
    <div className={classes.header}>
    <div className={classes.toggleContainer}>
      <AntSwitch
        checked={isVisibleAll()}
        onChange={toggleVisibleAll}
        value="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      <div>全タブを開く</div>
    </div>

    <div className={classes.headerRight}>
      <ExportButton text={exportButtonText} onClick={() => exportCsv(path)} />
      <ImportDialog path={path} onSuccess={fetchItems} title={importButtonText}>
        <ImportButton text={importButtonText}/>
      </ImportDialog>
    </div>
  </div>
  );
};

TreeHeaderButtons.propTypes = propTypes;

export default connect(
  null,
  dispatch => ({
    exportCsv: path => dispatch(thunkExportItems(path)),
  })
)(TreeHeaderButtons);
