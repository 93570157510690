export const MAX_SELECTED_OPTIONS = 12
export const HORIZONTAL_OPTIONS_PER_PAGE = 6

export const DEFAULT_COLOR = "#A86E6E"

export const OPTION_COLORS = [
  "#A86E6E", 
  "#DD9534",
  "#7D9A60",
  "#6FC1BB",
  "#333788",
  "#6F492C",
  "#AA65AD",
  "#D21F3B",
  "#CEE0B6",
  "#1090A0",
  "#000000",
  "#BC07DD",
]

export const NPS_QUESTION = 'type_nps_question'
export const NPS_COLORS = {
  promoter: "#1FA371",
  passive: "#FFCE00",
  detractor: "#F02544",
  greyColor: "rgb(200, 200, 200)",
  lightenColor: "#F7F7F7",
  grayColor: "#F2EEEC",
  others: "#B58D7A"
}

export const GRAY_COLOR = "#EFEFEF"
export const HEX_COLOR = "#C69075"
export const TRIPLET_COLOR = "#B7B7B7"
