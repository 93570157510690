import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControlLabel,
  TextField,
  Paper,
  Radio,
  Input,
  FormHelperText,
} from "@material-ui/core";
import TabPanel from "./TabPanel";
import { FormField, TextFieldWithError } from '../components';
import { useStyles } from './StartPageSettings';
import AddImage from '../components/AddImage'
import ElementIcon from "../../../../common/icon/ElementIcon";
import clsx from 'clsx';

const propTypes = {
  activeTab: PropTypes.number.isRequired,
  use_end_page_html: PropTypes.bool,
  setUseEndPageHtml: PropTypes.func.isRequired,
  use_confirm_page: PropTypes.bool,
  setUseConfirmPage: PropTypes.func.isRequired,
  use_redirect: PropTypes.bool.isRequired,
  setUseRedirect: PropTypes.func.isRequired,
  redirect_url: PropTypes.string,
  setRedirectUrl: PropTypes.func.isRequired,
  end_page_main_image: PropTypes.object,
  onLoadEndPageMainImage: PropTypes.func.isRequired,
  onDeleteEndPageMainImage: PropTypes.func.isRequired,
  end_page_main_text: PropTypes.string,
  setEndPageMainText: PropTypes.func.isRequired,
  end_page_html: PropTypes.string,
  setEndPageHtml: PropTypes.func.isRequired,
  end_page_custom_css: PropTypes.string,
  setEndPageCustomCss: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const EndPageSettings = ({
  activeTab,
  use_end_page_html,
  setUseEndPageHtml,
  use_confirm_page,
  setUseConfirmPage,
  use_redirect,
  setUseRedirect,
  redirect_url,
  setRedirectUrl,
  end_page_main_image,
  onLoadEndPageMainImage,
  onDeleteEndPageMainImage,
  end_page_main_text,
  setEndPageMainText,
  end_page_html,
  setEndPageHtml,
  end_page_custom_css,
  setEndPageCustomCss,
  errors,
}) => {
  const classes = useStyles();

  return (
    <TabPanel value={activeTab} index={3}>
      <div className={classes.root}>

        <div className={classes.radioGroup}>
          <FormGroup className={classes.button}>
            <FormControlLabel
              control={
                <Radio
                  checked={use_end_page_html}
                  onClick={() => setUseEndPageHtml(!use_end_page_html)}
                />
              }
              name="use_end_page_html"
              label="HTMLを使用"
            />
          </FormGroup>
          <FormGroup className={classes.button}>
            <FormControlLabel
              control={
                <Radio
                  checked={use_confirm_page}
                  onClick={() => setUseConfirmPage(!use_confirm_page)}
                />
              }
              name="use_confirm_page"
              label="確認ページを使用"
            />
          </FormGroup>
          <FormGroup className={classes.button}>
            <FormControlLabel
              control={
                <Radio
                  checked={use_redirect}
                  onClick={() => setUseRedirect(!use_redirect)}
                />
              }
              name="use_redirect"
              label="終了ページをリダイレクト"
            />
          </FormGroup>
        </div>

        {!use_redirect && !use_end_page_html &&
          <AddImage
            image={end_page_main_image}
            setImage={onLoadEndPageMainImage}
            onDelete={onDeleteEndPageMainImage}
          />
        }

        {!use_redirect ?
          <Paper className={classes.form}>
            {!use_end_page_html ?
              <FormField component="fieldset" fullWidth className={classes.htmlInput}>
                <Input
                  placeholder="ここにメインテキストを書いてください。"
                  fullWidth
                  name="end_page_main_text"
                  multiline
                  classes={{
                    input: classes.input,
                  }}
                  endAdornment={<ElementIcon name='edit-outline' className={classes.editIcon} />}
                  defaultValue={end_page_main_text}
                  onChange={e => setEndPageMainText(e.target.value)}
                />
              </FormField>
            :
              <FormField component="fieldset" label={"HTML入力エリア"} fullWidth className={clsx(classes.formInput, classes.textField)} error={!!errors.end_page_html}>
                <TextField
                  name="end_page_html"
                  placeholder="HTML入力エリア"
                  multiline
                  rows={10}
                  variant="outlined"
                  helperText="以下のタグが利用可能です。 h3, h4, h5, h6, blockquote, p, a, ul, ol, nl, li, b, i, strong, em, strike, code, hr, br, div, table, thead, caption, tbody, tr, th, td, pre, iframe"
                  defaultValue={end_page_html}
                  onChange={e => setEndPageHtml(e.target.value)}
                />
              </FormField>
            }

            <FormField className={clsx(classes.formInput, classes.textField)} component="fieldset" fullWidth label={"カスタムCSS"}>
              <TextField
                name="end_page_custom_css"
                placeholder="CSSを入力ください。"
                multiline
                rows={10}
                variant="outlined"
                defaultValue={end_page_custom_css}
                onChange={e => setEndPageCustomCss(e.target.value)}
              />
            </FormField>
          </Paper>
        :
          <Paper className={classes.form}>
            <FormField className={clsx(classes.formInputURL, classes.textField)} component="fieldset" fullWidth label={"リダイレクトURL"} error={!!errors.redirect_url}>
              <TextFieldWithError
                name="redirect_url"
                placeholder="リダイレクトURLを入力してください"
                rows={1}
                variant="outlined"
                helperText="例）https://some.url/with/param?shop_id=<%=shop_id=%>&staff_id=<%=staff_id=%>"
                defaultValue={redirect_url}
                onChange={e => setRedirectUrl(e.target.value)}
                type={"url"}
                errors={errors.redirect_url}
              />
              {!!errors.redirect_url && (
                <FormHelperText>{`リダイレクトURL${errors.redirect_url}`}</FormHelperText>
              )}
            </FormField>

            <b>URL内で以下のタグが使用可能です。</b>
            <ul>
              <li>店舗ID &lt;%=shop_id=%&gt;</li>
              <li>担当スタッフID &lt;%=staff_id=%&gt;</li>
              <li>レジID &lt;%=pos_id=%&gt;</li>
              <li>購入日 &lt;%=purchase_date=%&gt;</li>
              <li>取引ID &lt;%=deal_id=%&gt;</li>
              <li>顧客ID &lt;%=customer_id=%&gt;</li>
              <li>カスタム１ &lt;%=custom01=%&gt;</li>
              <li>カスタム２ &lt;%=custom02=%&gt;</li>
            </ul>
          </Paper>
        }
      </div>
    </TabPanel>
  );
}

EndPageSettings.propTypes = propTypes;

export default EndPageSettings;
