import {
  FETCH_COMPANIES_BEGIN,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_ALL_COMPANIES_BEGIN,
  FETCH_ALL_COMPANIES_SUCCESS,
  FETCH_ALL_COMPANIES_FAILURE,
  SAVE_COMPANY_BEGIN,
  SAVE_COMPANY_SUCCESS,
  SAVE_COMPANY_FAILURE,
  CHANGE_COMPANY_ITEM_STATE,
  UPDATE_COMPANY_ITEM,
  FETCH_COMPANIES_RESET,
} from './actions';
import { mergeItem } from "../../utils";


const initialState = {
  item: {},
  itemSubmitting: false,
  itemSubmitSuccess: false,
  errors: null,

  pagination: {
    total: 0,
  },

  items: [],
  itemsLoading: false,
  itemsLoaded: false,

  error: null,

  allItems: [],
  allItemsLoading: false,
  allItemsLoaded: false,
  allError: null,
};

export default (state=initialState, action) => {
  switch(action.type) {
    case FETCH_COMPANIES_BEGIN: {
      return {
        ...state,
        error: null,
        items: [],
        itemsLoading: true,
        itemsLoaded: false,
        itemSubmitting: false,
      };
    }

    case FETCH_COMPANIES_SUCCESS: {
      const { data } = action.payload.items;

      return {
        ...state,
        items: data.map(({id, attributes}) => ({ id, ...attributes })),
        itemsLoading: false,
        itemsLoaded: true,
        pagination: action.payload.pagination,
        error: null,
      };
    }

    case FETCH_COMPANIES_FAILURE: {
      return {
        ...state,
        error: action.payload.errors,
        items: [],
        itemsLoading: false,
        itemsLoaded: true,
        itemSubmitSuccess: false,
      };
    }

    case FETCH_COMPANIES_RESET: {
      return {
        ...state,
        itemsLoading: false,
        itemsLoaded: false,
      };
    }

    case FETCH_ALL_COMPANIES_BEGIN: {
      return {
        ...state,
        allItemsLoading: true,
        allItemsLoaded: false,
        allError: null,
        itemSubmitSuccess: false,
      };
    }

    case FETCH_ALL_COMPANIES_SUCCESS: {
      const { data } = action.payload.items;

      return {
        ...state,
        allItems: data,
        allItemsLoading: false,
        allItemsLoaded: true,
        allError: null,
      };
    }

    case FETCH_ALL_COMPANIES_FAILURE: {
      return {
        ...state,
        allItems: [],
        allItemsLoading: false,
        allItemsLoaded: true,
        allError: action.payload.errors,
      };
    }

    case UPDATE_COMPANY_ITEM: {
      const { item } = action.payload;

      return {
        ...state,
        item,
      };
    }

    case SAVE_COMPANY_BEGIN: {
      return {
        ...state,
        itemSubmitting: true,
        itemSubmitSuccess: false,
        errors: null,
      };
    }

    case SAVE_COMPANY_SUCCESS: {
      const { id, attributes } = action.payload.data
      const item = { id, ...attributes }
      return {
        ...state,
        itemSubmitting: false,
        itemSubmitSuccess: true,
        errors: null,
        item,
        items: mergeItem(item, state.items)
      }
    }

    case SAVE_COMPANY_FAILURE: {
      return {
        ...state,
        itemSubmitting: false,
        itemSubmitSuccess: false,
        errors: action.payload.errors,
      }
    }

    case CHANGE_COMPANY_ITEM_STATE: {
      const { id, discarded_at } = action.payload

      return {
        ...state,
        items: state.items.map(item => item.id === id ? {
          ...item,
          discarded_at,
        } : item),
        item: {
          ...state.item,
          discarded_at,
        }
      }
    }

    default:
      return state;
  }
};
