import {
  FETCH_FEEDBACKS_BEGIN,
  FETCH_FEEDBACKS_SUCCESS,
  FETCH_FEEDBACKS_FAILURE,
  UPDATE_FEEDBACK_COMMENT,
  OPEN_FEEDBACK_MODAL,
  CLOSE_FEEDBACK_MODAL,
  SET_ACTIVE_FEEDBACK,
  ARCHIVE_FEEDBACK,
  UPDATE_FEEDBACK_USER,
  SET_TAB_INDEX,
  SET_WITHOUT_EMPTY_FLG,
  FETCH_FEEDBACKS_RESET,
  PIN_FEEDBACK,
  FETCH_FEEDBACK_LIKES,
  FETCH_FEEDBACK_LIKES_BEGIN,
  LIKE_FEEDBACK,
  DISLIKE_FEEDBACK,
  LIKE_FEEDBACK_BEGIN,
  LIKE_FEEDBACK_FAILURE,
  EXPORT_FEEDBACK_BEGIN,
  EXPORT_FEEDBACK_SUCCESS,
  EXPORT_FEEDBACK_FAILURE,
  SET_FEEDBACK_SORT_OPTIONS,
  FETCH_FEEDBACK_QUESTIONS,
  SET_FEEDBACK_QUESTION,
  RESET_FEEDBACK_QUESTIONS,
  FETCH_FEEDBACK_QUESTIONS_BEGIN,
  FETCH_SCORE_QUESTIONS,
  SET_SCORE_QUESTION,
  FETCH_SCORE_QUESTIONS_BEGIN,
} from '../actions/feedback'
import {TAB_INDEX_ANSWERS} from "../../constants";
import { renderHtmlWithoutTags } from 'utils'

const initialState = {
  items: [],
  pagination: {
    total: 0,
  },
  loading: false,
  loaded: false,
  error: null,
  feedback: null,
  modalOpened: false,
  tabIndex: TAB_INDEX_ANSWERS,
  sort_key: 'created_at',
  sort_val: 'desc',
  without_empty: false,
}

export default function feedbacksReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_FEEDBACKS_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      }
    case FETCH_FEEDBACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.payload.feedbacks,
        pagination: action.payload.pagination,
        feedback: null,
        error: null,
        likeBegin: false,
      }
    case FETCH_FEEDBACKS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        likeBegin: false,
        error: action.payload.error,
        pagination: {
          total: 0,
        },
      }
    case FETCH_FEEDBACKS_RESET:
      return {
        ...state,
        loading: false,
        loaded: false,
      }
    case OPEN_FEEDBACK_MODAL:
      return {
        ...state,
        modalOpened: true,
        feedback: action.payload.feedback,
        likeBegin: false,
      }
    case CLOSE_FEEDBACK_MODAL:
      return {
        ...state,
        modalOpened: false,
        feedback: null,
        tabIndex: initialState.tabIndex,
        likeBegin: false,
      }
    case SET_TAB_INDEX: {
      return {
        ...state,
        tabIndex: action.payload.tabIndex,
      }
    }
    case SET_WITHOUT_EMPTY_FLG: {
      return{
        ...state,
        without_empty: action.payload.value
      }
    }
    case UPDATE_FEEDBACK_COMMENT:
      const { feedbackId, delta } = action.payload
      return {
        ...state,
        items: state.items.map(item => item.id === feedbackId ? {...item, comments_count: item.comments_count+delta} : item),
      }
    case SET_ACTIVE_FEEDBACK:
      return {
        ...state,
        feedback: action.payload.feedback,
      }
    case ARCHIVE_FEEDBACK:
      return {
        ...state,
        items: state.items.filter(({ id }) => id !== action.payload.id),
        pagination: {
          ...state.pagination,
          total: +(state.pagination.total)-1,
        }
      }
    case UPDATE_FEEDBACK_USER:
      const { id, user } = action.payload
      const feedback = {
        ...state.feedback,
        user
      };
      return {
        ...state,
        items: state.items.map(i => i.id === id ? feedback : i),
        feedback,
      };
    case PIN_FEEDBACK:
      return {
        ...state,
        items: state.items.map(i => i.id === action.payload.feedback.id ? action.payload.feedback : i),
        feedback: !!state.feedback ? action.payload.feedback : null
      }
    case FETCH_FEEDBACK_LIKES_BEGIN:
      return {
          ...state,
          likes: null
        }
    case FETCH_FEEDBACK_LIKES:
      return {
        ...state,
        likes: action.payload.users,
        items: state.items.map(item =>
            item.id === action.payload.feedback_id ? {...item, likes_count: action.payload.users.length} : item
        ),
      }
    case LIKE_FEEDBACK_BEGIN:
      return {
        ...state,
        likeBegin: true,
      }
    case LIKE_FEEDBACK_FAILURE:
      return {
        ...state,
        likeBegin: false,
      }
    case LIKE_FEEDBACK:
      return {
        ...state,
        likeBegin: false,
        ...updateFeedbacks(state, action.payload, +1)
      }
    case DISLIKE_FEEDBACK:
      return {
        ...state,
        likeBegin: false,
        ...updateFeedbacks(state, action.payload, -1)
      }
    case EXPORT_FEEDBACK_BEGIN:
      return {
        ...state,
        error: null,
        exporting: true,
      }
    case EXPORT_FEEDBACK_SUCCESS:
      return {
        ...state,
        error: null,
        exporting: false,
      }
    case EXPORT_FEEDBACK_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        exporting: false,
      }
    case SET_FEEDBACK_SORT_OPTIONS:
      return {
        ...state,
        ...action.payload.params,
      }
    case FETCH_FEEDBACK_QUESTIONS_BEGIN:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: true,
        }
      }
    case FETCH_FEEDBACK_QUESTIONS:
      return {
        ...state,
        questions: {
          ...state.questions,
          all: (action.payload.data || []).map(({ id, attributes: { abbreviation, body } }) => ({
             id, label: renderHtmlWithoutTags(abbreviation || body)
          })),
          loading: false,
        }
      }
    case SET_FEEDBACK_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          selected: action.payload.id,
        }
      }
    case RESET_FEEDBACK_QUESTIONS: {
      return {
        ...state,
        questions: {},
        question_filters: {},
      }
    }
    case FETCH_SCORE_QUESTIONS_BEGIN:
      return {
        ...state,
        scoreQuestions: {
          ...state.scoreQuestions,
          loading: true,
        }
      }
    case FETCH_SCORE_QUESTIONS:
      return {
        ...state,
        scoreQuestions: {
          ...state.scoreQuestions,
          all: (action.payload.data || []).map(({ id, attributes: { abbreviation, body }, maxScore }) => ({
             id, label: renderHtmlWithoutTags(abbreviation || body), maxScore
          })),
          loading: false,
        }
      }
    case SET_SCORE_QUESTION:
      return {
        ...state,
        scoreQuestions: {
          ...state.scoreQuestions,
          selected: action.payload.id,
        }
      }
    default:
      return state
  }
}

const updateFeedback = (feedback, feedback_like_id, delta) => ({
  ...feedback,
  feedback_like_id,
  likes_count: Math.max(feedback.likes_count + delta, 0),
})

export const updateFeedbacks = (state, payload, delta) => {
  const { feedback_id, feedback_like_id } = payload
  const items = state.items.map(item => {
    if (item.id === feedback_id) {
      return updateFeedback(item, feedback_like_id, delta)
    } else {
      return item
    }
  })
  return {
    items,
    feedback: !!state.feedback ? updateFeedback(state.feedback, feedback_like_id, delta) : null
  }
}