export const themedStyles = theme => ({
  buttonArchive: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #F02544",
    borderRadius: "5px",
    padding: "8px 21px 8px 15px",
    marginLeft: "20px",
    "& span": {
      textAlign: "left",
      font: "500 12px/24px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#F02544",
      opacity: 1,
      "& i": {
        fontSize: "16px",
        marginRight: "15px"
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "4px 5px",
      marginLeft: "10px",
      "& span": {
        font: "500 8px/15px Noto Sans JP, Hiragino Sans",
        "& >i": {
          fontSize: "12px !important",
          marginRight: "5px"
        }
      }
    }
  },
  buttonClose: {
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    padding: "8px 20px",
    float: "right",
    top: 0,
    "& span": {
      font: "600 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro",
      letterSpacing: "0.25px",
      color: "#FFFFFF",
      "& i": {
        fontSize: "16px",
        marginLeft: "10px"
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px",
      "& span": {
        font: "600 8px/23px Noto Sans JP, Hiragino Kaku Gothic Pro",
        "& i": {
          fontSize: "15px",
          marginLeft: "5px"
        }
      }
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  editName: {
    width: "100%",
    marginBottom: 5,
    "& input": {
      padding: "13px 10px 13px 10px",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#000",
      '& :placeholder': {
        color: '#B7B7B7',
      },

      '&::-ms-clear': {
        display: 'none',
      }
    },
    "& i": {
      marginTop: "-15px"
    },
    "&::before": {
      borderBottom: "1px solid #B7B7B7"
    }
  },
  buttonSaveSuccess: {
    background: "#EC681A 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    padding: "9px 32px 9px 15px",
    "& span": {
      font: "500 12px/24px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#FFFFFF",
      "& i": {
        marginRight: "15px"
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px",
      "& span": {
        font: "500 8px/15px Noto Sans JP, Hiragino Sans",
        "& i": {
          marginRight: "5px",
          fontSize: 15
        }
      }
    }
  },
  buttonReload: {
    background: "#F02544 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    padding: "9px 60px 9px 15px",
    marginLeft: "20px",
    "& span": {
      textAlign: "left",
      font: "400 11px/24px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#fff",
      opacity: 1,
      "& i": {
        fontSize: "16px",
        marginRight: "15px"
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px",
      "& span": {
        font: "500 8px/15px Noto Sans JP, Hiragino Sans",
        "& i": {
          fontSize: "15px",
          marginRight: "5px"
        }
      }
    }
  },
  headerFrom: {
    marginTop: "74px",
    position: "relative",
    // padding: "13px 20px 13px 10px",
    // borderBottom: "1px solid #ccc",
    "& >div": {
      textAlign: "left",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#1A0707",
      opacity: 1,
      display: "inline-block",
      "& i": {
        fontSize: "16px",
        color: "#F02544",
        marginLeft: "10px",       
      }
    },
    "& >i": {
      float: "right",
      fontSize: "16px",
      color: "#B7B7B7",
      right: 0,
      position: "absolute",
      top: 15,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "50px",
      // padding: "13px 10px 13px 10px",
      // borderBottom: "1px solid #ccc",
      "& >div": {
        textAlign: "left",
        font: "500 10px/18px Noto Sans JP, Hiragino Sans",
        letterSpacing: 0,
        color: "#1A0707",
        opacity: 1,
        display: "inline-block",
        "& i": {
          fontSize: "14px",
          color: "#F02544",
          marginLeft: "10px"
        }
      },
      "& >i": {
        float: "right",
        fontSize: "16px",
        color: "#B7B7B7",
        position: "relative",
        top: "5px"
      }
    }
  },
  blockTime: {
    display: "block",
    textAlign: "right",
    "& p": {
      display: "inline-block",
      font: "300 12px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      margin: "10px 0 10px 9px"
    },
    [theme.breakpoints.down("xs")]: {
      "& p": {
        font: "300 10px/16px Noto Sans JP, Hiragino Sans"
      }
    }
  },
  blockCountFeedback: {
    display: "flex",
    "& >.anket": {
      padding: "12px 0",
      "& p:nth-child(1)": {
        font: "500 14px Noto Sans JP, Hiragino Sans",
        letterSpacing: 0,
        color: "#1A0707",
        opacity: 1,
        margin: 0
      },
      "& p:nth-child(2)": {
        font: "500 20px Noto Sans JP, Hiragino Sans",
        letterSpacing: 0,
        opacity: 1,
        color: "#1A0707",
        margin: "10px 0"
      }
    },
    "& > .divide": {
      width: "1px",
      height: "64px",
      background: "#B7B7B7",
      marginLeft: "43px",
      position: "relative",
      top: "7px"
    },
    "& >.feedback": {
      marginLeft: "21px",
      padding: "12px 0",
      "& p:nth-child(1)": {
        font: "500 14px Noto Sans JP, Hiragino Sans",
        letterSpacing: 0,
        color: "#1A0707",
        opacity: 1,
        margin: 0
      },
      "& p:nth-child(2)": {
        font: "500 20px Noto Sans JP, Hiragino Sans",
        letterSpacing: 0,
        opacity: 1,
        color: "#1A0707",
        margin: "10px 0"
      }
    },
    [theme.breakpoints.down("xs")]: {
      "& >.anket": {
        padding: "12px 0",
        "& p:nth-child(1)": {
          font: "500 12px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          color: "#1A0707",
          opacity: 1,
          margin: 0
        },
        "& p:nth-child(2)": {
          font: "500 16px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          opacity: 1,
          color: "#1A0707",
          margin: "10px 0"
        }
      },
      "& > .divide": {
        width: "1px",
        height: "64px",
        background: "#B7B7B7",
        marginLeft: "43px",
        position: "relative",
        top: "7px"
      },
      "& >.feedback": {
        marginLeft: "21px",
        padding: "12px 0",
        "& p:nth-child(1)": {
          font: "500 12px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          color: "#1A0707",
          opacity: 1,
          margin: 0
        },
        "& p:nth-child(2)": {
          font: "500 16px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          opacity: 1,
          color: "#1A0707",
          margin: "10px 0"
        }
      }
    }
  },
  marginTop72: {
    marginTop: 72
  },
  inputGroup: {
    font: "400 12px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: 0,
    color: "#939393",
    opacity: 1,
    "& >p": {
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#1A0707",
      opacity: 1,
      margin: 0
    },
    "& >div": {
      width: "100%",
      "& >div": {
        width: "100%",
        margin: "10px 0",
        "& input": {
          width: "100%",
          font: "400 12px/16px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          color: "#1A0707",
          opacity: 1,
          "&::-webkit-input-placeholder": { /* Chrome/Opera/Safari */
            color: "#939393",
          },
          "&::-moz-placeholder": { /* Firefox 19+ */
            color: "#939393",
          },
          "&:-ms-input-placeholder": { /* IE 10+ */
            color: "#939393",
          },
          "&:-moz-placeholder": { /* Firefox 18- */
            color: "#939393"
          }
        },
        "& textarea": {
          width: "100%",
          font: "400 12px/16px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          color: "#1A0707",
          opacity: 1,
          "&::-webkit-input-placeholder": { /* Chrome/Opera/Safari */
            color: "#939393",
          },
          "&::-moz-placeholder": { /* Firefox 19+ */
            color: "#939393",
          },
          "&:-ms-input-placeholder": { /* IE 10+ */
            color: "#939393",
          },
          "&:-moz-placeholder": { /* Firefox 18- */
            color: "#939393"
          }
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      font: "400 12px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#939393",
      opacity: 1,
      "& >p": {
        font: "500 12px/18px Noto Sans JP, Hiragino Sans",
        letterSpacing: 0,
        color: "#1A0707",
        opacity: 1,
        margin: 0
      },
      "& >div": {
        width: "100%",
        "& >div": {
          width: "100%",
          margin: "10px 0",
          "& input": {
            width: "100%",
            font: "400 12px/16px Noto Sans JP, Hiragino Sans",
            letterSpacing: 0,
            color: "#939393",
            opacity: 1
          },
          "& textarea": {
            width: "100%",
            font: "400 12px/16px Noto Sans JP, Hiragino Sans",
            letterSpacing: 0,
            color: "#939393",
            opacity: 1
          }
        }
      }
    }
  },
  loadingRoot: {
    width: "100%",
    opacity: 0.5,
    overflowX: "auto"
  },
  loading: {
    width: "500px",
    height: "1000px",
    margin: "auto auto",
    padding: "225px"
  },
  box: {
    width: "640px",
    height: "100vh",
    padding: "32px 48px 42px 30px",
    overflow: "auto",
    flexGrow: "1",
    background: "#FFFFFF",
    boxShadow: "3px 0px 5px #B7B7B733",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "10px",
    }
  },

  cssTextField: {
    '& textarea': {
      border: '1px solid #ccc !important',
      borderRadius: 4,
      boxSizing: 'border-box',
      opacity: '1 !important',
      padding: '9px 12px',
      '-ms-overflow-style': 'none',

      '&:-ms-input-placeholder': {
        color: '#ccc !important',
      },


      '&:hover': {
        border: '1px solid #1A0707 !important',
      },

      '&:focus': {
        border: '2px solid #EC681A !important',
      }
    },

    '& fieldset': {
      display: 'none',
    }
  },

  modalRoot: {
    position: "absolute",
    backgroundColor: "white",
    padding: "44px 20px",
    borderTop: "2px solid #EC681A",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: "600",
    padding: "0 10px 10px 10px",
    borderBottom: "1px solid #B7B7B7",
    marginBottom: 40
  },
  modalNote: {
    textAlign: "center",
    fontWeight: "300",
    letterSpacing: "0.35px",
    color: "#1A0707"
  },
  modalButtonZone: {
    marginTop: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  modalDeleteBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
  modalCancelBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
  modalSizeBtn: {
    margin: "0 10px",
    width: 123
  }
});
