import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrop, useDrag } from 'react-dnd';
import { makeStyles } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import QuestionForm from './QuestionForm';


const QUESTION = 'QUESTION';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'auto',
    minHeight: 86,
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    position: 'relative',
    marginBottom: 50
  },
  contentItem: {
    width: 770,
    minHeight: 86,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 5px #B7B7B733',
    [theme.breakpoints.down("md")]: {
      width: "auto",
    }
  },
  reOrder: {
    position: 'absolute',
    left: '-29px',
    top: 0,
    width: 24,
    height: 86,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #B7B7B7',
    borderRadius: '14px',
    cursor: 'pointer',
  },
  arrowUpIcon: {
    color: '#B7B7B7',
    fontSize: '14px',
    position: 'absolute',
    left: 4,
    top: 15,
  },
  dehazeIcon: {
    color: '#B7B7B7',
    fontSize: '14px',
    position: 'absolute',
    left: 4,
    top: 35,
  },
  arrowDownIcon: {
    color: '#B7B7B7',
    fontSize: '14px',
    position: 'absolute',
    left: 4,
    top: 55,
  },
  dragging: {
    opacity: 0,
  },
}));

const propTypes = {
  errors: PropTypes.object,
  questionItem: PropTypes.object.isRequired,
  questionTypeOptions: PropTypes.array.isRequired,
  setDeleteQuestionParams: PropTypes.func.isRequired,
}

const QuestionDnD = ({
  moveCard,
  spIndex,
  piIndex,
  errors,
  questionItem,
  questionTypeOptions,
  setDeleteQuestionParams,
  isExpanded,
}) => {
  const classes = useStyles();
  const ref = useRef(null);
  const contentItem = useRef(null);

  const [, drop] = useDrop({
    accept: QUESTION,
    canDrop: () => false,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }

      const dragSpIndex = item.spIndex;
      const dragPiIndex = item.piIndex;
      const hoverSpIndex = spIndex;
      const hoverPiIndex = piIndex;

      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragSpIndex <= hoverSpIndex && dragPiIndex < hoverPiIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragSpIndex > hoverSpIndex && dragPiIndex > hoverPiIndex && hoverClientY > hoverMiddleY) {
        return
      }

      if ((questionItem.uid || questionItem.id) === item.uid) {
        return
      }

      if (!!!questionItem.uid && !!!questionItem.id) {
        return;
      }

      moveCard({
        spIndex1: item.spIndex,
        piIndex1: item.piIndex,
        spIndex2: spIndex,
        piIndex2: piIndex,
      });

      item.spIndex = spIndex;
      item.piIndex = piIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: QUESTION,
      spIndex: spIndex,
      piIndex: piIndex,
      uid: questionItem.uid || questionItem.id
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const rootClasses = isDragging ? `${classes.root} ${classes.dragging}` : classes.root;

  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;

  return (
    <div className={rootClasses} ref={preview} style={{ opacity }}>
      <div className={classes.reOrder} ref={ref}>
        <ArrowUpwardIcon className={classes.arrowUpIcon} />
        <DehazeIcon className={classes.dehazeIcon} />
        <ArrowDownwardIcon className={classes.arrowDownIcon} />
      </div>
      <div className={classes.contentItem} ref={contentItem}>
        <QuestionForm
          questionItem={questionItem}
          spIndex={spIndex}
          piIndex={piIndex}
          errors={errors}
          questionTypeOptions={questionTypeOptions}
          setDeleteQuestionParams={setDeleteQuestionParams}
          expanded={isExpanded}
        />
      </div>
    </div>
  )
}

QuestionDnD.propTypes = propTypes;

export default QuestionDnD;
