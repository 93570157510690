import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFeedbackComment } from './redux/thunk';
import { Button, Grid } from '@material-ui/core';
import ElementIcon from "common/icon/ElementIcon";
import { useFeedbackCommentInputStyle } from './styles'
import { extractValidationError } from "./utils";


const propTypes = {
  feedback: PropTypes.object,
  comments: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.object,
  createComment: PropTypes.func.isRequired,
  onCommentAdded: PropTypes.func.isRequired,
};

const FeedbackCommentInput = ({
  feedback,
  comments,
  error,
  createComment,
  onCommentAdded,
}) => {
  const [comment, setComment] = useState('');
  const [newlyAdded, setNewlyAdded] = useState(false);
  const classes = useFeedbackCommentInputStyle();

  useEffect(() => {
    if (newlyAdded && !!!error) {
      const lastAddedCommentId = Math.max.apply(Math, comments.map(({ id }) => id))
      onCommentAdded && onCommentAdded(lastAddedCommentId)
      setComment("")
    }
    // eslint-disable-next-line
  }, [comments.length, onCommentAdded]);

  const addComment = e => {
    e.preventDefault();

    if (!!feedback && !!comment) {
      createComment(feedback, comment);
      setNewlyAdded(true);
    }
  }

  const handleChange = e => {
    e.preventDefault();
    setComment(e.target.value);
  }

  return (
    <div className={classes.boxSearch}>
      <div>
        <input
          type='text'
          placeholder='ここにコメントを書いてください。'
          onChange={handleChange}
          value={comment}
          className={classes.input}
        />
        {<div style={{color: "red"}}>
          {error && `コメント${extractValidationError(error, 'body').join(', ')}`}
        </div>}
        <Grid className={classes.button}>
          <Button onClick={addComment}>
            <ElementIcon name={`chat-dot-square`} />
            <span>投稿</span>
          </Button>
        </Grid>
      </div>
    </div>
  )
}

FeedbackCommentInput.propTypes = propTypes;

export default connect(
  ({ feedbacks: { feedback }, feedbackComments: { items, error } }) => ({
    feedback,
    comments: items,
    error,
  }),
  dispatch => ({
    createComment: (feedback, comment) => dispatch(createFeedbackComment(feedback, comment)),
  }),
)(FeedbackCommentInput);
