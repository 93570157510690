export const themeAggregationStyles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginBottom:"50px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    verticalAlign: "middle",
    [theme.breakpoints.down("xs")]: {
      margin: "0 10px",
      overflow: "auto",
      padding: "20px 10px",
      "& .MuiBox-root": {
        padding: 0
      }
    }
  },
  paperChart: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 10px 20px 10px"
    }
  },
  selectOption: {
    [theme.breakpoints.down("xs")]: {
      overflow: "unset"
    }
  },
  button: {
    position: "absolute",
    right: 40,
    top: 0
  },
  tabs: {
    "& .Mui-selected": {
      color: "#EC681A",
      backgroundColor: "white",
      borderBottom: "4px solid #EC681A"      
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px 0 10px",
      "& .MuiTypography-body1": {
        overflowX: "scroll",
        "& .MuiBox-root": {
          padding: 0
        }
      }
    }
  },
  tab: {
    color: "#B7B7B7",
    borderBottom: "4px solid #B7B7B7",
    font: "12px/21px Noto Sans JP, Hiragino Sans",
    "&:hover": {
      backgroundColor: "white",
      borderBottom: "4px solid #939393",
      color: "#939393"
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      "&:hover": {
        borderBottom: "4px solid #B7B7B7",
        color: "#B7B7B7"
      }
    }
  },
  tabActived: {
    backgroundColor: "white",
    borderBottom: "4px solid #EC681A",
    font: "12px/21px Noto Sans JP, Hiragino Sans",
    "&:hover": {
      backgroundColor: "white",
      borderBottom: "4px solid #939393",
      color: "#939393"
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      "&:hover": {
        borderBottom: "4px solid #EC681A",
        color: "#EC681A"
      }
    }
  },
  btnExport: {
    float: "right",
    margin: 10,
    marginRight: 40,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  btnDownload: {
    padding: "10px 15px",
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    "& >span": {
      font: "500 12px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#FFFFFF",
      opacity: 1
    },
    "&:hover": {
      background: "#EC681A 0% 0% no-repeat padding-box"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  blockTable: {
    marginTop: 20,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: 12
    }
  },
  backgroundTab: {
    background: "#EC681A"
  },
  questionIcon: {
    width: 16
  }
});
export const themeAggregationCrossStyles = theme => ({
  buttonPercentage:{
    float: "left", 
    borderBottomStyle: "solid", 
    borderColor: "#EC681A", 
    borderWidth: 2, 
    padding: 3, 
    width: 96, 
    textAlign: "left",
    font: "500 12px/21px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    opacity: 1
  },
  root:{
    padding: 20,
    textAlign: "Right",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      width: 630
    }
  },
  blockTooltip:{
    background: "white", 
    padding: 8, 
    borderWidth: 1, 
    borderStyle: "solid", 
    borderColor: "#CCC",
    "& >div":{
      margin: 10, 
      marginTop: 0
    },
    "& >p":{
      margin: 0, 
      padding: 0
    }
  },
  pagination:{
    display: "inline-block"
  }
});
export const themeAggregationQuestionStyles = theme => ({
  root: {
    '& > *': {
      marginRight: theme.spacing(1),
      marginLeft: 0,
      marginBottom: theme.spacing(2),
    },
    display: "flex",
  },
  padding:{
    padding: 20,
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  buttonNoActive:{
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    variant: "contained",
    minWidth: "104px",
    "& >span":{
      font: "500 10px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color:"#FFFFFF",
      opacity: 1
    }
  },
  buttonActive:{
    background: "#EC681A 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    variant: "contained",
    minWidth: "104px",
    "& >span":{
      font: "500 10px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color:"#FFFFFF",
      opacity: 1
    },
    "&:hover":{
      background: "#EC681A 0% 0% no-repeat padding-box",
    }
  }
});
export const themeSelectQuestionStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 100%',
    margin: 19,
    border: 0,
    padding: "4px 60px 4px 7px",
    "& .down":{
      "& >span":{
        transform: "rotate(90deg)"
      }
    },
    [theme.breakpoints.down("xs")]:{
      display: "block",
      margin: 0,
      padding: 0,
      textAlign: "left"
    }
  },
  iconButton: {
    pointerEvents: "none",
    cursor: "auto",
    padding: 6,
    "& >span":{
      "& >svg":{
        color: "#EC681A"
      }
    },
    "&:hover":{
      background: "none"
    }
  },
  divider: {
    height: 42,
    margin: "0 0 0 4px",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  selectBox: {
    marginLeft: 30,
    width: "100%",
    textAlign: "left",
    "& >div":{
      width: "100%",
      "& >div":{
        width: "100%",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #1A0707",
        font: "500 12px/21px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.3px",
        color: "#B7B7B7",
        opacity: 1,
      }
    },
    [theme.breakpoints.down("xs")]:{
      marginLeft: 0,
    }
  },
  ul:{
    padding: "0px 20px",
    "& >div":{
      font: "300 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      borderBottom: "0.6000000238418579px solid #C2C2C2",
      padding: "14px 0",
      "&:hover": { 
        font: "600 10px/16px Noto Sans JP, Hiragino Sans",
        color: "#EC681A !important"
      }
    },
  },
  txtText:{
    marginLeft: "15px",
    font: "600 14px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.7px",
    color: "#1A0707",
    opacity: 1,
    minWidth: "60px",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  }
});
export const themeGraphTableStyles = theme => ({
  header: {
    background: "#F2EEEC",
    marginBottom: 0,
    borderBottom: "8px solid white",
  },
  cell: {
    minWidth: 80,
    borderRight: "2px solid white",
    font: "600 12px/18px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.6px",
    color: "#1A0707",
    opacity: 1
  },
  minCell: {
    borderRight: "2px solid #EC681A",
  },
  cellGraph: {
    minWidth: 80,
    padding: 0,    
  },
  cellGraphMobile: {
    [theme.breakpoints.down('xs')]: {
      boxShadow: "0px 3px 5px #B7B7B733 !important"
    }
  },
  root: {
    width: '100%',
    boxShadow: "none"
  },
  table: {
    minWidth: 650,
    "& td": {
      borderRight: "1px solid #ddd7d77a",
      font: "600 10px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.5px",
      color: "#1A0707",
      opacity: 1,
    },
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    backgroundColor: "#fff",
    [theme.breakpoints.down('xs')]: {
      minWidth: 300,
      borderSpacing: 0,
      "& td": {
        position: "relative",
        borderBottom: "none",
      }
    }
  },
  headerTable: {
    background: "#F2EEEC 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000029",
    borderRadius: "5px 5px 0px 0px",
  },  
  headerTableLabel: {
    background: "#EFEFEF 0% 0% no-repeat padding-box",
    boxShadow: "none",
    borderRadius: "5px 5px 0px 0px",
  },
  Tbody:{
    boxShadow: "0px 3px 5px #B7B7B733",
    border: "1px solid #F7F7F7",
    opacity: 1,
    "& >#count":{
      background: "#EFEFEF 0% 0% no-repeat padding-box"
    },
    "& >#rate":{
      background: "#F7F7F7 0% 0% no-repeat padding-box"
    },
    [theme.breakpoints.down("xs")]: {
      border: "none"
    }
  },
  tdValue: {
    background: "#F7F7F7 0% 0% no-repeat padding-box !important",
  },
  width30:{
    width: "15%"
  },
  width72:{
    width: 72,
    minWidth: 72
  },
  width10:{
    width: "10%"
  },
  floatLeft:{
    float: "left"
  },
  blockTableCell:{
    position: "relative",
    "& >div:nth-child(1)": {
      display: "inline", 
      position: "absolute", 
      left: 0
    },
    "& >div:nth-child(2)": {
      display: "inline", 
      float: "right" 
    }
  },
  spaceHeaderTable: {
    background: "white"
  },
  spaceRow: {
    padding: "5px 16px",
    border: "none !important"
  },
  rateText: {
    position: "absolute",
    right: 10,
    font: "500 8px/14px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.4px",
    color: "#1A0707"
  }
});
export const themeCrossTableStyles = theme => ({
  headerRow: {
    background: "#F2EEEC",
    marginBottom: 0,
    borderBottom: "8px solid white",
  },
  bodyRow: {
    boxShadow: '0px 3px 5px #B7B7B733',
  },
  cell: {
    minWidth: 80,
    borderRight: "2px solid white",
    font: "600 12px/18px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.6px",
    color: "#1A0707",
    opacity: 1
  },
  grayCell: {
    background: "#EFEFEF 0% 0% no-repeat padding-box" 
  },
  table: {
    minWidth: 650,
    "& td": {
      borderRight: "1px solid #ddd7d77a",
      font: "600 10px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.5px",
      color: "#1A0707",
      opacity: 1,
    },
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    backgroundColor: "#fff"
  },
  headerTable: {
    background: "#F2EEEC 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000029",
    borderRadius: "5px 5px 0px 0px",
  },
  headerTableSub:{
    background: "#EFEFEF 0% 0% no-repeat padding-box !IMPORTANT",
    boxShadow: "0px 3px 5px #B7B7B733",
    border: "1px solid #F7F7F7"
  },
  Tbody:{
    border: "1px solid #F7F7F7",
    opacity: 1,
    "& >#count":{
      background: "#EFEFEF 0% 0% no-repeat padding-box"
    },
    "& >#rate":{
      background: "#F7F7F7 0% 0% no-repeat padding-box"
    }
  },
  root: {
    width: '100%',
    boxShadow: "none"
  },
  width18:{
    width: "18%"
  },
  width12:{
    width: "12%"
  }
});
