export const themedStyles = theme => ({
  box: {
    width: "100%"
  },
  headerCom: {
    background: "#fff",
    "& p": {
      font: "18px/24px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.45px",
      color: "#1A0707",
      opacity: 1,
      margin: 0,
      padding: "17px"
    },
    "& hr": {
      color: "#EFEFEF",
      background: "#EFEFEF"
    },
    "& button": {
      background: "#EC681A 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      opacity: 1,
      textAlign: "left",
      font: "bold 14px/21px Noto Sans JP, Hiragino Sans",
      padding: "11px 15px",
      margin: "10px 17px 10px 17px",
      "& span": {
        font: "14px/21px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.35px",
        color: "#FFFFFF",
        opacity: 1,
        "& i": {
          marginRight: "15px"
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      background: "#fff",
      "& p": {
        font: "14px/24px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.45px",
        color: "#1A0707",
        opacity: 1,
        margin: 0,
        padding: "17px"
      },
      "& hr": {
        color: "#EFEFEF",
        background: "#EFEFEF"
      },
      "& button": {
        background: "#EC681A 0% 0% no-repeat padding-box",
        borderRadius: "5px",
        opacity: 1,
        textAlign: "left",
        font: "bold 10px/21px Noto Sans JP, Hiragino Sans",
        padding: "8px 10px",
        margin: "10px 17px 10px 17px",
        "& span": {
          font: "10px/21px Noto Sans JP, Hiragino Sans !important",
          letterSpacing: "0.35px",
          color: "#FFFFFF",
          opacity: 1,
          "& i": {
            marginRight: "15px",
            fontSize: "15px"
          }
        }
      }
    }
  },
  buttonShowAc: {
    background: "#fff",
    padding: "0x",
    margin: "20px 0",
    width: "100%",
    height: 52,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "& >.blockSearch": {
      height: 52,
      display: "inline-flex",
      "& >.icon": {
        borderRight: "2px solid #F7F7F7",
        height: 52,
        width: 52,
        justifyContent: "center",
        alignItems: 'center',
        display: "flex",
      },
      "& >.formSearch": {
        display: "flex",
        alignItems: "center",
        padding: "0 10px",
        "& >div": {
          display: "inline-block",
          "& >div": {
            verticalAlign: "middle",
            "& >div": {
              "& >input": {
                padding: "10px 15px",
                textAlign: "left",
                font: "500 14px/21px Noto Sans JP, Hiragino Sans",
                letterSpacing: "0.25px",
                border: "1px solid #939393",
                borderRadius: "5px",
                color: "#1A0707",
                opacity: 1,
                "&::-webkit-input-placeholder": { /* Chrome/Opera/Safari */
                  color: "#939393",
                },
                "&::-moz-placeholder": { /* Firefox 19+ */
                  color: "#939393",
                },
                "&:-ms-input-placeholder": { /* IE 10+ */
                  color: "#939393",
                },
                "&:-moz-placeholder": { /* Firefox 18- */
                  color: "#939393"
                }
              }
            }
          }
        },
        "& >.formIcon": {
          border: "1px solid #939393",
          height: 37,
          width: 37,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: "5px",
          marginLeft: 10,
          cursor: "pointer",
          "& >i": {
            fontSize: "16px"
          },
          "&:hover": {
            background: "#EC681A 0% 0% no-repeat padding-box",
            border: "solid 1px #EC681A",
            "& >i": {
              color: "white"
            },
          }
        }
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        "& >.icon": {
          padding: "10px"
        },
        "& >.formSearch": {
          padding: "5px",
          display: "flex",
          "& >div": {
            display: "inline-block",
            "& >div": {
              "& >div": {
                "& >input": {
                  padding: "10px 5px",
                  textAlign: "left",
                  font: "500 14px/21px Noto Sans JP, Hiragino Sans",
                  letterSpacing: "0.25px",
                  border: "1px solid #939393",
                  borderRadius: "5px",
                  color: "#B7B7B7",
                  opacity: 1
                }
              }
            }
          },
          "& >.formIcon": {
            display: "inline-block",
            border: "1px solid #939393",
            borderRadius: "5px",
            padding: "8px",
            margin: 0,
            lineHeight: 0,
            position: "relative",
            top: "0 !important",
            left: "5px",
            "& >i": {
              fontSize: "16px"
            },
            "&:hover": {
              background: "#EC681A 0% 0% no-repeat padding-box",
              border: "solid 1px #EC681A",
              "& >i": {
                color: "white"
              },
            }
          }
        }
      }
    },
    "& >.buttonShowStore": {
      alignItems: "center",
      textAlign: "right",
      width: "auto",
      padding: "8px 15px",
      border: "1px solid #B7B7B7",
      background: "#EFEFEF 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      cursor: "pointer",
      font: "500 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      opacity: 1,
      display: "inline-block",
      float: "right",
      marginRight: "15px",
      "&:hover": {
        width: "auto",
        border: "1px solid #EC681A",
        borderRadius: "5px",
        cursor: "pointer",
        color: "#fff",
        background:"#EC681A",
      },
      "& >div": {
        width: "16px",
        height: "16px",
        border: "1px solid #B7B7B7",
        borderRadius: "100%",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        marginRight: "15px",
        "& >div": {
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center"
        }
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        float: "right",
        margin: "0px 15px",
        textAlign:"center",
        padding: "5px",
        "& >div": {
          width: "16px",
          height: "16px",
          border: "1px solid #000",
          borderRadius: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "inline-flex",
          marginRight: "15px",
          "& >div": {
            background: "#EC681A 0% 0% no-repeat padding-box",
            width: "8px",
            height: "8px",
            borderRadius: "100%",
            display: "inline-block",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            alignSelf: "center"
          }
        }
      }
    },
    "& >.buttonShowStoreIsClick": {
      alignItems: "center",
      textAlign: "right",
      width: "auto",
      padding: "8px 15px",
      border: "1px solid #1A0707",
      borderRadius: "5px",
      cursor: "pointer",
      font: "500 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      display: "inline-block",
      float: "right",
      margin: "15px",
      "&:hover": {
        width: "auto",
        border: "1px solid #EC681A",
        borderRadius: "5px",
        cursor: "pointer",
        color: "#EC681A",
        background:"#F2EEEC"
      },
      "& >div": {
        width: "16px",
        height: "16px",
        border: "1px solid #000",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        marginRight: "15px",
        "& >div": {
          background: "#EC681A 0% 0% no-repeat padding-box",
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center"
        }
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        float: "right",
        margin: "0px 15px",
        padding: "5px",
        textAlign:"center",
        "& >div": {
          width: "16px",
          height: "16px",
          border: "1px solid #000",
          borderRadius: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "inline-flex",
          marginRight: "15px",
          "& >div": {
            background: "#EC681A 0% 0% no-repeat padding-box",
            width: "8px",
            height: "8px",
            borderRadius: "100%",
            display: "inline-block",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            alignSelf: "center"
          }
        }
      }
    },
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      textAlign: "right",
      margin: "20px 0",
      display: "flex",
      padding: 0,
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0",
      padding: 0,
      background: "#fff",
      textAlign: "right",
      display: "flex",
      alignItems: "center"
    }
  },
  filterIcon: {
    width: 16,
    cursor: "pointer"
  },
  blockTotal: {
    marginTop: "40px",
    padding: "0 40px",
    display: "block",
    "& >.txtTotal": {
      display: "inline-block",
      "& >p": {
        textAlign: "left",
        font: "600 16px/29px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.8px",
        color: "#1A0707",
        opacity: 1
      }
    },
    "& >.buttons": {
      display: "inline-block",
      float: "right",
      margin: "10px 0",
      "& > button": {
        font: "500 10px/16px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.25px",
        color: "#1A0707",
        borderRadius: "18px",
        opacity: 1,
        padding: "10px 15px",
        marginLeft: "10px",
        "& >span": {
          "& >i": {
            color: "#B7B7B7",
            fontSize: "16px",
            marginLeft: "15px"
          }
        },
      },
      [theme.breakpoints.down("xs")]: {
        "& > button": {
          font: "500 8px/10px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.25px",
          color: "#1A0707",
          border: "1px solid #B7B7B7",
          borderRadius: "18px",
          opacity: 1,
          padding: "8px 5px",
          marginLeft: "10px",
          "& >span": {
            "& >i": {
              color: "#B7B7B7",
              fontSize: "16px",
              marginLeft: "5px !important"
            }
          }
        }
      }
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px"
    },
    [theme.breakpoints.down("xs")]: {
      "& >.txtTotal": {
        display: "inline-block",
        "& >p": {
          textAlign: "left",
          font: "600 13px/23px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.8px",
          color: "#1A0707",
          opacity: 1
        }
      }
    }
  },
  textBox: {
    '& input': {
      padding: '0 15px !important',
      height: 35,

      '&:-ms-input-placeholder': {
        color: '#9f9797 !important',
      },

      '&::-ms-clear': {
        display: 'none',
      },

      '&:hover': {
        border: '1px solid #1A0707 !important'
      },

      '&:focus': {
        border: '2px solid #EC681A !important'
      },
    },

    '& input[value]:not([value=""])': {
      border: '2px solid #EC681A !important',
    },

    '& input ~ fieldset': {
      display: 'none'
    }
  }
})
