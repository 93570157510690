import React from 'react'
import { CircularProgress } from '@material-ui/core'

const Progress = () => {
  return (
    <div style={{ width: '100%', padding: 100 }}>
      <CircularProgress disableShrink style={{ display: 'block', margin: '0 auto' }} />
    </div>
  )
}

export default Progress