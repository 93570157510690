import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  MenuItem,
  Button,
  FormControlLabel,
  Switch,
  Select,
  FormHelperText,
  FormControl,
  makeStyles,
  TextField,
  RadioGroup,
  Radio
} from "@material-ui/core";
import InputSwitch from "react-input-switch";
import { thunkSaveCompany } from "../redux/thunk";
import { POINT_CHOICES } from "../constants";
import {SwipeableModal, UnSavedWarningDialog} from "components";
import { themedStyles, switchCustomThemedStyles } from "./styles";
import SaveBtn from "../../../components/SaveBtn";


const useStyles = makeStyles(themedStyles);
// const CustomRadio = styled(Radio)`
// && {
//   :hover {
//     background-color: transparent;  
//   }
// }
// `

const propTypes = {
  item: PropTypes.object.isRequired,
  itemSubmitting: PropTypes.bool.isRequired,
  submitErrors: PropTypes.object,
  save: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  userAuthority: PropTypes.string,
  // 商品スコア開発。ラジオボタン制御の為対象アカウントが新規登録フラグ
  isNewCompany: PropTypes.bool.isRequired,
};

const CompanyForm = ({
  item,
  itemSubmitting,
  submitErrors,
  save,
  modalOpen,
  closeModal,
  userAuthority,
  isNewCompany,
}) => {
  const [values, setValues] = useState(item);
  const [errors, setErrors] = useState({});
  const [dirty, setDirty] = useState(false);
  const [open, setOpen] = useState(modalOpen);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const classes = useStyles();
  const dirtyFields = useRef([]);
  const isSuperAdmin = userAuthority === "super_admin";
  const [openSelectGoodMail,setOpenSelectGoodMail]=useState(false);
  const [openSelectBadMail,setOpenSelectBadMail]=useState(false);


  useEffect(() => {
    setOpen(modalOpen)
    setDirty(false)
    setErrors({})
    setValues(item)
    
    // eslint-disable-next-line
  }, [modalOpen])

  useEffect(() => {
    setValues(item)
    dirtyFields.current = []
    setErrors({})
    // eslint-disable-next-line
  }, [item])

  
  const onDiscard = () => {
    console.log("onDiscard83");
    handleDirt('discarded_at', !!item.discarded_at === !!values.discarded_at)
    setValues({...values, discarded_at: values.discarded_at ? null : new Date()})
  };

  const generateDoc = (e) => {
    const values = {
      good_mail_subject: !!e.target.good_mail_subject ? e.target.good_mail_subject.value : '',
      good_mail_body: !!e.target.good_mail_body ? e.target.good_mail_body.value : '',
      bad_mail_subject: !!e.target.bad_mail_subject ? e.target.bad_mail_subject.value : '',
      bad_mail_body: !!e.target.bad_mail_body ? e.target.bad_mail_body.value : '',
    }

    if (!!e.target.name) values.name = e.target.name.value;
    if (!!e.target.uid) values.uid = e.target.uid.value;
    if (!!e.target.custom_domain) values.custom_domain = e.target.custom_domain.value;

    return values;
  };

  const handleSubmit = e =>  {
    e.preventDefault();

    const doc = generateDoc(e);
    save({name: '', ...values, ...doc });
    setDirty(null)
  };

  useEffect(() => {
    if (!!submitErrors) {
      setErrors(submitErrors.detail);
      setDirty(true);
    } else {
      setDirty(null)
    }
  }, [submitErrors])

  const onChange = e => {
    const { name, value } = e.target
    handleDirt(name, (item[name] || '') !== value)
    setValues({...values, [name]: value})
  };

  const onChecked = e => {
    const { name, checked } = e.target
    handleDirt(name, !!item[name] !== checked)
    setValues({...values, [name]: checked})
  };

  const handleDirt = (name, isDirty) => {
    if (isDirty)
      dirtyFields.current = dirtyFields.current.concat([name])
    else
      dirtyFields.current = dirtyFields.current.filter(field => field !== name)
    setDirty(dirtyFields.current.length > 0)
  }

  const closeConfirmationDialog = () => setIsConfirmationOpen(false)

  const SwitchCustom = ({ ...props }) => {
    const switchCustomStyles = makeStyles(switchCustomThemedStyles);
    const classes = switchCustomStyles();
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    );
  };


  const renderForm = () => {
    

    return (
      <form name={"company-form"} 
      onSubmit={handleSubmit} 
      id="company-form">
        <UnSavedWarningDialog
          open={isConfirmationOpen}
          onConfirm={() => {
            closeConfirmationDialog()
            closeModal && closeModal()
          }}
          onCancel={() => closeConfirmationDialog()}
        />
        
        <SaveBtn type="submit" dirty={dirty} itemSubmitting={dirty === null ? true : false} />

        <Button className={classes.buttonToggle} onClick={onDiscard}>
          <InputSwitch
            styles={{
              trackChecked: {
                backgroundColor: "#EC681A"
              }
            }}
            on="yes"
            off="no"
            value={!!!values.discarded_at ? "yes" : "no"}
          />
          {values.discarded_at ? "停止中" : "使用中"}
        </Button>

        <Button
          className={classes.buttonDel}
          variant="contained"
          onClick={onModalClose}
        >
          閉じる
          <i className="material-icons">clear</i>
        </Button>
        
        <div className={classes.headerFrom}>
          {isSuperAdmin ?
          <TextField
            fullWidth={true}
            required={true}
            name={"name"}
            placeholder={"アカウント名"}
            variant={"outlined"}
            error={!!errors.name}
            onChange={onChange}
            defaultValue={item.name}
            className={classes.editName}
            helperText={!!errors.name && `アカウント名${errors.name[0]}`}
          /> : <p>{item.name}</p>}
          <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
        </div>
        <div>
          <div className={classes.inputGroup}>
            <p>アカウントID</p>
            {isSuperAdmin ?
            <TextField
              name={"uid"}
              required={true}
              placeholder={"アカウントID"}
              variant={"outlined"}
              error={!!errors.uid}
              onChange={onChange}
              defaultValue={item.uid}
              helperText={!!errors.uid && `アカウントID${errors.uid[0]}`}
            /> : <p>{item.uid}</p>}
          </div>
          <div className={classes.inputGroup}>
            <p>カスタムドメイン</p>
            {isSuperAdmin ?
            <TextField
              name={"custom_domain"}
              placeholder={"カスタムドメイン"}
              variant={"outlined"}
              error={!!errors.custom_domain}
              onChange={onChange}
              defaultValue={item.custom_domain}
              helperText={!!errors.custom_domain && `カスタムドメイン${errors.custom_domain[0]}`}
              type={"url"}
            /> : <p>{item.custom_domain}</p>}
          </div>
          {/* 商品スコア-アンケート種別 、コードは他項目と合わせる*/}

          <div className={classes.headerGood}>
            <p>アンケート種別</p>
          </div>
          <FormControl>
    
            <RadioGroup
                name="survey_type"
                value={values.survey_type}
                defaultValue="0"
                className={classes.anketGroupRadio}
                onChange={e=>setValues({...values,survey_type:e.target.value})}
              >
              <FormControlLabel
                      value="1"
                      label="商品アンケート"
                      control={<Radio />}
                      className={classes.anketRadioButton}
                      disabled={!isNewCompany && values.survey_type!=="1"}
                    />
            
              <FormControlLabel
                      value="0"
                      label="店舗アンケート"
                      control={<Radio />}
                      className={classes.anketRadioButton}
                      disabled={!isNewCompany && values.survey_type!=="0"}
                    />

          </RadioGroup>
        </FormControl>
        
          <div className={classes.headerGood}>
            <p>メール設定</p>
          </div>
          <div>
            <FormControlLabel
              name={"use_good_mail"}
              label={<span className={classes.switchText}>GOODメールを利用する</span>}
              control={<SwitchCustom />}
              checked={!!values.use_good_mail}
              onChange={onChecked}
              className={classes.switchButton}
            />
          </div>
          {!!values.use_good_mail && (
            <div className={classes.formToggle}>
              <div className={classes.switchButtonGroup}>
                <FormControl
                  component="fieldset"
                  className={classes.combobox}
                  error={!!errors.good_mail_min_point}
                  onClick={()=>{setOpenSelectGoodMail(!openSelectGoodMail)}}
                >
                  <Select
                    open={openSelectGoodMail}
                    name="good_mail_min_point"
                    label="以下の場合メールを送信"
                    value={Number.isInteger(values.good_mail_min_point) ? values.good_mail_min_point : ""}
                    onChange={onChange}
                  >
                    {POINT_CHOICES.map(index => (
                      <MenuItem value={index} key={index}>
                        {index}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors.good_mail_min_point && (
                    <FormHelperText>
                      {`GOODメールの点数${errors.good_mail_min_point[0]}`}
                    </FormHelperText>
                  )}
                </FormControl>
                <p>以上の場合メールを送信</p>
              </div>
              <div className={classes.inputGroup20}>
                <p>GOODメール件名</p>
                <TextField
                  name={"good_mail_subject"}
                  placeholder={"GOODメールの件名"}
                  variant={"outlined"}
                  value={values.good_mail_subject || ""}
                  onChange={onChange}
                  error={!!errors.good_mail_subject}
                  helperText={!!errors.good_mail_subject && `GOODメールの件名${errors.good_mail_subject[0]}`}
                />
              </div>
              <div className={classes.inputGroup20}>
                <p>GOODメールの冒頭文</p>
                <TextField
                  name={"good_mail_body"}
                  placeholder={"GOODメールの冒頭文"}
                  variant={"outlined"}
                  value={values.good_mail_body || ""}
                  onChange={onChange}
                  error={!!errors.good_mail_body}
                  helperText={!!errors.good_mail_body && `GOODメールの冒頭文${errors.good_mail_body[0]}`}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <FormControlLabel
            name={"use_bad_mail"}
            label={<span className={classes.switchText}>BADメールを利用する</span>}
            checked={!!values.use_bad_mail}
            control={<SwitchCustom />}
            onChange={onChecked}
            className={classes.switchButton}
          />
        </div>
        {!!values.use_bad_mail && (
          <div className={classes.formToggle}>
            <div className={classes.switchButtonGroup}>
              <FormControl
                component="fieldset"
                className={classes.combobox}
                error={!!errors.bad_mail_max_point}
                onClick={()=>{setOpenSelectBadMail(!openSelectBadMail)}}
              >
                <Select
                  open={openSelectBadMail}
                  name="bad_mail_max_point"
                  label="以下の場合メールを送信"
                  value={Number.isInteger(values.bad_mail_max_point) ? values.bad_mail_max_point : ""}
                  onChange={onChange}
                >
                  {POINT_CHOICES.map(index => (
                    <MenuItem value={index} key={index}>
                      {index}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors.bad_mail_max_point && (
                  <FormHelperText>
                    {`BADメールの点数${errors.bad_mail_max_point[0]}`}
                  </FormHelperText>
                )}
              </FormControl>
              <p>以下の場合メールを送信</p>
            </div>
            <div className={classes.inputGroup20}>
              <p>BADメールの件名</p>
              <TextField
                name={"bad_mail_subject"}
                placeholder={"BADメールの件名"}
                variant={"outlined"}
                value={values.bad_mail_subject || ""}
                onChange={onChange}
                error={!!errors.bad_mail_subject}
                helperText={!!errors.bad_mail_subject && `BADメールの件名${errors.bad_mail_subject[0]}`}
              />
            </div>
            <div className={classes.inputGroup20}>
              <p>BADメールの冒頭文</p>
              <TextField
                name={"bad_mail_body"}
                placeholder={"BADメールの冒頭文"}
                variant={"outlined"}
                value={values.bad_mail_body || ""}
                onChange={onChange}
                error={!!errors.bad_mail_body}
                helperText={!!errors.bad_mail_body && `BADメールの冒頭文${errors.bad_mail_body[0]}`}
              />
            </div>
          </div>
        )}
        <div className={classes.headerGood}>
          <p>フィードバック設定</p>
        </div>
        <div>
          <FormControlLabel
            name={"visible_negative_feedback"}
            label={<span className={classes.switchText}>スタッフに3点以下のフィードバックを表示する</span>}
            checked={values.visible_negative_feedback}
            control={<SwitchCustom />}
            onChange={onChecked}
            className={classes.switchButton}
          />
        </div>
        {isSuperAdmin &&
          <>
            <div className={classes.headerGood}>
              <p>ダッシュボード設定</p>
            </div>
            <div>
              <FormControlLabel
                name={"use_dashboard"}
                label={<span className={classes.switchText}>ダッシュボードを利用する</span>}
                checked={values.use_dashboard}
                control={<SwitchCustom />}
                onChange={onChecked}
                className={classes.switchButton}
              />
            </div>
          </>
        }
      </form>
    );
  };

  const onModalClose = () => {
    if (dirty) {
      setIsConfirmationOpen(true)
    } else {
      closeModal && closeModal()
    }
  }

  return (
    <SwipeableModal modalOpened={open} closeModal={onModalClose}>
      <div className={classes.paper}>
        {renderForm()}
      </div>
    </SwipeableModal>
  );
};

CompanyForm.propTypes = propTypes;

export default connect(
  ({ companies: { item, errors, itemSubmitting }, global: { userData } }) => ({
    item,
    submitErrors: errors,
    itemSubmitting,
    userAuthority: userData.authority
  }),
  dispatch => ({
    save: data => dispatch(thunkSaveCompany(data)),
  })
)(CompanyForm);
