import React, { useState } from "react";
import { connect } from 'react-redux';
import {
  makeStyles,
  Button
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { UPDATE_COMPANY_ITEM } from './redux/actions';
import DialogModal from "components/DialogModal";
import CompanyCreateForm from "./CompanyCreateForm";
import CompanyForm from "./CompanyForm";


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  addBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
}));

const CompanyAppBarBottom = ({ updateUserItem }) => {
  const classes = useStyles();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const handleOpenModal = () => {
    setDrawerIsOpen(true)
    // survey_typeは初期値を設定、初期値は"0"店舗アンケート
    updateUserItem({survey_type:"0"});
  };

  const handleClose = () => {
    setDialogIsOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Button
          variant="contained"
          className={classes.addBtn}
          startIcon={<AddIcon />}
          // onClick={() => setDialogIsOpen(true)}  // uncomment to enable create form
          onClick={() => handleOpenModal()}
        >
          新規追加
        </Button>
      </div>

      <DialogModal
        open={dialogIsOpen}
        onClose={handleClose}
      >
        <CompanyCreateForm
          dialogIsOpen={dialogIsOpen}
          onClose={handleClose}
        />
      </DialogModal>
      <CompanyForm modalOpen={drawerIsOpen} isNewCompany={true} closeModal={() => setDrawerIsOpen(false)} />
    </>
  );
};

export default connect(
  null,
  (dispatch) => ({
    updateUserItem: (item) => dispatch({ type: UPDATE_COMPANY_ITEM, payload: { item } } ),
  })
)(CompanyAppBarBottom);
