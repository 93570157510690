import qs from 'query-string';
import { default as axios } from 'axios'; 
import { API_URL } from '../../../constants';
import { handleError } from 'utils';
import { uploadFileBegin, uploadFileSuccess, uploadFileFailure, exportFileFailure } from './actions';
import { saveAs } from 'file-saver';
import {
  EXPORT_FEEDBACK_BEGIN,
  EXPORT_FEEDBACK_FAILURE,
  EXPORT_FEEDBACK_SUCCESS
} from "../../../modules/feedbacks/redux/actions/feedback";
import { GAPageView } from '../../../ga';

const metaData = {
  timeout: 600000, // 1000 * 600 = 10min
  headers: {
      'content-type': 'multipart/form-data'
  }
}

export const thunkUploadFile = (path, file, params) => {
  const paramsStr = qs.stringify(params)
  const url = `${API_URL}/${path}/import${!!paramsStr ? '?' + paramsStr : ''}`;
  const formData = new FormData();
  formData.append('file', file)
  return dispatch => {
    dispatch(uploadFileBegin())
    axios.post(url, formData, metaData)
      .then(response => dispatch(uploadFileSuccess(response.data.data)))
      .catch(handleError(dispatch, uploadFileFailure));
  }
}

export const thunkExportItems = (path, params={}) => {
  const paramsStr = qs.stringify({
    ...params,
  })
  const url = `${API_URL}/${path}.csv?${paramsStr}`
  return downloadCsv(url)
}

export const thunkExportFeedbacks = params => dispatch => {
  dispatch({ type: EXPORT_FEEDBACK_BEGIN })
  const url = `${API_URL}/feedbacks.xls?${qs.stringify(params)}`
  return axios.get(url).then(response => {
    dispatch({ type: EXPORT_FEEDBACK_SUCCESS })
  })
  .catch(exception => {
    const { errors } = exception.response.data
    const error = errors['ActiveRecord'][0]
    dispatch({ type: EXPORT_FEEDBACK_FAILURE, payload: { error } })
  })
}

export const thunkExportSurveyQuestions = (project_id, survey_id) => {
  const paramsStr = qs.stringify({ project_id, survey_id })
  const url = `${API_URL}/questions.xls?${paramsStr}`
  return downloadXls(url)
}

const downloadCsv = url => dispatch => {
  GAPageView("csv", "CSV出力")
  return axios.get(url)
    .then(response => {
      const { data, headers } = response;
      // Default file name if it is not present in response headers
      let fileName = "export.csv"
      const contentDisposition = headers['content-disposition']
      if (contentDisposition) {
        fileName = contentDisposition.split(/''/).pop()
        fileName = decodeURIComponent(fileName)
      }
      const bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom, data], { type: headers['content-type'] });
      saveAs(blob, fileName);
    })
    .catch(handleError(dispatch, exportFileFailure));
}

const downloadXls = url => dispatch => {
  return axios.get(url, { responseType: 'arraybuffer' })
  .then(response => {
    const { data, headers } = response;
    let fileName = "export.xls" // default file name
    const contentDisposition = headers['content-disposition']
    if (contentDisposition) {
      fileName = contentDisposition.split(/''/).pop()
      fileName = decodeURIComponent(fileName)
    }
    const blob = new Blob([data], { type: headers['content-type'] });
    saveAs(blob, fileName);
  })
  .catch(handleError(dispatch, exportFileFailure));
}
