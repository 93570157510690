import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
} from '@material-ui/core';
import { TrendingFlat as ArrowDownwardIcon } from '@material-ui/icons';
import { Storage } from '../../utils';
import { exportGraphAsPng } from '../utils';
import { thunkFetchSurveyQuestions } from './redux/thunk';
import FeedbackSurveyFilter from '../feedbacks/FeedbackSurveyFilter'
import AnalysisNpsScore from '../analysis/AnalysisNpsScore';
import AggregationQuestion from './AggregationQuestion';
import AggregationCross from './AggregationCross';
import SelectQuestion from './SelectQuestion';
import { thunkExportItems } from 'common/csv/redux/thunk';
import SelectProjectWarning from 'modules/common/SelectProjectWarning';
import IconQuestion from 'assets/icons/icon_question.svg';
import { themeAggregationStyles } from "./styles";


const propTypes = {
  project_id: PropTypes.string,
  survey_id: PropTypes.string,
  fetchSurveyQuestions: PropTypes.func.isRequired,
  exportCsv: PropTypes.func.isRequired,
};

const TAB_QUESTION = 0;
const TAB_CROSS = 1;
const TAB_NAMES = ["questions", "cross"]

const useStyles = makeStyles(themeAggregationStyles);

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography component="div" role="tabpanel" hidden={value !== index} 
    id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
    {value === index && <Box p={3}>{children}</Box>}
  </Typography>
)

const Aggregation = ({ project_id, survey_id, fetchSurveyQuestions, exportCsv, ...other }) => {
  const dispatch = useDispatch();
  const { root, paper, button, tab, tabs, btnExport, btnDownload, blockTable, questionIcon, tabActived, selectOption, paperChart } = useStyles();
  const [tabIndex, setTabIndex] = useState(TAB_QUESTION);

  useEffect(() => {
    if (survey_id){
       fetchSurveyQuestions(survey_id)
    } else {
      dispatch({ type: 'FETCH_AGGREGATION_RESET' });
    }
    // eslint-disable-next-line
  }, [project_id, survey_id, fetchSurveyQuestions])

  if (!!!project_id){
    return <SelectProjectWarning />
  }

  const handleTabChange = (e, index) => {
    e.preventDefault()
    setTabIndex(index);
  }

  const downloadChart = e => {
    e.preventDefault()
    const elementId = tabIndex === TAB_QUESTION ? "graph-question" : "graph-cross"
    const element = document.getElementById(elementId)
    element && exportGraphAsPng(element, `${TAB_NAMES[tabIndex]}.png`)
  }

  const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: tabIndex === index ? tabActived : tab,
  })

  return (
    <div className={root}>
      <FeedbackSurveyFilter />
      <div className={btnExport}>
        {/* <ExportButton text="アンケート集計エクスポート" onClick={exportCsv}/> */}
      </div>
      <AnalysisNpsScore {...other} />
      <Grid container spacing={2} className={blockTable} >
        <Grid item xs={12} >
          <Tabs value={tabIndex} className={tabs} onChange={handleTabChange} textColor="primary" >
            <Tab label="設問集計" {...a11yProps(TAB_QUESTION)} />
            <Tab label="クロス集計" {...a11yProps(TAB_CROSS)} />
          </Tabs>
          <Paper className={clsx(paper, selectOption)} square >
            <TabPanel value={tabIndex} index={TAB_QUESTION}>
            <SelectQuestion text="設問選択" icon={<img src={IconQuestion} className={questionIcon} alt="question"/>} tag="single"/>
            </TabPanel>
            <TabPanel value={tabIndex} index={TAB_CROSS}>
              <SelectQuestion text="縦軸設問" rotate="down" icon={<ArrowDownwardIcon />} tag="vertical" />
              <SelectQuestion text="横軸設問" rotate="right" icon={<ArrowDownwardIcon />} tag="horizontal" />
            </TabPanel>
          </Paper>
        </Grid>
        {false && survey_id && <div className={button}>
          <Button className={btnDownload} variant="contained" endIcon={<i className="el-icon-download"></i>} onClick={downloadChart}>
            グラフをダウンロード
          </Button>
        </div>}
        <Grid item xs={12}>
          <Paper className={clsx(paper, paperChart)} square >
            {tabIndex === TAB_QUESTION ? <AggregationQuestion/> : <AggregationCross/>}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

Aggregation.propTypes = propTypes;

export default connect(
  ({ aggregation, global: { projectData, surveyData } }) => ({
    project_id: projectData.value,
    survey_id: surveyData.value,
    feedback_count: aggregation.feedback_count,
    nps_score: aggregation.nps_score,
    promoters: aggregation.promoters,
    passives: aggregation.passives,
    detractors: aggregation.detractors,
  }),
  dispatch => ({
    fetchSurveyQuestions: (survey_id) => dispatch(thunkFetchSurveyQuestions(survey_id, { question_type: 'aggregation' })),
    exportCsv: () => dispatch(thunkExportItems('feedbacks', Storage.getSearchParams())),
  })
)(Aggregation)
