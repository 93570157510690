import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ElementIcon from "common/icon/ElementIcon";

const useStyles = makeStyles(theme => {
  return{
    menuButton: {
      color: theme.palette.grayscale[400],
    }
  }
});

const MenuButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const visibleDrawer = useSelector(state => state.common.visibleDrawer);
  const isMobile = useSelector(state => state.common.isMobile);

  let IconType = `s-unfold`;

  if(!visibleDrawer){
    IconType = `s-fold`
  }

  const onClick = () => {
    dispatch({ type: "TOGGLE_DRAWER" });
    //distpacth change size too, b.c on mbile its doesn't appear
    !isMobile && dispatch({ type: "TOGGLE_MENU_SIZE" });
  };

  return (
    <IconButton
      className={classes.menuButton}
      color="inherit"
      aria-label="menu"
      onClick={onClick}
    >
      <ElementIcon name={ IconType } />
    </IconButton>
  );
};

export default MenuButton;
