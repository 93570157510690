import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Menu } from '@material-ui/core'
import clsx from "clsx"
import { dropdownSelectStyles } from './styles'
import { WrappedText } from '../modules/feedbacks/FeedbackDetail'

const useStyles = makeStyles(dropdownSelectStyles)

const DropdownSelect = ({ placeholder, label, data, onChange }) => {
  const filterSelected = (list, filterList) => {
    list.forEach(node => {
      if(node.checked) {
        filterList.push({
          type: node.type,
          label: node.label,
          value: +node.value,
          owner_group_id: node.owner_group_id,
        })
      }
      if(node.type === 'group') {
        filterSelected(node.children, filterList)
      }
    })
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [selected, setSelectd] = useState([])

  useEffect(() => {
    let selectedList = []
    filterSelected(data, selectedList)
    setSelectd(selectedList)
    // eslint-disable-next-line
  }, [data])

  const compareObject = (obj1, obj2) => (JSON.stringify(obj1) === JSON.stringify(obj2))

  const handleSelect = node => {
    let newSelectedList = []
    const nodeIsSelected = selected.some(el => (compareObject(node, el)))
    if (nodeIsSelected) {
      newSelectedList = selected.filter(el => (!compareObject(node, el)))
    }
    else {
      newSelectedList = [...selected, node]
    }
    setSelectd(newSelectedList)
    onChange(newSelectedList)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles()

  const renderTreeNode = (node, index) => {
    const {type, label, children, disabled, checked, owner_group_id, value} = node
    const hideCheckboxGroup = disabled && (type === 'group')
    const toggleGroup = ({target}) => {
      const group = target.offsetParent
      const expandedStatus = group.getAttribute('expanded')
      group.setAttribute('expanded', expandedStatus === 'true' ? 'false' : 'true')
    }

    return (
      <span key={clsx(index, value, owner_group_id)}>
        <WrappedText>
        <li expanded='true' className={checked ? 'checked' : ''}>
          <div className='selectArea' onClick={() => { handleSelect({
            type: type,
            label: label,
            value: +value,
            owner_group_id: owner_group_id,
          })}}>
            <label>{label}</label>
            <span className={clsx('checkbox', hideCheckboxGroup && classes.hidden)}>選択</span>
          </div>
          <span
            className={clsx('toggler', ((type !== 'group') || (children.length === 0)) ? classes.hidden : '')}
            onClick={toggleGroup}
          ></span>
        </li>
        {
          (type === 'group') &&
          <ul>
            { children.map(node => renderTreeNode(node, value)) }
          </ul>
        }
        </WrappedText>
      </span>
    )
  }

  return (
    <label className={classes.label}>
      <span>{label}</span>

      <div
        onClick={handleClick}
        className={clsx(classes.control, Boolean(anchorEl) ? classes.controlActive : '')}
      >
        {placeholder || ''}
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        classes={{
          list: classes.menuList,
          paper: classes.paper
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ul>
          {data.map((node, index) => renderTreeNode(node, index))}
        </ul>
      </Menu>
    </label>
  )
}

export default DropdownSelect;
