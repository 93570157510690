import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, withStyles } from "@material-ui/core";

const styles = theme => ({
  customIcon: {
    fontSize: "12px",
    color: "white"
  },
  customInput: {
    width: "100%",
    "& .MuiOutlinedInput-inputAdornedStart": {
      padding: "10px",
      fontSize: "12px"
    },
    "& .MuiInputAdornment-positionStart": {
      height: "40px",
      maxHeight: "unset",
      marginRight: 0,
      backgroundColor: "#ec681a",
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
      padding: "5px 15px;"
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0
    }
  },
  accountId: {
    "& .MuiInputAdornment-positionStart": {
      backgroundColor: "#b7b7b7 !important"
    }
  },
  submitBtn: {
    width: "auto",
    padding: "5px 85px",
    minWidth: "300px",
    marginTop: "10px",
    "&.MuiButton-contained": {
      backgroundColor: "#ec681a",
      color: "white"
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "5px 10px",
      minWidth: "150px",
      marginTop: "10px",
      fontSize: "10px",
      "&.MuiButton-contained": {
        backgroundColor: "#ec681a",
        color: "white"
      }
    }
  },
  dialogChangePassword: {
    width: "500px"
  },
  headerBackground: {
    position: "absolute",
    top: "0",
    border: "2px solid #ec681a",
    height: "1",
    width: "100%"
  },
  blockContent: {
    padding: "30px 100px",
    textAlign: "center",
    "& div": {
      padding: "20px",
      "& p": {
        margin: "0",
        fontSize: "12px",
        fontWeight: "600"
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px 10px",
      "& div": {
        padding: "20px",
        "& p": {
          margin: "0",
          fontSize: "10px",
          fontWeight: "600"
        }
      }
    }
  },
  blockButton: {
    padding: "20px 0 0 0"
  },
  buttonLeft: {
    background: "#ec681a",
    color: "#fff",
    marginRight: "15px",
    height: "38px",
    border: "1px solid #ec681a",
    fontSize: "14px",
    "&:hover":{
      opacity: 0.6
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "85px",
      height: "38px",
      border: "1px solid #ec681a",
      padding: "5px 0"
    }
  },
  buttonRight: {
    color: "#ec681a",
    background: "#fff",
    height: "38px",
    border: "1px solid #ec681a",
    fontSize: "14px",
    "&:hover":{
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      border: "1px solid #F02544",
      color: "#F02544"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "85px",
      height: "38px",
      border: "1px solid #ec681a",
      padding: "5px 0"
    }
  },
  blockChangePassword: {
    textAlign: "center",
    minWidth: "500px",
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "auto",
      padding: "15px"
    }
  },
  blockChangePasswordTitle: {
    padding: "10px 10px",
    borderBottom: "1px solid #ccc",
    textAlign: "left",
    "& p": {
      margin: "0"
    }
  },
  blockChangePassWordInput: {
    padding: "0 20px"
  },
  blockNoti: {
    padding: "40px 0",
    "& p": {
      fontSize: "12px",
      fontWeight: "600",
      margin: "0"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px 30px"
    }
  },
  inputEdit:{
    width: "100%",
    padding: "0 10px",
    "& >div":{
      "& >input":{
        font: "500 12px/18px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0",
        color: "#B7B7B7",
        opacity: 1,
        padding: "14px 20px"
      },
      "& div":{
        "& i":{
          color: "#B7B7B7"
        }
      }
    }
  }
});

const propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  okText: PropTypes.string,
  nokText: PropTypes.string,
};

const VerificationDialog = ({ open, classes, children, onConfirm, onCancel, okText, nokText }) => {
  const [show, setShow] = useState(open)

  useEffect(() => {
    setShow(open)
  }, [open])

  const handleConfirm = e => {
    !!onConfirm && onConfirm(e);
  };

  const handleCancel = e => {
    !!onCancel && onCancel(e);
    setShow(false)
  };

  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="simple-dialog-title"
      open={show}
    >
      <div className={classes.blockChangePassword}>
        <div className={classes.blockNoti}>
          { children }
        </div>
        <div className={classes.blockButton}>
          <Button
            type="submit"
            disabled={false}
            variant="contained"
            className={classes.buttonLeft}
            onClick={handleConfirm}
          >
            {okText}
          </Button>
          <Button
            type="button"
            disabled={false}
            variant="contained"
            className={classes.buttonRight}
            onClick={handleCancel}
          >
            {nokText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

VerificationDialog.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(VerificationDialog);
