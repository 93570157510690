import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "@material-ui/core";

import MenuList from "../MenuList";

const MenuDrawer = () => {
  const dispatch = useDispatch();
  const visibleDrawer = useSelector(state => state.common.visibleDrawer);
  const isMobile = useSelector(state => state.common.isMobile);
  let drawerVariant = 'persistent';
  let openType = true;

  if (isMobile) {
    drawerVariant = 'temporary';
    openType = visibleDrawer;
  }
  const onClose = () => {
    dispatch({ type: "TOGGLE_DRAWER" });
  };

  return (
    <>
        <Drawer 
          open={openType} 
          onClose={onClose}
          variant={drawerVariant}
          anchor="left"
          >
          <MenuList />
        </Drawer>
    </>
  );
};

export default MenuDrawer;
