import { useEffect } from 'react';
import GA4 from 'react-ga4';
import ReactGA from 'react-ga';
import { GA4_MEASUREMENT_ID, GA_MEASUREMENT_ID } from './constants';
import { Storage } from "utils";

export const initGA = (gaOptions={}) => {
  if (!!GA4_MEASUREMENT_ID) {
    // UserIDのセット
    const userData = Storage.getUserData();
    const options = {
      gaOptions: {
        ...gaOptions,
        userId: userData.id,
      },
    }
    GA4.initialize(GA4_MEASUREMENT_ID, options)
  }

  if (!!GA_MEASUREMENT_ID) {
    ReactGA.initialize(GA_MEASUREMENT_ID, { gaOptions })
  }
};

export const GAPageView = (suffPath, title, cus_dimensions={}) => {
  if (!!GA4_MEASUREMENT_ID) {
    const { path, dimensions } = GAData(suffPath, true, cus_dimensions)
    let fields = { hitType: 'pageview', page: path, title: title }
    if (dimensions !== null) { fields = { ...fields, ...dimensions } }
    GA4.send(fields)
  }

  if (!!GA_MEASUREMENT_ID) {
    const { path, dimensions } = GAData(suffPath, false, cus_dimensions)
    if (dimensions !== null) { ReactGA.set(dimensions) }
    ReactGA.pageview(path, [], title)
  }
};

const GAData = (suffPath, isGA4, cus_dimensions) => {
  let path = window.location.pathname;
  if (suffPath) path += "/" + suffPath;
  path +=  window.location.search;

  let dimensions = cus_dimensions;
  if (cus_dimensions !== null) {
    const userData = Storage.getUserData();
    const selectedCompany = Storage.getSelectedCompany();

    dimensions = {
      dimension1: userData.id,
      dimension2: selectedCompany.label,
      dimension3: userData.name,
      dimension4: userData.authority,
      dimension5: cus_dimensions['dimension5'],
      dimension6: cus_dimensions['dimension6'],
      dimension7: cus_dimensions['dimension7'],
    };

    if (isGA4) {
      dimensions = {
        'ユーザーID': userData.id,
        'アカウント名': selectedCompany.label,
        '権限': userData.authority,
        'フィードバックID': cus_dimensions['dimension5'],
        'スタッフ所属店舗': cus_dimensions['dimension7'],
        user_properties: { 
          user_app_id: userData.id
        },
      };
    }
  }

  return { path, dimensions };
};

export const useGAEffect = (ga, suffPath, title, cus_dimensions={}) => {
  useEffect(() => {
    if (ga.initialized === true) GAPageView(suffPath, title, cus_dimensions)
    // eslint-disable-next-line
  }, [ga.initialized])
}
