
export const lineStyle = {
  type: "linear",
  strokeWidth: 2,
  dot: { 
    r: 6 
  },
}

export const getMinMax = (items, fields) => {
  const apply = (func, array) => func.apply(Math, array)

  if (Array.isArray(items) && items.length > 0)
    return [
      apply(Math.min, fields.map(field => apply(Math.min, items.map(item => +item[field]).filter(x => !isNaN(x))))),
      apply(Math.max, fields.map(field => apply(Math.max, items.map(item => +item[field]).filter(x => !isNaN(x))))),
    ]
  else
    return [0, 0]
}

const SCALE = 0.9

export const scaleUp = (value, scale=SCALE) => {
  if (value === 0) {
    return 0
  } else if (value < 0) {
    return -scaleDown(-value, scale)
  }
  const wholePart = +(value / scale).toFixed()
  const wholePartLength = wholePart.toString().length
  if (wholePartLength === 1)
    return scaleUp(value*10, scale)/10
  else {
    const withFraction = (value / scale).toFixed(2)
    const factor = getRoundingFactor(wholePart, true)
    const answer = Math.ceil(withFraction / factor) * factor
    return answer
  }
}

export const scaleDown = (value, scale=SCALE) => {
  if (value === 0)
    return 0
  else if (value < 0)
    return -scaleUp(-value, scale)
  const wholePart = +(2*value - value / scale).toFixed()
  const wholePartLength = wholePart.toString().length
  if (wholePartLength === 1)
    return scaleDown(value*10, scale)/10
  else {
    const withFraction = (2*value - value / scale).toFixed(2)
    const factor = getRoundingFactor(wholePart, false)
    const answer = Math.floor(withFraction / factor) * factor
    return answer
  }
}

export const scaleCorrelation = ([lower, upper], defaultValue=[0, 10]) => {
  const deltaUpper = Math.abs(scaleUp(upper) - upper)
  const deltaLower = Math.abs(scaleDown(lower) - lower)
  const delta = Math.max(deltaUpper, deltaLower)
  const answer = delta === 0 ? defaultValue : [scaleDown(lower-delta, 1), scaleUp(upper+delta, 1)]
  answer.push((answer[0] + answer[1])/2)
  return answer
}

/**
 * Gets value that determines by how much given number can be rounded up or down
 * 
 * @param {Number} value to round
 * @param {Boolean} up indicates whether to round up or down
 */
const getRoundingFactor = (value, up=true) => {
  if (value === 0) 
    return 0.1
  const oron = value.toString().length
  if (oron === 2) {
    if (value % 5 < 3 && up)
      return 1
    else if (value % 5 > 2 && !up)
      return 1
    else
      return 5
  }
  else if (oron === 3) return 10
  else if (oron === 4) return 100
  else if (oron === 5) return 1000
  else if (oron > 5) return 10000
  else return 1;
}
