import { List, getIn } from "immutable";

export const getNewDisplayOrder = (tree, parentId) => {
  // top node
  if (!parentId || +parentId === 0) {
    return tree.length === 0 ? 0 : +tree[tree.length - 1].display_order + 1;
  }
  let order = null;
  for (const i in tree) {
    let children = tree[i].children;
    if ( tree[i].id === parentId ) {
      order = children.length === 0 ? 0 : children[children.length - 1].display_order + 1;
    } else {
      order = getNewDisplayOrder(children, parentId);
    }
    if (!!order) break;
  }
  return !!order ? order : 0;
}

export const getMemberIds = (tree, parentId) => {
  let rs = [];
  if (!!parentId) {
    const parentKeyPath = findKeyPathOf(
      tree,
      "children",
      node => node.get("id") === parentId
    ).concat("children");
    tree = getIn(tree, parentKeyPath);
  }
  tree.valueSeq().forEach(v => {
    let id  =  v.id ? v.id : v.get('id');
    rs.push(id);
  });
  return rs;
}

export const findKeyPathOf = (tree, childrenKey, predicate) => {
  let path;
  if (List.isList(tree))
    childrenKey = tree.findKey(child =>
      path = findKeyPathOf(child, childrenKey, predicate));
  else if (predicate(tree))
    return [];
  else
    path = findKeyPathOf(tree.get(childrenKey), childrenKey, predicate);
  return path && [childrenKey].concat(path);
}

export const checkVisibleAll = (list) => {
  let rs = false;
  if (list.length > 1) {
    rs = list.reduce(function (acc, cur) {
      return (!acc.hasOwnProperty("visibleTree") || (acc.hasOwnProperty("visibleTree") && acc.visibleTree)) 
        && (!cur.hasOwnProperty("visibleTree") || (cur.hasOwnProperty("visibleTree") && cur.visibleTree));
    });
  } else if (list.length === 1) {
    const [item] = list;
    rs = !item.hasOwnProperty("visibleTree") || (item.hasOwnProperty("visibleTree") && item.visibleTree)
  }
  return rs;
}
