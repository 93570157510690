import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  PowerSettingsNew as PowerSettingsNewIcon, 
} from "@material-ui/icons";
import { List, ListItem, ListItemIcon, ListItemText, Popover } from "@material-ui/core";
import { thunkFetchAllCompanies, thunkFetchCompany } from "modules/companies/redux/thunk";
import { logout } from "modules/authentication/redux/thunk";
import { parseToOptionValues } from "modules/utils";
import { Storage } from 'utils';

const useStyles = makeStyles(theme => {
  return{
    root:{
     '& .MuiPopover-paper':{
      width: 240,
       [theme.breakpoints.only('xs')]:{
         left: '0 !important',
         top: '108px !important',
         height: 'calc(100vh - 56px - 52px)',
       }
     },
     '& .MuiPaper-rounded':{
       borderRadius: 0,
     },
     '& .MuiListtime-root':{
       padding: '16px 0 23px 40px',
     }
    },
    listItems:{
      padding: '16px 0 23px 20px',
      '& .MuiListItemIcon-root':{
        marginRight: theme.spacing(3),
      },
      
      '& .MuiListItemText-root': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }
    },
    Dropdown:{
      backgroundColor: theme.palette.grayscale[100],
    },
    sublistWrap:{
      height: 'calc(90vh - 250px)',
      overflowY: 'scroll',
      padding: '0 10px 20px',
    },
    ListSubItems:{
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: '14px 5px 0',
      marginTop: theme.spacing(1),
      '& p':{
         width: 210,
      }
    },
    listBorder:{
      width: '100%',
      marginTop: 14,
      marginBottom: 0,
      border: '0.6px solid',
      borderColor: theme.palette.grayscale[300],
    },
    subSelected:{
      backgroundColor: 'transparent !important',
      color: theme.palette.primary.main,
      '& p':{
        fontWeight: 600,
      }
    }
  }
});

const propTypes = {
  companiesOptions: PropTypes.array,
  companiesLoading: PropTypes.bool.isRequired,
  companiesLoaded: PropTypes.bool.isRequired,
  selectedCompany: PropTypes.object,
  userAuthority: PropTypes.string,
  fetchCompanies: PropTypes.func.isRequired,
  globalVarsChanged: PropTypes.func.isRequired,
  dataInvalidate: PropTypes.func.isRequired,
};

const UserPopover = ({
  anchorEl,
  anchorV,
  anchorH,
  open,
  handleClose,
  companiesLoading,
  companiesLoaded,
  companiesOptions,
  selectedCompany,
  userAuthority,
  fetchCompanies,
  globalVarsChanged,
  dataInvalidate,
  openCompany,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = open ? "user-popover" : undefined;

  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [stateCompanyOptions, setStateCompanyOptions] = useState([]);
  const [stateCompany, setStateCompany] = useState(selectedCompany.value);
  const isSuperAdmin = userAuthority === "super_admin";
  const isAdmin = userAuthority === "admin";
  const isMobile = useSelector(state => state.common.isMobile);
  
  const classes = useStyles();
  
  useEffect(() => {
    if (!companiesLoading && !companiesLoaded) {
      fetchCompanies();
    }

    if (companiesOptions) {
      setStateCompanyOptions(companiesOptions);
      setStateCompany(selectedCompany.value)
    }
    // eslint-disable-next-line
  }, [companiesLoading, companiesLoaded, companiesOptions]);

  const onSelectCompany = ({ label, value, use_dashboard,survey_type }) => {
    // ヘッダーにてアカウントを選択した際のイベント関数
    setStateCompany(value);
    Storage.setSelectedCompany({ label, value, use_dashboard,survey_type });
    globalVarsChanged();
    dataInvalidate();
    setVisibleDropdown(false);


    if(survey_type==="1"){
      // 店舗スコアアカウント=>商品スコアアカウントに切り替えた場合URLの置換を行う
      if (window.location.pathname.match(/\/admin\/dashboard/)) {
        // ダッシュボード
        history.push("/admin/products/dashboard");
      }else if(window.location.pathname.match(/\/admin\/feedbacks/)) {
        // フィードバック一覧
        history.push("/admin/products/feedbacks");
      }else if(window.location.pathname.match(/\/admin\/analysis/)) {
        // NPS分析
        history.push("/admin/products/analysis");
      }else if(window.location.pathname.match(/\/admin\/aggregation/)) {
        // アンケート集計
        history.push("/admin/products/aggregation");
      }else if(window.location.pathname.match(/\/admin\/projects/)) {
        // プロジェクト管理
        history.push("/admin/products/projects");
      }else if(window.location.pathname.match(/\/admin\/surveys/)) {
        // アンケート管理
        history.push("/admin/products/surveys");
      }else if(window.location.pathname.match(/\/admin\/shops/)) {
        // 商品(店舗)管理
        history.push("/admin/products/products");
      }else if(window.location.pathname.match(/\/admin\/area/)) {
        // エリア管理
        history.push("/admin/products/categories");
      }else if(window.location.pathname.match(/\/admin\/groups/)) {
        // グループ管理
        history.push("/admin/products/groups");
      }else if(window.location.pathname.match(/\/admin\/users/)) {
        // スタッフ管理
        history.push("/admin/products/users");
      }

      // 商品スコア=>商品スコアの場合で、アンケート詳細を開いている場合、ヘッダーにてアカウントを変更するとアンケート一覧を表示する。
      if (window.location.pathname.match(/\/admin\/products\/surveys\/\d+/)) {
        history.push("/admin/products/surveys");
      }
    }else if(survey_type==="0"){
      // 商品スコアアカウント=>店舗スコアアカウントに切り替えた場合URLの置換を行う
      if (window.location.pathname.match(/\/admin\/products\/dashboard/)) {
        // ダッシュボード
        history.push("/admin/dashboard");
      }else if(window.location.pathname.match(/\/admin\/products\/feedbacks/)) {
        // フィードバック一覧
        history.push("/admin/feedbacks");
      }else if(window.location.pathname.match(/\/admin\/products\/analysis/)) {
        // NPS分析
        history.push("/admin/analysis");
      }else if(window.location.pathname.match(/\/admin\/products\/aggregation/)) {
        // アンケート集計
        history.push("/admin/aggregation");
      }else if(window.location.pathname.match(/\/admin\/products\/projects/)) {
        // プロジェクト管理
        history.push("/admin/projects");
      }else if(window.location.pathname.match(/\/admin\/products\/surveys/)) {
        // アンケート管理
        history.push("/admin/surveys");
      }else if(window.location.pathname.match(/\/admin\/products\/products/)) {
        // 商品（店舗）管理
        history.push("/admin/shops");
      }else if(window.location.pathname.match(/\/admin\/products\/categories/)) {
        // エリア管理
        history.push("/admin/areas");
      }else if(window.location.pathname.match(/\/admin\/products\/groups/)) {
        // グループ管理
        history.push("/admin/groups");
      }else if(window.location.pathname.match(/\/admin\/products\/users/)) {
        // スタッフ管理
        history.push("/admin/users");
      }

      // 店舗スコア=>店舗スコアの場合で、アンケート詳細を開いている場合、ヘッダーにてアカウントを変更するとアンケート一覧を表示する。
      if (window.location.pathname.match(/\/admin\/surveys\/\d+/)) {
        history.push("/admin/surveys");
      }

    }

  };

  const handleItemClick = () => {
    if (isSuperAdmin){
      setVisibleDropdown(!visibleDropdown);
      if (!visibleDropdown) {
        fetchCompanies()
      }
    } else if (isAdmin){
      handleClose()
      openCompany(selectedCompany.value)
      history.push("/admin/companies")
    }
  }
  if(isMobile){
    anchorV = 'bottom';
    anchorH = 'left';
  }
  else{
    anchorV = 'top';
    anchorH = 'right';
  } 
  
  return (
    <Popover
      id={id}
      className={classes.root}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: anchorV,
        horizontal: anchorH
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
    >
      <List disablePadding={true} aria-label="contacts">
        <ListItem button onClick={() => dispatch(logout())} disableGutters={true} className={classes.listItems}>
          <ListItemIcon>
            <PowerSettingsNewIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="ログアウト" />
        </ListItem>
        {(isAdmin || isSuperAdmin) && <ListItem button disableGutters={true} onClick={handleItemClick} 
        className={ clsx(classes.listItems, visibleDropdown && classes.Dropdown) }>
          <ListItemText primary={selectedCompany.label || "アカウント"} className={visibleDropdown ? classes.subSelected : ''}/>
          {isSuperAdmin && <ListItemIcon>
            {visibleDropdown ? <ArrowDropDownIcon className={classes.subSelected}/> : <ArrowDropUpIcon />}
          </ListItemIcon>}
        </ListItem>}
        <List disablePadding={true} className={ clsx(visibleDropdown && classes.sublistWrap) }>
          {(visibleDropdown && isSuperAdmin) &&
            (stateCompanyOptions || []).map(({ label, value, use_dashboard,survey_type }) => (
              <ListItem key=
                  {`list-item-${value}`} 
                  selected={value === stateCompany} 
                  button
                  onClick={() => onSelectCompany({ label, value, use_dashboard,survey_type })}
                  disableGutters={true}
                  classes={{ root: classes.ListSubItems, selected: classes.subSelected }}
                >
                <ListItemText secondary={label} />
                <hr className={classes.listBorder} />
              </ListItem>
            ))}
          </List>
      </List>
    </Popover>
  );
};

UserPopover.propTypes = propTypes;

export default connect(
  ({ companies, global: { selectedCompany, userData: { authority } } }) => {
    return {
      companiesLoading: companies.allItemsLoading,
      companiesLoaded: companies.allItemsLoaded,
      // 可読性が著しく悪いのでいずれ要改修
      // 商品スコア開発、selectedCompanyに渡すsurvey_typeを追加
      companiesOptions: Array.isArray(companies.allItems) ? parseToOptionValues(companies.allItems, ({ id, attributes: { name, use_dashboard,survey_type } }) => ({ label: name, value: id, use_dashboard,survey_type })) : null,
      selectedCompany: selectedCompany || {},
      userAuthority: authority,
    }
  },
  dispatch => ({
    fetchCompanies: () => dispatch(thunkFetchAllCompanies()),
    globalVarsChanged: () => dispatch({ type: 'GLOBAL_VALUES_CHANGED' }),
    openCompany: id => dispatch(thunkFetchCompany(id)),
    dataInvalidate: () => {
      dispatch({ type: 'FETCH_FEEDBACKS_RESET' });
      dispatch({ type: 'FETCH_ANALYSIS_RESET' });
      dispatch({ type: 'FETCH_AGGREGATION_RESET' });
      dispatch({ type: 'FETCH_PROJECTS_RESET' });
      dispatch({ type: 'FETCH_SURVEYS_RESET' });
      dispatch({ type: 'FETCH_SHOPS_RESET' });
      dispatch({ type: 'FETCH_AREAS_RESET' });
      dispatch({ type: 'FETCH_GROUPS_RESET' });
      dispatch({ type: 'FETCH_USERS_RESET' });
      dispatch({ type: 'FETCH_COMPANIES_RESET' });
    },
  })
)(UserPopover);
