import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Paper,
  Grid,
  Button,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  SettingsOutlined as SettingsOutlinedIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import {
  ADD_PAGE_SURVEY_HEADING,
  ADD_PAGE_QUESTION,
  ADD_SURVEY_PAGE,
  PAGE_ITEM_UPDATE,
  SURVEY_PAGE_DELETE,
  SWAP_QUESTION_ITEMS,
} from '../../redux/actions';
import { SURVEY_FORM_TYPE_SURVEY_HEADING, QUESTION_OPTIONS, QUESTION_TYPE_NPS_QUESTION, QUESTION_TYPE_NPS_REASON, QUESTION_TYPE_SELECT_USER } from './constants';
import ConditionsSetupModal from '../ConditionsSetupModal';
import QuestionDnD from './QuestionDnD';
import QuestionDnDPlaceholder from './QuestionDnDPlaceholder';
import QuestionPopover from '../../components/QuestionPopover';
import { questionListStyles } from './styles';
import IconDelete from 'assets/icons/icon_delete_active.svg';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog';
import { thunkFetchAreaChildren } from "../../../categories/redux/thunk"
import { loadShops } from "../../../../../common/Header/HeaderForm/utils"
import { getLeaves } from "../utils"


const useStyles = makeStyles(questionListStyles);

const propTypes = {
  showAll: PropTypes.bool.isRequired,
  survey_pages_attributes: PropTypes.array.isRequired,
  addPageQuestion: PropTypes.func.isRequired,
  addPageSurveyHeading: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  deletePage: PropTypes.func.isRequired,
};

const QuestionList = ({
  showAll,
  survey_pages_attributes,
  area,
  addPageQuestion,
  addPageSurveyHeading,
  addPage,
  errors,
  deleteQuestion,
  deletePage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const [surveyPageParams, setSurveyPageParams] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [stSpIndex, setStSpIndex] = useState(null);
  const [deleteQuestionParams, setDeleteQuestionParams] = useState(null);
  const [deletePageParams, setDeletePageParams] = useState(null);
  const [expandInfo, setExpandInfo] = useState({});
  const [areaShops, setAreaShops] = useState(null);

  window.addEventListener('drag', ({clientY}) => {
    const container = document.getElementById('root-content')
    const { top, bottom } = container.getBoundingClientRect()
    if (clientY && (clientY <= (top + 50))) {
      container.scrollBy(0, -3)
    }
    else if (clientY && (clientY >= (bottom - 50))) {
      container.scrollBy(0, 3)
    }
  });

  const showConditionsSetupModal = (page) => {
    setSurveyPageParams(page)
  }

  const onModalOpen = async (obj) => {
    showConditionsSetupModal(obj)
    if (!areaShops) {
      if (area && area.value) {
        const children = await thunkFetchAreaChildren(area.value)
        if (children && children.length > 0) {
          const leafNodes = getLeaves({ children })
          setAreaShops(leafNodes)
        } else {
          // If area has no children, then display nothing on page condition
          setAreaShops([])
        }
      } else {
        // No area set, therefore show all shops
        const shops = await loadShops()
        setAreaShops([{ value: '', label: '全商品', shops }])
      }
    }
  }

  React.useEffect(() => {
    setAreaShops(null)
  }, [area])

  const closeConditionsSetupModal = () => {
    setSurveyPageParams(null)
  }

  const handleClickCreateServey = (event, _spIndex) => {
    setAnchorEl(event.currentTarget);
    setStSpIndex(_spIndex);
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  let questionTypeOptions = QUESTION_OPTIONS;
  let npsQuestionCount = 0;
  let npsReasonCount = 0;
  let staffSelectionCount = 0;

  for (let i = 0; i < survey_pages_attributes.length; i ++) {
    for (let j = 0; j < survey_pages_attributes[i].question_items.length; j ++) {
      const questionType = survey_pages_attributes[i].question_items[j].question_type;

      if (questionType === QUESTION_TYPE_NPS_QUESTION) {
        npsQuestionCount ++;
      } else if (questionType === QUESTION_TYPE_NPS_REASON) {
        npsReasonCount ++;
      } else if (questionType === QUESTION_TYPE_SELECT_USER) {
        staffSelectionCount ++;
      }
    }
  }

  if (npsQuestionCount <= 0 || npsReasonCount !== 0) {
    questionTypeOptions = questionTypeOptions.filter(item => item.value !== QUESTION_TYPE_NPS_REASON)
  }

  if (npsQuestionCount > 0) {
    questionTypeOptions = questionTypeOptions.filter(item => item.value !== QUESTION_TYPE_NPS_QUESTION)
  }

  if (staffSelectionCount > 0) {
    questionTypeOptions = questionTypeOptions.filter(item => item.value !== QUESTION_TYPE_SELECT_USER)
  }

  const checkAndDeleteQuestion = (questionParams) => {
    const { spIndex, piIndex } = questionParams;
    const questionToDelete = survey_pages_attributes[spIndex].question_items[piIndex];

    if (!!questionToDelete.id) {
      for (let i = 0; i < survey_pages_attributes.length; i ++) {
        const surveyPage = survey_pages_attributes[i];
  
        if (!!surveyPage.id) {
          for (let j = 0; j < surveyPage.survey_page_display_conditions_attributes.length; j ++) {
            const displayConditions = surveyPage.survey_page_display_conditions_attributes;

            if (displayConditions.filter(item => +item.question_id === +questionToDelete.id).length > 0) {
              alert("この設問は表示条件に設定されている為削除できません");
              return false;
            }
          }
        }
      }
    }

    setDeleteQuestionParams(questionParams)  
  };

  const handleExpanded = (spIndex) => {

    expandInfo[`survey_page:${spIndex}`] = expandInfo[`survey_page:${spIndex}`] === false;
    const valueToSet = expandInfo[`survey_page:${spIndex}`];

    const keys = Object.keys(expandInfo);
    keys.forEach(key => {
      if (key.indexOf(`sp:${spIndex}-`) !== -1) {
        expandInfo[key] = valueToSet;
      }
    })

    setExpandInfo({...expandInfo});
  };

  const moveCard = ({ spIndex1, piIndex1, spIndex2, piIndex2 }) => {
    dispatch({ type: SWAP_QUESTION_ITEMS, payload: { spIndex1, piIndex1, spIndex2, piIndex2 } });
  };

  return (
    <div className={classes.root}>
      {survey_pages_attributes.map((page, spIndex) => [page, spIndex])
        .filter(([page]) => (!!!page.discarded_at || showAll === true) && page._destroy !== 1)
        .map(([page, spIndex], filteredIndex) => {
          return (
            <div key={`page-${spIndex}`}>
              <Paper key={`page-${spIndex}-paper`} onClick={() => handleExpanded(spIndex)}>
                <Grid
                  className={`${classes.topTitle} ${(active === spIndex) ? classes.activeTitle : classes.inactiveTitle}`} 
                  container 
                  direction="row" 
                  justify="space-between" 
                  alignItems="center">
                    <div className={classes.title}>ページ {page.page_number}</div>

                  {filteredIndex > 0 &&
                    <div className={classes.rightSetting}>
                      <div className={classes.iconDelete}>
                        <img src={IconDelete} alt="delete" onClick={() => setDeletePageParams({spIndex})}/>
                      </div>

                      <Button
                        className={classes.settingBtn}
                        onClick={() => onModalOpen({
                          spIndex: filteredIndex,
                          surveyPage: page,
                        })}
                        endIcon={<SettingsOutlinedIcon className={classes.iconSetting} />}
                      >
                        条件を設定
                      </Button>
                    </div>
                  }
                </Grid>
              </Paper>

              <QuestionDnDPlaceholder
                key={`question-dnd-placeholder-${spIndex}`}
                spIndex={spIndex}
                show={page.question_items.length === 0}
                moveCard={moveCard}
              />

              {page.question_items
                .sort((a, b) => +a.display_order - +b.display_order)
                .map((item, piIndex) => [item, piIndex])
                .filter(([item]) => (!!!item.discarded_at || showAll === true) && item._destroy !== 1)
                .map(([item, piIndex]) => {
                  return (
                    <div
                      key={`wrapper-${item.uid || item.id}`}
                      onClick={() => {
                        setActive(spIndex)
                        setExpandInfo(prev => ({...prev, [`sp:${spIndex}-pi:${piIndex}`]: true}))
                      }}
                      onBlur={() => {
                        // setActive(null);
                        // setExpandInfo(prev => ({...prev, [`sp:${spIndex}-pi:${piIndex}`]: false}))
                      }}
                    >
                      <QuestionDnD
                        key={`question-dnd-${item.uid || item.id}`}
                        spIndex={spIndex}
                        piIndex={piIndex}
                        questionItem={item}
                        errors={(errors[spIndex] || [])[piIndex] || {}}
                        questionTypeOptions={questionTypeOptions}
                        setDeleteQuestionParams={checkAndDeleteQuestion}
                        isExpanded={expandInfo[`sp:${spIndex}-pi:${piIndex}`] !== undefined ? expandInfo[`sp:${spIndex}-pi:${piIndex}`] : expandInfo[`survey_page:${spIndex}`] !== false}
                        moveCard={moveCard}
                      />
                    </div>
                  );
                }
              )}

              <div className={classes.createButtonWrapper}>
                <IconButton component="div" className={classes.createButton} onClick={(e) => handleClickCreateServey(e, spIndex)}>
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          );
        }
      )}

      <ConditionsSetupModal
        spIndex={(surveyPageParams || {}).spIndex}
        surveyPage={(surveyPageParams || {}).surveyPage}
        closeConditions={closeConditionsSetupModal}
        areaShops={areaShops}
      />

      <QuestionPopover
        addQuestion={() => addPageQuestion(stSpIndex)}
        addSurveyHeading={() => addPageSurveyHeading(stSpIndex, { body: "見出し" })}
        addPage={() => addPage(stSpIndex)}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />

      <ConfirmationDialog
        show={!!deletePageParams}
        text={"アンケートページを削除しますか"}
        onCancel={() => setDeletePageParams(null)}
        onConfirm={() => {deletePage(deletePageParams); setDeletePageParams(null)}}
      />

      <ConfirmationDialog
        show={!!deleteQuestionParams}
        text={(deleteQuestionParams || {}).type === SURVEY_FORM_TYPE_SURVEY_HEADING ? "見出しを削除しますか": "設問を削除しますか"}
        onCancel={() => setDeleteQuestionParams(null)}
        onConfirm={() => {deleteQuestion(deleteQuestionParams); setDeleteQuestionParams(null)}}
      />
    </div>
  );
}

QuestionList.propTypes = propTypes;

export default connect(
  ({ surveys: { item: { survey_pages_attributes } } }, { showAll }) => ({
    survey_pages_attributes,
    showAll,
  }),
  (dispatch) => ({
    addPageQuestion: (spIndex, question) => dispatch({
      type: ADD_PAGE_QUESTION,
      payload: {
        spIndex,
        question: {
          ...question,
          question_type: '',
          discarded_at: null,
          options: [],
        }
      }
    }),
    addPageSurveyHeading: (spIndex, survey_heading) => dispatch({
      type: ADD_PAGE_SURVEY_HEADING,
      payload: {
        spIndex,
        survey_heading: {
          ...survey_heading,
          question_type: SURVEY_FORM_TYPE_SURVEY_HEADING,
          discarded_at: null,
        }
      }
    }),
    addPage: (spIndex=-1) => dispatch({ type: ADD_SURVEY_PAGE, payload: { spIndex } }),
    
    deleteQuestion: ({ spIndex, piIndex }) => 
      dispatch({
        type: PAGE_ITEM_UPDATE,
        payload: {
          updatedItem: { _destroy: 1 },
          spIndex,
          piIndex,
        }
      }),

    deletePage: ({ spIndex }) => 
      dispatch({
        type: SURVEY_PAGE_DELETE,
        payload: {
          spIndex,
        }
      }),
  })
)(QuestionList);
