import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { makeStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { UPDATE_PROJECT_ITEM } from './redux/actions';
import ProjectForm from './ProjectForm';
import { thunkDeleteProject } from './redux/thunk';


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  addBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
}));

export default () => {
  const classes = useStyles();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const deactivate = id => dispatch(thunkDeleteProject({ id }))

  const openModal = () => {
    dispatch({ type: UPDATE_PROJECT_ITEM, payload: { item: {} } })
    setModalIsOpen(true);
  };

  return (
    <>
      <div className={classes.root}>
        <Button
          variant="contained"
          className={classes.addBtn}
          startIcon={<AddIcon />}
          onClick={openModal}
        >
          新規追加
        </Button>
      </div>
      <ProjectForm modalOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)} item={{}} deactivate={deactivate} />
    </>
  );
};

