import moment from "moment";

export const stringDateFormat = "YYYY/MM/DD"
export const formatDate = date => moment(date).format(stringDateFormat);

const maxDayOfMonth = (month, year) => {
  if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
    return 31
  }
  if ([4, 6, 9, 11].includes(month)) {
    return 30
  }
  if ((year % 4) === 0) {
    return 29
  }
  return 28
}

const stringDateToArray = dateString => dateString.split(/\D/).map(str => parseInt(str))

const diff = (startDate, endDate) => {
  const [startYYYY, startMM, startDD] = stringDateToArray(formatDate(startDate))
  const [endYYYY, endMM, endDD] = stringDateToArray(formatDate(endDate))
  if (startYYYY === endYYYY && startDD === 1 &&  startMM === 1 && endDD === 31 &&  endMM === 12) {
    return {
      type: 'years',
      value: 1
    }
  }
  if (startYYYY === endYYYY && startMM === endMM && startDD === 1 && endDD === maxDayOfMonth(endMM, endYYYY)) {
    return {
      type: 'months',
      value: 1
    }
  }
  const dayInMiliSecond = 86400000
  const diffValue =  endDate - startDate
  return {
    type: 'days',
    value: diffValue / dayInMiliSecond + 1
  }
}

const increase = (date, diff) => {
  const { type, value } = diff
  const momentDate = moment(date).add(value, type)
  if (type === 'months' && momentDate.date() !== 1) {
    const newMonth = momentDate._d.getMonth() + 1
    momentDate.set('date', maxDayOfMonth(newMonth, momentDate.year()))
  }
  return momentDate._d
}

export const increaseRange = (startDate, endDate) => {
  const diffDate = diff(startDate, endDate)
  return {
    startDate: increase(startDate, diffDate),
    endDate: increase(endDate, diffDate)
  }
}

export const decreaseRange = (startDate, endDate) => {
  const { type, value } = diff(startDate, endDate)
  return {
    startDate: increase(startDate, { type, value: - value }),
    endDate: increase(endDate, { type, value: - value })
  }
}
