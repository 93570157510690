import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Divider, Grid, Typography } from '@material-ui/core';
import NpsScoreBarChart from './charts/NpsScoreBarChart';
import { themeAnalysisNpsScoreStyles } from "./styles"

const propTypes = {
  feedback_count: PropTypes.number.isRequired,
  nps_score: PropTypes.string.isRequired,
  promoters: PropTypes.string.isRequired,
  passives: PropTypes.string.isRequired,
  detractors: PropTypes.string.isRequired,
}

const useStyles = makeStyles(themeAnalysisNpsScoreStyles);

const AnalysisNpsScore = ({ nps_score, feedback_count, ...other }) => {
  const { paperContainer } = useStyles();
  return (
    <Grid container spacing={2} className={paperContainer}>
      <Grid item xs={12} sm={6} md={3}>
        <CustomPaper header="NPS" content={{ text: nps_score }} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CustomPaper header="回答数" content={{ text: feedback_count }} />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <CustomPaper header="NPSカテゴリ" content={{ component: <NpsScoreBarChart {...other} /> }} />
      </Grid>
    </Grid>
  )
};

AnalysisNpsScore.propTypes = propTypes;

const CustomPaper = ({ header, content }) => {
  const { paperHeader, paperContent, paperItem, paperChart } = useStyles();

  return (
    <Paper className={header === "NPSカテゴリ" ? paperChart : paperItem} square>
      <Paper className={paperHeader} square elevation={0}>{header}</Paper>
      <Divider variant="fullWidth" />
      {content.text !== undefined && 
        <div className={paperContent}>
          <Typography variant="h4" align="center">{content.text}</Typography>
        </div>
      }
      {!!content.component && content.component}
    </Paper>
  )
};

export default AnalysisNpsScore;
