import { default as axios } from 'axios';
import {
  FETCH_PUBLIC_USERS_BEGIN,
  FETCH_PUBLIC_USERS_SUCCESS,
  FETCH_PUBLIC_USERS_FAILURE,
  FETCH_PUBLIC_SURVEY_BEGIN,
  FETCH_PUBLIC_SURVEY_SUCCESS,
  FETCH_PUBLIC_SURVEY_FAILURE,
  FETCH_PUBLIC_AREAS_BEGIN,
  FETCH_PUBLIC_AREAS_SUCCESS,
  FETCH_PUBLIC_AREAS_FAILURE,
  FETCH_PUBLIC_SHOPS_BEGIN,
  FETCH_PUBLIC_SHOPS_SUCCESS,
  FETCH_PUBLIC_SHOPS_FAILURE,
  PUBLIC_SAVE_FEEDBACK_SUCCESS,
} from './actions';
import { API_URL, DOMAIN_NAME } from '../../../constants';
import { handleError } from 'utils';


export const thunkFetchSurvey = (
  companyUrlText,
  shortUrl,
  isPreviewMode,
  actions={
    actionBegin: () => ({ type: FETCH_PUBLIC_SURVEY_BEGIN }),
    actionSuccess: (survey, isPreviewMode) => ({ type: FETCH_PUBLIC_SURVEY_SUCCESS, payload: { survey, isPreviewMode } }),
    actionFailure: error => ({ type: FETCH_PUBLIC_SURVEY_FAILURE, payload: { error } }),
  }) => {

  return dispatch => {
    dispatch(actions.actionBegin());

    return axios.get(`${API_URL}/companies/${companyUrlText}/surveys/${shortUrl}`)
      .then(res => {
        const { data } = res;
        const host = window.location.host;
        const domain = (data.data.attributes.domain || '').toLowerCase();

        if ([DOMAIN_NAME, domain, `www.${domain}`].includes(host)) {
          dispatch(actions.actionSuccess(data, isPreviewMode));
        } else {
          dispatch(actions.actionFailure(new Error("domain mismatch")))
        }

        return data;
      })
      .catch(error => dispatch(actions.actionFailure(error)));
  };
}

export const thunkFetchAreas = (
  company_url,
  short_url,
  actions={
    actionBegin: () => ({ type: FETCH_PUBLIC_AREAS_BEGIN }),
    actionSuccess: areas => ({ type: FETCH_PUBLIC_AREAS_SUCCESS, payload: { areas } }),
    actionFailure: error => ({ type: FETCH_PUBLIC_AREAS_FAILURE, payload: { error } }),
  }) => {

  return dispatch => {
    dispatch(actions.actionBegin());

    return axios.get(`${API_URL}/companies/${company_url}/surveys/${short_url}/areas`)
      .then(res => {
        const { data } = res;
        dispatch(actions.actionSuccess(data));

        return data;
      })
      .catch(error => dispatch(actions.actionFailure(error)));
  };
}

export const thunkFetchShops = (
  company_url,
  short_url,
  areaId,
  actions={
    actionBegin: () => ({ type: FETCH_PUBLIC_SHOPS_BEGIN }),
    actionSuccess: shops => ({ type: FETCH_PUBLIC_SHOPS_SUCCESS, payload: { shops } }),
    actionFailure: error => ({ type: FETCH_PUBLIC_SHOPS_FAILURE, payload: { error } }),
  }) => {

  return dispatch => {
    dispatch(actions.actionBegin());

    const areaIdParam = !!areaId ? `?area_id=${areaId}` : '';

    return axios.get(`${API_URL}/companies/${company_url}/surveys/${short_url}/shops${areaIdParam}`)
      .then(res => {
        const { data } = res;
        dispatch(actions.actionSuccess(data));

        return data;
      })
      .catch(error => dispatch(actions.actionFailure(error)));
  };
}

export const thunkFetchUsers = ({ companyUrlText, shortUrl, shopId }) => {
  return dispatch => {
    dispatch({ type: FETCH_PUBLIC_USERS_BEGIN });
    return axios
      .get(`${API_URL}/companies/${companyUrlText}/surveys/${shortUrl}/users?shop_id=${shopId}`)
      .then(({ data: { data, included }, headers }) => {
        dispatch({
          type: FETCH_PUBLIC_USERS_SUCCESS,
          payload: {
            items: { data, included },
          }
        });
      })
      .catch(handleError(dispatch, error => ({
        type: FETCH_PUBLIC_USERS_FAILURE,
        payload: { error }
      })));
  };
}

export const thunkSaveFeedback = ({companyUrlText, shortUrl, params}) => dispatch => 
  axios.post(`${API_URL}/companies/${companyUrlText}/surveys/${shortUrl}/feedbacks`, { feedback: params })
    .then(res => {
      dispatch({ type: PUBLIC_SAVE_FEEDBACK_SUCCESS})
    })
    .catch(error => console.error(error));
