import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts';
import { getMinMax, scaleCorrelation, lineStyle } from '../utils';
import TimeSeriesLegend from './TimeSeriesLegend';
import { TooltipCount } from './TimeSeriesTooltip';
import { range } from 'modules/aggregation/utils';
import {getElementWidth} from "../../../utils";

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const TimeSeriesAnswersChart = ({ data }) => {
  const [ leftMargin, setLeftMargin ] = useState(0)
  const [ chartWidth, setChartWidth ] = useState(0)

  useEffect(() => {
    setLeftMargin(getElementWidth('datatable-headerCol'))
    setChartWidth(getElementWidth('datatable'))
  }, [data])

  window.addEventListener('resize', () => {
    setLeftMargin(getElementWidth('datatable-headerCol'))
    setChartWidth(getElementWidth('datatable'))
  })

  const lineProps = value => ({
    y: value,
    stroke: "#B7B7B7", 
    strokeWidth: 2,
    label: { 
      position: 'left', 
      value: value, 
      fill: '#796255', 
      fontSize: 10 
    }
  })

  const [, max] = scaleCorrelation(getMinMax(data, ["count"]), [0, 10])

  return (
    <div id="graph-timeseries-0" key={0} >
      <LineChart width={chartWidth} height={320} data={data} margin={{top: 20, left: leftMargin, bottom: 15}} key={0}>
        <YAxis domain={[0, max]} type="number" hide={true} />
        <XAxis type="category" padding={{left: 35, right: 35}} hide={true}/>
        <Tooltip cursor={{strokeDasharray: '3 3'}} content={<TooltipCount header="count"/>} />
        <Legend verticalAlign="top" align="right" wrapperStyle={{top: 0, right: 0, width: isMobileOnly ? "100%" : "30%"}}
          content={<TimeSeriesLegend />}
        />
        {range(10).map(i => <ReferenceLine key={`T${i}`} y={i*max/10} stroke="#F2EEEC"/>)}
        {[0, max/2, max].map((v,i) => <ReferenceLine key={i} {...lineProps(v)} />)}
        <Line {...lineStyle} dataKey="count" stroke="#C69075" name="回答数"/>
      </LineChart>
    </div>
  )
};

TimeSeriesAnswersChart.propTypes = propTypes;

export default TimeSeriesAnswersChart;
