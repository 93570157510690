import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox} from '@material-ui/core';
import { addOption, removeOption } from './redux/actions';
import { MAX_SELECTED_OPTIONS, DEFAULT_COLOR } from './constants';


const propTypes = {
  value: PropTypes.number,
  checked: PropTypes.bool,
  color: PropTypes.string,
  addOption: PropTypes.func,
  removeOption: PropTypes.func,
  selectedOptions: PropTypes.arrayOf(PropTypes.number),
}

const useStyles = makeStyles({
  root: {
    padding: 0,
    marginRight: 10,
    color: DEFAULT_COLOR,
  },
});

const CrossCheckbox = ({ value, checked, color, addOption, removeOption, selectedOptions }) => {
  const { root } = useStyles()
  const inputRef = useRef(null)
  const [selected, setSelected] = useState(checked)

  useEffect(() => {
    setSelected(checked)
  }, [checked])

  const onChange = e => {
    let calculated = e.target.checked
    if (calculated){
      addOption(+(inputRef.current.value))
    } else {
      removeOption(+(inputRef.current.value))
    }
    if (calculated && selectedOptions.length >= MAX_SELECTED_OPTIONS) {
      calculated = false
    }
    setSelected(calculated)
  }

  return (
    <Checkbox className={root}
      inputRef={inputRef}
      value={value}
      onChange={onChange}
      checked={selected}
      style={{ color }}
    />
  )
}

CrossCheckbox.propTypes = propTypes;

export default connect(
  ({ aggregation }) => ({
    selectedOptions: aggregation.totalCross.selectedOptions,
  }),
  dispatch => ({
    addOption: option => dispatch(addOption(option)),
    removeOption: option => dispatch(removeOption(option)),
  })
)(CrossCheckbox);
