import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Slide,
  Popover
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { format } from 'date-fns';
import './../../../public/scss/shareFeedback.scss';
import FeedbackAnswerList from './FeedbackAnswerList';
import FeedbackCommentList from './FeedbackCommentList';
import { getNpsColorStyle, getFeedbackUserName } from './utils';
import {
  fetchFeedbackAnswers,
  fetchFeedbackComments,
  thunkArchiveFeedback,
  thunkDislikeFeedback,
  thunkFetchFeedbackLikes,
  thunkLikeFeedback,
  thunkPinFeedback
} from './redux/thunk';
import { CLOSE_FEEDBACK_MODAL } from './redux/actions/feedback';
import ElementIcon from "common/icon/ElementIcon";
import { useFeedbackDetailStyle } from './styles'
import {TAB_INDEX_ANSWERS, TAB_INDEX_COMMENTS} from "./constants";
import PrintTemplate from './PrintTemplate'
import PinIcon from "../../../common/icon/PinIcon";
import {useLikeButtonStyle} from "./stylesButton";
import PinDialog from "../dashboard/goodvocs/PinDialog";
import LikeButton from "../dashboard/goodvocs/LikeButton";

const propTypes = {
  feedback: PropTypes.object,
  tabIndex: PropTypes.number,
  notAuthorized: PropTypes.bool,
  feedbackId: PropTypes.string,
  commentId: PropTypes.string,
  commentsCount: PropTypes.number,
  fetchAnswers: PropTypes.func.isRequired,
  fetchComments: PropTypes.func.isRequired,
  archiveFeedback: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  userAuthority: PropTypes.string,
  can_pin_feedback: PropTypes.bool,
};

export const WrappedText = styled.div`
  font: 12px Noto Sans JP, Hiragino Kaku Gothic Pro;
  color: #1A0707;
  
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`

const FeedbackDetail = ({
  feedback,
  likes,
  likeBegin,
  tabIndex,
  notAuthorized,
  feedbackId, // this indicates that this page is loaded from url
  commentId,
  commentsCount,
  fetchAnswers,
  fetchComments,
  archiveFeedback,
  pinFeedback,
  fetchLikes,
  likeFeedback,
  dislikeFeedback,
  closeModal,
  userAuthority,
  can_pin_feedback,
}) => {
  const [tab, setTab] = useState(tabIndex);
  const [dialogShown, showArchiveDialog] = useState(false);
  const [visible, setVisible] = useState(false);
  const [transition, setTransition] = React.useState(null);
  const feedback_id = feedbackId || (feedback || {}).id;
  const [printing, setPrinting] = useState(false);
  const isAdmin = userAuthority === "super_admin" || userAuthority === "admin";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMorePopover = event => setAnchorEl(event.currentTarget)
  const closeMorePopover = () => setAnchorEl(null)
  const id = open ? 'simple-popover' : undefined;

    // 2023年次改良スワイプ機能実装の為座標管理のstateを追加
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
  
    // rootのdivタグがタップされた際のイベント
    const handleTouchStart = (e) => {
      setStartX(e.touches[0].clientX);
      setStartY(e.touches[0].clientY);
    };
    // スワイプ、ドラッグした場合の座標取得と処理
    const handleTouchMove = (e) => {
      const currentX = e.touches[0].clientX;
      const currentY = e.touches[0].clientY;
  
      const deltaX = currentX - startX;
      const deltaY = currentY - startY;
  
    // 動いたy軸の絶対値（縦の長さ）より動いたx軸の絶対値（横の長さ）が大きくかつそれが正の値（右向き）な場合にモーダルの表示を切り替える
      if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > 0) closeModal()
    }

  useEffect(() => {
    if (!!feedback_id) {
      fetchComments(feedback_id);
      fetchAnswers(feedback_id, !!feedbackId);
    }
    if (!!+commentId) {
      changeTab(TAB_INDEX_COMMENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedback_id, commentId, fetchAnswers, fetchComments])

  useEffect(() => {
    tabIndex && changeTab(tabIndex)
  }, [tabIndex])

  const getUrl = () => {
    const { origin, pathname } = window.location;
    const basePath = `${origin}${pathname}`;
    return pathname.endsWith("/answers") ? basePath : `${basePath}/${feedback_id}/answers`;
  }

  const closeDialog = () => showArchiveDialog(false);
  const startPrint = () => setPrinting(true);
  const stopPrint = () => setPrinting(false);

  const likeIt = () => {
    if (feedback && feedback.feedback_like_id) {
      dislikeFeedback(feedback.id, feedback.feedback_like_id)
    } else {
      likeFeedback(feedback_id)
    }
  }

  const archive = () => {
    archiveFeedback(feedback_id)
    closeModal();
  }

  const tabProps = index => ({
    onClick: () => changeTab(index),
    className: index === tab ? "tabbar-left active-tab" : "tabbar-left none-active-tab",
  });

  const changeTab = tabIndex => setTab(tabIndex);
  const classes = useFeedbackDetailStyle();
  const buttonStyle = useLikeButtonStyle()
  const TransitionRight = props => {
    return <Slide {...props} direction="left" />;
  }
  const handleCopy = () => {
    setTransition(() => TransitionRight);
    setVisible(true);
  }
  const closeSnackbar = () => setVisible(false);
  const contentSnackbar = (
    <div className={classes.snackbarContainer}>
      <span className={classes.checkIcon}><i className="el-icon-check"/></span>
      <div className={classes.snackbarRightContent}>
        <p className={classes.labelTitle}>URLをコピーしました。</p>
        <p className={classes.labelContent}>Ctrl＋vを押して共有できます。</p>
      </div>
      <span className={classes.closeIcon} onClick={closeSnackbar}><i className=" el-icon-close"/></span>
    </div>
  );

  const pinIt = () => {
    closeMorePopover()
    pinFeedback(feedback_id, {pinned: !!!feedback.pinned})
  }

  if (notAuthorized && feedbackId){
    return <Redirect to='/admin/feedbacks' />;
  }

  return (
    <div className={classes.root} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <Grid>
        <div className={classes.buttonContainer}>
          <div>
            <div style={{ display: 'inline-block', marginRight: 10 }}>
              <LikeButton likes_count={feedback && feedback.likes_count}
                          likeBegin={likeBegin} likes={likes}
                          onHover={() => fetchLikes(feedback_id)}
                          highlight={!!feedback && !!feedback.feedback_like_id}
                          onClick={likeIt}/>
            </div>
            <CopyToClipboard text={getUrl()}>
              <Button variant='outlined' className={buttonStyle.buttonHeader} onClick={handleCopy}>
                共有<ElementIcon name={`link`} />
              </Button>
            </CopyToClipboard>
            <Button variant='outlined' className={clsx(buttonStyle.buttonHeader, buttonStyle.hiddenMobile)} onClick={startPrint}  >
              プリント<ElementIcon name={`printer`} />
            </Button>
            {(can_pin_feedback || isAdmin) && (
              <Button variant='outlined' className={classes.buttonMore} onClick={openMorePopover}>
                <ElementIcon name="more" fontSize={13}/>
              </Button>
            )}
          </div>
          <div className={classes.btnClose}>
            {!!!feedbackId && <Button
              justify='flex-end'
              onClick={closeModal}
              variant='contained'
              color='primary'
            > <p>閉じる</p>
              <i className='material-icons'>close</i>
            </Button>}
          </div>
        </div>
        {!!feedback && (
          <div>
            <div style={{ display: 'flex', justifyContent: "space-between", margin: "25px 0 10px 0" }}>
              <Typography className={classes.txtTop}>ID: {feedback.id}
                {feedback.pinned && (
                    <span className={classes.pinText}>
                      <PinIcon size={11} color={"#EC681A"}/>{"  ピン留め"}
                    </span>
                )}
              </Typography>
              <Typography className={classes.titleLeft}>
                {format(new Date(feedback.created_at), "yyyy/MM/dd HH:mm")}
              </Typography>
            </div>
            <Grid container>
              <Box className={classes.boxNumber} style={getNpsColorStyle(feedback.nps_score)}>
                {feedback.nps_score}
              </Box>
              <WrappedText>
                <div style={{ fontWeight: "bold" }}>{getFeedbackUserName(feedback)}</div>
                <div>{feedback.shop.name}</div>
              </WrappedText>
            </Grid>
          </div>
        )}
        <div className={classes.tabbar}>
        <div {...tabProps(TAB_INDEX_ANSWERS)}>回答</div>
        <div {...tabProps(TAB_INDEX_COMMENTS)}>{`コメント（${commentsCount}件）`}</div>
          <div className='clear'/>
        </div>
        <div className='clear'/>
        {tab === TAB_INDEX_ANSWERS ? (
          <FeedbackAnswerList />
        ) : (
          <FeedbackCommentList commentId={commentId} />
        )}
      </Grid>
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closeMorePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          className={classes.morePopover}
      >
        {can_pin_feedback &&
          <PinDialog className={classes.buttonPin} pinned={!!(feedback || {}).pinned} onSubmit={pinIt} >
              <span style={{marginRight: 10}}><PinIcon size={13} color="#EC681A"/></span>
              {!!(feedback || {}).pinned ? "ピン留め解除" : "ピン留め"}
          </PinDialog>
        }
        {isAdmin &&
          <div className={classes.buttonArchive} onClick={() => showArchiveDialog(true)}>
            <ElementIcon name="receiving" style={{marginRight: 10}}/>アーカイブ
          </div>
        }
      </Popover>
      <Dialog open={dialogShown} onClose={closeDialog}>
        <DialogContent>
          <DialogContentText>このフィードバックをアーカイブしますか</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={archive} color="secondary">OK</Button>
          <Button variant="outlined" onClick={closeDialog}>キャンセル</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        key="bottom_right"
        open={visible}
        ContentProps={{
          classes: {
            root: classes.snackbarRoot
          }
        }}
        classes={{ anchorOriginBottomRight: classes.snackbarAnchor }}
        autoHideDuration={3000}
        TransitionComponent={transition}
        onClose={closeSnackbar}
        message={contentSnackbar}
      />
      {
        printing && <PrintTemplate stopPrint={stopPrint}/>
      }
    </div>
  );
};

const has404Error = error => (error || {}).status === 404

FeedbackDetail.propTypes = propTypes;

export default connect(
  ({ feedbacks: { feedback, tabIndex, likeBegin, likes }, feedbackComments: { items, error }, feedbackAnswers, global: { userData } }, { feedbackId }) => ({
    feedback,
    likes,
    likeBegin,
    tabIndex,
    notAuthorized: has404Error(error) || has404Error(feedbackAnswers.error),
    feedbackId,
    commentsCount: items.length,
    userAuthority: userData.authority,
    can_pin_feedback: 'true' === userData.pin_feedback,
  }),
  dispatch => ({
    fetchAnswers: (feedbackId, setOpenFeedback) => dispatch(fetchFeedbackAnswers(feedbackId, setOpenFeedback)),
    fetchComments: feedbackId => dispatch(fetchFeedbackComments(feedbackId)),
    archiveFeedback: feedbackId => dispatch(thunkArchiveFeedback(feedbackId)),
    pinFeedback: (feedbackId, pinned) => dispatch(thunkPinFeedback(feedbackId, pinned)),
    fetchLikes: id => dispatch(thunkFetchFeedbackLikes(id)),
    likeFeedback: id => dispatch(thunkLikeFeedback(id)),
    dislikeFeedback: (id, likeId) => dispatch(thunkDislikeFeedback(id, likeId)),
    closeModal: () => dispatch({ type: CLOSE_FEEDBACK_MODAL })
  })
)(FeedbackDetail);
