import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types';
import DateFnsUtils from "@date-io/date-fns";
import Select, { components } from 'react-select';
import clsx from 'clsx';
import {
  Paper,
  FormGroup,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Grid,
  Button,
  FormHelperText,
  makeStyles,
  TextField,
} from "@material-ui/core";
import {
  DateRange as DateRangeIcon,
  AccessTime as AccessTimeIcon
} from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { EVALUATION_SCALE_OPTIONS } from '../constants';
import TabPanel from "./TabPanel";
import { FormField, TextFieldWithError } from '../components';
import { cleanseForSelect } from 'modules/products/categories/utils'
import { thunkFetchAreasSync } from '../../categories/redux/thunk'
import { loadProjects } from '../../../../common/Header/HeaderForm/utils'
import NoOptionsMessage from '../components/NoOptionsMessage'
import { thunkDeleteSurveyFeedbacks } from '../redux/thunk'
import ConfirmationDialog from '../../../../common/ConfirmationDialog'
import { commonSetting } from './styles';
import SelectCustom from 'components/Select/SelectCustom';
import DialogTreeSelect from 'components/Select/DialogTreeSelect';
import ElementIcon from '../../../../common/icon/ElementIcon';
import SwitchBtn from "../../../../components/SwitchBtn";

const useStyles = makeStyles(commonSetting);

const propTypes = {
  id: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
  name: PropTypes.string,
  area: PropTypes.object,
  project: PropTypes.object,
  setProject: PropTypes.func.isRequired,
  page_title: PropTypes.string,
  evaluation_scale: PropTypes.string,
  setEvaluationScale: PropTypes.func.isRequired,
  setArea: PropTypes.func.isRequired,
  publish_start_at: PropTypes.any,
  setPublishStartAt: PropTypes.func.isRequired,
  publish_end_at: PropTypes.any,
  setPublishEndAt: PropTypes.func.isRequired,
  common_header: PropTypes.string,
  common_css: PropTypes.string,
  isCreateAction: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  userAuthority: PropTypes.string,
};

const CustomRadio = styled(Radio)`
  && {
    :hover {
      background-color: transparent;  
    }
  }
`

export const CommonSettings = ({
  id,
  activeTab,
  name,
  project,
  setProject,
  page_title,
  evaluation_scale,
  setEvaluationScale,
  area,
  setArea,
  publish_start_at,
  setPublishStartAt,
  publish_end_at,
  setPublishEndAt,
  common_header,
  common_footer,
  common_css,
  isCreateAction,
  errors,
  userAuthority,
  is_satisfaction,
  satisfaction_level,
  is_correlation,
  correlation_coefficient,
  shop_required,
}) => {
  const [axisX, setAxisX] = useState(is_satisfaction)
  const [axisY, setAxisY] = useState(is_correlation)
  const [shopRequired, setShopRequired] = useState(shop_required)
  const [open, setOpen] = useState(false)
  const [openSelectDialog, setOpenSelectDialog] = useState(false)
  const [projects, setProjects] = useState(null)
  const [areas, setAreas] = useState(null)
  const dispatch = useDispatch()
  const classes = useStyles();

  const openDialog = () => setOpen(true)
  const closeDialog = () => setOpen(false)
  const destroyFeedbacks = () => {
    setOpen(false);
    dispatch(thunkDeleteSurveyFeedbacks(id));
  };

  const onProjectSelectFocused = async () => {
    if (projects === null) {
      setProjects(await loadProjects() || [])
    }
  }

  const onAreaSelectOpen = async () => {
    setOpenSelectDialog(true)
    if (areas === null) {
      const items = (await thunkFetchAreasSync() || []).map(cleanseForSelect)
      setAreas(items)
    }
  }

  const onAreaCleared = () => {
    setArea({ value: null })
  }

  const DropdownIndicator = props => {
    const { DropdownIndicator } = components
    return (
      <DropdownIndicator {...props}>
        <ElementIcon name='caret-bottom'/>
      </DropdownIndicator>
    )
  };

  return (
    <TabPanel alt="common" value={activeTab} index={0}>
      <Paper className={classes.root}>
        {!isCreateAction &&
          <>
            <FormField
              fullWidth
              className={classes.formSection}
              error={!!errors.project}
            >
              <label className={classes.formLabel}>プロジェクト選択</label>
              <Select
                name="project_id"
                placeholder='プロジェクトを選択'
                options={projects || []}
                value={project}
                onFocus={onProjectSelectFocused}
                onChange={setProject}
                components={{ DropdownIndicator, NoOptionsMessage: () => <NoOptionsMessage loading={projects===null}/> }}
              />
              {!!errors.project && (
                <FormHelperText>{`プロジェクト${errors.project}`}</FormHelperText>
              )}
            </FormField>

            <FormField
              fullWidth
              className={classes.formSection}
              error={!!errors.name}
              defaultValue={name}
            >
              <label className={classes.formLabel}>アンケートタイトル</label>
              <TextFieldWithError
                name={"page_title"}
                fieldLabel={"アンケートタイトル"}
                defaultValue={page_title}
                variant={"outlined"}
                errors={errors.page_title}
                placeholder={'アンケートのタイトルを入力してください。'}
                InputProps={{className: clsx(classes.formInputBase, classes.textField)}}
              />
            </FormField>
          </>
        }

        <FormField
          component="fieldset"
          label={"顧客接点の評価尺度"}
          fullWidth
          className={classes.formSection}
        >
          {!!evaluation_scale &&
            <RadioGroup
              name="evaluation_scale"
              value={evaluation_scale}
              row
              className={classes.anketGroupRadio}
            >
              {EVALUATION_SCALE_OPTIONS.map(({ name, value }) => (
                <FormControlLabel
                  key={`evalutionScale-${value}`}
                  value={value}
                  label={name}
                  control={<CustomRadio classes={{ root: classes.radio, checked: classes.checked }} disableRipple/>}
                  onChange={() => setEvaluationScale(value)}
                  labelPlacement="end"
                  className={classes.anketRadioButton}
                />
              ))}
            </RadioGroup>
          }
        </FormField>

        <FormField className={classes.formSection}>
          <label className={classes.formLabel}>対象カテゴリ</label>
          <Grid container direction="row" alignItems="center">
            <div className={clsx(classes.selectTree, !area && classes.selectTreePlaceholder)}>
              {(area && area.value) && <ElementIcon name='close' onClick={onAreaCleared} className={classes.btnClose}/>}
              <SelectCustom
                handleClick={onAreaSelectOpen}
                placeholder="カテゴリを選択"
                value={area ? area.label : "カテゴリを選択"}
                isActived={openSelectDialog}
              />
            </div>
          </Grid>
        </FormField>

        <FormGroup row className={classes.formSection}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} item>
            <div>
              <FormLabel component="legend" className={classes.formLabel}>開始日時</FormLabel>
              <div>
                <KeyboardDatePicker
                  className={clsx(classes.formPicker, classes.formDatePicker, classes.textField)}
                  variant={"inline"}
                  inputVariant={"outlined"}
                  keyboardIcon={<DateRangeIcon />}
                  format={"yyyy/MM/dd"}
                  placeholder={"0000/00/00"}
                  name={"publish_start_at"}
                  value={publish_start_at}
                  onChange={date => setPublishStartAt(date) }
                  invalidDateMessage={"開始日付が正しくありません"}
                />
                <KeyboardTimePicker
                  className={clsx(classes.formPicker, classes.formTimePicker, classes.textField)}
                  variant={"inline"}
                  ampm={false}
                  inputVariant={"outlined"}
                  keyboardIcon={<AccessTimeIcon />}
                  placeholder={"00:00"}
                  name={"publish_start_at"}
                  value={publish_start_at}
                  onChange={time => setPublishStartAt(time) }
                  invalidDateMessage={"開始時間が正しくありません"}
                />
              </div>
            </div>

            <div className={classes.blockFromDate}>
              <FormLabel component="legend" className={classes.formLabel}>終了日時</FormLabel>
              <div>
                <KeyboardDatePicker
                  className={clsx(classes.formPicker, classes.formDatePicker, classes.textField)}
                  variant={"inline"}
                  format={"yyyy/MM/dd"}
                  placeholder={"0000/00/00"}
                  inputVariant={"outlined"}
                  keyboardIcon={<DateRangeIcon />}
                  name={"publish_end_at"}
                  value={publish_end_at}
                  onChange={date => setPublishEndAt(date) }
                  invalidDateMessage={"終了日付が正しくありません"}
                />
                <KeyboardTimePicker
                  className={clsx(classes.formPicker, classes.formTimePicker, classes.textField)}
                  variant={"inline"}
                  ampm={false}
                  inputVariant={"outlined"}
                  keyboardIcon={<AccessTimeIcon />}
                  placeholder={"00:00"}
                  name={"publish_end_at"}
                  value={publish_end_at}
                  onChange={time => setPublishEndAt(time) }
                  invalidDateMessage={"終了時間が正しくありません"}
                />
              </div>
            </div>
          </MuiPickersUtilsProvider>
        </FormGroup>

        <div className={clsx(classes.formLabel, classes.commonHtml)}>商品コード(shop_id)必須</div>
        <div className={classes.marginFromLeft}>
          <SwitchBtn
            name={'shop_required'}
            checked={!!shopRequired}
            onChange={() => setShopRequired(v => !v)}
          />
        </div>
        <div className={clsx(classes.formLabel, classes.commonHtml)}>相関分析-しきい線</div>
        <div className={classes.marginFromLeft}>
          <div className={clsx(classes.formLabel, classes.commonHtml)}>満足度(X軸)</div>
          <SwitchBtn
              name={'is_satisfaction'}
              checked={axisX}
              label={'指定値で固定する'}
              onChange={() => setAxisX(v => !v)}
          />
          {axisX && <TextFieldWithError
              name={"satisfaction_level"}
              defaultValue={satisfaction_level}
              variant={"outlined"}
              errors={errors.satisfaction_level}
              InputProps={{
                className: clsx(classes.formInputBase, classes.textField)
              }}
          />}
          <div className={clsx(classes.formLabel, classes.commonHtml)}>相関係数(Y軸)</div>
          <SwitchBtn
              name={'is_correlation'}
              checked={axisY}
              label={'指定値で固定する'}
              onChange={() => setAxisY(v => !v)}
          />
          {axisY && (
            <TextFieldWithError
                name={"correlation_coefficient"}
                defaultValue={correlation_coefficient}
                variant={"outlined"}
                errors={errors.correlation_coefficient}
                InputProps={{
                  className: clsx(classes.formInputBase, classes.textField)
                }}
            />
          )}
        </div>

        <FormField component="fieldset" label={"共通ヘッダー(HTML)"} fullWidth className={clsx(classes.commonHtml, classes.textField)} error={!!errors.common_header}>
          <TextField
            name="common_header"
            placeholder="Htmlを入力してください。"
            multiline
            defaultValue={common_header}
            rows={10}
            variant="outlined"
            helperText="以下のタグが利用可能です。 h3, h4, h5, h6, blockquote, p, a, ul, ol, nl, li, b, i, strong, em, strike, code, hr, br, div, table, thead, caption, tbody, tr, th, td, pre, iframe"
          />
        </FormField>

        <FormField component="fieldset" label={"共通フッター(HTML)"} fullWidth className={clsx(classes.commonHtml, classes.textField)} error={!!errors.common_footer}>
          <TextField
              name="common_footer"
              placeholder="Htmlを入力してください。"
              multiline
              defaultValue={common_footer}
              rows={10}
              variant="outlined"
              helperText="以下のタグが利用可能です。 h3, h4, h5, h6, blockquote, p, a, ul, ol, nl, li, b, i, strong, em, strike, code, hr, br, div, table, thead, caption, tbody, tr, th, td, pre, iframe"
          />
        </FormField>

        <FormField component="fieldset" label={"共通CSS"} fullWidth className={clsx(classes.commonHtml, classes.textField)} error={!!errors.common_css}>
          <TextField
            name="common_css"
            placeholder="CSSで入力ください。"
            defaultValue={common_css}
            multiline
            rows={10}
            variant="outlined"
          />
        </FormField>

        <hr className={classes.line} />

        {userAuthority === 'super_admin' && <Button color="secondary" variant="contained" onClick={openDialog}>全ての回答をクリアする</Button>}
      </Paper>
      <ConfirmationDialog show={open} text={'このアンケートに対するすべてのフィードバックをクリアしますか'} onCancel={closeDialog} onConfirm={destroyFeedbacks} />
      {openSelectDialog && (
        <DialogTreeSelect
          title="カテゴリを選択してください。"
          itemSelected={area || {}}
          handleClose={() => setOpenSelectDialog(false)}
          handleSelect={setArea}
          options={areas}
        />
      )}
    </TabPanel>
  );
};

CommonSettings.propTypes = propTypes;

export default CommonSettings;
