import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { Avt } from "common/Header/HeaderUser";
import MenuButton from "common/Header/HeaderButton/MenuButton";
import HeaderTitle from "common/Header/HeaderTitle";

const useStyles = makeStyles(theme => {
  return{
    appBarTop: {
      width: '100%',
      height: "52px",
      display: "flex",
      flexFlow: 'column',
      color: props => props.textColor || theme.palette.common.black,
      backgroundColor: props => props.backgroundColor || theme.palette.common.white,
      padding: '0 40px',
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    appBarTitle: {
      width: "100%",
      height: '100%',
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      borderBottomColor: theme.palette.grayscale[200],
    },
    topLeft: {
      display: "flex",
      flexFlow: 'row nowrap',
      alignItems: "center",
      padding: '0 20px',
      [theme.breakpoints.only("xs")]: {
        padding: '0 16px',
      }
    },
    topRight:{
      marginRight: 16,
    },
    hiddenSm: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    }
  }
});

const AppBarTop = props => {
  const classes = useStyles(props);
  return (
    <div
      className={clsx(
        classes.appBarTop,
        props.drawerWidth !== 240 && classes.menuClose
      )}
    >
      <div className={classes.appBarTitle}>
        <div className={classes.topLeft}>
          <div className={classes.hiddenSm}>
            <MenuButton />
          </div>
          <HeaderTitle />
        </div>
        <div className={clsx(classes.topRight, classes.hiddenSm)}>
          <Avt letter={props.userName && props.userName.slice(0,1)}/>
        </div>
      </div>
    </div>
  );
};

export default AppBarTop;
