import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    maxWidth: '335px',
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px"
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: 'flex-end'
    }
  },
  dateWrapper: {
    width: 'calc(100vw - 100px)',
    maxWidth: '250px',
    border: "1px solid #9A877D",
    borderRadius: "5px",
    height: "36px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0",
    margin: "0 5px",
    [theme.breakpoints.only("xs")]: {
      padding: "0 2px",
    }
  },
  iconCalendar: {
    height: '20px',
    marginLeft: '5px',
  },
  iconDown: {
    color: "#9A877D",
    borderLeft: '1px solid',
    display: 'flex',
    width: '30px',
    justifyContent: 'center',
    transform: 'translateX(-2px)',
  },
  iconClear: {
    color: "#9A877D",
    cursor: "pointer",
    zIndex: 100,
    marginRight: '5px',
  },
  button: {
    width: 36,
    minWidth: 36,
    maxWidth: 36,
    height: 36,
    border: "1px solid #9A877D"
  },
  iconArrow: {
    color: "#9A877D",
  },
  dateRangePicker: {
    padding: '0 16px',
    width: '332px',
    boxSizing: 'border-box',

    '& span.rdrStartEdge, span.rdrInRange, span.rdrEndEdge, span.rdrDayStartPreview, span.rdrDayInPreview, span.rdrDayEndPreview': {
      color: '#EC681A !important',
      opacity: 0.2,
      borderRadius: 0
    },

    '& span.rdrDayStartPreview, span.rdrDayInPreview, span.rdrDayEndPreview': {
      transform: 'translateY(2px)',
    },

    '& button.rdrDay > span': {
      height: '30px',
    },

    '& .rdrStartEdge.rdrEndEdge': {
      width: '30px',
      transform: 'translateX(3px)'
    },

    '& span.rdrStartEdge': {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px'
    },

    '& span.rdrEndEdge': {
      borderTopRightRadius: '15px',
      borderBottomRightRadius: '15px'
    },

    '& span.rdrDayNumber > span': {
      color: '#1d2429 !important',
    },

    '& span.rdrStartEdge ~ span.rdrDayNumber > span, span.rdrEndEdge  ~ span.rdrDayNumber > span': {
      color: '#EC681A !important',
      fontWeight: 'bold'
    },

    '& button.rdrDayToday > span.rdrDayNumber > span::after': {
      background: '#EC681A !important'
    },

    '& .rdrDateDisplayWrapper': {
      display: 'none',
    },

    '& .rdrStaticRange': {
      margin: 3.5,
      width: '68px !important',
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      border: '1px solid #9A877D !important',

      '& > span': {
        padding: '0 !important',
        margin: '0 !important',
        lineHeight: 0
      },

      '&:hover': {
        border: '1px solid #EC681A !important',
        color: '#EC681A',
      },
    },

    '& .rdrStaticRangeSelected': {
      color: '#EC681A !important',
      border: '1px solid #EC681A !important',
      background: '#F2EEEC !important'
    },

    '& .rdrWeekDays': {
      borderBottom: '1px solid #9A877D',
      marginBottom: '10px',
    },

    '& .rdrNextPrevButton': {
      background: 'none !important',
      margin: 0,

      '& i': {
        display: 'none',
      },

      '&:after': {
        fontFamily: 'element-icons!important',
        fontSize: '16px',
        color: '#707070',
        fontWeight: 'bold',
      }
    },

    '& .rdrPprevButton:after': {
      content: '"\\e6de"',
    },

    '& .rdrNextButton:after': {
      content: '"\\e6e0"',
    },

    '& .rdrMonth': {
      padding: '0 10px 10px',
      width: '100% !important',

      '& .rdrWeekDay': {
        font: '700 10px/16px Noto Sans JP, Hiragino Sans',
        letterSpacing: '0px',
        color: '#939393',
        marginBottom: '10px',
      }
    },

    '& .rdrMonthAndYearWrapper': {
      padding: '10px',
    },

    '& .rdrDefinedRangesWrapper': {
      width: '100%',
      border: 'none !important',

      '& > div': {
        border: 'none !important',
      }
    }
  },
  dateRangePickerRemove: {
    padding: '0 16px',
    width: '332px',
    boxSizing: 'border-box',

    '& span.rdrStartEdge, span.rdrInRange, span.rdrEndEdge, span.rdrDayStartPreview, span.rdrDayInPreview, span.rdrDayEndPreview': {
      color: '#ffffff !important',
      opacity: 0.2,
      borderRadius: 0
    },

    '& span.rdrDayStartPreview, span.rdrDayInPreview, span.rdrDayEndPreview': {
      transform: 'translateY(2px)',
    },

    '& button.rdrDay > span': {
      height: '30px',
    },

    '& .rdrStartEdge.rdrEndEdge': {
      width: '30px',
      transform: 'translateX(3px)'
    },

    '& span.rdrStartEdge': {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px'
    },

    '& span.rdrEndEdge': {
      borderTopRightRadius: '15px',
      borderBottomRightRadius: '15px'
    },

    '& span.rdrDayNumber > span': {
      color: '#1d2429 !important',
    },

    '& span.rdrStartEdge ~ span.rdrDayNumber > span, span.rdrEndEdge  ~ span.rdrDayNumber > span': {
      color: '#EC681A !important',
      fontWeight: 'bold'
    },

    '& button.rdrDayToday > span.rdrDayNumber > span::after': {
      background: '#EC681A !important'
    },

    '& .rdrDateDisplayWrapper': {
      display: 'none',
    },

    '& .rdrStaticRange': {
      margin: 3.5,
      width: '68px !important',
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      border: '1px solid #9A877D !important',

      '& > span': {
        padding: '0 !important',
        margin: '0 !important',
        lineHeight: 0
      },

      '&:hover': {
        border: '1px solid #EC681A !important',
        color: '#EC681A',
      },
    },

    '& .rdrStaticRangeSelected': {
      color: '#1d2429 !important',
      '&:hover': {
        border: '1px solid #EC681A !important',
        color: '#EC681A !important',
      },
    },

    '& .rdrWeekDays': {
      borderBottom: '1px solid #9A877D',
      marginBottom: '10px',
    },

    '& .rdrNextPrevButton': {
      background: 'none !important',
      margin: 0,

      '& i': {
        display: 'none',
      },

      '&:after': {
        fontFamily: 'element-icons!important',
        fontSize: '16px',
        color: '#707070',
        fontWeight: 'bold',
      }
    },

    '& .rdrPprevButton:after': {
      content: '"\\e6de"',
    },

    '& .rdrNextButton:after': {
      content: '"\\e6e0"',
    },

    '& .rdrMonth': {
      padding: '0 10px 20px',
      width: '100% !important',

      '& .rdrWeekDay': {
        font: '700 10px/16px Noto Sans JP, Hiragino Sans',
        letterSpacing: '0px',
        color: '#939393',
        marginBottom: '10px',
      }
    },

    '& .rdrMonthAndYearWrapper': {
      padding: '10px',
    },

    '& .rdrDefinedRangesWrapper': {
      width: '100%',
      border: 'none !important',

      '& > div': {
        border: 'none !important',
      }
    }
  },
  dateValue: {
    font: '500 12px/18px Noto Sans JP, Hiragino Sans',
    letterSpacing: '0.25px',
    color: '#1A0707',
  },
  dateInput: {
    width: '300px',
    margin: '15px auto 30px',

    '& > p': {
      font: '500 10px/14px Noto Sans JP, Hiragino Sans',
      letterSpacing: '0.5px',
      color: '#1A0707',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& input': {
      width: '110px',
      height: '32px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #9A877D',
      borderRadius: '5px',
      textAlign: 'center',
      font: '500 10px/21px Noto Sans JP, Hiragino Sans',
      letterSpacing: '0.25px',
      color: '#1A0707',
    },
    '& button': {
      width: '60px',
      height: '32px',
      background: '#9A877D 0% 0% no-repeat padding-box',
      borderRadius: '5px',
      border: 'none',
      textAlign: 'center',
      font: '500 10px/14px Noto Sans JP, Hiragino Sans',
      letterSpacing: '0.25px',
      color: '#FFFFFF',
      '&:hover, &:active': {
        background: '#EC681A 0% 0% no-repeat padding-box',
        cursor: 'pointer',
      },
      '&:active': {
        opacity: '0.8',
      },
      '&:disabled': {
        background: '#9A877D 0% 0% no-repeat padding-box !important',
        cursor: 'not-allowed',
      }
    },
    '& .error': {
      borderColor: 'red'
    }
  },
  erorMessage : {
    color: 'red !important'
  }
}));

export default useStyles;
