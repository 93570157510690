import qs from "query-string";
import { default as axios } from "axios";
import {
  fetchFeedbacksBegin,
  fetchFeedbacksSuccess,
  fetchFeedbacksFailure,
  updateFeedbackComment,
  setActiveFeedback,
  archiveFeedback,
  fetchFeedbackLikes,
  fetchFeedbackLikesBegin,
  pinFeedback,
  likeFeedback,
  dislikeFeedback,
  likeFeedbackFailure,
  PIN_FEEDBACK,
  LIKE_FEEDBACK,
  DISLIKE_FEEDBACK,
  LIKE_FEEDBACK_BEGIN,
  FETCH_FEEDBACK_QUESTIONS,
  FETCH_FEEDBACK_QUESTIONS_BEGIN,
  FETCH_SCORE_QUESTIONS,
  FETCH_SCORE_QUESTIONS_BEGIN,
} from "./actions/feedback";
import {
  fetchCommentsBegin,
  fetchCommentsSuccess,
  fetchCommentsFailure,
  createComment,
  deleteComment,
  likeComment,
  dislikeComment,
  fetchCommentLikes,
  fetchCommentLikesBegin,
  likeCommentFailure,
  LIKE_COMMENT_BEGIN,
} from "./actions/comment";
import {
  fetchAnswersBegin,
  fetchAnswersSuccess,
  fetchAnswersFailure,
  editAnswer,
} from "./actions/answer";
import { API_URL, API_URL_V2, PAGINATION_PARAM_NO_PAGINATION } from "../../../constants";
import { parseFeedbackFromAnswers, getFeedbackUserName } from "../utils";
import { handleError } from 'utils';
import { GAPageView } from '../../../ga';

export const thunkFetchFeedbacks = (page, searchParams={}) => {
  return dispatch => {
    dispatch(fetchFeedbacksBegin())
    let params = {};

    if (page) {
      if (page === PAGINATION_PARAM_NO_PAGINATION) {
        params = { no_paging: 1 };
      } else {
        params = { page };
      }
    }

    if (Object.keys(searchParams).length > 0) {
      params = { ...params, ...searchParams }
    }

    const paramString = qs.stringify(params, { skipNull: true })
    const path = `${API_URL}/feedbacks${!!paramString ? '?' + paramString : ''}`

    return axios.get(path)
      .then(response => {
        const { data } = response

        const paginationParams = {
          perPage: response.headers['page-items'],
          total: response.headers['total-count'],
          offset: (+response.headers['current-page'] - 1) * +response.headers['page-items']
        }

        dispatch(fetchFeedbacksSuccess(data, paginationParams))
      })
      .catch(handleError(dispatch, fetchFeedbacksFailure))
  }
}

export const fetchFeedbackComments = id => {
  return dispatch => {
    dispatch(fetchCommentsBegin())
    return axios.get(`${API_URL}/feedbacks/${id}/feedback_comments`)
      .then(response => {
        const { data } = response
        dispatch(fetchCommentsSuccess(data))
      })
      .catch(handleError(dispatch, fetchCommentsFailure))
  }
}

export const fetchFeedbackAnswers = (id, setOpenFeedback) => {
  return dispatch => {
    dispatch(fetchAnswersBegin())
    return axios.get(`${API_URL}/feedbacks/${id}/answers`)
      .then(response => {
        const { data } = response
        if (data && setOpenFeedback){
          const feedback = parseFeedbackFromAnswers(id, data.included)
          dispatch(setActiveFeedback(feedback))
        }
        dispatch(fetchAnswersSuccess(data))
      })
      .catch(handleError(dispatch, fetchAnswersFailure))
  }
}

export const updateFeedbackAnswer = (feedbackId, answer={}) => {
  const path = `${API_URL}/feedbacks/${feedbackId}/answers/${answer.id}`
  return dispatch => {
    axios.patch(path, { answer })
    .then(({ data={}}) => {
      dispatch(editAnswer(data))
    })
    .catch(handleError(dispatch, fetchAnswersFailure))
  }
}

export const createFeedbackComment = (feedback, comment) => {
  const path = `${API_URL}/feedbacks/${feedback.id}/feedback_comments`
  const params =  { body: comment }
  return dispatch => {
    axios.post(path, { feedback_comment: params })
      .then(response => {
        if (response.data) {
          dispatch(createComment(response.data))
          dispatch(updateFeedbackComment(feedback.id, 1))

          const dimensions = {
            dimension5: feedback.id,
            dimension6: getFeedbackUserName(feedback, '該当なし'),
            dimension7: feedback.shop.name
          }
          GAPageView("comments", "フィードバックコメント", dimensions)
        }
      })
      .catch(handleError(dispatch, fetchCommentsFailure))
  }
}

export const deleteFeedbackComment = (feedbackId, commentId) => {
  const path = `${API_URL}/feedbacks/${feedbackId}/feedback_comments/${commentId}`
  return dispatch => {
    axios.delete(path)
      .then(response => {
        dispatch(deleteComment(commentId))
        dispatch(updateFeedbackComment(feedbackId, -1))
      })
      .catch(handleError(dispatch, fetchCommentsFailure))
  }
}

export const thunkArchiveFeedback = id => {
  return dispatch => {
    return axios.delete(`${API_URL}/feedbacks/${id}`)
      .then(() => {
        dispatch(archiveFeedback(id))
      })
      .catch(handleError(dispatch, fetchFeedbacksFailure))
  }
}

export const thunkPinFeedback = (feedbackId, pinned, type= PIN_FEEDBACK) => {
  const params = { feedback: pinned }
  return dispatch => {
    axios.patch(`${API_URL}/feedbacks/${feedbackId}`, params)
    .then(({ data }) => {
      if (data) {
        dispatch(pinFeedback(data, type))
      }
    })
    .catch(error => console.error(error))
  }
}

export const thunkFetchCommentLikes = id => {
  const path = `${API_URL_V2}/feedback_comments/${id}/feedback_comment_likes`
  return dispatch => {
    dispatch(fetchCommentLikesBegin())
    axios.get(path)
    .then(({ data }) => {
      if (data){
        dispatch(fetchCommentLikes(data))
      }
    })
    .catch(handleError(dispatch, fetchFeedbacksFailure))
  }
}

export const thunkFetchFeedbackLikes = feedbackId => {
  const path = `${API_URL_V2}/feedbacks/${feedbackId}/feedback_likes`
  return dispatch => {
    dispatch(fetchFeedbackLikesBegin())
    axios.get(path)
    .then(({ data }) => {
      if (data){
        dispatch(fetchFeedbackLikes(data))
      }
    })
    .catch(handleError(dispatch, fetchFeedbacksFailure))
  }
}

export const thunkLikeFeedback = (feedbackId, type=LIKE_FEEDBACK) => {
  const path = `${API_URL_V2}/feedbacks/${feedbackId}/feedback_likes`
  return dispatch => {
    dispatch({ type: LIKE_FEEDBACK_BEGIN })
    axios.post(path)
    .then(({ data }) => {
      if (data){
        dispatch(likeFeedback(feedbackId, data.data.id, type))
        GAPageView(`${feedbackId}/feedback_likes`, "フィードバックいいね")
      }
    })
    .catch(handleError(dispatch, likeFeedbackFailure))
  }
}

export const thunkDislikeFeedback = (feedbackId, likeId, type=DISLIKE_FEEDBACK) => {
  const path = `${API_URL_V2}/feedbacks/${feedbackId}/feedback_likes/${likeId}`
  return dispatch => {
    dispatch({ type: LIKE_FEEDBACK_BEGIN })
    axios.delete(path)
    .then(response => {
        dispatch(dislikeFeedback(feedbackId, type))
        GAPageView(`${feedbackId}/feedback_likes`, "フィードバックいいね")
    })
    .catch(handleError(dispatch, likeFeedbackFailure))
  }
}

export const thunkLikeComment = (feedbackId, comment_id) => {
  return dispatch => {
    dispatch({ type: LIKE_COMMENT_BEGIN })
    return axios.post(`${API_URL_V2}/feedback_comments/${comment_id}/feedback_comment_likes`)
    .then(({ data }) => {
      if (data)
        dispatch(likeComment(data, comment_id))
        GAPageView(`${feedbackId}/comment_likes`, "フィードバックコメントいいね")
    })
    .catch(handleError(dispatch, likeCommentFailure))
  }
}

export const thunkDislikeComment = (feedbackId, comment_id, comment_like_id) => {
  return dispatch => {
    dispatch({ type: LIKE_COMMENT_BEGIN })
    return axios.delete(`${API_URL_V2}/feedback_comments/${comment_id}/feedback_comment_likes/${comment_like_id}`)
    .then(response => {
        dispatch(dislikeComment(comment_id))
        GAPageView(`${feedbackId}/comment_likes`, "フィードバックコメントいいね")
    })
    .catch(handleError(dispatch, likeCommentFailure))
  }
}

export const fetchSurveyQuestions = survey_id => {
  return dispatch => {
    dispatch({ type: FETCH_FEEDBACK_QUESTIONS_BEGIN })
    axios.get(`${API_URL}/surveys/${survey_id}/questions?question_type=feedback`)
    .then(({ data: { data } }) => {
      dispatch({ type: FETCH_FEEDBACK_QUESTIONS, payload: { data }})
    })
    .catch(error => console.error(error))
  }
}

export const fetchScoreQuestions = survey_id => {
  return dispatch => {
    dispatch({ type: FETCH_SCORE_QUESTIONS_BEGIN })
    axios.get(`${API_URL}/surveys/${survey_id}/questions?question_type=score`)
    .then((response) => {
      const {data} = response
      // 各設問の最大pt取得
      for(const question of data.data){
        let targetOptionsIds = []
        for (const option of question.relationships.question_options.data){
          targetOptionsIds = [...targetOptionsIds, option.id]
        }
        const targetOptions = data.included.filter((option)=>targetOptionsIds.includes(option.id))
        let maxScore = 0
        for(const option of targetOptions){
          if(option.attributes.value && option.attributes.value > maxScore) maxScore = option.attributes.value
        }
        question.maxScore = maxScore
      }

      dispatch({ type: FETCH_SCORE_QUESTIONS, payload: { data: data.data }})
    })
    .catch(error => console.error(error))
  }
}