export const loginStyles = theme => ({
  blockLogin: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexFlow: 'column',
    margin: 0,
    backgroundColor: '#fff',
    "& img":{
      maxWidth: "250px",
      height: "40px",
      marginBottom: "35px"
    },
  },
  popupLogin: {
    width: '90%',
    maxWidth: 600,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 460,
    }
  },
  formLogin: {
    padding: [[
      theme.spacing(4), 60, theme.spacing(5)
    ]],
    [theme.breakpoints.down("xs")]: {
      padding: "30px 24px 84px",
    }
  },
  labelLogin : {
    font: "600 25px/18px Noto Sans JP, Hiragino Kaku Gothic Pro",
    letterSpacing: "1.25px",
    color: "#1A0707",
    opacity: 1,
    lineHeight:"30px"
  },
  submitBtn: {
    width: "53%",
    minWidth: "120px",
    fontSize: '1rem',
    lineHeight: '1em',
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: '100%',
    },
  },
  //icon
  customIcon: {
    fontSize: 15,
    color: "white"
  },
  someGridStyle: {
    marginTop: 26,
  },
  blockCheckbox:{
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    width: '100%',
    height: '16px',
    position: 'relative',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      width: '100%',
    },
    '& .MuiFormControlLabel-label':{
      ...theme.R14,
    }
  },
  inputField: {
    width: '100%',
    height: 45,
    position: 'relative',
    
    '& > input': {
      padding: '0 10px 0 55px',
      fontFamily: 'Hiragino Sans,ヒラギノ角ゴシック,Hiragino Kaku Gothic ProN,ヒラギノ角ゴ ProN W3,YuGothic,游ゴシック,Meiryo,メイリオ,ＭＳ Ｐゴシック,sans-serif,Noto Sans CJK JP',
      fontSize: '1rem',
      height: '100%',
      width: '100%',
      borderRadius: '4px',
      border: '1px solid #c4c4c4',
      
      '&:hover': {
        borderColor: 'black'
      },
      
      '&:focus': {
        border: '2px solid #ec681a',
      }
    },

    '& > input:hover ~ div': {
      border: '1px solid black',
    },

    '& > input:focus ~ div': {
      border: '2px solid #ec681a',
    },

    '& > div': {
      height: '45px',
      width: '45px',
      backgroundColor: '#EC681A',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      position: 'absolute',
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRightColor: '#ec681a !important'
    },

    '& .bg-brown': {
      backgroundColor: theme.palette.brown.Default,
      borderRightColor: `${theme.palette.brown.Default} !important`
    }
  }
});
