
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormHelperText,
  makeStyles,
  Typography
} from '@material-ui/core';
import { QuestionTypeTextStyles } from './styles';
import HtmlRender from './HtmlRender';


const useStyles = makeStyles(QuestionTypeTextStyles);

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onInputValue: PropTypes.func.isRequired,
};

const QuestionTypeText = ({
  questionItem,
  answer,
  errors,
  onInputValue,
}) => {
  const classes = useStyles();
  
  const textChangeHandler = ({ target: { value } }) => {
    onInputValue(questionItem, { answer_entry: value });
  };

  const textHasError = !!errors.answer_entry;

  return (
    <>
      <Typography className={classes.textAnswer}>
        <HtmlRender
          value = {questionItem.body}
        />
        {questionItem.is_required && (
          <span className="required">必須</span>
        )}
      </Typography>
      {!!questionItem.question_image_url && (
        <div className={classes.img}>
          <img src={questionItem.question_image_url} alt={''}/>
        </div>
      )}
      <textarea
        className={classes.textArea}
        placeholder={"入力してください。"}
        onInput={textChangeHandler}
        rows={questionItem.line_number || 1}
        defaultValue={answer.answer_entry}
      />
      {textHasError && (
        <FormHelperText error={true}>{errors.answer_entry.map(err => err)}</FormHelperText>
      )}
    </>
  );
}

QuestionTypeText.propTypes = propTypes;

export default QuestionTypeText;
