import {
  AUTHENTICATION_BEGIN,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE,
  LOGOUT_SUCCESS
} from './actions';
import { Storage } from '../../../utils';
import { parseUser } from '../../users/utils';

// ログイン時のセッション情報をブラウザのlocalstorageに保持する為のmoduleです。
// 呼び出している/src/utils/index.jsまた更にその先で呼び出している/src/util/storage内でdispatchは実行しているのでstateの更新は発生します。
// /src/redux/reducers/index.jsから呼び出されreducerとして機能します。

const initialState = {
  authenticated: Storage.isAuthenticated(),
  loading: false,
  errors: null,
};

// Reducerはstateの更新のみを行うシンプルな関数である必要があります。（Redux公式ガイドブック　https://redux.js.org/basics/reducers）
// ローカルストレージの更新とごちゃまぜになっているので修正の機会があれば再設計が必要です。

export default (state=initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_BEGIN:
      return {
        ...state,
        loading: true,
        authenticated: false,
        errors: null
      };

    case AUTHENTICATION_SUCCESS:
      const { saveSession, response } = action.payload;
      Storage.saveSession(
        saveSession, {
          token: response.headers['access-token'],
          client: response.headers['client'],
          uid: response.headers['uid'],
          expiry: +response.headers['expiry'],
        })

      Storage.setUserData(parseUser(response.data.data, response.data.included));

      return {
        ...state,
        loading: false,
        authenticated: true
      };

    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
      };
    default:
      return state;
  }
}
