export const FETCH_SURVEY_BEGIN = 'FETCH_SURVEY_BEGIN';
export const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS';
export const FETCH_SURVEY_FAILURE = 'FETCH_SURVEY_FAILURE';
export const FETCH_SURVEYS_BEGIN = 'FETCH_SURVEYS_BEGIN';
export const FETCH_SURVEYS_SUCCESS = 'FETCH_SURVEYS_SUCCESS';
export const FETCH_SURVEYS_FAILURE = 'FETCH_SURVEYS_FAILURE';
export const FETCH_ALL_SURVEYS_BEGIN = 'FETCH_ALL_SURVEYS_BEGIN';
export const FETCH_ALL_SURVEYS_SUCCESS = 'FETCH_ALL_SURVEYS_SUCCESS';
export const FETCH_ALL_SURVEYS_FAILURE = 'FETCH_ALL_SURVEYS_FAILURE';
export const UPDATE_SURVEY_ITEM = 'UPDATE_SURVEY_ITEM';
export const ADD_SURVEY_PAGE = 'ADD_SURVEY_PAGE';
export const ADD_PAGE_QUESTION = 'ADD_PAGE_QUESTION';

export const PAGE_ITEM_UPDATE = 'PAGE_ITEM_UPDATE';
export const ADD_PAGE_SURVEY_HEADING = 'ADD_PAGE_SURVEY_HEADING';
export const SURVEY_PAGE_DELETE = 'SURVEY_PAGE_DELETE';

export const SAVE_SURVEY_BEGIN = 'SAVE_SURVEY_BEGIN';
export const SAVE_SURVEY_SUCCESS = 'SAVE_SURVEY_SUCCESS';
export const SAVE_SURVEY_FAILURE = 'SAVE_SURVEY_FAILURE';
export const COPY_SURVEY_SUCCESS = 'COPY_SURVEY_SUCCESS';
export const CREATE_OR_UPDATE_SURVEY_DISPLAY_CONDITIONS = 'CREATE_OR_UPDATE_SURVEY_DISPLAY_CONDITIONS';
export const UPDATE_PAGE_DISPLAY_CONDITIONS_ON_SHOPS = 'UPDATE_PAGE_DISPLAY_CONDITIONS_ON_SHOPS'
export const DELETE_SURVEY_DISPLAY_CONDITIONS = 'DELETE_SURVEY_DISPLAY_CONDITIONS';

export const FETCH_SURVEYS_RESET = "FETCH_SURVEYS_RESET";
export const SWAP_QUESTION_ITEMS = 'SWAP_QUESTION_ITEMS';
