import { parseFeedback } from '../../utils'

export const FETCH_FEEDBACKS_BEGIN   = 'FETCH_FEEDBACKS_BEGIN'
export const FETCH_FEEDBACKS_SUCCESS = 'FETCH_FEEDBACKS_SUCCESS'
export const FETCH_FEEDBACKS_FAILURE = 'FETCH_FEEDBACKS_FAILURE'
export const ARCHIVE_FEEDBACK = 'ARCHIVE_FEEDBACK'
export const UPDATE_FEEDBACK_COMMENT = 'UPDATE_FEEDBACK_COMMENT'
export const UPDATE_FEEDBACK_USER = 'UPDATE_FEEDBACK_USER'
export const SET_ACTIVE_FEEDBACK = 'SET_ACTIVE_FEEDBACK'
export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL'
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL'
export const SET_TAB_INDEX = 'SET_TAB_INDEX'
export const FETCH_FEEDBACKS_RESET = 'FETCH_FEEDBACKS_RESET'
export const FETCH_FEEDBACK_LIKES_BEGIN = 'FETCH_FEEDBACK_LIKES_BEGIN'
export const FETCH_FEEDBACK_LIKES = 'FETCH_FEEDBACK_LIKES'
export const PIN_FEEDBACK = 'PIN_FEEDBACK'
export const LIKE_FEEDBACK = 'LIKE_FEEDBACK'
export const LIKE_FEEDBACK_FAILURE = 'LIKE_FEEDBACK_FAILURE'
export const DISLIKE_FEEDBACK = 'DISLIKE_FEEDBACK'
export const LIKE_FEEDBACK_BEGIN = 'LIKE_FEEDBACK_BEGIN'
export const EXPORT_FEEDBACK_BEGIN = 'EXPORT_FEEDBACK_BEGIN'
export const EXPORT_FEEDBACK_SUCCESS = 'EXPORT_FEEDBACK_SUCCESS'
export const EXPORT_FEEDBACK_FAILURE = 'EXPORT_FEEDBACK_FAILURE'
export const SET_FEEDBACK_SORT_OPTIONS = 'SET_FEEDBACK_SORT_OPTIONS'
export const FETCH_FEEDBACK_QUESTIONS_BEGIN = 'FETCH_FEEDBACK_QUESTIONS_BEGIN'
export const FETCH_FEEDBACK_QUESTIONS = 'FETCH_FEEDBACK_QUESTIONS'
export const SET_FEEDBACK_QUESTION = 'SET_FEEDBACK_QUESTION'
export const RESET_FEEDBACK_QUESTIONS = 'RESET_FEEDBACK_QUESTIONS'
export const SET_QUESTION_FILTER = 'SET_QUESTION_FILTER'
export const SET_WITHOUT_EMPTY_FLG = 'SET_WITHOUT_EMPTY_FLG'
export const FETCH_SCORE_QUESTIONS = 'FETCH_SCORE_QUESTIONS'
export const SET_SCORE_QUESTION = 'SET_SCORE_QUESTION'
export const FETCH_SCORE_QUESTIONS_BEGIN = 'FETCH_SCORE_QUESTIONS_BEGIN'

export const fetchFeedbacksBegin = () => ({
  type: FETCH_FEEDBACKS_BEGIN
})

export const fetchFeedbacksSuccess = ({data, included}, paginationParams) => ({
  type: FETCH_FEEDBACKS_SUCCESS,
  payload: {
    feedbacks: data.map(element => parseFeedback({...element}, included)),
    pagination: paginationParams,
  }
})

export const fetchFeedbacksFailure = error => ({
  type: FETCH_FEEDBACKS_FAILURE,
  payload: {
    error
  }
})

export const setActiveFeedback = feedback => ({
  type: SET_ACTIVE_FEEDBACK,
  payload: {
    feedback
  }
})

export const updateFeedbackComment = (feedbackId, delta) => ({
  type: UPDATE_FEEDBACK_COMMENT,
  payload: {
    feedbackId,
    delta,
  }
})

export const archiveFeedback = id => ({
  type: ARCHIVE_FEEDBACK,
  payload: {
    id
  }
})

export const updateFeedbackUser = (feedbackId, user) => ({
  type: UPDATE_FEEDBACK_USER,
  payload: {
    id: feedbackId,
    user,
  }
});

export const fetchFeedbackLikesBegin = () => ({
  type: FETCH_FEEDBACK_LIKES_BEGIN
})

export const likeFeedbackFailure = error => ({
  type: LIKE_FEEDBACK_FAILURE,
  payload: {
    error
  }
})

export const fetchFeedbackLikes = ({ data: { id }, included }) => ({
  type: FETCH_FEEDBACK_LIKES,
  payload: {
    feedback_id: id,
    users: included.filter(i => i.type === 'user').map(user => user.attributes.name)
  }
})

export const pinFeedback = ({data, included}, type) => ({
  type,
  payload: {
    feedback: parseFeedback({...data}, included),
  }
})

export const likeFeedback = (feedback_id, feedback_like_id, type) => ({
  type,
  payload: { feedback_id, feedback_like_id }
})

export const dislikeFeedback = (feedback_id, type) => ({
  type,
  payload: { feedback_id, feedback_like_id: null }
})

export const setQuestionFilters = (filters={}) => ({
  type: SET_QUESTION_FILTER,
  payload: { filters }
})