import React from 'react';
import clsx from "clsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Pagination from 'components/Pagination';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { PAGINATION_PER_PAGE, USER_LIST_COLUMN_NAMES, GetAuthority } from "./constants";
import { UserListStyles } from "./styles";
import { useGAEffect } from '../../ga';


const useStyles = makeStyles(UserListStyles);

const propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object,
  page: PropTypes.number,
  openModal: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  ga: PropTypes.object.isRequired,
};

const UserList = ({
  items,
  loading,
  pagination,
  page,
  openModal,
  setPage,
  ga,
}) => {
  const classes = useStyles();

  useGAEffect(ga, null, "スタッフ管理")

  const handleClick = (v) => {
    setPage(v)
  };

  if (loading) {
    return (
      <Paper className={classes.root}>
        <div className={classes.loading}>
          <CircularProgress disableShrink />
        </div>
      </Paper>
    );
  }

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.headerTable}>
          <TableRow className={classes.rows}>
            {USER_LIST_COLUMN_NAMES.map(({ label, code }, i) =>
              <TableCell
                key={`user-column-${i}`}
                align={"left"}
                className={classes[code]}
              >
                {label}
              </TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {(items || []).map(user => (
            <TableRow
              key={`user-row-${user.id}`}
              onClick={() => openModal(user)}
              className={clsx(classes.bg_color_white, classes.rows)}
            >
              <TableCell
                scope="row"
                className={clsx(
                  user.discarded_at ? classes.bodyClose : classes.bodyCheck,
                  classes.is_active
                )}
              >
                {!!user.discarded_at ?
                  <ClearIcon className={classes.color_white} /> :
                  <DoneIcon className={classes.color_white} />
                }
              </TableCell>
              <TableCell component="th" scope="row" className={classes.ucode} title={user.ucode}>{user.ucode}</TableCell>
              <TableCell align="left" className={classes.login_id} title={user.login_id}>{user.login_id}</TableCell>
              <TableCell align="left" className={classes.name} title={user.name}>{user.name}</TableCell>
              <TableCell align="left" className={classes.shop_names} title={user.shop_names}>{user.shop_names}</TableCell>
              <TableCell align="left" className={classes.authority} title={GetAuthority(user.authority)}>{GetAuthority(user.authority)}</TableCell>
              <TableCell align="left" className={classes.viewing_target_names} title={user.viewing_target_names}>{user.viewing_target_names}</TableCell>
              <TableCell align="left" className={classes.is_personal_info_viewable}>
                <div className={classes.flex}>
                  {user.is_personal_info_exportable === true && <span className={clsx(classes.btnCheck, classes.bg_color_green)}>FBCSV</span>}
                  {user.is_personal_info_viewable === true && <span className={clsx(classes.btnCheck, classes.bg_color_red)}>個人情報</span>}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      <Pagination
        activePage={page}
        itemsCountPerPage={+pagination.perPage}
        pageRangeDisplayed={pagination.offset}
        totalItemsCount={+pagination.total}
        offset={pagination.offset}
        onChange={handleClick}
      /> 
    </div>
  );
}

UserList.propTypes = propTypes;

export default connect(
  ({ users: { items, loading, pagination }, ga }) => ({
    items,
    loading,
    pagination: !!pagination
      ? pagination
      : {
        offset: 0,
        total: PAGINATION_PER_PAGE,
        perPage: PAGINATION_PER_PAGE,
      },
    ga
  }),
)(UserList);
