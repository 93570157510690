export const themedStyles = theme => ({
  formSection: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
    display: 'inline-block',
    '& label.anKetRadioButton:nth-child(1)': {
      marginLeft: 0,
    },
    '& label.anKetRadioButton:nth-child(2)': {
      marginLeft: 10,
    },
  },
  formLabel: {
    color: '#1A0707',
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 9,
    display: 'flex',
  },
  itemContainer: {
    width: 770,
    padding: "10px 30px",
    borderLeft: "4px solid #B7B7B7",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    '& .Mui-focused': {
      border: '2px solid #EC681A !important',
    },
    '& p.Mui-focused': {
      border: 'none !important',
    }
  },
  activeItem: {
    borderLeft: "4px solid #EC681A",
  },
  textField1: {
    width: "100%",
    padding: "2px 20px",
    paddingRight: '35px',
    border: "1px solid #B7B7B7",
    minHeight: 38,
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  textFieldSmall: {
    width: 98,
    marginLeft: 10,
  },
  itemDivider: {
    marginTop: 20,
    height: 1,
    background: "#B7B7B7",
  },
  switchBoxContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: 41,
    "& > div": {
      marginTop: 10,
      "& >label": {
        minWidth: "100px"
      }
    },
    "& > div:nth-child(1) > label:nth-child(1)": {
      marginLeft: 0
    }
  },
  deleteButton: {
    width: 36,
    height: 36,
    color: "#F02544",
    border: "1px solid #F02544",
    borderRadius: "5px",
    position: "absolute",
    right: 0,
    top: -1,
  },
  deleteIcon: {
    fontSize: "13px",
    fill: "#F02544",
  },
  dropdown: {
    width: 265
  },
  visibleActiveIcon: {
    color: '#EC681A'
  },
  borderRadius5: {
    borderRadius: 5
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 274
  },
  formRowLabel: {
    color: '#1A0707',
    fontWeight: 'bold',
    fontSize: 12,
    minWidth: 30,
  },
  formAction: {
    padding: '10px 0'
  },
  uploadZone: {
    width: 122,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mt_10: {
    marginTop: 20,
  },
  ml_20: {
    marginLeft: 20,
  },
  iconDelete: {
    width: 36,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    border: '1px solid #F02544',
    "& img": {
      width: 16,
      height: 16,
      cursor: 'pointer'
    }
  },
  selectOptionIcon: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  slectOptionItem: {
    padding: 7,
    borderBottom: "1px solid #ccc",
    display: 'flex',
    alignItems: 'center'
  },
  inputFloatRight: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,

    '& label': {
      display: 'flex',
      alignItems: 'center',
      margin: '0 10px 0 25px'
    },

    '& input': {
      padding: '0 15px !important',
      height: 36,
      width: 98,
      border: '1px solid #b7b7b7 !important',

      '&:-ms-input-placeholder': {
        color: '#9f9797 !important',
      },

      '&::-ms-clear': {
        display: 'none',
      },

      '&:hover': {
        border: '1px solid #1A0707 !important'
      },

      '&:focus': {
        border: '2px solid #EC681A !important'
      },
    },

    '& input ~ fieldset': {
      display: 'none'
    },

    '& .Mui-focused': {
      border: 'none !important'
    },
    
    '& div': {
      height: 38,
      width: 98,
      padding: 0,
      border: 'none !important',
      position: 'relative',
      '& p': {
        margin: 0,
        top: '125%',
        position: 'absolute',
      },
    }
  },

  paddingBottom60: {
    paddingBottom: 60,
  },

  messageErrorInput: {
    color: 'red',
    marginLeft: 5
  }
});
