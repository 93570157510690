import React from 'react'
import styled from 'styled-components'
import { Popover, useMediaQuery, useTheme } from '@material-ui/core'
import { connect } from 'react-redux'
import { fetchScoreQuestions } from './redux/thunk'
import { SET_SCORE_QUESTION } from './redux/actions/feedback'
import ElementIcon from '../../../common/icon/ElementIcon'
import { Storage } from '../../../utils'
import { replaceParams } from 'modules/utils'

const Container = styled.div`
  padding: 5px 0;
  border-radius: 3px;
  border: 1px solid lightgray;
  color: grey;
  max-width: 450px;
  overflow: auto;

  div {
    padding: 5px 10px;
  }

  max-height: ${props => (props.height ? props.height + 'px' : '300px')};
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
`

const Link = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-width: 85%;
  cursor: ${props => (props.active ? 'pointer' : 'unset')};

  i {
    color: #ec681a;
    font-weight: bold;
    margin-left: 5px;
  }

  div {
    text-decoration: ${props => (props.active ? 'underline' : 'unset')};
  }
`

const Question = styled.div`
  cursor: pointer;
  color: ${props => (props.selected ? 'white' : '#1A0707')};
  background-color: ${props => (props.selected ? 'rgb(38, 128, 235)' : 'unset')};
  white-space: pre-wrap;

  :hover {
    color: white;
    background-color: rgb(38, 128, 235);
  }
`

const Label = styled.div`
  display: inline-block;
  overflow-x: hidden;
`

const Voc = styled.span`
  display: inline-block;
  padding: 1px 10px;
  margin-right: 20px;
  border-radius: 2px;
  border: 1px solid black;
`

export const QuestionPopover = ({
  loading,
  anchorEl,
  height,
  onClose,
  selected = {},
  items = [],
  onSelect,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
}) => {
  const open = Boolean(anchorEl)
  const id = open ? 'score-questions-popover' : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={{ vertical: height < 150 ? 'bottom' : 'top', horizontal: 'left' }}>
      <Container height={Math.max(height, 300)}>
        {loading ? (
          <div>Loading...</div>
        ) : items.length === 0 ? (
          <div>No options</div>
        ) : (
          items.map((item, index) => (
            <Question key={index} selected={selected.id === item.id} onClick={() => onSelect(item)}>
              {replaceParams(item.label)}
            </Question>
          ))
        )}
      </Container>
    </Popover>
  )
}

const ScoreQuestions = ({ survey_id, scoreQuestions = {}, fetchScoreQuestions, setScoreQuestion }) => {
  const { all = [], loading } = scoreQuestions
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [height, setHeight] = React.useState(250)
  const openMorePopover = event => setAnchorEl(event.currentTarget)
  const closeMorePopover = () => setAnchorEl(null)
  const selected = Storage.getScoreQuestion()
  const allAndDefaultOne = [{ label: '愛用度', id: undefined }, ...all]
  const tablet = useMediaQuery(useTheme().breakpoints.down('sm'))

  const handleClick = e => {
    const element = document.getElementById('score-question-list-popover')
    if (element && element.getBoundingClientRect()) {
      setHeight(window.innerHeight - element.getBoundingClientRect().y - 30)
    }

    if (survey_id) {
      openMorePopover(e)
      fetchScoreQuestions(survey_id)
    }
  }

  const selectQuestion = question => {
    question && setScoreQuestion(question)
    closeMorePopover()
  }

  return (
    <div id="score-question-list-popover">
      {tablet && <Voc>NPS</Voc>}
      <Link active={survey_id} onClick={handleClick}>
        <Label>{replaceParams(selected.label) || '愛用度'}</Label>
        {survey_id && <ElementIcon name="arrow-down" />}
      </Link>
      <QuestionPopover
        loading={loading}
        items={allAndDefaultOne}
        selected={selected}
        height={height}
        anchorEl={anchorEl}
        onSelect={selectQuestion}
        onClose={closeMorePopover}
      />
    </div>
  )
}

export default connect(
  ({ global: { surveyData }, feedbacks: { scoreQuestions } }) => ({
    survey_id: surveyData.value,
    scoreQuestions,
  }),
  dispatch => ({
    fetchScoreQuestions: survey_id => dispatch(fetchScoreQuestions(survey_id)),
    setScoreQuestion: scoreQuestion => {
      Storage.setScoreQuestion(scoreQuestion)
      // API対応後に以下修正予定
      dispatch({ type: SET_SCORE_QUESTION, payload: { id: scoreQuestion.id } })
    },
  })
)(ScoreQuestions)
