import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { thunkSaveProject } from '../redux/thunk';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Button, 
  TextField, Paper, 
  CircularProgress,
  // 2023/04時点では非対応になった為コメントアウト
  // FormControl,
  // RadioGroup,
  // FormControlLabel,
  // Radio
 } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { themedStyles } from "./styles";
import { format } from 'date-fns';
import {SwipeableModal, UnSavedWarningDialog} from "../../../../components";
import SaveBtn from "../../../../components/SaveBtn";
import ConfirmationDialog from 'common/ConfirmationDialog';
import SwitchBtn from "../../../../components/SwitchBtn"

const useStyles = makeStyles(themedStyles);

const propTypes = {
  item: PropTypes.object.isRequired,
  submitErrors: PropTypes.object,
  itemSubmitting: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  deactivate: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  userAuthority: PropTypes.string,
};

const goodvocCheckboxes = [
  { id: 1, label: '8点以下' },
  { id: 2, label: '9点or10点' },
  { id: 3, label: 'ピン留め' },
]

const StatefulProjectForm = ({
  item,
  submitErrors,
  itemSubmitting,
  itemSubmitSuccess,
  save,
  deactivate,
  modalOpen,
  closeModal,
  userAuthority,
}) => {
  const [errors, setErrors] = useState({});
  const dirtyFields = useRef([])
  const [dirty, setDirty] = useState(false)
  const [open, setOpen] = useState(modalOpen)
  const classes = useStyles();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [goodvocs, setGoodvocs] = useState([]);
  const isSuperAdmin = userAuthority === "super_admin";

  // 商品スコア開発評価種別 "0":11段階、"1":5段階
  // 2023/04時点では非対応になった為コメントアウト
  // const [evaluationType, setEvaluationType] = useState(item.evaluation_type)
  
  // 商品スコア開発算出方法種別 "0":NPSスコア、"1":TOP1率、"2":TOP2率
  // 2023/04時点では非対応になった為コメントアウト
  // const [calculationType, setCalculationType] = useState(item.calculation_type)

  const date = !!item.created_at ? new Date(item.created_at) : new Date();

  useEffect(() => {
    setErrors(Boolean(submitErrors) ? submitErrors.detail : {});
  }, [submitErrors])

  useEffect(() => {
    setOpen(modalOpen)
    setDirty(false)
    setErrors({})
    setGoodvocs(item.goodvocs || [])

    // モーダルが開いた時に評価種別、スコア算出をレンダリング
    // 2023/04時点では非対応になった為コメントアウト
    // if(modalOpen){
    //   setEvaluationType(item.evaluation_type)
    //   setCalculationType(item.calculation_type)
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  useEffect(() => {
    if (itemSubmitSuccess){
      setDirty(null)
      dirtyFields.current = []
    }
  }, [itemSubmitSuccess])

  const generateDoc = (e) => {
    const { name } = e.target;
    const doc = { name: name.value };

    if (!!e.target.description) doc.description = e.target.description.value;
    if (!!item.id) doc.id = item.id;
    doc.goodvoc_ids = goodvocs

    // 評価種別、スコア算出を追加
    // 2023/04時点では非対応になった為コメントアウト
    // doc={...doc,evaluation_type:evaluationType, calculation_type:calculationType}

    return doc;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const doc = generateDoc(e);
    save(doc);
  };

  const onDeactivate = () => {
    setDeactivateModal(true)
  };

  const onChange = e => {
    const { name, value } = e.target || {}
    handleDirt(name, (item[name] || '') !== value)
  }

  const onGoodvocChanged = (id, checked) => {
    let newGoodvocs = []
    if (goodvocs.includes(id)) {
      if (!checked)
        newGoodvocs = goodvocs.filter(i => i !== id)
    } else if (checked) {
        newGoodvocs = goodvocs.concat([id])
    }
    let isDirty = (item.goodvocs || []).length !== newGoodvocs.length
    if (!isDirty) {
      const common = (item.goodvocs || []).filter(i => newGoodvocs.includes(i))
      isDirty = common.length !== (item.goodvocs || []).length
    }
    setGoodvocs(newGoodvocs)
    handleDirt("goodvocs", isDirty)
  }

  const handleDirt = (name, isDirty) => {
    if (isDirty)
      dirtyFields.current = dirtyFields.current.concat([name])
    else
      dirtyFields.current = dirtyFields.current.filter(field => field !== name)
    setDirty(dirtyFields.current.length > 0)
  }

  const closeConfirmationDialog = () => setIsConfirmationOpen(false)

  const onModalClose = () => {
    if (dirty) {
      setIsConfirmationOpen(true)
    } else {
      closeModal && closeModal()
    }
  }

  const onCloseDeactivateModal = () => {
    setDeactivateModal(false)
  }

  const onConfirmDeactivateModal = () => {
    deactivate(item.id)
    onCloseDeactivateModal()
    closeModal && closeModal()
  }
  
  // 2023/04時点では非対応になった為コメントアウト
  // const evaluationTypeChange =(e)=>{
  //   setEvaluationType(e.target.value)
  //   // 評価種別を変えた場合、スコア算出に初期値を設定する
  //   if(e.target.value=="0"){
  //     // 11段階の初期値はスコアとする
  //     setCalculationType("0")
  //   }else{
  //     // 5段階評価の初期値はTOP1率とする
  //     setCalculationType("1")
  //   }
  // }
  return (
    <SwipeableModal closeModal={onModalClose} modalOpened={open}>
      <div className={classes.box}>
        <form onSubmit={handleSubmit}>
          <SaveBtn type="submit" dirty={dirty} itemSubmitting={dirty === null}/>
          {isSuperAdmin && !!!item.discarded_at && !!item.id &&
            <Button className={classes.buttonArchive} onClick={onDeactivate}>
              <i className="el-icon-receiving" aria-hidden="true"/>アーカイブ
            </Button>
          }

          <Button
            variant="outlined"
            endIcon={<CloseIcon />}
            onClick={onModalClose}
            className={classes.buttonClose}
          >
            閉じる
          </Button>
          <div className={classes.headerFrom}>
            <TextField
              fullWidth={true}
              required={true}
              name={"name"}
              placeholder={"プロジェクト名"}
              defaultValue={item.name || ''}
              error={!!errors.name}
              helperText={!!errors.name && `プロジェクト名${errors.name[0]}`}
              className={classes.editName}
              onChange={onChange}
            />
            <i className="fa fa-pencil-square-o" aria-hidden="true"/>
          </div>
          <div className={classes.blockTime}>
            <p>作成日</p>
            <p>{format(date, "yyyy/MM/dd")}</p>
            <p>{format(date, "HH:mm")}</p>
          </div>
          <div className={classes.blockCountFeedback}>
            <div className="anket">
              <p>アンケート数</p>
              <p>{item.survey_count || 0}</p>
            </div>
            <div className="divide" />
            <div className="feedback">
              <p>フィードバック</p>
              <p>{item.feedback_count || 0}</p>
            </div>
          </div>
          <div className={classes.inputGroup}>
            <p>プロジェクトの概要</p>
            <TextField
              className={classes.cssTextField}
              name={"description"}
              placeholder={"プロジェクトの概要と説明を入力してください。"}
              variant={"outlined"}
              multiline
              rows="5"
              rowsMax="10"
              margin="normal"
              defaultValue={item.description || ''}
              error={!!errors.description}
              helperText={!!errors.description && `プロジェクトの概要${errors.description[0]}`}
              onChange={onChange}
            />
          </div>
          <div style={{ marginBottom: 20 }}>VOC</div>
          <div>
            {goodvocCheckboxes.map(({ id, label }) => (
              <SwitchBtn
                key={`checklist-${id}-option-${id}`}
                name={`goodvocs-${id}`}
                checked={goodvocs.includes(id)}
                label={label}
                onChange={e => onGoodvocChanged(id, e.target.checked)}
              />
            ))}
          </div>
          {/* 商品スコア-評価種別 、コードは他項目と合わせる*/}
          {/* 2023/04時点では非対応になった為コメントアウト */}

          {/* <div className={classes.headerGood}>
            <p>評価種別</p>
          </div> */}
          {/* <FormControl>
    
            <RadioGroup
                name="evaluation_type"
                value={evaluationType}
                className={classes.anketGroupRadio}
                row
                onChange={e=>{                  
                  evaluationTypeChange(e)
                }}
              >
              <FormControlLabel
                      value="0"
                      label="11段階"
                      control={<Radio />}
                      className={classes.anketRadioButton}
                    />
            
              <FormControlLabel
                      value="1"
                      label="5段階"
                      control={<Radio />}
                      className={classes.anketRadioButton}
                    />

          </RadioGroup>
        </FormControl> */}
        {/* <p>{evaluationType}</p> */}
        {/* 商品スコア-算出方法種別 、コードは他項目と合わせる*/}

        {/* <div className={classes.headerGood}>
          <p>スコア算出</p>
        </div> */}
          {/* <FormControl>
    
            <RadioGroup
                name="calculation_type"
                value={calculationType}
                className={classes.anketGroupRadio}
                row
                onChange={e=>setCalculationType(e.target.value)}
              >
              {evaluationType==="0"?
              // 11段階の表示
              <FormControlLabel
                      value="0"
                      label="スコア"
                      control={<Radio />}
                      className={classes.anketRadioButton}
                    />
                    
                  :
              // 5段階の表示
              <>
              <FormControlLabel
                      value="1"
                      label="TOP1率"
                      control={<Radio />}
                      className={classes.anketRadioButton}
                    />
              <FormControlLabel
                      value="2"
                      label="TOP2率"
                      control={<Radio />}
                      className={classes.anketRadioButton}
                    />
                    </>
                  }
          </RadioGroup>
        </FormControl> */}
        {/* <p>{calculationType}</p> */}
        </form>
        {itemSubmitting && (
          <Paper
            className={classes.loadingRoot}
            style={{ position: "absolute", top: "0px" }}
          >
            <div className={classes.loading}>
              <CircularProgress disableShrink />
            </div>
          </Paper>
        )}
      </div>
      <UnSavedWarningDialog
        open={isConfirmationOpen}
        onConfirm={() => {
          closeConfirmationDialog()
          closeModal && closeModal()
        }}
        onCancel={() => closeConfirmationDialog()}
      />

      <ConfirmationDialog
        show={deactivateModal}
        onCancel={onCloseDeactivateModal}
        title='アーカイブ確認'
        text='アーカイブしてもよろしいでしょうか？'
        onConfirm={onConfirmDeactivateModal}
      />
    </SwipeableModal>
  );
}

StatefulProjectForm.propTypes = propTypes;

export default connect(
  ({ projects: { item, errors, itemSubmitting, itemSubmitSuccess }, global: { userData } }) => ({
    item: item || {},
    submitErrors: errors,
    itemSubmitting,
    itemSubmitSuccess,
    userAuthority: userData.authority
  }),
  (dispatch) => ({
    save: (data) => dispatch(thunkSaveProject(data)),
  })
)(StatefulProjectForm);
