import React from 'react'

const RankItem = ({ children, index = 0, rank = 0, classes = {} }) => {
  return (
    <div
      className={classes.rankItemContainer}
      style={{ backgroundColor: index % 2 === 1 ? '#F7F7F7' : 'white' }}>
      <div className={classes.rankNumber}>{rank}</div>
      <div className={classes.rankTextWrapper}>{children}</div>
    </div>
  )
}

export const FlexColumnSeparator = () => <div style={{ flex: 1 }} />

// Group/Shop rank, first column, NPS
export const NpsItem = ({ index, rank, score, name, classes = {} }) => {
  return (
    <RankItem index={index} rank={rank} classes={classes}>
      <div className={classes.rankText}>{name}</div>
      <div className={classes.rankNpsScore}>{score}</div>
    </RankItem>
  )
}

// Group/Shop rank, second column, Count
export const FeedbackItem = ({ index, rank, count, name, classes = {} }) => {
  return (
    <RankItem index={index} rank={rank} classes={classes}>
      <div className={classes.rankText}>{name}</div>
      <div className={classes.feedbackCountWrapper}>
        <span className={classes.feedbackCount}>{count}</span>
        <span className={classes.rankTextSmall}>件</span>
      </div>
    </RankItem>
  )
}

// User rank, first column, NPS
export const UserNpsItem = ({ index, rank, name, score, shops_name, classes = {} }) => {
  return (
    <RankItem index={index} rank={rank} classes={classes}>
      <div className={classes.shopNameInRank}>{shops_name}</div>
      <div className={classes.userNameInRank}>{name}</div>
      <div className={classes.rankNpsScore}>{score}</div>
    </RankItem>
  )
}

// User rank, second column, Count
export const UserFeedbackItem = ({ index, rank, count, name, shops_name, classes = {} }) => {
  return (
    <RankItem index={index} rank={rank} classes={classes}>
      <div className={classes.shopNameInRank}>{shops_name}</div>
      <div className={classes.userNameInRank}>{name}</div>
      <div className={classes.feedbackCountWrapper}>
        <span className={classes.feedbackCount}>{count}</span>
        <span className={classes.rankTextSmall}>件</span>
      </div>
    </RankItem>
  )
}
