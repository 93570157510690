import React from "react";
import { makeStyles } from "@material-ui/core";
import MenuButton from "common/Header/HeaderButton/MenuButton";
import imgLogo from '../../../assets/logo.svg';

const useStyles = makeStyles(theme => {
  return {
    root: {
      height: "52px",
      display: "flex",
      flexDirection: "row",
      padding: "0 10px",
      alignItems: "center",
      backgroundColor: theme.palette.common.white,
      justifyContent: props =>
        props.drawerWidth === 240 ? "flex-start" : "center"
    },
    logo:{
      height: 20,
      display: 'block',
    }
  }
});

const HeaderLogo = props => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <MenuButton />
      {props.drawerWidth === 240 && (
       <img src={imgLogo} alt="x-gauge" className={classes.logo}/>
      )}
    </div>
  );
};

export default HeaderLogo;
