import { API_URL } from '../../../../constants';
import { default as axios } from 'axios'; 
import {
  actionFetchAreasBegin,
  actionFetchAreasSuccess,
  actionFetchAreasFailure,
  actionCreateArea,
  actionUpdateArea,
  actionDeleteArea,
  actionChangeOrder,
  UPDATE_AREA_BEGIN,
  actionUpdateAreaFailure,
} from './actions';
import { handleError } from 'utils';


export const thunkFetchAreas = () => {
  return dispatch => {
    dispatch(actionFetchAreasBegin());

    return axios.get(`${API_URL}/areas`)
      .then(res => {
        const { data } = res;

        dispatch(actionFetchAreasSuccess(data));

        return data;
      })
      .catch(handleError(dispatch, actionFetchAreasFailure));
  };
};

export const thunkSaveArea = ({ id, parent_id, name, shop_ids, display_order, parent_name }) => dispatch => {
  let area = { parent_id, name, display_order };
  if (!!shop_ids) area = { ...area, shop_ids };
  dispatch({ type: UPDATE_AREA_BEGIN })
  
  if (!!id) {
    // update
    axios
      .patch(`${API_URL}/areas/${id}`, { area })
      .then((response) => {
        const { data, included } = response.data;
        return dispatch(actionUpdateArea({ parentId: parent_id, data, included }));
      })
      .catch(handleError(dispatch, actionUpdateAreaFailure));
  } else {
    // create new
    axios
      .post(`${API_URL}/areas`, { area })
      .then((response) => {
        const { data, included } = response.data;
        return dispatch(actionCreateArea({ parentId: parent_id, data, included, parentName: parent_name }));
      })
      .catch(handleError(dispatch, actionUpdateAreaFailure))
  }
};

export const thunkSaveOrder = ({ id, parent_id, member_ids, oldTree }) => dispatch => {
  // change order or change parents
  if (!!member_ids) {
    dispatch({ type: UPDATE_AREA_BEGIN });

    axios
    .patch(`${API_URL}/areas/${id}`, { area: { parent_id, member_ids } })
    .catch(err => {
      handleError(dispatch, actionUpdateAreaFailure)(err)
      dispatch(actionChangeOrder(oldTree));
    });
  }
}

export const thunkDeleteArea = (id) => {
  const path = `${API_URL}/areas/${id}`;

  return dispatch => {
    axios.delete(path)
      .then(() => {
        return dispatch(actionDeleteArea(id));
      })
      .catch(err => {
        try {
          alert(err.response.data.errors.base.join(", "));
        } catch(e) {
          alert(e);
        }
        console.log('patch update has failed');
        console.error(err);
      });
  }
};

export const thunkFetchAreasSync = () => {
  return axios.get(`${API_URL}/areas`)
  .then(({ data }) => data)
  .catch(error => console.error(error));
};

export const thunkFetchAreaChildren = area_id => {
  return axios.get(`${API_URL}/areas/${area_id}/descendants`)
  .then(({ data }) => data)
  .catch(error => console.error(error))
}
