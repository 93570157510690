export const themeGraphStyles = theme => ({
  npsValue: {
    fill: '#1A0707',
    font: '30px Noto Sans JP',
    fontWeight: 'bold',
  },
  npsValueWrapper: {
    position: 'absolute',
    top: '32%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    left: 0,
  },
  delta: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  small: {
    fill: '#b7b7b7',
    font: '10px Noto Sans JP',
  },
  radialWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  legendWrapper: {
    flex: 1,
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
    position: 'relative',
  },
  legendHeader: {
    marginTop: '15px',
    marginBottom: '15px',
    font: '14px Noto Sans JP, Hiragino Sans',
    color: '#ABABABDE',
    textAlign: 'left',
  },
  legendNumber: {
    marginTop: '2px',
    marginBottom: '0',
    font: '40px Noto Sans JP',
    fontWeight: 'bold',
    color: '#1A0707',
  },
  legendDelta: {
    marginTop: '0px',
    font: '12px Noto Sans JP',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  legendSubheader: {
    marginTop: '5px',
    marginBottom: '5px',
    font: '500 14px/16px Noto Sans JP, Hiragino Sans',
    color: '#ABABABDE',
    textAlign: 'left',
  },
  nps3Values: {
    position: 'absolute',
    left: '70%',
    fontSize: 14,
    fontWeight: 'bold',
  },
  npsDelta: {
    position: 'absolute',
    left: '90%',
    fontSize: 12,
  },
})
