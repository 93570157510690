export const themeAnalysisStyles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    overflowX: 'scroll',
    overflowY: 'hidden',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    verticalAlign: 'middle',
    '-ms-overflow-style': 'none',
    [theme.breakpoints.down('xs')]: {
      margin: "0 12px 40px 12px",
      padding: "20px 10px",
      "& .MuiTypography-body1":{
        overflowX: 'scroll'
      }
    }
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent"
    },
    "& .MuiTab-textColorInherit.Mui-selected":{
      color: '#EC681A',
      backgroundColor: '#FFFF',
      borderBottom: "4px solid #ec681a",
      "&:hover": {      
        borderBottom: "4px solid #939393",
        color: "#939393"
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: "20px 10px 0 10px",
      "& .MuiTypography-body1":{
        overflowX: 'scroll',
        "& .MuiBox-root": {
          padding: 0
        }
      },
      "&:hover": {      
        "& .MuiTab-textColorInherit.Mui-selected":{
          "&:hover": {      
            borderBottom: "4px solid #EC681A",
            color: "#EC681A",
          }
        }
      }
    }
  },
  tab:{
    borderBottom: '4px solid #B7B7B7',
    font: '500 12px/21px Noto Sans JP, Hiragino Sans',
    letterSpacing: "0.3px",
    "&:hover":{
      backgroundColor: '#FFF',
      font: "500 12px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#939393",
      opacity: 1
    },
    [theme.breakpoints.down('xs')]:{
      width: "calc(100%/3)",
      "& .MuiTab-textColorInherit.Mui-selected":{
        "&:hover": {      
          borderBottom: "4px solid #EC681A",
          color: "#EC681A",
        }
      }
    }
  },
  button: {
    position: "absolute",
    right: 40,
    top: 0,
    "& >button":{
      background: "#B7B7B7 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      opacity: 1,
      padding: "7px 15px",
      "&:hover":{
        background: "#EC681A 0% 0% no-repeat padding-box",
        color: "#fff"
      },
      "& >span":{
        font: "500 12px/21px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.3px",
        color: "#FFFFFF",
        opacity: 1
      }
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    }
  },
  blockExport:{
    float: "right", 
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  positionRelative:{
    marginTop: 20,
    position: "relative",
  },
  boxChart: {
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  }
});
export const themeAnalysisCorrelationsStyles = theme => ({
  chartGroup: {
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      width: 640
    }
  },
  textBold: {
    fontWeight: 'bold',
  },
  showTableForWeb: {
    [theme.breakpoints.up('mdf')]: {
      '& th, td': {
        minWidth: 'unset',
      }
    }
  },
  buttonGroup: {
    display: 'flex',
    width: 'auto',
    flexWrap: 'wrap',
    marginLeft: '100px',
    '& > button': {
      height: 32,
      width: 104,
      borderRadius: 4,
      border: 'none',
      color: '#fff',
      font: '500 10px/21px Noto Sans JP, Hiragino Sans',
      letterSpacing: '0.25px',
      background: '#EC681A 0% 0% no-repeat padding-box',
      margin: '0 10px 10px 0',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',

      '&:hover': {
        opacity: 0.6,
        cursor: 'pointer',
      }
    },

    '& > button.offButton': {
      background: '#B7B7B7 0% 0% no-repeat padding-box',
    }
  },
  visibleOverflow: {
    '& svg': {
      overflow: 'visible'
    }
  }
})
export const themeAnalysisNpsScoreStyles = theme => ({
  paperContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 12px"
    }    
  },
  paperHeader: {
    padding: theme.spacing(2),
    textAlign: 'left',
    font: "600 12px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.6px",
    color: "#1A0707",
    opacity: 1
  },
  paperContent: {
    display: "table-cell",
    padding: 10,
    height: 120,
    flexGrow: 1,
    width: "80vw",
    verticalAlign: "middle",
    "& >h4":{
      font: "Bold 32px/42px Noto Sans JP, Helvetica",
      letterSpacing: "0.8px",
      color: "#1A0707",
      opacity: 1
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      height: 72
    }
  },
  paperItem: {
    height: 176,
    [theme.breakpoints.down("xs")]: {
      height: 124
    }
  },
  paperChart: {
    height: 176
  }
})
export const themeAnalysisPeriodsStyles = theme => ({
  chartGroup: {
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      width: '100%',
      '& .data-table': {
        width: '100%',
        overflowX: 'scroll'
      }
    }
  },
  chartTag: {
    boxShadow: '2px 2px 5px #F7F7F7'
  },
  boldText: {
    font: "600 12px/18px 'Noto Sans JP', sans-serif",
    letterSpacing: '0.5px',
    color: '#1A0707',
  },
  showTableForWeb: {
    [theme.breakpoints.up('mdf')]: {
      '& th, td': {
        minWidth: 'unset',
      }
    }
  },
  valueText: {
    font: "500 8px/12px 'Noto Sans JP', sans-serif",
    letterSpacing: '0.4px',
    color: '#1A0707',
  },
})
export const themeAnalysisTimeSeriesStyles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40
    }
  },
  btn:{
    font: '10px/21px Noto Sans JP, Hiragino Sans',
    backgroundColor: '#B7B7B7',
    color: 'white',
    marginRight: 10,
    '&:hover': {
      backgroundColor: '#EC681A',
      color: 'white'
    },
    minWidth: '104px',
    [theme.breakpoints.down("xs")]: {
      minWidth: 126,
      marginRight: 10
    }
  },
  activeBtn: {
    backgroundColor: '#EC681A',
  },
  chartGroup: {
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      width: 650
    }
  },
  showTableForWeb: {
    [theme.breakpoints.up('mdf')]: {
      '& th, td': {
        minWidth: 'unset',
      }
    }
  },
})
export const themeDataTableStyles = theme => ({
  header: {
    background: "#F2EEEC",
    marginBottom: 0,
    borderBottom: "8px solid white",
  },
  cell: {
    minWidth: 90,
    borderRight: "2px solid white",
    font: "600 12px/18px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.6px",
    color: "#1A0707",
    opacity: 1
  },
  firstCell: {
    maxWidth: '120px !important',
    minWidth: '120px !important',
  },
  table: {
    minWidth: 650,
    '& tr': {
      display: 'flex',
      height: 'auto',
    },
    '& th, td': {
      minHeight: 42,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    "& td": {
      borderRight: "0px solid #ddd7d77a",
      font: "600 12px/18px 'Noto Sans JP', sans-serif",
      letterSpacing: "0.5px",
      color: "#1A0707",
      opacity: 1,
      borderBottom: 0,
      wordBreak: 'break-all'
    },
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    backgroundColor: "#fff"
  },
  headerTable: {
    background: "#F2EEEC 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000029",
    borderRadius: "5px 5px 0px 0px",
    "& th": {
      // borderRight: "2px solid #d2d1d0",
      // padding: "12px"
    }
  },
  headerTableSub:{
    background: "#rgba(239, 239, 239, 0.4) 0% 0% no-repeat padding-box !IMPORTANT",
    boxShadow: "0px 3px 5px #B7B7B733",
    border: "1px solid #F7F7F7"
  },
  Tbody:{
    border: "1px solid #F7F7F7",
    opacity: 1,
    "& >tr":{
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: "5px",
      "& >td:nth-child(even)":{
        background: "rgba(239, 239, 239, 0.4) 0% 0% no-repeat padding-box"
      },
    },
  },
  root: {
    width: '100%',
    boxShadow: "none"
  }
})
