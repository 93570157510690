import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { Storage } from '../../../utils';
import { DOMAIN_NAME } from '../../../constants';


const WithUser = (props) => {

  if (window.location.host !== DOMAIN_NAME) {
    window.location = `${window.location.protocol}//${DOMAIN_NAME}`;
  }

  if (Storage.isAuthenticated()) {
    return (
      <Fragment>
        { props.children }
      </Fragment>
    )
  } else {
    return <Redirect to='/users/sign_in' />;
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    authentication: state.authentication
  };
};

export default connect(mapStateToProps, {})(WithUser);
