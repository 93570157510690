import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Drawer } from '@material-ui/core'
import { CLOSE_FEEDBACK_MODAL } from './redux/actions/feedback';

const propTypes = {
  modalOpened: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.object,
}

const FeedbackModal = ({ modalOpened, closeModal, children }) => {

  React.useEffect(() => {
    return () => closeModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer anchor='right' 
      open={modalOpened} 
      onClose={closeModal}
    >
      {children}
    </Drawer>
  )
}

FeedbackModal.propTypes = propTypes;

export default connect(
  ({ feedbacks: { modalOpened } }) => ({
    modalOpened,
  }),
  dispatch => ({
    closeModal: () => dispatch({ type: CLOSE_FEEDBACK_MODAL }),
  })
)(FeedbackModal);
