export const FETCH_BEGIN = 'FETCH_BEGIN'
export const FETCH_FAILURE = 'FETCH_FAILURE'
export const FETCH_SURVEY_QUESTIONS = 'FETCH_SURVEY_QUESTIONS'
export const FETCH_QUESTION_AGGREGATION = 'FETCH_QUESTION_AGGREGATION'
export const FETCH_CROSS_AGGREGATION = 'FETCH_CROSS_AGGREGATION'
export const SET_QUESTION = 'SET_QUESTION'
export const ADD_OPTION = 'ADD_OPTION'
export const REMOVE_OPTION = 'REMOVE_OPTION'
export const SET_COLUMN_START_ID = 'SET_COLUMN_START_ID'
export const FETCH_AGGREGATION_RESET = 'FETCH_AGGREGATION_RESET'

export const fetchBegin = () => ({
  type: FETCH_BEGIN,
})

export const fetchSurveyQuestionsSuccess = ({ data }) => ({
  type: FETCH_SURVEY_QUESTIONS,
  payload: {
    data: {
      items: data.map(({ id, attributes }) => ({ id, body: attributes.body, question_type: attributes.question_type }))
    }
  }
})

export const fetchQuestionAggregationSuccess = ({ data }) => ({
  type: FETCH_QUESTION_AGGREGATION,
  payload: {
    data
  }
})

export const fetchCrossAggregationSuccess = ({ data }) => ({
  type: FETCH_CROSS_AGGREGATION,
  payload: {
    data
  }
})

export const fetchFailure = error => ({
  type: FETCH_FAILURE,
  payload: {
    error
  }
})

export const setQuestion = (tag, question) => ({
  type: SET_QUESTION,
  payload: {
    tag,
    question
  }
})

export const addOption = option => ({
  type: ADD_OPTION,
  payload: {
    option,
  }
})

export const removeOption = option => ({
  type: REMOVE_OPTION,
  payload: {
    option,
  }
})

export const setPageNumber = page => ({
  type: SET_COLUMN_START_ID,
  payload: {
    page
  }
})
