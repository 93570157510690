export const themeDashboardTableStyles = theme => ({
  tableContainer: {
    width: '100%',
    boxShadow: 'none',
  },
  tableBoxer: {
    height: 465,
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      background: '#B7B7B7 0% 0% no-repeat padding-box',
    },
    '&::-webkit-scrollbar-track': {
      background: '#F2EEEC 0% 0% no-repeat padding-box;',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#B7B7B7',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    [theme.breakpoints.down('md')]: {
      height: 695,
    },
    [theme.breakpoints.down('sm')]: {
      height: "100%",
      overflowX: 'visible',
      overflowY: 'visible',
    },
  },
  table: {
    width: '100%',
    '& td': {
      // borderRight: '1px solid #ddd7d77a',
      letterSpacing: '0.5px',
      color: '#1A0707',
      opacity: 1,
    },
    borderCollapse: 'separate',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      minWidth: 200,
      borderSpacing: 0,
      '& td': {
        position: 'relative',
        borderBottom: 'none',
      },
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      KhtmlUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
    },
  },
  headerTable: {
    background: '#F2EEEC 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 3px #00000029',
    borderRadius: '5px 5px 0px 0px',
  },
  cell: {
    borderRight: '2px solid white',
    font: 'Hiragino Sans CNS',
    letterSpacing: '0.6px',
    fontWeight: 600,
    fontSize: 12,
    color: '#1A0707',
    textAlign: 'center',
    wordBreak: 'break-all',
    '&.MuiTableCell-root': {
      padding: 10,
    },
    [theme.breakpoints.down('xs')]: {
      '&.MuiTableCell-root': {
        padding: '10px 0',
      }
    },
  },
  cell2: {
    background: '#F4F4F4',
    textAlign: 'right',
    font: 'Helvetica',
    borderRight: '0px solid transparent',
    margin: 'auto',
    paddingRight: 5,
    paddingLeft: 5,
    [theme.breakpoints.down('md')]: {
      padding: 10,
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  cell3: {
    borderRightColor: '#EC681B',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      '&.MuiTableCell-root': {
        padding: 5,
      }
    },
  },
  cell4: {
    [theme.breakpoints.down('xs')]: {
      '&.MuiTableCell-root': {
        padding: 5,
      }
    },
  },
  cellGraph: {
    padding: 0,
    height: '100%',
  },
  value: {
    fontSize: '14px',
    fontWeight: 'Bold',
    font: 'Helvetica',
    // marginLeft: 5,
    // marginRight: 5,
  },
  colWidth: {
    width: '25%',
    [theme.breakpoints.down('xs')]: {
      width: '38%',
    },
  },
  delta: {
    // marginLeft: 5,
    // marginRight: 5,
    fontSize: '10px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  Tbody: {
    boxShadow: '0px 3px 5px #B7B7B733',
    border: '1px solid #F7F7F7',
    opacity: 1,
    '& >#count': {
      background: '#EFEFEF 0% 0% no-repeat padding-box',
    },
    '& >#rate': {
      background: '#F7F7F7 0% 0% no-repeat padding-box',
    },
    [theme.breakpoints.down('xs')]: {
      border: 'none',
    },
  },
  tooltipWrapper: {
    border: '1px solid #C8C8C8',
    backgroundColor: 'white',
    padding: 10,
    zIndex: 999999,
  },
  tooltipText: {
    display: 'inline',
    font: 'ヒラギノ角ゴシック',
    fontWeight: 300,
    fontSize: 12,
  },
})
