import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactResizeDetector from 'react-resize-detector';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine
} from 'recharts';
import { getNpsColor } from './utils';
import { themeGraphTableStyles } from "./styles";
import { GRAY_COLOR, HEX_COLOR } from './constants';
import { replaceParams } from 'modules/utils';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  colNames: PropTypes.arrayOf(PropTypes.string),
  colKeys: PropTypes.arrayOf(PropTypes.string),
  activeGraph: PropTypes.string,
}

const useStyles = makeStyles(themeGraphTableStyles);

const GraphTableMobile = ({ data=[], colNames=[], colKeys=[], activeGraph, nps }) => {
  const [paperWidth, setPaperWidth] = useState(0)
  const { cell, minCell, cellGraph, table, root, width72, headerTable, Tbody, blockTableCell, floatLeft,
    rateText, spaceRow, spaceHeaderTable, headerTableLabel, cellGraphMobile, tdValue } = useStyles();
  const unit = '%'
  const max = 100
  const chartContainer = useRef(null)

  const onPaperResize = width => {
    setPaperWidth(width)
  }

  return (
    <Paper className={root}>
      <Table className={table} aria-label="caption table" >
        <colgroup>
          <col className={width72}/>
          <col style={{width: paperWidth/3}}/>
          <col style={{width: paperWidth/3}}/>
        </colgroup>
        <TableHead>
          <TableRow className={headerTable}>
            {colNames.map((col, idx) => (
              <TableCell key={idx} className={[cell, minCell].join(' ')}>{col}</TableCell>
            ))}
            <TableCell className={cell} align="center">
              <div className={floatLeft}>0{unit}</div>
            </TableCell>
            <TableCell className={[cell, minCell].join(' ')} align="center">
              <div className={blockTableCell}>
                <div>{max/2}{unit}</div>
                <div>{max}{unit}</div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) =>
            <React.Fragment key={index}>
              <TableRow className={spaceHeaderTable}>                
                <TableCell className={clsx(cell, spaceRow)} align="left" colSpan={3} />
              </TableRow>
              <TableRow className={headerTableLabel}>                
                <TableCell className={cell} align="left" colSpan={3}>
                  {replaceParams(item.label)}
                </TableCell>
              </TableRow>
              <TableRow className={Tbody} key={index}>
                {colKeys.map((colKey, idx) =>
                  <React.Fragment key={idx}>
                    <TableCell key={colKey} id={colKey} className={clsx(cell, width72, tdValue)} align="center">{item[colKey]}</TableCell>
                    <TableCell className={clsx(cellGraph, cellGraphMobile)} align="left" rowSpan={1} colSpan={2} id="graph-question" ref={chartContainer}>
                      <BarChart data={[item]} layout="vertical" barSize={24} barCategoryGap="20%"
                        height={18} width={chartContainer && chartContainer.current && chartContainer.current.offsetWidth}
                        margin={{ top: 0, right: 0, left: 0, bottom: 0 }} >
                        <XAxis type="number" domain={[0, max]} hide={true} />
                        <YAxis dataKey="name" type="category" hide={true} />
                        <ReferenceLine x={max/4} stroke={GRAY_COLOR} />
                        <ReferenceLine x={max/2} stroke={GRAY_COLOR} />
                        <ReferenceLine x={max*3/4} stroke={GRAY_COLOR} />
                        <Tooltip cursor={{ fill: 'none', fontSize: 11 }}/>
                        <Bar dataKey={activeGraph} name={colNames[colKeys.indexOf(activeGraph)]} unit={unit}>
                          {data.map((_, i) => <Cell key={i} height={18} fill={nps ? getNpsColor(index) : HEX_COLOR} />)}
                        </Bar>
                      </BarChart>
                      <span className={rateText}>{item.rate}%</span>
                    </TableCell>
                  </React.Fragment>
                )}
              </TableRow>
            </React.Fragment>
          )}
        </TableBody>
      </Table>
      <ReactResizeDetector handleWidth onResize={onPaperResize} />
    </Paper>
  )
}

GraphTableMobile.propTypes = propTypes;

export default GraphTableMobile;
