import React from "react";
import { Button, Modal } from '@material-ui/core';
import useStyles  from './styles';
import clsx from "clsx";

export default ({ show, title, text, onConfirm, onCancel }) => {
  const classes = useStyles()

  const onOk = (e) => {
    onConfirm && onConfirm(e)
  }

  const onClose = (e) => {
    onCancel && onCancel(e)
  }

  return (
    <Modal open={show} onClose={onClose}>
      <div className={classes.modalRoot}>
        { title && <div className={classes.modalTitle}>{title}</div> }
        { text && <div className={classes.modalNote}>{text} ?</div> }

        <div className={classes.modalButtonZone}>
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.modalSizeBtn)}
            onClick={onOk}
          >
            OK
          </Button>

          <Button
            variant="contained"
            onClick={onClose}
            className={clsx(classes.modalSizeBtn, classes.modalCancelBtn)}
          >
            キャンセル
          </Button>
        </div>
      </div>
    </Modal>
  )
}
