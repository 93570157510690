import React from "react";
import { FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import AntSwitch from "common/buttons/AntSwitch";

const useStyles = makeStyles(theme => ({
  switch: {
    paddingLeft: 10,
    width: "auto",
    minWidth: "150px",
    height: "42px",
    marginLeft: "20px",
    backgroundColor: "#EFEFEF",
    borderRadius: "5px",
    fontWeight: "bold",
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white"
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#EC681A"
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 5px"
    }
  },
  bgcolor_unset: {
    marginLeft: 0,
    paddingLeft: 0,
    backgroundColor: "transparent"
  }
}));

const SwitchBtn = props => {
  const classes = useStyles();
  const { label, backgroundColor, value, onChange, checked, ...others } = props;
  return (
    <FormControlLabel
      control={
        <AntSwitch
          checked={checked}
          value={value}
          onChange={onChange}
        />
      }
      label={label}
      className={classNames(
        classes.switch,
        backgroundColor ? "" : classes.bgcolor_unset
      )}
      {...others}
    />
  );
};
export default SwitchBtn;
