const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const asyncValidate = (values /*, dispatch */) => {
  return sleep(1000).then(() => {
    // simulate server latency
    if (['foo@foo.com', 'bar@bar.com'].includes(values.email)) {
      // eslint-disable-next-line no-throw-literal
      throw { email: 'Email already Exists' }
    }
  })
}

export const parseProjectWithGoodvocs = ({ id, attributes, relationships }) => {
  let goodvocs = []
  if (relationships && relationships.goodvocs && relationships.goodvocs.data) {
    goodvocs = relationships.goodvocs.data.map(({id}) => +id)
  }
  return {
    id,
    ...attributes,
    goodvocs
  }
}

export const parseProject = ({ id, attributes }) => ({
  id: String(id),
  ...attributes,
});

export const getProjectFromIncluded = ({ id, included }) => {
  let itemData = null;
  
  for (let item of included) {
    if (item.id === id) {
      itemData = item;
      break;
    }
  }

  return itemData === null ? {} : parseProject(itemData);
}

export const parseProjectForSelect = ({ id, attributes }) => {
  const { name } = attributes;

  return {
    id: String(id),
    name,
  }
};
