import { default as axios } from 'axios';
import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_ALL_USERS_BEGIN,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILURE,
  SAVE_USER_BEGIN,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
} from "./actions";
import { PAGINATION_PARAM_NO_PAGINATION } from '../../../constants';
import { USERS_API_PATH } from "../constants";
import { getRequestUrl, handleError, Storage } from "utils";
import { parseUser } from "../utils";

export const thunkFetchUsers = (
  page=1,
  searchParams,
  actions={
    actionBegin: () => ({ type: FETCH_USERS_BEGIN }),
    actionSuccess: (items, pagination) => ({ type: FETCH_USERS_SUCCESS, payload: { items, pagination } }),
    actionFailure: (error) => ({ type: FETCH_USERS_FAILURE, payload: { error } }),
  }) => {
  return dispatch => {
    dispatch(actions.actionBegin());

    return axios
      .get(getRequestUrl(USERS_API_PATH, page, !!searchParams ? searchParams : {}))
      .then(({ data, headers }) => {
        dispatch(
          actions.actionSuccess(data, {
            perPage: headers["page-items"],
            total: headers["total-count"],
            offset: (+headers["current-page"] - 1) * +headers["page-items"]
          })
        );
      })
      .catch(handleError(dispatch, actions.actionFailure));
  };
};

export const thunkFetchAllUsers = (searchParams) => thunkFetchUsers(
  PAGINATION_PARAM_NO_PAGINATION,
  searchParams,
  {
    actionBegin: () => ({ type: FETCH_ALL_USERS_BEGIN }),
    actionSuccess: (items) => ({ type: FETCH_ALL_USERS_SUCCESS, payload: { items } }),
    actionFailure: (error) => ({ type: FETCH_ALL_USERS_FAILURE, payload: { error } }),
  }
);

export const thunkSaveUser = ({ id, ...docFields }) => {
  const isUpdate = !!id;

  if (isUpdate) {
    return dispatch => {
      dispatch({ type: SAVE_USER_BEGIN });
      axios
        .patch(`${USERS_API_PATH}/${id}`, { user: { id, ...docFields } })
        .then(({ data }) => {
          dispatch({ type: SAVE_USER_SUCCESS, payload: { data } })

          if (+Storage.getUserData().id === +data.data.id)
            Storage.setUserData(parseUser(data.data, data.included), dispatch);

          const isStoredUserChanged = Storage.getSelectedUsers().find(({ value }) => +value === +data.data.id)
          if (isStoredUserChanged){
            const changed = Storage.getSelectedUsers().map(({ value }) => ({ value, label: data.data.attributes.name }));
            Storage.setSelectedUsers(changed, dispatch)
          }
        })
        .catch(handleError(dispatch, (errors) => ({
          type: SAVE_USER_FAILURE,
          payload: { errors }
        })));
    };
  } else {
    return dispatch => {
      dispatch({ type: SAVE_USER_BEGIN });
      axios
        .post(USERS_API_PATH, { user: docFields })
        .then(({ data }) => dispatch({ type: SAVE_USER_SUCCESS, payload: { data } }))
        .catch(handleError(dispatch, (errors) => ({
          type: SAVE_USER_FAILURE,
          payload: { errors }
        })));
    };
  }
};

export const thunkFetchUserSelf = () => {
  return dispatch => {
    const currentUser = Storage.getUserData()
    axios.get(USERS_API_PATH + `/${currentUser.id}?self=1`)
    .then(({ data= {} }) => {
      const user = parseUser(data.data, data.included)
      // Super admin can choose any company, so don't let selected company updated by one from API
      if (user.authority === 'super_admin') {
        delete user.company
      }
      Storage.setUserData(user, dispatch)
    })
  }
}