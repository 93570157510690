import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core'

const propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

const ButtonWithIcon = ({ text, variant, ...others }) => {
  return (
    <Button variant={variant || "outlined"} {...others}>
      {text}
    </Button>
  )
}

ButtonWithIcon.propTypes = propTypes;
export default ButtonWithIcon;
