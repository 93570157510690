import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Menu from "common/Menu";
import Login from "./modules/authentication/components/Login";
import PublicFeedback from "./modules/public/feedbacks";
import WithUser from "./modules/authentication/components/WithUser";
import Routes from "./Routes";
import "react-quill/dist/quill.snow.css";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import "assets/index.scss";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    width: '100vw',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      overflow: "auto", // スマートフォンの場合はoverflowをautoに
      height: 'auto',  // スマートフォンの場合はheightをautoに
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflow: "auto", // スマートフォンの場合はoverflowをautoに
      position: 'relative', // スマートフォンの場合はpositionをrelativeに
      height: 'auto',       // スマートフォンの場合はheightをautoに
    },
  }
}));

const AppComp = ({ authenticated,isProductSurvey }) => {
  const classes = useStyles();
  return (
    <Router>
      <div className={classes.root}>
        <Route exact path="/users/sign_in" component={Login} />
        <Route path="/s/:companyUrlText/:shortUrl/" exact component={PublicFeedback} />
        <Route path="/s/preview/:companyUrlText/:shortUrl/" exact component={PublicFeedback} />

        <Route exact path="/">
        {isProductSurvey?
          <Redirect to="/admin/product/feedbacks" />
          :<Redirect to="/admin/feedbacks" />
        }
        </Route>

        <Switch>
          <Route path="/admin">
            <WithUser>
              <div className={classes.wrapper}>
                <Menu />
                <Routes />
              </div>
            </WithUser>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default connect(
  ({ authentication : { authenticated }, global:{selectedCompany}}) => ({
    authenticated,
    isProductSurvey:selectedCompany.survey_type==="1",
  }),
  null,
)(AppComp);
