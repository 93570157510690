import dotenv from 'dotenv';

dotenv.config();

export const API_URL = process.env.REACT_APP_API_V1;
export const API_URL_V2 = process.env.REACT_APP_API_V2;
export const PAGINATION_PARAM_NO_PAGINATION = -1;
export const USE_DEBUG = process.env.REACT_APP_DEBUG;
export const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME;
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;
export const HEADER_BACKGROUND_COLOR = process.env.REACT_APP_HEADER_BACKGROUND_COLOR;
export const HEADER_TEXT_COLOR = process.env.REACT_APP_HEADER_TEXT_COLOR;
