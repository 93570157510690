import React, { useState, useEffect, useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  Button,
  TextField,
  Paper,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
// アップロードアイコン
import PublishIcon from '@material-ui/icons/Publish';
import Switch from "react-input-switch";
import { thunkSaveShop } from '../redux/thunk';
import { makeStyles } from '@material-ui/core/styles';
import MultipleSelect, { components } from 'react-select';
import { formHeaderButtonStyles, themedStyles, customStyles } from "./styles";
import CheckIcon from "@material-ui/icons/Check";
import { SwipeableModal, UnSavedWarningDialog } from "../../../../components";
import SaveBtn from "../../../../components/SaveBtn";

import { thunkFetchAllUsers } from 'modules/users/redux/thunk';


const useStyles = makeStyles(themedStyles);
const headerButtonsStyle = makeStyles(formHeaderButtonStyles);

const propTypes = {
  item: PropTypes.object.isRequired,
  submitErrors: PropTypes.object,
  allUsers: PropTypes.array,
  itemSubmitting: PropTypes.bool.isRequired,
  itemSubmitSuccess: PropTypes.bool,
  save: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
  fetchAllUsers: PropTypes.func.isRequired,
  allUsersLoaded: PropTypes.bool.isRequired,
};

const formatOptionLabel = ({ email, label }) => {
  return (
    <div className="listbox">
      <div className="rowTextAutocomplete">
        <div className="text">
          <p className="textLabel">{label}</p>
          <p>{email}</p>
        </div>
        <CheckIcon fontSize="small" />
      </div>
    </div>
  );
}

const ProductForm = ({
  item,
  submitErrors,
  allUsers,
  itemSubmitting,
  itemSubmitSuccess,
  save,
  closeModal,
  modalOpen,
  fetchAllUsers,
  allUsersLoaded,
}) => {
  const [errors, setErrors] = useState({});
  const dirtyFields = useRef([])
  const [value, setValue] = useState({})
  const [dirty, setDirty] = useState(false)
  const [open, setOpen] = useState(modalOpen)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [listEmployees, setListEmployees] = useState([]);
  const classes = useStyles();
  const { box, buttonToggle, buttonDel } = headerButtonsStyle();
  const [userOptions, setUserOptions] = useState([]);
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(null);


  const getUsers = (name, allOptions=null) => (allOptions || userOptions).filter(user => (item[name] || []).includes(user.value))

  useEffect(() => {
    if (fetchingUsers === false) {
      const container = document.getElementsByClassName('basic-single')[2]
      const searchBox = document.getElementsByClassName('search-box')[0]
      if (container && searchBox) {
      const defaultSearchBox = container.getElementsByTagName('input')[0]
      defaultSearchBox.setAttribute('disabled', true)
      
      const handleClick = ({target}) => {
        const clickInside = container.contains(target) || (target === searchBox)
        setSelectorOpen(clickInside)
        if (!clickInside) {
          searchBox.value = ''
          setUserOptions(allUsers || [])
        }
      }
      window.removeEventListener('click', handleClick)
      window.addEventListener('click', handleClick)
      }
    }

    // eslint-disable-next-line
  }, [fetchingUsers])

  useEffect(() => {
    if (allUsersLoaded === true && fetchingUsers === true) {
      setUserOptions(allUsers);
      setListEmployees(getUsers('user_ids', allUsers));
      setSelectedUsers(prev => ({
        ...prev,
        shops_good_mail_user_ids: getUsers('shops_good_mail_user_ids', allUsers),
        shops_bad_mail_user_ids: getUsers('shops_bad_mail_user_ids', allUsers),
        user_ids: getUsers('user_ids', allUsers),
      }));

      setFetchingUsers(false);
    }
    // eslint-disable-next-line
  }, [allUsersLoaded, allUsers]);

  useEffect(() => {
    setOpen(modalOpen)
    setDirty(false)
    setErrors({})
    if (modalOpen) {
      fetchAllUsers();
      setFetchingUsers(true);
    }
    if (item) {
      setValue(item)
    }
    // eslint-disable-next-line
  }, [modalOpen, item])

  useEffect(() => {
    if (itemSubmitSuccess) {
      setDirty(null)
      dirtyFields.current = []
    }
  }, [itemSubmitSuccess]);

  useEffect(() => {
    if (!!submitErrors) setErrors(submitErrors.detail);
  }, [submitErrors]);

  const groupedOptions = [
    {
      label: 'userOptions',
      options: userOptions,
    },
  ];

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="el-icon-search"></i>
      </components.DropdownIndicator>
    );
  }

  const formatGroupLabel = () => {
    return (
      <div>
        <span className="textLengthOption">検索結果</span>
        <span>({userOptions.length})</span>
        <hr></hr>
      </div>
    );
  }

  const CircularLoading = () => (
    <div className={classes.formContain}>
      <Paper className={classes.root}>
        <div className={classes.loading}>
          <CircularProgress disableShrink />
        </div>
      </Paper>
    </div>
  )

  const handleInputChange = (newValue) => {
    const searchList = allUsers.filter(option => option.label.toLowerCase().includes(newValue))
    setUserOptions(searchList)
  }

  const renderListUser = () => {
    return (
      <div className="listUser">
        {
          (listEmployees ? (listEmployees).map((option, index) => (
            <div key={`employee-${index}`}>
              <p>{option.label}</p>
              <i onClick={() => deleteItem(index)} className="material-icons">close</i>
            </div>
          )) : '')
        }
      </div>
    );
  }

  const renderInputSelectUser = () => {
    const inputChange = ({target}) => {
      const value = target.value
      const searchList = allUsers.filter(option => option.label.toLowerCase().includes(value))
      setUserOptions(searchList)
    }

    const selectValue = value => {
      onSetListEmployees('user_ids', value)
      setUserOptions(allUsers || [])
    }
  
    return (
      <Grid item xs={12} className={classes.blockListUser}>
        <div>
          <FormControl
            className="search"
            error={!!errors.user_ids}
          >
            <p>所属スタッフ一覧</p>
            <div className={classes.selectEmployee}>
              <input
                onChange={inputChange}
                placeholder="スタッフ名を検索"
                className='search-box'
              />
              <MultipleSelect
                formatGroupLabel={formatGroupLabel}
                onChange={selectValue}
                value={selectedUsers['user_ids']}
                isMulti
                isClearable={false}
                formatOptionLabel={formatOptionLabel}
                name="user_ids"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                placeholder="スタッフ名を検索"
                components={{ DropdownIndicator }}
                styles={customStyles({showMultivalue: false})}
                options={groupedOptions}
                className="basic-single"
                menuIsOpen={selectorOpen}
              />
            </div>
            {!!errors.user_ids && (
              <FormHelperText>{`users${errors.user_ids[0]}`}</FormHelperText>
            )}
          </FormControl>
          {renderListUser()}
        </div>
      </Grid>
    );
  }

  const onDiscard = () => {
    handleDirt('discarded_at', !!item.discarded_at === !!value.discarded_at)
    setValue({ ...value, discarded_at: value.discarded_at ? null : new Date() })
  };

  const handleSubmit = e => {
    e.preventDefault();

    const objectToSave = item.id ? { id: item.id } : {};
    dirtyFields.current.forEach(field => objectToSave[field] = value[field])
    save(objectToSave);
  };

  const onTextChange = e => {
    const { name, value } = e.target || {}
    onValueChanged(name, value)
  }

  const onValueChanged = (name, newValue) => {
    // 同スレッドの処理内で同じstateに複数回更新をかけられない為、画像アップロードまたは画像URLの更新では変更パラメータが複数になる為処理を分ける
    if(name === "image_url"){
      // 画像アップロードエラーメッセージをクリア
      if (errors.image){
        const {image, ...withoutImageError} = errors
        setErrors(withoutImageError)
      }
      setValue({ ...value, [name]: newValue,"shop_image":null })
      handleDirt(name, (item[name] || '') !== newValue)
      handleDirt("shop_image", (item["shop_image"] || '') !== null)
    }else if(name === "shop_image"){
      setValue({ ...value, [name]: newValue,"image_url":"" })
      handleDirt(name, (item[name] || '') !== newValue)
      handleDirt("image_url", (item["image_url"] || '') !== "")

    }else{
      setValue({ ...value, [name]: newValue })
      handleDirt(name, (item[name] || '') !== newValue)
    }
  }

  const onUsersChanged = (name, users) => {
    const existing = item[name] || []
    const incoming = (users || []).map(({ value }) => value)
    const clean = existing.length === incoming.length &&
      existing.sort().every((element, index) => element === incoming.sort()[index])
    setValue({ ...value, [name]: incoming })
    setSelectedUsers({ ...selectedUsers, [name]: users })
    handleDirt(name, !clean)
  }
  const onSetListEmployees = (name, users) => {
    const existing = item[name] || []
    const incoming = (users || []).map(({ value }) => value)
    const clean = existing.length === incoming.length &&
      existing.sort().every((element, index) => element === incoming.sort()[index])
    setValue({ ...value, [name]: incoming })
    setSelectedUsers({ ...selectedUsers, [name]: users })
    setListEmployees(users);
    handleDirt(name, !clean)
  };
  const deleteItem = (idx) => {
    if (listEmployees.length > 1) {
      listEmployees.splice(idx, 1)
      const existing = item["user_ids"] || []
      const incoming = (listEmployees || []).map(({ value }) => value)
      const clean = existing.length === incoming.length &&
        existing.sort().every((element, index) => element === incoming.sort()[index])
      setValue({ ...value, "user_ids": incoming })
      setSelectedUsers({ ...selectedUsers, "user_ids": listEmployees })
      setListEmployees(listEmployees)
      handleDirt("user_ids", !clean)
    } else {
      setSelectedUsers({ ...selectedUsers, "user_ids": [] })
      setListEmployees([])
      const existing = item["user_ids"] || []
      const incoming = ([]).map(({ value }) => value)
      const clean = existing.length === incoming.length &&
        existing.sort().every((element, index) => element === incoming.sort()[index])
      setValue({ ...value, "user_ids": incoming })
      handleDirt("user_ids", !clean)
    }
  }
  // プレビュー画像描写用のref
  const imgRef = useRef(null)

  // 画像変更時
  useEffect(() => {
      if(imgRef.current){
        if(value.image_url){
          imgRef.current.src=value.image_url
        }else if (value.shop_image){
          imgRef.current.src=value.shop_image.data
        }else{
          imgRef.current.src=`${process.env.PUBLIC_URL}/noimg.png`
        }
      }
  }, [value.image_url,value.shop_image]);
  // 画像アップロードアイコンクリック
  const inputRef = useRef(null);

  const iconButtonClickHandle = async (e) =>{
    await inputRef.current.click();
  }
  const uploadHandle = async(e) =>{
    // inputタグのonclickイベントの為patchリクエストの処理をキャンセル
    e.preventDefault();
    const file = e.target.files[0];
    await getImage(file).then((image)=>{
      // エラーメッセージをクリア
      if (errors.image){
        const {image, ...withoutImageError} = errors
        setErrors(withoutImageError)
      }
      // S3へのファイルアップロードはバックエンドで実行する為image_urlは空にする
      onValueChanged("shop_image",image)
    })
    .catch((error)=>{
      setErrors({...errors,image:error})
    })
  }
  

  const getImage = (file) => new Promise((resolve, reject) => {
    if(file){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // ファイル形式チェック
        const acceptFileTypes = ['image/jpg','image/jpeg','image/png']
        if(!file || !acceptFileTypes.includes(file.type)){
          reject(new Error('対応していないファイル形式です。(対応形式:jpeg, jpg, png)'))
        }
        if(file.size>10485760){
          reject(new Error('10MB以下の画像ファイルを指定してください。'))
        }
        resolve({
          "filename": file.name,
          "data": reader.result,
          "content_type": file.type
        });
      };

      reader.onerror = function (error) {
        console.error('Error: ', error);
        reject(error)
      };
    }
  })
  
  const noImageHandle=e=>{
    e.target.src = `${process.env.PUBLIC_URL}/noimg.png`
  }

  const renderForm = () => {
    return (
      <form name="shop-form" onSubmit={handleSubmit}>
        <SaveBtn type="submit" dirty={dirty} itemSubmitting={dirty === null ? true : false} />
        <Button className={buttonToggle} onClick={onDiscard}>
          <Switch
            styles={{
              trackChecked: {
                backgroundColor: "#EC681A"
              }
            }}
            on="yes"
            off="no"
            value={!!!value.discarded_at ? "yes" : "no"}
          />
          {value.discarded_at ? "非公開" : "公開中"}
        </Button>
        <Button className={buttonDel} variant="outlined" onClick={onModalClose} >
          閉じる
          <i className="material-icons">clear</i>
        </Button>
        {/* 商品画像 */}
        <div className={classes.imageWrap} >
          {value.image_url || value.shop_image?
            <img ref={imgRef} className={classes.image} src={value.image_url || value.shop_image} alt="" onError={noImageHandle} />
            :
            <img ref={imgRef} className={classes.image} src={`${process.env.PUBLIC_URL}/noimg.png`} alt="" onError={noImageHandle} />
          }
        </div>
        <div className={classes.headerFrom}>
          <TextField
            fullWidth={true}
            className={classes.editName}
            required={true}
            name={"name"}
            defaultValue={value.name || ''}
            placeholder={"商品名"}
            variant={"outlined"}
            error={!!errors.name}
            helperText={!!errors.name && `商品名${errors.name[0]}`}
            onChange={onTextChange}
          />
          <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
        </div>

        <Grid container>
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              fullWidth
              className={classes.inputGroup}
              error={!!errors.uid}
            >
              {/* 垂直方向に下揃えになるようラベルにスタイルを追加 */}
              <FormLabel component="p"  style={{ display: 'inline-flex', alignItems: 'end' }}>商品画像
                {/* ラベル、アイコン間にマージンを設ける為IconButtonでラップ */}
                <IconButton  style={{ marginLeft: '5px'}} onClick={iconButtonClickHandle}>
                  <PublishIcon fontSize='small'/>
                  <input
                    type="file"
                    ref={inputRef}
                    style={{ display: 'none' }}
                    accept=".jpg, .jpeg, .png"
                    onChange={uploadHandle}
                  />
                </IconButton>
              </FormLabel>
              <TextField
                required={false}
                name={"image_url"}
                value={value.image_url}
                placeholder={"URL"}
                variant={"outlined"}
                error={!!errors.image}
                helperText={!!errors.image && `商品画像${errors.image}`}
                onChange={onTextChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              fullWidth
              className={classes.inputGroup}
              error={!!errors.uid}
            >
              <FormLabel component="p">商品コード</FormLabel>
              <TextField
                required={true}
                name={"uid"}
                defaultValue={value.uid}
                placeholder={"商品コード"}
                variant={"outlined"}
                error={!!errors.uid}
                helperText={!!errors.uid && `商品コード${errors.uid[0]}`}
                onChange={onTextChange}
              />
            </FormControl>
          </Grid>


          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              fullWidth
              className={classes.inputGroup}
            >
              <FormLabel component="p">GOODメールの送付先</FormLabel>
              <div className={classes.select}>
                {fetchingUsers ? <CircularLoading/> : <MultipleSelect
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={v => onUsersChanged('shops_good_mail_user_ids', v)}
                  value={selectedUsers['shops_good_mail_user_ids']}
                  formatOptionLabel={formatOptionLabel}
                  name={"shops_good_mail_user_ids"}
                  isMulti
                  hideSelectedOptions={false}
                  placeholder={"スタッフ名を検索"}
                  components={{ DropdownIndicator }}
                  styles={customStyles({showMultivalue: true})}
                  formatGroupLabel={formatGroupLabel}
                  options={groupedOptions}
                  closeMenuOnSelect={false}
                  onInputChange={handleInputChange}
                />}
              </div>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              fullWidth
              className={classes.inputGroup}
            >
              <FormLabel component="p">BADメールの送付先</FormLabel>
              <div className={classes.select}>
                {fetchingUsers ? <CircularLoading/> : <MultipleSelect
                    className="basic-single"
                    onChange={v => onUsersChanged('shops_bad_mail_user_ids', v)}
                    value={selectedUsers['shops_bad_mail_user_ids']}
                    formatOptionLabel={formatOptionLabel}
                    name={"shops_bad_mail_user_ids"}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti
                    placeholder={"スタッフ名を検索"}
                    components={{DropdownIndicator}}
                    styles={customStyles({showMultivalue: true})}
                    formatGroupLabel={formatGroupLabel}
                    options={groupedOptions}
                    onInputChange={handleInputChange}
                />}
              </div>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.blockDate}>
            <p>公開期間の設定</p>
            <div className="formDate">
              <p>開始日時</p>
              <div>
                <FormControl component="fieldset" error={!!errors.publish_start_at}>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale} item>
                      <KeyboardDatePicker
                        required={true}
                        margin="normal"
                        format={"yyyy/MM/dd"}
                        placeholder={"0000/00/00"}
                        name={"publish_start_at"}
                        inputVariant={"outlined"}
                        value={value.publish_start_at || null}
                        onChange={date => onValueChanged('publish_start_at', date)}
                        KeyboardButtonProps={{"aria-label": "change date"}}
                        keyboardIcon={<i className="material-icons">date_range</i>}
                        invalidDateMessage={"開始日付が正しくありません"}
                      />
                      <KeyboardTimePicker
                        required={true}
                        margin="normal"
                        inputVariant={"outlined"}
                        className="changeTime"
                        keyboardIcon={<i className="material-icons">query_builder</i>}
                        invalidDateMessage={"開始時間が正しくありません"}
                        placeholder={"00:00"}
                        ampm={false}
                        name={"publish_start_at"}
                        value={value.publish_start_at || null}
                        onChange={time => onValueChanged('publish_start_at', time)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {!!errors.publish_start_at && (
                    <FormHelperText>{`開始日時${errors.publish_start_at[0]}`}</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="formDate">
              <p>終了日時</p>
              <div>
                <FormControl component="fieldset" error={!!errors.publish_end_at}>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale} item>
                      <KeyboardDatePicker
                        margin="normal"
                        format={"yyyy/MM/dd"}
                        placeholder={"0000/00/00"}
                        name={"publish_end_at"}
                        inputVariant={"outlined"}
                        value={value.publish_end_at || null}
                        onChange={date => onValueChanged('publish_end_at', date)}
                        KeyboardButtonProps={{"aria-label": "change date"}}
                        keyboardIcon={<i className="material-icons">date_range</i>}
                        invalidDateMessage={"終了日付が正しくありません"}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        inputVariant={"outlined"}
                        className="changeTime"
                        keyboardIcon={<i className="material-icons">query_builder</i>}
                        invalidDateMessage={"終了時間が正しくありません"}
                        placeholder={"00:00"}
                        ampm={false}
                        name={"publish_end_at"}
                        value={value.publish_end_at || null}
                        onChange={time => onValueChanged('publish_end_at', time)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {!!errors.publish_end_at && (
                    <FormHelperText>{`終了日時${errors.publish_end_at[0]}`}</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </Grid>

          {fetchingUsers ? <CircularLoading/> : renderInputSelectUser()}

        </Grid>
      </form>
    );
  }

  const handleDirt = (name, isDirty) => {

    if (isDirty){
      dirtyFields.current = dirtyFields.current.concat([name])
    }else{
      dirtyFields.current = dirtyFields.current.filter(field => field !== name)
    }
    setDirty(dirtyFields.current.length > 0)
  }

  const closeConfirmationDialog = () => setIsConfirmationOpen(false)

  const onModalClose = () => {
    if (dirty) {
      setIsConfirmationOpen(true)
    } else {
      closeModal && closeModal()
    }
  }

  return (
    <SwipeableModal closeModal={onModalClose} modalOpened={open}>
      <div className={box}>
        {renderForm()}
      </div>
      <UnSavedWarningDialog
        open={isConfirmationOpen}
        onConfirm={() => {
          closeConfirmationDialog()
          closeModal && closeModal()
        }}
        onCancel={() => closeConfirmationDialog()}
      />
    </SwipeableModal>
  );
};

ProductForm.propTypes = propTypes;

export default connect(
  ({ shops: { itemSubmitting, itemSaveError, item, itemSubmitSuccess }, users }) => ({
    item,
    itemSubmitting,
    itemSubmitSuccess,
    submitErrors: itemSaveError,
    allUsers: users.allItems,
    allUsersLoaded: users.allItemsLoaded,
  }),
  (dispatch) => ({
    save: data => dispatch(thunkSaveShop(data)),
    fetchAllUsers: () => dispatch(thunkFetchAllUsers()),
  })
)(ProductForm);
