import React from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import iconPicture from '../../../../assets/icons/icon_picture.svg'

import IconCustom from './IconCustom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    border: "1px solid #B7B7B7",
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemIconChild: {
    height: 22,
    width: 22,
    "& svg": {
      fontSize: 20,
      color: "#EC681A",
    }
  },
}))

const DropzoneSmall = ({ onChange }) => {
  const classes = useStyles();

  const {
    getRootProps,
    getInputProps,
    // isDragActive,
    // isDragAccept,
    // isDragReject
  } = useDropzone({ accept: 'image/*' });

  return (
    <div className={classes.root} {...getRootProps()}>
      <input
        {...getInputProps()}
        onChange={onChange}
      />
      <IconCustom className={classes.itemIconChild} src={iconPicture} />
    </div>
  );
}

export default DropzoneSmall;
