import React from "react";
import Pagination from "react-js-pagination";
import { makeStyles } from "@material-ui/core/styles";
import {isMobileOnly, isTablet, isBrowser} from 'react-device-detect';
const useStyles = makeStyles(theme => ({
  blockPagination: {
    textAlign: "center",
    "& >.pagination": {
      display: "block",
      listStyle: "none",
      paddingInlineStart: "0",
      "& >.active": {
        background: "#ec681a",
        border: "0.5px solid #ec681a",
        "& a": {
          color: "#fff"
        }
      },
      "& li:hover": {
        background: "#FFFFFF",
        border: "0.5px solid #EC681A",
        cursor: "pointer",
        "& a": {
          font: "bold 10px/18px Noto Sans JP, Hiragino Kaku Gothic Pro",
          color: "#1A0707",
          letterSpacing: "0.5px"
        }
      },
      "& li": {
        display: "inline-block",
        border: "0.5px solid #b7b7b7",
        opacity: 1,
        marginRight: "7px",
        padding: "12px 20px",
        cursor: "pointer",
        "& a": {
          font: "bold 10px/18px Noto Sans JP, Hiragino Kaku Gothic Pro",
          letterSpacing: "0.5px",
          color: "#b7b7b7",
          opacity: 1,
          textDecoration: "none",
          "& .firstPageText": {
            background: "#646464 0% 0% no-repeat padding-box",
            opacity: 1,
            padding: "17px 20px",
            color: "#fff"
          },
          "& .prevPageText": {
            background: "#646464 0% 0% no-repeat padding-box",
            opacity: 1,
            padding: "17px 20px",
            color: "#fff"
          },
          "& .lastPageText": {
            background: "#646464 0% 0% no-repeat padding-box",
            opacity: 1,
            padding: "17px 20px",
            color: "#fff"
          },
          "& .nextPageText": {
            background: "#646464 0% 0% no-repeat padding-box",
            opacity: 1,
            padding: "17px 20px",
            color: "#fff"
          }
        }
      },
      "& li:nth-child(2)": {
        marginRight: "20px",
        border: "0.5px solid #646464",
        padding: "0px"
      },
      "& li:nth-child(2):hover": {
        opacity: 0.6
      },
      "& li:nth-last-child(2)": {
        marginLeft: "13px",
        border: "0.5px solid #646464",
        padding: "0px",
        marginRight: 0
      },
      "& li:nth-last-child(2):hover": {
        opacity: 0.6
      },
      "& li:nth-child(1)": {
        marginRight: "7px",
        border: "0.5px solid #646464",
        padding: "0px"
      },
      "& li:nth-child(1):hover": {
        opacity: 0.6
      },
      "& li:nth-last-child(1)": {
        marginLeft: "7px",
        border: "0.5px solid #646464",
        padding: "0px"
      },
      "& li:nth-last-child(1):hover": {
        opacity: 0.6
      }
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      "& >.pagination": {
        display: "block",
        listStyle: "none",
        paddingInlineStart: 0,
        "& >.active": {
          background: "#ec681a",
          border: "0.5px solid #ec681a",
          "& a": {
            color: "#fff"
          }
        },
        "& li:hover": {
          background: "#FFFFFF",
          border: "0.5px solid #EC681A",
          cursor: "pointer",
          "& a": {
            font: "bold 10px/18px Noto Sans JP, Hiragino Kaku Gothic Pro",
            color: "#1A0707",
            letterSpacing: "0.5px"
          }
        },
        "& li": {
          display: "inline-block",
          border: "0.5px solid #b7b7b7",
          opacity: 1,
          marginRight: "7px",
          padding: "5px 10px !important",
          cursor: "pointer",
          "& a": {
            font: "bold 10px/18px Noto Sans JP, Hiragino Kaku Gothic Pro",
            letterSpacing: "0.5px",
            color: "#b7b7b7",
            opacity: 1,
            textDecoration: "none",
            "& .firstPageText": {
              background: "#646464 0% 0% no-repeat padding-box",
              opacity: 1,
              padding: "10px 15px !important",
              color: "#fff"
            },
            "& .prevPageText": {
              background: "#646464 0% 0% no-repeat padding-box",
              opacity: 1,
              padding: "10px 15px !important",
              color: "#fff"
            },
            "& .lastPageText": {
              background: "#646464 0% 0% no-repeat padding-box",
              opacity: 1,
              padding: "10px 15px !important",
              color: "#fff"
            },
            "& .nextPageText": {
              background: "#646464 0% 0% no-repeat padding-box",
              opacity: 1,
              padding: "10px 15px !important",
              color: "#fff"
            }
          }
        },
        "& li:nth-child(2)": {
          marginRight: "10px",
          border: "0px solid #646464 !important",
          padding: "0px !important"
        },
        "& li:nth-child(2):hover": {
          opacity: "0.6"
        },
        "& li:nth-last-child(2)": {
          marginLeft: "3px",
          border: "0px solid #646464 !important",
          padding: "0px !important",
          marginRight: 0
        },
        "& li:nth-last-child(2):hover": {
          opacity: "0.6"
        },
        "& li:nth-child(1)": {
          marginRight: "7px",
          border: "0px solid #646464 !important",
          padding: "0px !important"
        },
        "& li:nth-child(1):hover": {
          opacity: 0.6
        },
        "& li:nth-last-child(1)": {
          marginLeft: "7px",
          border: "0px solid #646464 !important",
          padding: "0px !important"
        },
        "& li:nth-last-child(1):hover": {
          opacity: 0.6
        }
      }
    }
  },
  displayNone:{
    display: "none"
  }
}));

// check device
const setPrePage = (total) =>{
  if (isMobileOnly) {
    if(total > 5){
      return 5;
    } else {
      return total;
    }
  }
  if (isTablet) {
    if(total > 5){
      return 5;
    } else {
      return total;
    }
  }
  if (isBrowser) {
    if(total > 10){
      return 10;
    } else {
      return total;
    }
  }
}
const PaginationComponent = props => {
  const classes = useStyles();
  return (
    <div className={classes.blockPagination}>
      <Pagination
        firstPageText={<i className={isMobileOnly ? `fa fa-backward ${classes.displayNone}` :"fa fa-backward firstPageText"}/>}
        lastPageText={<i className={isMobileOnly ? `fa fa-forward ${classes.displayNone}` :"fa fa-forward nextPageText"}/>}
        prevPageText={<i className="fa fa-caret-left prevPageText" />}
        nextPageText={<i className="fa fa-caret-right nextPageText" />}
        activePage={props.activePage}
        itemsCountPerPage={props.itemsCountPerPage}
        totalItemsCount={props.totalItemsCount}
        pageRangeDisplayed={setPrePage(props.pageRangeDisplayed)}
        onChange={(e, offset) => props.onChange(e)}
      />
    </div>
  );
};
export default PaginationComponent;
