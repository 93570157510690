import { API_URL } from '../../../constants';

export const PAGINATION_PER_PAGE = 20;
export const SURVEYS_API_PATH = `${API_URL}/surveys`;
export const API_MODEL_TYPE_QUESTION = "question";
export const API_MODEL_TYPE_QUESTION_OPTION = "question_option";
export const API_MODEL_TYPE_SURVEY_PAGE = "survey_page";
export const API_MODEL_TYPE_SURVEY_HEADING = "survey_heading";
export const EVALUATION_SCALE_OPTIONS = [
  { value: "average_score", name: '平均点(11段階)', },
  { value: "top_two_rate", name: 'TOP2率(5段階)', },
  { value: "top_one_rate", name: 'TOP1率(5段階)', },
]
