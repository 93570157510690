import { API_MODEL_TYPE_SHOP } from "../products/constants"
import { API_MODEL_TYPE_USER } from "../users/constants"
import { API_MODEL_TYPE_FEEDBACK,
  API_MODEL_TYPE_QUESTION,
  API_MODEL_TYPE_QUESTION_OPTION,
  API_MODEL_TYPE_ANSWER_OPTION,
  TYPE_SELECT_USER,
  TYPE_DATE,
  TYPE_TEXT_AREA,
  TYPE_TEXT_FIELD,
  TYPE_SELECT,
  TYPE_RADIO_BUTTON,
  TYPE_SELECT_BUTTON,
  TYPE_CHECK_BOX,
  TYPE_NPS_QUESTION,
  TYPE_NPS_REASON,
  TYPE_UPLOAD,
} from "./constants"
import { NPS_COLORS } from "modules/products/aggregation/constants"
import { useEffect, useRef } from 'react'
import Storage from '../../../utils/Storage'
import { renderParams } from "modules/utils"

const getDates = () => {
  return {
    sdate: Storage.getStartDate(),
    edate: Storage.getEndDate(),
  }
}

export const usePagingEffect = (effect, page, searchParams) => {
  const { group_id, project_id, survey_id, shop_id, sdate, edate, question_id, sort_key, sort_val, filters,score_question_id, without_empty } = searchParams
  const firstTimeRefresh = useRef(true)

  useEffect(() => {
    if (firstTimeRefresh.current === true) {
      effect(+page, getDates())
    } else {
      effect(1, getDates())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id, project_id, survey_id, shop_id, sdate, edate, question_id, sort_key, sort_val, filters,score_question_id, without_empty])

  useEffect(() => {
    if (firstTimeRefresh.current !== true){
      effect(+page, getDates())
    }
    firstTimeRefresh.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
}

export const parseFeedback = ({ attributes, relationships, id }, included) => {
  let { shop, user } = relationships
  shop = getObjectFromIncluded(shop, included, API_MODEL_TYPE_SHOP)
  user = getObjectFromIncluded(user, included, API_MODEL_TYPE_USER)
  return {
    id,
    ...attributes,
    shop,
    user,
  }
}

export const parseComment = ({attributes, relationships, id}, included) => {
  let { user } = relationships
  user = getObjectFromIncluded(user, included, API_MODEL_TYPE_USER)
  return {
    id,
    ...attributes,
    user
  }
}

export const parseAnswer = ({attributes, relationships, id}, included) => {
  let { user, question, answer_options } = relationships
  question = getObjectFromIncluded(question, included, API_MODEL_TYPE_QUESTION)
  if (question.question_options) {
    const question_options = question.question_options.data
      .map(i => getObjectWithIdFromIncluded(i.id, included, API_MODEL_TYPE_QUESTION_OPTION))
    question = {
      ...question,
      question_options,
    }
  }
  if (answer_options) {
    answer_options = answer_options.data
      .map(i => getObjectWithIdFromIncluded(i.id, included, API_MODEL_TYPE_ANSWER_OPTION).question_option.data.id)
  }
  return {
    id,
    ...attributes,
    user: getObjectFromIncluded(user, included, API_MODEL_TYPE_USER),
    question,
    answer_options,
  }
}

export const getObjectFromIncluded = (object, includedObjects, API_MODEL_TYPE) => {
  if (object.data){
    const found = (includedObjects || []).filter(i => i.type === API_MODEL_TYPE)
      .find(i => i.id === object.data.id)
    if (found){
      return {
        id: object.data.id,
        ...found.attributes,
        ...found.relationships,
      }
    }
  }
  return object
}

export const parseFeedbackFromAnswers = (id, includedObjects) => {
  let feedback = getObjectWithIdFromIncluded(id, includedObjects, API_MODEL_TYPE_FEEDBACK)
  if (feedback){
    return {
      ...feedback,
      user: getObjectFromIncluded(feedback.user, includedObjects, API_MODEL_TYPE_USER),
      shop: getObjectFromIncluded(feedback.shop, includedObjects, API_MODEL_TYPE_SHOP),
    }
  }
  return null
}

export const getObjectWithIdFromIncluded = (checkId, includedObjects, API_MODEL_TYPE) => {
  const found = (includedObjects || []).filter(({ type }) => type === API_MODEL_TYPE).find(({ id }) => id === checkId)
  if (found){
    return {
      id: checkId,
      ...found.attributes,
      ...found.relationships,
    }
  }
  return null
}

export const getNpsColorStyle = score => {
  const color = +(score) > 8 ? NPS_COLORS.promoter : +(score) > 6 ? NPS_COLORS.passive : score != null ? NPS_COLORS.detractor : NPS_COLORS.greyColor
  return { backgroundColor: color }
}

export const getScoreColorStyle = (maxScore = 0, score) =>{
  // フィードバック一覧単一選択色分け
  let color = NPS_COLORS.greyColor
  if(maxScore === 10){
    if (score >= 9) color = NPS_COLORS.promoter
    if (score === 8 || score === 7) color = NPS_COLORS.passive
    if (score <= 6) color = NPS_COLORS.detractor
  }
  if(maxScore <= 9 && maxScore >= 4){
    if (score === maxScore) color = NPS_COLORS.promoter
    if (score === maxScore -1) color = NPS_COLORS.passive
    if (score < maxScore -1) color = NPS_COLORS.detractor
  }
  if(maxScore >= 11 || maxScore <= 3){
    color = NPS_COLORS.others
  }
  return { backgroundColor: color }
  
}

export const getNpsName = score => {
  const name = +(score) > 8 ? '推奨者' : +(score) > 6 ? '中立者' : score != null ? '批判者' : ""
  return name
}

/**
 * Extracts 2 values (visible, editable) from given answer.
 * - visible: only to show on UI. For example, name of selected option
 * - editable: identity or id of the answer value. For example, id of selected option
 *
 * editable=null means this answer shall not be edited on UI, AnswerForm.
 *
 * @param {Object} feedback
 * @param {Object} answer
 */
export const getAnswerValue = (feedback, answer) => {
  switch(answer.question_type){
    case TYPE_NPS_REASON:
    case TYPE_TEXT_FIELD:
    case TYPE_TEXT_AREA:
      return {
        visible: answer.answer_entry,
        editable: answer.answer_entry || "",
      }
    case TYPE_UPLOAD:
      return {
        visible: answer.answer_image_url,
        editable: null,
      }
    case TYPE_DATE:
      return {
        visible: answer.answer_date,
        editable: null,
      }
    case TYPE_SELECT_USER:
      return {
        visible: answer.user.name || (feedback && getFeedbackUserName(feedback)),
        editable: answer.user.id || "",
      }
    case TYPE_NPS_QUESTION:
    case TYPE_SELECT_BUTTON:
    case TYPE_SELECT:
    case TYPE_RADIO_BUTTON:
    case TYPE_CHECK_BOX:
      const selected_options = answer.question.question_options.filter(o => answer.answer_options.includes(o.id))
      let hasFreeAnswer = false
      const visible = selected_options.map(o => {
        if (!!o.is_free_answer){
          hasFreeAnswer = true
          return `${o.name} ${answer.answer_entry}`
        } else {
          return feedback? renderParams(o.name, feedback.shop.name) : o.name
        }
      }).join(" / ")
      return {
        visible,
        editable: hasFreeAnswer ? (answer.answer_entry || "") : null,
      }
    default:
      return null
  }
}

export const extractValidationError = (error, property) => {
  const { detail={}} = error || {}
  const value = detail[property]
  if (value) {
    if (Array.isArray(value))
      return value
    else if (typeof value === 'object'){
      return Object.values(value)
    } else
      return [value]
  }
  return []
}

export const getFeedbackUserName = (feedback, str='') => {
  return feedback.user.name || (feedback.is_blank_user ? 'その他' : str)
}


export const getQuestionQueryKeyValues = (question, options) => {
  let key = `q_${question.id}[]`
  let values
  switch (question.type) {
    case TYPE_SELECT:
    case TYPE_CHECK_BOX:
    case TYPE_RADIO_BUTTON:
    case TYPE_NPS_QUESTION:
    case TYPE_SELECT_BUTTON:
      values = (options || []).map(o => o.value)
      break
    case TYPE_SELECT_USER:
      values = (options || []).map(o => o.value)
      if (question.id === -1001) {
        key = "user_ids[]"
      }
      break
    case 'type_nps_category':
      key = 'nps[]'
      values = options
      break
    case TYPE_DATE:
      values = options
      break
    case TYPE_TEXT_AREA:
    case TYPE_TEXT_FIELD:
    case TYPE_NPS_REASON:
      key = `q_${question.id}`
      values = options
      break
    default:
  }
  return [key, values]
}