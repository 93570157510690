import qs from 'query-string'
import { default as axios } from 'axios'
import { convertToListAndParseGroup } from '../../../modules/utils'
import { PROJECTS_API_PATH } from '../../../modules/projects/constants'
import { SURVEYS_API_PATH } from '../../../modules/surveys/constants'
import { GROUPS_API_PATH } from '../../../modules/groups/constants'
import { traverseParse } from '../../../modules/groups/utils'
import { SHOPS_API_PATH } from '../../../modules/shops/constants'
import { USERS_API_PATH } from '../../../modules/users/constants'

export const getUrl = (path, params) => {
  if (Object.keys(params).length === 0) return path
  else return path + '?' + qs.stringify(params, { skipNull: true, skipEmptyString: true })
}

export const loadProjects = (params = { no_paging: 1 }) => {
  return axios
    .get(getUrl(PROJECTS_API_PATH, params))
    .then(({ data }) =>
      data.data.map(({ id, attributes: { name } }) => ({ label: name, value: id }))
    )
}

export const loadSurveys = (params = { no_paging: 1 }) => {
  return axios
    .get(getUrl(SURVEYS_API_PATH, params))
    .then(({ data }) =>
      data.data.map(({ id, attributes: { name } }) => ({ label: name, value: id }))
    )
}

export const loadGroups = (params = { no_paging: 1 }) => {
  return axios.get(getUrl(GROUPS_API_PATH, params)).then(({ data }) => {
    const list = data.map(item => traverseParse(item, null, 'root'))
    return convertToListAndParseGroup(list)
  })
}

export const loadShops = (params = { no_paging: 1 }) => {
  return axios
    .get(getUrl(SHOPS_API_PATH, params))
    .then(({ data }) =>
      data.data.map(({ id, attributes: { name } }) => ({ label: name, value: id }))
    )
}

export const loadUsers = (params = { no_paging: 1 }) => {
  return axios
    .get(getUrl(USERS_API_PATH, params))
    .then(({ data }) =>
      data.data.map(({ id, attributes: { name } }) => ({ label: name, value: id }))
    )
}
