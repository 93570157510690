import React from "react";
import { Drawer, makeStyles } from "@material-ui/core";
import { themedStyles } from './styles';

const useStyles = makeStyles(themedStyles);

const ItemDrawer = ({ open, onClose, children }) => {
  const classes = useStyles();

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className={classes.root}>
        {children}
      </div>
    </Drawer>
  );
};

export default ItemDrawer;
