import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DateRangePicker } from 'react-date-range'
import { ja } from 'date-fns/locale'
import { Grid, Popover } from '@material-ui/core'
import { ArrowDropDown, Clear } from '@material-ui/icons'
import useStyles from '../../common/Header/HeaderForm/styles'
import { formatDate, stringDateFormat } from 'helpers/DateHelper'
import IconCalender from '../../assets/icons/icon_calendar_active.svg'
import { endOfWeek, startOfWeek } from 'date-fns'

const configWeekStart = { weekStartsOn: 1 }

const clean = str => str ? str.replace(/-/g, "/") : ''

const getSelected = selected => {
  let startDate = ''
  let endDate = ''
  if (selected && selected.length > 1) {
    startDate = clean(selected[0])
    endDate = clean(selected[1])
  }
  return { startDate, endDate }
}

const FeedbackQuestionDate = ({ onSelect, selected }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [valueRemove, setValueRemove] = useState(true)
  const [errorDateTime, setErrorDateTime] = useState(false)
  const [errorFomatDateTime, setErrorFomatDateTime] = useState(false)
  const [errorDateTimeEmpty, setErrorFomatTimeEmpty] = useState(false)
  const [finalRanges, setFinalRanges] = useState({
    startDate: formatDate(startOfWeek(new Date(), configWeekStart)),
    endDate: formatDate(endOfWeek(new Date(), configWeekStart))
  });
  const [inputRanges, setInputRanges] = useState(getSelected(selected))
  const classes = useStyles()

  const handleOpen = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  useEffect(() => {
    if (moment(inputRanges.endDate, stringDateFormat, true)._isValid) {
      setErrorDateTime(false)
      setErrorFomatDateTime(false)
      validateRanges()
    }
    // eslint-disable-next-line
  }, [inputRanges.endDate])

  useEffect(() => {
    if (!inputRanges.startDate || !inputRanges.endDate) {
      setErrorFomatTimeEmpty(true)
    } else {
      setErrorFomatTimeEmpty(false)
    }
    // eslint-disable-next-line
  }, [inputRanges.startDate, inputRanges.endDate])

  const open = Boolean(anchorEl)
  const id = open ? 'date-popover' : undefined
  const hasDate = inputRanges.startDate && inputRanges.endDate

  const replaceSeparator = str => str ? str.replace(/\//g, "-") : ''

  const handleSelect = ({ selection }) => {
    const { startDate: rawStart, endDate: rawEnd } = selection
    const startDate = formatDate(rawStart)
    const endDate = formatDate(rawEnd)
    setInputRanges({ startDate, endDate })
    setValueRemove(false)
    setFinalRanges({ startDate, endDate })
    onSelect([replaceSeparator(startDate), replaceSeparator(endDate)])
  }

  useEffect(() => {
    if (selected && selected.length > 1 && selected[0] && selected[1]) {
      const startDate = formatDate(new Date(selected[0]))
      const endDate = formatDate(new Date(selected[1]))
      setInputRanges({ startDate, endDate })
      setValueRemove(false)
      setFinalRanges({ startDate, endDate })
    }
    // eslint-disable-next-line
  }, [selected])

  const handleDeleteDate = event => {
    event.stopPropagation() // TODO: stop parent click event
    setInputRanges({ startDate: '', endDate: '' })
    setValueRemove(true)
    onSelect([])
  }

  const selectionRange = {
    startDate: new Date(finalRanges.startDate),
    endDate: new Date(finalRanges.endDate),
    key: 'selection',
  }

  const selectionRangeRemove = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }

  const renderInputDate = (dateValue, dateKey) => {
    const errorKey = dateKey + 'Error'
    const handleChange = e => {
      if (!moment(e.target.value, stringDateFormat, true)._isValid) {
        setErrorFomatDateTime(true)
      } else {
        setErrorFomatDateTime(false)
      }
      setInputRanges({
        ...inputRanges,
        [dateKey]: e.target.value,
        [errorKey]: !moment(e.target.value, stringDateFormat, true)._isValid,
      })
    }
    return (
      <input placeholder='yyyy/MM/dd' value={dateValue} onChange={handleChange}
             className={(inputRanges[errorKey] || !dateValue) ? 'error' : ''}
      />
    )
  }

  const validateRanges = () => {
    const timeBefore = new Date(new Date().setFullYear(new Date().getFullYear() + 20))
    const timeAfter = new Date(new Date().setFullYear(new Date().getFullYear() - 100))
    if (isNaN(new Date(inputRanges.startDate)) || isNaN(new Date(inputRanges.endDate)) || moment(inputRanges.startDate, 'YYYY/MM/DD')._d < timeAfter || moment(inputRanges.endDate, 'YYYY/MM/DD')._d > timeBefore) {
      setErrorDateTime(true)
      return
    } else {
      setErrorDateTime(false)
    }
    let newValue = inputRanges
    if (Date.parse(inputRanges.startDate) > Date.parse(inputRanges.endDate)) {
      newValue = { ...inputRanges, startDate: inputRanges.endDate }
      setInputRanges(newValue)
    }
    setValueRemove(false)
    return newValue
  }

  const onChoose = () => {
    const newValue = validateRanges()
    handleClose()
    if (newValue) {
      setFinalRanges(newValue)
      onSelect([replaceSeparator(newValue.startDate), replaceSeparator(newValue.endDate)])
    }
  }

  return (
    <Grid container alignItems='center' className={classes.root} style={{
      justifyContent: 'center',
      margin: 0,
      width: '100%',
      maxWidth: 'unset',
    }}>
      <div className={classes.dateWrapper} onClick={handleOpen} style={{ maxWidth: 'unset', width: '100%', margin: '0 10px' }}>
        <img src={IconCalender} className={classes.iconCalendar} alt={''} />
        {hasDate ?
          <>
            <div className={classes.dateValue}>
              {formatDate(inputRanges.startDate)} ~ {formatDate(inputRanges.endDate)}
            </div>
            <Clear onClick={handleDeleteDate} className={classes.iconClear} />
          </>
          :
          <div className={classes.iconDown}><ArrowDropDown /></div>
        }
      </div>
      <Popover
        id={id} anchorEl={anchorEl} open={open} onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <DateRangePicker
          locale={ja}
          weekStartsOn={1}
          editableDateInputs
          startDatePlaceholder='開始日'
          endDatePlaceholder='終了日'
          dateDisplayFormat='yyyy/MM/dd'
          ranges={valueRemove ? [selectionRangeRemove] : [selectionRange]}
          onChange={handleSelect}
          staticRanges={[]}
          inputRanges={[]}
          className={valueRemove ? classes.dateRangePickerRemove : classes.dateRangePicker}
        />
        <div className={classes.dateInput} style={{ marginTop: 0 }}>
          <p style={{ marginTop: 0 }}>期間入力</p>
          {(errorDateTime && <p className={classes.erorMessage}>期間の指定が正しくありません</p>)}
          {((errorFomatDateTime && !errorDateTime && !errorDateTimeEmpty) &&
            <p className={classes.erorMessage}>日付のフォーマットが正しくありません</p>)}
          {(errorDateTimeEmpty && <p className={classes.erorMessage}>日付を入力してください</p>)}
          <div>
            {renderInputDate(inputRanges.startDate, 'startDate')}
            <span>-</span>
            {renderInputDate(inputRanges.endDate, 'endDate')}
            <button onClick={onChoose} disabled={inputRanges.startDateError || inputRanges.endDateError}>
              入力
            </button>
          </div>
        </div>
      </Popover>
    </Grid>
  )
}

export default FeedbackQuestionDate
