import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const styles = makeStyles(theme => ({
  buttonShowAc: {
    background: "#fff",
    padding: "10px 40px",
    textAlign: "right",
    margin: "20px 0",
    width: "100%",
    "& >div": {
      display: "inline-flex",
      alignItems: "center",
      textAlign: "right",
      width: "160px",
      border: "1px solid #F02544",
      padding: "8px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      font: "500 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#F02544",
      opacity: 1,
      "&:hover": {
        opacity: 0.6
      },
      "& >div": {
        width: "16px",
        height: "16px",
        border: "1px solid #F02544",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        marginRight: "15px",
        "& >div": {
          background: "#EC681A 0% 0% no-repeat padding-box",
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center"
        }
      }
    },
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      padding: "10px 10px",
      textAlign: "right",
      margin: "20px 0"
    },
    [theme.breakpoints.down("xs")]: {
      background: "#fff",
      padding: "10px 10px",
      textAlign: "right",
      margin: "20px 0",
      "& >div": {
        padding: "8px 5px",
        font: "500 8px/16px Noto Sans JP, Hiragino Sans",
        width: "auto",
        "&:hover": {
          opacity: 0.6
        },
        "& >div": {
          width: "14px",
          height: "14px",
          marginRight: "10px",
          "& >div": {
            width: "8px",
            height: "8px"
          }
        }
      }
    }
  },
  activeButtonArchive: {
    background: "#fff",
    padding: "10px 40px",
    textAlign: "right",
    margin: "20px 0",
    width: "100%",
    "& >div": {
      display: "inline-flex",
      alignItems: "center",
      textAlign: "right",
      width: "160px",
      border: "2px solid #EC681A",
      padding: "8px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      font: "500 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#fff",
      background: "#EC681A",
      opacity: 1,
      "&:hover": {
        opacity: 0.6
      },
      "& >div": {
        width: "16px",
        height: "16px",
        border: "3px solid #fff",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        marginRight: "15px",
        "& >div": {
          background: "#fff 0% 0% no-repeat padding-box",
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center"
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px",
      textAlign: "right",
      margin: "20px 0",
      "& >div": {
        padding: "8px 5px",
        font: "500 8px/16px Noto Sans JP, Hiragino Sans",
        width: "auto",
        "&:hover": {
          opacity: 0.6
        },
        "& >div": {
          width: "14px",
          height: "14px",
          marginRight: "10px",
          "& >div": {
            width: "8px",
            height: "8px"
          }
        }
      }
    }
  }
}));

const propTypes = {
  showInactiveItems: PropTypes.bool.isRequired,
  setShowInactiveItems: PropTypes.func.isRequired,
};

const ProjectListTools = ({
  showInactiveItems,
  setShowInactiveItems,
}) => {
  const classes = styles();

  return (
    <Grid
      className={
        showInactiveItems ? classes.activeButtonArchive : classes.buttonShowAc
      }
      item
    >
      <div onClick={() => setShowInactiveItems(!showInactiveItems)}>
        <div />
        { showInactiveItems ? 'アーカイブを閲覧中' : 'アーカイブを見る' }
      </div>
    </Grid>
  )
}

ProjectListTools.propTypes = propTypes;

export default connect(
  ({ projects: { pagination: { total } } }) => ({
    total: +total,
  }),
  null,
)(ProjectListTools);
