/**
 * Get leaf nodes which has shops from nested areas.
 * Note: Leaf is a node which doesn't have children
 *
 * @param area parent node
 * @returns {[]} array of leaf nodes
 */
export const getLeaves = area => {
  let nonParent = []
  if (!area) return nonParent
  if (area.children && area.children.length > 0) {
    area.children.forEach(child => {
      nonParent = nonParent.concat(getLeaves(child))
    })
  } else {
    if (area.shops && area.shops.data && area.shops.data.length > 0) {
      const { id, name } = area
      const shops = area.shops.data.map(({ id, attributes: { name } }) => ({
        value: id,
        label: name,
      }))
      nonParent.push({ value: id, label: name, shops })
    }
  }
  return nonParent
}

/**
 * Check if condition(shop_id, area_id) exists in a list of areas
 * Notes:
 * Area has nested structure
 * Skip area which has children areas
 *
 * @param condition
 * @param areas
 * @returns {boolean}
 */
export const searchCondition = (condition, areas) => {
  for (const area of areas) {
    if (!area.children || area.children.length === 0) {
      if (+condition.area_id === +area.id) {
        for (const shop of area.shops.data) {
          if (+condition.shop_id === +shop.id) {
            return true
          }
        }
      }
    }
    else {
      if (searchCondition(condition, area.children)) {
        return true
      }
    }
  }
  return false
}