import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
} from "@material-ui/core";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { FormField } from '../components';
import DropzoneSmall from './DropzoneSmall';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F7F7F7',
  },
  imageZone: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
    position: 'relative',
    border: '1px solid #B7B7B7'
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
  },
  layerDelete: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    position: 'absolute',
    borderRadius: 5,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  deleteBtn: {
    color: '#B7B7B7'
  }
}));

const AddImageSmall = ({ image, setImage }) => {
  const classes = useStyles()
  const [hover, setHover] = useState(false)

  const getImage = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        "filename": file.name,
        "data": reader.result,
        "content_type": file.type
      });
    };

    reader.onerror = function (error) {
      console.error('Error: ', error);
      reject(error)
    };
  })

  const onUploadImage = async (event) => {
    event.preventDefault();

    const [file] = event.target.files;

    if (file) {
      const image = await getImage(file);
      setImage(image);
      setHover(false);

    } else {
      console.error('File uploaded is not valid.');
    }
  }

  return (
    <FormField className={classes.form}>
      {!image.data && (
        <DropzoneSmall onChange={onUploadImage} classes={classes} />
      )}
      {
        !!image.data && (
          <Grid
            className={classes.imageZone}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <img className={classes.image} src={image.data} alt="" />
            {
              hover &&
              <div className={classes.layerDelete}>
                <CloseOutlinedIcon
                  onClick={() => setImage(null)}
                  className={classes.deleteBtn}
                />
              </div>
            }
          </Grid>
        )
      }
    </FormField>
  )
}

export default AddImageSmall
