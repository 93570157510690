import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  FormHelperText,
  makeStyles,
  Typography,
  Paper, InputBase, Divider, IconButton 
} from "@material-ui/core";
import { thunkFetchUsers } from "modules/public/redux/thunk";
import { parseToOptionValues } from "modules/utils";
import { QuestionTypeSelectUserStyles } from "./styles";
import DialogSelect from './DialogSelect';
import HtmlRender from "./HtmlRender";

const useStyles = makeStyles(QuestionTypeSelectUserStyles);

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  shop_id: PropTypes.any, // string or number
  companyUrlText: PropTypes.string.isRequired,
  shortUrl: PropTypes.string.isRequired,
  users: PropTypes.array,
  usersLoaded: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  onInputValue: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  staff_id: PropTypes.number,
  updateDoc: PropTypes.func.isRequired,
  answer: PropTypes.object.isRequired,
};

const QuestionTypeSelectUser = ({
  questionItem,
  shop_id,
  companyUrlText,
  shortUrl,
  users,
  usersLoaded,
  errors,
  onInputValue,
  fetchUsers,
  staff_id,
  updateDoc,
  answer,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({ value: '', label: 'その他' });
  const selectHasError = !!errors.user_id;

  useEffect(() => {
    fetchUsers({ companyUrlText, shortUrl, shopId: shop_id });
    // eslint-disable-next-line
  }, [shop_id]);

  useEffect(() => {
    if (answerIsDetermined) {
      onInputValue(questionItem, { selectValue: String(staff_id) });
      updateDoc({
        user: {
          id: !!staff_id ? +staff_id : staff_id,
          name: !!staff_id ? (options.find(item => item.value === String(staff_id)) || {}).label || '' : ''
        }
      });
    } 
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    const options = [...users, { value: '', label: 'その他' }];
    setOptions(options);
    const selectedUserId = !!staff_id && !!options.find(item => item.value === String(staff_id)) ? String(staff_id) : String(answer.user_id);
    setSelectedOption(options.find(item => String(item.value) === String(selectedUserId)));

    // eslint-disable-next-line
  }, [usersLoaded]);

  if (!usersLoaded) {
    return null;
  }

  const selectChangeHandler = ({ value }) => {
    onInputValue(questionItem, { selectValue: value });
    updateDoc({
      user: {
        id: !!value ? +value : value,
        name: (users.filter(item => item.value === String(value))[0] || {}).label || '',
      }
    });

    setSelectedOption(options.find(item => item.value === value))
  };

  const answerIsDetermined = !!selectedOption && selectedOption.value === String(staff_id);

  return (
    <div className={classes.selectAnswer}>
      <Typography className={classes.textAnswer1}>
        <HtmlRender
          value = {questionItem.body}
        />
        {questionItem.is_required && (
          <span className="required">必須</span>
        )}
      </Typography>
      {!!questionItem.question_image_url && (
        <div className={classes.img}>
          <img src={questionItem.question_image_url} alt={''}/>
        </div>
      )}
      {!answerIsDetermined &&
        <>
          <Paper className={classes.selectRoot} onClick={() => setOpen(true)}>
            <InputBase
              className={classes.input}
              placeholder="選んでください。"
              readOnly
              value={!!selectedOption ? selectedOption.label : "選んでください。"}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton color="primary" className={classes.iconButton} aria-label="directions">
              <i className="el-icon-caret-bottom"></i>
            </IconButton>
          </Paper>
          {selectHasError && (
            <FormHelperText error={true}>{errors.user_id.map(err => err)}</FormHelperText>
          )}
        </>
      }
      {answerIsDetermined && (
        <Typography className={classes.textAnswer1}>
          {selectedOption.label}
        </Typography>
      )}
      {open &&
        <DialogSelect
          title="選んでください。"
          handleClose={() => setOpen(false)}
          itemSelected={selectedOption}
          handleSelect={selectChangeHandler}
          options={options}
        />
      }
    </div>
  )
}

QuestionTypeSelectUser.propTypes = propTypes;

export default connect(
  ({ public: { doc: { shop }, users, usersLoaded} }) => {
    return {
      shop_id: shop.id,
      users: parseToOptionValues(users, ({ id, attributes: { ucode } }) => ({ label: ucode, value: id })),
      usersLoaded,
    }
  },
  (dispatch) => ({
    fetchUsers: ({ companyUrlText, shortUrl, shopId }) => {
      dispatch(thunkFetchUsers({ companyUrlText, shortUrl, shopId }))
    },
    updateDoc: (params) => {
      dispatch({ type: 'UPDATE_FEEDBACK_DOC', payload: { params }})
    }
  })
)(QuestionTypeSelectUser);
