export const DialogSelectStyles = theme => ({
  dialogContainer: {
    "& .MuiDialog-paper": {
      borderRadius: 0,
      width: 295,
      maxHeight: 500
    },
    "& .MuiDialogTitle-root": {
      padding: "20px 10px 7px 10px"
    },
    "& .MuiDialogContent-root": {
      padding: "0 15px 0 15px"
    }
  },
  titleDialog: {
    font: "600 13px/21px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.25px",
    color: "#EC681A",
    borderBottom: "solid 1px #EC681A",
    margin: 0,
    padding: "10px 15px",
  },
  optionItem: {
    font: "400 10px/16px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.25px",
    color: "#1A0707",
    borderBottom: "0.6000000238418579px solid #C2C2C2",
    margin: 0,
    padding: "11px 2px",
    cursor: 'pointer',
    '&:hover': {
      background: "#F2EEEC 0% 0% no-repeat padding-box"
    }
  },
  childItem: {
    paddingLeft: 16
  },
  listRoot: {
    padding: 0
  },
  itemText: {
    "& .MuiListItemText-primary": {
      font: "400 13px/16px 'Noto Sans JP', sans-serif",
      letterSpacing: "0.25px",
      color: "#1A0707",
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      display: 'inline-block'
    }
  },
  selectedText: {
    "& .MuiListItemText-primary": {
      color: "#EC681A",
    },
    "& p": {
      color: "#EC681A",
    }
  },
  rotateIcon: {
    transform: "rotate(180deg)",
    color: "#b7b7b7",
    fontSize: "large"
  },
  icon: {
    color: "#b7b7b7",
    fontSize: "large"
  }
})
export const SelectStyles = theme => ({
  root: {
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    border: "1px solid #B7B7B7",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #EC681A",
    }
  },
  selectActived: {
    border: "1px solid #EC681A",
  },
  input: {
    marginLeft: 10,
    flex: 1,
    font: "300 10px/16px 'Noto Sans JP', sans-serifP",
    color: "#1A0707",
    letterSpacing: "0.25px",
    cursor: "pointer",
    "& .MuiInputBase-input": {
      cursor: "pointer"
    }
  },
  iconButton: {
    padding: 0,
    "& i": {
      color: "#1A0707",
      fontSize: 13,
      margin: 6
    }
  },
  divider: {
    height: 20,
    margin: "4px 0",
    background: "#1A0707"
  },
})