import React from 'react'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import { isMobileOnly } from 'react-device-detect'
import ElementIcon from '../../../../../common/icon/ElementIcon'

const useStyles = makeStyles(theme => ({
  input: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '47.6%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'inline-block',
  },
}))

const Dropdown = ({ groupData, shopData, name, onChange, onFocus, ...other }) => {
  const classes = useStyles()
  const isMobile = isMobileOnly

  React.useEffect(() => {
    if (name === 'group')
      onChange(groupData.value ? groupData : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData.value])

  React.useEffect(() => {
    if (name === 'shop')
      onChange(shopData.value ? shopData : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopData.value])

  const DropdownIndicator = props => {
    const { DropdownIndicator } = components
    return (
      <DropdownIndicator {...props}>
        {isMobile && <ElementIcon name="caret-bottom" />}
      </DropdownIndicator>
    )
  }

  const selectMenuStyle = provided => {
    const menuStyle = {
      ...provided,
      position: 'unset',
      borderRadius: 0,
      boxShadow: 'unset',
      marginBottom: 0,
      marginTop: 0,
      paddingRight: 10,
      '& > div': {
        padding: '4px 0 4px 10px',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    }
    return isMobile ? menuStyle : { ...provided }
  }

  const selectOptionStyle = (provided, { isSelected, isFocused }) => {
    const avoidOverflow = {
      alignItems: 'center',
      overflow: 'hidden',
      wordBreak: 'break-all',
    }
    const optionStyle = {
      ...provided,
      color: isSelected ? '#EC681A' : 'black',
      backgroundColor: isFocused ? '#F2EEEC !important' : 'unset',
      padding: 10,
      borderBottom: '1px solid #CCC',
      display: 'block',
      ...avoidOverflow,
      ':active': {
        backgroundColor: '#F2EEEC !important',
      },
    }
    return isMobile ? optionStyle : { ...provided, ...avoidOverflow }
  }

  const customStyles = {
    control: (base, state) => {
      const { hasValue, menuIsOpen } = state
      const { webStyle, mobileStyle } = hasValue
        ? {
            webStyle: {
              ...base,
              background: '#EC681A',
              borderColor: 'white',
              color: 'white',
            },
            mobileStyle: {
              ...base,
              borderRadius: 0,
              borderStyle: 'none !important',
              borderBottom: menuIsOpen
                ? '1px solid #EC681A !important'
                : '1px solid #1A0707 !important',
              boxShadow: 'none !important',
              color: menuIsOpen ? '#EC681A' : '#1A0707',
            },
          }
        : {
            webStyle: {
              ...base,
              color: '#ccc',
              '&:hover': { background: '#EFEFEF' },
              '& > div': { height: 38 },
            },
            mobileStyle: {
              ...base,
              borderRadius: 0,
              borderStyle: 'none !important',
              borderBottom: '1px solid #ccc !important',
              boxShadow: 'none !important',
              color: '#ccc',
            },
          }

      return isMobile ? mobileStyle : webStyle
    },
    container: provided =>
      isMobile ? { ...provided, position: 'unset' } : { ...provided },
    singleValue: provided => ({ ...provided, color: 'inherit' }),
    clearIndicator: provided => ({ ...provided, color: 'inherit' }),
    dropdownIndicator: provided => ({ ...provided, color: 'inherit' }),
    indicatorSeparator: provided => (isMobile ? { display: 'none' } : { ...provided }),
    menu: selectMenuStyle,
    option: selectOptionStyle,
  }

  return (
    <div className={classes.input}>
      <Select
        components={{ DropdownIndicator }}
        isSearchable={false}
        styles={customStyles}
        isClearable={true}
        onChange={e => onChange && onChange(e)}
        onFocus={onFocus}
        {...other}
      />
    </div>
  )
}

export default connect(({ global: { groupData, shopData } }) => ({
  groupData,
  shopData,
}))(Dropdown)
