import { makeStyles } from '@material-ui/core/styles';

export const dialogTitleStyle = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const dialogStyles = makeStyles(theme => ({
  dialogPaper: {
    overflowY: 'visible !important'
  },

  dialogContent: {
    overflowY: 'visible !important'
  }
}));

export const customSelectorStyle = {
  control: (provided) => ({
    ...provided,
    background: '#EC681A',
    color: '#fff',
  }),
  dropdownIndicator: (provided) => ({ ...provided, color: 'inherit' }),
  singleValue: (provided) => ({ ...provided, color: 'inherit' }),
}

export const roundEdgeStyles = makeStyles(theme => ({
  roundEdge: {
    font: "500 10px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.25px",
    color: "#1A0707",
    opacity: 1,
    border: "1px solid #B7B7B7",
    backgroundColor: "#fff",
    borderRadius: "18px",
    padding: "10px 15px",
    marginLeft: "10px",
    "&:hover":{
      border: "1px solid #EC681A",
      background: "#EC681A 0% 0% no-repeat padding-box"
    },
    "&:hover > span": {
      color: "white",
    },
    "&:hover i": {
      color: "white",
    },
    "& >span": {
      font: "500 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      "& >span": {
        marginLeft: 0,
        marginRight: 0,
        "& >i": {
          color: "#B7B7B7",
          fontSize: "16px !important",
          marginLeft: "15px",
        }
      }
    }
  },
  [theme.breakpoints.down("xs")]: {
    "& > button": {
      font: "500 8px/10px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      border: "1px solid #B7B7B7",
      borderRadius: "18px",
      padding: "8px 5px",
      marginLeft: "10px",
      "& >span": {
        "& >span": {
          marginLeft: 0,
          marginRight: 0,
          "& >svg": {
            color: "#B7B7B7",
            fontSize: "16px !important",
            marginLeft: "5px !important"
          }
        }
      }
    }
  }
}));
