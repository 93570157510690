import { makeStyles } from '@material-ui/core/styles';

export const useFeedbackAnserListStyle = makeStyles(theme => ({
  content: {
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    padding: '10px',
    position: 'relative'
  },
  paper: {
    backgroundColor: '#EFEFEF',
    marginTop: '10px',
    fontSize: '12px',
    color: '#1A0707',
    fontWeight: 'bold',
    padding: '11px 20px 11px 30px',
    boxShadow: 'none',
    width: '100%'
  },
  paperBottom: {
    padding: '16px 24px 24px 54px',
    boxShadow: '0px 0.2px 3px #C2C2C299',
    color: '#1A0707',
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
    marginTop: '2px',
    "& > *":{
      wordBreak: "break-all"
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px 10px 50px'
    }
  },
  boxScroll: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 306px)'
  },
  txtQ: {
    font: 'bold 10px Noto Sans JP, Hiragino Kaku Gothic Pro',
    letterSpacing: '0.25px',
    color: '#1A0707',
    opacity: 1,
    width: '5%',
    maxWidth: '5%'
  },
  titleHeader: {
    wordBreak: 'break-all',
    font: 'bold 10px/16px Noto Sans JP, Hiragino Kaku Gothic Pro',
    letterSpacing: '0.25px',
    color: '#1A0707',
    opacity: 1
  },
  colorRed: {
    background: '#CB4944 0% 0% no-repeat padding-box'
  },
  colorGreen: {
    background: '#1FA371 0% 0% no-repeat padding-box'
  },
  colorOrange: {
    background: '#EC681A 0% 0% no-repeat padding-box'
  },
  tag: {
    marginLeft: 15,
    textAlign: 'right',
    '& div': {
      font: 'bold 10px/18px Noto Sans JP, Hiragino Sans',
      letterSpacing: '0.8px',
      color: '#FFFFFF',
      opacity: 1,
      borderRadius: '10px',
      marginRight: '5px',
      marginBottom: '5px',
      padding: '1px 10px',
      display: 'inline-block'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 5,
      textAlign: 'right'
    }
  },
  txtContent: {
    font: '12px/18px Noto Sans JP, Hiragino Kaku Gothic Pro',
    letterSpacing: '0.3px',
    color: '#1A0707',
    opacity: 1,
    whiteSpace: 'pre-line'
  },
  txtBtnEdit: {
    font: 'bold 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro',
    letterSpacing: '0.25px',
    color: '#1A0707',
    opacity: 1,
    marginLeft: 20,
    '& i': {
      marginRight: '10px',
      fontSize: '15px'
    },
    "&:hover":{
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      border: "1px solid #EC681A",
      borderRadius: "5px"
    },
    "&:hover >span>i":{
      color: "#EC681A",
    },
    "&:hover >span":{
      color: "#EC681A",
    }
  },
  gridBtnDel: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      textAlign: 'right'
    }
  },
  answerNotBeSelected: {
    opacity: 0.25
  }
}));

export const useFeedbackCommentInputStyle = makeStyles(theme => ({
  boxSearch: {
    background: '#F2EEEC',
    padding: '15px 35px',
    bottom: 0,
    right: 0,
    left: 0,
    '& input': {
      width: '100%',
      height: '32px !important',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #939393',
      borderRadius: 5,
      padding: '7px 80px 8px 15px',
      '&:focus': {
        border: '1px solid #ec681a !important',
        outline: "none"
      },
    },
    '& >div': {
      position: 'relative',
      '& >button': {
        '& >span': {
          '& >span': {
            font: "600 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro",
            letterSpacing: "0.25px",
            color: "#FFFFFF",
            opacity: 1,
            marginLeft: "10px"
          }
        }
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: '15px 10px'
    }
  },
  button: {
    fontSize: 14,
    color: '#fff',
    borderRadius: '0 5px 5px 0',
    height: '100%',
    textAlign: 'center',
    border: 'none',
    position: 'absolute',
    right: 0,
    padding: 0,
    top: 0,
    width: '76px',
    '& :hover': {
      backgroundColor: '#ec681a !important',
    },
    '& >button': {
      height: '32px !important',
      background: '#ec681a',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& >span>span': {
        font: '600 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro',
        color: '#FFFFFF',
        letterSpacing: '0.25px',
        marginLeft: '10px'
      },
      '& .MuiSvgIcon-root': {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: '0 8px 0 0'
      },
      [theme.breakpoints.down('xs')]: {
        borderRadius: '0 5px 5px 0',
      }
    },
  }
}));

export const useFeedbackCommentListStyle = makeStyles(theme => ({
  content: {
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    padding: '10px',
    position: 'relative',
    wordBreak:"break-all"
  },
  perRepTop: {
    padding: theme.spacing(2),
    backgroundColor: 'white',
    marginTop: '10px',
    fontSize: '12px',
    color: '#1A0707'
  },
  boxComment: {
    padding: '24px 20px',
    boxShadow: '0px 0.2px 3px #C2C2C299',
    margin: 10,
    '& .content': {
      color: '#1A0707',
      borderBottom: 'solid 2px #e7e7e7',
      paddingBottom: 10,
      font: '10px/16px Noto Sans JP, Hiragino Kaku Gothic Pro',
      letterSpacing: '0.25px',
      marginTop: '5px'
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  nameComment: {
    font: 'bold 14px/24px Noto Sans JP, Hiragino Kaku Gothic Pro',
    letterSpacing: '0.35px',
    color: '#1A0707',
    opacity: 1
  },
  date: {
    font: '9px/10px Noto Sans JP, Hiragino Kaku Gothic Pro',
    letterSpacing: '0.23px',
    color: '#939393',
    opacity: 1,
    marginLeft: '20px'
  },
  boxScroll: {
    overflowY: 'auto',
    height: 'calc(100vh - 368px)'
  },

  btnDel: {
    font: '10px/24px Noto Sans JP, Hiragino Sans',
    letterSpacing: '0.25px',
    color: '#F02544',
    opacity: 1,
    margin: '10px 0px 5px 1px',
    border: '1px solid #F02544',
    '&:hover': {
      background: "#F2EEEC 0% 0% no-repeat padding-box"
    },
    '&  span': {
      '&  span': {
        '&  i': {
          fontSize: '15px !important'
        }
      }
    }
  },
  likeComment: {
    border: '1px solid #B7B7B7',
    width: '90px',
    height: '38px',
    borderRadius: '17px',
    margin: '10px 0',
    float: "left",
    "&:hover":{
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      border: "1px solid #EC681A"
    },
  },
  txtRight:{
    textAlign: 'right'
  }
}));

export const useFeedbackDetailStyle = makeStyles(theme => ({
  root: {
    width: 640,
    height: '100vh',
    flexGrow: 1,
    overflow: 'auto',
    right: 0,
    padding: '32px 48px 42px 30px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '-3px 0px 5px #B7B7B733',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 20px 42px 20px',
      width: '100vw'
    }
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    background: '#EFEFEF',
    padding: theme.spacing(0)
  },
  bodyStyle: {
    paddingTop: '0px(!important)'
  },
  fabGreen: {
    color: theme.palette.common.white
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: '#EFEFEF',
    marginTop: '10px',
    fontSize: '12px',
    color: '#1A0707',
    fontWeight: 'bold'
  },
  paperBottom: {
    padding: theme.spacing(2),
    color: '#1A0707',
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
    marginTop: '2px'
  },
  card: {
    maxWidth: '100%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {},
  tab: {
    backgroundColor: '#F7F7F7',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      '& >div': {
        padding: '10px'
      }
    }
  },
  paperRepTop: {
    backgroundColor: 'white',
    marginTop: '10px',
    fontSize: '12px',
    color: '#1A0707',
    fontWeight: 'bold'
  },
  txtTop: {
    font: 'Bold 12px/18px Noto Sans CJK JP',
    letterSpacing: '0.3px',
    color: '#1A0707',
    opacity: 1,
    [theme.breakpoints.down('xs')]: {
      font: 'Bold 10px/18px Noto Sans CJK JP'
    }
  },
  pinText: {
    backgroundColor: "#E8D9A1",
    color: "#EC681A",
    font: "ヒラギノ角ゴ ProN",
    fontSize: 10,
    padding: '3px 5px',
    marginLeft: 5,
    borderRadius: 4,
  },
  boxNumber: {
    backgroundColor: '#1FA371',
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'middle',
    font: 'Bold 22px/24px Noto Sans JP',
    letterSpacing: '1.1px',
    color: '#FFFFFF',
    opacity: 1,
    height: 'auto',
    width: 'auto',
    marginRight: 20,
    padding: '8px 18px'
  },
  titleLeft: {
    color: '#1A0707',
    font: '12px/16px Noto Sans JP, Hiragino Sans',
    letterSpacing: '0.3px',
    opacity: '1',
    textAlign: 'right'
  },
  btnClose: {
    textAlign: 'right',
    '& :hover': {
      backgroundColor: '#EC681A',
      color: '#fff',
    },
    '& >button': {
      backgroundColor: '#B7B7B7',
      width: 'auto',
      height: 'auto',
      padding: '7px 15px',
      '& p': {
        margin: 0,
      },
      '& span': {
        font: '10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro',
        letterSpacing: '0.25px',
        color: '#FFFFFF',
      },
      [theme.breakpoints.down('xs')]: {
        padding: 7,
        minWidth: 44,
        '& span': {
          font: '8px/20px Noto Sans JP, Hiragino Kaku Gothic Pro',
        },
      }
    }
  },
  tabbar: {
    width: '100%',
    marginTop: '20px',
    fontSize: '12px',
    lineHeight: '48px',
    '& >.tabbar-left': {
      width: '50%',
      height: '48px',
      float: 'left',
      textAlign: 'center',
      letterSpacing: '0.3px',
      cursor: 'pointer',
      font: "500 12px/40px Noto Sans JP, Hiragino Sans",
      color: "#EC681A",
      opacity: 1
    },
    '& >.active-tab': {
      color: '#EC681A',
      opacity: 1,
      borderBottom: '4px solid #EC681A'
    },
    '& >.none-active-tab': {
      borderBottom: '4px solid #EFEFEF',
      color: '#B7B7B7'
    },
    '& >.tabbar-right': {
      width: '50%',
      height: '48px',
      float: 'left',
      textAlign: 'center',
      cursor: 'pointer'
    }
  },
  morePopover: {
    '& .MuiPopover-paper': {
      minWidth: 0,
      border: "1px solid rgb(196 196 196)",
    },
  },
  buttonPin: {
    padding: '8px 10px',
    color: "#EC681A",
    cursor: 'pointer',
    "&:hover":{
      backgroundColor: "#F7F7F7",
    },
  },
  buttonArchive: {
    padding: '8px 10px',
    color: "red",
    cursor: 'pointer',
    "&:hover":{
      backgroundColor: "#F7F7F7",
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: "space-between",
  },
  hiddenMobile: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  buttonMore:{
    borderRadius: 25,
    color: 'rgb(26, 7, 7)',
    padding: '11px 0',
    width: '45px',
    '& span': {
      font: '10px/12px Noto Sans JP, Hiragino Sans',
      color: '#1A0707',
      '& i': {
        color: 'rgb(183, 183, 183)',
      }
    },
    "&:hover":{
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      border: "1px solid #EC681A",
      '& span': {
        '& i': {
          color: '#EC681A',
        }
      },
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 45,
    }
  },
  marginIconButtonLeft: {
    marginLeft: '31px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '11px'
    }
  },
  marginIconButtonRight: {
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px'
    }
  },
  snackbarAnchor: {
    right: "0 !important"
  },
  snackbarRoot: {
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    boxShadow: "-2px 3px 6px #B7B7B799",
    borderLeft: "solid 4px #EC681A",
    padding: "32px 26px !important"
  },
  snackbarContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  checkIcon: {
    color: "#EC681A",
    fontSize: 32
  },
  closeIcon: {
    color: "#939393",
    fontSize: 14,
    cursor: "pointer",
    marginLeft: 20
  },
  snackbarRightContent: {
    marginLeft: 20,
    paddingRight: 20,
    borderRight: "solid 1px #F2EEEC"
  },
  labelTitle: {
    font: "400 16px/27px 'Noto Sans JP', sans-serif",
    letterSpacing: "1.92px",
    color: "#1A0707",
    margin: "0 0 13px 0"
  },
  labelContent: {
    font: "400 12px/27px 'Noto Sans JP', sans-serif",
    letterSpacing: "1.44px",
    color: "#796255",
    margin: 0
  }
}));

export const useFeedbackListStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 200,
    flexGrow: 1,
    overflow: 'hidden',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: '0'
    }
  },
  clNumber: {
    width: '92px',
    [theme.breakpoints.up('lg')]: {
      flexBasis: 110,
    },
    [theme.breakpoints.only('md')]: {
      flex: 1,
      minWidth: '47px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  clShop: {
    [theme.breakpoints.up('lg')]: {
      flexBasis: 240,
    },
    [theme.breakpoints.only('md')]: {
      flex: 1,
      width: '184px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  clBody: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      maxWidth: '100%',
      flex: 1,
    },
    [theme.breakpoints.only('md')]: {
      flex: 1,
      maxWidth: '100%'
    },
    minWidth: '305px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  clDate: {
    [theme.breakpoints.up('lg')]: {
      flexBasis: 140,
    },
    [theme.breakpoints.only('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  clAction: {
    [theme.breakpoints.up('lg')]: {
      flexBasis: 140,
    },
    [theme.breakpoints.only('md')]: {
      flexBasis: 55,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  cellCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  },
  pinButtonCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.only('md')]: {
      flex: 1,
      alignItems: 'flex-end',
      maxWidth: '4%'
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: 110,
    },
  },
  pinButton: {
    cursor: 'pointer',
    width: 13,
    height: 13,
  },
  rightBorder: {
    width: '158px',
    padding: 10,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      margin: '20px 0px',
      display: 'flex',
      alignItems: 'center',
      borderRight: '1px solid #B7B7B7',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: 140,
      padding: 0,
    },
    [theme.breakpoints.only('md')]: {
      flexBasis: 55,
    },
  },
  clButton: {
    '& div': {
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: 110,
    },
    [theme.breakpoints.only('md')]: {
      padding: '0px',
      maxWidth: '4%',
      flexBasis: 55,
    },
    [theme.breakpoints.down('sm')]: {
      // padding: '0 10px 20px 0',
      // width: '100%',
      // position: 'relative',
      // bottom: '0',
      // right: '0',
      // '& div': {
      //   textAlign: 'right'
      // }
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: 110,
    }
  },
  trNumber: {
    width: '92px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#1FA371',
    [theme.breakpoints.down('lg')]: {
      minWidth: '47px'
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: 110,
    },
    [theme.breakpoints.only('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      height: 80,
      lineHeight: '64px',
      padding: '0px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }
  },
  trShop: {
    [theme.breakpoints.up('lg')]: {
      flexBasis: 240,
    },
    width: '184px',
    margin: '20px 0px',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #B7B7B7',
    [theme.breakpoints.only('md')]: {
      padding: '10px 0',
    },
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      padding: '0 0 0 10px',
      margin: 0,
      width: '100%',
    }
  },
  trShopMobile: {
    width: '184px',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: '10px 3px 0 0',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0'
    }
  },
  trBody: {
    width: 'calc(100% - 592px)',
    margin: '20px 0px',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #B7B7B7',
    padding: '20px',
    minWidth:'305px',
    maxWidth: '100%',
    [theme.breakpoints.only('md')]: {
      padding: '10px',
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 30px',
      border: 'none',
      margin: '0px',
      borderTop: '1px solid #B7B7B7',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px',
    },
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      maxWidth: 'none',
    },
  },
  trDate: {
    width: '158px',
    margin: '20px 0px',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #B7B7B7',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: 140,
    },
    [theme.breakpoints.only('md')]: {
      flex: 1,
      width: '158px',
    },
  },
  trButton: {
    width: '158px',
    padding: '20px 0px'
  },
  thTable: {
    backgroundColor: '#F2EEEC',
    height: '36px',
    lineHeight: '36px',
    color: '#1A0707',
    fontSize: '12px',
    fontWeight: 'bold',
    overflow: 'hidden',
    boxShadow: '0px 1px 3px #00000029',
    [theme.breakpoints.down('sm')]: {
      height: '72px'
    }
  },
  Thborder: {
    borderRight: '1px solid #B7B7B7',
    font: '12px/18px Noto Sans JP, Hiragino Sans',
    letterSpacing: '0.3px',
    color: '#1A0707',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '10px 5px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
 // 年次改良2023NPS理由列にトグルボタン追加
 ThborderContainer: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.up('lg')]: {
    flex: 1,
    maxWidth: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column'
  }
},
ThborderLeft: {
  font: '12px/18px Noto Sans JP, Hiragino Sans',
  letterSpacing: '0.3px',
  color: '#1A0707',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  padding: '10px 5px',
  fontWeight: '600',
  [theme.breakpoints.up('md')]: {
    flex: 1
  },
  [theme.breakpoints.down('sm')]: {
    padding: '10px 5px 0px 5px'
  }
},
ThborderRight: {
  display:'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderRight: '1px solid #B7B7B7',
  font: '12px/18px Noto Sans JP, Hiragino Sans',
  letterSpacing: '0.3px',
  color: '#1A0707',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: '600',
  textAlign: 'right',
  [theme.breakpoints.up('md')]: {
    minWidth: '170px'
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
    borderRight: 'none',
    marginLeft: '71.203px',
  }
},
// 年次改良2023NPS理由列にトグルボタン追加 ここまで
  ThButton: {
    font: '12px/18px Noto Sans JP, Hiragino Sans',
    letterSpacing: '0.3px',
    color: '#1A0707',
    /* opacity: 1; */
    padding: '12px 14px',
    fontWeight: '600',
    [theme.breakpoints.down('md')]: {
      letterSpacing: '0.3px',
      color: '#1A0707',
      /* opacity: 1; */
      padding: '12px 5px',
      fontWeight: '600'
    }
  },
  reason: {
    padding: '10px 0',
    wordBreak:'break-all',
    [theme.breakpoints.down('sm')]: {
      letterSpacing: '0.25px',
      color: '#1A0707',
      opacity: 1,
      padding: '0'
    }
  },
  likeButtonBox: {
    paddingBottom: 5,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 2,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  Tdborder: {
    alignItems: 'center',
    font: '12px/24px Noto Sans JP, Hiragino Sans',
    letterSpacing: '0.3px',
    padding: '20px 10px',
    color: '#1A0707',
    opacity: '1',
    textAlign: 'left',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      font: '9px/24px Noto Sans JP, Hiragino Sans'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      border: 'none'
    },
  },
  tdTable: {
    textAlign: 'left',
    color: '#1A0707',
    marginTop: '10px',
    backgroundColor: 'white',
    boxShadow: '0px 3px 5px #b7b7b733',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px'
    }
  },
  score: {
    textAlign: 'center',
    font: 'Bold 45px Noto Sans JP',
    letterSpacing: '2.25px',
    color: '#FFFFFF',
    opacity: '1',
    [theme.breakpoints.down('md')]: {
      font: 'Bold 25px/45px Noto Sans JP',
      marginTop: '0px',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      font: 'Bold 30px Noto Sans JP',
      textAlign: 'center',
      letterSpacing: '1.9px',
      color: '#FFFFFF',
      opacity: '1'
    }
  },
  underScore: {
    height: 'auto',
    font: 'Bold 10px Noto Sans JP',
    letterSpacing: '0',
    color: '#FFFFFF',
    opacity: '1',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      font: 'Bold 6px Noto Sans JP',
      textAlign: 'center',
      lineHeight: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      font: 'Bold 10px/18px Noto Sans JP',
      textAlign: 'center',
      color: '#FFFFFF'
    }
  },
  user: {
    font: 'bold 16px Noto Sans JP, Hiragino Sans',
    letterSacing: '0.4px',
    color: '#1A0707',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      font: 'bold 14px Noto Sans JP, Hiragino Sans'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.down('xs')]: {
      font: 'bold 12px/25px Noto Sans JP, Hiragino Sans',
    }
  },
  shop: {
    fontSize: 14,
    letterSpacing: '0',
    color: '#1A0707',
  },
  shopWrap: {
    marginBottom: 3,
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
      maxWidth: 350,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    }
  },
  userWrap: {
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
      maxHeight: 20,
      maxWidth: 350,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    }
  },
  date: {
    font: '14px/30px Noto Sans JP, Hiragino Sans',
    letterSpacing: '0.35px',
    color: '#1A0707',
    opacity: '1',
    [theme.breakpoints.down('md')]: {
      font: '12px/16px Noto Sans JP, Hiragino Sans'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      font: '10px/14px Noto Sans JP, Hiragino Sans',
      letterSpacing: '0.25px',
      marginRight: '0',
      textAlign: 'right'
    }
  },
  hour: {
    font: '14px/16px Noto Sans JP, Hiragino Kaku Gothic Pro',
    letterSpacing: '0.35px',
    color: '#1A0707',
    opacity: '1',
    [theme.breakpoints.down('md')]: {
      font: '12px/16px Noto Sans JP, Hiragino Kaku Gothic Pro'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      font: '10px/16px Noto Sans JP, Hiragino Kaku Gothic Pro',
      letterSpacing: '0.25px',
      color: '#1A0707',
      opacity: 1,
      textAlign: 'right'
    }
  },
  btnPaddingComent: {
    padding: '20px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px 20px 0',
      width: '100%',
      position: 'relative',
      bottom: '0',
      right: '0',
      '& div': {
        textAlign: 'right',
        padding: '0 5px'
      }
    }
  },
  btnComment: {
    justifyContent: 'center',
    border: '1px solid #B7B7B7',
    width: '98px',
    height: '32px',
    borderRadius: '15px',

    [theme.breakpoints.down('md')]: {
      width: 'auto',
      padding: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0'
    },
    "& span>svg>path":{
      fill: "#ec681a",
    },
    "&:hover":{
      background: "white 0% 0% no-repeat padding-box",
    },
    '& span': {
      '& i': {
        width: '#EC681A',
        fontSize: '16px',
        color: '#EC681A',
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
          marginRight: '5px'
        },
        [theme.breakpoints.down('sm')]: {
          marginRight: '9px'
        }
      }
    },
    "& svg": {
      marginRight: '10px',
      [theme.breakpoints.down('md')]: {
        marginRight: '5px'
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
      padding: '5px 15px',
      borderRadius: '20px'
    }
  },
  btnCommentHover: {
    "&:hover":{
      background: "#EC681A 0% 0% no-repeat padding-box",
      border: "1px solid #EC681A"
    },
    "&:hover >span>i":{
      color: "#fff",
    },
    "&:hover >span>svg>path":{
      fill: "white",
      stroke: "white",
    },
    "&:hover >span>div":{
      background: "#fff 0% 0% no-repeat padding-box",
    },
  },
  scoreComment: {
    width: 'auto',
    height: 'auto',
    background: '#F7F7F7',
    borderRadius: '10px',
    opacity: 1,
    fontWeight: 'bold',
    padding: '0 10px',
    font: 'bold 10px/18px Noto Sans JP, Hiragino Kaku Gothic Pro',
    letterSpacing: '0.8px',
    color: '#1A0707',
    [theme.breakpoints.down('md')]: {
      font: 'bold 10px Noto Sans JP, Hiragino Kaku Gothic Pro',
      letterSpacing: '0.8px',
      color: '#1A0707',
      padding: '2px 8px',
      opacity: 1,
      width: 'auto',
      height: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      font: 'bold 10px Noto Sans JP, Hiragino Kaku Gothic Pro',
      letterSpacing: '0.8px',
      color: '#1A0707',
      opacity: 1,
      width: 'auto',
      padding: '5px 10px',
      height: 'auto'
    }
  },
  paginate: {
    width: '45px',
    height: '45px',
    lineHeight: '45px',
    textAlign: 'center',
    background: 'white',
    marginLeft: '5px',
    color: '#B7B7B7',
    fontSize: '10px',
    cursor: 'pointer',
    border: '0.5px solid #B7B7B7',
    [theme.breakpoints.down('md')]: {
      width: '30px',
      height: '30px',
      border: '0.5px solid #B7B7B7',
      lineHeight: '30px',
      textAlign: 'center',
      background: 'white',
      marginLeft: '5px',
      color: '#B7B7B7',
      fontSize: '10px',
      cursor: 'pointer'
    }
  },
  containerPagi: {
    margin: '20px auto'
  },
  prev: {
    margin: '0px 10px 0px 15px',
    [theme.breakpoints.down('md')]: {
      margin: '0px 10px 0px 5px'
    }
  },
  actionPaginate: {
    width: '45px',
    height: '45px',
    background: '#646464',
    lineHeight: '45px',
    textAlign: 'center',
    color: 'white',
    fontSize: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '30px',
      height: '30px',
      background: '#646464',
      lineHeight: '30px',
      textAlign: 'center',
      color: 'white',
      fontSize: '10px',
      cursor: 'pointer'
    }
  },
  next: {
    margin: '0px 5px 0px 10px'
  },
  mobileNone: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideDesktop: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  hideTablet: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  poiterTablet: {
    [theme.breakpoints.between('sm', 'md')]: {
      font: 'Bold 38px Noto Sans JP',
      marginTop: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
}));

export const useFeedbackListToolsStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%', // full viewport
    padding: '10px 0 20px',
    margin: '0',
    [theme.breakpoints.down('md')]: {
      padding: '20px 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0',
      flexDirection: 'column',
    }
  },
  buttonGroup: {
    marginLeft: 7,
    '& :hover': {
      color: '#fff',
      border: '1px solid #EC681A',
      backgroundColor: '#EC681A !important',
    },
    '& button': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #B7B7B7',
      borderRadius: '18px',
      letterSpacing: '0.25px',
      height: '36px',
      color: '#1A0707',
      font: '10px/16px Noto Sans JP, Hiragino Sans-serif',
      '& :hover': {
        border: 0,
      },
      '& span': {
        '& span': {
          '& svg': {
            color: '#B7B7B7',
            fontSize: '16px !important'
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  headerFeedback: {
    font: '22px/29px Noto Sans JP, Hiragino Kaku Gothic Pro',
    color: '#1A0707',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      font: '18px/29px Noto Sans JP, Hiragino Kaku Gothic Pro',
      color: '#646464',
      fontWeight: 'bold'
    },
    [theme.breakpoints.down('xs')]: {
      font: '18px/29px Noto Sans JP, Hiragino Kaku Gothic Pro',
      color: '#646464',
      fontWeight: 'bold'
    }
  },
  headerFeedbackSub: {
    font: '16px/51px Noto Sans JP, Hiragino Sans',
    letterSpacing: '0.4px',
    color: '#939393',
    paddingLeft: '10px',
    [theme.breakpoints.down('sm')]: {
      font: '12px/51px Noto Sans JP, Hiragino Sans',
      color: '#9A877D',
      fontWeight: 'bold',
      letterSpacing: '0.3px',
    },
    [theme.breakpoints.down('xs')]: {
      font: '12px Noto Sans JP, Hiragino Sans',
      fontWeight: 'bold',
      padding: '0 5px 0 5px',
    }
  }
}));

export const printTemplateStyles = makeStyles(theme => ({
  sectionToPrint: {
    width: '100%',
    padding: '35px 45px !important',
    fontFamily: "'Noto Sans JP', sans-serif !important",
    '-webkit-print-color-adjust': 'exact !important',
    colorAdjust: 'exact !important'
  },

  txtTop: {
    margin: '0 0 30px',
    fontSize: 12,
  },

  boxInfo: {
    display: 'flex',
    marginBottom: 15,

    '& p': {
      margin: 0
    },
  },

  divNumber: {
    height: 72,
    width: 72,
    marginRight: 15,
    transform: 'translateY(3px)',
    fontWeight: 'bold',
    fontSize: 32,
    color: 'white',
    textAlign: 'center',
    paddingTop: 5,

    '& > p': {
      fontSize: 10,
    }
  },

  feedbackDate: {
    letterSpacing: 1,
    marginTop: '18px !important',
  },

  tagTitle: {
    fontSize: 12,
    height: 48,
    width: 165,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '4px solid #EC681A'
  },

  questionTitle: {
    margin: '15px 0 10px 0',
    padding: '10px 15px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    pageBreakInside: 'avoid'
  },

  questionTitleButton: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center'
  },

  questionTitleText: {
    width: 'auto',
    wordBreak: 'break-all'
  },

  answer: {
    padding: '10px 30px',
    wordBreak: 'break-all',
  },

  colorRed: {
    fontSize: 10,
    color: 'white',
    padding: '2px 10px',
    borderRadius: 10,
    letterSpacing: 0.8,
    background: '#CB4944',
    marginLeft: 15,
    minWidth: 45,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },

  colorGreen: {
    fontSize: 10,
    color: 'white',
    padding: '2px 10px',
    borderRadius: 10,
    letterSpacing: 0.8,
    background: '#1FA371',
    marginLeft: 15,
    minWidth: 45,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },

  colorOrange: {
    fontSize: 10,
    color: 'white',
    padding: '2px 10px',
    borderRadius: 10,
    letterSpacing: 0.8,
    background: '#EC681A',
    marginLeft: 15,
    minWidth: 45,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },

  questionTag: {
    breakInside: 'avoid !important'
  },

  notDisplay: {
    display: 'none'
  }
}));

export const feedbackAnswerEditTagStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  content: {
    width: '70%',
    '& div': {
      width: '100%'
    },
    "& .MuiOutlinedInput-input":{
      padding: "10px 14px"
    },
    "& svg": {
      borderLeft: "1px solid",
      width: "40px",
      right: 0
    },
  },
  action: {
    width: '20%',
    textAlign: 'right',
    minWidth: '90px'
  },
  iconDelete: {
    width: "20px",
  }
}));
export const useFeedbackQuestionStyle = makeStyles(theme => ({
  rootContainer:{
    maxWidth:'100%',
  }
}))