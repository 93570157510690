import update from 'immutability-helper';

import {
  FETCH_SURVEY_BEGIN,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAILURE,
  FETCH_SURVEYS_BEGIN,
  FETCH_SURVEYS_SUCCESS,
  FETCH_SURVEYS_FAILURE,
  FETCH_ALL_SURVEYS_BEGIN,
  FETCH_ALL_SURVEYS_SUCCESS,
  FETCH_ALL_SURVEYS_FAILURE,
  ADD_SURVEY_PAGE,
  PAGE_ITEM_UPDATE,
  ADD_PAGE_SURVEY_HEADING,
  ADD_PAGE_QUESTION,
  SAVE_SURVEY_BEGIN,
  SAVE_SURVEY_FAILURE,
  SAVE_SURVEY_SUCCESS,
  UPDATE_SURVEY_ITEM,
  COPY_SURVEY_SUCCESS,
  FETCH_SURVEYS_RESET,
  SURVEY_PAGE_DELETE,
  CREATE_OR_UPDATE_SURVEY_DISPLAY_CONDITIONS,
  DELETE_SURVEY_DISPLAY_CONDITIONS,
  SWAP_QUESTION_ITEMS,
  UPDATE_PAGE_DISPLAY_CONDITIONS_ON_SHOPS,
} from "./actions";
import { parseSurvey, parseSurveyForList } from "../utils";

const getRandomString = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const DEFAULT_ITEM = {
  id: null,
  survey_pages_attributes: []
}

const initialState = {
  items: [],
  loading: false,
  loaded: false,
  error: null,
  pagination: {
    total: 0
  },

  item: DEFAULT_ITEM,
  itemLoading: false,
  itemLoaded: false,
  itemSaveError: {},
  itemError: null,
  itemSubmitting: false,
  itemSubmitSuccess: false,
  itemSubmitted: false,
  
  allItems: null,
  allItemsLoading: false,
  allItemsLoaded: false,
  allItemsError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_SURVEYS_BEGIN: {
      return {
        ...state,
        allItemsLoading: true,
        allItemsLoaded: false,
        allItemsError: null,
      };
    }

    case FETCH_ALL_SURVEYS_SUCCESS: {
      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: true,
        allItems: action.payload.items,
        pagination: action.payload.pagination,
      };
    }

    case FETCH_ALL_SURVEYS_FAILURE: {
      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: true,
        allItemsError: action.payload.error,
        allItems: [],
      };
    }

    case FETCH_SURVEYS_BEGIN: {
      return {
        ...state,
        loading: true,
        loaded: false,
        items: [],
        itemSubmitSuccess: false,
      };
    }

    case FETCH_SURVEYS_SUCCESS: {
      const { items, included } = action.payload
      return {
        ...state,
        loading: false,
        loaded: true,
        items: items.map(item => parseSurveyForList(item, included)),
        pagination: action.payload.pagination,
      };
    }

    case FETCH_SURVEYS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
        items: [],
      };
    }

    case FETCH_SURVEYS_RESET:
      return {
        ...state,
        loading: false,
        loaded: false,
        allItemsLoaded: false,
        allItemsLoading: false,
      };

    case SAVE_SURVEY_BEGIN: {
      return {
        ...state,
        itemSubmitting: true,
        itemSubmitSuccess: false,
        itemSaveError: {},
        itemSubmitted: false,
      };
    }

    case SAVE_SURVEY_SUCCESS: {
      return {
        ...state,
        item: parseSurvey(action.payload.survey),
        itemSubmitting: false,
        itemSubmitSuccess: true,
        newSurveyId: action.payload.newSurveyId,
        itemSaveError: {},
        itemSubmitted: true,
      };
    }

    case SAVE_SURVEY_FAILURE: {
      const { itemSaveError } = action.payload;
      return {
        ...state,
        itemSubmitting: false,
        itemSubmitSuccess: false,
        itemSaveError,
        itemSubmitted: true,
      };
    }

    case FETCH_SURVEY_BEGIN: {
      return {
        ...state,
        itemLoading: true,
        itemLoaded: false,
        itemError: null,
      };
    }
    case FETCH_SURVEY_SUCCESS: {
      return {
        ...state,
        itemLoading: false,
        itemLoaded: true,
        itemError: null,
        item: parseSurvey(action.payload.survey),
        newSurveyId: null,
      };
    }
    case FETCH_SURVEY_FAILURE: {
      return {
        ...state,
        itemLoading: false,
        itemLoaded: true,
        item: DEFAULT_ITEM,
        itemError: action.payload.error,
      };
    }
    case UPDATE_SURVEY_ITEM: {
      const { item } = action.payload;

      return {
        ...state,
        item: Object.keys(item).length > 0 ? { id: item.id, survey_pages_attributes: [] } : DEFAULT_ITEM,
        itemSubmitSuccess: false,
        itemSaveError: {},
        itemSubmitted: false,
      };
    }
    case ADD_SURVEY_PAGE: {
      const { spIndex } = action.payload;

      if (spIndex === -1) {
        state.item.survey_pages_attributes = update(
          state.item.survey_pages_attributes,
          {
            $push: [{
              id: null,
              question_items: [],
              survey_page_display_conditions_attributes: [],
              discarded_at: null,
            }],
          }
        );
      } else {
        state.item.survey_pages_attributes = update(
          state.item.survey_pages_attributes,
          {
            $splice: [[spIndex + 1, 0, {
              id: null,
              question_items: [],
              survey_page_display_conditions_attributes: [],
              discarded_at: null,
            }]]
          },
        );
      }

      state.item.survey_pages_attributes = state.item.survey_pages_attributes.map((page, i) => {
        page.page_number = i + 1;
        return page;
      });

      return state;
    }

    case ADD_PAGE_QUESTION: {
      const {spIndex, question} = action.payload;
      question.uid = getRandomString();

      if (!!!state.item.survey_pages_attributes[spIndex].question_items) {
        state.item.survey_pages_attributes[spIndex].question_items = [];
      }

      state.item.survey_pages_attributes[spIndex].question_items.push(question);

      for (let i = 0; i < state.item.survey_pages_attributes[spIndex].question_items.length; i ++) {
        state.item.survey_pages_attributes[spIndex].question_items[i].display_order = i + 1;
      }

      return {
        ...state,
        item: {
          ...state.item,
          survey_pages_attributes: [
            ...state.item.survey_pages_attributes,
          ]
        }
      };
    }

    case ADD_PAGE_SURVEY_HEADING: {
      const {spIndex, survey_heading} = action.payload;

      if (!!!state.item.survey_pages_attributes[spIndex].question_items) {
        state.item.survey_pages_attributes[spIndex].question_items = [];
      }

      state.item.survey_pages_attributes[spIndex].question_items.push(survey_heading);

      for (let i = 0; i < state.item.survey_pages_attributes[spIndex].question_items.length; i ++) {
        state.item.survey_pages_attributes[spIndex].question_items[i].display_order = i + 1;
      }

      return {
        ...state,
        item: {
          ...state.item,
          survey_pages_attributes: [
            ...state.item.survey_pages_attributes,
          ]
        }
      };
    }

    case PAGE_ITEM_UPDATE: {
      const { spIndex, piIndex, updatedItem } = action.payload;
      const currentPageItem = state.item.survey_pages_attributes[spIndex].question_items[piIndex];

      state.item.survey_pages_attributes[spIndex].question_items[piIndex] = { ...currentPageItem, ...updatedItem };

      return state;
    }

    case SURVEY_PAGE_DELETE: {
      const { spIndex } = action.payload;
      const before_page = state.item.survey_pages_attributes[spIndex - 1];
      const delete_page = state.item.survey_pages_attributes[spIndex];
      const before_page_items_count = before_page.question_items.length;

      for (let i = 0; i < delete_page.question_items.length; i++) {
        const item = delete_page.question_items[i];
        item.before_survey_page_id = item.before_survey_page_id || delete_page.id;
        item.display_order = before_page_items_count + i + 1;
        before_page.question_items.push(item);
      }

      delete_page._destroy = 1;
      delete_page.question_items = [];

      return state;
    }

    case COPY_SURVEY_SUCCESS: {
      return {
        ...state,
        loading: false,
        newSurveyId: (parseSurvey(action.payload.survey) || {}).id
      }
    }

    case CREATE_OR_UPDATE_SURVEY_DISPLAY_CONDITIONS: {
      const { spIndex, survey_page_id, question_id, question_option_id, checked } = action.payload;

      state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes =
        state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes || [];

      let found = false;

      for (let cpIndex = 0; cpIndex < state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes.length; cpIndex ++) {
        const item = state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes[cpIndex];
        if (item.survey_page_id === survey_page_id
          && item.question_id === question_id
          && item.question_option_id === question_option_id
          && ((checked && item._destroy === 1) || (!checked && [null, undefined].includes(item._destroy)))
        ) {
          found = true;
          state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes[cpIndex]._destroy = checked ? null : 1;
          break;
        }
      }

      if (!found && checked) {
        state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes.push({
          survey_page_id,
          question_id,
          question_option_id,
        });
      }

      return state;
    }

    case DELETE_SURVEY_DISPLAY_CONDITIONS: {
      const { spIndex } = action.payload;

      state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes = state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes.map(item => ({
        ...item,
        _destroy: 1,
      }));

      return state;

    }

    case UPDATE_PAGE_DISPLAY_CONDITIONS_ON_SHOPS:
      const { survey_page_id, area_id, shop_id, checked, spIndex } = action.payload
      let conditions = state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes || []
      const newCondition = { survey_page_id, area_id, shop_id, _destroy: checked ? null : 1 }

      let isFound = false
      for (let i = 0; i < conditions.length; i++) {
        const item = conditions[i]
        if (+item.survey_page_id === +survey_page_id && +item.area_id === +area_id && +item.shop_id === +shop_id) {
          newCondition.id = item.id
          conditions[i] = newCondition
          isFound = true
          break
        }
      }
      if (!isFound) {
        conditions.push(newCondition)
      }
      state.item.survey_pages_attributes[spIndex].survey_page_display_conditions_attributes = conditions
      return state

    case SWAP_QUESTION_ITEMS: {
      const { spIndex1, piIndex1, spIndex2, piIndex2 } = action.payload;

      const tmpQuestionItem = state.item.survey_pages_attributes[spIndex1].question_items[piIndex1];

      if (tmpQuestionItem != null) {
        state.item.survey_pages_attributes[spIndex1].question_items.splice(piIndex1, 1);
        state.item.survey_pages_attributes[spIndex2].question_items.splice(piIndex2, 0, tmpQuestionItem);

        if (!!!tmpQuestionItem.before_survey_page_id) {
          tmpQuestionItem.before_survey_page_id = state.item.survey_pages_attributes[spIndex1].id;
        }

        state.item.survey_pages_attributes[spIndex1].question_items = state.item.survey_pages_attributes[spIndex1].question_items.map((item, index) => {
          item.display_order = index + 1;
          return item;
        });
  
        state.item.survey_pages_attributes[spIndex2].question_items = state.item.survey_pages_attributes[spIndex2].question_items.map((item, index) => {
          item.display_order = index + 1;
          return item;
        });
  
        return {
          ...state,
          item: {
            ...state.item,
            survey_pages_attributes: state.item.survey_pages_attributes.map(
              item => ({
                ...item,
                question_items: item.question_items.map(item => ({...item}))
              })
            ),
          },
          submitting: false,
        };
      }

      return state;
    }

    default:
      return state;
  }
};
