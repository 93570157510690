import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import { thunkExportItems } from "common/csv/redux/thunk";
import ImportDialog from "common/csv/ImportDialog";
import { ExportButton, ImportButton } from "common/csv/CsvButton";
import UserList from "./UserList";
import UserForm from "./UserForm";
import { thunkFetchUsers } from "./redux/thunk";
import { UPDATE_USER_ITEM } from "./redux/actions";
import IconFilter from 'assets/icons/icon_filter.svg';
import IconFilterActive from 'assets/icons/icon_filter_active.svg';
import { usersThemedStyles } from "./styles";


const useStyles = makeStyles(usersThemedStyles);

const propTypes = {
  total: PropTypes.number.isRequired,
  itemsLoading: PropTypes.bool.isRequired,
  itemsLoaded: PropTypes.bool.isRequired,
  fetchUsers: PropTypes.func,
  exportCsv: PropTypes.func.isRequired,
  updateUserItem: PropTypes.func.isRequired,
};

const Users = ({
  total,
  itemsLoading,
  itemsLoaded,
  fetchUsers,
  exportCsv,
  updateUserItem,
}) => {
  const [page, setPage] = useState(1);
  const [showInactiveItems, setShowInactiveItems] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const classes = useStyles();

  const [name, setName] = useState('');
  const firstTimeRefresh = useRef(true);

  const getSearchParams = (forcedKw=null) => {
    const searchParams = {};
    if (showInactiveItems) searchParams.status = "with_inactive";
    if (forcedKw !== null) searchParams.q = forcedKw;
    else if (!!name) searchParams.q = name;

    return searchParams;
  }

  const fetchData = () => {
    fetchUsers(page, getSearchParams());
  }

  const handleNameChange = ({ target: { value } }) => {
    setName(value);
  };

  useEffect(() => {
    if (firstTimeRefresh.current === false) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [page, showInactiveItems]);

  useEffect(() => {
    if ((!itemsLoading && !itemsLoaded) || firstTimeRefresh.current === true) {
      fetchUsers();
      firstTimeRefresh.current = false;
    }
    // eslint-disable-next-line
  }, [itemsLoading, itemsLoaded]);

  const openDrawer = (item) => {
    setDrawerIsOpen(true);
    updateUserItem(item);
  }

  const handleClearSearch = () => {
    setName('');
    fetchUsers(page, getSearchParams(''));
  };

  return (
    <>
      <Grid className={!showInactiveItems ? classes.buttonShowAc : `${classes.buttonShowAc}  ${classes.buttonShowAcActive}`}>
        <div className="blockSearch">
          <div className="icon">
            <img
              src={(name.length > 0) ? IconFilterActive : IconFilter}
              className={classes.filterIcon}
              onClick={handleClearSearch}
              alt={''}
            />
          </div>
          <div className="formSearch">
            <div>
              <TextField
                name="name"
                placeholder="キーワード検索"
                variant="outlined"
                onChange={handleNameChange}
                className={classes.textBox}
                value={name}
              />
            </div>
            <div className="formIcon" onClick={ () => fetchData() }>
              <i className="material-icons">search</i>
            </div>
          </div>
        </div>
        <div className="buttonShowStore" onClick={() => setShowInactiveItems(!showInactiveItems)}>
          <div className="circle">
            <div />
          </div>
          停止中のスタッフを表示
        </div>
      </Grid>
      <Grid className={classes.blockTotal}>
        <div className="txtTotal">
          <p>{total}名</p>
        </div>
        <div className={"buttons"}>
          <ExportButton text="スタッフエクスポート" onClick={() => exportCsv(getSearchParams())} />
          <ImportDialog path={"users"} onSuccess={fetchUsers} title='スタッフインポート（CSV）'>
            <ImportButton text="スタッフインポート（CSV）"/>
          </ImportDialog>
        </div>
      </Grid>
      <UserList openModal={openDrawer} page={page} setPage={setPage}/>
      <UserForm modalOpen={drawerIsOpen} closeModal={() => setDrawerIsOpen(false)} />
    </>
  )
}

Users.propTypes = propTypes;

export default connect(
  ({ users: { loading, loaded, pagination: { total } } }) => ({
    itemsLoading: loading,
    itemsLoaded: loaded,
    total: +(total),
  }),
  dispatch => ({
    exportCsv: (params) => dispatch(thunkExportItems("users", params)),
    fetchUsers: (page=null, searchParams) => dispatch(thunkFetchUsers(page, searchParams)),
    updateUserItem: (item) => dispatch({ type: UPDATE_USER_ITEM, payload: { item } } ),
  })
)(Users);
