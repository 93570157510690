export const inputModalStyles = theme => ({
  root: {
    overflow: "auto",
    display: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    }
  },
  paper: props => ({
    position: "absolute",
    width: 295,
    transform: props.isAndroid ? 'translate(-50%, -30%) !important' : 'none',
    backgroundColor: theme.palette.background.paper,
    padding: 5,
    display: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    }
  })
});
