import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LikeIcon from '../../../../common/icon/LikeIcon'
import clsx from 'clsx'
import Popover from '@material-ui/core/Popover'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  button: {
    border: '1px solid #B7B7B7',
    width: '98px',
    height: '32px',
    borderRadius: '15px',
    '& .MuiButton-label': {
      alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      padding: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
    '& span>svg>path': {
      fill: '#ec681a',
    },
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: 'white 0% 0% no-repeat padding-box',
      },
    },
    '& span': {
      '& i': {
        width: '#EC681A',
        fontSize: '16px',
        color: '#EC681A',
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
          marginRight: '5px',
        },
        [theme.breakpoints.down('sm')]: {
          marginRight: '9px',
        },
      },
    },
    '& svg': {
      marginRight: '10px',
      [theme.breakpoints.down('md')]: {
        marginRight: '5px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5px 15px',
    },
  },
  likeCount: {
    width: 'auto',
    height: 'auto',
    background: '#F7F7F7',
    borderRadius: '10px',
    opacity: 1,
    fontWeight: 'bold',
    padding: '0 10px',
    font: 'bold 10px/18px Noto Sans JP, Hiragino Kaku Gothic Pro',
    color: '#1A0707',
    [theme.breakpoints.down('md')]: {
      font: 'bold 10px Noto Sans JP, Hiragino Kaku Gothic Pro',
      padding: '2px 8px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5px 10px',
    }
  },
  buttonHighlight: {
    background: '#EC681A 0% 0% no-repeat !important',
    border: '1px solid #EC681A',
    '& span>i': {
      color: '#fff',
    },
    '& span>svg>path': {
      fill: 'white',
      stroke: 'white',
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: 140,
    border: '1px solid #B7B7B7',
  },
  hover: {
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: '#EC681A 0% 0% no-repeat padding-box',
        border: '1px solid #EC681A',
      },
      '&:hover >span>svg>path': {
        fill: 'white',
        stroke: 'white',
      },
      '&:hover >span>div': {
        background: '#fff 0% 0% no-repeat padding-box',
      },
    },
  }
}))

const LikeButton = ({ onClick, likes_count = 0, highlight, onHover, likes, likeBegin }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
    if (!isMobile) {
      onHover && onHover()
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const onButtonClick = e => {
    e.stopPropagation()
    onClick && onClick()
  }

  return (
    <div>
      <Button
        variant="outlined"
        disabled={likeBegin}
        className={clsx({
          [classes.button]: true,
          [classes.hover]: !isMobile,
          [classes.buttonHighlight]: !!highlight,
        })}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={onButtonClick}>
        <LikeIcon size={16} />
        <div className={classes.likeCount}>{likes_count}</div>
      </Button>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open && !!(likes && likes.length > 0)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        {likes && likes.map((like, i) => <div key={i}>{like}</div>)}
        {likes_count > 20 && <div>その他{+likes_count - 20}名</div>}
      </Popover>
    </div>
  )
}

export default LikeButton
