import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Box,
  Paper,
  CircularProgress
} from '@material-ui/core';
import Pagination from 'components/Pagination';
import { PAGINATION_PER_PAGE } from '../constants';
import { makeStyles } from '@material-ui/core/styles';
import { themedStyles } from "./styles";
import { format } from 'date-fns';
import ConfirmationDialog from 'common/ConfirmationDialog';
import { useGAEffect } from '../../../ga';


const useStyles = makeStyles(themedStyles);

const propTypes = {
  items: PropTypes.array,
  showInactiveItems: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  openDrawer: PropTypes.func.isRequired,
  page: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  deactivate: PropTypes.func.isRequired,
  userAuthority: PropTypes.string,
  ga: PropTypes.object.isRequired,
};

const ProjectList = ({
  items,
  showInactiveItems,
  loading,
  pagination,
  openDrawer,
  page,
  setPage,
  deactivate,
  userAuthority,
  ga
}) => {
  const classes = useStyles();
  const [showRefresh] = useState(false);
  const isSuperAdmin = userAuthority === "super_admin";

  useGAEffect(ga, null, "プロジェクト一覧")

  const handleClick = (v) => {
    setPage(v)
  }

  if (loading) {
    return (
      <Paper className={classes.root}>
        <div className={classes.loading}>
          <CircularProgress disableShrink />
        </div>
      </Paper>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.blockTable}>
          <Grid item>
            <Box
              display={{ sm: "block", xs: "block", md: "block", lg: "block" }}
            >
              <Grid container item className={classes.thTable} sm={12} md={12}>
                {showRefresh &&
                  <Grid sm={1} md={1} xs={1} item>
                    <Box className={classes.Thborder}>復旧</Box>
                  </Grid>
                }
                <Grid sm={1} md={1} xs={1} item>
                  <Box className={classes.Thborder}>ID</Box>
                </Grid>
                <Grid sm={2} md={2} xs={2} item>
                  <Box className={classes.Thborder}>作成日</Box>
                </Grid>
                <Grid
                  sm={showRefresh ? 3 : 4}
                  md={showRefresh ? 3 : 4}
                  xs={showRefresh ? 3 : 4}
                  item
                >
                  <Box className={classes.Thborder}>プロジェクト名</Box>
                </Grid>
                <Grid sm={2} md={2} xs={2} item>
                  <Box className={classes.Thborder}>アンケート数</Box>
                </Grid>
                <Grid sm={2} md={2} xs={2} item>
                  <Box className={classes.Thborder}>FB数</Box>
                </Grid>
                <Grid
                  sm={1}
                  md={1}
                  xs={1}
                  item
                  className={classes.borderRight0}
                >
                  <Box className={classes.Thborder}></Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {(items || []).filter(item => !showInactiveItems ? !!!item.discarded_at : true).map((item, index) => (
            <ProjectItem
              key={`project-item-${index}`}
              classes={classes}
              item={item}
              showRefresh={showRefresh}
              openDrawer={openDrawer}
              deactivate={deactivate}
              isSuperAdmin={isSuperAdmin}
            />
          ))}
        </div>
      </div>
      <Pagination
        activePage={page}
        itemsCountPerPage={+pagination.perPage}
        pageRangeDisplayed={pagination.offset}
        totalItemsCount={+pagination.total}
        offset={pagination.offset}
        onChange={handleClick}
      />
    </div>
  );
};

ProjectList.propTypes = propTypes;

const ProjectItem = ({ classes, showRefresh, item, openDrawer, deactivate, isSuperAdmin }) => {
  const openExpandRow = () => {
    setOpen(true)
  }
  const closeExpandRow = () => {
    setOpen(false)
  }
  const [open, setOpen] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);

  const onCloseDeactivateModal = () => {
    setDeactivateModal(false)
  }

  const onConfirmDeactivateModal = () => {
    setOpen(false)
    onCloseDeactivateModal()
    deactivate(item.id);
  }

  const openModalComfirm = () => {
    setDeactivateModal(true)
  }

  return (
    <Grid
      container
      item
      className={classes.tdTable}
      sm={12}
      display={{ sm: "none", xs: "none", md: "block", lg: "block" }}
      key={item.name}
      onClick={() => openDrawer(item) }
    >
      {showRefresh &&
        <Grid sm={1} xs={1} md={1} item className={classes.trNumber}>
          <Box
            className={`${classes.Tdborder} ${classes.poiterTablet}`}
          >
            <i className="material-icons">refresh</i>
          </Box>
        </Grid>
      }

      <Grid sm={1} xs={1} md={1} item className={classes.tdTableGird}>
        <Box className={`${classes.Tdborder} ${classes.contentId}`}>
          {item.id}
        </Box>
      </Grid>
      <Grid sm={2} xs={2} md={2} item className={classes.tdTableGird}>
        <Box
          className={classes.Tdborder}
        >
          <p className={classes.date}>{format(new Date(item.created_at), "yyyy/MM/dd")}</p>
          <p className={classes.time}>{format(new Date(item.created_at), "HH:mm")}</p>
        </Box>
      </Grid>
      <Grid
        sm={showRefresh ? 3 : 4}
        xs={showRefresh ? 3 : 4}
        md={showRefresh ? 3 : 4}
        className={classes.tdTableGird}
        item
      >
        <Box className={classes.Tdborder}>{item.name}</Box>
      </Grid>
      <Grid sm={2} xs={2} md={2} className={classes.tdTableGird} item>
        <Box className={classes.Tdborder}>{item.survey_count}</Box>
      </Grid>
      <Grid sm={2} xs={2} md={2} className={classes.tdTableGird} item>
        <Box className={classes.Tdborder}>{item.feedback_count}</Box>
      </Grid>
      {isSuperAdmin && !!!item.discarded_at && (open ? (
        <div className={classes.expandRow}>
          <div onClick={(e) => {e.stopPropagation(); openModalComfirm(); }}>
            <i className="el-icon-receiving"></i>
            アーカイブ
          </div>
          <i
            className="material-icons"
            onClick={(e) => {e.stopPropagation(); closeExpandRow(); }}
          >
            more_vert
          </i>
        </div>
        ) : (
        <Grid sm={1} xs={1} md={1} className={classes.tdTableGird} item>
          <Box className={classes.Tdborder}>
            <i
              className="material-icons"
              onClick={(e) => {e.stopPropagation(); openExpandRow(); }}
            >
              more_vert
            </i>
          </Box>
        </Grid>
      ))}

      <ConfirmationDialog
        show={deactivateModal}
        onCancel={(e) => { e.stopPropagation(); onCloseDeactivateModal(); }}
        title='アーカイブ確認'
        text='アーカイブしてもよろしいでしょうか？'
        onConfirm={ (e) => {e.stopPropagation(); onConfirmDeactivateModal(); }}
      />
    </Grid>
  );
};

export default connect(
  ({ projects: { items, loading, pagination }, global: { userData }, ga }) => ({
    items,
    loading,
    pagination: !!pagination ? pagination : {
      offset: 0,
      total: PAGINATION_PER_PAGE,
      perPage: PAGINATION_PER_PAGE,
    },
    userAuthority: userData.authority,
    ga,
  }),
)(ProjectList);
