import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Paper, Typography, CircularProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { format } from 'date-fns';
import FeedbackCommentInput from './FeedbackCommentInput';
import {
  deleteFeedbackComment,
  thunkDislikeComment,
  thunkFetchCommentLikes,
  thunkLikeComment
} from './redux/thunk';
import ElementIcon from "common/icon/ElementIcon";
import { CLEAR_ERROR } from "./redux/actions/comment";
import { useFeedbackCommentListStyle } from './styles'
import LikeButton from "../dashboard/goodvocs/LikeButton";


const propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  feedback: PropTypes.object,
  deleteComment: PropTypes.func.isRequired,
  clearError: PropTypes.func,
  commentId: PropTypes.string,
  loading: PropTypes.bool,
}

const FeedbackCommentList = ({ comments, feedback, likes, likeBegin, deleteComment, clearError, commentId, loading, likeComment, dislikeComment, fetchLikes }) => {
  const didMount = useRef(false);
  const classes = useFeedbackCommentListStyle();
  const [commentToDelete, setCommentToDelete] = useState(null);

  useEffect(() => {
    if (didMount.current && !!commentId) {
      scrollToComment(commentId);
    } else {
      didMount.current = true;
      clearError();
    }
  }, [clearError, comments, commentId]);

  const removeComment = () => {
    if (!!feedback && !!commentToDelete) {
      deleteComment(feedback.id, commentToDelete);
    }
    setCommentToDelete(null)
  }

  const scrollToComment = commentId => {
    const comment = document.getElementById(commentId);

    if (!!comment) {
      comment.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const closeModal = () => setCommentToDelete(null)
  const openModal = id => setCommentToDelete(id)

  const likeIt = (comment_id, feedback_comment_like_id) => {
    if (feedback_comment_like_id === null)
      likeComment(feedback.id, comment_id)
    else
      dislikeComment(feedback.id, comment_id, feedback_comment_like_id)
  }

  if (loading) {
    return <CircularProgress disableShrink />
  }

  return (
    <div className={classes.content}>
      <div className={classes.boxScroll}>
        {comments.map((comment, i) => (
          <Paper className={classes.boxComment} id={comment.id} key={i} >
            <div>
              <Typography variant='inherit' className={classes.nameComment}>
                {comment.user.name}
              </Typography>
              <Typography variant='inherit' className={classes.date}>
                {format(new Date(comment.created_at), "yyyy/MM/dd HH:mm")}
              </Typography>
              <div className='content'>{comment.body}</div>
            </div>
            <div style={{float: 'left', marginTop: 10}}>
              <LikeButton likes_count={comment.likes_count} onHover={() => fetchLikes(comment.id)}
                          likes={likes} likeBegin={likeBegin}
                          highlight={!!comment.feedback_comment_like_id}
                          onClick={() => likeIt(comment.id, comment.feedback_comment_like_id)}/>
            </div>
            <div className={classes.txtRight}>
              <Button
                variant='outlined'
                className={classes.btnDel}
                color='secondary'
                onClick={e => openModal(comment.id)}
                startIcon={<ElementIcon name={`delete`} />}
              >
                削除
              </Button>
            </div>
          </Paper>
        ))}
      </div>
      <FeedbackCommentInput onCommentAdded={scrollToComment} />
      <Dialog open={!!commentToDelete} onClose={closeModal}>
        <DialogContent>
          <DialogContentText>コメントを削除しますか</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={removeComment} color="secondary">OK</Button>
          <Button variant="outlined" onClick={closeModal}>キャンセル</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

FeedbackCommentList.propTypes = propTypes;

const mapState = ({ feedbacks, feedbackComments }) => ({
  feedback: feedbacks.feedback,
  likeBegin: feedbackComments.likeBegin,
  likes: feedbackComments.likes,
  comments: feedbackComments.items,
  loading: feedbackComments.loading,
});

const mapDispatch = dispatch => ({
  fetchLikes: comment_id => dispatch(thunkFetchCommentLikes(comment_id)),
  deleteComment: (feedbackId, commentId) => dispatch(deleteFeedbackComment(feedbackId, commentId)),
  likeComment: (feedbackId, comment_id) => dispatch(thunkLikeComment(feedbackId, comment_id)),
  dislikeComment: (feedbackId, comment_id, like_id) => dispatch(thunkDislikeComment(feedbackId, comment_id, like_id)),
  clearError: () => dispatch({ type: CLEAR_ERROR }),
});

export default connect(
  mapState,
  mapDispatch,
)(FeedbackCommentList);
