import React from "react"

const LikeIcon = ({size = 22}) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} viewBox="0 0 50 50">
        <path d="m 2.0520231,44.067197 c 0.00109,0.503889 0.5061163,0.93896 1.0100163,0.941419 m 0,0 L 16,45 c 0.55078,-3.67e-4 1,-0.445312 1,-1 v -1 h 1.15625 c 0.414063,1.164063 1.539063,2 2.84375,2 h 19 c 2.207031,0 4,-1.792969 4,-4 0,-0.824219 -0.261719,-1.582031 -0.6875,-2.21875 C 44.871094,38.234375 46,36.742188 46,35 46,34.019531 45.648438,33.132813 45.0625,32.4375 46.222656,31.738281 47,30.453125 47,29 47,28.019531 46.648438,27.132813 46.0625,26.4375 47.222656,25.738281 48,24.453125 48,23 48,20.792969 46.207031,19 44,19 H 29.375 C 29.984375,17.070313 31,13.617188 31,12 31,8.234375 28.242188,3 25.53125,3 H 23 c -0.550781,0 -1,0.449219 -1,1 v 7.75 l -5,10 -0.07225,-0.125723 C 16.6533,21.146743 16.550781,21 16,21 H 3 C 2.714844,21 2.441406,21.128906 2.25,21.34375 2.058594,21.558594 1.964844,21.839844 2,22.125 L 2.052023,44.067197 M 24,5 h 1.53125 C 26.449219,5 29,8.480469 29,12 29,13.566406 27.601563,18.082031 27.0625,19.6875 26.957031,19.992188 27,20.332031 27.1875,20.59375 27.375,20.855469 27.679688,21 28,21 h 16 c 1.101563,0 2,0.898438 2,2 0,1.101563 -0.898437,2 -2,2 h -1 c -0.554687,0 -1,0.445313 -1,1 0,0.554688 0.445313,1 1,1 1.101563,0 2,0.898438 2,2 0,1.101563 -0.898437,2 -2,2 h -1 c -0.554687,0 -1,0.445313 -1,1 0,0.554688 0.445313,1 1,1 1.101563,0 2,0.898438 2,2 0,1.101563 -0.898437,2 -2,2 h -2 c -0.554687,0 -1,0.445313 -1,1 0,0.554688 0.445313,1 1,1 1.101563,0 2,0.898438 2,2 0,1.101563 -0.898437,2 -2,2 H 21 c -0.550781,0 -1,-0.449219 -1,-1 0,-0.554687 -0.449219,-1 -1,-1 H 17 V 24 h 0.5 c 0.378906,0 0.734375,-0.222656 0.90625,-0.5625 l 5.5,-11 C 23.976563,12.300781 24,12.15625 24,12 Z M 4.125,23 c -2.75,18 -1.375,9 0,0 z M 10,39.5 c -6.6666667,7 -3.3333333,3.5 0,0 z"
        />
        <ellipse
            ry="1.2"
            rx="1.2"
            cy="40"
            cx="12"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              stroke: "#ffffff",
              strokeOpacity: 1,
              strokeWidth: 2,
            }}
        />
      </svg>
  )
}

export default LikeIcon
