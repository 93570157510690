import React from "react";
import { makeStyles, Modal } from "@material-ui/core";


const top = 50;
const left = 50;

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`
};

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    backgroundColor: "white",
    padding: "44px 20px",
    borderTop: "2px solid #EC681A"
  }
}));

const NewItemModal = ({ open, onClose, children, className = null}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle} className={className || classes.root}>
        {children}
      </div>
    </Modal>
  );
};

export default NewItemModal;
