import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles'
import { customStyles } from './styles'

const useStyles = makeStyles(customStyles)

export default () => {
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  const handleClose = () => setOpen(false)
  if (open) {
    return (
      <Dialog open={open} onClose={handleClose} className={classes.warningContainer}>
        <div className={classes.warningContentBox}>
          <DialogContent>
            <DialogContentText>{'最初にプロジェクトを選択してください'}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {'閉じる'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }
  return null
}
