import {
  CLEAR_RANKS,
  DISLIKE_GOODVOCS_FEEDBACK,
  FETCH_DASHBOARD_BEGIN,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_GOODVOCS_BEGIN,
  FETCH_GOODVOCS_SUCCESS,
  FETCH_RANKS_SUCCESS,
  LIKE_GOODVOCS_FEEDBACK,
  PIN_GOODVOCS_FEEDBACK,
  SELECT_COMMON_RANK_DROPDOWN,
  SET_COMMON_RANK_TRIM30,
  SELECT_USER_RANK_DROPDOWN,
} from './actions'
import { updateFeedbacks } from '../../feedbacks/redux/reducers/feedback'
import { LIKE_FEEDBACK_BEGIN } from '../../feedbacks/redux/actions/feedback'

const initialState = {
  nps_satisfactions: {
    comment_likes_count: 0,
    comments_count: 0,
    detractors: '0.0',
    feedback_count: 0,
    items: [],
    likes_count: 0,
    nps_score: '0.0',
    passives: '0.0',
    promoters: '0.0',
    promoters_count: 0,
    passives_count: 0,
    detractors_count: 0,
  },
  goodvocs: {},
  group: {},
  shop: {},
  user: {},
  ranking_trim30: {},
}

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_BEGIN:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          loading: true,
          error: null,
        },
      }
    case FETCH_GOODVOCS_BEGIN:
      return {
        ...state,
        goodvocs: {
          loading: true,
          error: null,
          items: [],
        },
      }
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        [action.payload.name]: {
          loading: false,
          error: null,
          ...action.payload.data,
        },
      }
    case FETCH_GOODVOCS_SUCCESS:
      const items = [...state.goodvocs.items];
      (action.payload.feedbacks || []).forEach(newOne => {
        if (!state.goodvocs.items.find(i => i.id === newOne.id)) {
          items.push(newOne)
        }
      })
      return {
        ...state,
        goodvocs: {
          loading: false,
          error: null,
          items,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
        },
      }
    case PIN_GOODVOCS_FEEDBACK:
      return {
        ...state,
        goodvocs: {
          loading: false,
          error: null,
          items: state.goodvocs.items.map(i =>
            i.id === action.payload.feedback.id ? action.payload.feedback : i
          ),
        },
      }
    case LIKE_FEEDBACK_BEGIN:
      return {
        ...state,
        goodvocs: {
          ...state.goodvocs,
          likeBegin: true,
        },
      }
    case LIKE_GOODVOCS_FEEDBACK:
      return {
        ...state,
        goodvocs: {
          ...state.goodvocs,
          likeBegin: false,
          items: updateFeedbacks(state.goodvocs, action.payload, +1).items,
        },
        nps_satisfactions: {
          ...state.nps_satisfactions,
          likes_count: state.nps_satisfactions.likes_count + 1
        }
      }
    case DISLIKE_GOODVOCS_FEEDBACK:
      return {
        ...state,
        goodvocs: {
          ...state.goodvocs,
          likeBegin: false,
          items: updateFeedbacks(state.goodvocs, action.payload, -1).items,
        },
        nps_satisfactions: {
          ...state.nps_satisfactions,
          likes_count: state.nps_satisfactions.likes_count - 1
        }
      }
    case FETCH_RANKS_SUCCESS:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          loading: false,
          error: null,
          ...action.payload.data,
        },
      }
    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          loading: false,
          error: action.payload.error,
        },
      }
    case SELECT_COMMON_RANK_DROPDOWN:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          selected: action.payload.selected,
        },
      }
    case SET_COMMON_RANK_TRIM30:
      return{
        ...state,
        ranking_trim30: {
          ...state.ranking_trim30,
          [action.payload.name]: action.payload.value
        },
      }

    case SELECT_USER_RANK_DROPDOWN:
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.name]: action.payload.selected,
        },
      }
    case CLEAR_RANKS:
      return {
        ...state,
        group: {
          selected: state.group.selected,
        },
        shop: {
          selected: state.shop.selected,
        },
        user: {
          group: state.user.group,
          shop: state.user.shop,
        },
        ranking_trim30: {},
      }
    default:
      return state
  }
}
