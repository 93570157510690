
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormHelperText,
  makeStyles,
  Typography
} from '@material-ui/core';
import { QuestionTypeTextInputStyles } from './styles';
import HtmlRender from './HtmlRender';

const useStyles = makeStyles(QuestionTypeTextInputStyles);

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onInputValue: PropTypes.func.isRequired,
};

const QuestionTypeTextInput = ({
  questionItem,
  answer,
  errors,
  onInputValue,
}) => {
  const classes = useStyles();
  
  const textChangeHandler = ({ target: { value } }) => {
    onInputValue(questionItem, { answer_entry: value });
  };

  const textHasError = !!errors.answer_entry;

  return (
    <>
      <Typography className={classes.text}>
        <HtmlRender
          value = {questionItem.body}
        />
        {questionItem.is_required && (
          <span className="required">必須</span>
        )}
      </Typography>

      {!!questionItem.question_image_url && (
        <div className={classes.img}>
          <img src={questionItem.question_image_url} alt={''}/>
        </div>
      )}

      <input
        defaultValue={answer.answer_entry || ""}
        className={classes.textInput}
        onChange={textChangeHandler}
        type={"text"}
        placeholder={"入力してください。"}
      />

      {textHasError && (
        <FormHelperText error={true}>{errors.answer_entry.map(err => err)}</FormHelperText>
      )}
    </>
  );
}

QuestionTypeTextInput.propTypes = propTypes;

export default QuestionTypeTextInput;
