import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components'
import Select, { components } from 'react-select'
import { Button, Popover } from '@material-ui/core';
import ImportDialog from 'common/csv/ImportDialog';
import ExportDialog from 'common/csv/ExportDialog';
import { thunkFetchFeedbacks } from './redux/thunk';
import { Storage } from '../../../utils';
import {
  thunkExportFeedbacks,
  thunkExportSurveyQuestions
} from 'common/csv/redux/thunk';
import { useFeedbackListToolsStyle } from './styles'
import ElementIcon from "../../../common/icon/ElementIcon";
import { SET_FEEDBACK_SORT_OPTIONS } from "./redux/actions/feedback";

const propTypes = {
  totalFeedbacks: PropTypes.number,
  fetchFeedbacks: PropTypes.func.isRequired,
  exportFeedbacks: PropTypes.func.isRequired,
  user: PropTypes.object,
  searchParams: PropTypes.object,
  key: PropTypes.number,
};

export const SORT_KEYS = {
  nps_score: { label: '愛用度', value: 'nps_score'},
  shop_user: { label: '担当スタッフ/商品', value: 'shop_user'},
  created_at: { label: '回答日時', value: 'created_at'},
  likes_count: { label: 'フィードバックのいいね数', value: 'likes_count'},
  comment_likes_count: { label: 'コメントいいね数', value: 'comment_likes_count'},
  comments_count: { label: 'コメント数', value: 'comments_count'},
  pinned: { label: 'ピン留め', value: 'pinned'},
}

export const SORT_ORDERS = {
  asc: { label: '昇順', value: 'asc' },
  desc: { label: '降順', value: 'desc' },
}

const Item = styled.div`
  cursor: pointer;
  padding: 10px;
  font: 500 10px/16px Noto Sans JP, Hiragino Sans;
  width: 240px;
  height: 37px;
  :hover {
    background-color: lightgray;
  }
  i {
    margin-right: 10px;
    font-size: 14px;
  }
`

const DropdownIndicator = props => {
  const { DropdownIndicator } = components
  return (
      <DropdownIndicator {...props}>
        <ElementIcon name="caret-bottom" />
      </DropdownIndicator>
  )
}

const FeedbackListTools = ({
  totalFeedbacks,
  sort_key,
  sort_val,
  question_id,
  fetchFeedbacks,
  exportFeedbacks,
  user,
  exportSurveyQuestions,
  searchParams,
  setSortOptions,
  question_filters,
}) => {
  const [exportBtnEnabled, setExportBtnEnabled] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMorePopover = event => setAnchorEl(event.currentTarget)
  const closeMorePopover = () => setAnchorEl(null)
  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    setExportBtnEnabled(true);

    // eslint-disable-next-line
  }, [searchParams['user_ids[]']])
  const {
    container,
    buttonGroup,
    headerFeedback,
    headerFeedbackSub
  } = useFeedbackListToolsStyle();

  const isAdmin = user.authority === "super_admin" || user.authority === "admin";
  const exportFunction = (e, params) => {
    setExportBtnEnabled(false);
    exportFeedbacks({ ...params, question_id, sort_key, sort_val, ...question_filters })
  }

  const onSortKeyChange = newSortKey => {
    setSortOptions({ sort_key: newSortKey.value })
  }

  const onSortOrderChange = newSortOrder => {
    setSortOptions({ sort_val: newSortOrder.value })
  }

  return (
    <div className={container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className={headerFeedback}>{`${totalFeedbacks}件`}</span>
        <span className={headerFeedbackSub}>のフィードバックがあります</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        <div>
          <Select
              isSearchable={false}
              components={{ DropdownIndicator }}
              options={Object.values(SORT_KEYS)}
              onChange={onSortKeyChange}
              value={SORT_KEYS[sort_key]}
              styles={{
                control: props => ({
                  ...props,
                  width: 120,
                })
              }}
          />
        </div>
        <div style={{ marginLeft: 7 }}>
          <Select
              isSearchable={false}
              components={{ DropdownIndicator }}
              options={Object.values(SORT_ORDERS)}
              onChange={onSortOrderChange}
              value={SORT_ORDERS[sort_val]}
              styles={{
                control: props => ({
                  ...props,
                  width: 90,
                })
              }}
          />
        </div>
        <div className={buttonGroup}>
          <Button variant='outlined' onClick={openMorePopover}>...</Button>
        </div>
      </div>
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closeMorePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div style={{ border: '1px solid lightgrey', borderRadius: 3 }}>
          <Item onClick={exportSurveyQuestions}><ElementIcon name={`download`}/>質問表エクスポート</Item>
          {user.fb_exportable === "true" && (
              <ExportDialog disabled={!exportBtnEnabled} exportFunction={exportFunction} onClose={closeMorePopover}>
                <Item><ElementIcon name={`download`}/>フィードバックエクスポート</Item>
              </ExportDialog>
          )}
          {isAdmin && (
              <ImportDialog path='feedbacks' onSuccess={() => fetchFeedbacks({ sort_key, sort_val, question_id })} onClose={closeMorePopover} title='フィードバックインポート（CSV）'>
                <Item><ElementIcon name={`upload2`}/>フィードバックインポート（CSV）</Item>
              </ImportDialog>
          )}
        </div>
      </Popover>
    </div>
  );
}

FeedbackListTools.propTypes = propTypes;

export default connect(
  ({
    feedbacks: { pagination: { total }, sort_key, sort_val, questions = {}, question_filters },
    global: { userData, searchParams }
  }) => ({
    totalFeedbacks: +total,
    user: userData,
    searchParams,
    sort_key,
    sort_val,
    question_id: questions.selected,
    question_filters,
  }),
  dispatch => ({
    setSortOptions: params => dispatch({ type: SET_FEEDBACK_SORT_OPTIONS, payload: { params } }),
    fetchFeedbacks: params => dispatch(thunkFetchFeedbacks(1, { ...Storage.getSearchParams(), ...params })),
    exportFeedbacks: params => dispatch(thunkExportFeedbacks({ ...Storage.getSearchParams(), 'user_ids[]': null, ...params })),
    exportSurveyQuestions: () => dispatch(thunkExportSurveyQuestions(Storage.getProjectData().value, Storage.getSurveyData().value)),
  })
)(FeedbackListTools);
