import { makeStyles } from "@material-ui/core";

const distanceLeft = 10;

export const treeHeaderButtonsStyle = makeStyles(theme => ({
  header: {
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: `calc(70% - ${distanceLeft * 2}px)`,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: `0 ${distanceLeft}px`,
      flexWrap: "wrap-reverse"
    }
  },
  headerRight: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 20
    }
  },
  toggleContainer: {
    height: 36,
    padding: "0 12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    letterSpacing: "0.3px",
    color: "#B7B7B7"
  },
}));