export const parseShop = ({
  id,
  attributes,
  relationships = { users: { data: [] }, shops_good_mail_users: { data: [] }, shops_bad_mail_users: { data: [] } }
}) => {
  const { users, shops_good_mail_users, shops_bad_mail_users } = relationships;

  return {
    id: id,
    ...attributes,
    user_ids: users.data.map(user => user.id),
    shops_good_mail_user_ids: shops_good_mail_users.data.map(user => user.id),
    shops_bad_mail_user_ids: shops_bad_mail_users.data.map(user => user.id),
  }
};

export const getShopFromIncluded = ({ id, included }) => {
  let itemData = null;
  
  for (let item of included) {
    if (item.id === id) {
      itemData = item;
      break;
    }
  }

  return itemData === null ? {} : parseShop(itemData);
};
