import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { themeDataTableStyles } from "./styles"
import clsx from "clsx"

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  rowHeader: PropTypes.string,
  rowNames: PropTypes.arrayOf(PropTypes.string),
  rowKeys: PropTypes.arrayOf(PropTypes.string),
  pivot: PropTypes.bool,
}

const useStyles = makeStyles(themeDataTableStyles);

const Row = styled(TableRow)`
  && {
    box-shadow: 0 1px 3px rgb(200, 200, 200);
  }
`

const DataTable = ({ data=[], rowHeader, rowNames=[], rowKeys=[], pivot=false, unitSurfix, rowWithSurfix }) => {
  const { cell, table, root, headerTable, Tbody, firstCell } = useStyles();

  if (pivot) {
    [data, rowNames] = [rowNames, data]
  }

  return (
    <Paper className={root}>
      <Table className={table} aria-label="caption table" id="datatable">
        <TableHead>
          <Row className={headerTable}>
            <TableCell className={clsx(cell, firstCell)} id={"datatable-headerCol"}>{rowHeader}</TableCell>
            {data.map((item, i) => 
              <TableCell key={i} className={cell} align="left">{pivot ? item : item.label}</TableCell>
            )}
          </Row>
        </TableHead>
        <TableBody className={Tbody}>
          {rowNames.map((name, i) => (
            <Row key={i}>
              <TableCell className={clsx(cell, firstCell)}>{pivot ? (name.label || name.body) : name}</TableCell>
              {pivot ? rowKeys.map((rowKey, j) => 
                <TableCell key={j} className={cell} align="left">{name[rowKey]} {rowWithSurfix === j ? unitSurfix : ''}</TableCell>)
              : data.map((item, j) =>
              <TableCell key={j} className={cell} align="left">{item[rowKeys[i]]} {rowWithSurfix <= i ? unitSurfix : ''}</TableCell>
              )}
            </Row>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

DataTable.propTypes = propTypes;

export default DataTable;
