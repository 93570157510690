import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobileOnly } from 'react-device-detect'
import { Tab, Tabs } from '@material-ui/core'
import { FeedbackItem, FlexColumnSeparator, NpsItem } from './RankItems'
import { themeRankStyles } from './styles'

const useStyles = makeStyles(themeRankStyles)

const Tab01 = ({ data, classes }) => (
  <div className={classes.rankColumnContainer}>
    {data.map((item, i) => (
      <NpsItem
        key={i}
        index={i}
        rank={data.map(v => v.nps_score).indexOf(item.nps_score) + 1}
        score={item.nps_score}
        name={item.name}
        classes={classes}
      />
    ))}
  </div>
)

const Tab02 = ({ classes, data }) => (
  <div className={classes.rankColumnContainer}>
    {data.map((item, i) => (
      <FeedbackItem
        key={i}
        index={i}
        rank={data.map(v => v.feedback_count).indexOf(item.feedback_count) + 1}
        count={item.feedback_count}
        name={item.name}
        classes={classes}
      />
    ))}
  </div>
)

export const Mobile = ({ tab1, tab2 }) => {
  const glass = useStyles()
  const [tabIndex, setTabIndex] = React.useState(0)

  const onChange = (e, index) => {
    e.preventDefault()
    setTabIndex(index)
  }

  const tabProps = index => ({
    id: `simple-tab-${index}`,
    className: tabIndex === index ? glass.tabActive : glass.tab,
  })

  return (
    <>
      <Tabs value={tabIndex} className={glass.tabs} onChange={onChange}>
        <Tab label="PXスコア TOP 30" {...tabProps(0)} />
        <Tab label="回答数 TOP 30" {...tabProps(1)} />
      </Tabs>
      <div className={glass.mobileContainer}>{tabIndex === 0 ? <>{tab1}</> : <>{tab2}</>}</div>
    </>
  )
}

export const RankBody = ({ nps_top5 = [], feedback_top5 = [], classes = {} }) => {
  if (nps_top5.length === 0 && feedback_top5.length === 0)
    return null

  if (isMobileOnly)
    return (
      <Mobile
        tab1={<Tab01 classes={classes} data={nps_top5} />}
        tab2={<Tab02 classes={classes} data={feedback_top5} />}
      />
    )

  return (
    <div>
      <div className={classes.rankContainer}>
        <div className={classes.columnHeader}>PXスコア TOP 30</div>
        <FlexColumnSeparator />
        <div className={classes.columnHeader}>回答数 TOP 30</div>
      </div>
      <div className={classes.rankContainer}>
        <Tab01 classes={classes} data={nps_top5} />
        <FlexColumnSeparator />
        <Tab02 classes={classes} data={feedback_top5} />
      </div>
    </div>
  )
}
