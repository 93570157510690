import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  button: props => ({
    color: '#EC681A',
    backgroundColor: 'white',
    fontWeight: 'normal',
    border: '1px solid #C7C7C7',
    padding: '10px 25px',
    marginLeft: props.isFold ? 0 : 20,
    marginTop: props.isFold ? 18 : 0,
    width: props.isMobileOnly ? '100%' : 'inherit',
  }),
}))

const ReloadButton = ({ isFold, onReload, disabled }) => {
  const classes = useStyles({ isFold, isMobileOnly })

  const onClick = () => {
    onReload && onReload()
  }

  return (
    <div style={{ display: 'inline-block', width: isMobileOnly ? '100%' : 'inherit' }}>
      <Button disabled={disabled} className={classes.button} onClick={onClick}>
        ランキングを取得する
      </Button>
    </div>
  )
}

export default ReloadButton
