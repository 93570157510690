import IconNPS from 'assets/icons/icon_nps.svg';
import IconNPSActive from 'assets/icons/icon_nps_active.svg';
import IconTextarea from 'assets/icons/icon_textarea.svg';
import IconPulldown from 'assets/icons/icon_pulldown.svg';
import IconPulldownActive from 'assets/icons/icon_pulldown_active.svg';
import IconRadio from 'assets/icons/icon_radio.svg';
import IconRoundedSquare from 'assets/icons/icon_rounded_square.svg';
import IconRoundedSquareActive from 'assets/icons/icon_rounded_square_active.svg';
import IconCheckbox from 'assets/icons/icon_checkbox.svg';
import IconCheckboxActive from 'assets/icons/icon_checkbox_active.svg';
import IconCalender from 'assets/icons/icon_calendar_light.svg';
import IconCalenderActive from 'assets/icons/icon_calendar_active.svg';
import IconPhoto from 'assets/icons/icon_photo.svg';
import IconSelectBtn from 'assets/icons/icon_select_btn.svg';
import IconSelectNum from 'assets/icons/icon_select_num.svg';

export const QUESTION_TYPE_SELECT = "type_select";
export const QUESTION_TYPE_RADIO_BUTTON = "type_radio_button";
export const QUESTION_TYPE_SELECT_BUTTON = "type_select_button";
export const QUESTION_TYPE_CHECK_BOX = "type_check_box";
export const QUESTION_TYPE_NPS_QUESTION = "type_nps_question";
export const QUESTION_TYPE_NPS_REASON = "type_nps_reason";
export const QUESTION_TYPE_TEXT_FIELD = "type_text_field";
export const QUESTION_TYPE_TEXT_AREA = "type_text_area";
export const QUESTION_TYPE_DATE = "type_date";
export const QUESTION_TYPE_SELECT_USER = "type_select_user";
export const QUESTION_TYPE_FILE_UPLOAD = "type_upload";

export const QUESTION_TYPE_SELECT_LABEL = '単一選択（プルダウン）';
export const QUESTION_TYPE_RADIO_BUTTON_LABEL = '単一選択（ラジオボタン）';
export const QUESTION_TYPE_SELECT_BUTTON_LABEL = '単一選択（ボタン）';
export const QUESTION_TYPE_CHECK_BOX_LABEL = '複数選択（チェックボックス）';
export const QUESTION_TYPE_NPS_QUESTION_LABEL = '愛用度設問';
export const QUESTION_TYPE_NPS_REASON_LABEL = '愛用度理由';
export const QUESTION_TYPE_TEXT_FIELD_LABEL = '自由記入（1行）';
export const QUESTION_TYPE_TEXT_AREA_LABEL = '自由記入（複数行）';
export const QUESTION_TYPE_DATE_LABEL = "日付";
export const QUESTION_TYPE_SELECT_USER_LABEL = "スタッフ名";
export const QUESTION_TYPE_FILE_UPLOAD_LABEL = "画像アップロード";

export const QUESTION_TYPE_SELECT_ICON = IconPulldown;
export const QUESTION_TYPE_SELECT_ICON_ACTIVE = IconPulldownActive;
export const QUESTION_TYPE_RADIO_BUTTON_ICON = IconRadio;
export const QUESTION_TYPE_SELECT_BUTTON_ICON = IconRoundedSquare;
export const QUESTION_TYPE_SELECT_BUTTON_ICON_ACTIVE = IconRoundedSquareActive;
export const QUESTION_TYPE_CHECK_BOX_ICON = IconCheckbox;
export const QUESTION_TYPE_CHECK_BOX_ICON_ACTIVE = IconCheckboxActive;
export const QUESTION_TYPE_NPS_QUESTION_ICON = IconNPS;
export const QUESTION_TYPE_NPS_QUESTION_ICON_ACTIVE = IconNPSActive;
export const QUESTION_TYPE_NPS_REASON_ICON = IconTextarea;
export const QUESTION_TYPE_TEXT_FIELD_ICON = IconTextarea;
export const QUESTION_TYPE_TEXT_AREA_ICON = IconTextarea;
export const QUESTION_TYPE_DATE_ICON = IconCalender;
export const QUESTION_TYPE_DATE_ICON_ACTIVE = IconCalenderActive;
export const QUESTION_TYPE_SELECT_USER_ICON = IconPulldown;
export const QUESTION_TYPE_FILE_UPLOAD_ICON = IconPhoto;

export const DEFAULT_MIN_SELECTION_COUNT = 0;
export const DEFAULT_MAX_SELECTION_COUNT = 1;
export const DEFAULT_MIN_CHARACTER_COUNT = 0;
export const DEFAULT_MAX_CHARACTER_COUNT = 3000;
export const DEFAULT_LINE_NUMBER = 3;

export const SURVEY_FORM_TYPE_SURVEY_HEADING = "survey_heading"

export const QUESTION_OPTIONS = [
  { value: QUESTION_TYPE_SELECT, label: QUESTION_TYPE_SELECT_LABEL, icon: QUESTION_TYPE_SELECT_ICON, activeIcon: QUESTION_TYPE_SELECT_ICON_ACTIVE },
  { value: QUESTION_TYPE_RADIO_BUTTON, label: QUESTION_TYPE_RADIO_BUTTON_LABEL, icon: QUESTION_TYPE_RADIO_BUTTON_ICON },
  { value: QUESTION_TYPE_SELECT_BUTTON, label: QUESTION_TYPE_SELECT_BUTTON_LABEL, icon: QUESTION_TYPE_SELECT_BUTTON_ICON, activeIcon: QUESTION_TYPE_SELECT_BUTTON_ICON_ACTIVE },
  { value: QUESTION_TYPE_CHECK_BOX, label: QUESTION_TYPE_CHECK_BOX_LABEL, icon: QUESTION_TYPE_CHECK_BOX_ICON, activeIcon: QUESTION_TYPE_CHECK_BOX_ICON_ACTIVE },
  { value: QUESTION_TYPE_NPS_QUESTION, label: QUESTION_TYPE_NPS_QUESTION_LABEL, icon: QUESTION_TYPE_NPS_QUESTION_ICON, activeIcon: QUESTION_TYPE_NPS_QUESTION_ICON_ACTIVE },
  { value: QUESTION_TYPE_NPS_REASON, label: QUESTION_TYPE_NPS_REASON_LABEL, icon: QUESTION_TYPE_NPS_REASON_ICON },
  { value: QUESTION_TYPE_TEXT_FIELD, label: QUESTION_TYPE_TEXT_FIELD_LABEL, icon: QUESTION_TYPE_TEXT_FIELD_ICON },
  { value: QUESTION_TYPE_TEXT_AREA, label: QUESTION_TYPE_TEXT_AREA_LABEL, icon: QUESTION_TYPE_TEXT_AREA_ICON },
  { value: QUESTION_TYPE_DATE, label: QUESTION_TYPE_DATE_LABEL, icon: QUESTION_TYPE_DATE_ICON, activeIcon: QUESTION_TYPE_DATE_ICON_ACTIVE },
  { value: QUESTION_TYPE_SELECT_USER, label: QUESTION_TYPE_SELECT_USER_LABEL, icon: QUESTION_TYPE_SELECT_USER_ICON },
  { value: QUESTION_TYPE_FILE_UPLOAD, label: QUESTION_TYPE_FILE_UPLOAD_LABEL, icon: QUESTION_TYPE_FILE_UPLOAD_ICON },
];

export const FREE_ENTRY_VALIDATION_NO_LIMIT = "valid_no_limit";
export const FREE_ENTRY_VALIDATION_KATAKANA = "valid_katakana";
export const FREE_ENTRY_VALIDATION_HIRAGANA = "valid_hiragana";
export const FREE_ENTRY_VALIDATION_ALPHANUMERIC = "valid_alphanumeric";
export const FREE_ENTRY_VALIDATION_NUMERIC = "valid_numeric";
export const FREE_ENTRY_VALIDATION_NUMERIC_SYMBOL = "valid_numeric_symbol";

export const FREE_ENTRY_VALIDATION_OPTIONS = [
  { value: FREE_ENTRY_VALIDATION_NO_LIMIT, label: "制限なし" },
  { value: FREE_ENTRY_VALIDATION_KATAKANA, label: "全角カナ" },
  { value: FREE_ENTRY_VALIDATION_HIRAGANA, label: "全角かな" },
  { value: FREE_ENTRY_VALIDATION_ALPHANUMERIC, label: "半角英数" },
  { value: FREE_ENTRY_VALIDATION_NUMERIC, label: "半角数字" },
  { value: FREE_ENTRY_VALIDATION_NUMERIC_SYMBOL, label: "数字記号" },
];

export const SELECT_BUTTON_OPTIONS =[
  { value: true, label: "数字を表示", icon: IconSelectNum　},
  { value: false, label: "数字を表示しない", icon: IconSelectBtn　}
];

export const ALIGN_OPTIONS = [
  { value: "vertical", name: "縦並び" },
  { value: "horizontal", name: "横並び" },
];

export const QUESTION_LABEL_MAP = {
  [QUESTION_TYPE_SELECT]: QUESTION_TYPE_SELECT_LABEL,
  [QUESTION_TYPE_RADIO_BUTTON]: QUESTION_TYPE_RADIO_BUTTON_LABEL,
  [QUESTION_TYPE_SELECT_BUTTON]: QUESTION_TYPE_SELECT_BUTTON_LABEL,
  [QUESTION_TYPE_CHECK_BOX]: QUESTION_TYPE_CHECK_BOX_LABEL,
  [QUESTION_TYPE_NPS_QUESTION]: QUESTION_TYPE_NPS_QUESTION_LABEL,
  [QUESTION_TYPE_NPS_REASON]: QUESTION_TYPE_NPS_REASON_LABEL,
  [QUESTION_TYPE_TEXT_FIELD]: QUESTION_TYPE_TEXT_FIELD_LABEL,
  [QUESTION_TYPE_TEXT_AREA]: QUESTION_TYPE_TEXT_AREA_LABEL,
  [QUESTION_TYPE_DATE]: QUESTION_TYPE_DATE_LABEL,
  [QUESTION_TYPE_SELECT_USER]: QUESTION_TYPE_SELECT_USER_LABEL,
  [QUESTION_TYPE_FILE_UPLOAD]: QUESTION_TYPE_FILE_UPLOAD_LABEL,
};

export const QUESTION_TYPE_LIMIT_MAP = {
  [QUESTION_TYPE_SELECT_BUTTON]: 11,
  [QUESTION_TYPE_NPS_QUESTION]: 11,
};
