import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Paper,
  Button,
  Checkbox,
  CircularProgress,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import { authenticate } from '../redux/thunk';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
//import { companyConstraints } from 'modules/companies/validations';
// import ForgotPassword from "./forgotPassword";

import imgLogo from '../../../assets/logo.svg';
//icon import (Material UI)
import ApartmentIcon from '@material-ui/icons/Apartment';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import { loginStyles } from './styles';

//なるべく統一された変数名に　「useStyles」
//moduleは理由がない限り withStylesより　「makeStyles」を,
//import { useTheme, makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(loginStyles);

const propTypes = {
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.array,
  authenticated: PropTypes.bool,
  history: PropTypes.object,
  authenticate: PropTypes.func.isRequired,
  isProductSurvey: PropTypes.bool,
} 

const Login = ({ loading, errors, authenticated, history, authenticate, isProductSurvey }) => {
  const [saveSession, setSaveSession] = useState(true);
  const [account_id, setAccountId] = useState('');
  const [login_id, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const classes = useStyles();

  //Componetsを利用する際、Themeを流用できるものはthemeを参照して利用
  //themeの中身を変数として使えるために定義
  //const theme = useTheme();

  useEffect(() => {
    if (authenticated) {
      isProductSurvey? 
        history.push('/admin/products/feedbacks')
      : history.push('/admin/feedbacks');
    }
  }, [authenticated, history, isProductSurvey]);

  const handleSubmit = (e) => {
    e.preventDefault();
    authenticate({
      saveSession,
      account_id,
      login_id,
      password,
    }, history);
    // setSaveSession(true);
    // setLoginId('');
    // setAccountId('')
    setPassword('');
  };

  // handleShowFogotPassword() {
  //   if (!this.state.showForgotPassword) {
  //     this.setState({
  //       showForgotPassword: true
  //     });
  //   }
  // }

  if (loading) {
    return (
      <Paper className={classes.root}>
        <CircularProgress disableShrink />
      </Paper>
    );
  }
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={3}
      className={classes.blockLogin}
    >
      <Grid item align="center">
        <img src={imgLogo} alt=""/>
      </Grid>
      {/* {!this.state.showForgotPassword ? ( */}
      <div className={classes.popupLogin}>
        <Paper align="center" className={classes.formLogin}>
          <span className={classes.labelLogin}>ログイン</span>
          <form onSubmit={handleSubmit}>
            {(errors || []).map((err, index) => (
              <span key={`err-${index}`} style={{color: "red"}}> {err} </span>
            ))}
            <Grid item sm={12} className={classes.someGridStyle}>
              <div className={classes.inputField}>
                <input
                  type="text"
                  placeholder="アカウントID"
                  value={account_id}
                  onChange={e => setAccountId(e.target.value)}
                />
                <div className='bg-brown'>
                  <ApartmentIcon className={classes.customIcon} />
                </div>
              </div>
            </Grid>

            <Grid item sm={12} className={classes.someGridStyle}>
              <div className={classes.inputField}>
                <input
                  type="text"
                  placeholder="ログインID"
                  autoComplete="user-name"
                  value={login_id}
                  onChange={e => setLoginId(e.target.value)}
                />
                <div>
                  <LockOutlinedIcon className={classes.customIcon} />
                </div>
              </div>
            </Grid>

            <Grid item sm={12} className={classes.someGridStyle}>
              <div className={classes.inputField}>
                <input
                  placeholder="パスワード"
                  autoComplete="current-password"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <div>
                  <VpnKeyOutlinedIcon className={classes.customIcon} />
                </div>
              </div>
            </Grid>

            <Grid item sm={12} className={classes.blockCheckbox}>
              <FormGroup row>
                <FormControlLabel
                    control={
                      <Checkbox
                      checked={saveSession}
                      onChange={e => setSaveSession(e.target.checked)}
                      value={'saveSession'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    }
                    label="ログインを維持"
                />
              </FormGroup>
            </Grid>
            
            <Grid item sm={12}>
              <Button
                color="primary"
                type="submit"
                disabled={false}
                variant="contained"
                className={classes.submitBtn}
              >
                ログイン
              </Button>
            </Grid>

           
          </form>
        </Paper>
        {/* <div item sm={12} className={classes.forgotPassword}>
          <a href="#" onClick={() => this.handleShowFogotPassword()}>
              パスワードをお忘れの場合
            </a>
          <a href="#">パスワードをお忘れの場合</a>
        </div> */}
      </div>
      {
        // ) : (
        //   <ForgotPassword />
        // )}
      }
    </Grid>
  );
};

Login.propTypes = propTypes;

export default connect(
  ({ authentication: { loading, errors, authenticated } , global:{selectedCompany}}) => ({
    loading,
    errors,
    authenticated,
    isProductSurvey:selectedCompany.survey_type==="1",
  }),
  dispatch => {
    return {
      authenticate: (params) => dispatch(authenticate(params))
    };
  }
)(withRouter(Login));
