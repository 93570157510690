import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  breakpoints: {
    keys: [
      'xs',
      'sm',
      'md',
      'mdf',
      'lg',
      'xl',
    ],
    values:{ 
      xs: 0,
      sm: 640,
      md: 768,
      mdf: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  R14:{fontSize: '0.875rem', fontWeight: 300,},
  R12:{fontSize: '0.75rem', fontWeight: 300,},
  M16:{fontSize: '0.875rem', fontWeight: 500,},
  M12:{fontSize: '0.75rem', fontWeight: 500,},
  palette: {
    common: {
      black: '#1A0707',
      white: '#fff',
    },
    primary: {
      main: '#EC681A',
      active: '#EC681A',
      disable: '#b7b7b7',
      contrastText: '#ffffff',
      light: '#796255',
      dark: 'rgba(236, 104, 26, 0.7)',
    },
    secondary: {
      main: '#ff0000',
      Promoter: '#1FA371',
      Passive: '#FFCE00',
      Detractor: '#F02544',
    },
    brown: {
      Light: '#F2EEEC',
      Default: '#9A877D',
      Dark: '#796255',
    },
    info: {
      main: '#F2EEEC',
    },
    error: {
      main: '#F02544',
    },
    background: {
      paper: '#fff',
      default: '#F7F7F7',
    },
    grayscale: {
      100: '#F7F7F7',
      200: '#EFEFEF',
      300: '#B7B7B7',
      400: '#939393',
    },
    text: {
      primary: '#1A0707',
      active: '#EC681A',
      disabled: '#b7b7b7',
    }
  },
  notchedOutline:{
    default: '#ff0000',
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
    fontWeight: 300,    
    fontFamily: [
    'Noto Sans JP',
    'Hiragino Sans',
    'ヒラギノ角ゴシック',
    'Hiragino Kaku Gothic ProN',
    'ヒラギノ角ゴ ProN W3',
    'YuGothic',
    '游ゴシック',
    'Meiryo',
    "メイリオ",
    "ＭＳ Ｐゴシック",
    'sans-serif',
    'Noto Sans CJK JP'
    ].join(','),
    h5: {
      fontSize: '1.5625rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 'inherit',
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
  },
  spacing: factor => [0, 4, 16, 20, 30, 42, 72][factor],
  shadows: Array(25).fill('none'),
  overrides: {
    MuiButton: {
      root:{
        borderRadius: 5,
      },
      primary: {
        '& :hover':{
          backgroundColor: '#1FA371'
        }
      },
      text: {
        color: 'white',
        lineHeight: '1em',
        fontSize: '0.875rem',
        fontWeight: 600,
      },
     },
     MuiTextField: {
       root: {
        text:{
          color: '#ff0000',
          fontSize: '0.875rem',
        }
       }
     },
     MuiInputBase: {
       root: {
        text: {
          font: 14,
        }
       }
     },
     MuiListItemText: {
       root:{
        marginTop: 0,
        marginBottom: 0,
       },
       primary:{
        fontSize: '0.75rem',
        fontWeight: 'inherit',
       },
       secondary:{
        fontSize: '0.625rem',
        fontWeight: 300,
        color: 'inherit',
        overflowWrap: 'break-word',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
       },
     },
     MuiPopover: {
      paper:{
        boxShadow: '3px 0px 5px 0px rgba(183,183,183,0.2)',
        minWidth: 240,
      }
     },
     MuiListItem:{
       button:{
        '&:hover':{
          backgroundColor: '#F2EEEC',
        },
       },
       gutters:{
         paddingLeft: 20,
       }
     },
     MuiListItemIcon: {
       root:{
         minWidth: 14,
       }
     },
     MuiDrawer:{
       docked:{
         '& .MuiDrawer-paperAnchorDockedLeft':{
           borderRight: '1px solid #F2EEEC',
         }
       }
     }
  },
});

export default theme;
