import { API_URL } from '../../../constants';
import qs from 'query-string';
import { default as axios } from 'axios'; 
import {
  actionFetchGroupsBegin,
  actionFetchGroupsSuccess,
  actionFetchGroupsFailure,
  actionFetchAllGroupsBegin,
  actionFetchAllGroupsSuccess,
  actionFetchAllGroupsFailure,
  actionCreateGroup,
  actionUpdateGroup,
  actionDeleteGroup,
  actionChangeOrder,
  actionUpdateGroupFailure,
  UPDATE_GROUP_BEGIN,
} from './actions';
import { handleError } from 'utils';
import { Storage } from "../../../utils";


export const thunkFetchGroups = (searchParams={}) => {
  const params = qs.stringify({ ...searchParams, no_paging: 1 });
  return dispatch => {
    dispatch(actionFetchGroupsBegin());

    return axios.get(`${API_URL}/groups?${params}`)
      .then(({ data, headers }) => {
        dispatch(actionFetchGroupsSuccess(data));
      })
      .catch(handleError(dispatch, actionFetchGroupsFailure));
  };
};

export const thunkFetchAllGroups = () => {
  return dispatch => {
    dispatch(actionFetchAllGroupsBegin());

    return axios.get(`${API_URL}/groups`)
      .then(({ data, headers }) => {
        dispatch(actionFetchAllGroupsSuccess(data));
      })
      .catch(handleError(dispatch, actionFetchAllGroupsFailure));
  };
};

export const thunkCreateGroup = ({ parentId, name, shops }) => {
  const path = `${API_URL}/groups`;

  let doc = { };
  if (!!parentId) doc = { ...doc, parent_id: parentId };
  if (!!name) doc = { ...doc, name };
  if (!!shops) doc = { ...doc, shop_ids: shops };

  return dispatch => {
    axios.post(path, { group: doc })
      .then((response) => {
        const { data, included } = response.data;
        return dispatch(actionCreateGroup({ parentId, data, included }));
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const thunkSaveGroup = ({ id, parent_id, name, shop_ids, display_order, parent_name }) => dispatch => {
  let group = { parent_id, name, display_order };
  if (!!shop_ids) group = { ...group, shop_ids };
  dispatch({ type: UPDATE_GROUP_BEGIN });
  
  if (!!id) {
    // update
    axios
      .patch(`${API_URL}/groups/${id}`, { group })
      .then(({ data: { data, included }}) => {
        dispatch(actionUpdateGroup({ parentId: parent_id, data, included }));
        if (+Storage.getGroupData().value === +data.id)
          Storage.setGroupData({ value: data.id, label: data.attributes.name }, dispatch);
      })
      .catch(handleError(dispatch, actionUpdateGroupFailure));
  } else {
    // create new
    axios
      .post(`${API_URL}/groups`, { group })
      .then((response) => {
        const { data, included } = response.data;
        return dispatch(actionCreateGroup({ parentId: parent_id, data, included, parentName: parent_name }));
      })
      .catch(handleError(dispatch, actionUpdateGroupFailure));
  }
};

export const thunkSaveOrder = ({ id, parent_id, member_ids, oldTree }) => dispatch =>{
  // change order or change parents
  if (!!member_ids) {
    dispatch({ type: UPDATE_GROUP_BEGIN });
    axios
      .patch(`${API_URL}/groups/${id}`, { group: { parent_id, member_ids } })
      .catch(err => {
        handleError(dispatch, actionUpdateGroupFailure)(err)
        dispatch(actionChangeOrder(oldTree));
      });  }
}

export const thunkDeleteGroup = (id) => {
  const path = `${API_URL}/groups/${id}`;

  return dispatch => {
    axios.delete(path)
      .then(() => {
        return dispatch(actionDeleteGroup(id));
      })
      .catch((error) => {
        console.log('patch update has failed');
        console.error(error);
      });
  }
};
