import React, { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import renderHTML from 'react-render-html';
import { format } from 'date-fns';
import {
  Grid,
  Divider,
  Button,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { PUBLIC_GO_TO_PAGE, PUBLIC_SURVEY_NEXT_PAGE, PUBLIC_SURVEY_PREV_PAGE } from '../../redux/actions';
import {
  QUESTION_TYPE_DATE,
  QUESTION_TYPE_TEXT_AREA,
  QUESTION_TYPE_TEXT_FIELD,
  QUESTION_TYPE_NPS_REASON,
  QUESTION_TYPE_SELECT_USER,
} from 'modules/surveys/form/questions/constants';
import { themedStyles } from './styles';
import { QUESTION_TYPE_UPLOAD } from '../constants';
import { useGAEffect } from '../../../../ga';
import { htmlCss } from "../utils";
import SurveyFooter from "../SurveyPage/SurveyFooter";
import HtmlRender from '../components/HtmlRender';

const useStyles = makeStyles(themedStyles);

const cssCommon = `
  .textHeader {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    background: #EC681A;
    display: block;
    padding: 5px 10px;
  }
`;

const propTypes = {
  survey: PropTypes.object.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  doc: PropTypes.object.isRequired,
  goToPrevPage: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  updateHeaderHeight: PropTypes.func.isRequired,
  top: PropTypes.number.isRequired,
  visitedPages: PropTypes.array.isRequired,
  public_ga: PropTypes.object.isRequired,
};

const SurveyPage = ({ index, goToPage, page_number }) => {
  const classes = useStyles();

  const renderEditButton = () => (
    <div className={classes.bgButtonConfirm}>
      <Grid container>
        <Grid item xs={6} sm={6} className="bgButtonConfirm-p">
          <Typography>ページ{page_number}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          className={classes.rightCss}
        >
          <Button
            className={classes.btEdit}
            onClick={() => goToPage(index)}
            variant="contained"
            endIcon={<span className="fa fa-pencil-square-o"></span>}
          >
            編集
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  if (index === 0) {
    return (
      <>
        {renderEditButton()}
      </>
    )
  }

  return (
    <div className={classes.btPadding}>
      {renderEditButton()}
    </div>
  )
}

const ConfirmPage = ({
  survey,
  goToNextPage,
  doc,
  goToPrevPage,
  goToPage,
  updateHeaderHeight,
  top,
  visitedPages,
  public_ga,
}) => {
  const classes = useStyles();
  const [headerHeigh, setHeaderHeigh] = useState(0);

  useGAEffect(public_ga, "confirm", "回答確認", null)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const onPrevPage = () => {
    goToPrevPage();
  };

  const onNextPage = () => {
    goToNextPage();
  };

  const colorButton = "#EC681A";
  const styleColorButton = {backgroundColor: colorButton, border: `1px solid ${colorButton}`}

  const getAnswer = (questionItem) => {
    const answers = (doc.answers_attributes || []).filter(({ question_id }) => question_id === questionItem.id);

    if (questionItem.question_type === QUESTION_TYPE_DATE) {
      let date = "";
      if (!!answers[0]) {
        date = format(new Date(answers[0].answer_date), "yyyy年MM月dd日");
      }
      return date;
    } else if ([
        QUESTION_TYPE_TEXT_AREA,
        QUESTION_TYPE_TEXT_FIELD,
        QUESTION_TYPE_NPS_REASON
      ].includes(questionItem.question_type)
    ) {
      return !!answers[0] ? answers[0].answer_entry : "";
    } else if (questionItem.question_type === QUESTION_TYPE_SELECT_USER) {
      return (doc.user || {}).name || '';
    } else if (questionItem.question_type === QUESTION_TYPE_UPLOAD) {
      return (((answers || [])[0] || {}).answer_image || {}).data || '';
    } else {
      if (!!answers[0]) {
        const selectedAnswers = questionItem.question_options_attributes.filter(({ id }) => answers[0].answer_options_attributes.map(({ question_option_id }) => question_option_id).includes(id));
        return selectedAnswers.map(({ name }) => name).join(",")
      }
      return "";
    }
  }

  const renderPageContent = (surveyPage) => {
    const renderQuestion = (questionItem) => {
      const getAnswerQuestionItem = getAnswer(questionItem);
      if (questionItem.question_type === QUESTION_TYPE_UPLOAD) {
        return (
          <div className={classes.display}>
            <div className={classes.textConfirm}>{
            <HtmlRender
                value = {questionItem.body}
            />
            }</div>
            <div className={classes.imgConfirm}>
              {getAnswerQuestionItem && (
                <div className={classes.imgconfirmBorder}>
                  <div className={classes.imgConfirmStyle}>
                    <img className={classes.img} src={getAnswerQuestionItem} alt={''}/>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      } else {
        return (
          <div className={classes.display}>
            <div className={classes.textConfirm}>{
            <HtmlRender
              value = {questionItem.body}
           />
            }</div>
            <div className={classes.rightCss}>
              <HtmlRender
                value = {getAnswerQuestionItem}
              />
            </div>
          </div>
        )
      }
    }
    return (
      surveyPage.question_items.map((questionItem, index) => (
        <div key={`question-item-${index}`}>
          {renderQuestion(questionItem)}
          <Divider variant="middle"/>
        </div>
      ))
    );
  }

  const visitedPageIds = visitedPages
    .filter(item => item !== "start" && item !== "end" && item !== "finish")
    .map(item => +survey.survey_pages_attributes[+item].id);

  return (
    <div className={classes.root}>
      <Paper
        className={classes.headerPaper}
        ref={el => {
          if (!el) return;
          updateHeaderHeight(el.getBoundingClientRect().height)
        }}
      >
        {renderHTML(survey.common_header)}
        {renderHTML(htmlCss(survey.common_css || cssCommon))}
      </Paper>
      <div className={classes.bgTextConfirm} style={{top:top - 20}}
        ref={el => {
          if (!el) return;
          const { height, top } = el.getBoundingClientRect()
          setHeaderHeigh(height + top)
        }}
      >
        <h4>{survey.page_title}</h4>
        <h5>{doc.shop.name}</h5>
        <h6>ありがとうございます。</h6>
        <h6>下記内容でよろしいでしょうか？</h6>
      </div>
      <div className={classes.bgTextConfirm1} style={{ paddingTop: headerHeigh }}>
        {survey.survey_pages_attributes.map((surveyPage, index) => (
          visitedPageIds.includes(+surveyPage.id) &&
            <div key={`survey-page-container-${index}`}>
              <SurveyPage
                key={`survey-page-${index}`}
                index={index}
                goToPage={() => goToPage(index)}
                page_number={surveyPage.page_number}
              />
              {renderPageContent(surveyPage)}
            </div>
        ))}
        <Divider variant="middle"/>
        <div className={classes.textConfirm3}>
          ご確認後問題ないようでしたら<br />
          完了を押して送信お願いいたします。
        </div>
      </div>
      <div className={classes.btFooter}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <button
              className={classes.buttonHandlePrev}
              onClick={onPrevPage}>
              <i className="fa fa-caret-left"></i>
              前のページへ
            </button>
          </Grid>
          <Grid item xs={6}>
            <button
              className={classes.buttonHandleNext}
              style={styleColorButton}
              onClick={onNextPage}>
              <i className="fa fa-caret-right"></i>
              完了
            </button>
          </Grid>
        </Grid>
      </div>
      <div className={classes.heightButton}></div>
      <SurveyFooter classes={classes} survey={survey} defaultCss={cssCommon} />
    </div>
  );
}

ConfirmPage.propTypes = propTypes;

export default connect(
  ({ public: { survey, doc, visitedPages }, public_ga }) => ({
    survey,
    doc,
    visitedPages,
    public_ga,
  }),
  (dispatch) => ({
    goToPrevPage: () => dispatch({ type: PUBLIC_SURVEY_PREV_PAGE }),
    goToNextPage: () => dispatch({ type: PUBLIC_SURVEY_NEXT_PAGE }),
    goToPage: (page) => dispatch({ type: PUBLIC_GO_TO_PAGE, payload: { page } })
  })
)(withRouter(ConfirmPage));
