import React from 'react'
import { connect } from 'react-redux'
import { useMediaQuery, useTheme, Radio, FormControlLabel } from '@material-ui/core'
import { isEdge, isIE, isMobileOnly } from 'react-device-detect'
import { useRankingStyles } from './styles'
import { FlexColumnSeparator, UserFeedbackItem, UserNpsItem } from './common/RankItems'
import Dropdown from './common/Dropdown'
import { SELECT_USER_RANK_DROPDOWN, SET_COMMON_RANK_TRIM30 } from '../redux/actions'
import { loadGroups, loadShops } from '../../../common/Header/HeaderForm/utils'
import { Mobile } from './common/RankBody'
import ReloadButton from './common/ReloadButton'
import Progress from './common/Progress'
// import SwitchBtn from "../../../components/SwitchBtn"

const Tab01 = ({ data, classes }) => (
  <div className={classes.rankColumnContainer}>
    {data.map((item, i) => (
      <UserNpsItem
        key={i}
        index={i}
        rank={data.map(v => v.nps_score).indexOf(item.nps_score) + 1}
        score={item.nps_score}
        name={item.name}
        shops_name={item.shops_name}
        classes={classes}
      />
    ))}
  </div>
)

const Tab02 = ({ data, classes }) => (
  <div className={classes.rankColumnContainer}>
    {data.map((item, i) => (
      <UserFeedbackItem
        key={i}
        index={i}
        rank={data.map(v => v.feedback_count).indexOf(item.feedback_count) + 1}
        count={item.feedback_count}
        name={item.name}
        shops_name={item.shops_name}
        classes={classes}
      />
    ))}
  </div>
)

const Body = ({ classes, nps = [], feedback = [] }) => {
  if (nps.length === 0 && feedback.length === 0) return null

  if (isMobileOnly)
    return (
      <Mobile
        tab1={<Tab01 classes={classes} data={nps} />}
        tab2={<Tab02 classes={classes} data={feedback} />}
      />
    )

  return (
    <div>
      <div className={classes.rankContainer}>
        <div className={classes.columnHeader}>NPS TOP 5</div>
        <FlexColumnSeparator />
        <div className={classes.columnHeader}>回答数 TOP 5</div>
      </div>
      <div className={classes.rankContainer}>
        <Tab01 classes={classes} data={nps} />
        <FlexColumnSeparator />
        <Tab02 classes={classes} data={feedback} />
      </div>
    </div>
  )
}

const UserRank = ({
  feedbacks_top5 = [],
  nps_score_top5 = [],
  group,
  shop,
  loading,
  selectDropdown,
  onReload,
  goodVocloading,
  setTrim30Ranking,
}) => {
  const [cleared, setCleared] = React.useState(true)
  const [shops, setShops] = React.useState([])
  const [groups, setGroups] = React.useState([])
  const [trim30_flag, setTrim30_flag] = React.useState(false)
  const classes = useRankingStyles()
  const tablet = useMediaQuery(useTheme().breakpoints.down('md'))

  React.useEffect(() => {
    setCleared(true)
  }, [goodVocloading])

  const fetchAllGroups = async () => {
    const items = await loadGroups()
    setGroups(items)
  }

  const fetchAllShops = async () => {
    const items = await loadShops({ no_paging: 1, group_id: (group || {}).value })
    setShops(items)
  }

  return (
    <div>
      <div className = {classes.rankings}>
        <span className = {classes.dropdowns}>
          <Dropdown
            label="Group: "
            placeholder="グループを選択"
            options={groups}
            value={group}
            name={'group'}
            onChange={e => {
              selectDropdown('group', e)
              selectDropdown('shop', null)
              setCleared(true)
            }}
            onFocus={fetchAllGroups}
          />
          </span>
          <div style={{ minWidth: '10px', display: 'inline-block' }} />
          <span className = {classes.dropdowns}>
            <Dropdown
              label="Shop: "
              placeholder="店舗を選択"
              options={shops || []}
              value={shop}
              name={'shop'}
              onChange={e => {
                selectDropdown('shop', e)
                setCleared(true)
              }}
              onFocus={fetchAllShops}
            />
          </span>
          <div
            style={{
              display: 'inline-block',
              position: 'relative',
              top: isEdge ? -3 : isIE ? -16 : 'unset',
              width: isMobileOnly ? '100%' : 'unset',
            }}>
            <span className= {classes.switchBtnWrapperUserRanking}>
              <FormControlLabel
                className={classes.dashboardRadioButtonUserRanking}
                control={
                  <Radio
                    name={`ranking-user-trim30`}
                    checked={trim30_flag}
                    onClick={(e)=> {
                      setTrim30Ranking('user', !trim30_flag)
                      setTrim30_flag(!trim30_flag)
                      setCleared(true)
                    }}
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={'30件未満を除く'}
                labelPlacement="end"
              />
            </span>
            <ReloadButton
              disabled={!cleared}
              isFold={tablet}
              onReload={() => {
                setCleared(false)
                onReload && onReload()
              }}
            />
        </div>
      </div>
      {loading ? (
        <Progress />
      ) : (
        <Body classes={classes} feedback={feedbacks_top5} nps={nps_score_top5} />
      )}
    </div>
  )
}

export default connect(
  ({
    dashboard: {
      user: { feedbacks_top5, nps_score_top5, group, shop, loading },
      goodvocs,
    },
  }) => ({
    feedbacks_top5,
    nps_score_top5,
    group,
    shop,
    loading,
    goodVocloading: goodvocs.loading,
  }),
  dispatch => ({
    selectDropdown: (name, selected) =>
      dispatch({ type: SELECT_USER_RANK_DROPDOWN, payload: { name, selected } }),
      setTrim30Ranking: (name, value)=>dispatch({type: SET_COMMON_RANK_TRIM30, payload: {name, value}}),
  })
)(UserRank)
