import React from 'react'
import { connect, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { thunkFetchFeedbacks, thunkFetchFeedbackLikes } from '../../feedbacks/redux/thunk'
import { getFeedbackUserName, getNpsColorStyle, getNpsName } from '../../feedbacks/utils'
import ElementIcon from 'common/icon/ElementIcon'
import PinDialog from './PinDialog'
import LikeButton from './LikeButton'
import PinIcon from '../../../../common/icon/PinIcon'
import { useFeedbackListStyle } from '../../feedbacks/styles'
import {
  thunkDislikeGoodvocs,
  thunkFetchGoodvocs,
  thunkLikeGoodvocs,
  thunkPinGoodvocs,
} from '../redux/thunk'
import { Storage } from '../../../../utils'
import clsx from 'clsx'
import { WrappedText } from '../../feedbacks/FeedbackDetail'

const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 675px;
  ::-webkit-scrollbar {
    width: 10px;
    background: #b7b7b7 0% 0% no-repeat padding-box;
  }
  ::-webkit-scrollbar-track {
    background: #f2eeec 0% 0% no-repeat padding-box;
  }
  ::-webkit-scrollbar-thumb {
    background: #b7b7b7;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const Tag = styled.div`
  display: inline-block;
  max-width: 95px;
  white-space: nowrap;
  overflow-x: hidden;
  background-color: #F5F5F5;
  padding: 2px 5px;
  color: #696969;
  size: 12px;
  font-weight: 300;
  margin-right: 10px;
`

export const VocHeader = () => {
  const shop = useSelector(state => state.global.shopData).label
  const group = useSelector(state => state.global.groupData).label

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <span style={{ marginRight: 20 }}>VOC</span>
      {group && <Tag>{group}</Tag>}
      {shop && <Tag>{shop}</Tag>}
      {!shop && !group && <Tag>全社</Tag>}
    </div>
  )
}

const Goodvocs = ({
  goodvocs,
  page,
  totalPages,
  likes,
  likeBegin,
  loading,
  pinFeedback,
  can_pin_feedback,
  fetchFeedbackLikes,
  likeFeedback,
  dislikeFeedback,
  fetchGoodvocs,
}) => {
  const classes = useFeedbackListStyle()

  const pinIt = (id, pinned) => {
    pinFeedback(id, { pinned })
  }

  const likeIt = (id, like_id) => {
    if (like_id) dislikeFeedback(id, like_id)
    else likeFeedback(id)
  }

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom && totalPages > page) {
      fetchGoodvocs(page + 1)
    }
  }

  if (loading) return <CircularProgress disableShrink />

  return (
    <Container onScroll={handleScroll}>
      <Grid item={true}>
        {(goodvocs || []).map((feedback, i) => (
          feedback.nps_reason && feedback.nps_reason.trim() !== "" ?
            <Grid
              sm={12}
              container
              item
              className={classes.tdTable}
              key={i}
              style={{ backgroundColor: !!feedback.pinned ? '#FEF9EB' : 'white' }}
              display={{ sm: 'none', xs: 'none', md: 'block', lg: 'block' }}>
              <Grid
                xs={2}
                sm={2}
                md={1}
                lg={1}
                item
                className={classes.trNumber}
                style={getNpsColorStyle(feedback.nps_score)}>
                <Box className={`${classes.Tdborder} ${classes.poiterTablet}`}>
                  <Typography className={classes.score}>{feedback.nps_score}</Typography>
                  <Typography className={classes.underScore}>
                    {getNpsName(feedback.nps_score)}
                  </Typography>
                </Box>
              </Grid>
              <Grid sm={8} xs={7} md={2} lg={2} item className={classes.trShop}>
                <Box className={classes.Tdborder}>
                  <Typography className={clsx(classes.user, classes.userWrap)}>{getFeedbackUserName(feedback)}</Typography>
                  <WrappedText>
                    <Typography className={clsx(classes.shop, classes.shopWrap)}>
                      {feedback.shop.name}
                    </Typography>
                  </WrappedText>
                </Box>
              </Grid>
              <Grid
                sm={2}
                xs={3}
                md={2}
                item
                className={`${classes.trShopMobile} ${classes.hideDesktop}`}>
                <Box className={classes.Tdborder}>
                  <Typography className={classes.date}>
                    {format(new Date(feedback.created_at), 'yyyy/MM/dd')}
                  </Typography>
                  <Typography className={classes.hour}>
                    {format(new Date(feedback.created_at), 'HH:mm')}
                  </Typography>
                </Box>
              </Grid>
              <Grid sm={12} xs={12} md={4} lg={4} item className={classes.trBody}>
                <Box className={(classes.Tdborder, classes.reason)}>{feedback.nps_reason}</Box>
              </Grid>
              <Grid sm={12} md={2} lg={2} item className={classes.trDate}>
                <Box className={classes.Tdborder}>
                  <Typography className={classes.date}>
                    {format(new Date(feedback.created_at), 'yyyy/MM/dd')}
                  </Typography>
                  <Typography className={classes.hour}>
                    {format(new Date(feedback.created_at), 'HH:mm')}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={11} sm={11} md={2} lg={2} item className={classes.rightBorder}>
                <div className={classes.cellCenter}>
                  <Box className={classes.likeButtonBox}>
                    <LikeButton
                      likes_count={feedback.likes_count}
                      highlight={!!feedback.feedback_like_id}
                      onHover={() => fetchFeedbackLikes(feedback.id)}
                      likes={likes}
                      likeBegin={likeBegin}
                      onClick={() => likeIt(feedback.id, feedback.feedback_like_id)}
                    />
                  </Box>
                  <Button variant="outlined" className={classes.btnComment}>
                    <ElementIcon name={`chat-dot-round`} />
                    <Box className={classes.scoreComment}>{feedback.comments_count}</Box>
                  </Button>
                </div>
              </Grid>
              <Grid xs={1} sm={1} md={1} lg={1} item className={classes.pinButtonCell}>
                {can_pin_feedback ?
                  <PinDialog
                    className={classes.pinButton}
                    pinned={!!feedback.pinned}
                    onSubmit={() => pinIt(feedback.id, !!!feedback.pinned)}>
                    <PinIcon size={13} color={!!feedback.pinned ? '#ec681a' : 'black'} />
                  </PinDialog>
                  :
                  !!feedback.pinned && <PinIcon size={13} color={'#ec681a'} />
                }
              </Grid>
            </Grid>
            : <></>
        ))}
      </Grid>
    </Container>
  )
}

export default connect(
  ({ feedbacks: { likes }, dashboard: { goodvocs }, global: { userData } }) => ({
    likes,
    likeBegin: goodvocs.likeBegin,
    goodvocs: goodvocs.items,
    page: goodvocs.page,
    totalPages: goodvocs.totalPages,
    loading: goodvocs.loading,
    can_pin_feedback: 'true' === userData.pin_feedback,
  }),
  dispatch => ({
    fetchFeedbacks: (page, searchParams) => dispatch(thunkFetchFeedbacks(page, searchParams)),
    pinFeedback: (id, pinned) => dispatch(thunkPinGoodvocs(id, pinned)),
    likeFeedback: id => dispatch(thunkLikeGoodvocs(id)),
    dislikeFeedback: (id, like_id) => dispatch(thunkDislikeGoodvocs(id, like_id)),
    fetchFeedbackLikes: id => dispatch(thunkFetchFeedbackLikes(id)),
    fetchGoodvocs: page => dispatch(thunkFetchGoodvocs(Storage.getSearchParams(), page)),
  })
)(Goodvocs)
