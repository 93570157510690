import React, { useEffect, useState } from 'react'
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core'
import { connect } from 'react-redux'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import ReactResizeDetector from 'react-resize-detector'
import { isTablet } from 'react-device-detect'
import { Bar, BarChart, ReferenceLine, XAxis, YAxis } from 'recharts'
import { GRAY_COLOR } from '../../aggregation/constants'
import { makeStyles } from '@material-ui/core/styles'
import { themeDashboardTableStyles } from './styles'
import { getNpsColor, sign } from '../utils'
import { renderHtmlWithoutTags } from '../../../utils'

const useStyles = makeStyles(themeDashboardTableStyles)

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const ShakyCell = ({ tablet, item, classes, deltaColor }) => {
  if (tablet) {
    return (
      <TableCell className={classes.cell2}>
        <span className={classes.value}>{item.x}</span>
        <span className={classes.delta} style={{ color: deltaColor }}>
          {sign(item.delta)}
        </span>
      </TableCell>
    )
  }

  return (
    <>
      <TableCell className={classes.cell2}>
        <span className={classes.value}>{item.x}</span>
      </TableCell>
      <TableCell className={classes.cell2}>
        <span className={classes.delta} style={{ color: deltaColor }}>
          {sign(item.delta)}
        </span>
      </TableCell>
    </>
  )
}

const TableGraph = ({ label, items = [], loading, visibleDrawer }) => {
  const [cellHeight, setCellHeight] = useState(20)
  const [width, setWidth] = useState(0)
  const classes = useStyles()
  const max = label === '平均点' ? 10 : 100
  const unitR = label === '平均点' ? '' : '%'
  const tablet = useMediaQuery(useTheme().breakpoints.down('md'))

  useEffect(() => {
    const height = (document.getElementById('graph-cell') || {}).clientHeight || 20
    setCellHeight(height)
  }, [items])

  const onResize = w => {
    setWidth(w)
  }

  if (loading) return <CircularProgress disableShrink />

  return (
    <Paper className={classes.tableContainer}>
      <div className={classes.tableBoxer}>
        <Table className={classes.table} stickyHeader>
          <colgroup>
            <col className={classes.colWidth} />
          </colgroup>
          {items.length > 0 && (
            <TableHead>
              <TableRow className={classes.headerTable}>
                <TableCell className={classes.cell}>項目</TableCell>
                <TableCell className={`${classes.cell} ${classes.cell3}`} colSpan={tablet ? 1 : 2}>
                  {label}
                </TableCell>
                <TableCell className={`${classes.cell} ${classes.cell4}`}>
                  <div style={{ float: 'left' }}>0{unitR}</div>
                  <div style={{ float: 'right' }}>{max}{unitR}</div>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {items.map((item, index) => {
              const deltaColor = getNpsColor(+(item.delta || 0) >= 0)
              return (
                <TableRow className={classes.Tbody} key={index} height={cellHeight}>
                  <TableCell id={index} className={classes.cell} align="center">
                    {item.label || item.body}
                  </TableCell>
                  <ShakyCell
                    classes={classes}
                    deltaColor={deltaColor}
                    tablet={tablet}
                    item={item}
                  />
                  <TableCell className={classes.cellGraph} align="center" id="graph-cell">
                    <CustomTooltip
                      placement={'top-start'}
                      title={
                        <>
                          <span className={classes.tooltipText}>
                            {renderHtmlWithoutTags(item.body)}
                          </span>
                          <span className={classes.value}>{item.x}</span>
                          <span className={classes.delta} style={{ color: deltaColor }}>
                            {sign(item.delta)}
                          </span>
                        </>
                      }>
                      <div>
                        <BarChart
                          data={[{ value: item.x, beforeValue: item.x - item.delta }]}
                          layout="vertical"
                          margin={{ left: 0, right: 0 }}
                          width={(width * (visibleDrawer && isTablet ? 2.5 : isTablet ? 3 : 4)) / 7}
                          height={cellHeight}
                          barSize={10}
                          barGap={5}>
                          <XAxis type="number" domain={[0, max]} hide={true} />
                          <YAxis dataKey="name" type="category" hide={true} />
                          <ReferenceLine x={max / 4} stroke={GRAY_COLOR} />
                          <ReferenceLine x={max / 2} stroke={GRAY_COLOR} />
                          <ReferenceLine x={(max * 3) / 4} stroke={GRAY_COLOR} />
                          <Bar dataKey="value" fill={deltaColor} />
                          <Bar dataKey="beforeValue" fill="#D8D8D8" />
                        </BarChart>
                      </div>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      <ReactResizeDetector handleWidth onResize={onResize} />
    </Paper>
  )
}

export default connect(
  ({
    dashboard: {
      nps_satisfactions: { label, items, loading },
    },
    common,
  }) => ({
    label,
    items,
    loading,
    visibleDrawer: common.visibleDrawer,
  })
)(TableGraph)
