import { initGA } from "ga";

export default (state={initialized: false}, action) => {
  switch (action.type) {
    case 'GA_INIT': {
      initGA(action.payload);
      state.initialized = true;
      return state;
    }

    default:
      return state;
  }
}
