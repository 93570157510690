export const FETCH_ANALYSIS_BEGIN = 'FETCH_ANALYSIS_BEGIN'
export const FETCH_FAILURE = 'FETCH_FAILURE'
export const FETCH_PERIODS = 'FETCH_PERIODS'
export const FETCH_TIME_SERIES = 'FETCH_TIME_SERIES'
export const FETCH_CORRELATIONS = 'FETCH_CORRELATIONS'
export const SET_ACTIVE_CHART = 'SET_ACTIVE_CHART'
export const FETCH_ANALYSIS_RESET = 'FETCH_ANALYSIS_RESET'

export const fetchBegin = () => ({
  type: FETCH_ANALYSIS_BEGIN,
})

export const fetchPeriodsSuccess = ({data}) => ({
  type: FETCH_PERIODS,
  payload: {
    data
  }
})

export const fetchTimeSeriesSuccess = ({data}) => ({
  type: FETCH_TIME_SERIES,
  payload: {
    data
  }
})

export const fetchCorrelationsSuccess = ({data}) => ({
  type: FETCH_CORRELATIONS,
  payload: {
    data
  }
})

export const fetchFailure = error => ({
  type: FETCH_FAILURE,
  payload: {
    error
  }
})

export const setActiveChart = chart => ({
  type: SET_ACTIVE_CHART,
  payload: {
    chart
  }
})