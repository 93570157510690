import React from 'react'
import { RadialBar, RadialBarChart } from 'recharts'
import { makeStyles } from '@material-ui/core/styles'
import { themeGraphStyles } from './styles'
import { connect } from 'react-redux'
import { getNpsColor, sign } from '../utils'

const getColorizedData = (positive, nps) => {
  return [
    { nps: 200, fill: '#EFEFEF' },
    { nps: 100, fill: '#B7B7B7' },
    { nps: Math.abs(+nps), fill: getNpsColor(positive) },
  ]
}

const RadialGraph = ({ nps_score, delta_nps_score = 0 }) => {
  const positive = +nps_score >= 0
  const classes = makeStyles(themeGraphStyles)()
  const radius = 70
  const radialWidth = 40
  const x = radius
  const y = radius
  return (
    <div className={classes.radialWrapper}>
      <RadialBarChart
        width={(radius + radialWidth / 2) * 2 + 5}
        height={(radius + radialWidth / 2) * 2 + 30}
        cx={x + 22}
        cy={y + 22}
        innerRadius={radius}
        outerRadius={radius}
        barSize={radialWidth}
        data={getColorizedData(positive, nps_score)}
        startAngle={90}
        endAngle={positive ? -270 : 450}>
        <RadialBar clockWise={true} background={true} dataKey="nps" />
        <text className={classes.small} x={x - 10} y={radius * 2 + 58}>
          -100
        </text>
        <text className={classes.small} x={x + 25} y={radius * 2 + 58}>
          +100
        </text>
      </RadialBarChart>
      <div className={classes.npsValueWrapper}>
        <div className={classes.npsValue}>{nps_score}</div>
        <div className={classes.delta} style={{ color: getNpsColor(+delta_nps_score >= 0) }}>
          {sign(+delta_nps_score)}
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    dashboard: {
      nps_satisfactions: { nps_score, delta_nps_score },
    },
  }) => ({
    nps_score,
    delta_nps_score,
  }),
  null
)(RadialGraph)
