export const formHeaderButtonStyles = theme => ({
  buttonToggle: {
    background: "#EFEFEF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    padding: "11px 49px 11px 12px",
    "& span": {
      textAlign: "left",
      font: "bold 12px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      opacity: 1,
      "& label": {
        marginRight: "10px"
      }
    }
  },
  buttonDel: {
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    padding: "9px 20px",
    float: "right",
    "& span": {
      textAlign: "left",
      font: "bold 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro",
      letterSpacing: "0.25px",
      color: "#FFFFFF",
      opacity: 1,
      "& i": {
        foniSize: "20px"
      }
    },
    "&:hover":{
      background: "#EC681A 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      opacity: 1,
      border: "1px solid #EC681A"
    },
    "&:hover span":{
      color:"#fff"
    },
    "&:hover i":{
      color:"#fff"
    },
  },
  box: {
    width: "640px",
    height: "100vh",
    padding: "32px 48px 42px 30px",
    overflow: "auto",
    flexGrow: "1",
    background: "#FFFFFF",
    boxShadow: "3px 0px 5px #B7B7B733",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
});

export const themedStyles = theme => ({
  headerFrom: {
    // 商品スコア開発にて商品名の上部に画像を配置する為marginTopは画像のラップクラス(imageWrapに移動)
    // marginTop: "74px",
    position: "relative",
    "& >div": {
      textAlign: "left",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#1A0707",
      opacity: 1,
      display: "inline-block",
      "& >div":{
        "& >fieldset":{
          border: 0,
          borderBottom: "1px solid #b7b7b7",
          borderRadius: 0
        },
      },
      "& i": {
        fontSize: "16px",
        color: "#F02544",
        marginLeft: "10px",
      }
    },
    "& >i": {
      float: "right",
      fontSize: "16px",
      color: "#B7B7B7",
      right: 0,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "50px",
      "& >div": {
        textAlign: "left",
        font: "500 10px/18px Noto Sans JP, Hiragino Sans",
        letterSpacing: 0,
        color: "#1A0707",
        opacity: 1,
        display: "inline-block",
        "& i": {
          fontSize: "14px",
          color: "#F02544",
          marginLeft: "10px"
        }
      },
      "& >i": {
        float: "right",
        fontSize: "16px",
        color: "#B7B7B7",
        position: "relative",
        top: "5px"
      }
    }
  },
  editName: {
    width: "100%",
    marginBottom: 5,
    "& input": {
      padding: "13px 10px 13px 10px",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#000",
      '& :placeholder': {
        color: '#B7B7B7',
      },
    },
    "& i": {
      marginTop: "-15px"
    },
    "&::before": {
      borderBottom: "1px solid #B7B7B7"
    }
  },
  select:{
    "& >div":{
      position: "relative",
      outline: "none",
      "& span.textLengthOption": {
        paddingRight: "10px"
      },
      "&:focus":{
        border: "1px solid #EC681A",
        borderRadius: "5px"
      },
      "& >div":{
        position: "relative",
        "& >div":{
          "& >span":{
            display: "none"
          },
          "& >div":{
            '&:active':{
              backgroundColor: "#FFF"
            }
          },
        },
        "& >.select__indicators":{
          "& >span":{
            display: "none"
          },
          "& >div":{
            "& .el-icon-caret-bottom":{
              color: "#1A0707"
            }
          }
        },
      },
    },
  },
  selectEmployee:{
    position: 'relative',
    "& >div":{
      "& span.textLengthOption": {
        paddingRight: "10px"
      },
      "& >div":{
        "& .css-id0lq7-multiValue":{
          display: "none"
        },
        "& svg":{
          display: "none"
        },

        "& >.select__indicators":{
          "& >span":{
            display: "none"
          },
          "& >div":{
            "& .el-icon-caret-bottom":{
              color: "#1A0707"
            }
          }
        },
      },
    },
  },
  inputGroup: {
    marginTop: "42px",
    "& p": {
      textAlign: "left",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0",
      color: "#1A0707",
      opacity: 1
    },
    "& >div": {
      width: "100%",
      "& >div": {
        "& >input": {
          padding: "10px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          border: "0px solid #B7B7B7",
          borderRadius: "5px",
          font: "400 12px/21px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.3px",
          color: "#1A0707",
          opacity: 1,
          "&::-webkit-input-placeholder": { /* Chrome/Opera/Safari */
            color: "#B7B7B7",
          },
          "&::-moz-placeholder": { /* Firefox 19+ */
            color: "#B7B7B7",
          },
          "&:-ms-input-placeholder": { /* IE 10+ */
            color: "#B7B7B7",
          },
          "&:-moz-placeholder": { /* Firefox 18- */
            color: "#B7B7B7"
          }
        },
        "& >fieldset":{
          top: 0,
          "&:focus":{
            borderColor: "#EC681A"
          },
          "& >legend":{
            display: "none"
          }
        }
      }
    }
  },
  blockDate: {
    marginTop: 42,
    "& >p": {
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#1A0707",
      opacity: 1
    },
    "& >.formDate": {
      '& fieldset.MuiFormControl-root': {
        '& input': {
          border: '1px solid #ccc !important',
          borderRadius: 4,
          height: '36px',
          boxSizing: 'border-box',
          opacity: '1 !important',
          padding: '9px 12px',

          '&:-ms-input-placeholder': {
            color: '#ccc !important',
          },

          '&::-ms-clear': {
            display: 'none',
          },

          '&:hover': {
            border: '1px solid #1A0707 !important',
          },

          '&:focus': {
            border: '1px solid #EC681A !important',
          }
        },

        '& fieldset': {
          display: 'none',
        }
      },
      padding: 10,
      "& >p": {
        font: "600 12px/21px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.3px",
        color: "#1A0707",
        opacity: 1,
        margin: 0
      },
      "& >div": {
        "& >fieldset": {
          "& >div": {
            "& >.changeTime": {
              marginLeft: 10,
              "& >div": {
                width: 95,
                "& >input": {
                  width: '100%',
                  textAlign: "left",
                  font: "400 10px/18px Noto Sans JP, Hiragino Sans",
                  letterSpacing: "0.25px",
                  color: "#1A0707",
                  opacity: 1
                }
              }
            },
            "& >div": {
              margin: 0,
              "& >div": {
                "&:before": {
                  borderBottom: 0
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: 0
                },
                borderRadius: "5px",
                position: 'relative',
                padding: 0,
                "& >input": {
                  font: "400 10px/18px Noto Sans JP, Hiragino Sans",
                  letterSpacing: "0.25px",
                  color: "#1A0707",
                  opacity: 1
                },
                "& >div": {
                  position: 'absolute',
                  right: 10,
                  top: '50%',
                  transform: 'translateY(-50%)',

                  "& >button": {
                    padding: 0,
                    "& >span": {
                      "& >i": {
                        fontSize: "20px",
                        color: "#939393"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  blockListUser: {
    marginTop: "50px",
    "& >div": {
      "& >.search": {
        height: '36px',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        "& >p": {
          display: "inline-block",
          width: "21%",
          font: "500 14px/18px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          color: "#1A0707",
          opacity: 1,
          margin: '10px 0',
        },
        "& >div": {
          display: "inline-block",
          width: "79%"
        },

        "& #react-select-2-input, .css-1wa3eu0-placeholder": {
          display: 'none',
        },
        '& .search-box': {
          position: 'absolute',
          zIndex: '1',
          top: 1,
          bottom: 1,
          left: 1,
          width: 'calc(100% - 33px)',
          height: 'calc(100% - 2px)',
          border: 'none !important',
          borderRadius: 4,
          padding: '0 12px 0 12px',

          '&::-ms-clear': {
            display: 'none',
          },

          '&:hover ~ div > div:first-of-type, &:focus ~ div > div:first-of-type ': {
            border: '1px solid #EC681A',
          }
        }
      },
      "& >.listUser": {
        background: "#F7F7F7 0% 0% no-repeat padding-box",
        opacity: 1,
        minHeight: "500px",
        padding: "20px 40px",
        marginTop: "10px",
        overflow: "auto",
        "& >div": {
          background: "#9A877D 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          opacity: 1,
          display: "flex",
          padding: "12px 30px",
          marginTop: "10px",
          position: 'relative',
          "& >p": {
            font: "600 12px/21px Noto Sans JP, Hiragino Sans",
            letterSpacing: "0.3px",
            color: "#FFFFFF",
            opacity: 1,
            margin: 0,
            paddingRight: 30,
            width: '100%',
            overflowWrap: "break-word",
            wordWrap: "break-word",
            msWordBreak: "break-all",
            wordBreak: "break-word",
          },
          "& >i": {
            float: "right",
            color: "#fff",
            cursor: "pointer",
            position: 'absolute',
            right: 15,
          }
        }
      },
    }
  },
  paper:{
    position: "absolute",
    top: "0px"
  },
  formContain: {
    width: "540px",
    padding: "10px 30px",
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  // 商品スコア開発、画像
  imageWrap:{
    marginTop: "74px"
    // textAlign: 'center' 
  },
  image:{
    width: '50%',
    height: '20%',
    display:'block',
    margin:'auto',
    padding:'20px 20px',
  }
});

export const customStyles = ({showMultivalue}) => ({
  control: (base, state) => ({
    ...base,
    outline: "none",
    boxShadow: 'none',
    wordBreak: 'break-all',
    border: state.isFocused ? "1px solid #EC681A": "1px solid #B7B7B7",
    '&:hover': {
      border: "1px solid #EC681A",
      outline: "none",
    },
    '&:focus': {
      border: "1px solid #EC681A",
      outline: "none",
    },
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      background: "#9A877D 0% 0% no-repeat padding-box",
      borderRadius: "10px",
      opacity: 1,
      display: showMultivalue ? "flex" : "none",
      alignItems: "center",
      padding: "0px 10px",
      marginRight: "5px",
      color: "#fff",
      "& >div": {
        font: "600 10px/16px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.25px",
        color: "#FFFFFF",
        opacity: 1,
        "& >div":{
          "& >div":{
            "& >.text":{
              "& >p:nth-of-type(1)":{
                margin: 0,
                color: "#fff",
                whiteSpace: 'pre-wrap',
              },
              "& >p:nth-of-type(2)":{
                display: "none"
              },
            },
            "& svg":{
              display: "none"
            }
          },
          "& >svg": {
            fontSize: "15px",
            color: "#fff"
          }
        }
      },
    }
  },
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ?  "#fff" : "#fff",
    padding: '0px 12px',
    ".listbox": {
      width: "100%",
      margin: "2px 0 0",
      padding: 0,
      listStyle: "none",
      overflow: "auto",
      maxHeight: "250px",
      borderRadius: "4px",
      zIndex: 1,
      "& li": {
        padding: "5px 12px",
        display: "flex",
        "& span": {
          flexGrow: 1
        },
        "& svg": {
          color: "transparent"
        }
      },
      "& li[aria-selected='true']": {
        backgroundColor: "#fafafa",
        fontWeight: 600,
        "& svg": {
          color: "#EC681A"
        }
      },
      "& li[data-focus='true']": {
        backgroundColor: "#e6f7ff",
        cursor: "pointer",
        "& svg": {
          color: "#000"
        }
      },
    },
    ".rowTextAutocomplete": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      position: 'relative',
      borderBottom: "1px solid #B7B7B7",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#EC681A !important",
        cursor: "pointer"
      },
      "& >.text": {
        width: "100%",
        minHeight: 38,
        paddingRight: '30px',
        "&:hover p.textLabel": {
          color: "#EC681A !important",
          cursor: "pointer"
        },
        "& p.textLabel": {
          font: "600 10px/16px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.25px",
          color: state.isSelected ? "#EC681A" : "#1A0707",
          opacity: 1,
          margin: 0
        },
        "& p": {
          font: "300 8px/16px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.2px",
          color: "#796255",
          opacity: 1,
          margin: 0,
          overflowWrap: "break-word",
          wordWrap: "break-word",
          msWordBreak: "break-all",
          wordBreak: "break-word",
          fallbacks: [
            {
              wordBreak: "break-all"
            }
          ],
          msHyphens: "auto",
          mozHyphens: "auto",
          webkitHyphens: "auto",
          hyphens: "auto",
          marginBottom: 5,
          marginRight: '30px',
        }
      },
      "& >svg": {
        display: state.isSelected ? "block" : "none",
        color: state.isSelected ? "#EC681A" : "#1A0707",
        position: 'absolute',
        right: 5,
      }
    },
  })
})
