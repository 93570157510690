import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import { connect } from 'react-redux';
import Select, {components} from 'react-select';
import update from 'immutability-helper';
import {
  Grid,
  Divider,
  FormControl,
  InputBase,
  makeStyles,
  FormGroup
} from '@material-ui/core';
import { PAGE_ITEM_UPDATE } from '../../../redux/actions';
import {
  DEFAULT_MIN_SELECTION_COUNT,
  DEFAULT_MAX_SELECTION_COUNT,
  DEFAULT_MIN_CHARACTER_COUNT,
  DEFAULT_MAX_CHARACTER_COUNT,
  DEFAULT_LINE_NUMBER,
  QUESTION_TYPE_LIMIT_MAP,
  SURVEY_FORM_TYPE_SURVEY_HEADING,
  QUESTION_TYPE_NPS_QUESTION,
  QUESTION_TYPE_CHECK_BOX,
  QUESTION_TYPE_SELECT,
  QUESTION_TYPE_RADIO_BUTTON,
  QUESTION_TYPE_SELECT_BUTTON,
  QUESTION_TYPE_NPS_REASON,
  QUESTION_TYPE_TEXT_AREA,
  QUESTION_TYPE_TEXT_FIELD,
  QUESTION_TYPE_SELECT_USER,
  FREE_ENTRY_VALIDATION_OPTIONS,
  QUESTION_OPTIONS,
  SELECT_BUTTON_OPTIONS,
} from '../constants'
import AddImageSmall from '../../../components/AddImageSmall';
import Options from '../Options';
import SwitchBtn from 'components/SwitchBtn';
import { themedStyles } from './styles';
import { TextFieldWithError } from 'modules/surveys/components';
import IconDelete from 'assets/icons/icon_delete_active.svg';

const useStyles = makeStyles(themedStyles);

const propTypes = {
  surveyPageId: PropTypes.string,
  saveQuestion: PropTypes.func.isRequired,
  questionItem: PropTypes.object,
  spIndex: PropTypes.number,
  piIndex: PropTypes.number,
  errors: PropTypes.object,
  questionTypeOptions: PropTypes.array.isRequired,
  setDeleteQuestionParams: PropTypes.func.isRequired,
  survey_pages_attributes: PropTypes.array.isRequired,
};

const QuestionForm = ({
  surveyPageId,
  saveQuestion,
  questionItem,
  spIndex,
  piIndex,
  errors,
  questionTypeOptions,
  setDeleteQuestionParams,
  survey_pages_attributes,
  expanded
}) => {
  const questionErrors = errors.errors || {};
  const classes = useStyles(expanded);

  const [doc, setDoc] = useState({
    is_required: [true, undefined].includes(questionItem.is_required),
    is_privacy: questionItem.is_privacy || false,
    is_correlation_target: questionItem.is_correlation_target || false,
    // display_order: questionItem.display_order,
    question_type: questionItem.question_type,
    free_entry_validation: questionItem.free_entry_validation,
    option_align: questionItem.option_align || "",
    use_number: [true, undefined].includes(questionItem.use_number),
    destroy: 0,
    discarded_at: questionItem.discarded_at,
  });

  const [question_options_attributes, setQuestionOptionsAttributes] = useState(questionItem.question_options_attributes ? questionItem.question_options_attributes : []);
  const [question_image, setQuestionImage] = useState(questionItem.question_image_url ? { data: questionItem.question_image_url } : { data: '' });

  const [active, setActive] = useState(false);

  const maxSelectionCount = useRef(questionItem.max_selection_count || DEFAULT_MAX_SELECTION_COUNT);

  const generateDoc = () => ({
    ...doc,
    survey_page_id: surveyPageId,
    id: questionItem.id,
    discarded_at: null,
    question_options_attributes,
    _destroy: doc._destroy,
  });

  const updateQuestion = (params) => {
    let newValues = doc;

    if (!!params.question_type) {
      newValues = { ...newValues, question_type: params.question_type };

      params.question_options_attributes = question_options_attributes || [];

      if (([QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_SELECT, QUESTION_TYPE_SELECT_BUTTON, QUESTION_TYPE_RADIO_BUTTON].includes(doc.question_type) && params.question_type === QUESTION_TYPE_NPS_QUESTION)
        || (doc.question_type === QUESTION_TYPE_NPS_QUESTION && [QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_SELECT, QUESTION_TYPE_SELECT_BUTTON, QUESTION_TYPE_RADIO_BUTTON].includes(params.question_type))
        || ([QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_SELECT, QUESTION_TYPE_SELECT_BUTTON, QUESTION_TYPE_RADIO_BUTTON, QUESTION_TYPE_NPS_QUESTION].includes(doc.question_type) && ![QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_SELECT, QUESTION_TYPE_SELECT_BUTTON, QUESTION_TYPE_RADIO_BUTTON, QUESTION_TYPE_NPS_QUESTION].includes(params.question_type))
      ) {
        for (let i = 0; i < params.question_options_attributes.length; i ++) {
          params.question_options_attributes[i]._destroy = 1;
        }
      }

      if ([QUESTION_TYPE_NPS_QUESTION].includes(params.question_type)) {

        for (let i = 10; 0 <= i; i--) {
          params.question_options_attributes.push({value: `${i}`, discarded_at: null})
        }

      } else if ([QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_SELECT, QUESTION_TYPE_SELECT_BUTTON, QUESTION_TYPE_RADIO_BUTTON].includes(params.question_type)) {

        if (![QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_SELECT, QUESTION_TYPE_SELECT_BUTTON, QUESTION_TYPE_RADIO_BUTTON].includes(doc.question_type)) {
          params.question_options_attributes.push({ value: "0", discarded_at: null, is_aggregate: true });
        }

        if (QUESTION_TYPE_CHECK_BOX === params.question_type) {
          params.min_selection_count = questionItem.min_selection_count || DEFAULT_MIN_SELECTION_COUNT;
          params.max_selection_count = params.question_options_attributes.filter(i => i._destroy !== 1).length || DEFAULT_MAX_SELECTION_COUNT;
          maxSelectionCount.current = params.max_selection_count;
        }

      } else if ([QUESTION_TYPE_TEXT_FIELD, QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_NPS_REASON].includes(params.question_type)) {
        newValues.free_entry_validation = 'valid_no_limit';
        params.free_entry_validation = 'valid_no_limit';
        params.min_character_count = DEFAULT_MIN_CHARACTER_COUNT;
        params.max_character_count = DEFAULT_MAX_CHARACTER_COUNT;

        if ([QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_NPS_REASON].includes(params.question_type)) {
          params.line_number = 3;
        }
      }

      setQuestionOptionsAttributes(params.question_options_attributes);
      setDoc({...doc, ...newValues});
      saveQuestion({ ...generateDoc(), ...params }, spIndex, piIndex);

      return;
    }

    setDoc({ ...newValues, ...params });

    saveQuestion(params, spIndex, piIndex);
  }

  // question option update methods

  const handleAddOption = () => {
    const maxLength = QUESTION_TYPE_LIMIT_MAP[doc.question_type] || 5000;
    const is_aggregate = [QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_SELECT, QUESTION_TYPE_SELECT_BUTTON, QUESTION_TYPE_RADIO_BUTTON].includes(doc.question_type) || undefined;

    const newOptions = [...question_options_attributes, { value: "0", discarded_at: null, is_aggregate }];

    if (question_options_attributes.filter(i => i._destroy !== 1).length < maxLength) {
      setQuestionOptionsAttributes(newOptions);
      const notDeletedLength = newOptions.filter(i => i._destroy !== 1).length;
      updateQuestion({question_options_attributes: newOptions, max_selection_count: notDeletedLength});
      maxSelectionCount.current = notDeletedLength;
    }
  };

  const toggleOptionItem = (index) => {
    const newOptions = update(question_options_attributes, {
      [index]: {
        $set: {
          ...question_options_attributes[index],
          discarded_at: question_options_attributes[index].discarded_at === null ? new Date() : null,
        }
      }
    });

    setQuestionOptionsAttributes(newOptions);
    updateQuestion({question_options_attributes: newOptions});
  }

  const setParam = (index, params) => {
    const newOptions = update(question_options_attributes, {
      [index]: {
        $set: {
          ...question_options_attributes[index],
          ...params,
        }
      }
    });

    setQuestionOptionsAttributes(newOptions);
    updateQuestion({question_options_attributes: newOptions});
  }

  const checkQuestionOptionAndDelete = (index, { discarded_at }) => {
    const questionOptionToDelete = survey_pages_attributes[spIndex].question_items[piIndex].question_options_attributes[index];

    if (!!questionOptionToDelete.id) {
      for (let i = 0; i < survey_pages_attributes.length; i ++) {
        const surveyPage = survey_pages_attributes[i];
  
        if (!!surveyPage.id) {
          for (let j = 0; j < surveyPage.survey_page_display_conditions_attributes.length; j ++) {
            const displayConditions = surveyPage.survey_page_display_conditions_attributes;
            if (displayConditions.filter(item => +item.question_option_id === +questionOptionToDelete.id).length > 0) {
              alert("この選択肢は表示条件に設定されている為非表示にできません");
              return false;
            }
          }
        }
      }
    }

    setParam(index, { discarded_at })  
  }

  // question update methods

  const setMinSelectionCount = (min_selection_count) => updateQuestion({ min_selection_count: min_selection_count });
  const setMaxSelectionCount = (max_selection_count) => {
    maxSelectionCount.current = max_selection_count;
    updateQuestion({ max_selection_count: max_selection_count });
  }
  const setMinCharacterCount = (min_character_count) => updateQuestion({ min_character_count: min_character_count });
  const setMaxCharacterCount = (max_character_count) => updateQuestion({ max_character_count: max_character_count });
  const setLineNumber = (line_number) => updateQuestion({ line_number });
  const setUseNumber = (option) => updateQuestion({ use_number: option.value });

  const setFreeEntryValidation = (free_entry_validation) => {
    updateQuestion({ free_entry_validation });
    setDoc({ ...doc, free_entry_validation });
  };

  const checkAndDiscardQuestion = ({ discarded_at }) => {
    const questionToDelete = survey_pages_attributes[spIndex].question_items[piIndex];

    if (!!questionToDelete.id) {
      for (let i = 0; i < survey_pages_attributes.length; i ++) {
        const surveyPage = survey_pages_attributes[i];
  
        if (!!surveyPage.id) {
          for (let j = 0; j < surveyPage.survey_page_display_conditions_attributes.length; j ++) {
            const displayConditions = surveyPage.survey_page_display_conditions_attributes;

            if (displayConditions.filter(item => +item.question_id === +questionToDelete.id).length > 0) {
              alert("この設問は表示条件に設定されている為非公開にできません");
              return false;
            }
          }
        }
      }
    }

    updateQuestion({ discarded_at })
  }

  const className = active ? clsx(classes.itemContainer, classes.activeItem) : classes.itemContainer;

  if (doc.question_type === SURVEY_FORM_TYPE_SURVEY_HEADING) {
    return (
      <div className={className}>
        <FormControl component="fieldset" fullWidth>
          <InputBase
            className={classes.textField1}
            placeholder="見出し"
            name={"body"}
            defaultValue={questionItem.body}
            onChange={e => updateQuestion({body: e.target.value})}
          />
          <div className={classes.iconDelete} onClick={() => setDeleteQuestionParams({spIndex, piIndex, type: SURVEY_FORM_TYPE_SURVEY_HEADING})}>
            <img src={IconDelete} alt="delete" />
          </div>
        </FormControl>
        {!!questionErrors.body &&
          <span style={{color: 'red'}}>{questionErrors.body.join(',')}</span>
        }

        <div className={classes.switchBoxContainer}>
          <div>
          </div>
          <div>
            <SwitchBtn label={!!!doc.discarded_at ? "公開" : "非公開"} labelPlacement="start" checked={!!!doc.discarded_at} onChange={() => updateQuestion({discarded_at: !!!doc.discarded_at ? new Date() : null})}/>
          </div>
        </div>
      </div>
    );
  };

  const formatOptionLabel = ({ icon, activeIcon, label, selected }) => {
    const iconSrc = selected && activeIcon ? activeIcon : icon
    return (
      <div className={classes.slectOptionItem}>
        {icon && <img src={iconSrc} className={classes.selectOptionIcon} alt={''} />}
        {label}
      </div>
    )
  };

  const SingleValue = ({ children, ...props }) => {
    const data = props.data
    const { SingleValue } = components
    const selectedOption = {
      ...data,
      selected: true,
      label: data.label
    }
    return (
      <SingleValue {...props}>
        {formatOptionLabel(selectedOption)}
      </SingleValue>
    )
  };

  const handleTextareaChange = event => {
    const textArea = event.target
    updateQuestion({body: textArea.value});
    changeTextAreaSize(event);
  }

  const changeTextAreaSize = event => {
    const textArea = event.target
    const maxHeight = 63
    textArea.style.cssText = 'height: auto'
    textArea.style.cssText = `height: ${Math.min(textArea.scrollHeight, maxHeight) + 'px'}`
    return true;
  }

  return (
    <div
      className={className}
      onFocus={() => { setActive(true) }}
      onBlur={() => { setActive(false) }}
    >
      <FormControl component="fieldset" fullWidth>
        <InputBase
          className={classes.textField1}
          placeholder="質問を入力してください。"
          name={"body"}
          defaultValue={questionItem.body}
          onChange={handleTextareaChange}
          onFocus={changeTextAreaSize}
          multiline
          rows={1}
        />
        <div className={classes.iconDelete}>
          <img src={IconDelete} alt="delete" onClick={() => setDeleteQuestionParams({spIndex, piIndex})}/>
        </div>
      </FormControl>
      {!!questionErrors.body &&
        <span style={{color: 'red'}}>{questionErrors.body.join(',')}</span>
      }
    { expanded &&
    <div>
      <Grid className={classes.formAction} container direction="row" alignItems="center" justify="space-between">
        <FormControl className={classes.formRow}>
          <label className={classes.formRowLabel}>略称</label>
          <InputBase
            className={classes.textField1}
            placeholder="略称を入力してください。"
            name="abbreviation"
            defaultValue={questionItem.abbreviation}
            onChange={e => updateQuestion({abbreviation: e.target.value}) }
          />
        </FormControl>

        <FormControl className={classes.formRow}>
          <label className={classes.formRowLabel}>画像</label>
          <div className={classes.uploadZone}>
            <AddImageSmall image={question_image} setImage={(question_image) => { updateQuestion({ question_image }); setQuestionImage(question_image || { data: '' }); } } />
          </div>
        </FormControl>

        <Select
          fullWidth
          placeholder="回答方式を選んでください。"
          options={questionTypeOptions}
          defaultValue={QUESTION_OPTIONS.filter(opt => opt.value === questionItem.question_type)}
          onChange={({ value }) => updateQuestion({question_type: value})}
          className={`${classes.dropdown} ${classes.borderRadius5}`}
          formatOptionLabel={formatOptionLabel}
          components={{ SingleValue }}
          maxMenuHeight={300}
          menuPlacement='auto'
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#EC681A',
              primary25: '#ffcf86',
              primary50: '#ffcf86',
            },
          })}
        />
        {!!questionErrors.abbreviation &&
          <span style={{color: 'red'}}>{questionErrors.abbreviation.join(',')}</span>
        }
        {!!questionErrors.question_type &&
          <span style={{color: 'red'}}>{questionErrors.question_type.join(',')}</span>
        }
      </Grid>

      {doc.question_type === QUESTION_TYPE_SELECT_BUTTON && (
        <Grid container direction="row" justify="flex-end">
          <Select
            className={classes.dropdown}
            name="use_number"
            defaultValue={ SELECT_BUTTON_OPTIONS.find(item => item.value === doc.use_number) }
            onChange={ setUseNumber }
            options={ SELECT_BUTTON_OPTIONS }
            formatOptionLabel={formatOptionLabel}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#EC681A',
                primary25: '#ffcf86',
                primary50: '#ffcf86',
              },
            })}
          />
        </Grid>
      )}

      {QUESTION_TYPE_CHECK_BOX === doc.question_type && (
        <FormGroup row>
          <div className= {clsx(classes.inputFloatRight, (questionErrors.min_selection_count || questionErrors.max_selection_count) && classes.paddingBottom60)}>
            <label className={classes.formRowLabel}>最小選択数</label>
            <TextFieldWithError
              name="min_selection_count" 
              defaultValue={questionItem.min_selection_count || DEFAULT_MIN_SELECTION_COUNT}
              onChange={e => setMinSelectionCount(e.target.value) }
              variant="outlined"
              placeholder="最小選択数"
              errors={questionErrors.min_selection_count}
            />

            <label className={classes.formRowLabel}>最大選択数</label>
            <TextFieldWithError
              name="max_selection_count"
              value={maxSelectionCount.current}
              onChange={e => setMaxSelectionCount(e.target.value) }
              variant="outlined"
              placeholder="最大選択数"
              errors={questionErrors.max_selection_count}
            />
          </div>
        </FormGroup>
      )}

      <Options
        option={doc.question_type}
        options={question_options_attributes}
        handleAddOption={handleAddOption}
        setName={(index, name) => setParam(index, { name })}
        setValue={(index, value) => setParam(index, { value })}
        toggleOptionItem={toggleOptionItem}
        setIsExclusive={(index, is_exclusive) => setParam(index, { is_exclusive })}
        setIsFreeAnswer={(index, is_free_answer) => setParam(index, { is_free_answer })}
        setIsAggregate={(index, is_aggregate) => setParam(index, { is_aggregate })}
        setDiscardedAt={(index, discarded_at) => checkQuestionOptionAndDelete(index, { discarded_at: !!discarded_at ? null: new Date() })}
        setOptionImage={(index, option_image) => setParam(index, { option_image, option_image_url: '' }) }
        errors={(errors || {}).question_option_attributes || []}
      />


      {[QUESTION_TYPE_NPS_REASON, QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_TEXT_FIELD].includes(doc.question_type) && (
        <FormGroup row>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <label className={classes.formRowLabel}>入力規則</label>
            <Select
              className={clsx(classes.dropdown, classes.ml_20)}
              placeholder='制限なし'
              name="free_entry_validation"
              onChange={({ value }) => setFreeEntryValidation(value) }
              value={FREE_ENTRY_VALIDATION_OPTIONS.filter(item => item.value === doc.free_entry_validation)[0] || ''}
              options={FREE_ENTRY_VALIDATION_OPTIONS}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#EC681A',
                  primary25: '#ffcf86',
                  primary50: '#ffcf86',
                },
              })}
            />
            {!!questionErrors.free_entry_validation &&
              <span style={{color: 'red'}}>{questionErrors.free_entry_validation.join(',')}</span>
            }
          </Grid>
          <div className={clsx(
            classes.inputFloatRight,
            (
              questionErrors.min_character_count ||
              questionErrors.max_character_count ||
              (questionErrors.line_number && [QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_NPS_REASON].includes(doc.question_type))
            ) &&
            classes.paddingBottom60
          )}>
            <label className={classes.formRowLabel}>最小文字数</label>
            <TextFieldWithError
              name="min_character_count"
              defaultValue={questionItem.min_character_count || DEFAULT_MIN_CHARACTER_COUNT}
              placeholder={"最小文字数"}
              onChange={e => setMinCharacterCount(e.target.value) }
              variant="outlined"
              errors={questionErrors.min_character_count}
            />

            <label className={classes.formRowLabel}>最大文字数</label>
            <TextFieldWithError
              name="max_character_count"
              defaultValue={questionItem.max_character_count || DEFAULT_MAX_CHARACTER_COUNT}
              placeholder="最大文字数"
              onChange={e => setMaxCharacterCount(e.target.value) }
              variant="outlined"
              errors={questionErrors.max_character_count}
            />

            {[QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_NPS_REASON].includes(doc.question_type)  && (
              <>
                <label className={classes.formRowLabel}>行数</label>
                <TextFieldWithError
                  variant="outlined"
                  errors={questionErrors.line_number}
                  name="line_number"
                  defaultValue={questionItem.line_number || DEFAULT_LINE_NUMBER}
                  onChange={e => setLineNumber(e.target.value) }
                />
              </>
            )}
          </div>
        </FormGroup>
      )}

      <Divider component="div" className={classes.itemDivider} />
    </div>
    }
      <div className={classes.switchBoxContainer}>
        <div>
          {QUESTION_TYPE_SELECT_USER !== doc.question_type && (
            <SwitchBtn label="必須" checked={doc.is_required} onChange={() => updateQuestion({is_required: !doc.is_required})} />
          )}
          <SwitchBtn label="個人情報" checked={doc.is_privacy || false} onChange={() => updateQuestion({is_privacy: !doc.is_privacy})} />
          {QUESTION_TYPE_NPS_QUESTION !== doc.question_type && (
            <SwitchBtn label="相関対象" checked={doc.is_correlation_target || false} onChange={() => updateQuestion({is_correlation_target: !doc.is_correlation_target})} />
          )}
        </div>
        <div>
          <SwitchBtn label={!!!doc.discarded_at ? "公開" : "非公開"} labelPlacement="start" checked={!!!doc.discarded_at} onChange={() => checkAndDiscardQuestion({ discarded_at: !!!doc.discarded_at ? new Date() : null })}/>
        </div>
      </div>
    </div>
  );
};

QuestionForm.propTypes = propTypes;

export default connect(
  ({ surveys: { item: { survey_pages_attributes } } }) => ({
    survey_pages_attributes,
  }),
  (dispatch, {spIndex, piIndex}) => ({
    saveQuestion: (updatedItem) => 
      dispatch({
        type: PAGE_ITEM_UPDATE,
        payload: {
          updatedItem,
          spIndex,
          piIndex,
        }
      }),
  })
)(QuestionForm);
