import { makeStyles } from "@material-ui/core";

export const useHeaderDrawerStyle = makeStyles(theme => ({
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "70px"
  },
  buttonDel: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #F02544",
    borderRadius: "5px",
    opacity: 1,
    padding: "8px 17px",
    "& span": {
      textAlign: "left",
      font: "500 12px/24px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#F02544",
      opacity: 1,
    }
  },
  buttonClose: {
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    padding: "9px 20px",
    float: "right",
    "& span": {
      textAlign: "left",
      font: "bold 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro",
      letterSpacing: "0.25px",
      color: "#FFFFFF",
      opacity: 1,
      "& i": {
        foniSize: "20px"
      }
    }
  },
}));
