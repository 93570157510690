import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { thunkFetchTimeSeries } from './redux/thunk';
import { setActiveChart } from './redux/actions';
import TimeSeriesAnswersChart from './charts/TimeSeriesAnswersChart';
import TimeSeriesNpsScoreChart from './charts/TimeSeriesNpsScoreChart';
import TimeSeriesCategoryChart from './charts/TimeSeriesCategoryChart';
import DataTable from './DataTable';
import classNames from 'classnames';
import { themeAnalysisTimeSeriesStyles } from "./styles"
import { isMobileOnly } from 'react-device-detect';
import { useGAEffect } from '../../../ga';


const propTypes = {
  time_series: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  label: PropTypes.string,
  searchParams: PropTypes.object,
  fetchTimeSeries: PropTypes.func.isRequired,
  setActiveChart: PropTypes.func.isRequired,
  ga: PropTypes.object.isRequired,
}

const useStyles = makeStyles(themeAnalysisTimeSeriesStyles);

const AnalysisTimeSeries = ({ 
  time_series,
  loading,
  label,
  searchParams,
  fetchTimeSeries,
  setActiveChart,
  ga,
  filters,
 }) => {
  const { root, btn, activeBtn, chartGroup, showTableForWeb } = useStyles();
  const [active, setActive] = useState(0);
  const { group_id, project_id, survey_id, sdate, edate } = searchParams

  useEffect(() => {
    setActiveChart("graph-timeseries-" + active);
  })

  useGAEffect(ga, "time_series", "期間合計")

  useEffect(() => {
    fetchTimeSeries({ ...searchParams, ...filters });
    // eslint-disable-next-line
  }, [fetchTimeSeries, group_id, project_id, survey_id, sdate, edate, filters]);

  const handleClick = (e, index) => {
    e.preventDefault();
    setActive(index);
  }

  const rowNames = ["NPS", "回答数", "推奨者(割合)", "中立者(割合)", "批判者(割合)"];
  const rowKeys = ["nps_score", "count", "promoters", "passives", "detractors"];

  const buttonProps = index => ({
    variant: "contained",
    className: classNames(btn, active === index && activeBtn),
    onClick: e => handleClick(e, index)
  });

  return (
    <div>
      <div className={root}>
        <Button {...buttonProps(0)}>回答数</Button>
        <Button {...buttonProps(1)}>NPS値</Button>
        <Button {...buttonProps(2)}>NPSカテゴリシェア</Button>
      </div>
      <div className={chartGroup}>
        {active === 0 ? <TimeSeriesAnswersChart data={time_series} /> :
          active === 1 ? <TimeSeriesNpsScoreChart data={time_series} /> :
          <TimeSeriesCategoryChart data={time_series} />
        }
      </div>
      <div className={isMobileOnly ? 'data-table' : showTableForWeb}>
        {loading ? <CircularProgress disableShrink /> 
        : <DataTable data={time_series} rowHeader={label} rowNames={rowNames} rowKeys={rowKeys} rowWithSurfix={2} unitSurfix='%' />}
      </div>
    </div>
  )
}

AnalysisTimeSeries.propTypes = propTypes;

export default connect(
  ({
    analysis: {
      time_series,
      loading,
      label,
    },
    global: { searchParams, filters },
    ga
  }) => ({
    time_series,
    loading,
    label,
    searchParams,
    ga,
    filters
  }),
  dispatch => ({
    fetchTimeSeries: searchParams => dispatch(thunkFetchTimeSeries(searchParams)),
    setActiveChart: chart => dispatch(setActiveChart(chart)),
  })
)(AnalysisTimeSeries);
