import qs from 'query-string';
import StorageClass from './Storage';
import { PAGINATION_PARAM_NO_PAGINATION } from '../constants';

export const Storage = StorageClass;

export const handleError = (dispatch, action) => error => {

  console.error(error)

  if (!!error.response){
    const { status, statusText, data } = error.response
    const validationErrors = data ? data.errors : null
    dispatch(action({
      status,
      statusText,
      detail: validationErrors,
    }));
  } else {
    dispatch(action(error));
  }
};

export const getRequestUrl = (apiPath, page, searchParams) => {
  const paramString = getQsParamString(page, searchParams);

  return `${apiPath}${!!paramString ? `?${paramString}` : ''}`;
};

export const getQsParamString = (page, searchParams) => {
  let params = getPaginationParams(page);

  if (Object.keys(searchParams).length > 0) {
    params = { ...params, ...searchParams };
  }

  return qs.stringify(params);
};

export const getPaginationParams = (page) => {
  let params = {};

  if (page) {
    if (page === PAGINATION_PARAM_NO_PAGINATION) {
      params = { ...params, no_paging: 1 };
    } else {
      params = { ...params, page };
    }
  }

  return params;
};

export const getPaginationQS = (window, page) => {
  const getParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});
  const paramsString = qs.stringify({ ...getParams, ...getPaginationParams(page) });
  
  return !!paramsString ? "?" + paramsString : "";
};

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const renderHtmlWithoutTags = (html) => {
  const regex = /(<([^>]+)>)/ig;
  const result = (html || '').replace(regex, '');

  return result;
}
