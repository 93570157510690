import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { makeStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {OPEN_GROUP_FORM_MODAL} from './redux/actions';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  addBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
  box: {
    width: "640px",
    height: "100vh",
    padding: "32px 48px 42px 30px",
    overflow: "auto",
    flexGrow: "1",
    background: "#FFFFFF",
    boxShadow: "3px 0px 5px #B7B7B733",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "10px",
    }
  },
  btnSave:{
    background: "#fff 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    border: "1px solid #B7B7B7",
    padding: "0 20px",
    "& >span":{
      font: "500 14px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.35px",
      color: "#EC681A",
      opacity: 1,
      "& >svg":{
        fontSize: "16px",
        marginRight: "20px"
      }
    },
    "&:hover":{
      background: "#EC681A 0% 0% no-repeat padding-box",
      border: "1px solid #EC681A"
    },
    "&:hover span":{
      color: "#fff",
    },
    "&:hover i":{
      color: "#fff",
    }
  }
}));

const propTypes = {
  openModal: PropTypes.func.isRequired
};

const GroupAppBarBottom = ({ openModal }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        className={classes.addBtn}
        startIcon={<AddIcon />}
        onClick={openModal}
      >
        新規追加
      </Button>
    </div>
  );
};

GroupAppBarBottom.propTypes = propTypes;

export default connect(
  null,
  dispatch => ({
    openModal: () => dispatch({
      type: OPEN_GROUP_FORM_MODAL,
      payload: {
        modalOpened: true,
        doc: {},
      }
    }),
  })
)(GroupAppBarBottom);
