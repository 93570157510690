import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { DialogSelectStyles } from './styles';
import { ExpandLess } from '@material-ui/icons';
import FadingDots from '../../modules/common/FadingDots'

const propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array,
  itemSelected: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

const useStyles = makeStyles(DialogSelectStyles);

const ItemList = props => {
  const { item, itemSelected, disableSelectParent } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const el = {
    ...item,
    children: (item.children ? item.children : []).map(elm =>
      ({ value: elm.value || elm.id, label: elm.label || elm.name, children: elm.children, level: (item.level || 0) + 1 })
    )
  };
  const hasChildrenNode = el.children && el.children.length > 0
  const handleClick = e => {
    e.stopPropagation();
    setOpen(!open)
  };
  const onSelect = element => {
    if(element.children.length > 0 && disableSelectParent) {
      setOpen(!open)
    }
    else{
      props.handleSelect({ ...element, value: element.value || element.id, label: element.label || element.name })
    }
  }
  const isSelected = itemSelected && (+itemSelected.value === +el.value || (itemSelected.current && itemSelected.current.id === el.value));

  return (
    <React.Fragment>
      <ListItem button onClick={() => onSelect(el)} className={classes.optionItem} style={{ paddingLeft: (el.level || 0) * 16 }}>
        <ListItemText className={isSelected ? clsx(classes.itemText, classes.selectedText) : classes.itemText}>{el.label}</ListItemText>
        {hasChildrenNode &&
          <span onClick={handleClick}>{open ? <ExpandLess className={classes.rotateIcon}/> : <ExpandLess className={classes.icon}/>}</span>
        }
      </ListItem>
      {hasChildrenNode && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {el.children.map(childEl => (
              <ItemList item={childEl} itemSelected={itemSelected} key={childEl.value} handleSelect={onSelect} disableSelectParent={disableSelectParent} />
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  )
}

const DialogTreeSelect = props => {
  const { title, options, itemSelected, disableSelectParent } = props;
  const classes = useStyles();
  const handleSelect = item => {
    props.handleSelect(item);
    closeDialog();
  }
  const closeDialog = () => props.handleClose();

  return (
    <Dialog onClose={closeDialog} aria-labelledby="simple-dialog-title" open={true} className={classes.dialogContainer}>
      <DialogTitle id="simple-dialog-title">
        <p className={classes.titleDialog}>{title}</p>
      </DialogTitle>
      <DialogContent>
        {options ?
          <List component="nav" className={classes.listRoot}>
          {options.length > 0 && options.map(el => (
            <ItemList item={el} itemSelected={itemSelected} key={el.value} handleSelect={handleSelect} disableSelectParent={disableSelectParent} />
          ))}
          </List>
          :
          <FadingDots style={{ margin: 10 }} />
        }
      </DialogContent>
    </Dialog>
  )
}

DialogTreeSelect.propTypes = propTypes;

export default DialogTreeSelect;
