import { makeStyles } from '@material-ui/core/styles'

export const useRankingStyles = makeStyles(theme => ({
  rankContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rankColumnContainer: {
    flex: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  rankItemContainer: {
    border: '1px solid #EFEFEF',
    height: 72,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      height: 45,
    },
  },
  feedbackCountWrapper: {
    position: 'absolute',
    right: 0,
    top: 20,
    [theme.breakpoints.down('sm')]: {
      top: 10,
    },
  },
  feedbackCount: {
    fontFamily: 'ヒラギノ角ゴシック',
    fontWeight: 400,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  feedbackCountText: {
    fontFamily: 'ヒラギノ角ゴシック',
    fontWeight: 400,
    fontSize: 18,
    marginRight: 15,
  },
  rankNumber: {
    width: 65,
    backgroundColor: '#EC681A',
    fontSize: 32,
    fontWeight: 'bold',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      width: 35,
    },
  },
  rankTextWrapper: {
    width: '100%',
    position: 'relative',
    height: 72,
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 0,
    },
  },
  userNameInRank: {
    fontFamily: 'ヒラギノ角ゴシック',
    fontWeight: 400,
    fontSize: 18,
    marginLeft: 0,
    paddingTop: 12,
    position: 'absolute',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '34%',
    left: '46%',
    [theme.breakpoints.down('sm')]: {
      left: 0,
      bottom: 5,
      fontSize: 12,
      marginLeft: 5,
      maxWidth: '75%',
    },
  },
  rankText: {
    fontFamily: 'ヒラギノ角ゴシック',
    fontWeight: 400,
    fontSize: 18,
    marginLeft: 15,
    overflow: 'hidden',
    maxWidth: '70%',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    height: 'auto',
    lineHeight: '1.2',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      top: '40%',
      transform: 'translateY(-50%)',
    },
  },
  rankNpsScore: {
    fontSize: 22,
    fontWeight: 'bold',
    marginRight: 10,
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      top: '40%',
      transform: 'translateY(-50%)',
    },
  },
  rankTextSmall: {
    fontSize: 14,
    margin: 13,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: 8,
    },
  },
  shopNameInRank: {
    color: '#888888',
    fontSize: 14,
    fontFamily: 'ヒラギノ角ゴシック',
    fontWeight: 400,
    paddingLeft: 15,
    height: '70%',
    borderRight: '1px solid #EFEFEF',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '45%',
    minWidth: '45%',
    position: 'absolute',
    paddingTop: 15,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5,
      paddingTop: 5,
      fontSize: 10,
      maxWidth: '75%',
      border: 'none',
    },
  },
  columnHeader: {
    color: '#ABABAB',
    fontSize: 12,
    fontWeight: 600,
    margin: '25px 0',
    flex: 15,
  },
  rankings: {
    flexDirection: 'row',
    padding: '15px 10px', 
    backgroundColor: '#F4F4F4',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  dropdowns: {
    verticalAlign: 'middle',
    width: '280px',
    margin: '5px 0px', 
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  switchBtnWrapper: {
    display: 'inline-block',
    marginRight: '10px',
    [theme.breakpoints.up("md")]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: '0px',
    },
  },
  switchBtnWrapperUserRanking: {
    // タブレットサイズの場合にユーザランキングのみ2段になるのでマージン調整
    display: 'inline-block',
    marginRight: '10px',
    [theme.breakpoints.up("lg")]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: '0px',
    },
  },
  root: {
    width: 770,
    margin: '0 auto',
    [theme.breakpoints.down("md")]: {
      width: "auto"
    }
  },
  radio: {
    '&$checked': {
      color: '#EC681A'
    }
  },
  checked: {},
  dashboardRadioButton: {
    border: '1px solid #B7B7B7',
    borderRadius: 5,
    backgroundColor: 'white',
    marginLeft: '0 !important',
    marginRight: '0',
    padding: '10px 20px 10px 16px',
    width: 145,
    height: 36,
    '& span.MuiIconButton-label': {
      width: 14,
      height: 14,
    },
    '& > span:nth-child(1)': {
      padding: 0,
    },
    '& > span:nth-child(2)': {
      marginLeft: 15,
      fontSize: 10,
      letterSpacing: 0.25,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: '0px',
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: '18px',
    },
  },
  dashboardRadioButtonUserRanking: {
    border: '1px solid #B7B7B7',
    borderRadius: 5,
    backgroundColor: 'white',
    marginLeft: '0 !important',
    marginRight: '0',
    padding: '10px 20px 10px 16px',
    width: 145,
    height: 36,
    '& span.MuiIconButton-label': {
      width: 14,
      height: 14,
    },
    '& > span:nth-child(1)': {
      padding: 0,
    },
    '& > span:nth-child(2)': {
      marginLeft: 15,
      fontSize: 10,
      letterSpacing: 0.25,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: '0px',
    },
    [theme.breakpoints.down("md")]: {
      marginTop: '18px',
    },
  },
}))
