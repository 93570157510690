import qs from 'query-string'
import {
  fetchBegin,
  fetchSurveyQuestionsSuccess,
  fetchQuestionAggregationSuccess,
  fetchCrossAggregationSuccess,
  fetchFailure,
} from './actions'
import { API_URL } from '../../../../constants'
import { default as axios } from 'axios'
import { handleError } from 'utils';

export const thunkFetchSurveyQuestions = (surveyId, searchParams={}) => {
  return dispatch => {
    dispatch(fetchBegin())
    const params = qs.stringify(searchParams)
    const path = `${API_URL}/surveys/${surveyId}/questions${!!params ? '?' + params : ''}`
    return axios.get(path)
      .then(response => {
        const { data } = response
        dispatch(fetchSurveyQuestionsSuccess(data))
      })
      .catch(handleError(dispatch, fetchFailure))
  }
}

export const thunkFetchQuestionAggregation = searchParams => {
  return dispatch => {
    dispatch(fetchBegin())
    const params = qs.stringify(searchParams)
    const path = `${API_URL}/totaling/questions${!!params ? '?' + params : ''}`
    return axios.get(path)
      .then(response => {
        const { data } = response
        dispatch(fetchQuestionAggregationSuccess(data))
      })
      .catch(handleError(dispatch, fetchFailure))
  }
}

export const thunkFetchCrossAggregation = searchParams => {
  return dispatch => {
    dispatch(fetchBegin())
    const params = qs.stringify(searchParams)
    const path = `${API_URL}/totaling/cross${!!params ? '?' + params : ''}`
    return axios.get(path)
      .then(response => {
        const { data } = response
        dispatch(fetchCrossAggregationSuccess(data))
      })
      .catch(handleError(dispatch, fetchFailure))
  }
}
