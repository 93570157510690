import React from 'react';
import PropTypes from 'prop-types';
import {
  InputBase,
} from '@material-ui/core';
import {
  VisibilityOutlined,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import AddImageSmall from '../../components/AddImageSmall';
import { questionChildStyles } from './styles';
import { QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_RADIO_BUTTON } from './constants';

const useStyles = makeStyles(questionChildStyles);

const propTypes = {
  errors: PropTypes.object,
  discarded_at: PropTypes.any,
  setDiscardedAt: PropTypes.func.isRequired,
  image: PropTypes.object,
  setImage: PropTypes.func.isRequired,
  question_type: PropTypes.string,
};

const QuestionChild = ({
  discarded_at,
  setDiscardedAt,
  index,
  name,
  setName,
  value,
  setValue,
  image,
  setImage,
  question_type,
  errors,
}) => {
  const classes = useStyles();
  return (
    <div>
    <div className={classes.itemChildWrapper}>
      <div className={classes.itemChildLeft}>
        <VisibilityOutlined
          onClick={_ => setDiscardedAt(index, discarded_at)}
          className={!!discarded_at ? classes.visibleInActiveIcon : classes.visibleActiveIcon}
        />
      </div>
      <div className={classes.itemChildCenter}>
        <InputBase
          className={classes.mainInput}
          placeholder="未評価選択肢を入力（選択）"
          name="option_name"
          value={name}
          onChange={e => setName(index, e.target.value) }
        />
        <span>
          <span>Pt</span>
          <InputBase
            className={classes.itemChildInput}
            name="option_value"
            value={value}
            onChange={e => setValue(index, (e.target.value || '').length === 0 ? '' : (+e.target.value || 0)) }
          />
        </span>
      </div>

      {[QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_RADIO_BUTTON].includes(question_type) && (
        <div className={classes.itemChildRight}>
          <AddImageSmall image={image} setImage={(img) => setImage(index, img)} />
        </div>
      )}
    </div>
    <div className={classes.allItemError}>
      <div className={classes.itemErrorInput}>{!!errors.name && errors.name}</div>
      <div className={classes.itemError}>{!!errors.value && errors.value}</div>
    </div>
    </div>
  )
}

QuestionChild.propTypes = propTypes;

export default QuestionChild;
