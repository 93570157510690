import React from 'react';
import { roundEdgeStyles } from './styles';
import ButtonWithIcon from '../buttons/ButtonWithIcon';
import ElementIcon from "common/icon/ElementIcon";

export const ImportButton = props => {
  const { roundEdge } = roundEdgeStyles()
  return <ButtonWithIcon className={roundEdge} endIcon={<ElementIcon name={`upload2`} />} {...props}/>
}

export const ExportButton = props => {
  const { roundEdge } = roundEdgeStyles()
  return <ButtonWithIcon className={roundEdge} endIcon={<ElementIcon name={`download`} />} {...props}/>
}
