import { parseAnswer } from '../../utils'

export const FETCH_ANSWERS_BEGIN   = 'FETCH_ANSWERS_BEGIN'
export const FETCH_ANSWERS_SUCCESS = 'FETCH_ANSWERS_SUCCESS'
export const FETCH_ANSWERS_FAILURE = 'FETCH_ANSWERS_FAILURE'
export const EDIT_ANSWER = 'EDIT_ANSWER'
export const OPEN_ANSWER_DIALOG = 'OPEN_ANSWER_DIALOG'
export const CLOSE_ANSWER_DIALOG = 'CLOSE_ANSWER_DIALOG'

export const fetchAnswersBegin = () => ({
  type: FETCH_ANSWERS_BEGIN
})

export const fetchAnswersSuccess = ({data, included}) => ({
  type: FETCH_ANSWERS_SUCCESS,
  payload: {
    answers: data.map(answer => parseAnswer(answer, included)),
  }
})

export const fetchAnswersFailure = error => ({
  type: FETCH_ANSWERS_FAILURE,
  payload: {
    error 
  }
})

export const editAnswer = ({data, included}) => ({
  type: EDIT_ANSWER,
  payload: {
    answer: parseAnswer(data, included) 
  }
})
