import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
  ReferenceLine,
  LabelList
} from 'recharts';
import DataTable from './DataTable';
import { thunkFetchPeriods } from './redux/thunk';
import { setActiveChart } from './redux/actions';
import { getMinMax, scaleUp } from './utils';
import { NPS_COLORS } from 'modules/aggregation/constants';
import { range } from 'modules/aggregation/utils';
import { themeAnalysisPeriodsStyles } from "./styles"
import {getElementWidth} from "../utils";
import { useGAEffect } from '../../ga';


const propTypes = {
  periods: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  searchParams: PropTypes.object,
  fetchPeriods: PropTypes.func,
  setActiveChart: PropTypes.func,
  ga: PropTypes.object.isRequired,
}

const useStyles = makeStyles(themeAnalysisPeriodsStyles);

const AnalysisPeriods = ({
  periods,
  fetchPeriods,
  setActiveChart,
  loading,
  loaded,
  searchParams,
  ga,
  filters,
 }) => {
  useEffect(() => {
    setActiveChart("graph-periods");
  })
  const { chartGroup, chartTag, boldText, showTableForWeb, valueText } = useStyles();
  const { group_id, project_id, survey_id, sdate, edate } = searchParams;
  const [ leftMargin, setLeftMargin ] = useState(0)
  const [ chartWidth, setChartWidth ] = useState(0)
  const firstTimeRefresh = useRef(true);

  useGAEffect(ga, "periods", "時系列推移")

  useEffect(() => {
    if (firstTimeRefresh.current === false) fetchPeriods({ ...searchParams, ...filters });
    // eslint-disable-next-line
  }, [fetchPeriods, group_id, project_id, survey_id, sdate, edate, filters]);

  useEffect(() => {
    if ((!loading && !loaded) || firstTimeRefresh.current === true) {
      fetchPeriods({ ...searchParams, ...filters });
      firstTimeRefresh.current = false;
    }
    // eslint-disable-next-line
  }, [loading, loaded]);

  useEffect(() => {
    setLeftMargin(getElementWidth('datatable-headerCol'))
    setChartWidth(getElementWidth('datatable'))
  }, [])

  window.addEventListener('resize', () => {
    setLeftMargin(getElementWidth('datatable-headerCol'))
    setChartWidth(getElementWidth('datatable'))
  })

  const lineProps = value => ({
    y: value,
    stroke: "#B7B7B7", 
    strokeWidth: 2,
    label: { 
      position: 'left', 
      value: value + '%', 
      fill: '#796255', 
      fontSize: 10 
    }
  });

  const rowNames = ["回答数", "割合"];
  const rowKeys = ["count", "rate"];
  const [, max] = getMinMax(periods, ["rate"])
  const domainMax = scaleUp(max) || 100

  const customizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const data = { ...props, content: ''}
    const customValue = `${value.toString()} %`
    const style = value >= 15 ?
    {
      x: x + width - customValue.replace('.', '').length * 6,
      y: y + height - 9,
      fill: 'white'
    }
    :
    {
      x: x + width + 5,
      y: y + height - 10,
      fill: 'black'
    }

    return (
      <g>
        <text {...data} x={style.x} y={style.y} fill={style.fill} className={valueText} width='150'>
          {customValue}
        </text>
      </g>
    );
  };

  const customizedLabelY = (props) => {
    const { x, y, payload } = props;
    const data = { ...props }
    delete data.verticalAnchor
    delete data.visibleTicksCount
    return (
      <g>
        <rect width="55" height="45" x={x - 40} y={y - 20} fill={NPS_COLORS.lightenColor}/>
        <text {...data} fill='black' className={boldText}>
          <tspan x={x} dy="0.355em">{payload.value}</tspan>
        </text>
      </g>
    );
  }

  const customizedLabelX = (props) => {
    const { x, y, payload } = props;
    const data = { ...props }
    delete data.verticalAnchor
    delete data.visibleTicksCount
    return (
      <g>
        <rect width="600" height="30" x={x - 56} y={y - 23} fill={NPS_COLORS.lightenColor}/>
        <text {...data} fill='black' y={y - 8} className={boldText}>
          {payload.value === 0 && <tspan x={x - 25} dy="0.355em">Pt</tspan>}
          <tspan x={payload.value === 100 ? x - 10 : payload.value === 0 ? x + 20 : x} y={y - 8} dy="0.355em">{payload.value} %</tspan>
        </text>
        <rect width={payload.value === 50 ? 0 : 2} height="30" x={payload.value === 100 ? x + 18 : x} y={y - 23} fill='#ec681a'/>
      </g>
    );
  }

  const SigleChart = ({height, data, bottom, hideX, tick, npsColor}) => (
    <ResponsiveContainer width='100%' height={height} id="graph-periods" className={chartTag}>
      <BarChart width={600} height={height} data={data} barSize={24} reverseStackOrder={true} layout='vertical'
                margin={{top: 5, right: 0, left: 0, bottom: bottom}}>
        <Tooltip cursor={{fill: 'none'}} />
        <XAxis hide={hideX} type="number" domain={[0, 100]} tickCount={3} unit=" %" orientation="top" tickLine={false} tick={tick} axisLine={false}/>
        { [50, 100].map(i => <ReferenceLine key={i} x={i} stroke={NPS_COLORS.lightenColor}strokeWidth={1}/>) }
        <YAxis reversed type="category" dataKey="label" tickLine={false} tickMargin={10} axisLine={false} tick={customizedLabelY}/>
        <Bar dataKey="rate" unit="%" name="割合" >
          <LabelList dataKey="rate" position="right" content={customizedLabel}/>
          { range(11).map(i => <Cell key={i} fill={npsColor} />) }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )

  const barChartForMobile = () => (
    <>
      <SigleChart height={124} data={periods.slice(9)} bottom={5} hideX={false} tick={customizedLabelX} npsColor={NPS_COLORS.promoter} />
      <SigleChart height={95} data={periods.slice(7, 9)} bottom={5} hideX={true} tick={null} npsColor={NPS_COLORS.passive} />
      <SigleChart height={332} data={periods.slice(0, 7)} bottom={32} hideX={true} tick={null} npsColor={NPS_COLORS.detractor} />
    </>
  )

  const barChartForWeb = () => (
    <div id="graph-periods">
      <BarChart width={chartWidth} height={300} data={periods} barSize={50}
                margin={{top: 5, right: 0, left: leftMargin, bottom: 32}}>
        <Tooltip cursor={{fill: 'none'}} />
        <YAxis type="number" domain={[0, domainMax]} tickCount={11} hide={true} stroke={NPS_COLORS.grayColor} />
        {range(10).map(i => <ReferenceLine key={`L${i}`} y={i*domainMax/10} stroke={NPS_COLORS.grayColor}/>)}
        {[0, domainMax/2, domainMax].map(i => <ReferenceLine key={i} {...lineProps(i)} />)}
        <Bar dataKey="rate" unit="%" name="NPSカテゴリ" >
          {range(11).map(i => 
            <Cell key={i} fill={i > 8 ? NPS_COLORS.promoter : i > 6 ? NPS_COLORS.passive : NPS_COLORS.detractor} />
          )}
        </Bar>
      </BarChart>
    </div>
  )

  return (
    <div className={chartGroup}>
      { isMobileOnly ? barChartForMobile() : barChartForWeb() }

      <div className={isMobileOnly ? 'data-table' : showTableForWeb}>
        {loading ? <CircularProgress disableShrink />  
        : <DataTable data={periods} rowHeader="推奨度" rowNames={rowNames} rowKeys={rowKeys} rowWithSurfix={1} unitSurfix='%'/>}
      </div>
    </div>
  )
}

AnalysisPeriods.propTypes = propTypes

export default connect(
  ({
    analysis: {
      periods,
      loading,
      loaded,
    },
    global: { searchParams, filters },
    ga
  }) => ({
    periods,
    loading,
    loaded,
    searchParams,
    ga,
    filters,
  }),
  dispatch => ({
    fetchPeriods: searchParams => dispatch(thunkFetchPeriods(searchParams)),
    setActiveChart: chart => dispatch(setActiveChart(chart)),
  })
)(AnalysisPeriods);
