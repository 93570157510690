import qs from 'query-string';
import {
  fetchBegin,
  fetchPeriodsSuccess,
  fetchTimeSeriesSuccess,
  fetchCorrelationsSuccess,
  fetchFailure,
} from './actions';
import { API_URL } from '../../../../constants';
import { default as axios } from 'axios';
import { handleError } from 'utils';
import {
  EXPORT_FEEDBACK_BEGIN,
  EXPORT_FEEDBACK_FAILURE,
  EXPORT_FEEDBACK_SUCCESS,
} from '../../feedbacks/redux/actions/feedback'

export const thunkFetchPeriods = (searchParams = {}) => {
  return dispatch => {
    dispatch(fetchBegin())
    const params = qs.stringify(searchParams)
    const path = `${API_URL}/analysis/periods${!!params ? '?' + params : ''}`
    return axios.get(path)
      .then(response => {
        const { data } = response
        dispatch(fetchPeriodsSuccess(data))
        return data
      })
      .catch(handleError(dispatch, fetchFailure))
  }
}

export const thunkFetchTimeSeries = (searchParams={}) => {
  return dispatch => {
    dispatch(fetchBegin())
    const params = qs.stringify(searchParams)
    const path = `${API_URL}/analysis/time_series${!!params ? '?' + params : ''}`
    return axios.get(path)
      .then(response => {
        const { data } = response
        dispatch(fetchTimeSeriesSuccess(data))
        return data
      })
      .catch(handleError(dispatch, fetchFailure))
  }
}

export const thunkFetchCorrelations = (searchParams = {}) => {
  return dispatch => {
    dispatch(fetchBegin())
    const params = qs.stringify(searchParams)
    const path = `${API_URL}/analysis/correlations${!!params ? '?' + params : ''}`
    return axios.get(path)
      .then(response => {
        const { data } = response
        dispatch(fetchCorrelationsSuccess(data))
        return data
      })
      .catch(handleError(dispatch, fetchFailure))
  }
}

export const thunkExportFeedbacks = params => dispatch => {
  dispatch({ type: EXPORT_FEEDBACK_BEGIN })
  const url = `${API_URL}/feedbacks.csv?${qs.stringify(params)}`
  return axios.get(url).then(_ => {
    dispatch({ type: EXPORT_FEEDBACK_SUCCESS })
  })
  .catch(exception => {
    const { errors } = exception.response.data
    const error = errors['ActiveRecord'][0]
    dispatch({ type: EXPORT_FEEDBACK_FAILURE, payload: { error } })
  })
}
