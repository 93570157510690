const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const asyncValidate = (values /*, dispatch */) => {
  return sleep(1000).then(() => {
  })
}

export const parseCompany = ({ id, attributes }) => ({
  id: id,
  name: attributes.name,
  use_dashboard: attributes.use_dashboard,
  survey_type: attributes.survey_type,
});

export const getCompanyFromIncluded = ({ id, included }) => {
  let itemData = null;
  
  for (let item of included) {
    if (item.id === id) {
      itemData = item;
      break;
    }
  }

  return itemData === null ? {} : { ...parseCompany(itemData), uid: itemData.attributes.uid };
}
