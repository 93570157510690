import { default as axios } from 'axios';
import {
  FETCH_PROJECTS_BEGIN,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  FETCH_ALL_PROJECTS_BEGIN,
  FETCH_ALL_PROJECTS_SUCCESS,
  FETCH_ALL_PROJECTS_FAILURE,
  SAVE_PROJECT_SUCCESS,
  SAVE_PROJECT_FAILURE,
  SAVE_PROJECT_BEGIN,
  DEACTIVATE_PROJECT,
} from './actions';
import { PAGINATION_PARAM_NO_PAGINATION } from '../../../../constants';
import { getRequestUrl, handleError } from 'utils';
import { PROJECTS_API_PATH } from '../constants';
import { parseProjectWithGoodvocs } from "../utils";
import { Storage } from "../../../../utils";


export const thunkFetchProjects = (
  page=1,
  searchParams,
  actions={
    actionBegin: () => ({ type: FETCH_PROJECTS_BEGIN }),
    actionSuccess: (items, pagination) => ({ type: FETCH_PROJECTS_SUCCESS, payload: { items, pagination } }),
    actionFailure: (error) => ({ type: FETCH_PROJECTS_FAILURE, payload: { error } }),
  }) => {
  return dispatch => {
    dispatch(actions.actionBegin());

    return axios
      .get(getRequestUrl(PROJECTS_API_PATH, page, !!searchParams ? searchParams : {}))
      .then(({ data, headers }) => {

        // ****スコープ外となった為コメントアウト*************
        // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // 
        // projectのattributeに商品スコアにて実装予定の「evaluation_type,calculation_typeを付与する」、apiの対応完了後削除予定// 
        // 一旦取得したprojectは以下の3パターンを割り当てる
        // indexを3で割った余りが
        // 0 => evaluation_type==0,calculation_type==0(11段階、NPSスコア評価)
        // 1 => evaluation_type==1,calculation_type==1(5段階、TOP1率評価)
        // 2 => evaluation_type==1,calculation_type==2(5段階、TOP2率評価)
        // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // 
        // eslint-disable-next-line

        // data.data.map((project,index)=>{
        //   let type = index%3
        //   switch(type){
        //     case 0:
        //       project.attributes ={...project.attributes,evaluation_type:"0" ,calculation_type:"0"}
        //       break
        //     case 1:
        //       project.attributes ={...project.attributes,evaluation_type:"1" ,calculation_type:"1"}
        //       break
        //     case 2:
        //       project.attributes ={...project.attributes,evaluation_type:"1" ,calculation_type:"2"}
        //       break
        //     default:
        //       project.attributes ={...project.attributes,evaluation_type:"0" ,calculation_type:"0"}
            
        //   }

        // })



        dispatch(
          actions.actionSuccess(data, {
            perPage: headers['page-items'],
            total: headers['total-count'],
            offset: (+headers['current-page'] - 1) * +headers['page-items']
          })
        );
      })
      .catch(handleError(dispatch, actions.actionFailure));
  };
};

export const thunkFetchAllProjects = (searchParams) => thunkFetchProjects(
  PAGINATION_PARAM_NO_PAGINATION,
  searchParams,
  {
    actionBegin: () => ({ type: FETCH_ALL_PROJECTS_BEGIN }),
    actionSuccess: (items) => ({ type: FETCH_ALL_PROJECTS_SUCCESS, payload: { items } }),
    actionFailure: (error) => ({ type: FETCH_ALL_PROJECTS_FAILURE, payload: { error } }),
  }
);

export const thunkSaveProject = ({ id, ...params } ) => dispatch => {
  dispatch({ type: SAVE_PROJECT_BEGIN });

  if (!!id) {
    axios.patch(`${PROJECTS_API_PATH}/${id}`, { project: params })
      .then(({ data }) => {
        const item = parseProjectWithGoodvocs((data || {}).data)
        dispatch({ type: SAVE_PROJECT_SUCCESS, payload: { item }})
        if (+Storage.getProjectData().value === +item.id)
          Storage.setProjectData({ value: item.id, label: item.name }, dispatch)
      })
      .catch(handleError(dispatch, (errors) => ({
        type: SAVE_PROJECT_FAILURE,
        payload: { errors }
      })));
  } else {
    axios.post(PROJECTS_API_PATH, { project: params })
      .then(({ data }) => dispatch({ type: SAVE_PROJECT_SUCCESS, payload: { item: parseProjectWithGoodvocs((data || {}).data) } }))
      .catch(handleError(dispatch, (errors) => ({
        type: SAVE_PROJECT_FAILURE,
        payload: { errors }
      })));
  }
};

export const thunkDeleteProject = ({ id }) => dispatch => {
  dispatch({ type: DEACTIVATE_PROJECT, payload: { id }});

  return axios
    .delete(`${PROJECTS_API_PATH}/${id}`)
    .then(() => {})
    .catch((error) => {
      console.error(error);
    });
};
