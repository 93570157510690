import { combineReducers } from "redux";
import projects from "modules/projects/redux/reducers";
import areas from "modules/areas/redux/reducers";
import authentication from "modules/authentication/redux/reducers";
import companies from "modules/companies/redux/reducers";
import shops from "modules/shops/redux/reducers"
import groups from "modules/groups/redux/reducers"
import users from "modules/users/redux/reducers"
import feedbackReducers from "modules/feedbacks/redux/reducers";
import surveys from "modules/surveys/redux/reducers";
import analysis from "modules/analysis/redux/reducers";
import aggregation from "modules/aggregation/redux/reducers";
import dashboard from "modules/dashboard/redux/reducers";
import upload from "common/csv/redux/reducers";
import publicReducer from 'modules/public/redux/reducers'
import gaReducer from './ga';
import common from './common';
import { Storage } from "utils";
import { SET_QUESTION_FILTER } from '../../modules/feedbacks/redux/actions/feedback'

const { feedbacks, comments, answers } = feedbackReducers;

const globalValuesInitialState = {
  isAuthenticated: Storage.isAuthenticated(),
  userData: Storage.getUserData(),
  selectedCompany: Storage.getSelectedCompany(),
  groupData: Storage.getGroupData(),
  shopData: Storage.getShopData(),
  projectData: Storage.getProjectData(),
  surveyData: Storage.getSurveyData(),
  startDate: Storage.getStartDate(),
  endDate: Storage.getEndDate(),
  searchParams: Storage.getSearchParams(),
};

const globalValuesReducer = (state=globalValuesInitialState, action) => {
  if (action.type === 'GLOBAL_VALUES_CHANGED') {
    return {
      isAuthenticated: Storage.isAuthenticated(),
      userData: Storage.getUserData(),
      selectedCompany: Storage.getSelectedCompany(),
      groupData: Storage.getGroupData(),
      shopData: Storage.getShopData(),
      projectData: Storage.getProjectData(),
      surveyData: Storage.getSurveyData(),
      startDate: Storage.getStartDate(),
      endDate: Storage.getEndDate(),
      searchParams: Storage.getSearchParams(),
      filters: Storage.getQuestionFilterQueryParams(),
    }
  } else if (action.type === SET_QUESTION_FILTER) {
    return {
      ...state,
      filters: action.payload.filters
    }
  }

  return { ...state };
}

export default combineReducers({
  dashboard,
  projects,
  areas,
  authentication,
  companies,
  shops,
  groups,
  users,
  feedbacks,
  feedbackComments: comments,
  feedbackAnswers: answers,
  surveys,
  analysis,
  aggregation,
  upload,
  global: globalValuesReducer,
  public: publicReducer,
  common,
  ga: gaReducer,
  public_ga: gaReducer,
});
