import { default as axios } from 'axios';
import { Storage } from './utils';
import { API_URL } from './constants';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const storage = Storage.getStorage();
  // Do something before request is sent
  config.headers['access-token'] = storage.getItem('OAUTH_ACCESS_TOKEN');
  config.headers['client'] = storage.getItem('OAUTH_CLIENT');
  config.headers['uid'] = storage.getItem('OAUTH_UID');
  config.headers['cid'] = storage.getItem('USER_COMPANY_ID');

  return config;

}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  if (error.message === 'Request failed with status code 401') {
    Storage.saveSession(
      true, {
        token: '',
        client: '',
        uid: '',
        expiry: '',
      })

    Storage.saveSession(
      false, {
        token: '',
        client: '',
        uid: '',
        expiry: '',
      })

    if (error.config.url !== `${API_URL}/auth/sign_in`) {
      window.location = '/users/sign_in';
    }
  }

  // Do something with response error
  return Promise.reject(error);
});
