import React from "react";
import { makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import HeaderForm from "common/Header/HeaderForm";
import ShopAppBarBottom from "modules/shops/ShopAppBarBottom";
import CompanyAppBarBottom from "modules/companies/CompanyAppBarBottom";
import UserAppBarBottom from "modules/users/UserAppBarBottom";
import SurveyAdd from "modules/surveys/SurveyAdd";
import SurveyDetailHeader from "modules/surveys/SurveyDetailHeader";
import ProjectAppBarBottom from "modules/projects/ProjectAppBarBottom";
import AreaTopBarBottom from "modules/areas/AreaTopBarBottom";
import GroupAppBarBottom from "modules/groups/GroupAppBarBottom";
// 商品スコア各画面のヘッダー(新規追加ボタン等)
import ProductsAppBarBottom from "modules/products/products/ProductsAppBarBottom";
import ProductUserAppBarBottom from "modules/products/users/UserAppBarBottom";
import ProductSurveyAdd from "modules/products/surveys/SurveyAdd";
import ProductSurveyDetailHeader from "modules/products/surveys/SurveyDetailHeader";
import ProductProjectAppBarBottom from "modules/products/projects/ProductProjectAppBarBottom";
import ProductAreaTopBarBottom from "modules/products/categories/AreaTopBarBottom";
import ProductGroupAppBarBottom from "modules/products/groups/GroupAppBarBottom";

const useStyles = makeStyles(theme => ({
  appBarBottom: {
    height: "56px",
    display: "flex",
    flexDirection: "row",
    padding: "10px 40px",
    boxShadow: "0px 3px 5px #B7B7B733",
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    },

    '& > div': {
      left: '100%',
    }
  }
}));

const AppBarBottom = props => {
  const classes = useStyles(props);
  const { pathname } = useLocation();

  let mainPath = pathname;

  const renderTypeHeader = () => {
    if (/\/admin\/surveys\/\d+/.test(mainPath)) {
      return <SurveyDetailHeader />
    }
    if (/\/admin\/products\/surveys\/\d+/.test(mainPath)) {
      return <ProductSurveyDetailHeader />
    }

    switch (mainPath) {
      case "/admin/shops":
        return <ShopAppBarBottom />;
      case "/admin/users":
        return <UserAppBarBottom />;
      case "/admin/projects":
        return <ProjectAppBarBottom />;
      case "/admin/companies":
        return <CompanyAppBarBottom />;
      case "/admin/surveys":
        return <SurveyAdd />
      case "/admin/areas":
          return <AreaTopBarBottom />
      case "/admin/groups":
        return <GroupAppBarBottom />
        // 商品スコアの各画面
      case "/admin/products/products":
        return <ProductsAppBarBottom />;
      case "/admin/products/users":
        return <ProductUserAppBarBottom />;
      case "/admin/products/projects":
        return <ProductProjectAppBarBottom />;
      case "/admin/products/surveys":
        return <ProductSurveyAdd />
      case "/admin/products/categories":
          return <ProductAreaTopBarBottom />
      case "/admin/products/groups":
        return <ProductGroupAppBarBottom />

      default:
        return <HeaderForm />;
    }
  };

  return <div className={classes.appBarBottom}>{renderTypeHeader()}</div>
};

export default AppBarBottom;
