import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CompanyForm from './CompanyForm';
import { UPDATE_COMPANY_ITEM } from './redux/actions';
import { thunkFetchCompanies } from './redux/thunk';
import CompanyList from './CompanyList';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { themeCompaniesStyles } from "./styles";
import {ExportButton} from "../../common/csv/CsvButton";
import {thunkExportItems} from "../../common/csv/redux/thunk";

const useStyles = makeStyles(themeCompaniesStyles);

const propTypes = {
  total: PropTypes.number,
  updateCompanyItem: PropTypes.func.isRequired,
  itemsLoaded: PropTypes.bool.isRequired,
  itemsLoading: PropTypes.bool.isRequired,
  fetchCompanies: PropTypes.func,
  exportCsv: PropTypes.func.isRequired,
  userAuthority: PropTypes.string,
};

const Companies = ({
  total,
  updateCompanyItem,
  itemsLoaded,
  itemsLoading,
  fetchCompanies,
  exportCsv,
  exportAllStaffCsv,
  userAuthority,
}) => {
  // カレントページ、初期値1
  const [page, setPage] = useState(1);
  // 停止中のアカウントを表示、初期値false
  const [showInactiveItems, setShowInactiveItems] = useState(false);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  
  const firstTimeRefresh = useRef(true);
  // userData.authority、ログインユーザの権限がスーパーアドミンか boolean
  const isSuperAdmin = userAuthority === "super_admin";

  const classes = useStyles();

  // 停止中のアカウントを表示がfalseであれば空オブジェクト{}、trueであれば{status:"with_inactive"}を返す
  const getSearchParams = () => {
    const searchParams = {};
    if (showInactiveItems) searchParams.status = "with_inactive";

    return searchParams;
  }
  // 初期値（画面読み込み時）ではfetchCompanies(1,{})で実行される、何故ラップしているかは不明
  const fetchData = () => {
    fetchCompanies(page, getSearchParams());
  };
// 「停止中のアカウントを表示」、「表示中のページ」いずれかが変更された場合に再度アカウント情報をフェッチする
  useEffect(() => {
    if (firstTimeRefresh.current === false) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [showInactiveItems, page]);

  useEffect(() => {
    if ((!itemsLoaded && !itemsLoading) || firstTimeRefresh.current === true) {
      fetchCompanies();
      firstTimeRefresh.current = false;
    }
    // eslint-disable-next-line
  }, [itemsLoaded, itemsLoading]);

  const openDrawer = (item) => {
    setDrawerIsOpen(true);
    updateCompanyItem(item);
  }

  return (
    <>
      <Grid className={!showInactiveItems ? classes.buttonShowAc : `${classes.buttonShowAc}  ${classes.buttonShowAcActive}`}>
        <div className="buttonShowStore" onClick={() => setShowInactiveItems(!showInactiveItems)}>
          <div>
            <div />
          </div>
          停止中のアカウントを表示
        </div>
      </Grid>
      <Grid className={classes.blockTotal}>
        <div className="txtTotal">
          <p>{total}アカウント</p>
        </div>
        <div className={"buttons"}>
          {isSuperAdmin && <ExportButton text="スタッフ情報一括エクスポート" onClick={() => exportAllStaffCsv({all:1})} />}
          {isSuperAdmin && <ExportButton text="アカウントエクスポート" onClick={() => exportCsv(getSearchParams())} />}
        </div>
      </Grid>
      <div className={classes.blockList}>
        <CompanyList openModal={openDrawer} setPage={setPage} page={page} showInactiveItems={showInactiveItems} />
      </div>
      <CompanyForm modalOpen={drawerIsOpen} isNewCompany={false} closeModal={() => setDrawerIsOpen(false)} />
    </>
  );
}

Companies.propTypes = propTypes;

export default connect(
  ({ companies: { itemsLoaded, itemsLoading, pagination: { total } }, global: { userData } }) => ({
    itemsLoaded,
    itemsLoading,
    total: +(total),
    userAuthority: userData.authority
  }),
  dispatch => ({
    updateCompanyItem: (item) => dispatch({ type: UPDATE_COMPANY_ITEM, payload: { item } } ),
    fetchCompanies: (page=null, searchParams) => dispatch(thunkFetchCompanies(page, searchParams)),
    exportCsv: params => dispatch(thunkExportItems('companies', params)),
    exportAllStaffCsv: (params) => dispatch(thunkExportItems("users", params)),
  })
)(Companies);
