import { NPS_COLORS, DEFAULT_COLOR } from './constants';
import { TYPE_NPS_QUESTION } from 'modules/feedbacks/constants';

export const parseQuestions = questions => {
  let nps = questions.find(question => question.question_type === TYPE_NPS_QUESTION)
  if (nps) {
    nps.body = "NPSカテゴリ";
    return [nps].concat(questions.filter(question => question.id !== nps.id))
  } else {
    return questions
  }
}

export const getNpsColor = id => {
  switch(+(id)){
    case 0: return NPS_COLORS.promoter 
    case 1: return NPS_COLORS.passive 
    case 2: return NPS_COLORS.detractor
    default: return DEFAULT_COLOR 
  }
}

export const range = number => {
  const a = [];
  for (let i=0; i<number; i++) { a.push(i) }
  return a;
}
