export const themedStyles = theme => ({
  root: {
    borderTop: '4px solid #EC681A'
  },
  btnOk: {
    backgroundColor: "#EC681A",
    color: "white",
    minWidth: "120px"
  },
  btnClose: {
    border: "1px solid #EC681A",
    color: "#EC681A",
    minWidth: "120px"
  },
  btnContainer: {
    margin: "40px auto"
  },
  title: {
    color: "black",
    marginBottom: "40px",
    borderBottom: " 1px solid #B7B7B7",
    padding: "20px 10px",
    fontWeight: "bold"
  },
  input: {
    padding: "0 10px"
  }
});
