import qs from 'query-string';
import { default as axios } from 'axios'; 
import {
  FETCH_SHOPS_BEGIN,
  FETCH_SHOPS_SUCCESS,
  FETCH_SHOPS_FAILURE,
  FETCH_ALL_SHOPS_BEGIN,
  FETCH_ALL_SHOPS_SUCCESS,
  FETCH_ALL_SHOPS_FAILURE,
  SAVE_SHOP_BEGIN,
  SAVE_SHOP_SUCCESS,
  SAVE_SHOP_FAILURE,
} from './actions';
import { API_URL, PAGINATION_PARAM_NO_PAGINATION } from '../../../constants';
import { handleError, getRequestUrl } from 'utils';
import { SHOPS_API_PATH } from '../constants';
import { Storage } from "../../../utils";


export const thunkFetchShops = (
  page=1,
  searchParams,
  actions={
    actionBegin: () => ({ type: FETCH_SHOPS_BEGIN }),
    actionSuccess: (items, pagination) => ({ type: FETCH_SHOPS_SUCCESS, payload: { items, pagination } }),
    actionFailure: (error) => ({ type: FETCH_SHOPS_FAILURE, payload: { error } })
  }) => {
  searchParams = !!searchParams ? searchParams : {};

  return dispatch => {
    dispatch(actions.actionBegin());

    return axios.get(getRequestUrl(SHOPS_API_PATH, page, searchParams))
      .then(({ data, headers }) => {
        dispatch(
          actions.actionSuccess(data, {
            perPage: headers['page-items'],
            total: headers['total-count'],
            offset: (+headers['current-page'] - 1) * +headers['page-items']
          })
        );
      })
      .catch(handleError(dispatch, actions.actionFailure));
  };
}

export const thunkFetchAllShops = (searchParams) => thunkFetchShops(
  PAGINATION_PARAM_NO_PAGINATION,
  searchParams,
  {
    actionBegin: () => ({ type: FETCH_ALL_SHOPS_BEGIN }),
    actionSuccess: (items) => ({ type: FETCH_ALL_SHOPS_SUCCESS, payload: { items } }),
    actionFailure: (error) => ({ type: FETCH_ALL_SHOPS_FAILURE, payload: { error } }),
  }
);

export const thunkFetchShopsByQs = () => {
  const q = qs.parse(window.location.search, {ignoreQueryPrefix: true});
  const page = q.page ? +q.page : null;
  const groupId = q.group_id ? q.group_id : null;
  const name = q.q ? q.q : null;

  return thunkFetchShops(page, !!q ? { group_id: groupId, q: name } : {});
}

export const thunkSaveShop = ({ id, ...params }) => dispatch => {
  dispatch({ type: SAVE_SHOP_BEGIN });

  if (!!id) {
    axios.patch(`${API_URL}/shops/${id}`, { shop: params })
      .then(({ data }) => {
        dispatch({ type: SAVE_SHOP_SUCCESS, payload: { data } });
        if (+Storage.getShopData().value === +data.data.id)
          Storage.setShopData({ value: data.data.id, label: data.data.attributes.name }, dispatch)
      })
      .catch(handleError(dispatch, (errors) => ({
        type: SAVE_SHOP_FAILURE,
        payload: { errors }
      })));
  } else {
    axios.post(`${API_URL}/shops`, { shop: params })
      .then(({ data }) => {
        dispatch({ type: SAVE_SHOP_SUCCESS, payload: { data } });
      })
      .catch(handleError(dispatch, (errors) => ({
        type: SAVE_SHOP_FAILURE,
        payload: { errors }
      })));
  }
}
