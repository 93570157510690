import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import { makeStyles, IconButton, FormHelperText } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import {customStyles, themedStyles} from "./styles";

const propTypes = {
  selectable: PropTypes.array,
  selected: PropTypes.array,
  onShopAdd: PropTypes.func,
  onShopRemove: PropTypes.func,
};

const useStyles = makeStyles(themedStyles);

const formatOptionLabel = ({ label }) => (
  <div className="listbox" >
    <div className="rowTextAutocomplete">
      <div className="text">
        <p>{label}</p>
      </div>
      <CheckIcon fontSize="small" />
    </div>
  </div>
);

const Item = ({ label, clearShop }) => (
  <div>
    <p>{label}</p>
    <IconButton onClick={clearShop}>
      <ClearIcon />
    </IconButton>
  </div>
)

const renderShops = ({ selected, onShopRemove}) => {
  return (
    <div className="listUser">
      {(selected || []).map((shop, i) => (
        <Item key={`shop-item-${i}`} label={shop.label} clearShop={() => onShopRemove(i)}/>
      ))}
    </div>
  );
};
const ShopSelection = ({selectable, selected, onShopAdd, onShopRemove, onChangeShop, errors}) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState(selectable)
  const container = document.getElementsByClassName('basic-single')[0]
  const searchBox = document.getElementsByClassName('search-box')[0]

  useEffect(() => {
    if (container && searchBox) {
      const defaultSearchBox = container.getElementsByTagName('input')[0]	
      defaultSearchBox.setAttribute('disabled', true)

      const handleClick = ({target}) => {
        const clickInside = container.contains(target) || (target === searchBox)
        setOpen(clickInside)
        if (!clickInside) {
          searchBox.value = ''
          setOptions(selectable)
        }
      }
      if (container) {
        window.removeEventListener('click', handleClick)
        window.addEventListener('click', handleClick)
      }
    }
  }, [container, searchBox, selectable])

  useEffect(() => {
    setOptions(selectable)
  }, [selectable])

  const classes = useStyles()

  const inputChange = ({target}) => {
    const value = target.value
    const searchList = selectable.filter(option => option.label.toLowerCase().includes(value))
    setOptions(searchList)
  }

  const onSelect = value => {
    onShopAdd(value)
    setOptions(selectable)
  }

  return (
    <div className={classes.blockListUser}>
      <div>
        <div className="search">
          <p>所属店舗</p>
          <input
            onChange={inputChange}
            placeholder="所属店舗を選択"
            className='search-box'
          />
          <Select
            onChange={onSelect}
            value={selected}
            isMulti
            formatOptionLabel={formatOptionLabel}
            name="shop"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            placeholder="所属店舗を選択"
            styles={customStyles}
            options={options}
            className="basic-single"
            isClearable={false}
            error={!!errors.shops}
            menuIsOpen={open}
          />
        </div>
        {!!errors.shops && <FormHelperText>{errors.shops}</FormHelperText>}
        {renderShops({ selected, onShopRemove})}
      </div>
    </div>
  );
};

ShopSelection.propTypes = propTypes

export default ShopSelection
