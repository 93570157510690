import validate from 'validate.js';
import {
  QUESTION_TYPE_CHECK_BOX,
  QUESTION_TYPE_SELECT_USER,
  QUESTION_TYPE_SELECT,
  QUESTION_TYPE_RADIO_BUTTON,
  QUESTION_TYPE_SELECT_BUTTON,
  QUESTION_TYPE_NPS_QUESTION,
  QUESTION_TYPE_FILE_UPLOAD,
  QUESTION_TYPE_DATE,
  QUESTION_TYPE_NPS_REASON,
  QUESTION_TYPE_TEXT_AREA,
  QUESTION_TYPE_TEXT_FIELD,
  FREE_ENTRY_VALIDATION_KATAKANA,
  FREE_ENTRY_VALIDATION_HIRAGANA,
  FREE_ENTRY_VALIDATION_ALPHANUMERIC,
  FREE_ENTRY_VALIDATION_NUMERIC,
  FREE_ENTRY_VALIDATION_NUMERIC_SYMBOL,
  FREE_ENTRY_VALIDATION_NO_LIMIT
} from 'modules/surveys/form/questions/constants';


export const htmlCss = (styles) => {
  return `
    <style type="text/css">${styles}</style>
  `;
}

export const generateValidationConstraints = ({ questionItems, answers }) => {
  let validationConstraints = {};

  questionItems.forEach(questionItem => {
    let constraint = {};

    if (questionItem.question_type === QUESTION_TYPE_CHECK_BOX) {
      const min_selection_count = questionItem.is_required && +questionItem.min_selection_count === 0 ? 1 : +questionItem.min_selection_count;

      constraint = {
        answer_options_attributes: {
          presence: { message: "^選択してください" },
          length: {
            minimum: min_selection_count,
            maximum: +questionItem.max_selection_count,
            message: `^${min_selection_count}個以上で${+questionItem.max_selection_count}個以下選択してください`
          }
        }
      }

      const questionFreeAnswerOptions = (questionItem.question_options_attributes || []).filter(({ is_free_answer }) => is_free_answer === true).map(({ id }) => String(id));
      const questionAnswer = answers[questionItem.id] || {};
      const questionAnswerOptions = (questionAnswer.answer_options_attributes || []).map(({ question_option_id }) => String(question_option_id));

      if (questionFreeAnswerOptions.filter(value => questionAnswerOptions.includes(value)).length > 0) {
        constraint = {
          ...constraint,
          answer_entry: { presence: {  allowEmpty: false, message: "^入力してください" } }
        }
      }

      validationConstraints[questionItem.id] = constraint;
      return;
    }

    if (questionItem.question_type === QUESTION_TYPE_SELECT_USER) {
      constraint = {
        ...constraint,
        user_id: {
          presence: { message: "^選択してください" },
        }
      }
    }

    if (questionItem.is_required === true) {
      if ([
        QUESTION_TYPE_SELECT,
        QUESTION_TYPE_RADIO_BUTTON,
        QUESTION_TYPE_SELECT_BUTTON,
        QUESTION_TYPE_NPS_QUESTION,
      ].includes(questionItem.question_type)) {
        constraint = {
          ...constraint,
          answer_options_attributes: {
            presence: { message: "^選択してください" },
            length: { is: 1, message: "^選択してください" }
          }
        }

        const questionFreeAnswerOptions = (questionItem.question_options_attributes || []).filter(({ is_free_answer }) => is_free_answer === true).map(({ id }) => String(id));
        const questionAnswer = answers[questionItem.id] || {};
        const questionAnswerOptions = (questionAnswer.answer_options_attributes || []).map(({ question_option_id }) => String(question_option_id));

        if (questionFreeAnswerOptions.filter(value => questionAnswerOptions.includes(value)).length > 0) {
          constraint = {
            ...constraint,
            answer_entry: { presence: { allowEmpty: false, message: "^入力してください" } }
          }
        }

      } else if (QUESTION_TYPE_SELECT_USER === questionItem.question_type) {

      } else if (QUESTION_TYPE_FILE_UPLOAD === questionItem.question_type) {
        constraint = {
          ...constraint,
          answer_image: { presence: { allowEmpty: false, message: "^選択してください" } },
        };
      } else if (questionItem.question_type === QUESTION_TYPE_DATE) {
        constraint = {
          ...constraint,
          answer_date: { presence: { allowEmpty: false, message: "^選択してください" } }
        }
      } else {
        constraint = {
          ...constraint,
          answer_entry: { presence: { allowEmpty: false, message: "^入力してください" } }
        }
      }

      validationConstraints[questionItem.id] = constraint;
    }

    if ([
      QUESTION_TYPE_NPS_REASON,
      QUESTION_TYPE_TEXT_AREA,
      QUESTION_TYPE_TEXT_FIELD,
    ].includes(questionItem.question_type)) {
      constraint = {
        answer_entry: (value) => {
          if (questionItem.is_required) {
            if (!!!value) {
              return {
                presence: { allowEmpty: false, message: "^入力してください" }
              }
            }
          }

          return {
            ...(constraint.answer_entry || {}),
            format: {
              pattern: {
                exec: (value) => {
                  let characterTypeValidationFails = false;

                  if (questionItem.free_entry_validation === FREE_ENTRY_VALIDATION_KATAKANA) {
                    characterTypeValidationFails = !!!(value.match(/^[\u{3000}-\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}]*$/mu) ? [value] : null)
                  }

                  if (questionItem.free_entry_validation === FREE_ENTRY_VALIDATION_HIRAGANA) {
                    characterTypeValidationFails = !!!(value.match(/^[\u{3000}-\u{301C}\u{3041}-\u{3093}\u{309B}-\u{309E}]*$/mu) ? [value] : null)
                  }

                  if (questionItem.free_entry_validation === FREE_ENTRY_VALIDATION_ALPHANUMERIC) {
                    characterTypeValidationFails = !!!(value.match(/^[A-z0-9]*$/mu) ? [value] : null)
                  }

                  if (questionItem.free_entry_validation === FREE_ENTRY_VALIDATION_NUMERIC) {
                    characterTypeValidationFails = !!!(value.match(/^[0-9]*$/mu) ? [value] : null)
                  }

                  if (questionItem.free_entry_validation === FREE_ENTRY_VALIDATION_NUMERIC_SYMBOL) {
                    characterTypeValidationFails = !!!(value.match(/^[^@\s]+@[^@\s]+$/mu) || value === '' ? [value] : null)
                  }

                  return characterTypeValidationFails ? null : [value]
                }
              },
              message: () => {
                let errMessage = '';
                if (questionItem.free_entry_validation !== FREE_ENTRY_VALIDATION_NO_LIMIT) {
                  errMessage = '%{charType}入力してください。';
                }

                return validate.format(`^${errMessage}`, {
                  charType: {
                    [FREE_ENTRY_VALIDATION_KATAKANA]: 'カタカナのみ',
                    [FREE_ENTRY_VALIDATION_HIRAGANA]: 'ひらがなのみ',
                    [FREE_ENTRY_VALIDATION_ALPHANUMERIC]: '半角英数のみ',
                    [FREE_ENTRY_VALIDATION_NUMERIC]: '半角数字のみ',
                    [FREE_ENTRY_VALIDATION_NUMERIC_SYMBOL]: '無効な形式です、メールアドレスを',
                  }[questionItem.free_entry_validation],
                });
              }
            },
            length: {
              minimum: +questionItem.min_character_count,
              maximum: +questionItem.max_character_count,
              message: `^${questionItem.min_character_count}文字以上で${+questionItem.max_character_count}文字以下で入力してください`
            }
          }
        },
      };
    }

    validationConstraints[questionItem.id] = constraint;
  });

  return validationConstraints;
};
export const labelTextRender = (shopName = "", value = "") =>{
  // アンケート回答画面、単一選択プルダウンのラベル表示用
  if(value){
    const SELECTED_NAME = '{%SELECTED_NAME%}'
    const SELECTED_IMAGE = '{%SELECTED_IMAGE%}'
    const label = value.replace(SELECTED_NAME, shopName).replace(SELECTED_IMAGE, '')
    return label
  }else{
    return ''
  }
}
