import React, { useRef } from "react";
import clsx from "clsx";
import { useDrag, useDrop } from "react-dnd";
import { makeStyles, IconButton, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import IconDrag from "assets/icons/icon_custom_dragable.svg";
import { themedStyles } from "./styles";


const useStyles = makeStyles(themedStyles);

const Item = ({
  type,
  parentId,
  index,
  ownItem,
  dataSet,
  currentItemId,
  setCurrentItemId,
  moveCard,
  hoverJSON,
  openCreateModal,
  openUpdateModal,
  listParentName
}) => {
  const classes = useStyles();

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: type,
    hover(currentDragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = currentDragItem.index;
      const hoverIndex = index;

      const dragId = currentDragItem.id;
      const hoverId = ownItem.id;

      // don't replace outside parent
      if (currentDragItem.parentId !== parentId) {
        return;
      }

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(
        dragIndex,
        hoverIndex,
        dragId,
        hoverId,
        currentDragItem.dragJSON,
        hoverJSON,
        parentId,
        "hover",
      );
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      currentDragItem.index = hoverIndex;
      
      // item display_order change
      let dragObj = JSON.parse(currentDragItem.dragJSON);
      let hoverObj = JSON.parse(hoverJSON);
      dragObj.display_order = hoverObj.display_order;
      currentDragItem.dragJSON = JSON.stringify(dragObj);
    },
    drop(currentDragItem, monitor) {
      if (!ref.current) {
        return;
      }
      // don't use drop for same parent or parent
      if (currentDragItem.parentId === parentId || currentDragItem.parentId === ownItem.id) {
        return;
      }

      const dragIndex = currentDragItem.index;
      const hoverIndex = index;
      const dragId = currentDragItem.id;
      const hoverId = ownItem.id;
      // Time to actually perform the action
      moveCard(
        dragIndex,
        hoverIndex,
        dragId,
        hoverId,
        currentDragItem.dragJSON,
        hoverJSON,
        parentId,
        "drop",
      );
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type,
      id: ownItem.id,
      index,
      parentId,
      dragJSON: JSON.stringify(ownItem)
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const active = currentItemId === ownItem.id;

  const handleCreateNew = (e) => {
    e.stopPropagation();
    openCreateModal(ownItem, ownItem.id, listParentName);
  }

  return (
    <div
      ref={preview}
      style={{ opacity }}
      className={clsx(classes.root)}
      onMouseEnter={() => setCurrentItemId(ownItem.id)}
    >
      <img alt="" className={classes.iconDrag} src={IconDrag} ref={ref}/>
      <div className={classes.itemWrapperLeft}>
        <Button
          className={clsx(classes.container, active && classes.itemActive)}
          onClick={() => openUpdateModal(ownItem, ownItem.id, listParentName)}
        >
          <div className={classes.textBox}>
            {ownItem.name}
          </div>
        </Button>

        <IconButton
          className={classes.addBtn}
          color="secondary"
          onClick={handleCreateNew}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default Item;
