import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'
import { Paper } from '@material-ui/core'
import MultipleSelect, { components } from 'react-select'
import ElementIcon from 'common/icon/ElementIcon'
import { customSelectorStyles, themeUserSelectBarStyles } from '../modules/users/styles'
import NoOptionsMessage from '../modules/surveys/components/NoOptionsMessage'

const useStyles = makeStyles(themeUserSelectBarStyles)

const MultiSelectSearchBar = ({ options=[], queryOptions, onSelect, selected, placeholder='選択肢を追加' }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(selected || [])
  const groupedOptions = [{ label: '検索結果', options }]

  const Query = async (q) => {
    setLoading(true)
    queryOptions(q)
    setLoading(false)
  }

  const onFocus = async () => {
    Query(null)
  }

  const onType = q => {
    Query(q)
  }

  const selectedOptionsChange = selectedOnes => {
    setSelectedOptions(selectedOnes)
    onSelect && onSelect(selectedOnes)
  }

  const DropdownIndicator = props => {
    const { DropdownIndicator } = components
    return (
      <DropdownIndicator {...props}>
        <ElementIcon name='search' />
      </DropdownIndicator>
    )
  }

  const Option = props => {
    const { Option } = components
    return (
      <Option {...props}>
        <div className={classes.selectOption}>
          <p>
            <strong className={props.isSelected ? 'selected' : ''}>{props.data.label}</strong>
            <br />
            {props.data.email}
          </p>
          {props.isSelected && <ElementIcon name='check' />}
        </div>
      </Option>
    )
  }

  const formatGroupLabel = ({ label, options }) => {
    if (!options || options.length === 0) {
      return <></>
    }
    return (
      <div className={classes.customList}>
        <p>{label} ({options.length})</p>
      </div>
    )
  }

  return (
    <Paper className={classes.root} style={{
      display: 'unset',
      maxWidth: '100%',
      marginBottom: 0,
      padding: 0,
    }}>
      <div className={classes.searchbox}>
        <MultipleSelect
          components={{ DropdownIndicator, Option, NoOptionsMessage: () => <NoOptionsMessage loading={loading} /> }}
          formatGroupLabel={formatGroupLabel}
          onFocus={onFocus}
          onChange={selectedOptionsChange}
          onInputChange={onType}
          filterOption={(options, filter, current_values) => options}
          value={selectedOptions}
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          placeholder={selectedOptions && selectedOptions.length > 0 ? '' : placeholder}
          options={groupedOptions}
          isSearchable
          styles={customSelectorStyles(isMobile)}
        />
      </div>
    </Paper>
  )
}

export default MultiSelectSearchBar
