export const commonSetting = theme => ({
  root: {
    width: 770,
    padding: 30,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: 0,
    borderLeft: "4px solid #B7B7B7",
    boxShadow: "0px 5px 6px #B7B7B733",
    opacity: 1,
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "auto"
    }
  },
  radio: {
    "&$checked": {
      color: "#EC681A"
    }
  },
  checked: {},
  selectBox: {
    width: 220,
    marginRight: 10,
    borderRadius: 5
  },
  selectTree: {
    width: "auto",
    minWidth: 230,
    marginRight: 10,
    marginTop: 6,
    position: 'relative',
    "& > *":{
      "& > *":{
        font: "300 13px/16px 'Noto Sans JP', sans-serif",
      },
    }
  },
  selectTreePlaceholder: {
    '& input': {
      color: '#ccc !important',
    }
  },
  radioGroup: {
    width: "100%",
    height: 36,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #B7B7B7",
    borderRadius: 5,
    marginRight: 10,
    marginLeft: 0
  },
  formLabel: {
    color: "#1A0707",
    fontWeight: "bold",
    fontSize: 12,
    fontFamily: "Hiragino Sans",
    marginBottom: 9,
    display: "flex"
  },
  formSection: {
    width: "100%",
    marginTop: 36,
    "& label.anKetRadioButton:nth-child(1)": {
      marginLeft: 0
    },
    "& label.anKetRadioButton:nth-child(2)": {
      marginLeft: 10
    }
  },
  formInputBase: {
    height: 36,
    padding: "14px 0",
    display: "flex"
  },
  formTextarea: {
    width: "100%",
    height: 120,
    padding: "12px 20px",
    resize: "none",
    border: "1px solid #B7B7B7"
  },
  msgArea: {
    padding: "0 20px",
    display: "flex",
    color: "#1A0707",
    fontSize: 10,
    letterDSpacing: 0.25
  },
  formDatePicker: {
    width: 210,
  },
  formTimePicker: {
    width: 106,
    marginLeft: "10px !important"
  },
  formPicker: {
    '& > div': {
      padding: '0 !important'
    },

    '& input': {
      paddingRight: '30px !important',
    },

    "& .MuiInputAdornment-positionEnd": {
      width: '24px',
      height: '24px',
      position: 'absolute',
      margin: '0 !important',
      right: '5px',
      top: '50%',
      transform: 'translateY(-50%)',

      '& button': {
        padding: "0"
      }
    },
  },
  anketGroupRadio: {
    marginTop: 10,
    flexDirection: "row !important"
  },
  anketRadioButton: {
    border: "1px solid #B7B7B7",
    borderRadius: 5,
    marginLeft: "0 !important",
    padding: "10px 20px 10px 16px",
    width: 200,
    height: 36,
    "& span.MuiIconButton-label": {
      width: 14,
      height: 14
    },
    "& > span:nth-child(1)": {
      padding: 0
    },
    "& > span:nth-child(2)": {
      marginLeft: 15,
      fontSize: 13,
      letterSpacing: 0.25
    }
  },
  line: {
    margin: "30px 0"
  },
  blockFromDate: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 10
    }
  },
  commonHtml: {
    marginTop: "30px",
    "& >legend": {
      font: "600 12px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      opacity: 1
    },
    '& div': {
      width: '100%',
    }
  },
  marginFromLeft: {
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
    }
  },
  textField: {
    '& input': {
      border: '1px solid #ccc !important',
      borderRadius: 4,
      height: '36px',
      boxSizing: 'border-box',
      opacity: '1 !important',
      padding: '9px 12px',

      '&:-ms-input-placeholder': {
        color: '#ccc !important',
      },

      '&:hover': {
        border: '1px solid #1A0707 !important',
      },

      '&:focus': {
        border: '2px solid #EC681A !important',
      }
    },

    '& textarea': {
      border: '1px solid #ccc !important',
      borderRadius: 4,
      boxSizing: 'border-box',
      opacity: '1 !important',
      padding: '9px 12px',
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',

      '&:-ms-input-placeholder': {
        color: '#ccc !important',
      },

      '&:hover': {
        border: '1px solid #1A0707 !important',
      },

      '&:focus': {
        border: '2px solid #EC681A !important',
      }
    },

    '& fieldset': {
      display: 'none',
    }
  },
  btnClose: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    right: 40,
    transform: 'translateY(-50%)',
    zIndex: 10
  }
});

export const conditionsSetupModalStyles = theme => ({
  conditionsSetupModal: {
    "& >.underlinedTop": {
      background: "#EC681A 0% 0% no-repeat padding-box",
      height: "2px"
    },
    "& >.conditionsSetupModalContent": {
      padding: "22px 30px",
      "& >.header": {
        borderBottom: "1px solid #B7B7B7",
        "& >p": {
          font: "600 14px/21px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.35px",
          color: "#1A0707",
          opacity: 1,
          display: "inline-block"
        },
        "& >div": {
          float: "right",
          display: "flex",
          "& >.buttonSetup": {
            background: "#F02544 0% 0% no-repeat padding-box",
            borderRadius: "5px",
            opacity: 1,
            font: "600 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro",
            letterSpacing: "0.25px",
            color: "#FFFFFF",
            padding: "8px 20px",
            cursor: "pointer"
          },
          "& >.buttonDel": {
            marginLeft: 10,
            background: "#B7B7B7 0% 0% no-repeat padding-box",
            borderRadius: "5px",
            opacity: 1,
            font: "600 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro",
            letterSpacing: "0.25px",
            color: "#FFFFFF",
            padding: "8px 20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            "& >i": {
              fontSize: "20px",
              marginLeft: "10px"
            },
            "&:hover": {
              background: "#EC681A 0% 0% no-repeat padding-box",
              color: "#FFF",
              border: "1px solid #EC681A"
            }
          }
        }
      },

      "& >.content": {
        overflow: 'hidden',
        "& >p": {
          font: "600 12px/21px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.3px",
          color: "#1A0707",
          opacity: 1
        },
        "& >.expansionPanel": {
          marginTop: 10,
          boxShadow: "none",
          "& >div:nth-child(1)": {
            background: "#F2EEEC 0% 0% no-repeat padding-box",
            opacity: 1,
            minHeight: "5px",
            padding: "14px 6px",
            lineHeight: 0,
            "& >div:nth-child(1)": {
              margin: 0,
              "& >p": {
                margin: 0,
                font: "300 10px/16px Noto Sans JP, Hiragino Sans",
                letterSpacing: "0.25px",
                color: "#1A0707",
                opacity: 1
              }
            },
            "& >div:nth-child(2)": {
              margin: 0,
              padding: 0,
              "& >span":{
                "& >i":{
                  fontSize: "15px",
                  color: "#707070"
                }
              }
            }
          },
          "& .MuiExpansionPanelSummary-expandIcon.Mui-expanded":{
            transform: "rotate(90deg)"
          },
          "& >.Mui-expanded": {
            background: "#F2EEEC 0% 0% no-repeat padding-box",
            opacity: 1,
            minHeight: "5px",
            padding: "14px 6px",
            lineHeight: 0,
            borderBottom: "0.6000000238418579px solid #EC681A"
          },
          "& .expansionDetail": {
            padding: 0,
            display: "block"
          }
        }
      }
    }
  }
});

export const conditionQuestionOptionsStyles = theme => ({
  blockCheckbox: {
    display: "block",
    alignItems: "center",
    borderBottom: "1px solid #B7B7B7",
    padding: "11px 6px",
    marginLeft: "20px",
    "& .MuiIconButton-label": {
      border: '1px solid #1A0707',
      height: 18,
      width: 18,
      position: 'relative',
    },
    "& .MuiIconButton-label ~ span": {
      display: 'none',
    },
    "& >span": {
      padding: "9px 9px 9px 0",
      "&:hover": {
        background: "#fff"
      },
      "& >span": {
        "& >svg": {
          fontSize: 22,
          color: "#1A0707"
        }
      }
    },
    "& >.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#ec681a",
      "& >span:nth-child(1)": {
        border: "1px solid #1A0707",
        "& >i": {
          fontSize: 16
        }
      }
    },
    "& >.MuiCheckbox-colorSecondary.Mui-checked:hover": {
      backgroundColor: "#FFF"
    },
    "& >p": {
      display: "inline-block",
      margin: 0,
      textAlign: "left",
      font: "300 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      width: 200,
      whiteSpace: 'nowrap',
    }
  },
  blockCheckboxChecked: {
    borderBottom: "1px solid #EC681A"
  }
});
