import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  withStyles,
} from '@material-ui/core'
import { ExportButton } from './CsvButton'
import ButtonWithIcon from '../buttons/ButtonWithIcon'
import ElementIcon from 'common/icon/ElementIcon'
import Select, { components } from 'react-select'
import { customSelectorStyle, dialogStyles, dialogTitleStyle } from './styles'
import { Cancel, Close } from '@material-ui/icons'
import SwitchBtn from '../../components/SwitchBtn'

const propTypes = {
  disabled: PropTypes.bool,
}

export const MuiDialogTitle = withStyles(dialogTitleStyle)(props => {
  const { label, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{label}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const DropdownIndicator = props => {
  const { DropdownIndicator } = components
  return (
    <DropdownIndicator {...props}>
      <ElementIcon name="caret-bottom" />
    </DropdownIndicator>
  )
}

const options = [
  { value: '0', label: '選択肢番号で出力' },
  { value: '1', label: '選択肢名で出力' },
]

const Bold = styled.div`
  display: inline-block;
  min-width: 130px;
  font-weight: bold;
`

const SuccessReport = () => (
  <div>
    Excelファイル生成を受け付けました。<br />Excelファイルが出来上がるとメールでダウンロードリンクが通知されます。
  </div>
)

const ExportDialog = ({
  disabled,
  exportFunction,
  project_name,
  survey_name,
  group_name,
  store_name,
  sdate,
  edate,
  fb_viewable,
  error,
  exporting,
  children,
  onClose,
}) => {
  const { dialogContent, dialogPaper } = dialogStyles()
  const [open, setOpen] = useState(false)
  const [generalInfo, setGeneralInfo] = useState(false)
  const [selected, setSelected] = useState(options[0])
  const container = useRef(null)
  const success = !error && !exporting && disabled

  const openModal = () => setOpen(true)
  const closeModal = () => {
    setOpen(false)
    onClose && onClose()
  }
  const onExport = e =>
    exportFunction(e, { option_name: selected.value, general_info: generalInfo ? 0 : 1})

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        maxWidth={'sm'}
        fullWidth
        classes={{ paper: dialogPaper }}>
        <MuiDialogTitle onClose={closeModal} label="フィードバックエクスポート" />
        <DialogContent dividers ref={container} classes={{ root: dialogContent }}>
          {success ? (
            <SuccessReport />
          ) : (
            <>
              <Select
                components={{ DropdownIndicator }}
                placeholder=""
                options={options}
                onChange={newValue => setSelected(newValue)}
                value={selected}
                styles={customSelectorStyle}
              />
              <div style={{ margin: '20px 0', fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {project_name && (
                  <div>
                    <Bold>プロジェクト</Bold>: {project_name}
                  </div>
                )}
                {survey_name && (
                  <div>
                    <Bold>アンケート</Bold>: {survey_name}
                  </div>
                )}
                {group_name && (
                  <div>
                    <Bold>グループ</Bold>: {group_name}
                  </div>
                )}
                {store_name && (
                  <div>
                    <Bold>店舗</Bold>: {store_name}
                  </div>
                )}
                {(sdate || edate) && (
                  <div>
                    <Bold>期間</Bold>: {sdate} ~ {edate}
                  </div>
                )}
              </div>
              {fb_viewable && (
                <SwitchBtn
                  name={`personal_info`}
                  checked={generalInfo}
                  label={'個人情報を含める'}
                  onChange={e => setGeneralInfo(e.target.checked)}
                />
              )}
              {error && <div style={{ color: 'red' }}>{error}</div>}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!success && (
            <ButtonWithIcon
              startIcon={<ElementIcon name={`download`} />}
              variant="outlined"
              onClick={onExport}
              color="primary"
              text="OK"
            />
          )}
          <ButtonWithIcon
            autoFocus
            variant="outlined"
            onClick={closeModal}
            startIcon={<Cancel />}
            text="閉じる"
            color="default"
          />
        </DialogActions>
      </Dialog>
      <div style={{ display: children ? 'block' : 'inline-block'}} onClick={openModal}>
        {children || <ExportButton text="CSVエクスポート" disabled={disabled}/>}
      </div>
    </>
  )
}

ExportDialog.propTypes = propTypes

export default connect(({ global, feedbacks }) => ({
  project_name: global.projectData.label,
  survey_name: global.surveyData.label,
  group_name: global.groupData.label,
  store_name: global.shopData.label,
  sdate: global.searchParams.sdate,
  edate: global.searchParams.edate,
  fb_viewable: global.userData.fb_viewable === "true",
  error: feedbacks.error,
  exporting: feedbacks.exporting,
}))(ExportDialog)
