import React from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { Storage } from '../../../utils'
import { makeStyles } from '@material-ui/core/styles'
import { themeDashboardStyles } from './styles'
import LikeInfo from './LikeInfo'
import { thunkFetchGoodvocs, thunkFetchNpsSatisfactions, thunkFetchProductRankings } from './redux/thunk'
import TableGraph from './table-graph'
import CustomPaper from './CustomPaper'
import RadialGraph from './radial-graph/RadialGraph'
import RadialLegend from './radial-graph/RadialLegend'
import Goodvocs, { VocHeader } from './goodvocs'
// import UserRank from './rankings/UserRank'
import CommonRank from './rankings/CommonRank'
import SelectProjectWarning from '../../common/SelectProjectWarning'
import { useGAEffect } from '../../../ga'
import { CLEAR_RANKS } from './redux/actions'
import FeedbackSurveyFilter from '../feedbacks/FeedbackSurveyFilter'

const useStyles = makeStyles(themeDashboardStyles)

const Dashboard = ({
  selectedGroup,
  selectedShop,
  selectedUserGroup: r_group_id,
  selectedUserShop: r_shop_id,
  ranking_trim30,
  goodvocsLoading,
  goodvocsError,
  searchParams,
  fetchNpsSatisfactions,
  fetchGoodvocs,
  fetchProductRankings,
  clearRanks,
  ga,
  filters,
}) => {
  const { group_id, shop_id, project_id, survey_id, sdate, edate } = searchParams
  const users = JSON.stringify(searchParams['user_ids[]'])
  const classes = useStyles()
  const fetchGroupRanks = () => fetchProductRankings('group', { r_group_id: selectedGroup, ranking_trim30: (ranking_trim30.group || false), ...filters })
  const fetchShopRanks = () => fetchProductRankings('shop', { r_group_id: selectedShop, ranking_trim30: (ranking_trim30.shop || false), ...filters })
  // const fetchUserRanks = () => fetchRankings('user', { r_group_id, r_shop_id, ranking_trim30: (ranking_trim30.user || false), ...filters })

  React.useEffect(() => {
    fetchNpsSatisfactions(filters)
    !goodvocsLoading && fetchGoodvocs(filters)
    clearRanks()
    // eslint-disable-next-line
  }, [project_id, survey_id, group_id, shop_id, users, sdate, edate, filters])

  useGAEffect(ga, null, "ダッシュボード")

  if (!!!project_id) {
    return <SelectProjectWarning />
  }

  return (
    <>
      <FeedbackSurveyFilter />
      <Grid container spacing={2} className={classes.paperContainer}>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item>
              <CustomPaper header="PXスコア">
                <div className={classes.graphsContainer}>
                  <RadialGraph />
                  <RadialLegend />
                </div>
              </CustomPaper>
            </Grid>
            <Grid item>
              <CustomPaper header="認知賞賛">
                <LikeInfo />
              </CustomPaper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomPaper header="顧客接点満足度">
            <TableGraph />
          </CustomPaper>
        </Grid>
        {!goodvocsError.includes('403') && (
          <Grid item xs={12} sm={12} md={12}>
            <CustomPaper header={<VocHeader />}>
              <Goodvocs />
            </CustomPaper>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <CustomPaper header="グループランキング">
            <CommonRank name={'group'} onReload={fetchGroupRanks} />
          </CustomPaper>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomPaper header="商品ランキング">
            <CommonRank name={'shop'} onReload={fetchShopRanks}/>
          </CustomPaper>
        </Grid>
        {/* 商品スコアではスタッフランキングは非表示 */}
        {/* <Grid item xs={12} sm={12} md={12}>
          <CustomPaper header="スタッフランキング">
            <UserRank onReload={fetchUserRanks}/>
          </CustomPaper>
        </Grid> */}
      </Grid>
    </>
  )
}

export default connect(
  ({ global: { searchParams, filters }, dashboard: { group, shop, user, goodvocs, ranking_trim30}, ga }) => ({
    searchParams,
    selectedGroup: (group.selected || {}).value,
    selectedShop: (shop.selected || {}).value,
    selectedUserGroup: (user.group || {}).value,
    selectedUserShop: (user.shop || {}).value,
    ranking_trim30: (ranking_trim30 || {}),
    goodvocsLoading: goodvocs.loading,
    goodvocsError: goodvocs.error || '',
    ga,
    filters,
  }),
  dispatch => ({
    fetchNpsSatisfactions: params => dispatch(thunkFetchNpsSatisfactions({ ...Storage.getSearchParams(), ...params })),
    fetchGoodvocs: params => dispatch(thunkFetchGoodvocs({ ...Storage.getSearchParams(), ...params })),
    fetchProductRankings: (name, params) =>
      dispatch(thunkFetchProductRankings(name, { ...Storage.getSearchParams(), ...params })),
    clearRanks: () => dispatch({ type: CLEAR_RANKS }),
  })
)(Dashboard)
