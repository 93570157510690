import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';

const propTypes = {};

const useStyles = makeStyles(theme => ({
  icon: {
    width: 24,
    height: 24,
    margin: 14,
  },
  image: {
    width: '100%',
    height: '100%',
    display: 'block',
  },
}));

const IconCustom = ({ src, alt="", className, setKeyword, setQ}) => {
  const classes = useStyles();

  const handleClearSearch = () => {
    setKeyword && setKeyword('');
    setQ && setQ('');
  };

  return (
    <Icon className={`${className || classes.icon}`} onClick={handleClearSearch}>
      <img src={src} alt={alt} className={classes.image} />
    </Icon>
  );
}

IconCustom.propTypes = propTypes;

export default IconCustom;
