import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ShopList from './ShopList';
import ShopListTools from './ShopListTools';
import ShopForm from './ShopForm';
import { thunkFetchShops } from './redux/thunk';
import { UPDATE_SHOP_ITEM } from './redux/actions';


const propTypes = {
  total: PropTypes.number,
  itemsLoading: PropTypes.bool.isRequired,
  itemsLoaded: PropTypes.bool.isRequired,
  updateShopItem: PropTypes.func.isRequired,
  fetchShops: PropTypes.func.isRequired,
};

const Shops = ({
  itemsLoading,
  itemsLoaded,
  updateShopItem,
  fetchShops,
}) => {
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');

  const [showInactiveItems, setShowInactiveItems] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const firstTimeRefresh = useRef(true);

  const getSearchParams = () => {
    const searchParams = {};
    if (showInactiveItems) searchParams.status = "with_inactive";
    if (!!name) searchParams.q = name;

    return searchParams;
  }

  const fetchData = () => {
    fetchShops(page, getSearchParams());
  }

  useEffect(() => {
    if (firstTimeRefresh.current === false) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [showInactiveItems, page, name]);

  useEffect(() => {
    if ((!itemsLoading && !itemsLoaded) || firstTimeRefresh.current === true) {
      fetchShops();
      firstTimeRefresh.current = false
    }

    // eslint-disable-next-line
  }, [itemsLoading, itemsLoaded]);

  const openDrawer = (item) => {
    setDrawerIsOpen(true);
    updateShopItem(item);
  };

  return (
    <Fragment>
      <ShopListTools
        name={name}
        setName={setName}
        getSearchParams={getSearchParams}
        showInactiveItems={showInactiveItems}
        setShowInactiveItems={setShowInactiveItems}
      />
      <ShopForm modalOpen={drawerIsOpen} closeModal={() => setDrawerIsOpen(false)} />
      <ShopList openModal={(item) => openDrawer(item)} page={page} setPage={setPage}/>
    </Fragment>
  )
}

Shops.propTypes = propTypes;

export default connect(
  ({
    shops: { loading, loaded },
  }) => ({
    itemsLoading: loading,
    itemsLoaded: loaded,
  }),
  (dispatch) => ({
    updateShopItem: (item) => dispatch({ type: UPDATE_SHOP_ITEM, payload: { item } } ),
    fetchShops: (page=null, searchParams) => dispatch(thunkFetchShops(page, searchParams)),
  })
)(Shops);
