import React, { useState } from 'react'
import renderHTML from 'react-render-html'
import { Paper } from '@material-ui/core'
import { htmlCss } from '../utils'

const SurveyFooter = ({ classes = {}, survey = {}, defaultCss }) => {
  const [height, setHeight] = useState(0)

  if (!survey.common_footer) return <></>

  return (
    <>
      <div style={{ height }} />
      <Paper
        className={classes.footer}
        ref={e => e && setHeight(e.getBoundingClientRect().height)}>
        <div className={classes.headerContent}>
          {renderHTML(survey.common_footer)}
          {renderHTML(htmlCss(survey.common_css || defaultCss))}
        </div>
      </Paper>
    </>
  )
}

export default SurveyFooter
