import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Hidden } from "@material-ui/core";
import { isAndroid } from 'react-device-detect'

import HeaderInputs from "common/Header/HeaderForm/HeaderInputs";
import { inputModalStyles } from "./styles"

const useStyles = makeStyles(inputModalStyles);

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
};

const InputsModal = ({ open, onClose }) => {
  const classes = useStyles({ isAndroid })
  const modalStyle = getModalStyle();

  return (
    <Hidden smUp>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose}
        className={classes.root}
      >
        <div style={modalStyle} className={classes.paper}>
          <HeaderInputs />
        </div>
      </Modal>
    </Hidden>
  );
};

export default InputsModal;
