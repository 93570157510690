import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ReactResizeDetector from 'react-resize-detector';
import {
  ScatterChart,
  ResponsiveContainer,
  ReferenceLine,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
} from 'recharts';
import { CircularProgress } from '@material-ui/core';
import DataTable from './DataTable';
import { thunkFetchCorrelations } from './redux/thunk';
import { setActiveChart } from './redux/actions';
import {getMinMax, scaleCorrelation} from './utils';
import { range } from 'modules/aggregation/utils';
import { themeAnalysisCorrelationsStyles } from "./styles";
import { isMobileOnly } from 'react-device-detect';
import { useGAEffect } from '../../ga';


const propTypes = {
  correlations: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  label: PropTypes.string,
  average_x: PropTypes.string,
  average_y: PropTypes.string,
  searchParams: PropTypes.object,
  fetchCorrelations: PropTypes.func.isRequired,
  setActiveChart: PropTypes.func.isRequired,
  ga: PropTypes.object.isRequired,
};

const useStyles = makeStyles(themeAnalysisCorrelationsStyles);

const AnalysisCorrelations = ({
  correlations,
  loading,
  label,
  average_x,
  average_y,
  fetchCorrelations,
  setActiveChart,
  searchParams,
  ga,
  filters,
}) => {
  const [graphWidth, setGraphWidth] = React.useState(0)
  const { group_id, project_id, survey_id, sdate, edate } = searchParams;
  const { chartGroup, textBold, showTableForWeb, buttonGroup, visibleOverflow } = useStyles();
  const [correlationData, setCorrelationData] = useState([]);

  useGAEffect(ga, "correlations", "相関分析")

  useEffect(() => {
    setActiveChart("graph-correlation");
    setCorrelationData(correlations.map((item) => ({ ...item, label: item.label ? item.label : item.body, status: true })));
    // eslint-disable-next-line
  }, [correlations]);

  useEffect(() => {
    fetchCorrelations({ ...searchParams, ...filters });
    // eslint-disable-next-line
  }, [fetchCorrelations, group_id, project_id, survey_id, sdate, edate, filters])

  const onResize = () => {
    const firstReferenceLine = document.getElementById('first-reference-line')
    setGraphWidth(firstReferenceLine.getBBox().width)
  }

  const lineProps = (x, y, value, dx, dy, strokeWidth, position) => ({
    x, y,
    stroke: "#B7B7B7",
    strokeWidth,
    label: {
      position: !!position ? position : 'left',
      fill: '#796255',
      fontSize: 14,
      value, dx, dy,
    }
  })

  const rowNames = [label || "満足度", "相関係数", "回答数"];
  const rowKeys = ["x", "y", "count"];

  // Calculate points on Y coordinate
  const [domainMinY, scaledMaxY] = scaleCorrelation(getMinMax(correlations, ["y"]), [-1, 1])
  const domainMaxY = Math.min(scaledMaxY, 1)
  const unitY = (domainMaxY - domainMinY)/8

  // Calculate points on X coordinate
  const [scaledMinX, scaledMaxX] = scaleCorrelation(getMinMax(correlations, ["x"]), [-10, 10])
  const domainMaxX = Math.min(scaledMaxX, label === "平均点" ? 10 : 100)
  const domainMinX = Math.max(scaledMinX, 0)
  const unitX = (domainMaxX - domainMinX)/10

  const toggleButton = (i) => {
    correlationData[i].status = !correlationData[i].status
    setCorrelationData([...correlationData])
  }

  return (
    <div style={{ marginRight: 10 }}>
      <div className={chartGroup}>
        <div className={buttonGroup}>
          {
            correlationData.map((corr, index) => (
              <button key={index} className={corr.status ? '' : 'offButton'} onClick={() => {toggleButton(index)}}>{corr.label}</button>
            ))
          }
        </div>
        <ResponsiveContainer width='100%' height={560} id="graph-correlation" className={visibleOverflow}>
          <ScatterChart width={400} height={400} margin={{top: 60, right: 1, bottom: 60, left: 100}}>
            <ReferenceLine {...lineProps(0, average_y || 0, average_y, 0, 0, 4, 'right')} />
            <ReferenceLine {...lineProps(0, domainMaxY, domainMaxY.toFixed(3), 0, 6, 2)} id='first-reference-line'/>
            <ReferenceLine {...lineProps(0, domainMinY, domainMinY.toFixed(3), 0, -6, 2)} />
            <ReferenceLine {...lineProps(domainMinX, null, domainMinX, 9, 0, 2, 'bottom')} />
            <ReferenceLine {...lineProps(average_x || 0, null, average_x, 0, 0, 4, 'top')} />
            <ReferenceLine {...lineProps(domainMaxX, null, domainMaxX, -19, 0, 2, 'bottom')} />
            {range(8).map(i => <ReferenceLine key={`S${i}`} {...lineProps(0, domainMinY + i*unitY, "", 0, 0)} />)}
            {range(10).map(i => <ReferenceLine key={i} {...lineProps(domainMinX + i*unitX, null, "", 0, 0)} />)}
            <YAxis dataKey={'y'} type="number" name='相関係数' domain={[domainMinY, domainMaxY]} tickCount={0} hide={true}/>
            <XAxis dataKey={'x'} type="number" name={label} domain={[domainMinX, domainMaxX]} tickCount={11} hide={true} />
            <text x="50%" y="98%" className={textBold}>満足度{label ? `(${label})` : ''}</text>
            <text dx="18" y="200" rotate="-90" transform="rotate(90, 45, 180)" letterSpacing="5" textAnchor="middle">
              <tspan x="135" dy="0.655em" className={textBold}>N P S 相関係数</tspan>
            </text>
            <g transform="translate(20, 150) rotate(-90)"><Arrow /></g>
            <g transform="translate(20, 405) rotate(90)"><Arrow /></g>
            <g transform="translate(192, 545) rotate(180)"><Arrow /></g>
            <g transform={`translate(${graphWidth}, 545) rotate(0)`}><Arrow /></g>
            <g transform="translate(110,67)"><CoordinateLabel text="優先課題" /></g>
            <g transform="translate(110,461)"><CoordinateLabel text="注意観察" /></g>
            <g transform={`translate(${graphWidth}, 461)`}><CoordinateLabel text="基本維持" right /></g>
            <g transform={`translate(${graphWidth}, 67)`}><CoordinateLabel text="維持強化" right /></g>
            <Scatter data={correlationData} fill='#8884d8' shape={<CustomDot />} isAnimationActive={false}>
              <LabelList dataKey="body" position="top" content={({ index, ...props }) => <QuestionAsLabel data={correlationData[index]} {...props} />} />
            </Scatter>
            <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip label={label}/>}/>
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <ReactResizeDetector handleWidth onResize={onResize} />

      <div className={isMobileOnly ? 'data-table' : showTableForWeb}>
        {loading ? <CircularProgress disableShrink />
          : <DataTable data={correlations} rowHeader="項目" rowKeys={rowKeys} rowNames={rowNames} rowWithSurfix={label === '平均点' ? -1 : 0} unitSurfix='%' pivot />}
      </div>
    </div>
  )
}

const QuestionAsLabel = props => {
  const { x, y, width, data } = props;
  const [actualWidth, setActualWidth] = useState(0);
  const ref = useRef(null);
  const question = data.label;
  let rightEdge = 36
  const firstReferenceLine = document.getElementById('first-reference-line')
  if (firstReferenceLine) {
    const firstReferenceLineBox = firstReferenceLine.getBBox()
    rightEdge += firstReferenceLineBox.x + firstReferenceLineBox.width
  }
  const expectRectX = x - actualWidth/2 + 6
  const expectTextX = x + width/2
  const rectX = (expectRectX + actualWidth) <= rightEdge ? expectRectX : (rightEdge - actualWidth)
  const textX = (expectRectX + actualWidth) <= rightEdge ? expectTextX : (expectTextX - expectRectX + rectX)

  useEffect(() => {
    if (ref.current) {
      const text = ref.current.getBBox();
      setActualWidth(text.width + 25);
    }
    // eslint-disable-next-line
  }, [data]);

  if (!!!data.status) {
    return null;
  }

  return (
    <g>
      <rect width={actualWidth} height={35} fill="#F2EEEC" x={rectX} y={y - 55} rx={5} />
      <polygon fill="#F2EEEC" points={`${x} ${y - 22}, ${x + 10} ${y - 22}, ${x + 5} ${y - 12}`} />
      <text ref={ref} x={textX} y={y - 35} textAnchor="middle" dominantBaseline="middle">
        {question}
      </text>
    </g>
  )
};

const CustomTooltip = ({ payload }) => {
  if (!payload || payload.length === 0 || !payload[0].payload)
    return null

  const { body, x, y, count } = payload[0].payload;
  return (
    <div style={{ background: "#F2EEEC", padding: 8, borderRadius: 5, color: "black"}}>
      {body}
      <div>{payload[0].name}: {x}</div>
      <div>{payload[1].name}: {y}</div>
      <div>{'回答数'}: {count}</div>
    </div>
  )
}

const CustomDot = ({ cx, cy }) => (
  <circle cx={cx} cy={cy} r={8} stroke="black" strokeWidth={4} fillOpacity="0" />
)

const Arrow = () => <>
  <line x1="0" y1="0" x2="80" y2="0" stroke="#939393" strokeWidth="3" />
  <polygon points="80 -6, 93 0, 80 6" fill="#939393" />
</>

const CoordinateLabel = ({text, right}) => <>
  <line x1="45" y1="0" x2="45" y2="32" stroke="#F2EEEC" strokeWidth="90" />
  <line x1={right ? "90" : "0"} y1="0" x2={right ? "90" : "0"} y2="32" stroke="#EC681A" strokeWidth="4" />
  <text x="11" y="22">{text}</text>
</>

AnalysisCorrelations.propTypes = propTypes;

export default connect(
  ({
    analysis: {
      correlations,
      loading,
      label,
      average_x,
      average_y,
    },
    global: { searchParams, filters },
    ga
  }) => ({
    correlations,
    loading,
    label,
    average_x,
    average_y,
    searchParams,
    ga,
    filters,
  }),
  dispatch => ({
    fetchCorrelations: searchParams => dispatch(thunkFetchCorrelations(searchParams)),
    setActiveChart: chart => dispatch(setActiveChart(chart)),
  })
)(AnalysisCorrelations);
