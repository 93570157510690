import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { isMobileOnly, isTablet } from 'react-device-detect'
import InputsModal from "common/Header/HeaderModal/InputsModal";
import InputsPopover from "common/Header/HeaderModal/InputsPopover";

const useStyles = makeStyles(theme => ({
  settingBtn: props => ({
    boxShadow: "none",
    fontSize: "10px",
    [theme.breakpoints.down('sm')]: {
      width: 300
    },
    [theme.breakpoints.up('sm')]: {
      width: 230
    },
    height: "36px",
    backgroundColor: "#EC681A",
    color: "white",
    "&:hover": {
      backgroundColor: props.tablet ? '#CC681A' : 'white',
      color: props.tablet ? 'white' : '#EC681A',
      border: props.tablet ? 'none' : "1px solid #EC681A",
    }
  }),
  extendedIcon: {
    color: "white",
    marginLeft: "10px"
  },
  hoverIcon: {
    color: "#EC681A"
  }
}));

const ToggleInputButton = () => {
  const classes = useStyles({ tablet: isTablet || isMobileOnly })
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <div>
      <Fab
        variant="extended"
        color="default"
        aria-label="setting"
        className={classes.settingBtn}
        onClick={handleOpen}
      >
        プロジェクト/アンケートの設定
        <SettingsIcon
          fontSize="small"
          className={clsx(classes.extendedIcon, open && classes.hoverIcon)}
        />
      </Fab>

      <InputsModal open={open} onClose={handleClose} />

      <InputsPopover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default ToggleInputButton;
