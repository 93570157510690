import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from "immutable";
import {thunkDeleteGroup, thunkFetchGroups, thunkSaveGroup, thunkSaveOrder} from './redux/thunk';
import { findKeyPathOf, getMemberIds } from "common/utils";
import Draggable from "../../../common/Draggable";
import {actionCloseGroupFormModal, OPEN_GROUP_FORM_MODAL} from "./redux/actions";
import { useGAEffect } from '../../../ga';


const propTypes = {
  tree: PropTypes.array.isRequired,
  itemsLoading: PropTypes.bool.isRequired,
  itemsLoaded: PropTypes.bool.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  doc: PropTypes.object,
  modalOpened: PropTypes.bool,
  error: PropTypes.object,
  isSubmitSuccess: PropTypes.bool,
  save: PropTypes.func,
  saveOrder: PropTypes.func,
  ga: PropTypes.object.isRequired,
};

const GroupsContainer = ({
  doc,
  modalOpened,
  error,
  isSubmitSuccess,
  tree,
  itemsLoading,
  itemsLoaded,
  fetchGroups,
  destroy,
  openModal,
  closeModal,
  save,
  saveOrder,
  ga,
}) => {
  const [list, setList] = useState([]);
  const firstTimeRefresh = useRef(true);
  let timeoutID = useRef(null);

  useGAEffect(ga, null, "グループ管理")

  useEffect(() => {
    if ((!itemsLoading && !itemsLoaded) || firstTimeRefresh.current === true) {
      fetchGroups();
      firstTimeRefresh.current = false;
    }

    setList(tree || []);
    // eslint-disable-next-line
  }, [itemsLoading, itemsLoaded, tree]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex, dragId, hoverId, dragJSON, hoverJSON, parentId, callbackType) => {
      if (dragId && hoverId) {
        const tree = fromJS(list);
        let newTree;
        let dragObj = JSON.parse(dragJSON);
        let dragDisplayOrder = dragObj.display_order;
        let hoverObj = JSON.parse(hoverJSON);
        let hoverDisplayOrder = hoverObj.display_order;

        if (callbackType === 'hover') {
          // swap display_order
          dragObj.display_order = hoverDisplayOrder;
          hoverObj.display_order = dragDisplayOrder;
          
          if (parentId) {
            // has parentID, => not first Level
            const parentKeyPath = findKeyPathOf(
              tree,
              "children",
              node => node.get("id") === parentId
            );
            newTree = tree.updateIn(parentKeyPath, node => {
              let newNode = node.update("children", children => {
                let newChildren = children.update(dragIndex, () =>
                  hoverObj
                );
                newChildren = newChildren.update(hoverIndex, () =>
                  dragObj
                );
                return newChildren;
              });
              return newNode;
            });
          } else {
            // dont have parentID, => first Level
            newTree = tree.update(dragIndex, () => hoverObj);
            newTree = newTree.update(hoverIndex, () => dragObj);
          }
          parentId = +parentId === 0 ? null : parentId;
          const member_ids = getMemberIds(newTree, parentId);

          if (!!member_ids) {
            setList(newTree.toJS());

            if (!!timeoutID.current) {
              clearTimeout(timeoutID.current)
            }
            timeoutID.current = setTimeout(() => {
              saveOrder({
                parent_id: parentId,
                id: dragId,
                member_ids,
                items: newTree.toJS(),
                oldTree: tree,
              });
            }, 1500);
          }
        } else {
          // handle for drop (from child to ancestor)
          let dragParentId = dragObj.parentId;
          const dragParentKeyPath = findKeyPathOf(
            tree,
            "children",
            node => node.get("id") === dragParentId
          );
          dragObj.parentId = hoverId;
          const dropKeyPath = parentId !== 0 ? findKeyPathOf(
            tree,
            "children",
            node => node.get("id") === hoverId
          ) : [hoverIndex];
          if (dragParentId && (dragParentKeyPath.length >= dropKeyPath.length
            || dragParentKeyPath[dragParentKeyPath.length - 1] !== dropKeyPath[dragParentKeyPath.length - 1])
            && dragParentKeyPath[0] === dropKeyPath[0]) {
            
            newTree = tree.updateIn(dropKeyPath, node => {
              let newNode = node.update("children", children => {
                dragObj.display_order = children.last() ? children.last().get('display_order') + 1 : 1;
                let newChildren = children.push(dragObj);
                return newChildren;
              });
              return newNode;
            });
            newTree = newTree.updateIn(dragParentKeyPath, node => {
              let newNode = node.update("children", children => {
                let newChildren = children.delete(dragIndex);
                return newChildren;
              });
              return newNode;
            });
            parentId = +parentId === 0 ? null : parentId;
            const member_ids = getMemberIds(newTree, hoverId);

            if (!!member_ids) {
              setList(newTree.toJS());

              if (!!timeoutID.current) {
                clearTimeout(timeoutID.current)
              }
              timeoutID.current = setTimeout(() => {
                saveOrder({
                  parent_id: hoverId,
                  id: dragId,
                  member_ids,
                  items: newTree.toJS(),
                  oldTree: tree,
                });

              }, 1500);
            }
          }
        }
      }
      // eslint-disable-next-line
    }, [list]
  );

  return <Draggable moveCard={moveCard}
                    list={list}
                    setList={setList}
                    doc={doc}
                    modalOpened={modalOpened}
                    error={error}
                    isSubmitSuccess={isSubmitSuccess}
                    fetchItems={fetchGroups}
                    closeModal={closeModal}
                    openModal={openModal}
                    destroy={destroy}
                    save={save}
                    constants={{
                      type: 'group',
                      path: 'groups',
                      name: 'グループ',
                      parentName: '親グループ',
                      placeHolder: 'グループ名を入力してください。',
                      exportButtonText: 'グループエクスポート',
                      importButtonText: 'グループインポート（CSV）',
                    }}
                    isProductSurvey={true}
                    />
}

GroupsContainer.propTypes = propTypes;

export default connect(

  ({ groups: { doc, modalOpened, error, isSubmitSuccess, items, itemsLoading, itemsLoaded }, ga }) => ({
    doc, modalOpened, error, isSubmitSuccess,
    tree: items,
    itemsLoading,
    itemsLoaded,
    ga,
  }),
  (dispatch) => ({
    fetchGroups: () => dispatch(thunkFetchGroups()),
    openModal: doc => dispatch({
      type: OPEN_GROUP_FORM_MODAL,
      payload: {
        modalOpened: true,
        doc,
      }
    }),
    closeModal: () => dispatch(actionCloseGroupFormModal()),
    destroy: (id) => dispatch(thunkDeleteGroup(id)),
    save: (params) => dispatch(thunkSaveGroup(params)),
    saveOrder: (params) => dispatch(thunkSaveOrder(params)),
}))(GroupsContainer);
