import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { thunkFetchQuestionAggregation } from './redux/thunk';
import { NPS_QUESTION } from './constants';
import GraphTable from './GraphTable';
import GraphTableMobile from './GraphTableMobile';
import { themeAggregationQuestionStyles } from "./styles";
import {isMobile} from 'react-device-detect';
import { useGAEffect } from '../../ga';


const propTypes = {
  totalQuestion: PropTypes.arrayOf(PropTypes.object),
  fetchTotalQuestion: PropTypes.func,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  nps: PropTypes.bool,
  searchParams: PropTypes.object,
  ga: PropTypes.object.isRequired,
};

const useStyles = makeStyles(themeAggregationQuestionStyles)

const AggregationQuestion = ({ 
  totalQuestion, 
  fetchTotalQuestion, 
  loading,
  loaded,
  nps,
  searchParams,
  ga,
  filters,
 }) => {
  const { root, buttonNoActive, buttonActive, padding } = useStyles();
  const activeButtonProp = { color: "primary" };
  const [activeGraph, setActiveGraph] = useState("rate");
  const [style, setStyle] = useState([activeButtonProp]);
  const [activeButtonRatio, setActiveButtonRatio] = useState(true);
  const [activeButtonNumber, setActiveButtonNumber] = useState(false);
  const { question_id, group_id, project_id, survey_id, sdate, edate } = searchParams;
  const firstTimeRefresh = useRef(true);

  useGAEffect(ga, "question", "設問集計")

  useEffect(() => {
    if (firstTimeRefresh.current === false) fetchTotalQuestion({ ...searchParams, ...filters });
    // eslint-disable-next-line
  }, [fetchTotalQuestion, question_id, group_id, project_id, survey_id, sdate, edate, filters]);

  useEffect(() => {
    if ((!loading && !loaded) || firstTimeRefresh.current === true) {
      fetchTotalQuestion({ ...searchParams, ...filters });
      firstTimeRefresh.current = false;
    }
    // eslint-disable-next-line
  }, [loading, loaded]);

  if (!!!question_id){
    return "設問を選択してください。"
  } else if (loading) {
    return <CircularProgress disableShrink />
  }

  const handleClick = (id, graph) => {
    if (activeGraph !== graph)
      setStyle([0, 1].map(i => i === id ? activeButtonProp : {}))
    setActiveGraph(graph)
    if(activeButtonRatio){
      setActiveButtonRatio(false)
      setActiveButtonNumber(true)
    }else{
      setActiveButtonRatio(true)
      setActiveButtonNumber(false)
    }
  }

  const colKeys = isMobile ? ["count"] : ["label", "count", "rate"]
  const colNames = isMobile ? ["回答数"] : ["選択肢", "回答数", "割合"]
  const tableProps = { colNames, colKeys, activeGraph: isMobile ? "rate" : activeGraph }
  return (
    <div className={padding}>
      {isMobile ?
        <GraphTableMobile data={totalQuestion} rowHeader="スコア" {...tableProps} nps={nps} />
        :
        <>
        <div className={root}>
          <Button className={activeButtonRatio? buttonActive : buttonNoActive} {...style[0]} onClick={e => handleClick(0, "rate")}>割合</Button>
          <Button className={activeButtonNumber? buttonActive : buttonNoActive} {...style[1]} onClick={e => handleClick(1, "count")}>回答数</Button>
        </div>
        <GraphTable data={totalQuestion} rowHeader="スコア" {...tableProps} nps={nps} />
        </>
      }
    </div>
  )
}

AggregationQuestion.propTypes = propTypes;

export default connect(
  ({ aggregation, global: { searchParams, filters }, ga }) => ({
    totalQuestion: aggregation.totalQuestion,
    loading: aggregation.loading,
    loaded: aggregation.loaded,
    nps: (aggregation.selectedQuestions.single || {}).question_type === NPS_QUESTION,
    searchParams: {
      ...searchParams,
      question_id: (aggregation.selectedQuestions.single || {}).id
    },
    ga,
    filters,
  }),
  dispatch => ({
    fetchTotalQuestion: searchParams => dispatch(thunkFetchQuestionAggregation(searchParams)),
  })
)(AggregationQuestion);
