const colorTextCommon = "#fff";

export const themedStyles = theme => ({
  root: {
    maxWidth: "600px",
    margin: "auto",
    font: "bold 14px/24px 'Noto Sans JP', sans-serif",
    '& >*': {
      fontFamily: "'Noto Sans JP', sans-serif !important"
    }
  },
  buttonHandleNext: {
    font: '500 12px/24px Noto Sans JP, Hiragino Sans',
    fontFamily: "'Noto Sans JP', sans-serif",
    float: "right",
    textAlign: "center",
    borderRadius: "5px",
    color: colorTextCommon,
    padding: 0,
    height: 42,
    border: "none",
    cursor: "pointer",
    display: "block",
    backgroundColor: "#EC681A",
    position: 'relative',
    width: '100%',
    " & i": {
      position: "absolute",
      right: 10,
      top: '50%',
      transform: 'translateY(-50%)'
    }
  },
  buttonHandlePrev: {
    font: '500 12px/24px Noto Sans JP, Hiragino Sans',
    fontFamily: "'Noto Sans JP', sans-serif",
    width: '100%',
    textAlign: "center",
    borderRadius: "5px",
    color: "#FFFFFF",
    height: 42,
    padding: "0px",
    border: "none",
    cursor: "pointer",
    display: "block",
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    position: 'relative',
    " & i": {
      position: "absolute",
      left: 10,
      top: '50%',
      transform: 'translateY(-50%)'
    }
  },
  btEdit: {
    backgroundColor: 'white',
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #B7B7B7",
    borderRadius: "5px",
    opacity: 1,
    boxShadow: "none",
    "& >span":{
      font: "600 8px/10px 'Noto Sans JP', sans-serif",
      letterSpacing: "0.2px",
      color: "#939393",
      opacity: 1,
      "& >span":{
        "& >.fa-pencil-square-o":{
          fontSize: "15px"
        }
      }
    }
  },
  display: {
    paddingTop: 20,
    paddingRight: 22,
    paddingLeft: 22
  },
  bgTextConfirm: {
    backgroundColor: "#fff",
    padding: "10px 20px",
    position: "fixed",
    width: 600,
    wordBreak: "break-all",
    zIndex: 1,
    "& h4":{
      marginTop:"60px",
      maxWidth:"100%",
    },
    "& h5":{
      marginTop:"15px",
      maxWidth:"100%",
    },
    "& h6":{
      lineHeight:0,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%"
    }
  },
  bgTextConfirm1: {
    backgroundColor: '#F7F7F7',
    paddingTop: 40
  },
  bgButtonConfirm: {
    backgroundColor: '#B7B7B729',
    padding: 10,
    marginTop: "40px",
    "& >div":{
      alignItems: "center",
      "& >.bgButtonConfirm-p":{
        "& >p":{
          font: "600 12px/18px 'Noto Sans JP', sans-serif",
          letterSpacing: "0.3px",
          color: "#1A0707",
          opacity: 1,
        }
      }
    }
  },
  textConfirm: {
    fontFamily: "'Noto Sans JP', sans-serif",
    fontSize: 14,
    color: 'hsl(0,0%,80%)',
    fontWeight: 'bold'
  },
  textConfirm3: {
    textAlign: 'center',
    fontSize: 14,
    padding: "40px",
    font: "300 10px/18px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.25px",
    color: "#1A0707",
    opacity: 1,
    margin: 0
  },
  btPadding: {
    paddingBottom: 20,
    paddingTop: 40,
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    border: '1px solid gray'
  },
  imgConfirmStyle: {
    position: 'absolute',
    top: 10,
    right: 40,
    left: 40,
    bottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imgConfirm: {
    textAlign: 'center',
    paddingLeft: 20,
    paddingBottom: 10,
    paddingRight: 20
  },
  imgconfirmBorder: {
    position: "relative",
    paddingTop: '58%'
  },
  btFooter: {
    padding: 20,
    marginBottom: "50px",
    backgroundColor: "#F7F7F7"
  },
  heightButton:{
    height: "50px"
  },
  rightCss: {
    fontSize: 14,
    textAlign: 'right',
    whiteSpace: 'pre-line'
  },
  headerPaper: {
    position: "fixed",
    top: 0,
    zIndex: 1,
    width: "600px",
    minHeight: "auto",
    padding: "10px 24px",
    wordBreak: "break-word",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    }
  },
  footer: {
    position: "fixed",
    bottom: 0,
    zIndex: 1,
    width: "600px",
    minHeight: "auto",
    padding: "10px 24px",
    wordBreak: "break-word",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    }
  },
});
