import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import CrossCheckbox from './CrossCheckbox';
import { OPTION_COLORS } from './constants';
import { getNpsColor } from './utils';
import { themeCrossTableStyles } from "./styles";
import clsx from "clsx";
import { replaceParams } from 'modules/utils';

const propTypes = {
  totalCross: PropTypes.object.isRequired,
  nps: PropTypes.bool,
}
const useStyles = makeStyles(themeCrossTableStyles);

const CrossTable = ({ totalCross={}, nps }) => {
  const { header={}, headerOptionKeys=[], selectedOptions, total={}, items=[] } = totalCross
  const { cell, table, root, headerTable, Tbody, headerTableSub, width18, width12, grayCell, bodyRow } = useStyles()

  return (
    <Paper className={root}>
      <Table className={table} aria-label="caption table" size="small">
        <colgroup>
          <col className={width18}/>
          <col className={width12}/>
          {headerOptionKeys.map(option => <col key={option} style={{width: 70/headerOptionKeys.length +"%"}}/>)}
        </colgroup>
        <TableHead>
          <TableRow className={headerTable}>
            <TableCell className={cell} align="left" id='choiceCol'>選択肢</TableCell>
            <TableCell className={cell} align="center" id='countCol'>{header.label}</TableCell>
            {headerOptionKeys.map(option =>
              <TableCell key={option} className={cell} align="center">{replaceParams(header[option])}</TableCell>
            )}
          </TableRow>
          <TableRow className={headerTableSub}>
            <TableCell className={cell} align="left">{total.label}</TableCell>
            <TableCell className={cell} align="center">{total.count}</TableCell>
            {headerOptionKeys.map(option => 
              <TableCell key={option} className={cell} align="center">{total['rate_'+option]}%</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody className={Tbody}>
          {items.map((item, index) => 
            <TableRow key={index} className={bodyRow}>
              <TableCell key={1} className={cell} align="left">
                <CrossCheckbox key={index} color={nps ? getNpsColor(index) : OPTION_COLORS[index]} 
                  value={index} checked={selectedOptions.includes(index)}/>
                {replaceParams(item.label)}
              </TableCell>
              <TableCell key={2} className={clsx(cell, grayCell)} align="center">{item.count}</TableCell>
              {headerOptionKeys.map((option, index) =>
                <TableCell key={option} className={clsx(cell, (index % 2 === 1) && grayCell)} align="center">{item['rate_'+option]}%</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

CrossTable.propTypes = propTypes;

export default CrossTable;
