import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  modalRoot: {
    position: "absolute",
    backgroundColor: "white",
    padding: "44px 20px",
    borderTop: "2px solid #EC681A",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: "600",
    padding: "0 10px 10px 10px",
    borderBottom: "1px solid #B7B7B7",
    marginBottom: 40
  },
  modalNote: {
    textAlign: "center",
    fontWeight: "300",
    letterSpacing: "0.35px",
    color: "#1A0707"
  },
  modalButtonZone: {
    marginTop: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  modalDeleteBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
  modalCancelBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
  modalSizeBtn: {
    margin: "0 10px",
    width: 123
  },
})
