import React, { useState } from 'react'
import { isIE } from 'react-device-detect'
import { connect, useSelector } from 'react-redux'
import styled from 'styled-components'
import { default as axios } from 'axios'
import { Clear } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { renderHtmlWithoutTags, Storage } from '../../../utils'
import { API_URL } from '../../../constants'
import { QuestionPopover } from './FeedbackQuestions'
import MultiSelectSearchBar from '../../../components/MultiSelectSearchBar'
import { loadUsers } from '../../../common/Header/HeaderForm/utils'
import { LimitedLines } from '../../common/styles'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  QUESTION_TYPE_DATE,
  QUESTION_TYPE_FILE_UPLOAD,
  QUESTION_TYPE_SELECT_USER,
} from '../surveys/form/questions/constants'
import { getQuestionQueryKeyValues } from './utils'
import { setQuestionFilters } from './redux/actions/feedback'
import FeedbackQuestionDate from './FeedbackQuestionDate'

const MAX_QUESTION_FILTER = 6

const defaultQuestions = [
  {
    id: -1000,
    label: 'PXカテゴリ',
    type: 'type_nps_category',
  },
  {
    id: -1001,
    label: '担当スタッフ',
    type: QUESTION_TYPE_SELECT_USER,
  },
]

const useStyles = makeStyles(theme => {
  return {
    container: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      '-ms-grid-columns': '(1fr)[3]',
      gridColumnGap: 15,
      gridRowGap: 15,
      [theme.breakpoints.down(1024)]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'auto',
      },
      marginBottom: 15,
    },
    ieContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: '0 5px',
      },
    },
    ieQuestionBox: {
      width: '32%',
      marginRight: 15,
      marginBottom: 15,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    questionBoxHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#EFEFEF',
      height: 32,
      padding: '5px 5px 5px 10px',
      fontWeight: 'bold',
    },
    formSearch: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      width: '100%',
      '& >div': {
        display: 'inline-block',
        '& >div': {
          verticalAlign: 'middle',
          '& >div': {
            '& >input': {
              padding: '10px 15px',
              textAlign: 'left',
              font: '500 14px/21px Noto Sans JP, Hiragino Sans',
              letterSpacing: '0.25px',
              border: '1px solid #939393',
              borderRadius: '5px',
              color: '#1A0707',
              opacity: 1,
              '&::-webkit-input-placeholder': { /* Chrome/Opera/Safari */
                color: '#939393',
              },
              '&::-moz-placeholder': { /* Firefox 19+ */
                color: '#939393',
              },
              '&:-ms-input-placeholder': { /* IE 10+ */
                color: '#939393',
              },
              '&:-moz-placeholder': { /* Firefox 18- */
                color: '#939393',
              },
            },
          },
        },
      },
      '& >.formIcon': {
        border: '1px solid #939393',
        height: 37,
        width: 37,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        marginLeft: 10,
        cursor: 'pointer',
        '& >i': {
          fontSize: '16px',
        },
        '&:hover': {
          background: '#EC681A 0% 0% no-repeat padding-box',
          border: 'solid 1px #EC681A',
          '& >i': {
            color: 'white',
          },
        },
      },
    },
    textBox: {
      '& input': {
        padding: '0 15px !important',
        height: 35,

        '&:-ms-input-placeholder': {
          color: '#9f9797 !important',
        },

        '&::-ms-clear': {
          display: 'none',
        },

        '&:hover': {
          border: '1px solid #1A0707 !important',
        },
        '&:focus': {
          border: '2px solid #EC681A !important',
        },
      },
      '& input[value]:not([value=""])': {
        border: '2px solid #EC681A !important',
      },
      '& input ~ fieldset': {
        display: 'none',
      },
    },
  }
})

const NpsBase = styled.div`
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  text-align: center;
  flex: 1;
`

const NpsA = styled(NpsBase)`
  border: 1px solid #1FA371;
  color: ${props => props.active ? 'white' : '#1FA371'};
  background: ${props => props.active ? '#1FA371' : 'white'};
`

const NpsB = styled(NpsBase)`
  margin: 0 10px;
  border: 1px solid #FFCE00;
  color: ${props => props.active ? 'white' : '#FFCE00'};
  background: ${props => props.active ? '#FFCE00' : 'white'};
`

const NpsC = styled(NpsBase)`
  border: 1px solid #F02544;
  color: ${props => props.active ? 'white' : '#F02544'};
  background: ${props => props.active ? '#F02544' : 'white'};
`

const NpsOption = ({ onSelect, values = [] }) => {
  const [a, b, c] = ['promoters', 'passives', 'detractors']
  const [nps, setNps] = React.useState([a, b, c].filter(v => values && values.includes(v)))

  const onClick = value => {
    let updated = []
    if (nps.includes(value)) {
      updated = nps.filter(v => v !== value)
    } else {
      updated = [...nps, value]
    }
    onSelect(updated)
    setNps(updated)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%', margin: '0 10px' }}>
      <NpsA onClick={() => onClick(a)} active={nps.includes(a)}>推奨者</NpsA>
      <NpsB onClick={() => onClick(b)} active={nps.includes(b)}>中立者</NpsB>
      <NpsC onClick={() => onClick(c)} active={nps.includes(c)}>批判者</NpsC>
    </div>
  )
}

const TextOption = ({ onSelect, value='' }) => {
  const classes = useStyles()
  const [keyword, setKeyword] = useState(value)

  const onChange = ({ target: { value } }) => {
    setKeyword(value)
  }

  const onSearch = () => {
    onSelect(keyword)
  }

  return (
    <div className={classes.formSearch}>
      <div style={{ width: '100%' }}>
        <TextField
          style={{ width: '100%' }}
          name='name'
          placeholder='含まれる単語を入力'
          variant='outlined'
          value={keyword}
          onChange={onChange}
          className={classes.textBox}
        />
      </div>
      <div className='formIcon' onClick={onSearch}>
        <i className='material-icons'>search</i>
      </div>
    </div>
  )
}

const QuestionBox = ({ question, onClose, onSelect, included = [] }) => {
  const [options, setOptions] = React.useState(question.selected || [])
  const searchParams = useSelector(state => state.global.searchParams)
  const classes = useStyles()

  const onClickClose = () => {
    setOptions([])
    onClose()
  }

  const queryOptions = query => {
    const filtered = included.filter(v => question.options && question.options.includes(v.id)).map(v => ({
      value: v.id,
      label: v.attributes.name,
    }))
    if (query) {
      setOptions(filtered.filter(v => v.label.includes(query)))
    } else {
      setOptions(filtered)
    }
  }

  const queryUsers = async q => {
    const { group_id, shop_id } = searchParams
    setOptions(await loadUsers({ no_paging: 1, q, group_id, shop_id }))
  }

  return (
    <div className={isIE && classes.ieQuestionBox}>
      <div className={classes.questionBoxHeader}>
        <LimitedLines>{question.label}</LimitedLines>
        <Clear onClick={onClickClose} style={{ color: '#EC681A', cursor: 'pointer' }} />
      </div>
      <div style={{
        minHeight: 70,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        height: `calc(100% - 33px)`,
      }}>
        {question.type === 'type_nps_category' ? <NpsOption onSelect={onSelect} values={question.selected} /> :
          question.type === 'type_select_user' ?
            <MultiSelectSearchBar options={options} queryOptions={queryUsers} onSelect={onSelect}
                                  placeholder='スタッフ名を検索' selected={question.selected} /> :
            (question.type === 'type_text_area' || question.type === 'type_text_field' || question.type === 'type_nps_reason') ?
              <TextOption onSelect={onSelect} value={question.selected} /> :
              question.type === QUESTION_TYPE_DATE ?
                <FeedbackQuestionDate onSelect={onSelect} selected={question.selected} /> :
                <MultiSelectSearchBar options={options} queryOptions={queryOptions}
                                      onSelect={onSelect} selected={question.selected} />}
      </div>
    </div>
  )
}

const loadSurveyQuestions = (surveyId) => {
  const path = `${API_URL}/surveys/${surveyId}/questions`
  return axios.get(path)
    .then(response => response.data)
    .catch(err => console.error('Unhandled aldaa: ', err))
}

const FeedbackSurveyFilter = ({ survey_id, filters = {}, saveFilters }) => {
  const mounted = React.useRef()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [questions, setQuestions] = React.useState([])
  const [allQuestions, setAllQuestions] = React.useState([])
  const [includedOptions, setIncludedOptions] = React.useState([])
  const [height, setHeight] = React.useState(250)
  const [loading, setLoading] = React.useState(false)
  const classes = useStyles()

  const openPopover = event => setAnchorEl(event.currentTarget)
  const closePopover = () => setAnchorEl(null)

  const queryQuestions = () => {
    setLoading(true)
    loadSurveyQuestions(survey_id)
      .then(({ data, included }) => {
        setIncludedOptions(included)
        setAllQuestions(defaultQuestions.concat(data.map(({ id, attributes, relationships }) => ({
            id,
            label: renderHtmlWithoutTags(attributes.abbreviation || attributes.body),
            type: attributes.question_type,
            options: relationships.question_options.data.map(v => v.id),
          }))
            .filter(v => v.type !== QUESTION_TYPE_FILE_UPLOAD)
            .filter(v => v.type !== QUESTION_TYPE_SELECT_USER)),
        )
        setLoading(false)
      })
  }

  const onClickAddQuestion = event => {
    const element = document.getElementById('question-list-popover')
    if (element && element.getBoundingClientRect()) {
      setHeight(window.innerHeight - element.getBoundingClientRect().y - 30)
    }
    if (survey_id) {
      openPopover(event)
    }
  }

  // Add to LOCAL
  const selectQuestion = (question = {}) => {
    closePopover()
    const newQuestions = questions.concat([question])
    setQuestions(newQuestions)
  }

  // Remove from all 3
  const onClickClose = (question = {}) => {
    const updated = questions.filter(q => q.id !== question.id)
    setQuestions(updated)
    const [key] = getQuestionQueryKeyValues(question, undefined)
    if (Object.keys(filters).includes(key)) {
      saveFilters({ ...filters, [key]: undefined }) // Update from updated list
    }
    Storage.setQuestionFilters(updated)
  }

  // Add or update to all 3
  const onOptionSelected = (question, selected) => {
    const questionWithOptions = { ...question, selected }
    const updated = questions.map(q => q.id === question.id ? questionWithOptions : q)
    setQuestions(updated)
    Storage.setQuestionFilters(updated)
    const [key, values] = getQuestionQueryKeyValues(question, selected)
    saveFilters({ ...filters, [key]: values })
  }

  // Read from Storage and Add to Local
  React.useEffect(() => {
    mounted.current = true
    const storedFilters = Storage.getQuestionFilters()
    if (Array.isArray(storedFilters)) {
      setQuestions(storedFilters)
    }
  }, [])

  // Remove from all 3
  React.useEffect(() => {
    if (survey_id) {
      queryQuestions()
    } else {
      if (!mounted.current) {
        setQuestions([]) // local
        Storage.setQuestionFilters({}) // localstorage
        saveFilters([]) // redux
      }
    }
    mounted.current = false
    // eslint-disable-next-line
  }, [survey_id])

  const getFilteredQuestions = () => {
    // Odoo songogdtson bga asuuluudaa songoltoos hasah
    return allQuestions.filter(a => !questions.map(q => q.id).includes(a.id))
  }

  return (
    <div className={isIE ? classes.ieContainer : classes.container}>
      {questions.map(question =>
        <QuestionBox key={question.id} question={question}
                     included={includedOptions}
                     onSelect={options => onOptionSelected(question, options)}
                     onClose={() => onClickClose(question)} />,
      )}
      {questions.length < MAX_QUESTION_FILTER && survey_id && (
        <div style={{
          color: '#EC681A',
          fontWeight: 'bold',
          cursor: 'pointer',
        }} onClick={onClickAddQuestion}>
          + 絞込条件を追加
        </div>
      )}
      <QuestionPopover loading={loading} anchorEl={anchorEl} items={getFilteredQuestions()}
                       anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                       height={height} onSelect={selectQuestion} onClose={closePopover} />
    </div>
  )
}

export default connect(
  ({ global: { searchParams, filters } }) => ({
    survey_id: searchParams.survey_id,
    filters,
  }), (dispatch) => ({
    saveFilters: filters => dispatch(setQuestionFilters(filters)),
  }),
)(withRouter(FeedbackSurveyFilter))
