export const themedStyles = theme => ({
  root: {
    width: "640px",
    padding: "34px 44px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "auto"
    }
  },
  shopSelectWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  selectContainer: {
    width: 253,
    marginLeft: 20
  },
  shopsZone: {
    marginTop: 10,
    padding: 20,
    height: "50vh",
    overflowY: "scroll",
    borderRadius: 5,
    backgroundColor: "#F7F7F7",
    display: "flex",
    flexDirection: "column"
  }
});
