import { API_URL } from '../../../constants';
import { default as axios } from 'axios';
import {
  authenticationBegin,
  authenticationSuccess,
  authenticationFailure,
  logoutSuccess,
} from './actions';
import { Storage } from '../../../utils';

//※ログイン画面における実質action creator

export const authenticate = (data, history) => {
  const path = `${API_URL}/auth/sign_in`;
  const { account_id, login_id, password, saveSession } = data;

  return dispatch => {
    dispatch(authenticationBegin());
    axios.post(path, { account_id, login_id, password })
      .then((response) => {
        // ↓のアクションはtype: AUTHENTICATION_SUCCESS, payload:{response, saveSession, history}
        dispatch(authenticationSuccess(response, saveSession, history));
        dispatch({ type: 'GLOBAL_VALUES_CHANGED' });
      })
      .catch((error) => {
        console.error(error);
        dispatch(authenticationFailure(error));
      });
  }
}

export const logout = () => {
  return dispatch => {
    axios.delete(`${API_URL}/auth/sign_out`)
      .then(() => {
        Storage.clear();
        dispatch(logoutSuccess());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
