export const themedStyles = theme => ({
  formContainer: {
    width: "100%",
    marginBottom: "30px"
  },
  formLabel: {
    fontSize: "18px",
    "&.Mui-focused": {
      color: "#EC681A !important"
    }
  },
  blockListUser: {
    marginTop: "20px",
    "& >div": {
      "& >.search": {
        height: '36px',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        "& >p": {
          display: "inline-block",
          width: "15%",
          font: "500 14px/18px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          color: "#1A0707",
          opacity: 1
        },
        "& >div": {
          display: "inline-block",
          width: "75%",
          maxWidth: "253px",
          "& >div:nth-child(1)": {
            "& >div:nth-child(1)": {
              "& >div":{
                "& >div":{
                  display: "none"
                }
              }
            }
          },
          "& >.css-26l3qy-menu": {
            borderRadius: 0,
            "& >div":{
              borderTop: "2px solid #9A877D"
            }
          }
        },
        "& #react-select-2-input, .css-1wa3eu0-placeholder": {
          display: 'none',
        },
        '& .search-box': {
          position: 'absolute',
          zIndex: '1',
          top: '50%',
          transform: 'translate(10px, -50%)',
          border: 'none',
          '&::-ms-clear': {
            display: 'none',
          },
          '&:hover ~ div > div:first-of-type, &:focus ~ div > div:first-of-type': {
            border: '1px solid #EC681A',
          },
        }
      },
      "& >.listUser": {
        background: "#F7F7F7 0% 0% no-repeat padding-box",
        opacity: 1,
        minHeight: "500px",
        padding: "20px 40px",
        marginTop: "10px",
        overflow: "auto",
        "& >div": {
          background: "#9A877D 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          opacity: 1,
          display: "block",
          padding: "12px 15px",
          marginTop: "10px",
          position: 'relative',
          "& >p": {
            font: "600 12px/21px Noto Sans JP, Hiragino Sans",
            letterSpacing: "0.3px",
            color: "#FFFFFF",
            opacity: 1,
            display: "inline-block",
            margin: 0,
            paddingRight: 30,
            wordBreak: 'break-all',
            width: '100%'
          },
          "& >button": {
            float: "right",
            color: "#fff",
            padding: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 10
          }
        }
      },
    }
  },
  ul: {
    "& >div": {
      font: "300 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      borderBottom: "0.6000000238418579px solid #C2C2C2",
      padding: "14px 0",
      "&:hover": {
        font: "300 10px/16px Noto Sans JP, Hiragino Sans",
        color: "#1A0707 !important",
        background: "#ec681a3b 0% 0% no-repeat padding-box",
        cursor: "pointer"
      }
    },
  }
});


export const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: 'none',
    outline: state.isFocused ? "none" : null,
    border: state.isFocused ? "1px solid #EC681A": "1px solid #B7B7B7",
    '&:hover': {
      border: "1px solid #EC681A",
      outline: "none",
    },
    '&:focus': {
      border: "1px solid #EC681A",
      outline: "none",
    },
    "& >svg":{
      display: "none"
    },
    '&:active': {
      backgroundColor: state.isSelected ? "#FFF" : "#FFF"
    }
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      background: "#9A877D 0% 0% no-repeat padding-box",
      borderRadius: "10px",
      opacity: 1,
      alignItems: "center",
      padding: "0px 10px",
      marginRight: "5px",
      color: "#fff",
      display: 'none',
      "& >div": {
        font: "600 10px/16px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.25px",
        color: "#FFFFFF",
        opacity: 1,
        display: 'none',
        "& >div":{
          "& >div":{
            "& >.text":{
              "& >p:nth-of-type(1)":{
                margin: 0,
                color: "#fff",
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
              "& >p:nth-of-type(2)":{
                display: "none"
              },
            },
            "& svg":{
              display: "none"
            }
          },
          "& >svg": {
            fontSize: "15px",
            color: "#fff",
            display: 'none',
          }
        }
      },
    }
  },
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ?  "#fff" : "#fff",
    padding: "0px 12px",
    ".listbox": {
      width: "100%",
      margin: "0",
      padding: 0,
      listStyle: "none",
      overflow: "auto",
      maxHeight: "250px",
      borderRadius: "4px",
      zIndex: 1,
      "& li": {
        padding: "5px 12px",
        display: "flex",
        "& span": {
          flexGrow: 1
        },
        "& svg": {
          color: "transparent"
        }
      },
      "& li[aria-selected='true']": {
        backgroundColor: "#fafafa",
        fontWeight: 600,
        "& svg": {
          color: "#EC681A"
        }
      },
      "& li[data-focus='true']": {
        backgroundColor: "#e6f7ff",
        cursor: "pointer",
        "& svg": {
          color: "#000"
        }
      },
    },
    ".rowTextAutocomplete": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "14px 4px",
      borderBottom: "1px solid #B7B7B7",
      position: 'relative',
      "&:hover": { 
        backgroundColor: "#fff", 
        color: "#EC681A !important",
        cursor: "pointer"
      },
      "&:hover svg": { 
        color: "#EC681A",
      },
      "& >.text": {
        width: "100%",
        "&:hover p": {
          color: "#EC681A !important",
          cursor: "pointer",
        },
        "& p": {
          font:  state.isSelected ? "600 10px/16px Noto Sans JP, Hiragino Sans" : "300 10px/16px Noto Sans JP, Hiragino Sans" ,
          letterSpacing: "0.25px",
          color: state.isSelected ? "#EC681A" : "#1A0707",
          opacity: 1,
          margin: 0,
          overflowWrap: "break-word",
          wordWrap: "break-word",
          msWordBreak: "break-all",
          wordBreak: "break-word",
          fallbacks: [
            {
              wordBreak: "break-all"
            }
          ],
          msHyphens: "auto",
          mozHyphens: "auto",
          webkitHyphens: "auto",
          hyphens: "auto",
          paddingRight: 30
        },
        "& p:nth-of-type(2)": {
          font: "300 8px/16px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.2px",
          color: "#796255",
          opacity: 1,
          margin: 0
        }
      },
      "& >svg": {
        color: state.isSelected ? "#EC681A" : "#1A0707",
        display: state.isSelected ? "block" : "none",
        fontSize: "15px",
        position: 'absolute',
        top: '50%',
        right: 15,
        transform: 'translateY(-50%)',
      }
    },
  })
}
