import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, FormControl, FormLabel, TextField, Paper, CircularProgress } from "@material-ui/core";
import { getNewDisplayOrder } from "common/utils";
import { themedStyles } from "./styles";
import HeaderDrawer from "../HeaderDrawer";
import ShopSelection from "../ShopSelection";
import ProductSelection from "../ProductSelection";
import UnSavedWarningDialog from "../../../components/UnSavedWarningDialog";
import ItemDrawer from "../../../components/ItemDrawer";
import DeleteItemModal from "../../../components/DeleteItemModal";

import { thunkFetchAllShops } from 'modules/shops/redux/thunk';


const useStyles = makeStyles(themedStyles);

const propTypes = {
  item: PropTypes.object.isRequired,
  dataSet: PropTypes.array.isRequired,
  shopItems: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  modalOpened: PropTypes.bool,
  error: PropTypes.object,
  isSubmitSuccess: PropTypes.bool,
  constants: PropTypes.object,
  fetchAllShops: PropTypes.func.isRequired,
  allShopsLoaded: PropTypes.bool.isRequired,
  // 商品選択か否かのフラグ追加
  isProductSurvey: PropTypes.bool,
};

const UpdateForm = ({
  item,
  dataSet,
  shopItems,
  save,
  destroy,
  onClose,
  modalOpened,
  error,
  isSubmitSuccess,
  constants,
  fetchAllShops,
  allShopsLoaded,
  isProductSurvey
}) => {
  const classes = useStyles();
  const [dirty, setDirty] = useState(false)
  const [changeShop, setchangeShop] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const [name, setName] = useState('');
  const [shops, setShops] = useState([]);
  const errors = !!error ? error.detail : {};
  const [selectorOpen, setSelectorOpen] = useState(false)

  useEffect(() => {
    if (modalOpened) {
      fetchAllShops();
      setDirty(false)
      setSelectorOpen(false);
    }
    // eslint-disable-next-line
  }, [modalOpened])

  useEffect(() => {
    if (allShopsLoaded) {
      setShops(shopItems.filter(i => item.shops.includes(i.value)));
      setName(item.name);
      setSelectorOpen(true);
    }
    // eslint-disable-next-line
  }, [shopItems, allShopsLoaded])

  useEffect(() => {
    if (isSubmitSuccess)
      setDirty(null)
  }, [isSubmitSuccess])

  const clearShop = (idx) => {
    shops.splice(idx, 1)
    setShops(shops);
    if(changeShop){
      setchangeShop(false);
    } else {
      setchangeShop(true);
    }
    setDirty(checkIfDirty({name, shops: shops}))
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // get display order for creating new
    let display_order = null;
    if (!item.id) {
      // create new
      display_order = getNewDisplayOrder(dataSet, item.parentId);
    } else {
      // update
      display_order = item.display_order;
    }

    save({
      parent_id: item.parentId,
      id: item.id,
      name,
      shop_ids: shops.map(({ value }) => value),
      display_order: display_order,
      parent_name: item.parentName,
    });
  }

  const checkIfDirty = area => {
    return (item.name || '') !== area.name || !(area.shops.length === item.shops.length &&
      area.shops.map(v => v.value).sort().every((element, index) => element === item.shops.sort()[index]))
  }

  const onNameChange = e => {
    setName(e.target.value)
    setDirty(checkIfDirty({name: e.target.value, shops}))
  }

  const onShopsChange = newShops => {
    const data = []
    data.push(newShops[newShops.length - 1])
    setShops(data[0])
    setDirty(checkIfDirty({name, shops: data[0]}))
  }

  const onShopAdd = v => {
    onShopsChange([...shops, v])
  }

  const listParentName = item.listParentName || "ルート";

  const closeConfirmationDialog = () => setIsConfirmationOpen(false)

  const onModalClose = () => {
    if (dirty) {
      setIsConfirmationOpen(true)
    } else {
      onClose && onClose()
    }
  }

  const onOpenDeleteConfirmation = () => setIsDeleteConfirmationOpen(true)

  const onDelete = () => {
    item.id && destroy(item.id);
    onClose && onClose();
  }

  return (
    <ItemDrawer open={modalOpened} onClose={onModalClose}>
      <form onSubmit={handleSubmit}>
        <HeaderDrawer
          onClose={onModalClose}
          onDelete={item && item.id ? onOpenDeleteConfirmation : null}
          onSubmit={handleSubmit}
          dirty={dirty}
          name={constants.name}
        />
        <div className={classes.group}>
          <FormControl className={classes.formContainer}>
            <FormLabel component="p" className={classes.formLabel}>
              {constants.parentName}
            </FormLabel>
            <TextField
              variant={"outlined"}
              defaultValue={listParentName}
              disabled
            />
          </FormControl>

          <FormControl className={classes.formContainer}>
            <FormLabel component="p" className={classes.formLabel} error={!!errors.name}>
              {constants.name}名
            </FormLabel>
            <TextField
              required={true}
              name={"name"}
              value={name}
              placeholder={constants.placeHolder}
              variant={"outlined"}
              error={!!errors.name}
              helperText={!!errors.name && `${constants.name}名${errors.name[0]}`}
              onChange={onNameChange}
            />
          </FormControl>
        </div>
        {/* 商品スコア実装 */}
        {isProductSurvey?
          <ProductSelection onShopAdd={onShopAdd} onShopRemove={clearShop} selected={shops} selectable={shopItems} onChangeShop={changeShop} errors={errors} />
          :
          <ShopSelection onShopAdd={onShopAdd} onShopRemove={clearShop} selected={shops} selectable={shopItems} onChangeShop={changeShop} errors={errors} />
        }

        <UnSavedWarningDialog
          open={isConfirmationOpen}
          onConfirm={() => {
            closeConfirmationDialog()
            onClose && onClose()
          }}
          onCancel={closeConfirmationDialog}
        />
        <DeleteItemModal
          open={isDeleteConfirmationOpen}
          onDelete={onDelete}
          onClose={() => setIsDeleteConfirmationOpen(false)}
          name={constants.name}
          parentName={constants.parentName}
        />
      </form>
    
      { !selectorOpen &&
        <div className={classes.formContain}>
          <Paper className={classes.root}>
            <div className={classes.loading}>
              <CircularProgress disableShrink />
            </div>
          </Paper>
        </div>
      }
    </ItemDrawer>
  );
};

UpdateForm.propTypes = propTypes;

export default connect(
  ({ shops: { allItems, allItemsLoaded }}) => ({
    shopItems: allItems,
    allShopsLoaded: allItemsLoaded
  }),
  (dispatch) => ({
    fetchAllShops: () => dispatch(thunkFetchAllShops()),
  })
)(UpdateForm);
