import qs from 'query-string'
import {
  fetchDashboardBegin,
  fetchDashboardFailure,
  fetchDashboardSuccess,
  fetchGoodvocsSuccess,
  fetchRanksSuccess,
  LIKE_GOODVOCS_FEEDBACK,
  DISLIKE_GOODVOCS_FEEDBACK,
  PIN_GOODVOCS_FEEDBACK, fetchGoodvocsBegin,
} from './actions'
import { API_URL_V2 } from '../../../constants'
import { default as axios } from 'axios'
import Storage from '../../../utils/Storage'
import {
  thunkDislikeFeedback,
  thunkLikeFeedback,
  thunkPinFeedback,
} from '../../feedbacks/redux/thunk'

export const thunkFetchNpsSatisfactions = searchParams => {
  const name = 'nps_satisfactions'
  return dispatch => {
    dispatch(fetchDashboardBegin(name))
    const params = qs.stringify(searchParams)
    const path = `${API_URL_V2}/dashboard/${name}${!!params ? '?' + params : ''}`
    return axios
      .get(path)
      .then(({ data }) => {
        dispatch(fetchDashboardSuccess(data, name))
      })
      .catch(error => {
        // If 403 is returned, invalidate use_dashboard
        if (error.response && +error.response.status === 403) {
          const { label, value } = Storage.getSelectedCompany()
          Storage.setSelectedCompany({label, value}, dispatch)
        }
        dispatch(fetchDashboardFailure(error.message, name))
      })
  }
}

export const thunkFetchGoodvocs = (searchParams, page= 1) => {
  return dispatch => {
    if (page === 1)
      dispatch(fetchGoodvocsBegin())
    const params = qs.stringify({ ...searchParams, page })
    return axios
      .get(`${API_URL_V2}/dashboard/goodvocs?${params}`)
      .then(response => {
        let totalPages = 1
        if (response.headers && response.headers["total-pages"])
          totalPages = +response.headers["total-pages"]
        dispatch(fetchGoodvocsSuccess(response.data, page, totalPages))
      })
      .catch(error => dispatch(fetchDashboardFailure(error.message, 'goodvocs')))
  }
}

export const thunkPinGoodvocs = (feedbackId, pinned) =>
  thunkPinFeedback(feedbackId, pinned, PIN_GOODVOCS_FEEDBACK)

export const thunkLikeGoodvocs = feedbackId => thunkLikeFeedback(feedbackId, LIKE_GOODVOCS_FEEDBACK)

export const thunkDislikeGoodvocs = (feedbackId, likeId) =>
  thunkDislikeFeedback(feedbackId, likeId, DISLIKE_GOODVOCS_FEEDBACK)

export const thunkFetchRankings = (name, searchParams) => {
  return dispatch => {
    dispatch(fetchDashboardBegin(name))
    const params = qs.stringify(searchParams)
    const path = `${API_URL_V2}/dashboard/${name}_rankings${!!params ? '?' + params : ''}`
    return axios
      .get(path)
      .then(({ data }) => {
        dispatch(fetchRanksSuccess(data, name))
      })
      .catch(error => dispatch(fetchDashboardFailure(error.message, name)))
  }
}
