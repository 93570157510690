import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from 'react-redux';
import { DateRangePicker } from "react-date-range";
import { ja } from 'date-fns/locale'
import { Popover, Grid, Button } from "@material-ui/core";
import { ArrowDropDown, ArrowLeft, ArrowRight, Clear } from "@material-ui/icons";
import DefinedRanges from "./DefinedRanges";
import { Storage } from "../../../utils";
import useStyles from './styles'
import IconCalender from 'assets/icons/icon_calendar_active.svg'
import { formatDate, increaseRange, decreaseRange, stringDateFormat } from 'helpers/DateHelper'
import {
  startOfWeek,
  endOfWeek
} from "date-fns";

const CalendarPicker = ({ startDate, endDate, globalVariablesChanged }) => {
  const configWeekStart = {
    weekStartsOn: 1
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const [valueRemove, setValueRemove] = useState(false);
  const [errorDateTime, setErrorDateTime] = useState(false);
  const [errorFomatDateTime, setErrorFomatDateTime] = useState(false);
  const [errorDateTimeEmpty, setErrorFomatTimeEmpty] = useState(false);
  const [inputRanges, setInputRanges] = useState({
    startDate: formatDate(startDate ? startDate : startOfWeek(new Date(), configWeekStart)),
    endDate: formatDate(endDate ? endDate : endOfWeek(new Date(), configWeekStart))
  });
  const classes = useStyles();

  const handleOpen = event => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  useEffect(() => {
    if(moment(inputRanges.endDate, stringDateFormat, true)._isValid) {
      setErrorDateTime(false);
      setErrorFomatDateTime(false);
      handleInputChange();
    }
    // eslint-disable-next-line
  }, [inputRanges.endDate])

  useEffect(() => {
    if (!inputRanges.startDate || !inputRanges.endDate) {
      setErrorFomatTimeEmpty(true);
    }
    else {
      setErrorFomatTimeEmpty(false);
    }
    // eslint-disable-next-line
  }, [inputRanges.startDate, inputRanges.endDate])

  const open = Boolean(anchorEl);
  const id = open ? "date-popover" : undefined;
  const hasDate = startDate && endDate

  const handleSelect = ({ selection })=> {
    const { startDate, endDate } = selection;

    setInputRanges({ startDate: formatDate(startDate), endDate: formatDate(endDate) })
    Storage.setStartDate(formatDate(startDate));
    Storage.setEndDate(formatDate(endDate));
    setValueRemove(false);
    globalVariablesChanged()
  };

  const handleNavigateDate = type => {
    if(!hasDate) return

    let newStart, newEnd
    if (type === 'increase') {
      const newRange = increaseRange(startDate, endDate)
      newStart = newRange.startDate
      newEnd = newRange.endDate
    } else {
      const newRange = decreaseRange(startDate, endDate)
      newStart = newRange.startDate
      newEnd = newRange.endDate
    }

    Storage.setStartDate(formatDate(newStart))
    Storage.setEndDate(formatDate(newEnd))
    setValueRemove(false);
    globalVariablesChanged()
  }

  const handleDeleteDate = event => {
    event.stopPropagation() // TODO: stop parent click event
    Storage.setStartDate('')
    Storage.setEndDate('')
    setInputRanges({ startDate: '', endDate: ''})
    setValueRemove(true);
    globalVariablesChanged()
  }

  const selectionRange = {
    startDate: startDate ? startDate : startOfWeek(new Date(), configWeekStart),
    endDate: endDate ? endDate : endOfWeek(new Date(), configWeekStart),
    key: "selection"
  };
  const selectionRangeRemove = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  };

  const renderInputDate = (dateValue, dateKey) => {
    const errorKey = dateKey + 'Error'

    const handleChange = e => {
      if(!moment(e.target.value, stringDateFormat, true)._isValid) {
        setErrorFomatDateTime(true);
      }
      else {
        setErrorFomatDateTime(false);
      }
      setInputRanges({
        ...inputRanges,
        [dateKey]: e.target.value,
        [errorKey]: !moment(e.target.value, stringDateFormat, true)._isValid
      })
    }
    return (
      <input
        value={dateValue}
        onChange={handleChange}
        className={ (inputRanges[errorKey] || !dateValue) ? 'error' : '' }
        placeholder='yyyy/MM/dd'
      />
    )
  }

  const handleInputChange = () => {
    const timeBefore = new Date(new Date().setFullYear(new Date().getFullYear() + 20));
    const timeAfter = new Date(new Date().setFullYear(new Date().getFullYear() - 100));
    if(isNaN(new Date(inputRanges.startDate)) || isNaN(new Date(inputRanges.endDate)) || moment(inputRanges.startDate, 'YYYY/MM/DD')._d < timeAfter || moment(inputRanges.endDate, 'YYYY/MM/DD')._d > timeBefore) {
      setErrorDateTime(true);
      return;
    }
    else {
      setErrorDateTime(false);
    }
    if (Date.parse(inputRanges.startDate) > Date.parse(inputRanges.endDate)) {
      setInputRanges({ ...inputRanges, startDate: inputRanges.endDate })
      Storage.setStartDate(inputRanges.endDate)
    }
    else {
      Storage.setStartDate(inputRanges.startDate)
    }

    Storage.setEndDate(inputRanges.endDate)
    setValueRemove(false);
    globalVariablesChanged()
  }

  const renderBoxDatePicker = () => {
    return (
      <>
        <div className={classes.dateWrapper} onClick={handleOpen}>
          <img src={IconCalender} className={classes.iconCalendar} alt={''} />
          {hasDate ?
          <>
            <div className={classes.dateValue}>{formatDate(startDate)}~{formatDate(endDate)}</div>
            <Clear onClick={handleDeleteDate} className={classes.iconClear} />
          </>
          :
            <div className={classes.iconDown}><ArrowDropDown /></div>
          }
        </div>

        <Popover
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <>
            <DateRangePicker
              locale={ja}
              weekStartsOn={1}
              editableDateInputs
              startDatePlaceholder="開始日"
              endDatePlaceholder="終了日"
              dateDisplayFormat="yyyy/MM/dd"
              ranges={valueRemove ? [selectionRangeRemove] : [selectionRange]}
              onChange={handleSelect}
              staticRanges={DefinedRanges}
              inputRanges={[]}
              className={valueRemove ? classes.dateRangePickerRemove : classes.dateRangePicker}
            />
            <div className={classes.dateInput}>
              <p>期間入力</p>
              {(errorDateTime && <p className={classes.erorMessage}>期間の指定が正しくありません</p>)}
              {((errorFomatDateTime && !errorDateTime && !errorDateTimeEmpty) && <p className={classes.erorMessage}>日付のフォーマットが正しくありません</p>)}
              {(errorDateTimeEmpty && <p className={classes.erorMessage}>日付を入力してください</p>)}
              <div>
                {renderInputDate(inputRanges.startDate, 'startDate')}
                <span>-</span>
                {renderInputDate(inputRanges.endDate, 'endDate')}
                <button
                  onClick={handleInputChange}
                  disabled={inputRanges.startDateError || inputRanges.endDateError}
                >
                  入力
                </button>
              </div>
            </div>
          </>
        </Popover>
      </>
    )
  }

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={() => handleNavigateDate('decrease')}
      >
        <ArrowLeft className={classes.iconArrow} />
      </Button>
      {renderBoxDatePicker()}
      <Button
        variant="outlined"
        className={classes.button}
        onClick={() => handleNavigateDate('increase')}
      >
        <ArrowRight className={classes.iconArrow} />
      </Button>
    </Grid>
  );
};

export default connect(
  ({ global: { startDate, endDate } }) => ({
    startDate: startDate ? new Date(moment(startDate, stringDateFormat)) : null,
    endDate: endDate ? new Date(moment(endDate, stringDateFormat)) : null,
  }),
  dispatch => ({
    globalVariablesChanged: () => dispatch({ type: "GLOBAL_VALUES_CHANGED" })
  }),
)(CalendarPicker);
