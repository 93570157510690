import React from "react";
import {
  Dialog,
  Slide,
 } from "@material-ui/core";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogModal = ({ open, onClose, children }) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="sm"
      >
        {children}
      </Dialog>
    </div>
  );
}

export default DialogModal;
