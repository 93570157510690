import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Popover,
  makeStyles,
} from '@material-ui/core';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { PAGINATION_PER_PAGE } from './constants';
import { thunkCopySurvey, thunkDeleteSurvey } from './redux/thunk';
import Confirmation from '../../common/ConfirmationDialog';
import ElementIcon from "common/icon/ElementIcon";
import {surveyList} from "./styles"
import Pagination from 'components/Pagination';


const useStyles = makeStyles(surveyList);

const EnhancedTableHead = props => {
  const { classes } = props;

  const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: 'ID', className: classes.width_80 },
    { id: 'createdDate', numeric: false, disablePadding: true, label: '作成日', className: classes.width_102 },
    { id: 'surveyName', numeric: true, disablePadding: false, label: 'アンケート名' },
    { id: 'project', numeric: true, disablePadding: false, label: 'プロジェクト' },
    { id: 'fbNumber', numeric: true, disablePadding: false, label: 'FB数', className: classes.width_80 },
    { id: 'questionNum', numeric: true, disablePadding: false, label: '設問数', className: classes.width_80 },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          sortDirection={false}
          className={clsx(classes.cellHeaderFirst, classes.no_pd_bt, classes.width_65)}
        >
          <div className={clsx(classes.tableCellHeader, classes.cellHeaderFirst)}>公開</div>
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            align="left"
            key={headCell.id}
            sortDirection={false}
            className={clsx(classes.no_pd_bt, headCell.className && headCell.className)}
          >
            <div className={classes.tableCellHeader}>{headCell.label}</div>
          </TableCell>
        ))}
        <TableCell
          align="left"
          sortDirection={false}
          className={clsx(classes.cellHeaderEnd, classes.no_pd_bt, classes.width_65)}
        >
          <div className={`${classes.tableCellHeader} ${classes.cellHeaderEnd}`} style={{ borderRight: 0 }}></div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const MoreFunction = ({ classes, item, copySurvey, openPreview, archiveSurvey, isSuperAdmin }) => {
  const [open, setOpen] = useState(false)
  const [text, setText] = useState("")
  const [action, setAction] = useState(null)
  const closeDialog = () => setOpen(false)

  const openDialog = (dialogText, confirmedAction) => {
    setOpen(true)
    setText(dialogText)
    setAction(() => confirmedAction)
  }

  return (
    <div className={classes.moreWrapper}>
      <Button
        variant="outlined"
        className={classes.button}
        startIcon={<i className="el-icon-copy-document"></i>}
        onClick={() => openDialog('アンケートを複製しますか', copySurvey)}
      >
        複製
      </Button>
      {item.active &&
      <>
      <Button
          variant="outlined"
          className={classes.button}
          startIcon={<i className="el-icon-view"></i>}
          onClick={openPreview}
      >
        プレビュー
      </Button>
      {isSuperAdmin && <Button
          variant="outlined"
          color="secondary"
          className={classes.button}
          startIcon={<i className="el-icon-receiving"></i>}
          onClick={() => openDialog('アンケートをアーカイブしますか', archiveSurvey)}
      >
        アーカイブ
      </Button>
      }
      </>
      }
      <Confirmation show={open} onConfirm={() => action(item)} onCancel={closeDialog} text={text}/>
    </div>
  )
}

const propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  page: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  userAuthority: PropTypes.string,
};

const SurveyList = ({
  items,
  loading,
  pagination,
  setPage,
  userAuthority,
  deleteSurvey,
  copySurvey,
  newSurveyId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [activePage, setActivePage] = useState(pagination.currentPage);
  const isSuperAdmin = userAuthority === "super_admin";

  const handleClickPage = (v) => {
    setPage(v)
    setActivePage(v)
  }

  const goToSurveyDetail = surveyId => {
    history.push({
      pathname: `/admin/surveys/${surveyId}`,
    })
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  useEffect(() => {
    if (newSurveyId && !loading) {
      goToSurveyDetail(newSurveyId);
    }
    handleClose()
    // eslint-disable-next-line
  }, [newSurveyId, loading]);

  if (loading) {
    return (
      <Paper className={classes.root}>
        <div className={classes.loading}>
          <CircularProgress disableShrink />
        </div>
      </Paper>
    );
  }

  const handleClick = (currentTarget, rowIndex) => {
    setAnchorEl(currentTarget);
    setActiveRow(rowIndex);
  }

  const renderMoreIcon = (id, classes) => {
    let newMoreClasses = `${classes.tableCellBody} ${classes.cellBodyEnd}`;
    const open = Boolean(anchorEl);
    if (open && activeRow === id) {
      newMoreClasses = newMoreClasses + ` ${classes.moreActive}`;
    }
    return (
      <div className={newMoreClasses} onClick={(event) => handleClick(event.currentTarget, id)}>
        <MoreVertIcon className={classes.size_24} />
      </div>
    )
  }

  const openPreview = () => {
    const item = items.filter(item => item.id === activeRow)[0];
    window.open(`${window.location.protocol}//${item.domain || window.location.host}/s/preview/${item.company_uid}/${item.url_string}`, "_blank");
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table aria-label="simple table" className={classes.table}>
          <EnhancedTableHead
            classes={classes}
          />
          <TableBody>
            {items.map(item =>
              <TableRow
                hover
                key={item.id}
                className={classes.tableRow}
              >
                <TableCell
                  onClick={() => { goToSurveyDetail(item.id) }}
                  className={classes.no_pd_bt}
                >
                  <div className={classes.tableSelectedWrapper}>
                    {item.active ? (
                      <span className={`${classes.tableSelected} ${classes.checkMark}`}>
                        <CheckIcon className={classes.size_24} />
                      </span>
                    ) : (
                      <span className={`${classes.tableSelected} ${classes.closeMark}`}>
                        <CloseIcon className={classes.size_24} />
                      </span>
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => { goToSurveyDetail(item.id) }}
                  align="right"
                  className={classes.no_pd_bt}
                >
                  <div className={classes.tableCellBody}>{item.id}</div>
                </TableCell>
                <TableCell
                  onClick={() => { goToSurveyDetail(item.id) }}
                  align="right"
                  className={classes.no_pd_bt}
                >
                  <div className={classes.dateCell}>
                    <div>{format(new Date(item.created_at), "yyyy/MM/dd")}</div>
                    <div>{format(new Date(item.created_at), "HH:mm")}</div>
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => { goToSurveyDetail(item.id) }}
                  align="right"
                  className={classes.no_pd_bt}
                >
                  <div className={clsx(classes.tableCellBody, classes.printIcon, classes.hideTextOverflow)}>
                    {item.name}
                    {item.discarded_at ? <ElementIcon name={`receiving`}/> : '' }
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => { goToSurveyDetail(item.id) }}
                  align="right"
                  className={classes.no_pd_bt}
                >
                  <div className={clsx(classes.tableCellBody, classes.hideTextOverflow)}>{item.project_name}</div>
                </TableCell>
                <TableCell
                  onClick={() => { goToSurveyDetail(item.id) }}
                  align="right"
                  className={classes.no_pd_bt}
                >
                  <div className={classes.tableCellBody}>{item.feedback_count}</div>
                </TableCell>
                <TableCell
                  onClick={() => { goToSurveyDetail(item.id) }}
                  align="right"
                  className={classes.no_pd_bt}
                >
                  <div className={classes.tableCellBody}>{item.question_count}</div>
                </TableCell>
                <TableCell align="right" className={clsx(classes.no_pd_bt, classes.width_65)}>
                  {renderMoreIcon(item.id, classes)}
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className={classes.morePopover}
                  >
                    <MoreFunction
                      item={items.find(({ id }) => id === activeRow) || {}}
                      classes={classes}
                      archiveSurvey={deleteSurvey}
                      openPreview={openPreview}
                      copySurvey={copySurvey}
                      isSuperAdmin={isSuperAdmin}
                    />
                  </Popover>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

     <Pagination
        activePage={activePage}
        itemsCountPerPage={+pagination.perPage}
        pageRangeDisplayed={pagination.offset}
        totalItemsCount={+pagination.total}
        onChange={handleClickPage}
      /> 
    </div>
  );
}

SurveyList.propTypes = propTypes;

export default connect(
  ({ surveys: { newSurveyId, items, loading, pagination }, global: { userData } }) => ({
    newSurveyId,
    items,
    loading,
    pagination: !!pagination ? pagination : {
      currentPage: 1,
      total: PAGINATION_PER_PAGE,
      perPage: PAGINATION_PER_PAGE
    },
    userAuthority: userData.authority
  }),
  dispatch => ({
    deleteSurvey: (item) => dispatch(thunkDeleteSurvey(item)),
    copySurvey: item => dispatch(thunkCopySurvey(item)),
  })
)(SurveyList);
