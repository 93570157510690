import React, { useState } from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import {
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import DateTimePicker from 'react-datetime-picker';
import { formatDate } from 'helpers/DateHelper'
import clsx from "clsx";
import HtmlRender from './HtmlRender';

const useStyles = makeStyles(theme => ({
  calendar: {
    marginBottom: 30,
  },
  text: {
    color: "#000",
    display: "block",
    margin: "0 0 10px 0",
    fontWeight: 'bold',
    paddingTop: '20px',
    whiteSpace: 'pre-line',
    fontSize: '16px',
    fontFamily: "'Noto Sans JP', sans-serif",
    "& .required": {
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing: "0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }

  },
  buttonCalendar: {
    width: "100%",
    border: "solid 1px #b3b3b3",
    boxSizing: "border-box",
    padding: "12px 15px",
    borderRadius: "22px",
    textAlign: "center",
    background: "#fff",
    color: "#908e8e",
    outline: 'none',
    position: 'relative',
    '& input, .react-datetime-picker__inputGroup__divider, .react-datetime-picker__inputGroup__leadingZero': {
      opacity: 0
    },
    "& .react-datetime-picker__calendar": {
      marginBottom: 2,
      bottom: '0 !important',
      top: '-50px !important',
    },
    "& .react-datetime-picker__calendar-button": {
      outline: 'none',
    },
    "& i": {
      top: '50%',
      right: 15,
      position: 'absolute',
      color: '#ef820f',
      fontSize: 16,
      transform: 'translateY(-50%)',
      outline: 'none',
    },
    "& .react-datetime-picker__wrapper": {
      border: 'none'
    },
    "& .react-datetime-picker__inputGroup__leadingZero": {
      color: '#070707'
    },
    "& .react-datetime-picker__inputGroup__divider": {
      color: '#070707'
    }
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
  datePickerIsOpened: {
    height: '300px'
  },
  datePickerIsClosed: {
    height: 'auto'
  },
  datePickerContainer: {
    position: 'relative'
  },
  datePickerValue: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  datePickerPlaceholder: {
    color: '#b9b9b9'
  }
}));

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onInputValue: PropTypes.func.isRequired,
};

const QuestionTypeDate = ({
  questionItem,
  answer,
  errors,
  onInputValue,
}) => {
  const classes = useStyles();
  const [datePickerClass, setDatePickerClass] = useState(classes.datePickerIsClosed);
  const [value, setValue] = useState(!!answer.answer_date ? new Date(answer.answer_date) : null);
  const textHasError = !!errors.answer_date;

  const textChangeHandler = (value) => {
    if (value) value.setHours(9);
    onInputValue(questionItem, { answer_date: value });
    setValue(value);
  };

  const inputReadOnlyDateTime = (el) => {
    if(el){
      for (var i = 0; i < el.wrapper.getElementsByTagName('input').length; i++) {
        el.wrapper.getElementsByTagName('input')[i].readOnly = true;
      }
    }
  }

  const handleClickPlaceholder = ({target}) => {
    const container = target.offsetParent
    const calendarButton = container.getElementsByClassName('react-datetime-picker__calendar-button react-datetime-picker__button')[0]
    calendarButton.click()
  }

  return (
    <div className={`${classes.calendar} ${datePickerClass}`}>
      <p className={classes.text}>
        <HtmlRender
          value = {questionItem.body}
        />
        {questionItem.is_required && (
          <span className="required">必須</span>
        )}
      </p>

      {!!questionItem.question_image_url && (
        <div className={classes.img}>
          <img src={questionItem.question_image_url} alt={''}/>
        </div>
      )}

      <div className={classes.datePickerContainer}>
        <DateTimePicker
          className={classes.buttonCalendar}
          onChange={textChangeHandler}
          value={value}
          disableClock={true}
          clearIcon={null}
          format={!!value ? "yyyy/MM/dd" : "dd"}
          dayPlaceholder='日付を選んでください。'
          ref={inputReadOnlyDateTime}
          calendarIcon={<i className="fa fa-calendar-o" aria-hidden="true"></i>}
          onCalendarClose={() => { setDatePickerClass(classes.datePickerIsClosed) }}
          onCalendarOpen={() => { setDatePickerClass(classes.datePickerIsOpened) }}
        />
        <div className={clsx(classes.datePickerValue, !!!value && classes.datePickerPlaceholder)} onClick={handleClickPlaceholder}>
          {!!value ? formatDate(value) : '日付を選んでください。'}
        </div>
      </div>

      {textHasError && (
        <FormHelperText error={true}>{errors.answer_date.map(err => err)}</FormHelperText>
      )}
    </div>
  );
}

QuestionTypeDate.propTypes = propTypes;

export default QuestionTypeDate;
