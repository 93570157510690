import { Divider, Paper } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  paperHeader: {
    padding: theme.spacing(2),
    textAlign: 'left',
    font: 'Hiragino Sans',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: '0.6px',
    color: '#1A0707',
  },
  paperContent: {
    display: 'table-cell',
    flexGrow: 1,
    width: '80vw',
    padding: 35,
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      padding: '35px 5px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      padding: 10,
    },
  },
}))

const CustomPaper = ({ header, children, height }) => {
  const { paperHeader, paperContent } = useStyles()

  return (
    <Paper square>
      <Paper square className={paperHeader} elevation={0}>
        {header}
      </Paper>
      <Divider variant="fullWidth" />
      <div className={paperContent} style={{ height }}>
        {children}
      </div>
    </Paper>
  )
}

export default CustomPaper
