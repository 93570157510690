import React from "react";
import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import HeaderInputs from "./HeaderInputs";
import CalendarPicker from "./CalendarPicker";
import ToggleInputButton from "common/Header/HeaderButton/ToggleInputButton";

const useStyles = makeStyles(theme => {
  return{
      root: {
      width: "100%",
      display: "flex",
      flexFlow: 'row nowrap',
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column',
      }
    },
    dateWrapper: {
      width: 255,
      [theme.breakpoints.only("xs")]: {
        width: 300,
        marginTop: "16px"
      }
    }
  }
});

const HeaderForm = () => {
  const classes = useStyles();

  return (
    <form className={classes.root}>
      <Hidden mdDown>
        <HeaderInputs />
      </Hidden>
      <Hidden lgUp>
        <ToggleInputButton/>
      </Hidden>
      <CalendarPicker />
    </form>
  );
};

export default HeaderForm;
