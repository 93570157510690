export const themedStyles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px"
  },
  itemWrapperLeft: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  container: {
    width: "100%",
    marginRight: "10px",
    color: "black",
    backgroundColor: "white",
    padding: "10px 30px",
    borderRadius: 0,
    textAlign: "left",
    borderLeft: "4px solid #B7B7B7",
    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px"
    }
  },
  iconDrag: {
    cursor: "row-resize",
    height: 70,
    border: "1px solid #b7b7b7",
    borderRadius: 20,
    backgroundColor: "white",
    padding: "0 5px",
    marginRight: 10,
    width: '27px',
    [theme.breakpoints.down("xs")]: {
      height: 46,
      padding: "5px 3px"
    }
  },
  itemActive: {
    borderLeft: "4px solid #EC681A",
    boxShadow: "5px 20px 50px #B7B7B7"
  },
  textBox: {
    width: "100%",
    padding: "12px 20px",
    border: "1px solid #efefef",
    textTransform: 'none',
    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px"
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addBtn: {
    backgroundColor: "white",
    color: "#EC681A",
  }
})
