import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts';
import TimeSeriesLegend from './TimeSeriesLegend';
import { getMinMax, scaleCorrelation, lineStyle } from '../utils';
import { NPS_COLORS } from 'modules/aggregation/constants';
import { range } from 'modules/aggregation/utils';
import { TooltipNpsCategory } from './TimeSeriesTooltip';
import {getElementWidth} from "../../utils";

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const TimeSeriesCategoryChart = ({ data }) => {
  const [ leftMargin, setLeftMargin ] = useState(0)
  const [ chartWidth, setChartWidth ] = useState(0)

  useEffect(() => {
    setLeftMargin(getElementWidth('datatable-headerCol'))
    setChartWidth(getElementWidth('datatable'))
  }, [data])

  window.addEventListener('resize', () => {
    setLeftMargin(getElementWidth('datatable-headerCol'))
    setChartWidth(getElementWidth('datatable'))
  })

  const lineProps = value => ({
    y: value,
    stroke: "#B7B7B7", 
    strokeWidth: 2,
    label: { 
      position: 'left', 
      value: value + '%',
      fill: '#796255', 
      fontSize: 10 
    }
  })

  const [scaledMin, scaledMax] = scaleCorrelation(getMinMax(data, ["detractors", "passives", "promoters"]), [0, 10])
  const max = Math.min(scaledMax, 100)
  const min = Math.max(scaledMin, 0)
  const middle = (max + min)/2
  const unit = (max - min)/10;

  return (
    <div id="graph-timeseries-2" >
      <LineChart width={chartWidth} height={320} data={data} margin={{top: 20, left: leftMargin, bottom: 15}}>
        <YAxis domain={[min, max]} type="number" hide={true} />
        <XAxis type="category" padding={{left: 35, right: 35}} hide={true}/>
        <Tooltip cursor={{strokeDasharray: '3 3'}} content={<TooltipNpsCategory />}/>
        <Legend verticalAlign="top" align="right" wrapperStyle={{top: 0, right: 0, width: isMobileOnly ? "100%" : "50%"}}
          content={<TimeSeriesLegend />}
        />
        {range(10).map(i => <ReferenceLine key={`C${i}`} y={min + i*unit} stroke="#F2EEEC" />)}
        {[min, middle, max].map(i => <ReferenceLine key={i} {...lineProps(i)} />)}
        <Line {...lineStyle} dataKey="detractors" stroke={NPS_COLORS.detractor} name="批判者"/>
        <Line {...lineStyle} dataKey="passives" stroke={NPS_COLORS.passive} name="中立者"/>
        <Line {...lineStyle} dataKey="promoters" stroke={NPS_COLORS.promoter} name="推奨者"/>
      </LineChart>
    </div>
  )
}

TimeSeriesCategoryChart.propTypes = propTypes;

export default TimeSeriesCategoryChart;
