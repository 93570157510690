import {
  API_MODEL_TYPE_QUESTION,
  API_MODEL_TYPE_SURVEY_HEADING,
  API_MODEL_TYPE_QUESTION_OPTION,
  API_MODEL_TYPE_SURVEY_PAGE,
} from './constants';
import { SURVEY_FORM_TYPE_SURVEY_HEADING } from './form/questions/constants';
import { API_MODEL_TYPE_PROJECT } from "../projects/constants";
import { API_MODEL_TYPE_AREA } from "../areas/constants";


export const parseSurveyForSelect = ({ id, attributes: { name } }) => ({ id, name });

const includesId = (items, id) => items.data.map(({ id }) => id).includes(id);

export const parseSurvey = ({ data: { id, attributes, relationships }, included }, includeDiscarded=true) => {
  const includedSurveyPages = included.filter(({ id, type }) => type === API_MODEL_TYPE_SURVEY_PAGE && includesId(relationships.survey_pages, id))
  const questions = included.filter(({ type }) => type === API_MODEL_TYPE_QUESTION);
  const survey_heading = included.filter(({ type }) => type === API_MODEL_TYPE_SURVEY_HEADING);
  const questionOptions = included.filter(({ type }) => type === API_MODEL_TYPE_QUESTION_OPTION);
  const survey_page_display_conditions_attributes = included.filter(({ type }) => type === "survey_page_display_condition");
  const area = getRelatedObjectFromIncluded(relationships.area, API_MODEL_TYPE_AREA, included)
  const project = getRelatedObjectFromIncluded(relationships.project, API_MODEL_TYPE_PROJECT, included)

  return {
    id,
    ...attributes,
    area,
    project,
    area_id: (area || {}).value,
    project_id: (project || {}).value,
    survey_pages_attributes: relationships.survey_pages.data.map(({ id }) => getSurveyPageFromIncluded({ id, relationships, included: includedSurveyPages, questions, survey_heading, questionOptions, survey_page_display_conditions_attributes }, includeDiscarded)).filter(item => item !== null)
  };
}

const getRelatedObjectFromIncluded = (relatedObject, type, included = []) => {
  if (relatedObject && relatedObject.data && relatedObject.data.id) {
    const includedObject = included.find(i => i.type === type && i.id === relatedObject.data.id)
    if (includedObject && includedObject.attributes)
      return {
        label: includedObject.attributes.name,
        value: includedObject.id,
      }
  }
  return null
}

export const parseSurveyForList = ({
  id,
  attributes: {
    name,
    active,
    feedback_count,
    question_count,
    domain,
    url_string,
    company_uid,
    created_at,
    discarded_at
  },
  relationships: {
    project
  }
}, included) => {
  const relatedProject = getRelatedObjectFromIncluded(project, API_MODEL_TYPE_PROJECT, included)
  const project_name = relatedProject ? relatedProject.label : ''
  return {
    id,
    name,
    active,
    project_name,
    feedback_count,
    question_count,
    domain,
    url_string,
    company_uid,
    created_at,
    discarded_at,
  }
};

export const parseSurveyPage = ({ id, attributes, relationships, questions, survey_heading, questionOptions, survey_page_display_conditions_attributes }, includeDiscarded=true) => {
  const given_before_survey_page_id = id;
  return {
    id,
    ...attributes,
    question_items: [
      ...questions.filter(({ id }) => includesId(relationships.questions, id))
        .map(({ id, attributes, relationships }) => {
          return {
            id,
            ...attributes,
            given_before_survey_page_id,
            question_options_attributes: questionOptions.filter(({ id }) => includesId(relationships.question_options, id))
              .map(({ id, attributes }) => ({
                id,
                ...attributes,
                value: String(attributes.value),
              })).filter(item => includeDiscarded ? item : !!item.discarded_at ? null : item),
          }
        }).filter(item => includeDiscarded ? item : !!item.discarded_at ? null : item),
      ...survey_heading.filter(({ id }) => includesId(relationships.survey_headings, id))
        .map(({ id, attributes }) => {
          return {
            id,
            ...attributes,
            given_before_survey_page_id,
            question_type: SURVEY_FORM_TYPE_SURVEY_HEADING,
          }
        }).filter(item => includeDiscarded ? item : !!item.discarded_at ? null : item),
    ].sort((a, b) => a.display_order - b.display_order),
    survey_page_display_conditions_attributes: survey_page_display_conditions_attributes.filter(({ id }) => includesId(relationships.survey_page_display_conditions, id)).map(
      item => ({
        id: +item.id,
        survey_page_id: +id,
        question_id: item.attributes.question_id,
        question_option_id: item.attributes.question_option_id,
        area_id: item.attributes.area_id,
        shop_id: item.attributes.shop_id,
      })
    ),
  }
};

export const getSurveyPageFromIncluded = ({ id, included, questions, survey_heading, questionOptions, survey_page_display_conditions_attributes }, includeDiscarded=true) => {
  let itemData = null;
  
  for (let item of included) {
    if (item.id === id && (includeDiscarded || (!includeDiscarded && !!!item.discarded_at))) {
      itemData = item;
      break;
    }
  }

  return itemData === null ? null : parseSurveyPage({ ...itemData, questions, survey_heading, questionOptions, survey_page_display_conditions_attributes }, includeDiscarded);
}
