import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormHelperText,
  FormControl,
  TextField,
  Button,
  makeStyles,
  Typography
} from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import { QuestionTypeSelectCheckboxStyles } from "./styles";
import HtmlRender from './HtmlRender';

const useStyles = makeStyles(QuestionTypeSelectCheckboxStyles);

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onInputValue: PropTypes.func.isRequired,
};

const QuestionTypeSelectCheckbox = ({
  questionItem,
  answer,
  errors,
  onInputValue,
}) => {
  const classes = useStyles();
  const freeAnswerOptions = questionItem.question_options_attributes.filter(({ is_free_answer }) => is_free_answer === true);
  const exclusiveAnswerOptions = questionItem.question_options_attributes.filter(({ is_exclusive }) => is_exclusive === true);
  const [values, setValues] = useState((answer.answer_options_attributes || []).map(({ question_option_id }) => question_option_id));
  const [freeAnswerIsSelected, setFreeAnswerIsSelected] = useState(freeAnswerOptions.filter(({ id }) => values.includes(id)).length > 0);
  const [faValue, setFaValue] = useState(answer.answer_entry || "");

  const selectHasError = !!errors.answer_options_attributes;
  const textHasError = !!errors.answer_entry;

  const selectChangeHandler = (option) => {
    const value = option.id;
    const onTimeSelectedValues = values.includes(value) ?  values.filter(item => item !== value) : [...values, value];
    const exclusiveAnswerIsSelected = exclusiveAnswerOptions.filter(({ id }) => onTimeSelectedValues.includes(value) && value === id).length > 0;

    let resValues = [];
    if (exclusiveAnswerIsSelected) {
      resValues = [value];
    } else {
      resValues = onTimeSelectedValues.filter(selectedOptionId => !exclusiveAnswerOptions.map(item => item.id).includes(selectedOptionId));
    }

    setValues([...resValues]);

    const faIsSelected = freeAnswerOptions.filter(({ id }) => resValues.includes(id)).length > 0;
    let answer_entry = '';

    if (faIsSelected) {
      answer_entry = faValue;
    } else {
      setFaValue(answer_entry);
    }

    setFreeAnswerIsSelected(faIsSelected);
    onInputValue(questionItem, { checkboxValues: resValues, answer_entry });
  };

  const textChangeHandler = ({ target: { value } }) => {
    setFaValue(value);
    onInputValue(questionItem, { checkboxValues: values, answer_entry: value });
  };

  const min_selection_count = questionItem.is_required && +questionItem.min_selection_count === 0 ? 1 : +questionItem.min_selection_count;

  return (
    <>
      <Typography className={classes.textAnswer}>
        <HtmlRender
          value = {questionItem.body}
        />
        {questionItem.is_required && (
          <span className="required">必須</span>
        )}
      </Typography>
      {!!questionItem.question_image_url && (
        <div className={classes.img}>
          <img src={questionItem.question_image_url} alt={''}/>
        </div>
      )}
      <div className={classes.note}>
        {(0 < min_selection_count) && `最低${min_selection_count}個、`}最大{questionItem.max_selection_count}個まで選択できます。({values.length}/{questionItem.max_selection_count})
      </div>
      <div className={classes.contentSelect}>
        {questionItem.question_options_attributes.map(option => {
          return (
            <Button
              className={values.includes(option.id) ? classes.btnColor : classes.btn}
              variant="outlined"
              startIcon={
                <StopIcon
                  className={values.includes(option.id) ? classes.active : classes.icon}
                />
              }
              onClick={() => selectChangeHandler(option)}
              key={option.id}
            >
              {!!option.option_image_url ? (
                <div>
                  <div className={classes.image}>
                    <img className={classes.imgView} src={option.option_image_url} alt={''}/>
                  </div>
                  <div>
                    <HtmlRender
                      value = {option.name}
                    />
                  </div>
                </div>
              ) : (
                <HtmlRender
                  value = {option.name}
                />
              )}
            </Button>
          )
        })}
        {selectHasError && (
          <FormHelperText error={true}>{errors.answer_options_attributes.map(err => err)}</FormHelperText>
        )}
      </div>
      {freeAnswerIsSelected && (
        <FormControl component="fieldset" fullWidth className={classes.form_control} error={textHasError}>
          <TextField
            variant="standard"
            value={faValue}
            onInput={textChangeHandler}
            placeholder="入力してください"
          />
          {textHasError && (
            <FormHelperText error={true}>{errors.answer_entry.map(err => err)}</FormHelperText>
          )}
        </FormControl>
      )}
    </>
  );
}

QuestionTypeSelectCheckbox.propTypes = propTypes;

export default QuestionTypeSelectCheckbox;
