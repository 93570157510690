import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  UPDATE_USER_ITEM,
  SAVE_USER_BEGIN,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  FETCH_ALL_USERS_BEGIN,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILURE,
  CHANGE_USER_ITEM_STATE,
  FETCH_USERS_RESET,
} from "./actions";
import { parseUser } from "../utils";
import { mergeItem } from "../../utils";


const initialState = {
  item: {},
  itemSubmitting: false,
  itemSubmitSuccess: false,
  errors: null,

  pagination: {
    total: 0
  },

  items: [],
  loading: false,
  loaded: false,
  error: null,

  allItems: null,
  allItemsLoading: false,
  allItemsLoaded: false,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_BEGIN: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        itemSubmitting: false,
      };
    }

    case FETCH_USERS_SUCCESS: {
      const { data, included } = action.payload.items;

      return {
        ...state,
        loading: false,
        loaded: true,
        items: data.map(item => parseUser(item, included)),
        pagination: action.payload.pagination,
      };
    }

    case FETCH_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
        items: [],
      };
    }

    case FETCH_USERS_RESET: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case UPDATE_USER_ITEM: {
      const { item } = action.payload;

      return {
        ...state,
        item,
      };
    }

    case FETCH_ALL_USERS_BEGIN: {
      return {
        ...state,
        allItemsLoading: true,
        allItemsLoaded: false,
        allItemsError: null,
      };
    }

    case FETCH_ALL_USERS_SUCCESS: {
      const { data } = action.payload.items;

      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: true,
        allItems: data.map(({ id, attributes: { name , email} }) => ({ label: name, value: id, email: email })),
      };
    }

    case FETCH_ALL_USERS_FAILURE: {
      return {
        ...state,
        allItemsLoading: false,
        allItemsLoaded: true,
        allItemsError: action.payload.error,
        allItems: [],
      };
    }

    case SAVE_USER_BEGIN: {
      return {
        ...state,
        itemSubmitting: true,
        errors: null,
        itemSubmitSuccess: false,
      };
    }

    case SAVE_USER_SUCCESS: {
      const { data, included } = action.payload.data || {}
      const item = parseUser(data, included)
      return {
        ...state,
        itemSubmitting: false,
        itemSubmitSuccess: true,
        errors: null,
        item,
        items: mergeItem(item, state.items),
      };
    }

    case SAVE_USER_FAILURE: {
      return {
        ...state,
        itemSubmitting: false,
        itemSubmitSuccess: false,
        errors: action.payload.errors,
      };
    }

    case CHANGE_USER_ITEM_STATE: {
      const { id, discarded_at } = action.payload

      return {
        ...state,
        items: state.items.map(item => item.id === id ? {
          ...item,
          discarded_at,
        } : item),
        item: {
          ...state.item,
          discarded_at,
        }
      }
    }

    default:
      return state;
  }
};
