import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover, Hidden } from "@material-ui/core";

import HeaderInputs from "common/Header/HeaderForm/HeaderInputs";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10px'
  },
  paper: {
    width: 295,
    backgroundColor: theme.palette.background.paper,
    padding: "20px 20px 10px 20px"
  }
}));

const InputsPopover = ({ open, onClose, anchorEl }) => {
  const classes = useStyles();

  return (
    <Hidden only={["xs", "lg", "xl"]}>
      <Popover
        id={"inputs-header-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        className={classes.root}
      >
        <div className={classes.paper}>
          <HeaderInputs />
        </div>
      </Popover>
    </Hidden>
  );
};

export default InputsPopover;
