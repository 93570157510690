import React from 'react';
import PropTypes from 'prop-types';
import { SwipeableDrawer } from '@material-ui/core';

const propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.any,
};

const SwipeableModal = ({ modalOpened, closeModal, children }) => {

  return (
    <SwipeableDrawer
      anchor="right"
      aria-describedby="simple-modal-description"
      open={modalOpened}
      onOpen={() => {}}
      onClose={closeModal}
    >
      {children}
    </SwipeableDrawer>
  )
}

SwipeableModal.propTypes = propTypes;

export default SwipeableModal;
