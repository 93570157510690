export const themedStyles = theme => ({
  loading: {
    width: "200px",
    height: "200px",
    margin: "0 auto",
    paddingTop: "75px"
  },
  root: {
    width: "100%",
    overflowX: "auto",
    boxShadow: "0px 2px 1px -1px #fff"
  },

  headerCom: {
    background: "#fff",
    "& p": {
      font: "18px/24px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.45px",
      color: "#1A0707",
      opacity: 1,
      margin: 0,
      padding: "17px"
    },
    "& hr": {
      color: "#EFEFEF",
      background: "#EFEFEF"
    },
    "& button": {
      background: "#EC681A 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      opacity: 1,
      textAlign: "left",
      font: "bold 14px/21px Noto Sans JP, Hiragino Sans",
      padding: "11px 15px",
      margin: "10px 17px 10px 17px",
      "& span": {
        font: "14px/21px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.35px",
        color: "#FFFFFF",
        opacity: 1,
        "& i": {
          marginRight: "15px"
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      background: "#fff",
      "& p": {
        font: "14px/24px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.45px",
        color: "#1A0707",
        opacity: 1,
        margin: 0,
        padding: "17px"
      },
      "& hr": {
        color: "#EFEFEF",
        background: "#EFEFEF"
      },
      "& button": {
        background: "#EC681A 0% 0% no-repeat padding-box",
        borderRadius: "5px",
        opacity: 1,
        textAlign: "left",
        font: "bold 10px/21px Noto Sans JP, Hiragino Sans",
        padding: "8px 10px",
        margin: "10px 17px 10px 17px",
        "& span": {
          font: "10px/21px Noto Sans JP, Hiragino Sans !important",
          letterSpacing: "0.35px",
          color: "#FFFFFF",
          opacity: 1,
          "& i": {
            marginRight: "15px",
            fontSize: "15px"
          }
        }
      }
    }
  },
  headerTable: {
    background: "#F2EEEC 0% 0% no-repeat padding-box",
    borderRadius: "5px 5px 0px 0px",
    "& th": {
      borderRight: "1px solid #B7B7B7",
      padding: "12px",
      font: "500 12px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0",
      color: "#1A0707",
      opacity: 1
    }
  },
  thTable: {
    backgroundColor: "#F2EEEC",
    height: "36px",
    lineHeight: "36px",
    color: "#1A0707",
    fontSize: "12px",
    fontWeight: "bold",
    boxShadow: "0px 1px 3px #00000029"
  },
  bodyCheck: {
    backgroundColor: "#EC681A",
    borderRadius: " 5px 0px 0px 5px"
  },
  totalAc: {
    textAlign: "left",
    font: "600 22px/29px Noto Sans JP, Hiragino Sans",
    letterSpacing: "1.1px",
    color: "#1A0707",
    opacity: 1,
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      font: "600 15px/20px Noto Sans JP, Hiragino Sans",
      color: "#1A0707",
      opacity: 1,
      textAlign: "left",
      marginbBttom: "15px",
      letterSpacing: "1.1px"
    },
    [theme.breakpoints.down("xs")]: {
      font: "600 15px/20px Noto Sans JP, Hiragino Sans",
      color: "#1A0707",
      opacity: 1,
      textAlign: "left",
      marginbBttom: "15px",
      letterSpacing: "1.1px"
    }
  },
  btnCheck: {
    width: "40px",
    height: "10px",
    padding: "5px 11px",
    borderRadius: "10px",
    font: "10px/18px Noto Sans JP, Hiragino Kaku Gothic Pro",
    color: "white",
    marginRight: "8px"
  },
  contentId: {
    font: "400 12px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    opacity: 1
  },
  date: {
    font: "400 12px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.25px",
    color: "#1A0707",
    opacity: 1,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      font: "400 10px/16px Noto Sans JP, Hiragino Sans"
    },
    [theme.breakpoints.down("xs")]: {
      font: "400 7px/16px Noto Sans JP, Hiragino Sans"
    }
  },
  time: {
    font: "400 10px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.25px",
    color: "#939393",
    opacity: 1,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      font: "400 10px/16px Noto Sans JP, Hiragino Sans"
    },
    [theme.breakpoints.down("xs")]: {
      font: "400 7px/11px Noto Sans JP, Hiragino Sans"
    }
  },
  users_viewing_target_names: {
    width: "16.9%"
  },
  is_personal_info_viewable: {
    minWidth: "171px",
    borderRight: "none !important"
  },
  blockTable: {
    padding: "34px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0"
    }
  },
  borderRight0: {
    "& >div": {
      borderRight: "0px solid #B7B7B7"
    }
  },
  Thborder: {
    borderRight: "1px solid #B7B7B7",
    font: "12px/14px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    /* opacity: 1; */
    padding: "12px 20px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      borderRight: "1px solid #B7B7B7",
      font: "10px/12px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      /* opacity: 1; */
      padding: "12px 5px",
      fontWeight: "600"
    },
    [theme.breakpoints.down("xs")]: {
      borderRight: "1px solid #B7B7B7",
      font: "7px/12px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      /* opacity: 1; */
      padding: "12px 3px",
      fontWeight: "600"
    }
  },
  tdTable: {
    textAlign: "left",
    fontSize: "12px",
    color: "#1A0707",
    marginTop: "10px",
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    backgroundColor: "white",
    position: "relative",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {}
  },
  tdTableGird: {
    display: "flex",
    alignItems: "center",
    padding: "18px 20px",
    borderLeft: "1px solid #EFEFEF",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0 10px 4px"
    },
    [theme.breakpoints.down("xs")]: {}
  },
  Tdborder: {
    alignItems: "center",
    font: "12px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    opacity: "1",
    textAlign: "left",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    "& i": {
      color: "#B7B7B7",
      cursor: "pointer",
      fontSize: 33
    },
    [theme.breakpoints.down("sm")]: {
      font: "10px/16px Noto Sans JP, Hiragino Sans",
      "& i": {
        fontSize: "20px"
      }
    },
    [theme.breakpoints.down("xs")]: {
      font: "7px/10px Noto Sans JP, Hiragino Sans",
      "& i": {
        fontSize: "20px"
      }
    }
  },
  expandRow: {
    position: "absolute",
    right: 0,
    padding: "12px 29px 12px 30px",
    background: "#EFEFEF 0% 0% no-repeat padding-box",
    width: "300px",
    display: "block",
    alignItems: "center",
    bottom: 0,
    top: 0,
    lineHeight: 4,
    "& >div": {
      font:"500 10px/24px Noto Sans JP, Hiragino Sans",
      color: "#F02544",
      border: "1px solid #F02544",
      cursor: "pointer",
      opacity: 1,
      padding: "3px 20px 3px 15px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      textAlign: "left",
      borderRadius: "5px",
      letterSpacing: "0.25px",
      alignItems: "center",
      display: "inline-flex",
      "&:hover": {
        opacity: 0.6
      },
      "& >i": {
        fontSize: "16px",
        marginRight: "15px",
        cursor: "pointer"
      }
    },
    "& >i": {
      color: "#EC681A",
      fontSize: "33px",
      float: "right",
      position: "relative",
      top: "5px",
      cursor: "pointer"
    }
  },
  trNumber: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& >div>i": {
      fontSize: "30px"
    },
    [theme.breakpoints.down("sm")]: {
      "& >div>i": {
        fontSize: "20px"
      }
    },
    [theme.breakpoints.down("xs")]: {
      "& >div>i": {
        fontSize: "20px"
      }
    }
  },
  activeButtonArchive: {
    background: "#fff",
    padding: "10px 40px",
    textAlign: "right",
    margin: "20px 0",
    "& >div": {
      display: "inline-flex",
      alignItems: "center",
      textAlign: "right",
      width: "160px",
      border: "2px solid #EC681A",
      padding: "8px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      font: "500 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#fff",
      background: "#EC681A",
      opacity: 1,
      "&:hover": {
        opacity: 0.6
      },
      "& >div": {
        width: "16px",
        height: "16px",
        border: "1px solid #fff",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        marginRight: "15px",
        "& >div": {
          background: "#fff 0% 0% no-repeat padding-box",
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center"
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px",
      textAlign: "right",
      margin: "20px 0",
      "& >div": {
        padding: "8px 5px",
        font: "500 8px/16px Noto Sans JP, Hiragino Sans",
        width: "auto",
        "&:hover": {
          opacity: 0.6
        },
        "& >div": {
          width: "14px",
          height: "14px",
          marginRight: "10px",
          "& >div": {
            width: "8px",
            height: "8px"
          }
        }
      }
    }
  }
});
