import React, { useRef, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { printTemplateStyles } from './styles'
import { getNpsColorStyle, getAnswerValue, getNpsName, getFeedbackUserName } from './utils'
import {TYPE_NPS_QUESTION, TYPE_NPS_REASON, TYPE_UPLOAD} from './constants'
import { format } from 'date-fns'
import { connect } from 'react-redux'
import { GetApp as GetAppIcon } from '@material-ui/icons'
import { renderHtmlWithoutTags } from '../../utils';


const PrintTemplate = ({answers, stopPrint, feedback}) => {
  const printTagRef = useRef(null)
  const classes = printTemplateStyles()

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0]
    const body = document.getElementsByTagName('body')[0]
    const printScreen = window.open('', '', '')
    printScreen.document.write(html.outerHTML.replace(body.innerHTML, printTagRef.current.outerHTML))
    if(feedback) {
      setTimeout(() => {
        printScreen.document.close()
        printScreen.focus()
        printScreen.print()
        printScreen.close()
        stopPrint()
      }, 200);
    }
  })

  if (!feedback || !answers) { return <></> }

  return (
    <div className={classes.notDisplay}>
      <div className={classes.sectionToPrint} ref={printTagRef}>
        <p className={classes.txtTop}>ID: {feedback.id}</p>
        <div className={classes.boxInfo}>
          <div className={classes.divNumber} style={getNpsColorStyle(feedback.nps_score)}>
            {feedback.nps_score}
            <p className={classes.underScore}>{getNpsName(feedback.nps_score)}</p>
          </div>

          <div>
            <p><strong>{getFeedbackUserName(feedback)}</strong></p>
            <p>{feedback.shop.name}</p>
            <p className={classes.feedbackDate}>{format(new Date(feedback.created_at), "yyyy/MM/dd HH:mm")}</p>
          </div>
        </div>
        <p className={classes.tagTitle}>回答</p>
        {
          answers.map((answer, index) => {
            const { visible } = getAnswerValue(feedback, answer)
            return (
              <div key={index} className={classes.questionTag}>
                <div className={classes.questionTitle}>
                  <div className={classes.questionTitleText}>
                    {renderHtmlWithoutTags(answer.question.body)}
                  </div>

                  <div className={classes.questionTitleButton}>
                    {answer.question.is_required && (
                      <div className={classes.colorRed}>必須</div>
                    )}
                    {answer.question_type === TYPE_NPS_REASON && (
                      <div className={classes.colorGreen}>NPS理由</div>
                    )}
                    {answer.question_type === TYPE_NPS_QUESTION && (
                      <div className={classes.colorOrange}>NPS</div>
                    )}
                  </div>
                </div>

                <div className={classes.answer}>
                  {
                    answer.question_type === TYPE_UPLOAD ?
                    <Button variant="contained" startIcon={<GetAppIcon/>}>
                      {`ダウンロード(${answer.answer_image_size})`}
                    </Button>
                    : visible
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const mapState = ({ feedbacks: { feedback }, feedbackAnswers: { items, loading } }) => ({
  answers: items,
  feedback,
  loading,
})

export default connect(
  mapState
)(PrintTemplate);
