import React, { useState, useEffect, useRef } from "react";
import { default as axios } from "axios";
import { makeStyles } from "@material-ui/core";
import DialogModal from "components/DialogModal";
import { SURVEYS_API_PATH } from "./constants";
import { QRCodeModalStyles } from "./styles";

const useStyles = makeStyles(QRCodeModalStyles);

const QRCodeModal = ({ feedbackPath, closeQRModal, survey, showQRModal }) => {
  const classes = useStyles();
  const [paramList, setParamList] = useState([]);
  const response = useRef(null);
 
  useEffect(() => {
    if (!!survey.id) {
      setParamList([]);
      axios
        .get(`${SURVEYS_API_PATH}/${survey.id}/survey_shortened_urls`)
        .then(res => {
          response.current = res.data;
          setParamList(res.data.data.map(item => {
            const areaId = !!item.relationships.area.data ? item.relationships.area.data.id : null;
            const shopId = !!item.relationships.shop.data ? item.relationships.shop.data.id : null;
            let areaName = null, shopName = null, queryParamString = '';

            if (!!shopId) {
              const foundShop = res.data.included.find(item => item.type === 'shop' && +item.id === +shopId);

              if (!!foundShop) {
                shopName = foundShop.attributes.name;
                queryParamString = `?shop_id=${foundShop.attributes.uid}`;
              } 
            } else if (!!areaId) {
              areaName = res.data.included.find(item => item.type === 'area' && +item.id === +areaId).attributes.name;
              queryParamString = `?area_id=${areaId}`;
            }

            return {
              areaName,
              shopName,
              queryParamString,
            }
          }));
        });
    }

  }, [survey.id]);

  const handleClose = () => {
    closeQRModal();
  };

  return (
    <DialogModal open={showQRModal} onClose={handleClose}>
      <div className={classes.QrCodeModal}>
        <div className="underlinedTop" />
        <div className="QrCodeModalContent">
          <div className="header">
            <p>{survey.name}</p>
            <button onClick={handleClose}>
              閉じる
              <i className="el-icon-close"></i>
            </button>
          </div>
          <div className="content">
            <div className="url">
              <p>URL</p>
              <p className="link">
                {feedbackPath}
              </p>
            </div>
          </div>

          {paramList.map((item, index) =>
            <div key={`param-${index}`} className={`blockDownload`}>
              <p className={classes.shopName}>{!!item.shopName ? item.shopName : !!item.areaName ? item.areaName : ''}</p>
              <p className={classes.feedbackLink}>{feedbackPath}{item.queryParamString}</p>
            </div>
          )}

        </div>
      </div>
    </DialogModal>
  );
};

export default QRCodeModal;
