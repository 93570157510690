import { SURVEY_FORM_TYPE_SURVEY_HEADING, QUESTION_TYPE_SELECT, QUESTION_TYPE_RADIO_BUTTON, QUESTION_TYPE_SELECT_BUTTON, QUESTION_TYPE_CHECK_BOX, QUESTION_TYPE_NPS_QUESTION, QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_NPS_REASON, QUESTION_TYPE_TEXT_FIELD } from "./questions/constants";

export const questionConstraints = {
  body: (_, attributes) => {
    return {
      presence: {
        allowEmpty: attributes.question_type === SURVEY_FORM_TYPE_SURVEY_HEADING,
        message: "^質問を入力してください"
      },
//      length: {
//        maximum: 500,
//        message: "^質問は500文字以内で入力してください"
//      }
    }
  },

  abbreviation: {
    length: {
      maximum: 255,
      message: "^略称は255文字以内で入力してください"
    }
  },

  question_type: {
    presence: {
      allowEmpty: false,
      message: "^回答方式を選択してください"
    },
  },

  question_options_attributes: (_, attributes) => {
    if (
      [ QUESTION_TYPE_SELECT,
        QUESTION_TYPE_RADIO_BUTTON,
        QUESTION_TYPE_CHECK_BOX
      ].includes(attributes.question_type)
    ) {
      return {
        presence: { message: "^選択肢は1件以上入力してください" },
        length: { minimum: 1 }
      }
    } else if (attributes.question_type === QUESTION_TYPE_NPS_QUESTION) {
      return {
        presence: { message: "^選択肢は11件入力してください" },
        length: { is: 11 }
      }
    } else if (attributes.question_type === QUESTION_TYPE_SELECT_BUTTON) {
      return {
        presence: { message: "^選択肢は11件まで入力してください" },
        length: { maximum: 11 }
      }
    }

    return null;
  },

  min_selection_count: (_, attributes) => {
    if ([QUESTION_TYPE_CHECK_BOX].includes(attributes.question_type)) {
      const optionCount = attributes.question_options_attributes.length;
      return {
        numericality: {
          onlyInteger: true,
          strict: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: optionCount,
          message: `^最小選択数は0-${optionCount}の間で入力してください`
        },
      }
    }

    return null;
  },

  max_selection_count: (_, attributes) => {
    if ([QUESTION_TYPE_CHECK_BOX].includes(attributes.question_type)) {
      const minCount = Math.max(+attributes.min_selection_count || 0, 0);
      const optionCount = attributes.question_options_attributes.length;
      return {
        numericality: {
          onlyInteger: true,
          strict: true,
          greaterThanOrEqualTo: minCount,
          lessThanOrEqualTo: optionCount,
          message: `^最大選択数は${minCount}-${optionCount}の間で入力してください`
        },
      }
    }

    return null;
  },

  min_character_count: (_, attributes) => {
    if ([QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_NPS_REASON, QUESTION_TYPE_TEXT_FIELD].includes(attributes.question_type)) {
      const maxCount = Math.min(+attributes.max_character_count || 5000, 5000);
      return {
        numericality: {
          onlyInteger: true,
          strict: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: maxCount,
          message: `^最小文字数は0-${maxCount}の間で入力してください`
        },
      }
    }

    return null;
  },

  max_character_count: (_, attributes) => {
    if ([QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_NPS_REASON, QUESTION_TYPE_TEXT_FIELD].includes(attributes.question_type)) {
      const minCount = Math.max(+attributes.min_character_count || 0, 0);
      return {
        numericality: {
          onlyInteger: true,
          strict: true,
          greaterThanOrEqualTo: minCount,
          lessThanOrEqualTo: 5000,
          message: `^最大文字数は${minCount}-5000の間で入力してください`
        },
      }
    }

    return null;
  },

  line_number: (_, attributes) => {
    if ([QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_NPS_REASON].includes(attributes.question_type)) {
      return {
        numericality: {
          onlyInteger: true,
          strict: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 5000,
          message: "^行数は0-5000の間で入力してください"
        },
      }
    }

    return null;
  },

  free_entry_validation: (_, attributes) => {
    if ([QUESTION_TYPE_TEXT_AREA, QUESTION_TYPE_NPS_REASON, QUESTION_TYPE_TEXT_FIELD].includes(attributes.question_type)) {
      return {
        presence: { allowEmpty: false, message: "^入力規則を選択してください" },
      }
    }

    return null;
  }
};

export const optionAttributeConstraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: "^未評価選択肢を入力してください"
    },
    length: {
      maximum: 255,
      message: "^未評価選択肢は255文字以内で入力してください"
    }
  },
  value: (_, attributes) => {
    if (attributes.question_type === QUESTION_TYPE_NPS_QUESTION) {
      return {
        //presence: { allowEmpty: false, message: "^Ptを入力してください" },
        numericality: {
          onlyInteger: true,
          strict: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 10,
          message: "^Ptは0-10の間で入力してください"
        },
      };
    }

    return {
      //presence: { allowEmpty: false, message: "^Ptを入力してください" },
      numericality: {
        onlyInteger: true,
        strict: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 5000,
        message: "^Ptは0-5000の間で入力してください"
      },
    };
  }
};
