import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormHelperText,
  makeStyles,
  Typography
} from '@material-ui/core';
import { QuestionTypeNPSStyle } from "./styles"
import CustomTooltip from '../CustomTooltip'
import HtmlRender from './HtmlRender';

const useStyles = makeStyles(QuestionTypeNPSStyle);

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onInputValue: PropTypes.func.isRequired,
};

const QuestionTypeNPS = ({
  questionItem,
  answer,
  errors,
  onInputValue,
}) => {
  const classes = useStyles();
  const value = (answer.answer_options_attributes || []).map(({ question_option_id }) => question_option_id)[0] || "";
  const [selectedValue, setSelectedValue] = useState(value);

  const selectHasError = !!errors.answer_options_attributes;

  const selectChangeHandler = (value) => {
    onInputValue(questionItem, { selectValue: value });
    setSelectedValue(value);
  };

  const choices = (questionItem.question_options_attributes || []);

  return (
    <>
      <Typography className={classes.textAnswer}>
        <HtmlRender
        value = {questionItem.body}
        />
        {questionItem.is_required && (
          <span className="required">必須</span>
        )}
      </Typography>
      {!!questionItem.question_image_url && (
        <div className={classes.img}>
          <img src={questionItem.question_image_url} alt={''}/>
        </div>
      )}
      <div className={classes.tabWithNumber}>
        <div className={classes.flexContainer}>
          {
            choices.map((option, index) => {
              const dataLenght = questionItem.question_options_attributes.length;
              const styles = {
                width: `calc(100%/${dataLenght})`,
                minWidth: `calc(100%/${dataLenght})`,
                fontSize: questionItem.use_number ? 13 : 25
              }
              const { id, name, value } = option

              return (
                <CustomTooltip
                  open={selectedValue ? selectedValue === id : [0, choices.length - 1].includes(index)}
                  title={name}
                  style={styles}
                  key={id}
                >
                  <Button className={selectedValue !== id ? classes.btnIcon : classes.btnIconColor}
                    onClick={() => { selectChangeHandler(id) }}
                  >
                    {questionItem.use_number === true ? value : '○'}
                  </Button>
                </CustomTooltip>
              )
            })
          }
        </div>
      </div>
        {selectHasError && (
          <FormHelperText error={true}>{errors.answer_options_attributes.map(err => err)}</FormHelperText>
        )}
    </>
  );
}

QuestionTypeNPS.propTypes = propTypes;

export default QuestionTypeNPS;
