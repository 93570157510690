import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import {
  Paper,
  makeStyles,
} from '@material-ui/core';
import QuestionItem from '../components/QuestionItem';
import { themedStyles } from './styles';
import { GAPageView } from '../../../../ga';
import ProgressionButtons from './ProgressionButtons';
import { htmlCss } from "../utils";
import SurveyFooter from "./SurveyFooter";
import { isIE } from 'react-device-detect'


const colorComon = "#EC681A";

const useStyles = makeStyles(themedStyles);

const Root = styled.div`
  width: 100%;
  background-color: #F7F7F7;
  padding: 0 calc((100vw - 600px) / 2);
  font-size: 13px;
`

const propTypes = {
  surveyPage: PropTypes.object.isRequired,
  companyUrlText: PropTypes.string.isRequired,
  shortUrl: PropTypes.string.isRequired,
  staff_id: PropTypes.number,
  currentPage: PropTypes.string.isRequired,
  survey: PropTypes.object,
  updateHeaderHeight: PropTypes.func.isRequired,
  top: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
  public_ga: PropTypes.object.isRequired,
};

const SurveyPage = ({
  surveyPage,
  companyUrlText,
  shortUrl,
  staff_id,
  currentPage,
  survey,
  updateHeaderHeight,
  top,
  meta,
  public_ga
}) => {
  const styleProps = { color: colorComon, isIE };
  const classes = useStyles(styleProps);
  const [headerHeigh, setHeaderHeigh] = useState(0);
  const surveyPageTag = useRef(null);
  const [currentPageErrors, setCurrentPageErrors] = useState({});

  useEffect(() => {
    if (public_ga.initialized === true) {
      const pageNo = +currentPage + 1;
      GAPageView(pageNo, `回答ページ${pageNo}`, null);
    }
  }, [public_ga.initialized, currentPage]);

  useEffect(() => {
    if (surveyPageTag.current) {
      surveyPageTag.current.scrollIntoView();
    }

    // eslint-disable-next-line
  }, [currentPage]);

  const cssCommon = `
    .textHeader {
      background-color: #EC681A;
      color: #fff;
      padding: 10px;
      margin-bottom: 0;
      font-size: 13px;
    }
  `;

  return (
    <Root>
      <Paper
        className={classes.headerPaper}
        ref={el => {
          if (!el) return;
          updateHeaderHeight(el.getBoundingClientRect().height)
        }}
      >
        {renderHTML(survey.common_header)}
        {renderHTML(htmlCss(survey.common_css || cssCommon))}
      </Paper>

      <div className={classes.header} style={{ top: top + 35 }}
        ref={el => {
          if (!el) return;
          const { height } = el.getBoundingClientRect()
          setHeaderHeigh(height + top)
        }}
      >
        <div>{survey.page_title}</div>
        <div className={classes.headerShop}>{meta.shop.name}</div>
      </div>

      <div className={classes.contentSurveyPage} style={{ paddingTop: headerHeigh + 20 }} ref={surveyPageTag}>
        {surveyPage.question_items.map((questionItem, index) =>
          <QuestionItem
            key={`question-item-${surveyPage.page_number}-${questionItem.question_type}-${questionItem.id}`}
            surveyPage={surveyPage}
            questionItem={questionItem}
            questionIndex={index}
            companyUrlText={companyUrlText}
            shortUrl={shortUrl}
            staff_id={staff_id}
            setCurrentPageErrors={setCurrentPageErrors}
          />
        )}
      </div>

      <div className={classes.heightButton}></div>

      <ProgressionButtons currentPageErrors={currentPageErrors}/>
      <SurveyFooter classes={classes} survey={survey} defaultCss={cssCommon}/>
    </Root>
  )
}

SurveyPage.propTypes = propTypes;

export default connect(
  ({ public: { survey, meta }, public_ga }) => ({
    survey,
    meta,
    public_ga,
  }),
)(SurveyPage);
