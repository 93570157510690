export const AUTHENTICATION_BEGIN   = 'AUTHENTICATION_BEGIN';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// このmoduleはdispatchしないのでaction createrではありません。
// actionのtype及びpayloadを返します。


export const authenticationBegin = () => ({
  type: AUTHENTICATION_BEGIN
});

export const authenticationSuccess = (response, saveSession, history) => ({
  type: AUTHENTICATION_SUCCESS,
  payload: {
    response,
    saveSession,
    history,
  }
});

export const authenticationFailure = error => ({
  type: AUTHENTICATION_FAILURE,
  payload: { errors: error.response === undefined ? ['サーバで異常が発生しました'] : error.response.data.errors }
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})
