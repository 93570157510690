import React from "react";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHeaderDrawerStyle } from './styles'
import SaveBtn from "../../../components/SaveBtn";

const HeaderDrawer = ({ onDelete, onSubmit, onClose, dirty, name }) => {
  const classes = useHeaderDrawerStyle();

  return (
    <div className={classes.header}>
      <SaveBtn onClick={onSubmit} dirty={dirty} itemSubmitting={dirty === null ? true : false}/>

      <Button
        startIcon={<DeleteIcon />}
        className={classes.buttonDel}
        onClick={onDelete}
        disabled={!!!onDelete}
      >
        {`この${name}を削除`}
      </Button>

      <Button
        variant="contained"
        color="default"
        className={classes.buttonClose}
        endIcon={<CloseIcon />}
        onClick={onClose}
      >
        閉じる
      </Button>
    </div>
  );
};

export default HeaderDrawer;
