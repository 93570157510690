export const themedStyles = theme => ({
  loading: {
    width: "200px",
    height: "200px",
    margin: "0 auto",
    paddingTop: "75px"
  },
  root: {
    width: "100%",
    position: "relative",
    flexGrow: 1,
    // padding: 0,
    overflow: "hidden",
    // padding: theme.spacing(3, 3),
    [theme.breakpoints.down("md")]: {
      width: "1101px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "614px",
      padding: "0"
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0"
    }
  },
  clNumber: {},
  clShop: {
    width: "184px"
  },
  clBody: {
    width: "calc(100% - 592px)"
  },
  clDate: {
    width: "158px"
  },
  clButton: {
    width: "158px",
    "& div": {
      textAlign: "left"
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px"
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "19px",
      right: "24px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px 20px 0",
      width: "100%",
      position: "relative",
      bottom: "0",
      right: "0",
      "& div": {
        textAlign: "right"
      }
    }
  },
  trNumber: {
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    borderRadius: "5px 0px 0px 5px",
    opacity: 1,
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    }
  },
  trCheckBox: {
    background: "#EC681A 0% 0% no-repeat padding-box",
    borderRadius: "5px 0px 0px 5px",
    opacity: 1,
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    }
  },
  trShop: {
    padding: "20px 26px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    "&::before": {
      content: '""',
      display: "block",
      borderLeft: "1px solid #B7B7B7",
      position: "absolute",
      height: "100%",
      right: 0,
      top: 0
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px"
    }
  },
  trShopLast: {
    padding: "20px 26px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    "&::before": {
      content: '""',
      display: "block",
      borderLeft: "0px solid #B7B7B7",
      position: "absolute",
      height: "100%",
      right: 0
    }
  },
  trBody: {
    width: "calc(100% - 592px)",
    padding: "20px",
    position: "relative",
    "&::before": {
      content: '""',
      display: "block",
      borderRight: "1px solid #B7B7B7",
      position: "absolute",
      height: "75%",
      right: 0
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      "&::before": {
        content: '""',
        display: "block",
        borderRight: "1px solid #B7B7B7",
        position: "absolute",
        height: "75%",
        right: 0
      }
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 30px",
      "&::before": {
        borderRight: "0"
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px 10px",
      border: "none",
      borderTop: "1px solid #B7B7B7",
      marginTop: "0px"
    }
  },
  borderMobile: {
    [theme.breakpoints.down("sm")]: {
      borderTop: "1px solid #B7B7B7",
      marginTop: "20px"
    },
    [theme.breakpoints.down("xs")]: {
      borderTop: "0",
      marginTop: "0px"
    }
  },
  trDate: {
    width: "158px",
    padding: "20px 0px",
    position: "relative",
    "&::before": {
      content: '""',
      display: "block",
      borderRight: "1px solid #B7B7B7",
      position: "absolute",
      height: "75%",
      right: 0
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px 0px",
      "&::before": {
        content: '""',
        display: "block",
        borderRight: "1px solid #B7B7B7",
        position: "absolute",
        height: "75%",
        right: 0
      }
    }
  },
  trButton: {
    padding: "20px 0px"
  },
  thTable: {
    backgroundColor: "#F2EEEC",
    height: "36px",
    lineHeight: "36px",
    color: "#1A0707",
    fontSize: "12px",
    fontWeight: "bold",
    boxShadow: "0px 1px 3px #00000029"
  },
  Thborder: {
    borderRight: "1px solid #B7B7B7",
    font: "12px/14px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    /* opacity: 1; */
    padding: "12px 20px",
    fontWeight: "600",
    height: 36,
    [theme.breakpoints.down("md")]: {
      borderRight: "1px solid #B7B7B7",
      font: "6px/12px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      /* opacity: 1; */
      padding: "12px 5px",
      fontWeight: "600"
    }
  },
  ThborderLast: {
    borderRight: "none !important",
  },
  ThButton: {
    font: "10px/14px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    /* opacity: 1; */
    padding: "12px 14px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      font: "6px/14px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      /* opacity: 1; */
      padding: "12px 5px",
      fontWeight: "600"
    }
  },
  reason: {
    padding: "10px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0"
    },
    [theme.breakpoints.down("xs")]: {
      font: "10px/20px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      padding: "0"
    }
  },
  Tdborder: {
    alignItems: "center",
    font: "12px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    opacity: "1",
    textAlign: "left",
    wordBreak: "break-word",
    '-ms-word-break': 'break-all',
    
    "& svg": {
      fontSize: "40px",
      color: "#fff"
    },
    [theme.breakpoints.down("xs")]: {
      "& svg": {
        fontSize: "25px",
        color: "#fff"
      }
    }
  },
  tdTable: {
    textAlign: "left",
    fontSize: "12px",
    color: "#1A0707",
    marginTop: "10px",
    backgroundColor: "white",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px"
    }
  },
  score: {
    textAlign: "center",
    font: "Bold 45px Noto Sans JP",
    letterSpacing: "2.25px",
    color: "#FFFFFF",
    opacity: "1",
    [theme.breakpoints.down("md")]: {
      font: "Bold 25px/45px Noto Sans JP",
      marginTop: "0px",
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      font: "Bold 30px Noto Sans JP",
      marginTop: "5px",
      textAlign: "center",
      letterSpacing: "1.9px",
      color: "#FFFFFF",
      opacity: "1"
    }
  },
  underScore: {
    height: "auto",
    font: "Bold 10px Noto Sans JP",
    letterSpacing: "0",
    color: "#FFFFFF",
    opacity: "1",
    [theme.breakpoints.down("md")]: {
      font: "Bold 6px Noto Sans JP",
      textAlign: "center",
      lineHeight: "0px"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
      font: "Bold 10px/18px Noto Sans JP",
      textAlign: "center",
      lineHeight: "0px",
      color: "#FFFFFF"
    }
  },
  user: {
    font: "bold 16px Noto Sans JP, Hiragino Sans",
    letterSacing: "0.4px",
    color: "#1A0707",
    opacity: "1",
    [theme.breakpoints.down("md")]: {
      font: "bold 9px/25px Noto Sans JP, Hiragino Sans"
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      font: "bold 14px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.35px",
      color: "#1A0707",
      opacity: 1
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      font: "bold 12px/25px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      opacity: 1
    }
  },
  shop: {
    font: "14px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0",
    color: "#1A0707",
    opacity: "1",

    [theme.breakpoints.down("md")]: {
      font: "9px/10px Noto Sans JP, Hiragino Sans"
    },
    [theme.breakpoints.down("sm")]: {
      font: "10px/10px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0",
      color: "#1A0707",
      opacity: 1
    },
    [theme.breakpoints.down("xs")]: {
      font: "10px/10px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0",
      color: "#1A0707",
      opacity: 1
    }
  },
  date: {
    font: "14px/30px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.35px",
    color: "#1A0707",
    opacity: "1",
    [theme.breakpoints.down("md")]: {
      font: "9px/10px Noto Sans JP, Hiragino Sans"
    },
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
      font: "12px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      opacity: 1,
      marginRight: "5px"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      font: "10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      marginRight: "0",
      textAlign: "right"
    }
  },
  hour: {
    font: "14px/16px Noto Sans JP, Hiragino Kaku Gothic Pro",
    letterSpacing: "0.35px",
    color: "#1A0707",
    opacity: "1",
    [theme.breakpoints.down("md")]: {
      font: "9px/10px Noto Sans JP, Hiragino Kaku Gothic Pro"
    },
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
      font: "12px/16px Noto Sans JP, Hiragino Kaku Gothic Pro",
      letterSpacing: "0.3px",
      color: "#1A0707",
      opacity: 1
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      font: "10px/16px Noto Sans JP, Hiragino Kaku Gothic Pro",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      textAlign: "right"
    }
  },
  headerCom: {
    background: "#fff",
    "& p": {
      font: "18px/24px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.45px",
      color: "#1A0707",
      opacity: 1,
      margin: 0,
      padding: "17px"
    },
    "& hr": {
      color: "#EFEFEF",
      background: "#EFEFEF"
    },
    "& button": {
      background: "#EC681A 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      opacity: 1,
      textAlign: "left",
      font: "bold 14px/21px Noto Sans JP, Hiragino Sans",
      padding: "11px 15px",
      margin: "10px 17px 10px 17px",
      "& span": {
        font: "14px/21px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.35px",
        color: "#FFFFFF",
        opacity: 1,
        "& i": {
          marginRight: "15px"
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      background: "#fff",
      "& p": {
        font: "14px/24px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.45px",
        color: "#1A0707",
        opacity: 1,
        margin: 0,
        padding: "17px"
      },
      "& hr": {
        color: "#EFEFEF",
        background: "#EFEFEF"
      },
      "& button": {
        background: "#EC681A 0% 0% no-repeat padding-box",
        borderRadius: "5px",
        opacity: 1,
        textAlign: "left",
        font: "bold 10px/21px Noto Sans JP, Hiragino Sans",
        padding: "8px 10px",
        margin: "10px 17px 10px 17px",
        "& span": {
          font: "10px/21px Noto Sans JP, Hiragino Sans !important",
          letterSpacing: "0.35px",
          color: "#FFFFFF",
          opacity: 1,
          "& i": {
            marginRight: "15px",
            fontSize: "15px"
          }
        }
      }
    }
  },
  paginate: {
    width: "45px",
    height: "45px",
    border: "0.5px solid #B7B7B7",
    lineHeight: "45px",
    textAlign: "center",
    background: "white",
    marginLeft: "5px",
    color: "#B7B7B7",
    fontSize: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "30px",
      height: "30px",
      border: "0.5px solid #B7B7B7",
      lineHeight: "30px",
      textAlign: "center",
      background: "white",
      marginLeft: "5px",
      color: "#B7B7B7",
      fontSize: "10px",
      cursor: "pointer"
    }
  },
  containerPagi: {
    margin: "20px auto"
  },
  prev: {
    margin: "0px 10px 0px 15px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px 0px 5px"
    }
  },
  actionPaginate: {
    width: "45px",
    height: "45px",
    background: "#646464",
    lineHeight: "45px",
    textAlign: "center",
    color: "white",
    fontSize: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "30px",
      height: "30px",
      background: "#646464",
      lineHeight: "30px",
      textAlign: "center",
      color: "white",
      fontSize: "10px",
      cursor: "pointer"
    }
  },
  next: {
    margin: "0px 5px 0px 10px"
  },
  totalAc: {
    textAlign: "left",
    font: "600 22px/29px Noto Sans JP, Hiragino Sans",
    letterSpacing: "1.1px",
    color: "#1A0707",
    opacity: 1,
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      font: "600 15px/20px Noto Sans JP, Hiragino Sans",
      color: "#1A0707",
      opacity: 1,
      textAlign: "left",
      marginbBttom: "15px",
      letterSpacing: "1.1px"
    },
    [theme.breakpoints.down("xs")]: {
      font: "600 15px/20px Noto Sans JP, Hiragino Sans",
      color: "#1A0707",
      opacity: 1,
      textAlign: "left",
      marginbBttom: "15px",
      letterSpacing: "1.1px"
    }
  },
  poiterTablet: {
    [theme.breakpoints.between("sm", "md")]: {
      font: "Bold 38px Noto Sans JP",
      marginTop: "0px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0"
    }
  }
});
