export const themedStyles = theme => ({
  root: {
    maxWidth: "559px",
    margin: 'auto',
    height: "100vh",
    wordBreak: "break-all",
    backgroundColor: '#fff',
    '& >*': {
      fontFamily: "'Noto Sans JP', sans-serif !important"
    }
  },
  content: {
    padding: "30px 24px",
    backgroundColor: '#fff',
  },
  contentTitle: {
    padding: "0 24px",
    backgroundColor: '#fff',
    "& h2": {
      marginBottom: "0px",
      paddingTop: "70px",
      font: "bold 14px/24px 'Noto Sans JP', sans-serif",
      letterSpacing: "0.3px",
      wordBreak: "break-all",
      marginTop: '0',
    }
  },
  footer: {
    padding: "0 24px",
    backgroundColor: '#fff',
    bottom: 0,
    position: "absolute",
    "& h2": {
      marginBottom: "0px",
      paddingTop: "70px",
      font: "bold 14px/24px 'Noto Sans JP', sans-serif",
      letterSpacing: "0.3px",
      wordBreak: "break-all",
      marginTop: '0',
    }
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  imgStyle: {
    backgroundColor: '#fff',
    textAlign: 'center'
  },
  textEnd: {
    marginBottom: '35px',
  },
  txtEndHeader: {
    font: "600 12px/18px 'Noto Sans JP', sans-serif",
    letterSpacing: '0.3px',
    color: '#FFFFFF',
    opacity: 1,
  },
  txtEndsubHeader: {
    textAlign: 'left',
    font: "600 24px/32px 'Noto Sans JP', sans-serif",
    letterSpacing: '0.6px',
    color: '#FFFFFF',
    opacity: 1,
  },
  txtEndContent: {
    textAlign: 'left',
    font: "600 10px/21px 'Noto Sans JP', sans-serif",
    letterSpacing: '0.25px',
    color: '#1A0707',
    opacity: 1,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  buttonHandleClose: {
    margin: "0 auto",
    height: 35,
    width: '20%',
    textAlign: 'center',
    color: "#FFFFFF",
    font: "500 12px/24px 'Noto Sans JP', sans-serif",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    display: "block",
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    position: 'relative',
    " & i": {
      fontSize: "large",
      position: "absolute",
      left: 10,
      top: '50%',
      transform: 'translateY(-50%)'
    },
    [theme.breakpoints.down("sm")]: {
      width: '34%'
    },
  }
});
