import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import { useParams, withRouter } from 'react-router-dom';
import FeedbackList from './FeedbackList';
import FeedbackListTools from './FeedbackListTools';
import FeedbackModal from './FeedbackModal';
import FeedbackDetail from './FeedbackDetail';
import FeedbackSurveyFilter from './FeedbackSurveyFilter'
import SelectProjectWarning from '../common/SelectProjectWarning';
import { useGAEffect } from '../../ga';

const useStyles = makeStyles(theme => ({
  feedbackContainer: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: 0,
    minHeight: "calc(100% - 110px)",
    paddingRight: 150,
    paddingLeft: 150,
    [theme.breakpoints.down('xl')]: {
      paddingRight: 100,
      paddingLeft: 100,
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: 20,
      paddingLeft: 20,
      paddingTop: 20,
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 50,
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 5,
      paddingLeft: 5,
    },
  },
}))

const propTypes = {
  searchParams: PropTypes.object,
  history: PropTypes.object.isRequired,
  ga: PropTypes.object.isRequired,
};

const Feedbacks = ({ searchParams, history, ga }) => {
  const classes = useStyles()
  // feedback id comes in path
  const { id } = useParams();
  // comment id comes in query parameter
  const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});

  useGAEffect(ga, null, "フィードバック一覧")

  if (id) {
    return <FeedbackDetail feedbackId={id} commentId={queryParams.id} />
  }

  if (!!!searchParams.project_id) {
    return <SelectProjectWarning />
  }

  return (
    <div className={classes.feedbackContainer}>
      <FeedbackSurveyFilter />
      <FeedbackListTools />
      <FeedbackList history={history} />
      <FeedbackModal>
        <FeedbackDetail />
      </FeedbackModal>
    </div>
  );
};

Feedbacks.propTypes = propTypes;

export default connect(
  ({ global: { searchParams }, ga }) => ({
    searchParams,
    ga
  }),
)(withRouter(Feedbacks))
