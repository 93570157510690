import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  makeStyles,
  Paper, InputBase, Divider, IconButton 
} from '@material-ui/core';
import { parseToOptionValues } from 'modules/utils';
import DialogSelect from './DialogSelect';
import { QuestionTypeSelectStyles } from './styles';
import HtmlRender from './HtmlRender';
import { connect } from "react-redux";
import {labelTextRender} from '../utils'

const useStyles = makeStyles(QuestionTypeSelectStyles);

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  answer: PropTypes.object,
  errors: PropTypes.object,
  onInputValue: PropTypes.func.isRequired,
};

const QuestionTypeSelect = ({
  shopName,
  questionItem,
  answer,
  errors,
  onInputValue,
}) => {
  const classes = useStyles();
  const freeAnswerOptions = questionItem.question_options_attributes.filter(({ is_free_answer }) => is_free_answer === true);
  const selectedOptions = (answer.answer_options_attributes || []).map(({ question_option_id }) => +question_option_id);
  const [selectedOption, setSelectedOption] = useState(questionItem.question_options_attributes.filter(({ id }) => selectedOptions.includes(+id)).map(({ name, id }) => ({ label: name, value: id }))[0] || null);
  const [freeAnswerIsSelected, setFreeAnswerIsSelected] = useState(freeAnswerOptions.map((item) => +item.id).includes(!!selectedOption ? +selectedOption.value : null));
  const [faValue, setFaValue] = useState(answer.answer_entry || "");

  const selectHasError = !!errors.answer_options_attributes;
  const textHasError = !!errors.answer_entry;

  const [open, setOpen] = useState(false);

  const selectChangeHandler = (option) => {
    const faIsSelected = freeAnswerOptions.map(item => +item.id).includes(+option.value);
    let answer_entry = '';
    if (faIsSelected) {
      answer_entry = faValue;
    } else {
      setFaValue(answer_entry);
    }

    onInputValue(questionItem, { selectValue: option.value, answer_entry });

    setSelectedOption(option);
    setFreeAnswerIsSelected(faIsSelected);
  };

  const textChangeHandler = ({ target: { value } }) => {
    setFaValue(value);
    onInputValue(questionItem, { selectValue: selectedOption.value, answer_entry: value });
  };

  const options = parseToOptionValues(
    questionItem.question_options_attributes?
      questionItem.question_options_attributes.map((option)=>{return {...option, name:option.name}})
      : []);

  return (
    <>
      <div className={classes.slAnswer}>
        <Typography className={classes.textAnswer1}>
          <HtmlRender
            value = {questionItem.body}
          />
          {questionItem.is_required && (
            <span className="required">必須</span>
          )}
        </Typography>
        {!!questionItem.question_image_url && (
          <div className={classes.img}>
            <img src={questionItem.question_image_url} alt={''}/>
          </div>
        )}
        <Paper className={classes.selectRoot} onClick={() => setOpen(true)}>
          <InputBase
            className={classes.input}
            placeholder="選択してください"
            readOnly
            value={selectedOption ? 
              labelTextRender(shopName, selectedOption.label)
              : 
              "選択してください"}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton color="primary" className={classes.iconButton} aria-label="directions">
            <i className="el-icon-caret-bottom"></i>
          </IconButton>
        </Paper>
        {selectHasError && (
          <FormHelperText error={true}>{errors.answer_options_attributes.map(err => err)}</FormHelperText>
        )}
      </div>
      {freeAnswerIsSelected && (
        <FormControl component="fieldset" fullWidth className={classes.form_control} error={textHasError}>
          <TextField
            variant="standard"
            value={faValue}
            onInput={textChangeHandler}
            placeholder="入力してください"
          />
          {textHasError && (
            <FormHelperText error={true}>{errors.answer_entry.map(err => err)}</FormHelperText>
          )}
        </FormControl>
      )}
      {open &&
        <DialogSelect
          title="選択してください"
          handleClose={() => setOpen(false)}
          itemSelected={selectedOption}
          handleSelect={selectChangeHandler}
          options={options}
        />
      }
    </>
  );
}

QuestionTypeSelect.propTypes = propTypes;

export default  connect (({public: {doc}})=> {
  return {
    shopName : doc.shop.name
  }
})(QuestionTypeSelect);
