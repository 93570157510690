import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useMediaQuery, useTheme, Radio, FormControlLabel } from '@material-ui/core'
import { useRankingStyles } from './styles'
import { SELECT_COMMON_RANK_DROPDOWN, SET_COMMON_RANK_TRIM30} from '../redux/actions'
import { RankBody } from './common/RankBody'
import Dropdown from './common/Dropdown'
import { convertToListAndParseGroup } from '../../../utils'
import { thunkFetchGroups } from '../../groups/redux/thunk'
import ReloadButton from './common/ReloadButton'
import Progress from './common/Progress'
import styled from 'styled-components'
// import SwitchBtn from "../../../components/SwitchBtn"

const Container = styled.div`
width: 100%;
flex-grow: 1;
overflow-x: hidden;
overflow-y: auto;
max-height: 547.156px;
::-webkit-scrollbar {
  width: 10px;
  background: #b7b7b7 0% 0% no-repeat padding-box;
}
::-webkit-scrollbar-track {
  background: #f2eeec 0% 0% no-repeat padding-box;
}
::-webkit-scrollbar-thumb {
  background: #b7b7b7;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`


const CommonRank = ({
  items,
  name,
  top5,
  selectGroup,
  fetchAllGroups,
  setTrim30Ranking,
  onReload,
  goodVocloading,
}) => {
  const [cleared, setCleared] = useState(true)
  const [trim30_flag, setTrim30_flag] = useState(false)
  const classes = useRankingStyles()
  const { feedbacks_top5, nps_score_top5, selected, loading } = top5
  const groupOptions = convertToListAndParseGroup(items || [])
  const tablet = useMediaQuery(useTheme().breakpoints.down('sm'))

  React.useEffect(() => {
    setCleared(true)
  }, [goodVocloading])


  return (
    <div>
      <div className = {classes.rankings}>
        <span className = {classes.dropdowns}>
          <Dropdown
            label="Group: "
            name={'group'}
            placeholder="グループを選択"
            options={groupOptions || []}
            value={selected}
            onChange={e => {
              selectGroup(name, e)
              setCleared(true)
            }}
            onFocus={fetchAllGroups}
            />
        </span>
        <span className= {classes.switchBtnWrapper}>
            
          <FormControlLabel
            className={classes.dashboardRadioButton}
            control={
              <Radio
                name={`ranking-${name}-trim30`}
                checked={trim30_flag}
                onClick={(e)=> {
                  setTrim30Ranking(name, !trim30_flag)
                  setTrim30_flag(!trim30_flag)
                  setCleared(true)
                }}
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label={'30件未満を除く'}
            labelPlacement="end"
          />
        </span>
        <ReloadButton
          disabled={!cleared}
          isFold={tablet}
          onReload={() => {
            setCleared(false)
            onReload && onReload()
          }}
        />
      </div>
      <Container>
      {loading ? (
        <Progress />
      ) : (
        <RankBody feedback_top5={feedbacks_top5} nps_top5={nps_score_top5} classes={classes} />
      )}
    </Container>
    </div>
  )
}

export default connect(
  ({ dashboard, groups: { items } }, { name }) => ({
    items,
    top5: dashboard[name],
    goodVocloading: dashboard.goodvocs.loading,
  }),
  dispatch => ({
    selectGroup: (name, selected) =>
      dispatch({ type: SELECT_COMMON_RANK_DROPDOWN, payload: { name, selected } }),
    fetchAllGroups: () => dispatch(thunkFetchGroups()),
    setTrim30Ranking: (name, value)=>dispatch({type: SET_COMMON_RANK_TRIM30, payload: {name, value}})
  })
)(CommonRank)
