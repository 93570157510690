import { Switch, withStyles } from "@material-ui/core";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 20,
    padding: 0,
    display: 'flex',
    marginRight: 10,
  },
  switchBase: {
    padding: 2,
    color: 'white',
    '&$checked': {
      transform: 'translateX(19px)',
      color: 'white',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#EC681A'
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 10,
    opacity: 1,
    backgroundColor: '#B7B7B7',
  },
  checked: {},
}))(Switch);


export default AntSwitch;
