import {
  FETCH_COMMENTS_BEGIN,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  CREATE_COMMENT,
  DELETE_COMMENT,
  CLEAR_ERROR,
  DISLIKE_FEEDBACK_COMMENT,
  LIKE_FEEDBACK_COMMENT,
  FETCH_COMMENT_LIKES,
  FETCH_COMMENT_LIKES_BEGIN,
  LIKE_COMMENT_BEGIN,
  LIKE_COMMENT_FAILURE,
} from '../actions/comment'

const initialState = {
  items: [],
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        items: [],
      }
    case FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        likeBegin: false,
        items: action.payload.comments,
        error: null,
      }
    case FETCH_COMMENTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case CREATE_COMMENT:
      return {
        ...state,
        items: state.items.concat([action.payload.comment]),
        error: null,
      }
    case FETCH_COMMENT_LIKES_BEGIN:
      return {
        ...state,
        likes: null,
      }
    case FETCH_COMMENT_LIKES:
      return {
        ...state,
        likes: action.payload.users,
      }
    case DELETE_COMMENT:
      return {
        ...state,
        likeBegin: false,
        items: state.items.filter(item => item.id !== action.payload.commentId),
        error: null,
      }
    case LIKE_COMMENT_BEGIN:
      return {
        ...state,
        likeBegin: true,
      }
    case LIKE_COMMENT_FAILURE:
      return {
        ...state,
        likeBegin: false,
        error: action.payload.error,
      }
    case LIKE_FEEDBACK_COMMENT:
      return {
        ...state,
        likeBegin: false,
        items: updateComments(state, action.payload, +1),
      }
    case DISLIKE_FEEDBACK_COMMENT:
      return {
        ...state,
        likeBegin: false,
        items: updateComments(state, action.payload, -1),
      }
    case CLEAR_ERROR:
      return {
        ...state,
        likeBegin: false,
        error: null,
      }
    default:
      return state
  }
};

const updateComments = (state, payload, delta) => {
  const { comment_id, feedback_comment_like_id } = payload
  return (state.items || []).map(item => {
    if (item.id === comment_id) {
      return {
        ...item,
        feedback_comment_like_id,
        likes_count: Math.max(item.likes_count + delta, 0),
      }
    } else {
      return item
    }
  })
}