import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import MenuDrawer from "./MenuDrawer";
import { thunkFetchUserSelf } from "../../modules/users/redux/thunk";

const useStyles = makeStyles(theme => ({
  navWrap: {
    position: 'relative',
    width: 'auto',
    height: '100vh',
    '& .MuiDrawer-paper':{
      position: 'relative',
    },
    [theme.breakpoints.down('sm')]: {
      zIndex: 1000,
      position: 'fixed',
    },
  },
  menuOpen: {
    width: drawerWidth => drawerWidth,
    borderRight: "1px solid #efefed",
    height: "calc(100% - 100px)",
  }
}));

const Menu = () => {
  const drawerWidth = useSelector(state => state.common.drawerWidth)
  const classes = useStyles(drawerWidth);
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(thunkFetchUserSelf())
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.navWrap}>
      <MenuDrawer />
    </div>
  );
};

export default Menu;
