import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBarTop from "./AppBarTop";
import AppBarBottom from "./AppBarBottom";
import { HEADER_BACKGROUND_COLOR, HEADER_TEXT_COLOR } from "../../constants";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flex: 1,
  },
  appBar: {
    flexDirection: "column",
    boxShadow: "none",
    backgroundColor: "white",
    position: "sticky",
    [theme.breakpoints.between("sm", "md")]: {
      position: "sticky",
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  appBarTop: {
    zIndex: theme.zIndex.appBar + 2,
  },
  appBarBottomWrapper: {
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up(768)]: {
      marginLeft: "0px",
    },
    [`@media (min-width:641px) and (max-width:767px)`]: {
      marginLeft: "-98px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  mobileAppBarBottomWrapper: {
    position: "fixed",
    top: "48px",
    width: '100%',
    transition: 'transform 0.3s ease-in-out',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: "white",
  },
  hideAppBarBottom: {
    transform: "translateY(-100%)",
  }
}));

const CustomAppBar = ({userName}) => {
  const classes = useStyles();
  const drawerWidth = useSelector(state => state.common.drawerWidth);
  const lastScrollTopRef = useRef(0);
  const appBarBottomRef = useRef(null);
  const scrollTimeoutRef = useRef(null);
  const isHidingRef = useRef(false);
  const isMobile = useMediaQuery('(max-width:640px)');
  const [isAppBarBottomVisible, setIsAppBarBottomVisible] = useState(true);

  useEffect(() => {
    if (!isMobile) return;
    const handleScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      const appBarBottomHeight = appBarBottomRef.current ? appBarBottomRef.current.offsetHeight : 0;
      const scrollUpBuffer = 10;
      
      if (st > lastScrollTopRef.current && st > appBarBottomHeight) {
        // 下スクロール
        if (!isHidingRef.current) {
          isHidingRef.current = true;
          setIsAppBarBottomVisible(false);
        }
      } else if (st + scrollUpBuffer < lastScrollTopRef.current) {
        // 上スクロール
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
        scrollTimeoutRef.current = setTimeout(() => {
          isHidingRef.current = false;
          setIsAppBarBottomVisible(true);
        }, 500);
      }
      lastScrollTopRef.current = st <= 0 ? 0 : st;
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [isMobile]);

  return (
    <div className={classes.root}>
      <AppBar color="default" className={classes.appBar} elevation={0}>
        <div className={classes.appBarTop}>
          <AppBarTop
            drawerWidth={drawerWidth}
            userName={userName}
            backgroundColor={HEADER_BACKGROUND_COLOR}
            textColor={HEADER_TEXT_COLOR}
          />
        </div>
        <div 
          className={isMobile ? classes.mobileAppBarBottomWrapper : classes.appBarBottomWrapper} 
          ref={appBarBottomRef}
          style={isMobile ? { transform: isAppBarBottomVisible ? 'translateY(0)' : 'translateY(-100%)' } : {}}
        >
          <AppBarBottom drawerWidth={drawerWidth} />
        </div>
      </AppBar>
    </div>
  );
};

export default connect(
  ({ global: { userData } }) => ({
    userName: userData.name,
  }),
)(CustomAppBar);