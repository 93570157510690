export const PAGINATION_PER_PAGE = 20;
export const API_MODEL_TYPE_FEEDBACK = 'feedback';
export const API_MODEL_TYPE_QUESTION = 'question';
export const API_MODEL_TYPE_QUESTION_OPTION = 'question_option';
export const API_MODEL_TYPE_ANSWER_OPTION = 'answer_option';

export const TYPE_SELECT = 'type_select';
export const TYPE_RADIO_BUTTON = 'type_radio_button';
export const TYPE_SELECT_BUTTON = 'type_select_button';
export const TYPE_CHECK_BOX = 'type_check_box';
export const TYPE_NPS_QUESTION = 'type_nps_question';
export const TYPE_NPS_REASON = 'type_nps_reason';
export const TYPE_TEXT_FIELD = 'type_text_field';
export const TYPE_TEXT_AREA = 'type_text_area';
export const TYPE_DATE = 'type_date';
export const TYPE_SELECT_USER = 'type_select_user';
export const TYPE_UPLOAD = 'type_upload';

export const TAB_INDEX_ANSWERS = 1;
export const TAB_INDEX_COMMENTS = 2;
