import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "date-fns";
import Select from 'react-select'
import { useParams, useHistory, Prompt } from "react-router-dom";
import {
  Button,
  Tabs,
  Tab,
  Paper,
  Grid,
  CircularProgress,
  makeStyles,
  Link,
  FormHelperText,
  Snackbar,
  Slide
} from "@material-ui/core";
import {
  Close as CloseIcon,
} from "@material-ui/icons";
import validate from 'validate.js';
import cloneDeep from 'clone-deep';
import TabPanel from "./TabPanel";
import Questions from "./form/questions/Questions";
import { thunkSaveSurvey, thunkFetchSurvey } from "./redux/thunk";
import CommonSettings from "./form/CommonSettings";
import StartPageSettings from "./form/StartPageSettings";
import EndPageSettings from "./form/EndPageSettings";
import { TextFieldWithError, FormField } from "./components";
import IconCustom from './components/IconCustom'
import QRCodeModal from "./QRCodeModal"
import { UPDATE_SURVEY_ITEM } from "./redux/actions";
import { questionConstraints, optionAttributeConstraints } from "./form/validations";
import { surveyDetailStyles } from "./styles";
import iconCodescan from "../../assets/icons/icon_codescan.svg";
import iconReturn from "../../assets/icons/icon_return.svg";
import ElementIcon from '../../common/icon/ElementIcon';
import clsx from 'clsx';
import { loadProjects } from "../../common/Header/HeaderForm/utils";
import NoOptionsMessage from "./components/NoOptionsMessage";


const styles = makeStyles(surveyDetailStyles);

const propTypes = {
  itemLoading: PropTypes.bool.isRequired,
  itemLoaded: PropTypes.bool.isRequired,
  itemError: PropTypes.object,
  item: PropTypes.object.isRequired,
  itemSaveError: PropTypes.object.isRequired,
  itemSubmitting: PropTypes.bool.isRequired,
  itemSubmitSuccess: PropTypes.bool.isRequired,
  itemSubmitted: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  fetchSurvey: PropTypes.func.isRequired,
  updateSurveyItem: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  user: PropTypes.object.isRequired,
};

const SurveyDetail = ({
  itemLoading,
  itemLoaded,
  itemError,
  item,
  itemSaveError,
  itemSubmitting,
  itemSubmitSuccess,
  itemSubmitted,
  save,
  fetchSurvey,
  updateSurveyItem,
  closeModal,
  drawerWidth,
  user,
}) => {
  
  const classes = styles(drawerWidth);
  const [doc, setDoc] = useState({});
  const [hiddenQRModal, setHiddenQRModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [start_page_main_image, setStartPageMainImage] = useState(null);
  const [end_page_main_image, setEndPageMainImage] = useState(null);
  const [showUrlString, setShowUrlString] = useState(true);
  const [errors, setErrors] = useState([]);
  const [showPreviewOnSubmit, setShowPreviewOnSubmit] = useState(false);
  const [feedbackPath, setFeedbackPath] = useState('');
  const [projects, setProjects] = useState(null)

  const { survey_id } = useParams();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [transition, setTransition] = useState(null);
  const [errorsFoundForm, setErrorsFoundForm] = useState(false);

  const isCreateAction = !!!survey_id;
  const isUpdateAction = !isCreateAction;
  const [height, setHeight] = useState(100)

  useEffect(() => {
    if (Object.keys(itemSaveError).length > 0) {
      setErrorsFoundForm(true);
    }
  }, [itemSaveError]);

  const onProjectSelectFocused = async () => {
    const element = document.getElementById("survey-project-select")
    const top = element.getBoundingClientRect().top
    setHeight(window.innerHeight - top - element.offsetHeight - 20)
    setProjects(await loadProjects() || [])
  }

  useEffect(() => {
    if (itemSubmitted && !isCreateAction) {
      handleNotification();
    }
    // eslint-disable-next-line
  }, [itemSubmitted]);
  
  useEffect(() => {
    if (!!survey_id) {
      fetchSurvey(survey_id);
    }
    
    return () => {
      updateSurveyItem();
    }
    // eslint-disable-next-line
  }, [survey_id]);

  useEffect(() => {
    setDoc({
      area: item.area,
      evaluation_scale: item.evaluation_scale,
      publish_start_at: item.publish_start_at,
      publish_end_at: item.publish_end_at,
      use_start_page_html: item.use_start_page_html,
      use_end_page_html: item.use_end_page_html,
      use_confirm_page: item.use_confirm_page,
      use_redirect: item.use_redirect,
      project: item.project,
      url_string: item.url_string,
      redirect_url: item.redirect_url,
      end_page_html: item.end_page_html,
      end_page_main_text: item.end_page_main_text,
      end_page_custom_css: item.end_page_custom_css,
    });

    setStartPageMainImage({
      data: item.start_page_main_image_url,
      filename: '',
      content_type: '',
    });

    setEndPageMainImage({
      data: item.end_page_main_image_url,
      filename: '',
      content_type: '',
    })

    // eslint-disable-next-line
  }, [item.name]);

  useEffect(() => {
    setFeedbackPath(`${window.location.protocol}//${item.domain || window.location.host}/s/${item.company_uid}/${doc.url_string}`);

    // eslint-disable-next-line
  }, [doc.url_string]);

  if (itemSubmitSuccess && showPreviewOnSubmit) {
    window.open(`${window.location.protocol}//${item.domain || window.location.host}/s/preview/${item.company_uid}/${item.url_string}`, "_blank");
    setShowPreviewOnSubmit(false);
  }

  const generateDoc = (e) => {
    return {
      id: item.id,
      name: e.target.name.value || item.name,
      page_title: !!e.target.page_title ? e.target.page_title.value : '',
      use_confirm_page: !!e.target.use_confirm_page ? e.target.use_confirm_page.checked : false,
      common_header: !!e.target.common_header ? e.target.common_header.value : '',
      common_footer: !!e.target.common_footer ? e.target.common_footer.value : '',
      common_css: !!e.target.common_css ? e.target.common_css.value : '',
      start_page_html: !!e.target.start_page_html ? e.target.start_page_html.value : null,
      start_page_main_text: !!e.target.start_page_main_text ? e.target.start_page_main_text.value : null,
      start_page_custom_css: !!e.target.start_page_custom_css ? e.target.start_page_custom_css.value : '',
      start_page_button_name: !!e.target.start_page_button_name ? e.target.start_page_button_name.value : '',
      use_end_page_html: !!e.target.use_end_page_html ? e.target.use_end_page_html.checked : false,
      use_redirect: !!e.target.use_redirect ? e.target.use_redirect.checked : false,
      is_satisfaction: (e.target.is_satisfaction || {}).checked,
      is_correlation: (e.target.is_correlation || {}).checked,
      satisfaction_level: (e.target.satisfaction_level || {}).value,
      correlation_coefficient: (e.target.correlation_coefficient || {}).value,
      shop_required: (e.target.shop_required || {}).checked,
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fields = generateDoc(e);
    setErrorsFoundForm(false);

    const nestedErrors = [];
    let errorsFound = false;

    for (let i = 0; i < item.survey_pages_attributes.length; i ++) {
      const surveyPage = item.survey_pages_attributes[i];
      nestedErrors[i] = nestedErrors[i] || [];

      for (let j = 0; j < surveyPage.question_items.length; j ++) {

        const questionItem = cloneDeep(surveyPage.question_items[j]);

        if (!!!questionItem.id && questionItem._destroy === 1) continue;

        if (!!questionItem.question_options_attributes) {
          questionItem.question_options_attributes = questionItem.question_options_attributes.filter(i => i._destroy !== 1);
        }
      
        const questionConstraintErrors = validate(questionItem, questionConstraints) || {};
        nestedErrors[i][j] = nestedErrors[i][j] || {errors: {}, question_option_attributes: []};

        if (Object.keys(questionConstraintErrors).length > 0) {
          nestedErrors[i][j].errors = questionConstraintErrors;
          setErrorsFoundForm(true);
          errorsFound = true;
        }

        if (!!!questionItem.question_options_attributes) continue;

        for (let k = 0; k < questionItem.question_options_attributes.length; k ++) {
          const optionAttributeItem = questionItem.question_options_attributes[k];
          if (optionAttributeItem._destroy !== 1) {
            const optionAttributeConstraintErrors = validate({...optionAttributeItem, body: questionItem.body, abbreviation: questionItem.abbreviation, question_type: questionItem.question_type}, optionAttributeConstraints) || {};

            if (Object.keys(optionAttributeConstraintErrors).length > 0) {
              nestedErrors[i][j].question_option_attributes[k] = optionAttributeConstraintErrors;
              setErrorsFoundForm(true);
              errorsFound = true;
            }
          }
        }
      }
    }
    setErrors(nestedErrors);
    console.log("SurveyDetail errors: ", nestedErrors);
    if (errorsFound) {
      handleNotification();
      return;
    }

    const submitFields  = {
      ...doc,
      ...fields,
      end_page_button_name: '',
      survey_pages_attributes: item.survey_pages_attributes.map((surveyPage, index) => ({
        id: surveyPage.id,
        page_number: index + 1,
        _destroy: surveyPage._destroy,
        questions_attributes: (surveyPage.question_items || [])
          .filter(item => (!!item.id || item._destroy !== 1) && item.question_type !== 'survey_heading')
          .map(pageItem => ({
            id: pageItem.id,
            survey_page_id: surveyPage.id,
            before_survey_page_id: pageItem.before_survey_page_id,
            display_order: pageItem.display_order,
            body: pageItem.body,
            is_required: pageItem.is_required,
            is_privacy: pageItem.is_privacy,
            is_correlation_target: pageItem.is_correlation_target,
            abbreviation: pageItem.abbreviation,
            question_type: pageItem.question_type,
            free_entry_validation: pageItem.free_entry_validation,
            min_selection_count: pageItem.min_selection_count,
            max_selection_count: pageItem.max_selection_count,
            max_character_count: pageItem.max_character_count,
            min_character_count: pageItem.min_character_count,
            line_number: pageItem.line_number,
            option_align: pageItem.option_align,
            use_number: pageItem.use_number,
            discarded_at: pageItem.discarded_at,
            question_image: pageItem.question_image,
            question_image_destroy: pageItem.id && pageItem.question_image === null ? 1 : undefined,
            _destroy: pageItem._destroy,
            question_options_attributes: (pageItem.question_options_attributes || []).map((item, index) => ({
              ...item,
              display_order: index + 1,
              option_image_destroy: item.id && item.option_image === null ? 1 : undefined,
            })
          ),
        })),
        survey_headings_attributes: (surveyPage.question_items || [])
          .filter(item => (!!item.id || item._destroy !== 1) && item.question_type === 'survey_heading')
          .map(pageItem => ({
            id: pageItem.id,
            survey_page_id: surveyPage.id,
            before_survey_page_id: pageItem.before_survey_page_id,
            display_order: pageItem.display_order,
            body: pageItem.body,
            discarded_at: pageItem.discarded_at,
            _destroy: pageItem._destroy,
          })
        ),
        survey_page_display_conditions_attributes: (surveyPage.survey_page_display_conditions_attributes || []).map(item => ({
          id: item.id,
          survey_page_id: item.survey_page_id,
          question_id: item.question_id,
          question_option_id: item.question_option_id,
          area_id: item.area_id,
          shop_id: item.shop_id,
          _destroy: item._destroy,
        })),
      })),
    };

    if (start_page_main_image === null) { // image was deleted
      submitFields.start_page_main_image = null;
      submitFields.start_page_main_image_destroy = 1;
    } else if (!!start_page_main_image.filename) { // image was updated
      submitFields.start_page_main_image = start_page_main_image;
    } // image was not touched

    if (end_page_main_image === null) { // image was deleted
      submitFields.end_page_main_image = null;
      submitFields.end_page_main_image_destroy = 1;
    } else if (!!end_page_main_image.filename) { // image was updated
      submitFields.end_page_main_image = end_page_main_image;
    } // image was not touched

    if (e.nativeEvent.detail) {
      const { inputValue, withPreview } = e.nativeEvent.detail;

      submitFields.name = inputValue;
      setShowPreviewOnSubmit(withPreview);
    }
    delete submitFields.area
    delete submitFields.project
    save(submitFields);
  }

  const customSelectTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#EC681A'
    }
  })

  const customSelectStyles = {
    control: base => ({
      ...base,
      width: '100%',
      height: '42px',
      marginBottom: 15,
      '&:hover': {
        boxShadow: '0 0 0 1px #1A0707',
        borderWidth: 0
      }
    }),

    placeholder: provided => ({
      ...provided,
      font: "500 12px/18px 'Noto Sans JP', sans-serif !important",
      color: '#9e9696'
    }),

    singleValue: provided => ({
      ...provided,
      font: "500 12px/18px 'Noto Sans JP', sans-serif !important",
      fontWeight: 'normal'
    }),

    menuList: provided => ({
      ...provided,
      borderTop: '1px solid #ec681a',
      height,
      fontWeight: 'normal'
    }),

    option: provided => ({
      ...provided,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    })
  }

  const onChangeTab = (_, activeTab) => {
    setActiveTab(activeTab);
  };

  const handleSaveUrl = (e) => {
    e.preventDefault();
    setShowUrlString(false);
  }

  const handleCloseUrl = (e) => {
    e.preventDefault();
    setShowUrlString(true);
  }

  const showQRModal = () => {
    setHiddenQRModal(true)
  }

  const closeQRModal = () => {
    setHiddenQRModal(false)
  }

  const onCloseModal = () => {
    setProjects(null)
    closeModal && closeModal()
  }

  const onDeleteLocalFile = (stateSetter) => stateSetter(null);

  const onLoadLocalFile = (stateSetter, event) => {
    event.preventDefault();

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        stateSetter({
          "filename": file.name,
          "data": reader.result,
          "content_type": file.type
        });
      };

      reader.onerror = function (error) {
        console.error('Error: ', error);
      };
    } else {
      console.error('File uploaded is not valid.');
    }
  }
  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className: activeTab === index ? classes.tabActived : classes.tab
    };
  }

  const renderTabLinks = () => (
    <Tabs
      value={activeTab}
      onChange={onChangeTab}
      aria-label="simple tabs"
      className={classes.anketTabDetail}
      classes={{ indicator: classes.indicator }}
    >
      <Tab label="共通設定" {...a11yProps(0)} />
      <Tab label="開始" {...a11yProps(1)} />
      <Tab label="回答" {...a11yProps(2)} />
      <Tab label="終了" {...a11yProps(4)} />
    </Tabs>
  );

  const renderTabContents = () => (
    <>
      <CommonSettings
        id={+item.id}
        activeTab={activeTab}
        name={item.name}
        project={doc.project}
        setProject={project => setDoc({...doc, project, project_id: project.value})}
        page_title={item.page_title}
        evaluation_scale={doc.evaluation_scale}
        setEvaluationScale={evaluation_scale => setDoc({...doc, evaluation_scale})}
        area={doc.area}
        setArea={area => setDoc({...doc, area, area_id: area.value})}
        publish_start_at={doc.publish_start_at}
        setPublishStartAt={publish_start_at => setDoc({...doc, publish_start_at}) }
        publish_end_at={doc.publish_end_at}
        setPublishEndAt={publish_end_at => setDoc({...doc, publish_end_at})}
        use_confirm_page={item.use_confirm_page}
        common_header={item.common_header}
        common_footer={item.common_footer}
        common_css={item.common_css}
        isCreateAction={isCreateAction}
        errors={itemSaveError}
        userAuthority={user.authority}
        is_satisfaction={item.is_satisfaction}
        satisfaction_level={item.satisfaction_level}
        is_correlation={item.is_correlation}
        correlation_coefficient={item.correlation_coefficient}
        shop_required={item.shop_required}
      />
      <StartPageSettings
        activeTab={activeTab}
        use_start_page_html={!!doc.use_start_page_html}
        setUseStartPageHtml={use_start_page_html => setDoc({...doc, use_start_page_html}) }
        start_page_main_image={start_page_main_image}
        onLoadStartPageMainImage={(event) => onLoadLocalFile(setStartPageMainImage, event)}
        onDeleteStartPageMainImage={() => onDeleteLocalFile(setStartPageMainImage)}
        start_page_main_text={item.start_page_main_text}
        start_page_html={item.start_page_html}
        start_page_custom_css={item.start_page_custom_css}
        start_page_button_name={item.start_page_button_name}
        errors={itemSaveError}
      />
      <TabPanel value={activeTab} index={2}>
        <Questions errors={errors} area={doc.area}/>
      </TabPanel>
      <EndPageSettings
        activeTab={activeTab}
        use_end_page_html={!!doc.use_end_page_html}
        setUseEndPageHtml={use_end_page_html => setDoc({...doc, use_end_page_html})}
        use_confirm_page={!!doc.use_confirm_page}
        setUseConfirmPage={use_confirm_page => setDoc({...doc, use_confirm_page})}
        use_redirect={!!doc.use_redirect}
        setUseRedirect={use_redirect => setDoc({...doc, use_redirect})}
        redirect_url={doc.redirect_url}
        setRedirectUrl={redirect_url => setDoc({...doc, redirect_url })}
        end_page_main_image={end_page_main_image}
        onLoadEndPageMainImage={event => onLoadLocalFile(setEndPageMainImage, event)}
        onDeleteEndPageMainImage={() => onDeleteLocalFile(setEndPageMainImage)}
        end_page_main_text={doc.end_page_main_text}
        setEndPageMainText={end_page_main_text => setDoc({...doc, end_page_main_text})}
        end_page_html={doc.end_page_html}
        setEndPageHtml={end_page_html => setDoc({...doc, end_page_html})}
        end_page_custom_css={doc.end_page_custom_css}
        setEndPageCustomCss={end_page_custom_css => setDoc({...doc, end_page_custom_css})}
        errors={itemSaveError}
      />
    </>
  );

  const renderUpdateForm = () => (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormField label={""} component="fieldset" fullWidth>
            <Paper className={classes.tabHeader}>
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Grid>
                  <div className={classes.left}>
                    <ElementIcon name='link' className={classes.leftIcon} />
                  </div>
                  {
                    showUrlString ?
                      <>
                        <Link
                          className={classes.linkButton}
                          variant="body2"
                          href={feedbackPath}
                          target="_blank"
                          rel="noopener"
                        >
                          {feedbackPath}
                        </Link>
                        <Button
                          variant="outlined"
                          startIcon={<ElementIcon name='edit-outline' className={classes.icons} />}
                          className={classes.button}
                          onClick={handleSaveUrl}
                        >
                          編集
                        </Button>
                      </> :
                      <>
                        <FormField label={""} component="fieldset" className={classes.formField}>
                          <TextFieldWithError
                            name={"url_string"}
                            fieldLabel={"カスタムURL"}
                            value={doc.url_string}
                            onChange={e => setDoc({ ...doc, url_string: e.target.value })}
                            errors={itemSaveError.url_string}
                            placeholder={'カスタムURLを入力してください。'}
                            type={"url"}
                          />
                        </FormField>
                        <Button
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          onClick={handleCloseUrl}
                          className={classes.button}
                        >
                          閉じる
                        </Button>
                      </>
                  }
                  <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={showQRModal}
                    startIcon={<IconCustom className={classes.largeIcon} src={iconCodescan} />}
                  >
                    URL リスト
                    </Button>
                </Grid>

                <Button
                  variant="outlined"
                  onClick={() => history.push({ pathname: `/admin/surveys` })}
                  className={classes.button}
                  startIcon={<IconCustom className={classes.largeIcon} src={iconReturn} />}
                >
                  アンケート一覧に戻る
                </Button>
              </Grid>
            </Paper>

          </FormField>
        </Grid>
      </Grid>

      {renderTabLinks()}
      {renderTabContents()}

      <Prompt
        when={true}
        message={() =>
          "アンケート作成画面から移動してよろしいでしょうか？保存していない場合、データが失われる可能性がありますのでご注意ください。"
        }
      />
    </>
  );

  const TransitionRight = props => {
    return <Slide {...props} direction="left" />;
  }
  const handleNotification = () => {
    setTransition(() => TransitionRight);
    setVisible(true);
  }

  const closeSnackbar = () => setVisible(false);
  const contentSnackbar = (
    <div className={classes.snackbarContainer}>
      {!errorsFoundForm ?
        <span className={classes.checkIcon}><i className="el-icon-check"></i></span>
      :
        <span className={classes.errorIcon}><i className="el-icon-close"></i></span>
      }
      <div className={classes.snackbarRightContent}>
        {!errorsFoundForm ?
          <p className={classes.labelTitle}>保存しました。</p>
        :
          <p className={classes.labelTitle}>保存に失敗しました<br /></p>
        }
        {errorsFoundForm && Object.keys(itemSaveError).map(itemKey => itemSaveError[itemKey].filter(validationMessage => !!validationMessage.indexOf("は") && !!validationMessage.indexOf("を")).map(validationMessage =>
          <p className={classes.labelTitle}>{validationMessage}</p>
        ))}
      </div>
      <span className={classes.closeIcon} onClick={closeSnackbar}><i className=" el-icon-close"></i></span>
    </div>
  );

  return (
    <Grid item className={classes.root}>
      <form onSubmit={handleSubmit} name={"survey-form"}>
        {isCreateAction && (
          <Paper className={classes.topContent}>
            <div>新しいアンケートを作成</div>
            <hr />
            <div>
              <Grid container>
                <FormField
                  fullWidth
                  className={classes.formSection}
                  label={''}
                  error={!!itemSaveError.name}
                >
                  <label className={classes.formLabel}>アンケート名</label>
                  <TextFieldWithError
                    name={"name"}
                    fieldLabel={"アンケート名"}
                    variant={"outlined"}
                    errors={itemSaveError.name}
                    placeholder={'アンケート名を入力してください。'}
                    InputProps={{className: classes.formInputBase}}
                  />
                  <ElementIcon name='edit-outline' className={classes.iconInput}/>
                </FormField>

                <FormField
                  fullWidth
                  className={classes.formSection}
                  error={!!itemSaveError.project}
                >
                  <label className={classes.formLabel}>プロジェクト選択</label>
                  <Select
                    name="project_id"
                    id={"survey-project-select"}
                    placeholder='プロジェクトを選択'
                    options={projects || []}
                    defaultValue={''}
                    onChange={({value}) => setDoc({...doc, project_id: value})}
                    styles={customSelectStyles}
                    theme={customSelectTheme}
                    onFocus={onProjectSelectFocused}
                    components={{ NoOptionsMessage: () => <NoOptionsMessage loading={projects===null}/>}}
                  />
                  {!!itemSaveError.project && (
                    <FormHelperText>{`プロジェクト${itemSaveError.project[0]}`}</FormHelperText>
                  )}
                </FormField>
              </Grid>
            </div>

            <div className={classes.buttonGroup}>
              <Button
                type="submit"
                className={classes.buttonFill}
              >
                作成
              </Button>
              <Button
                onClick={onCloseModal}
                className={classes.buttonOutline}
              >
                キャンセル
              </Button>
            </div>

            {itemSubmitSuccess &&
              <Grid item xs={12}>
                <span style={{color: 'green'}}>保存しました</span>
              </Grid>
            }
          </Paper>
        )}

        {hiddenQRModal &&
          <QRCodeModal feedbackPath={feedbackPath} survey={item} showQRModal={hiddenQRModal} closeQRModal={closeQRModal}/>
        }

        {(itemSubmitting || itemLoading) &&
          <Paper className={classes.loadingRoot}>
            <div className={classes.loading}>
              <CircularProgress disableShrink />
            </div>
          </Paper>
        }

        {(isUpdateAction && !itemSubmitting && itemLoaded) && renderUpdateForm()}
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        key="bottom_right"
        open={visible}
        ContentProps={{
          classes: {
            root: itemSubmitSuccess ? classes.snackbarRoot : clsx(classes.snackbarRoot, classes.snackbarRootError)
          }
        }}
        classes={{ anchorOriginBottomRight: classes.snackbarAnchor }}
        autoHideDuration={3000}
        TransitionComponent={transition}
        onClose={closeSnackbar}
        message={contentSnackbar}
      />
    </Grid>
  );
}

SurveyDetail.propTypes = propTypes;

export default connect(
  ({
    surveys: {
      itemLoading,
      itemLoaded,
      itemError,
      item,
      itemSaveError,
      itemSubmitting,
      itemSubmitSuccess,
      itemSubmitted,
    },
    common: { drawerWidth },
    global: { userData },
  }) => ({
    itemLoading,
    itemLoaded,
    itemError,
    item,
    itemSaveError,
    itemSubmitting,
    itemSubmitSuccess,
    itemSubmitted,
    drawerWidth,
    user: userData,
  }),
  dispatch => ({
    save: (data, pages) => dispatch(thunkSaveSurvey(data, pages)),
    fetchSurvey: (surveyId) => dispatch(thunkFetchSurvey(surveyId)),
    updateSurveyItem: () => dispatch({ type: UPDATE_SURVEY_ITEM, payload: { item: {} } }),
  }),
)(SurveyDetail);
