export const FETCH_AREAS_BEGIN = 'FETCH_AREAS_BEGIN';
export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS';
export const FETCH_AREAS_FAILURE = 'FETCH_AREAS_FAILURE';
export const CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS';
export const UPDATE_AREA_BEGIN = 'UPDATE_AREA_BEGIN';
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS';
export const UPDATE_AREA_FAILURE = 'UPDATE_AREA_FAILURE';
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS';
export const OPEN_AREA_FORM_MODAL = 'OPEN_AREA_FORM_MODAL';
export const CLOSE_AREA_FORM_MODAL = 'CLOSE_AREA_FORM_MODAL';
export const CHANGE_AREA_ORDER = 'CHANGE_AREA_ORDER'

export const FETCH_AREAS_RESET = "FETCH_AREAS_RESET";


export const actionFetchAreasBegin = () => ({
  type: FETCH_AREAS_BEGIN,
})

export const actionFetchAreasSuccess = (items) => ({
  type: FETCH_AREAS_SUCCESS,
  payload: {
    items,
  }
});

export const actionFetchAreasFailure = error => ({
  type: FETCH_AREAS_FAILURE,
  payload: { error }
})

export const actionCreateArea = ({ parentId, data, included, parentName }) => {
  return {
    type: CREATE_AREA_SUCCESS,
    payload: {
      item: { parentId, data, included, parentName },
    }
  }
}

export const actionUpdateArea = ({ parentId, data, included }) => {
  return {
    type: UPDATE_AREA_SUCCESS,
    payload: {
      item: { parentId, data, included },
    }
  }
}

export const actionUpdateAreaFailure = error => ({
  type: UPDATE_AREA_FAILURE,
  payload: { error}
})

export const actionDeleteArea = (id) => {
  return {
    type: DELETE_AREA_SUCCESS,
    payload: {
      deleteAreaId: id,
    }
  }
}

export const actionCloseAreaFormModal = () => {
  return {
    type: CLOSE_AREA_FORM_MODAL,
    payload: {
      modalOpened: false,
    }
  }
}

export const actionChangeOrder = (items) => {
  return {
    type: CHANGE_AREA_ORDER,
    payload: {
      items,
    }
  }
}
