export const QuestionTypeNPSStyle = theme => ({
  textAnswer: {
    background: "#F7F7F7",
    padding: "40px 0 10px 0",
    fontWeight: 'bold',
    fontFamily: "'Noto Sans JP', sans-serif",
    whiteSpace: 'pre-line',
    "& .required": {
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing: "0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }
  },
  tabWithNumber: {
    padding :'20px 20px',
    backgroundColor:'#EFEFEF',
    marginBottom: '15px'

  },
  flexContainer: {
    background: "white",
    display: "flex",
  },
  indicator: {
    backgroundColor: "transparent",
    color: "transparent",
  },
  btnIcon: {
    background: "#FFFFFF",
    color: "#646464",
    borderRadius: "0px",
    border: "solid 1px #EFEFEF",
    height: '50px !important',
    width: '100%',
    minWidth: '0',

    "&:hover": {
      background: '#FFFFFF'
    },

    "& span": {
      fontFamily: "'Noto Sans JP', sans-serif",
      textAlign: "center",
      display: "block",
      wordBreak: "keep-all",
      width: "max-content"
    }
  },
  btnIconColor: {
    background: "#EC681A",
    color: "#FFFFFF",
    borderRadius: "0px",
    border: "solid 1px #EFEFEF",
    height: '50px !important',
    width: '100%',
    minWidth: 'unset !important',

    "&:hover": {
      background: '#EC681A'
    },

    "& span": {
      fontFamily: "'Noto Sans JP', sans-serif",
      textAlign: "center",
      display: "block",
      wordBreak: "keep-all",
      width: "max-content"
    },
  },
  noDisplay: {
    display: "none",
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
  appBar: {
    background: "white"
  }
});
export const QuestionTypeRadioStyles = theme => ({
  textAnswer: {
    background: "#F7F7F7",
    padding: "40px 0 10px 0",
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
    fontFamily: "'Noto Sans JP', sans-serif",
    "& .required":{
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing:"0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }
  },
  bgRadio: {
    backgroundColor: '#F7F7F7',
    paddingTop: '20px',
    paddingBottom: '20px',
    "& >p": {
      fontFamily: "'Noto Sans JP', sans-serif"
    }
  },
  btn: {
    fontFamily: "'Noto Sans JP', sans-serif",
    borderRadius: "5px",
    margin: "0px 0 5px 0",
    width: "100%",
    background: "#FFFFFF !important",
    color: "#646464",
    fontSize: "13px",
    border: "2px solid #EFEFEF",
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    },
    "& .MuiButton-label": {
      textAlign: "justify",
      justifyContent: "unset",
    },
  },
  btnColor: {
    fontFamily: "'Noto Sans JP', sans-serif",
    borderRadius: "5px",
    margin: "0px 0 5px 0",
    width: "100%",
    background: "#FFFFFF !important",
    color: "#646464",
    fontSize: "13px",
    border: "2px solid #EC681A",
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    },
    "& .MuiButton-label": {
      textAlign: "justify",
      justifyContent: "unset",
    },
  },
  iconRadio: {
    border: "1.2px solid #646464",
    color: "#FFFFFF",
    marginRight: "10px",
    borderRadius: '50%',
  },
  activeRadio: {
    color: "#EC681A",
    border: "1.2px solid #646464",
    marginRight: "10px",
    borderRadius: '50%',
  },
  image: {
    padding: "0px 20px 0px 20px",
    background: "#F7F7F7",
    marginBottom: "5px",
    textAlign: "center"
  },
  imgView: {
    width: '70%',
    height: '70%',
    objectFit: 'cover',
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
});
export const QuestionTypeSelectCheckboxStyles = theme => ({
  textAnswer: {
    background: "#F7F7F7",
    padding: "40px 0 10px 0",
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
    fontFamily: "'Noto Sans JP', sans-serif",
    "& .required": {
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing: "0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }
  },
  note: {
    background: "#F7F7F7",
    padding: "20px 10px 10px 20px",
    color: "#AF221C",
    fontSize: "12px",
  },
  contentSelect: {
    backgroundColor: '#F7F7F7',
    padding: '10px 0px',
    width: '100%',
  },
  btn: {
    fontFamily: "'Noto Sans JP', sans-serif",
    borderRadius: "5px",
    margin: "0px 0 5px 0",
    width: "100%",
    background: "#FFFFFF !important",
    color: "#646464",
    fontSize: "13px",
    border: "2px solid #EFEFEF",
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    },
    "& .MuiButton-label": {
      textAlign: "justify",
      justifyContent: "unset",
    },
  },
  btnColor: {
    fontFamily: "'Noto Sans JP', sans-serif",
    borderRadius: "5px",
    margin: "0px 0 5px 0",
    width: "100%",
    background: "#FFFFFF !important",
    color: "#646464",
    fontSize: "13px",
    border: "2px solid #EC681A",
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    },
    "& .MuiButton-label": {
      textAlign: "justify",
      justifyContent: "unset",
    },
  },
  active: {
    color: "#EC681A",
    border: "1.2px solid #646464",
    marginRight: "10px",
  },
  icon: {
    border: "1.2px solid #646464",
    color: "#FFFFFF",
    marginRight: "10px",
  },
  image: {
    padding: "0px 20px 0px 20px",
    background: "#F7F7F7",
    marginBottom: "5px",
    textAlign: "center"
  },
  imgView: {
    width: '70%',
    height: '70%',
    objectFit: 'cover',
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
})
export const QuestionTypeSelectButtonStyles = theme => ({
  textAnswer: {
    background: "#F7F7F7",
    padding: "40px 0 10px 0",
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
    fontFamily: "'Noto Sans JP', sans-serif",
    "& .required": {
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing: "0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }
  },
  tabWithNumber: {
    marginBottom: '15px',
    padding: "20px 20px",
    backgroundColor:'#EFEFEF',
    textAlign: 'center'
  },
  flexContainer: {
    background: "white",
    display: "flex",
  },
  indicator: {
    backgroundColor: "transparent",
    color: "transparent",
  },
  btnIcon: {
    background: "#FFFFFF",
    color: "#646464",
    borderRadius: "0px",
    border: "solid 1px #EFEFEF",
    height: '50px !important',
    width: '100%',
    minWidth: 'unset !important',

    "&:hover": {
      background: '#FFFFFF'
    },

    "& span": {
      textAlign: "center",
      display: "block",
      wordBreak: "keep-all",
      width: "max-content",
      fontFamily: "'Noto Sans JP', sans-serif"
    }
  },
  btnIconColor: {
    background: "#EC681A",
    color: "#FFFFFF",
    borderRadius: "0px",
    border: "solid 1px #EFEFEF",
    height: '50px !important',
    width: '100%',
    minWidth: 'unset !important',

    "&:hover": {
      background: '#EC681A'
    },

    "& span": {
      textAlign: "center",
      display: "block",
      wordBreak: "keep-all",
      width: "max-content",
      fontFamily: "'Noto Sans JP', sans-serif"


    },
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
})
export const QuestionTypeTextStyles = theme => ({
  textAnswer: {
    background: "#F7F7F7",
    padding: "40px 0 10px 0",
    fontWeight: 'bold',
    fontFamily: "'Noto Sans JP', sans-serif",
    whiteSpace: 'pre-line',
    "& .required": {
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing: "0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }
  },
  text: {
    font: "600 12px/21px 'Noto Sans JP', sans-serif",
    color: "#1A0707",
    letterSpacing: "0.3px",
    whiteSpace: 'pre-line'
  },
  textArea: {
    width: "100%",
    border: "solid 1px #b3b3b3",
    boxSizing: "border-box",
    padding: "14px 15px",
    resize: "none",
    marginTop: 10,
    fontFamily: "'Noto Sans JP', sans-serif",
    font: "400 12px/21px 'Noto Sans JP', sans-serif",
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
})
export const QuestionTypeSelectUserStyles = theme => ({
  selectAnswer: {
    margin: 'auto',
    paddingTop: '40px',
    background: "#F7F7F7",
  },
  textAnswer1: {
    fontFamily: "'Noto Sans JP', sans-serif",
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 'bold',
    margin: "auto",
    whiteSpace: 'pre-line',
    "& .required": {
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing: "0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }
  },
  ul:{
    "& >div":{
      font: "300 10px/16px 'Noto Sans JP', sans-serif",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      borderBottom: "0.6000000238418579px solid #C2C2C2",
      padding: "14px 4px",
      "&:hover": {
        font: "300 10px/16px 'Noto Sans JP', sans-serif",
        color: "#1A0707 !important",
        background: "#ec681a3b 0% 0% no-repeat padding-box",
        cursor: "pointer"
      }
    },
  },
  slAnswer: {
    background: "#F7F7F7",
    padding: "30px 0 20px",
    [theme.breakpoints.down('sm')]: {
      margin: 'auto'
    },
	"& > p": {
      fontFamily: "'Noto Sans JP', sans-serif",
    },
    "& .basic-single":{
      "& >div":{
        "& >.select__indicators":{
          "& >span":{
            background: "#1A0707"
          },
          "& >div":{
            "& .el-icon-caret-bottom":{
              color: "#1A0707"
            }
          }
        },
        "& >.select__menu-list":{
          borderTop: "2px solid #EC681A"
        }
      }
    }
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
  selectRoot: {
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    border: "1px solid #B7B7B7",
    cursor: "pointer",
    margin: "auto",
    "&:hover": {
      border: "1px solid #EC681A",
    }
  },
  selectActived: {
    border: "1px solid #EC681A",
  },
  input: {
    marginLeft: 10,
    flex: 1,
    font: "300 13px/16px 'Noto Sans JP', sans-serif",
    color: "#1A0707",
    letterSpacing: "0.25px",
    cursor: "pointer",
    "& .MuiInputBase-input": {
      cursor: "pointer"
    }
  },
  iconButton: {
    padding: 0,
    "& i": {
      color: "#1A0707",
      fontSize: 13,
      margin: 6
    }
  },
  divider: {
    height: 20,
    margin: "4px 0",
    background: "#1A0707"
  },
})
export const DialogSelectStyles = theme => ({
  dialogRoot: {
    borderRadius: 0,
    width: 295,
    maxHeight: 500
  },
  dialogTitle: {
    padding: "20px 10px 7px 10px"
  },
  dialogContent: {
    padding: "0 15px 40px 15px"
  },
  titleDialog: {
    font: "600 13px/21px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.25px",
    color: "#EC681A",
    borderBottom: "solid 1px #EC681A",
    margin: 0,
    padding: "10px 15px",
  },
  optionItem: {
    font: "400 13px/16px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.25px",
    color: "#1A0707",
    borderBottom: "0.6000000238418579px solid #C2C2C2",
    margin: 0,
    padding: "11px 2px",
    cursor: 'pointer',
    '&:hover': {
      background: "#F2EEEC 0% 0% no-repeat padding-box"
    }
  },
  listRoot: {
    padding: 0
  },
  itemText: {
    "& span": {
      font: "400 13px/16px 'Noto Sans JP', sans-serif",
      letterSpacing: "0.25px",
      color: "#1A0707",
    }
  },
  selectedText: {
    "& span": {
      color: "#EC681A",
    }
  },
  activedItem: {
    color: "#EC681A"
  }
})
export const QuestionTypeSelectStyles = theme => ({
  selectAnswer: {
    margin: 'auto',
    paddingTop: '40px',
    background: "#F7F7F7",
    "& >*": {
      marginLeft: '20px',
      marginRight: '20px',
    }
  },
  textAnswer1: {
    fontFamily: "'Noto Sans JP', sans-serif",
    marginBottom: 10,
    fontWeight: 'bold',
    margin: "auto",
    whiteSpace: 'pre-line',
    "& .required": {
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing: "0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }
  },
  ul:{
    "& >div":{
      font: "300 10px/16px 'Noto Sans JP', sans-serif",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      borderBottom: "0.6000000238418579px solid #C2C2C2",
      padding: "14px 4px",
      "&:hover": {
        font: "300 10px/16px 'Noto Sans JP', sans-serif",
        color: "#1A0707 !important",
        background: "#ec681a3b 0% 0% no-repeat padding-box",
        cursor: "pointer"
      }
    },
  },
  slAnswer: {
    background: "#F7F7F7",
    padding: "30px 0 20px",
    [theme.breakpoints.down('sm')]: {
      margin: 'auto'
    },
    "& > p": {
      fontFamily: "'Noto Sans JP', sans-serif",
    },
    "& .basic-single":{
      "& >div":{
        "& >.select__indicators":{
          "& >span":{
            background: "#1A0707"
          },
          "& >div":{
            "& .el-icon-caret-bottom":{
              color: "#1A0707"
            }
          }
        },
        "& >.select__menu-list":{
          borderTop: "2px solid #EC681A"
        }
      }
    }
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
  selectRoot: {
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    border: "1px solid #B7B7B7",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #EC681A",
    }
  },
  selectActived: {
    border: "1px solid #EC681A",
  },
  input: {
    marginLeft: 10,
    flex: 1,
    font: "300 13px/16px 'Noto Sans JP', sans-serif",
    color: "#1A0707",
    letterSpacing: "0.25px",
    cursor: "pointer",
    "& .MuiInputBase-input": {
      cursor: "pointer"
    }
  },
  iconButton: {
    padding: 0,
    "& i": {
      color: "#1A0707",
      fontSize: 13,
      margin: 6
    }
  },
  divider: {
    height: 20,
    margin: "4px 0",
    background: "#1A0707"
  },
})

export const QuestionTypeTextInputStyles = theme => ({
  text: {
    fontFamily: "'Noto Sans JP', sans-serif",
    font: "600 16px/21px 'Noto Sans JP', sans-serif",
    width: "100%",
    boxSizing: "border-box",
    padding: "40px 0 10px 0",
    background: "#F7F7F7",
    margin: 0,
    whiteSpace: 'pre-line',
    "& .required": {
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing: "0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }
  },
  textInput: {
    font: "400 12px/21px 'Noto Sans JP', sans-serif",
    fontFamily: "'Noto Sans JP', sans-serif",
    width: "100%",
    border: "solid 1px #b3b3b3",
    boxSizing: "border-box",
    padding: "14px 15px",
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
})
