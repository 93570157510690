export const FETCH_COMPANIES_BEGIN = "FETCH_COMPANIES_BEGIN";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";
export const FETCH_ALL_COMPANIES_BEGIN = "FETCH_ALL_COMPANIES_BEGIN";
export const FETCH_ALL_COMPANIES_SUCCESS = "FETCH_ALL_COMPANIES_SUCCESS";
export const FETCH_ALL_COMPANIES_FAILURE = "FETCH_ALL_COMPANIES_FAILURE";
export const OPEN_COMPANY_FORM_MODAL = "OPEN_COMPANY_FORM_MODAL";
export const CLOSE_COMPANY_FORM_MODAL = "CLOSE_COMPANY_FORM_MODAL";
export const SAVE_COMPANY_BEGIN = "SAVE_COMPANY_BEGIN";
export const SAVE_COMPANY_SUCCESS = "SAVE_COMPANY_SUCCESS";
export const SAVE_COMPANY_FAILURE = "SAVE_COMPANY_FAILURE";
export const CHANGE_COMPANY_ITEM_STATE = "CHANGE_COMPANY_ITEM_STATE";
export const UPDATE_COMPANY_ITEM = "UPDATE_COMPANY_ITEM";

export const FETCH_COMPANIES_RESET = "FETCH_COMPANIES_RESET";

export const saveCompanyFailure = errors => ({
  type: SAVE_COMPANY_FAILURE,
  payload: { errors }
})
