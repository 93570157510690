import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { exportGraphAsPng } from '../../utils'
import { ExportButton } from 'common/csv/CsvButton'
import AnalysisNpsScore from './AnalysisNpsScore'
import AnalysisTimeSeries from './AnalysisTimeSeries'
import AnalysisCorrelations from './AnalysisCorrelations'
import AnalysisPeriods from './AnalysisPeriods'
import { Storage } from '../../../utils'
import SelectProjectWarning from 'modules/common/SelectProjectWarning'
import { themeAnalysisStyles } from './styles'
import ButtonWithIcon from '../../../common/buttons/ButtonWithIcon'
import { Cancel } from '@material-ui/icons'
import { dialogStyles } from '../../../common/csv/styles'
import { MuiDialogTitle } from '../../../common/csv/ExportDialog'
import { thunkExportFeedbacks } from './redux/thunk'
import FeedbackSurveyFilter from '../feedbacks/FeedbackSurveyFilter'


const propTypes = {
  activeChart: PropTypes.string,
  feedback_count: PropTypes.number,
  nps_score: PropTypes.string,
  promoters: PropTypes.string,
  passives: PropTypes.string,
  detractors: PropTypes.string,
  exportCsvAsync: PropTypes.func.isRequired,
  projectSelected: PropTypes.bool,
  searchParams: PropTypes.object,
}

const TAB_INDEX_PERIODS = 0
const TAB_INDEX_TIMESERIES = 1
const TAB_INDEX_CORRELATIONS = 2
const TAB_NAMES = ['periods', 'time_series', 'correlations']

const useStyles = makeStyles(themeAnalysisStyles)

const TabPanel = ({ children, value, index, ...other }) => {
  const { boxChart } = useStyles()
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
      <Box p={3} className={boxChart}>
        {children}
      </Box>
      }
    </Typography>
  )
}

const Analysis = ({ activeChart, exportCsvAsync, projectSelected, searchParams, error, filters, ...other }) => {
  const { root, tab, tabs, paper, button, blockExport, positionRelative } = useStyles()
  const { dialogContent, dialogPaper } = dialogStyles()
  const [open, setOpen] = useState(false)
  const [tabIndex, setTabIndex] = useState(TAB_INDEX_PERIODS)
  const [exportBtnEnabled, setExportBtnEnabled] = useState(true)

  useEffect(() => {
    setExportBtnEnabled(true)

    // eslint-disable-next-line
  }, [searchParams['user_ids[]']])

  const exportFunction = () => {
    setExportBtnEnabled(false)
    setOpen(true)
    exportCsvAsync(filters)
  }

  if (!projectSelected) {
    return <SelectProjectWarning />
  }

  const downloadChart = e => {
    e.preventDefault()
    const element = document.getElementById(activeChart)
    exportGraphAsPng(element, `${TAB_NAMES[tabIndex]}.png`)
  }

  const handleTabChange = (e, index) => {
    e.preventDefault()
    setTabIndex(index)
  }

  const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: tab,
  })

  return (
    <div className={root}>
      <FeedbackSurveyFilter />
      <div className={blockExport}>
        <ExportButton disabled={!exportBtnEnabled} text='NPS分析エクスポート' onClick={exportFunction} />
      </div>
      <AnalysisNpsScore {...other} />
      <Grid container spacing={2} className={positionRelative}>
        <Grid item xs={12}>
          <Tabs value={tabIndex} className={tabs} onChange={handleTabChange}>
            <Tab label='期間合計' {...a11yProps(TAB_INDEX_PERIODS)} />
            <Tab label='時系列推移' {...a11yProps(TAB_INDEX_TIMESERIES)} />
            <Tab label='相関分析' {...a11yProps(TAB_INDEX_CORRELATIONS)} />
          </Tabs>
          <Paper className={paper} square>
            <TabPanel value={tabIndex} index={TAB_INDEX_PERIODS}><AnalysisPeriods /></TabPanel>
            <TabPanel value={tabIndex} index={TAB_INDEX_TIMESERIES}><AnalysisTimeSeries /></TabPanel>
            <TabPanel value={tabIndex} index={TAB_INDEX_CORRELATIONS}><AnalysisCorrelations /></TabPanel>
          </Paper>
        </Grid>
        {false && <Grid item xs={12} align='right' className={button}>
          <Button variant='contained' endIcon={<i className='el-icon-download'></i>} onClick={downloadChart}>
            グラフをダウンロード
          </Button>
        </Grid>}
      </Grid>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        maxWidth={'sm'}
        fullWidth
        classes={{ paper: dialogPaper }}>
        <MuiDialogTitle onClose={() => setOpen(false)} label='NPS分析エクスポート' />
        <DialogContent dividers classes={{ root: dialogContent }}>
          {error ?
            (<div style={{ color: 'red' }}>{error}</div>)
            :
            (<div>
              <div>CSVファイル生成を受け付けました。</div>
              <div>CSVファイルが出来上がるとメールでダウンロードリンクが通知されます。</div>
            </div>)
          }
        </DialogContent>
        <DialogActions>
          <ButtonWithIcon
            autoFocus
            variant='outlined'
            onClick={() => setOpen(false)}
            startIcon={<Cancel />}
            text='閉じる'
            color='default'
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

Analysis.propTypes = propTypes

export default connect(
  ({ analysis, feedbacks, global: { projectData: { value }, searchParams, filters } }) => ({
    activeChart: analysis.activeChart,
    feedback_count: analysis.feedback_count,
    nps_score: analysis.nps_score,
    promoters: analysis.promoters,
    passives: analysis.passives,
    detractors: analysis.detractors,
    projectSelected: !!value,
    searchParams: searchParams,
    error: feedbacks.error,
    filters,
  }),
  dispatch => ({
    exportCsvAsync: (params) => dispatch(thunkExportFeedbacks({ ...Storage.getSearchParams(), ...params })),
  }),
)(Analysis)
