const tooltipBody = {
  position: 'absolute',
  fontFamily: "'Noto Sans JP', sans-serif",
  backgroundColor: '#EC681A',
  color: '#fff',
  padding: '3px 10px',
  fontSize: '0.625rem',
  maxWidth: 300,
  wordWrap: 'break-word',
  borderRadius: 4,
  left: '50%',
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
  zIndex: 1,
  textAlign: 'center',
  minWidth: 30
}

const tooltipArrow = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  borderStyle: 'solid',
  borderWidth: 5
}

export default {
  tooltipParent: {
    position: 'relative'
  },

  tooltipTop: {
    ...tooltipBody,
    bottom: 'calc(100% + 5px)',    

    '& > span': {
      ...tooltipArrow,
      top: '100%',      
      borderColor: '#EC681A transparent transparent transparent'
    }
  },

  tooltipBottom: {
    ...tooltipBody,
    top: 'calc(100% + 5px)',

    '& > span': {
      ...tooltipArrow,
      bottom: '100%',      
      borderColor: 'transparent transparent #EC681A transparent'
    }
  }
}
