import { getShopFromIncluded } from "../shops/utils";
import { API_MODEL_TYPE_SHOP } from "../shops/constants";
import { API_MODEL_TYPE_COMPANY } from "../companies/constants";
import { API_MODEL_TYPE_GROUP } from "../groups/constants";
import { getCompanyFromIncluded } from "../companies/utils";
import { getGroupFromIncluded } from "../groups/utils";


export const parseUser = ({ attributes, relationships, id }, included) => {
  const {
    authority,
    discarded_at,
    email,
    is_comment_notification,
    is_feedback_notification,
    is_feedback_pin,
    is_personal_info_exportable,
    is_personal_info_viewable,
    visible_negative_feedback,
    company_visible_negative_feedback,
    login_id,
    name,
    shop_names,
    ucode,
    viewing_target_names
  } = attributes;

  let {
    shops,
    company,
    users_viewing_groups,
    users_viewing_shops,
  } = relationships;

  const includedShops = included.filter(el => el.type === API_MODEL_TYPE_SHOP);
  shops = shops.data.map(({ id }) => getShopFromIncluded({ id, included: includedShops || [] }));

  const includedCompanies = included.filter(el => el.type === API_MODEL_TYPE_COMPANY);
  company = getCompanyFromIncluded({ id: company.data.id, included: includedCompanies || [] });

  const includedUsersViewingGroups = included.filter(el => el.type === API_MODEL_TYPE_GROUP);
  users_viewing_groups = users_viewing_groups.data.map(({ id }) => getGroupFromIncluded({ id, included: includedUsersViewingGroups || [] }, ({ id, attributes }) => ({ id, ...attributes })));

  users_viewing_shops = users_viewing_shops.data.map(({ id }) => getShopFromIncluded({ id, included: includedShops || [] }));

  return {
    id,
    authority,
    discarded_at,
    email,
    is_comment_notification,
    is_feedback_notification,
    is_feedback_pin,
    is_personal_info_exportable,
    is_personal_info_viewable,
    visible_negative_feedback,
    company_visible_negative_feedback,
    login_id,
    name,
    shop_names,
    ucode,
    viewing_target_names,
    shops,
    company,
    users_viewing_groups,
    users_viewing_shops,
  };
};
