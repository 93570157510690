import React from 'react';
import {
  Paper,
  makeStyles,
  Button,
  Grid,
} from "@material-ui/core";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { FormField } from '../components';
import Dropzone from './Dropzone';


const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    padding: '20px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderLeft: '4px solid #B7B7B7',
    borderRadius: 0,
    boxShadow: '0px 5px 6px #B7B7B733',
    opacity: 1,
  },
  image: {
    marginTop: 10,
    maxWidth: '100%',
    height: 'auto'
  },
  deleteImageBtn: {
    marginTop: 10
  }
}));

const AddImage = ({ image, setImage, onDelete }) => {
  const classes = useStyles()

  return (
    <FormField fullWidth className={classes.form}>
      <Paper>
        <Dropzone onChange={setImage} classes={classes} />
        {
          !!image && !!image.data && (
            <Grid container direction="column" justify="center" alignItems="center">
              <img className={classes.image} src={image.data} alt="" />
              <Button
                startIcon={<DeleteOutlinedIcon />}
                color="secondary" variant="outlined"
                onClick={onDelete}
                className={classes.deleteImageBtn}
              >
                削除
              </Button>
            </Grid>
          )
        }

      </Paper>
    </FormField>
  )
}

export default AddImage
