import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import tooltipStyles from './styles'

export default ({title, position, children, open, style}) => {
  const useStyle = makeStyles(tooltipStyles)
  const { tooltipParent, tooltipTop, tooltipBottom } = useStyle()
  const tooltip = useRef(null)

  const handleOverLeft = (tooltip, arrow, left) => {
    const leftEdge = 10
    if (left >= leftEdge) { return }

    const buffer = Math.ceil(leftEdge - left)
    tooltip.setAttribute("style", `margin-left: ${buffer}px;`)
    arrow.setAttribute("style", `margin-left: -${buffer}px;`)
  }

  const handleOverRight = (tooltip, arrow, right) => {
    const rightEdge = window.innerWidth - 10
    if (right <= rightEdge) { return }

    const buffer = Math.ceil(right - rightEdge)
    tooltip.setAttribute("style", `margin-left: -${buffer}px;`)
    arrow.setAttribute("style", `margin-left: ${buffer}px;`)
  }

  useEffect(() => {
    if (tooltip && tooltip.current) {
      const { left, right } = tooltip.current.getBoundingClientRect()
      const arrow = tooltip.current.firstElementChild
      handleOverLeft(tooltip.current , arrow, left)
      handleOverRight(tooltip.current , arrow, right)
    }
  })

  return (
    <div className={tooltipParent} style={style}>
      {children}

      {
        open &&
        <div className={position === 'top' ? tooltipTop : tooltipBottom} ref={tooltip}>
          {title}
          <span></span>
        </div>
      }
    </div>
  )
}
