import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Button } from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import TreeHeaderButtons from "./TreeHeaderButtons";
import ItemTree from "./ItemTree";
import { themedStyles } from "./styles";
import UpdateForm from "./UpdateForm";
import { checkVisibleAll } from "common/utils";


const useStyles = makeStyles(themedStyles);

const propTypes = {
  list: PropTypes.array,
  setList: PropTypes.func.isRequired,
  moveCard: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  doc: PropTypes.object,
  modalOpened: PropTypes.bool,
  error: PropTypes.object,
  isSubmitSuccess: PropTypes.bool,
  save: PropTypes.func,
  constants: PropTypes.object.isRequired,
  // 商品選択か否かのフラグ
  isProductSurvey:PropTypes.bool,
};

const Draggable = ({
  list,
  setList,
  moveCard,
  destroy,
  openModal,
  closeModal,
  fetchItems,
  doc,
  modalOpened,
  error,
  isSubmitSuccess,
  save,
  constants,
  isProductSurvey,
}) => {
  const classes = useStyles();
  const [currentItemId, setCurrentItemId] = useState(null);

  const openCreateModal = (doc, parentId, listParentName) => openModal({ parentName: doc.name, parentId, listParentName: (listParentName.concat((' > ').concat(doc.name))) })

  const openUpdateModal = (item, ownId, listParentName) => {
    Object.assign(item, {listParentName: listParentName});
    openModal(item)
    setCurrentItemId(ownId);
  };

  const toggleVisibleAll = () => {
    const newList = list.map(firstLevel => ({
      ...firstLevel,
      visibleTree: !isVisibleAll()
    }));
    setList([...newList]);
  };

  const toggleVisible = firstLevelId => {
    const newList = list.map(firstLevel => {
      if (firstLevel.id === firstLevelId)
        return {
          ...firstLevel,
          visibleTree: !firstLevel.visibleTree
        };
      else return firstLevel;
    });
    setList([...newList]);
  };

  const isVisibleAll = () => {
    return checkVisibleAll(list);
  };

  const shouldShowChild = item => {
    if (item.children) {
      return !item.hasOwnProperty("visibleTree") ? true : item.visibleTree;
    } else {
      return null;
    }
  };
  
  const renderTreeView = (items, parentId = 0, listParentName='') => {
    return items.map((item, index) => {
      const show = shouldShowChild(item);
      const active = currentItemId === item.id;
      const childName = item.parentName === 'root' ? 'ルート' : listParentName.concat(' > ').concat(item.parentName);

      return (
        <div key={item.id} className={classes.itemWrapper}>
          <ItemTree
            type={constants.type}
            ownItem={item}
            dataSet={list}
            listParentName={childName}
            currentItemId={currentItemId}
            setCurrentItemId={setCurrentItemId}
            moveCard={moveCard}
            index={index}
            parentId={parentId}
            hoverJSON={JSON.stringify(item)}
            openCreateModal={openCreateModal}
            openUpdateModal={openUpdateModal}
          />
          {show ?
            <div className={clsx(classes.itemContainer, active && classes.itemActive)}>
              {renderTreeView(item.children, item.id, childName)}
            </div>
          : // TODO: avoid null, true value
            <Button
              className={classes.expandMore}
              onClick={() => toggleVisible(item.id)}
            >
              <ExpandMoreIcon />
            </Button>
          }
        </div>
      );
    });
  };

  return (
    <div className={classes.root}>
      <TreeHeaderButtons
        path={constants.path}
        toggleVisibleAll={toggleVisibleAll}
        isVisibleAll={isVisibleAll}
        openCreateModal={openCreateModal}
        fetchItems={fetchItems}
        exportButtonText={constants.exportButtonText}
        importButtonText={constants.importButtonText}
      />
      <div className={classes.list}>{renderTreeView(list)}</div>

      <UpdateForm
        dataSet={list}
        onClose={closeModal}
        destroy={destroy}
        item={doc}
        modalOpened={modalOpened}
        error={error}
        isSubmitSuccess={isSubmitSuccess}
        save={save}
        constants={constants}
        isProductSurvey={isProductSurvey}
      />
    </div>
  )
}

Draggable.propTypes = propTypes;

export default Draggable;
