import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

export const parseToOptionValues = (items, parseFunc=({ id, name }) => ({ label: name, value: id })) => items.map(parseFunc);

export const exportGraphAsPng = (element, fileName) => {
  domtoimage.toBlob(element, { bgcolor: "white", filter: node => node.tagName !== 'BUTTON'})
    .then(blob => saveAs(blob, fileName));
}

export const getElementWidth = elementId => (document.getElementById(elementId) || {}).offsetWidth || 0;

export const convertToListAndParse = (allItems, level=0) => {
  let list = [];

  allItems.forEach(item => {
    list.push({ value: item.id, label: item.name, level , children: item.children })

  });
  return list;
};

export const convertToListAndParseGroup = (allItemGroups) => {
  let list = [];
  allItemGroups.forEach(item => {
    list.push({ value: item.id, label: item.name })
    if (!!item.children) {
      const subList = convertToListAndParseGroup(item.children);
      list = list.concat(subList);
    }
  });
  return list;
};

export const renderParams = (body = "", name = "") => {
  const SELECTED_NAME = '{%SELECTED_NAME%}'
  const SELECTED_IMAGE = '{%SELECTED_IMAGE%}'

  const returnVal = body.replace(SELECTED_NAME, name).replace(SELECTED_IMAGE, "")

  return returnVal
}

export const replaceParams = (body = "") => {
  const SELECTED_NAME = '{%SELECTED_NAME%}'
  const SELECTED_IMAGE = '{%SELECTED_IMAGE%}'
  const replaceValue = '商品'

  const returnVal = body.replace(SELECTED_NAME, replaceValue).replace(SELECTED_IMAGE, "")

  return returnVal
}

/**
 * Merge single item into array of items. If the item exists in the array, it will replace.
 * If not, it will be appended at the end of the list.
 *
 * @param item to be merged
 * @param items list of items
 * @returns {({}|*)[]|*[]} merged items
 */
export const mergeItem = (item= {}, items= []) => {
  let found = undefined
  const mapped = items.map(i => {
    if (+i.id === +item.id) {
      found = item
      return found
    }
    return i
  })
  if (found)
    return mapped
  else
    return items.concat([item])
}