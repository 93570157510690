export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const UPLOAD_FILE_BEGIN = 'UPLOAD_FILE_BEGIN'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'
export const EXPORT_FILE_FAILURE = 'EXPORT_FILE_FAILURE'

export const openModal = () => ({ 
  type: OPEN_MODAL
})

export const closeModal = () => ({ 
  type: CLOSE_MODAL
})

export const uploadFileBegin = () => ({
  type: UPLOAD_FILE_BEGIN,
})

export const uploadFileSuccess = response => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: { response }
})

export const uploadFileFailure = error => ({
  type: UPLOAD_FILE_FAILURE,
  payload: { error }
})

export const exportFileFailure = error => ({
  type: EXPORT_FILE_FAILURE,
  payload: { error }
})
