import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Typography, Box } from "@material-ui/core";
import clsx from "clsx";

import UserPopover from "./UserPopover";

const useStyles = makeStyles(theme => {
  return{
    root: {
      height: "56px",
      width: "100%",
      display: "flex",
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: "center",
      paddingLeft: "15px",
      paddingRight: "15px",
      backgroundColor: theme.palette.grayscale[100],
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.brown.Light,
      cursor: 'pointer',
    },
    hideInfo: {
      justifyContent: 'center',
    },
    avt: {
      width: "36px",
      height: "36px",
      fontWeight: "bold",
      color: "white",
      borderRadius: "4px",
      backgroundColor: "#EC681A",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    user: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      '& .MuiTypography-root > div': {
        whiteSpace: 'nowrap',
        width: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    },
    menuOpen: {
      color: '#EC681A'
    }
  }
});

export const Avt = ({ letter }) => {
  const classes = useStyles();
  return <div className={classes.avt}>{letter || 'K'}</div>;
};

const HeaderUser = ({ hideInfo, companyName, userName}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div className={clsx(classes.root, hideInfo && classes.hideInfo )} onClick={handleClick}>
        <div className={classes.user}>
          <Avt letter={userName && userName.slice(0,1)}/>
          {!hideInfo && (
            <div className={classes.info}>
              <Typography component="div">
                <Box fontWeight="600" fontSize="12px" ml={1} className={open ? classes.menuOpen : ''}>
                  {userName}
                </Box>
              </Typography>
              <Typography component="div">
                <Box fontWeight="600" fontSize="10px" color="#646464" ml={1}>
                  {companyName}
                </Box>
              </Typography>
            </div>
          )}
        </div>

        {!hideInfo && <ArrowRightIcon className={open ? classes.menuOpen : ''}/>}
      </div>

      <UserPopover open={open} anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
};

export default connect(
  ({ global: { selectedCompany, userData } }) => ({
    companyName: selectedCompany.label,
    userName: userData.name,
  }),
)(HeaderUser);
