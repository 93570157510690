export const FETCH_PROJECTS_BEGIN   = 'FETCH_PROJECTS_BEGIN';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';
export const FETCH_ALL_PROJECTS_BEGIN = 'FETCH_ALL_PROJECTS_BEGIN';
export const FETCH_ALL_PROJECTS_SUCCESS = 'FETCH_ALL_PROJECTS_SUCCESS';
export const FETCH_ALL_PROJECTS_FAILURE = 'FETCH_ALL_PROJECTS_FAILURE';

export const UPDATE_PROJECT_ITEM = 'UPDATE_PROJECT_ITEM';

export const SAVE_PROJECT_BEGIN = 'SAVE_PROJECT_BEGIN';
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS';
export const SAVE_PROJECT_FAILURE = 'SAVE_PROJECT_FAILURE';

export const DEACTIVATE_PROJECT = 'DEACTIVATE_PROJECT';

export const FETCH_PROJECTS_RESET = "FETCH_PROJECTS_RESET";
