import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormHelperText,
  FormControl,
  Button,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import {
  FiberManualRecord as FiberManualRecordIcon
} from '@material-ui/icons';
import { QuestionTypeRadioStyles } from "./styles"
import HtmlRender from './HtmlRender';

const colorComon = "#EC681A";

const useStyles = makeStyles(QuestionTypeRadioStyles);

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onInputValue: PropTypes.func.isRequired,
};

const QuestionTypeRadio = ({
  questionItem,
  answer,
  onInputValue,
  errors,
}) => {
  const styleProps = {color: colorComon};
  const classes = useStyles(styleProps);
  const freeAnswerOptions = questionItem.question_options_attributes.filter(({ is_free_answer }) => is_free_answer === true);
  const selectedOptions = (answer.answer_options_attributes || []).map(({ question_option_id }) => +question_option_id);
  const [selectedOption, setSelectedOption] = useState(questionItem.question_options_attributes.filter(({ id }) => selectedOptions.includes(+id))[0] || null);
  const [freeAnswerIsSelected, setFreeAnswerIsSelected] = useState(freeAnswerOptions.map(item => +item.id).includes(!!selectedOption ? +selectedOption.id : null));
  const [faValue, setFaValue] = useState(answer.answer_entry || "");

  const selectHasError = !!errors.answer_options_attributes;
  const textHasError = !!errors.answer_entry;
  
  const selectChangeHandler = (option) => {
    const faIsSelected = freeAnswerOptions.map((item) => +item.id).includes(+option.id);
    let answer_entry = '';

    if (faIsSelected) {
      answer_entry = faValue;
    } else {
      setFaValue(answer_entry);
    }

    onInputValue(questionItem, { selectValue: option.id, answer_entry });
    setSelectedOption(option);
    setFreeAnswerIsSelected(faIsSelected);
  };

  const textChangeHandler = ({ target: { value } }) => {
    setFaValue(value);
    onInputValue(questionItem, { selectValue: selectedOption.id, answer_entry: value });
  };

  return (
    <>
      <Typography className={classes.textAnswer}>
        <HtmlRender
          value = {questionItem.body}
        />
        {questionItem.is_required && (
          <span className="required">必須</span>
        )}
      </Typography>
      {!!questionItem.question_image_url && (
        <div className={classes.img}>
          <img src={questionItem.question_image_url} alt={''}/>
        </div>
      )}
      <div className={classes.bgRadio}>
        {questionItem.question_options_attributes.map(option => {
          return (
            <Button
              className={!!selectedOption && +selectedOption.id === +option.id ? classes.btnColor : classes.btn }
              variant="outlined"
              startIcon={
                <FiberManualRecordIcon
                  className={!!selectedOption && +selectedOption.id === +option.id ? classes.activeRadio : classes.iconRadio }
                />
              }
              onClick={() => selectChangeHandler(option)}
              key={option.id}
            >
              {!!option.option_image_url ? (
                <div>
                  <div className={classes.image}>
                    <img className={classes.imgView} src={option.option_image_url} alt={''}/>
                  </div>
                  <div>
                    <HtmlRender
                      value = {option.name}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <HtmlRender
                    value = {option.name}
                  />
                </>
              )}
              
            </Button>
          )
        })}
        {selectHasError && (
          <FormHelperText error={true}>{errors.answer_options_attributes.map(err => err)}</FormHelperText>
        )}
      </div>
      {freeAnswerIsSelected && (
        <FormControl component="fieldset" fullWidth className={classes.form_control} error={textHasError}>
          <TextField
            variant="standard"
            value={faValue}
            onInput={textChangeHandler}
            placeholder="入力してください"
          />
          {textHasError && (
            <FormHelperText error={true}>{errors.answer_entry.map(err => err)}</FormHelperText>
          )}
        </FormControl>
      )}
    </>
  );
}

QuestionTypeRadio.propTypes = propTypes;

export default QuestionTypeRadio;
