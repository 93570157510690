export const themedStyles = theme => ({
  buttonToggle: {
    background: "#EFEFEF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    padding: "11px 49px 11px 12px",
    "& span": {
      textAlign: "left",
      font: "bold 12px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707",
      opacity: 1,
      "& label": {
        marginRight: "10px"
      }
    }
  },
  buttonDel: {
    background: "#B7B7B7 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    padding: "9px 20px",
    float: "right",
    "& span": {
      textAlign: "left",
      font: "bold 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro",
      letterSpacing: "0.25px",
      color: "#FFFFFF",
      opacity: 1,
      "& i": {
        foniSize: "20px"
      }
    },
    "&:hover":{
      background: "#EC681A 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      opacity: 1,
      border: "1px solid #EC681A"
    },
    "&:hover span":{
      color:"#fff"
    },
    "&:hover i":{
      color:"#fff"
    },
  },
  displayInline: {
    display: "inline"
  },
  ml_20: {
    marginLeft: "20px"
  },
  pd_0_10: {
    padding: "0 10px"
  },
  width70: {
    width: "70%"
  },
  formContain: {
    width: "540px",
    padding: "10px 30px",
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  selectAuthority: {
    padding: '0 0 20px',
    "& .el-icon-caret-bottom": {
      color: "black"
    }
  },
  TextField: {
    "& .MuiOutlinedInput-input": {
      padding: "6.5px 14px"
    }
  },
  selectedChip: {
    color: 'white',
    backgroundColor: '#9A877D'
  },
  headerFrom: {
    marginTop: "74px",
    position: "relative",
    "& >div": {
      textAlign: "left",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#1A0707",
      opacity: 1,
      display: "inline-block",
      "& >div":{
        "& >fieldset":{
          border: 0,
          borderBottom: "1px solid #b7b7b7",
          borderRadius: 0
        },
      },
      "& i": {
        fontSize: "16px",
        color: "#F02544",
        marginLeft: "10px",       
      }
    },
    "& >i": {
      float: "right",
      fontSize: "16px",
      color: "#B7B7B7",
      right: 0,
      position: "absolute",
      top: "50%",
      transform: "translate(-100%, -50%)",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "50px",
      "& >div": {
        textAlign: "left",
        font: "500 10px/18px Noto Sans JP, Hiragino Sans",
        letterSpacing: 0,
        color: "#1A0707",
        opacity: 1,
        display: "inline-block",
        "& i": {
          fontSize: "14px",
          color: "#F02544",
          marginLeft: "10px"
        }
      },
      "& >i": {
        float: "right",
        fontSize: "16px",
        color: "#B7B7B7",
        position: "relative",
        top: "5px"
      }
    }
  },
  editName: {
    width: "100%",
    marginBottom: 5,
    "& input": {
      padding: "13px 10px 13px 10px",
      font: "500 14px/18px Noto Sans JP, Hiragino Sans",
      letterSpacing: 0,
      color: "#000",
      '& :placeholder': {
        color: '#B7B7B7',
      },
    },
    "& i": {
      marginTop: "-15px"
    },
    "&::before": {
      borderBottom: "1px solid #B7B7B7"
    }
  },
  labelSelect: {
    minWidth: 60,
    margin: '0 15px 0 0'
  },

  blockListUser: {
    "& >div": {
      "& >.search": {
        display: "block",
        "& >p": {
          display: "inline-block",
          width: "21%",
          font: "500 14px/18px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          color: "#1A0707",
          opacity: 1
        },
        "& >div": {
          display: "flex",
          width: "100%",
          justifyContent: 'space-between'
        },
      },
      "& >.listUser": {
        background: "#F7F7F7 0% 0% no-repeat padding-box",
        opacity: 1,
        padding: "0px 10px",
        marginTop: "10px",
        overflow: "auto",
        display: "flex",
        marginRight: "5px",
        borderRadius: "0px",
        flexWrap: "wrap",
        minHeight: "46px",
        alignItems: 'center',
        "& >div": {
          background: "#9A877D 0% 0% no-repeat padding-box",
          borderRadius: "10px",
          opacity: 1,
          display: "flex",
          margin: "5px 5px 5px 0",
          padding: "3px 5px",
          wordBreak: 'break-all',
          "& >p": {
            font: "600 10px/16px Noto Sans JP, Hiragino Sans",
            letterSpacing: "0.25px",
            color: "#FFFFFF",
            opacity: 1,
            margin: "2px 1px",
          },
          "& >i": {
            float: "right",
            color: "#fff",
            cursor: "pointer",
            fontSize: "15px",
            display: "contents"
          }
        }
      },
    },

    '& .dropdown-content': {
      '& label': {
        width: '100% !important',
        marginRight: '60px'
      },

      '& ul': {
        margin: '0',
        padding: '0',
        width: '100%',
        maxHeight: '540px',
        overflowY: 'scroll',
        paddingRight: '10px !important',
        position: 'relative',

        '&::-webkit-scrollbar': {
          width: "2px",
          background: "#F2EEEC 0% 0% no-repeat padding-box",
          borderRadius: "1px",
          opacity: 1,
        },
    
        '&::-webkit-scrollbar-thumb': {
          borderRadius: "1px",
          background: "#B7B7B7 0% 0% no-repeat padding-box",
          opacity: 1,
        }
      }  
    }
  },
  
  hiddenGroupSelect: {
    '& .disabled': {
      '& input': {
        visibility: "hidden"
      },

      '& label': {
        cursor: 'pointer',
        color: "#1A0707"
      }
    }
  },

  labelInput: {
    margin: "30px 0 -6px 0",
    font: "500 14px/18px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0px",
    color: "#1A0707"
  },

  inputGroup: {
    "& .MuiFormControl-root": {
      margin: 0
    },
    '& .MuiFormLabel-root': {
      color: '#1a0707',
      fontWeight: '600',
    },
    '& p': {
      margin: '30px 0 0 !important'
    },
    '& label': {
      width: 'fit-content',
    }
  },

  textFieldInput: {
    margin: '14px 0 0 !important',
    '& > div': {
      height: 32
    },
    '& input': {
      padding: '9px 12px',
      border: '1px solid #ccc',
      borderRadius: 4,
      height: '38px',
      boxSizing: 'border-box',
      opacity: '1 !important',

      '&:hover': {
        borderColor: '#1A0707'
      },

      '&:focus': {
        border: '2px solid #EC681A',
      },

      '&:-ms-input-placeholder': {
        color: '#ccc !important',
      }
    },

    '& fieldset': {
      display: 'none',
    }
  },

  selectField :{
    marginTop: '14px',
  }
});
