import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  BarChart,
  Bar,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import { CircularProgress } from '@material-ui/core';
import Pagination from 'components/Pagination';
import { thunkFetchCrossAggregation } from './redux/thunk';
import { setPageNumber } from './redux/actions';
import {
  HORIZONTAL_OPTIONS_PER_PAGE,
  NPS_QUESTION,
  OPTION_COLORS,
  DEFAULT_COLOR
} from './constants';
import CrossTable from './CrossTable';
import { range, getNpsColor } from './utils';
import { makeStyles } from '@material-ui/core/styles';
import { themeAggregationCrossStyles } from "./styles";
import { getElementWidth } from "../utils";
import { useGAEffect } from '../../ga';


const propTypes = {
  totalCross: PropTypes.object,
  searchParams: PropTypes.object,
  nps: PropTypes.bool,
  fetchTotalCross: PropTypes.func.isRequired,
  setPage: PropTypes.func,
  ga: PropTypes.object.isRequired,
};

const useStyles = makeStyles(themeAggregationCrossStyles);

const AggregationCross = ({
  totalCross,
  searchParams,
  nps,
  fetchTotalCross,
  setPage,
  ga,
  filters,
 }) => {
  const { header, headerOptionKeys, selectedOptions, graph, items, loading, page } = totalCross
  const { h_question_id, v_question_id, group_id, project_id, survey_id, sdate, edate } = searchParams
  const { buttonPercentage, root, blockTooltip, pagination } = useStyles();
  const [leftMargin, setLeftMargin] = useState(0)

  useEffect(() => {
    fetchTotalCross({ ...searchParams, ...filters })
    // eslint-disable-next-line
  }, [fetchTotalCross, h_question_id, v_question_id, group_id, project_id, survey_id, sdate, edate, filters])

  useEffect(() => {
    const choiceColumnWidth = getElementWidth('choiceCol')
    const countColumnWidth = getElementWidth('countCol')
    setLeftMargin(choiceColumnWidth + countColumnWidth)
    // eslint-disable-next-line
  }, [totalCross]);

  useGAEffect(ga, "cross", "クロス集計")

  if (!(!!h_question_id && !!v_question_id)) {
    return "設問を選択してください。"
  } else if (loading || !!!graph) {
    return <CircularProgress disableShrink />
  }

  const CustomTooltip = ({ active, payload, label }) => {
    const tooltipTitle = header[headerOptionKeys[label]]
    return !active ?
      null
      :
      <div className={blockTooltip}>
        <div>{tooltipTitle}</div>
        {payload && payload.map((item, i) =>
          <p key={i} style={{ color: item.style.color}}>
            {item.name}: {item.value}%
          </p>
        )}
      </div>
  }

  const getColor = i => nps ? getNpsColor(i) : OPTION_COLORS[i] || DEFAULT_COLOR;

  const lineProps = value => ({
    y: value,
    stroke: "#B7B7B7",
    strokeWidth: 2,
    label: {
      position: 'left',
      value: value + '%',
      fill: '#796255',
      fontSize: 10
    }
  })

  return (
    <div className={root}>
      <div className={buttonPercentage}>
        割合
      </div>
      <div className={pagination}>
        <Pagination
          activePage={page}
          firstPageText={true}
          lastPageText={true}
          itemsCountPerPage={HORIZONTAL_OPTIONS_PER_PAGE}
          totalItemsCount={Object.keys(header || {}).length}
          onChange={setPage}
        />
      </div>
      <ResponsiveContainer width='100%' height={400} id="graph-cross">
        <BarChart width={779} height={500} data={graph} barCategoryGap="10%"
          margin={{top: 30, right: 0, left: leftMargin, bottom: 60}}>
          <CartesianGrid strokeWidth={0.7} vertical={false} />
          <Tooltip cursor={{fill: 'none'}} content={<CustomTooltip />} />
          <YAxis type="number" domain={[0, 100]} tickCount={11} hide={true} stroke="#F2EEEC" />
          <XAxis type="category" hide={true}/>
          {range(10).map(i => <ReferenceLine key={i+1} y={i * 10} stroke="#F2EEEC" />)}
          {[0, 50, 100].map(i => <ReferenceLine key={i} {...lineProps(i)} />)}
          {selectedOptions.map(i =>
            <Bar key={i} dataKey={"bar"+i} unit="%" name={items[i].label} style={{ color: getColor(i) }}>
              {range(graph.length).map(num => <Cell key={num} fill={getColor(i)} />)}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
      <CrossTable totalCross={totalCross} nps={nps} />
    </div>
  )
}

AggregationCross.propTypes = propTypes;

export default connect(
  ({ aggregation, global: { searchParams, filters }, ga }) => {
    const { totalCross, selectedQuestions, loading } = aggregation;
    const { graph, headerOptionKeys=[], page } = totalCross;
    const { vertical={}, horizontal={} } = selectedQuestions;
    const slice = array => array ? array.slice((page-1) * HORIZONTAL_OPTIONS_PER_PAGE, page * HORIZONTAL_OPTIONS_PER_PAGE) : undefined

    return ({
      totalCross: {
        ...totalCross,
        graph: slice(graph),
        headerOptionKeys: slice(headerOptionKeys),
      },
      loading,
      selectedQuestions,
      nps: vertical.question_type === NPS_QUESTION,
      searchParams: {
        ...searchParams,
        v_question_id: vertical.id,
        h_question_id: horizontal.id,
      },
      ga,
      filters,
    }
  )},
  dispatch => ({
    fetchTotalCross: searchParams => dispatch(thunkFetchCrossAggregation(searchParams)),
    setPage: page => dispatch(setPageNumber(page)),
  })
)(AggregationCross);
