import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function IconFeed(props) {
  return (
    <SvgIcon {...props}>
      <path d="M6.06,11.38h14A2.8,2.8,0,0,0,23,8.71V2.66A2.79,2.79,0,0,0,20.09,0H4.17A2.79,2.79,0,0,0,1.28,2.66V8.71a2.61,2.61,0,0,0,1.21,2.17L1,14.65Zm-3-.88A2,2,0,0,1,1.94,8.71V2.66A2.14,2.14,0,0,1,4.17.61H20.09a2.14,2.14,0,0,1,2.23,2.05V8.71a2.15,2.15,0,0,1-2.23,2.06H5.86L2.31,13l1-2.41Z"/>
      <path d="M7.23,6.73A.82.82,0,0,0,8.08,6a.85.85,0,0,0-1.7,0A.82.82,0,0,0,7.23,6.73Z"/>
      <path d="M12.13,6.73A.82.82,0,0,0,13,6a.85.85,0,0,0-1.7,0A.82.82,0,0,0,12.13,6.73Z"/>
      <path d="M17,6.73A.82.82,0,0,0,17.88,6a.85.85,0,0,0-1.7,0A.82.82,0,0,0,17,6.73Z"/>
      <path d="M21.79,20.92a2.09,2.09,0,0,0,.93-1.7V14.53A2.23,2.23,0,0,0,20.4,12.4H8.06a2.23,2.23,0,0,0-2.32,2.13v4.69a2.24,2.24,0,0,0,2.32,2.13H18.91L23.05,24Zm-2.68-.17h-11A1.6,1.6,0,0,1,6.4,19.22V14.53A1.6,1.6,0,0,1,8.06,13H20.4a1.59,1.59,0,0,1,1.65,1.53v4.69a1.51,1.51,0,0,1-.83,1.32l-.24.13.71,1.72Z"/>
      <path  d="M10.13,16.31a.66.66,0,1,0,.71.66A.68.68,0,0,0,10.13,16.31Z"/>
      <path  d="M14.23,16.31a.66.66,0,1,0,.71.66A.68.68,0,0,0,14.23,16.31Z"/>
      <ellipse  cx="18.33" cy="16.97" rx="0.71" ry="0.65"/>
    </SvgIcon>
  );
}

export function IconNps(props) {
  return (
    <SvgIcon {...props}>
        <path d="M20.5,8.6c-4.7-4.7-12.3-4.7-17,0C1.3,10.9,0,13.9,0,17.1v0.2h2.6v-0.5H0.5
c0.1-2.9,1.2-5.7,3.2-7.7l1.6,1.6l0.3-0.3L4,8.8c2.1-2,4.9-3.2,7.8-3.2v2.1h0.5V5.6c3,0,5.8,1.3,7.9,3.4c0.1,0.1,0.2,0.2,0.3,0.3
	L19,10.8l0.3,0.3l1.5-1.5c1.7,2,2.7,4.6,2.7,7.2h-2.1v0.5H24v-0.2C24,13.9,22.7,10.9,20.5,8.6z"/>
    <path d="M13.9,17.8L13.9,17.8c0.4-0.7,1.5-5.2,1.9-7.1c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.2,0-0.2,0.1
	c-1.4,1.3-4.8,4.5-5.2,5.2l0,0l0,0l0,0c-0.6,1-0.2,2.3,0.8,2.9C12,19.2,13.3,18.9,13.9,17.8L13.9,17.8L13.9,17.8z"/>
    </SvgIcon>
  );
}

export function IconProject(props) {
  return (
    <SvgIcon {...props}>
        <path d="M13.2,8.1c3.2,0,6.5,0,9.7,0c0.2,0,0.4,0,0.6,0.1c0.4,0.1,0.6,0.5,0.4,0.9
            c-0.7,3.8-1.5,7.6-2.2,11.4c0,0.1,0,0.1,0,0.2c0,0.5-0.5,0.9-1,0.9c-0.1,0-0.1,0-0.2,0c-6.4,0-12.9,0-19.3,0c-0.2,0-0.4,0-0.6-0.1
            c-0.4-0.1-0.6-0.6-0.6-1c0.7-3.4,1.4-6.9,2.1-10.3c0.1-0.4,0.2-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.8-0.8c0.1,0,0.2,0,0.3,0H13.2z"/>
        <path d="M21.3,7.1h-0.4c-5.7,0-11.5,0-17.2,0C3.1,7,2.7,7.1,2.3,7.3c-0.5,0.3-0.9,0.8-1,1.4
            c-0.3,1.7-0.7,3.5-1,5.2c-0.1,0.4-0.2,0.8-0.3,1.2v-0.5C0,11.1,0,7.6,0,4c0-0.7,0.3-1,1-1c1.1,0,2.2,0,3.3,0c0.8,0,1,0.3,1.1,1.1
            c0,0.1,0,0.1,0,0.2c0.1,0,0.2,0,0.4,0c4.8,0,9.6,0,14.5,0c0.7,0,1.1,0.3,1.1,1.1c0,0.5,0,0.9,0,1.4L21.3,7.1z"/>
    </SvgIcon>
  );
}

export function IconSurvey(props) {
  return (
    <SvgIcon {...props}>
        <path d="M13.1,6.2h-2.6V3.6h2.6V6.2z M11.1,5.6h1.4V4.2h-1.4V5.6z"/>
        <path d="M13.1,10h-2.6V7.4h2.6V10z M11.1,9.4h1.4V8h-1.4V9.4z"/>
        <rect x="5" y="8.4" />
        <rect x="5" y="12.3" />
        <rect x="5" y="4.5" />
        <path d="M16.9,24c-0.1,0-0.1,0-0.2,0c-0.6,0-1.1-0.2-1.6-0.4c-0.4-0.2-0.8-0.5-1.2-0.7
            c-0.2-0.1-0.3-0.2-0.5-0.3c-0.4-0.3-0.8-0.5-1.2-0.8c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.3-0.7-0.5c-0.3-0.2-0.4-0.6-0.3-0.9
            c0.1-0.3,0.4-0.5,0.7-0.5c0,0,0.1,0,0.1,0l0.1,0c0.4,0.1,0.9,0.2,1.4,0.3c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1
            c-0.1-0.4-0.3-0.7-0.4-1.1c-0.1-0.2-0.2-0.4-0.3-0.7c-0.3-0.7-0.5-1.4-0.8-2.1l0-0.1c-0.1-0.2-0.2-0.5-0.3-0.7
            c-0.1-0.4-0.3-0.8-0.4-1.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.3,0-0.7,0.3-1c0.2-0.1,0.4-0.2,0.6-0.2h0
            c0.3,0,0.7,0.2,0.8,0.5c0.1,0.4,0.3,0.7,0.4,1.1c0.1,0.3,0.3,0.7,0.4,1c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.3,0.2,0.5,0.3,0.8l0.1,0.3
            c0-0.3,0.1-0.5,0.4-0.7c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.1,0,0.2,0c0.1,0.3,0.4,0.5,0.7,0.5c0.3-0.1,0.5-0.4,0.5-0.7c0,0,0,0,0-0.1
            l0,0c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0.2,0,0.5,0.2,0.6,0.4c0-0.3,0.1-0.5,0.3-0.6c0.2-0.1,0.3-0.2,0.5-0.2
            c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.6,0.5,1.3,0.8,1.9
            c0.2,0.5,0.2,1,0.3,1.5c0,0.7-0.2,1.4-0.6,2c-0.4,0.6-0.9,1.1-1.6,1.4C18.3,23.8,17.6,24,16.9,24z"/>
        <path d="M13.3,22.8c-0.4-0.3-0.8-0.5-1.2-0.8c-0.2-0.1-0.4-0.3-0.6-0.4l0,0c-0.2-0.1-0.5-0.3-0.7-0.4
            c-0.3-0.2-0.5-0.5-0.5-0.9H3.5V2.7h11.1v12.5c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0.1V1.7
            C15.8,0.7,15,0,14.1,0h-10C3.2,0,2.4,0.7,2.4,1.7v19.6c0,0.9,0.8,1.7,1.7,1.7h9.5C13.5,22.9,13.4,22.8,13.3,22.8z"/>
        <path d="M11,13.9l-0.1-0.3l-0.1-0.3c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.7,0.6-1.3,1.3-1.3c0.5,0,1,0.3,1.2,0.8
            c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.2,0.5,0.3,0.8c0-0.1,0.1-0.3,0.1-0.5c0-0.9-0.7-1.7-1.6-1.7c-0.9,0-1.7,0.7-1.7,1.6
            C10.1,13,10.5,13.6,11,13.9L11,13.9z"/>
    </SvgIcon>
  );
}

export function IconTotal(props) {
  return (
    <SvgIcon {...props}>
       <path d="M21.6,20V4.5h-3.3V20h-2V6.4h-3.3V20h-2V9.5H7.8V20h-2v-7.6H2.6V20H0.4V3.6H0v16.8h24V20H21.6z"/>
    </SvgIcon>
  );
}