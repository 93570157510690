import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import validate from 'validate.js';
import QuestionTypeSelect from './QuestionTypeSelect';
import QuestionTypeRadio from './QuestionTypeRadio';
import QuestionTypeSelectButton from './QuestionTypeSelectButton';
import QuestionTypeSelectCheckbox from './QuestionTypeSelectCheckbox';
import QuestionTypeDate from './QuestionTypeDate';
import QuestionTypeText from './QuestionTypeText';
import QuestionTypeSelectUser from './QuestionTypeSelectUser';
import QuestionTypeNPS from './QuestionTypeNPS';
import QuestionTypeImageUpload from './QuestionTypeImageUpload';
import QuestionHead from './QuestionHead';
import QuestionTypeTextInput from './QuestionTypeTextInput';
import {
  SURVEY_FORM_TYPE_SURVEY_HEADING,
  QUESTION_TYPE_SELECT,
  QUESTION_TYPE_RADIO_BUTTON,
  QUESTION_TYPE_SELECT_BUTTON,
  QUESTION_TYPE_NPS_QUESTION,
  QUESTION_TYPE_SELECT_USER,
  QUESTION_TYPE_CHECK_BOX,
  QUESTION_TYPE_DATE,
  QUESTION_TYPE_FILE_UPLOAD,
  QUESTION_TYPE_NPS_REASON,
  QUESTION_TYPE_TEXT_FIELD,
  QUESTION_TYPE_TEXT_AREA,
} from 'modules/surveys/form/questions/constants';
import { generateValidationConstraints } from '../utils';


const propTypes = {
  surveyPage: PropTypes.object.isRequired,
  questionItem: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
  companyUrlText: PropTypes.string.isRequired,
  shortUrl: PropTypes.string.isRequired,
  staff_id: PropTypes.number,
  updateDoc: PropTypes.func.isRequired,
  setCurrentPageErrors: PropTypes.func.isRequired,
  currentPage: PropTypes.string,
  autovalidate: PropTypes.bool.isRequired,
}

const QuestionItem = ({
  surveyPage,
  questionItem,
  questionIndex,
  companyUrlText,
  shortUrl,
  staff_id,
  updateDoc,
  setCurrentPageErrors,
  currentPage,
  autovalidate,
}) => {
  const [errors, setErrors] = useState({});
  const timeoutID = useRef(null);

  useEffect(() => {
    validateQuestion();
    // eslint-disable-next-line

    return () => {
      setCurrentPageErrors({});
    }

    // eslint-disable-next-line
  }, [currentPage]);

  const customTheme = createMuiTheme({
    overrides: {
      MuiButton: {
        label: {
          textAlign: 'justify',
          justifyContent: 'unset',
          textTransform: 'none'
        },
      },
    },
  });
  
  let answer = questionItem.storedAnswer;

  const validateQuestion = () => {
    const validationConstraints = generateValidationConstraints({ questionItems: [questionItem], answers: { [questionItem.id]: answer } });

    const docToValidate = answer || { answer_options_attributes: [], answer_entry: '' };
    const questionValidationErrors = validate(docToValidate, validationConstraints[questionItem.id]);

    const tmpErrors = { ...(questionValidationErrors || {}) };

    setErrors(_ => ({ ...tmpErrors }));

    setCurrentPageErrors(prev => {
      if (Object.keys(tmpErrors).length === 0) {

        if (prev[questionItem.id] === undefined) {
          return { ...prev };
        }

        delete prev[questionItem.id];

        return { ...prev };
      } else {
        return {
          ...prev,
          [questionItem.id] : { ...tmpErrors },
        }
      }
    });
  };

  const onInputValue = (questionItem, { selectValue, checkboxValues, answer_entry, answer_date, answer_image }) => {
    if ([
      QUESTION_TYPE_SELECT,
      QUESTION_TYPE_RADIO_BUTTON,
      QUESTION_TYPE_SELECT_BUTTON,
      QUESTION_TYPE_NPS_QUESTION
    ].includes(questionItem.question_type)) {
      if (selectValue !== undefined) {
        answer = {
          answer_entry: '',
          question_id: questionItem.id,
          answer_options_attributes: [{ question_id: questionItem.id, question_option_id: selectValue }],
        };
      }

      if (answer_entry !== undefined) {
        answer = {
          ...answer,
          answer_entry,
        }
      }
    } else if (questionItem.question_type === QUESTION_TYPE_SELECT_USER) {
      answer = {
        question_id: questionItem.id,
        user_id: selectValue,
      };

    } else if (questionItem.question_type === QUESTION_TYPE_CHECK_BOX) {

      if (checkboxValues !== undefined) {
        answer = {
          answer_entry: '',
          question_id: questionItem.id,
          answer_options_attributes: []
        };

        checkboxValues.forEach(checkboxValue => {
          answer.answer_options_attributes.push({ question_id: questionItem.id, question_option_id: checkboxValue });
        });
      }
      
      if (answer_entry !== undefined) {
        answer = {
          ...answer,
          answer_entry,
        }
      }

    } else if (questionItem.question_type === QUESTION_TYPE_DATE) {
      answer = {
        answer_date,
        answer_entry: '',
        question_id: questionItem.id,
      };

    } else if (questionItem.question_type === QUESTION_TYPE_FILE_UPLOAD) {
      if (Object.keys(answer_image).length > 0) {
        answer = {
          answer_entry: '',
          question_id: questionItem.id,
          answer_image,
        };
      } else {
        answer = {
          question_id: questionItem.id,
          answer_image: null,
        };
      }

    } else {
      answer = answer_entry !== null && answer_entry !== undefined ? {
        answer_entry,
        question_id: questionItem.id,
      } : {};
    }

    if (questionItem.question_type === QUESTION_TYPE_TEXT_AREA ||
      questionItem.question_type === QUESTION_TYPE_TEXT_FIELD ||
      questionItem.question_type === QUESTION_TYPE_NPS_REASON) {

      if (!!timeoutID.current) {
        clearTimeout(timeoutID.current)
      }
      
      timeoutID.current = setTimeout(() => {
        updateDoc({ answers_attributes: [answer] });
        validateQuestion();
      }, 500);

      // disable submit button for timeout time
      setCurrentPageErrors(prev => {
        const errors = {
          ...prev,
          [questionItem.id] : 'fake error',
        };
        
        return errors;
      });
    } else {
      updateDoc({ answers_attributes: [answer] });
      validateQuestion();
    }
  }
  
  const commonQuestionItemProps = {
    questionItem,
    onInputValue,
    errors: autovalidate ? errors || {} : {},
    answer: answer || {},
    key: `question_items-question-${surveyPage.page_number}-${questionIndex}`,
  };

  return (
    (questionItem.question_type === SURVEY_FORM_TYPE_SURVEY_HEADING) ?
      <div key={`question_items-${surveyPage.page_number}-${questionIndex}`}>
        <QuestionHead questionItem={questionItem} />
      </div>
      :
      <ThemeProvider theme={customTheme} key={`question_items-${surveyPage.page_number}-${questionIndex}`}>
        <div>
          {{
            [QUESTION_TYPE_SELECT]: (
              <QuestionTypeSelect {...commonQuestionItemProps} />
            ),
            [QUESTION_TYPE_RADIO_BUTTON]: (
              <QuestionTypeRadio {...commonQuestionItemProps} />
            ),
            [QUESTION_TYPE_SELECT_BUTTON]: (
              <QuestionTypeSelectButton {...commonQuestionItemProps} />
            ),
            [QUESTION_TYPE_CHECK_BOX]: (
              <QuestionTypeSelectCheckbox {...commonQuestionItemProps} />
            ),
            [QUESTION_TYPE_NPS_QUESTION]: (
              <QuestionTypeNPS {...commonQuestionItemProps} />
            ),
            [QUESTION_TYPE_DATE]: (
              <QuestionTypeDate {...commonQuestionItemProps} />
            ),
            [QUESTION_TYPE_NPS_REASON]: (
              <QuestionTypeText {...commonQuestionItemProps}/>
            ),
            [QUESTION_TYPE_TEXT_FIELD]: (
              <QuestionTypeTextInput {...commonQuestionItemProps}/>
            ),
            [QUESTION_TYPE_TEXT_AREA]: (
              <QuestionTypeText {...commonQuestionItemProps}/>
            ),
            [QUESTION_TYPE_FILE_UPLOAD]: (
              <QuestionTypeImageUpload {...commonQuestionItemProps} />
            ),
            [QUESTION_TYPE_SELECT_USER]: (
              <QuestionTypeSelectUser companyUrlText={companyUrlText} shortUrl={shortUrl} staff_id={staff_id} {...commonQuestionItemProps} />
            )
          }[questionItem.question_type]}
        </div>
      </ThemeProvider>
  )
}

QuestionItem.propTypes = propTypes;

export default connect(
  ({ public: { survey, currentPage, autovalidate }, public_ga }) => ({
    survey,
    currentPage,
    autovalidate,
    public_ga,
  }),
  (dispatch) => ({
    updateDoc: (params) => dispatch({ type: 'UPDATE_FEEDBACK_DOC', payload: { params } })
  })
)(QuestionItem);
