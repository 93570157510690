import {
  FETCH_BEGIN,
  FETCH_SURVEY_QUESTIONS,
  FETCH_QUESTION_AGGREGATION,
  FETCH_CROSS_AGGREGATION,
  FETCH_FAILURE,
  SET_QUESTION,
  REMOVE_OPTION,
  ADD_OPTION,
  SET_COLUMN_START_ID,
  FETCH_AGGREGATION_RESET,
} from './actions'
import { MAX_SELECTED_OPTIONS } from '../constants'

const initialState = {
  feedback_count: 0,
  nps_score: "0.0",
  promoters: "0.0",
  passives: "0.0",
  detractors: "0.0",
  questions: [],
  selectedQuestions: {},
  totalQuestion: [],
  totalCross: {
    page: 1,
  },
  loading: false,
  loaded: false,
  error: null,
}

export default function analysisReducer(state=initialState, action) {
  switch(action.type) {
    case FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      }
    case FETCH_SURVEY_QUESTIONS:
      return {
        ...destructurePayload(state, action, "questions"),
        selectedQuestions: {},
        totalQuestion: [],
        totalCross: initialState.totalCross,
      }
    case FETCH_QUESTION_AGGREGATION:
      return destructurePayload(state, action, "totalQuestion")
    case FETCH_CROSS_AGGREGATION:
      const { items, header={}, total, ...rest } = action.payload.data
      const selectableMax = Math.min((items || []).length, MAX_SELECTED_OPTIONS)
      const headerOptionKeys = Object.keys(header).filter(key => key !== 'label')
      const graph = []
      headerOptionKeys.forEach(columnKey => {
        const bar = {}
        items.forEach((item, i) => bar["bar" + i] = item['rate_' + columnKey])
        graph.push(bar)
      })
      return {
        ...state,
        ...rest,
        loading: false,
        loaded: true,
        totalCross: {
          header,
          headerOptionKeys,
          graph,
          total,
          items,
          page: initialState.totalCross.page,
          selectedOptions: [...Array(selectableMax).keys()],
        },
      }
    case SET_QUESTION:
      const { tag, question } = action.payload
      return {
        ...state,
        selectedQuestions: {
          ...state.selectedQuestions,
          [tag]: question,
        }
      }
    case ADD_OPTION:
      const option = action.payload.option
      const options = state.totalCross.selectedOptions || []
      if (options.length < MAX_SELECTED_OPTIONS && !options.includes(option)){
        return {
          ...state,
          totalCross: {
            ...state.totalCross,
            selectedOptions: options.concat([option]).sort()
          }
        }
      }
      return state
    case REMOVE_OPTION:
      return {
        ...state,
        totalCross: {
          ...state.totalCross,
          selectedOptions: state.totalCross.selectedOptions.filter(i => i !==  action.payload.option).sort()
        }
      }
    case SET_COLUMN_START_ID:
      return {
        ...state,
        totalCross: {
          ...state.totalCross,
          page: action.payload.page,
        }
      }
    case FETCH_FAILURE:
      return {
        ...initialState,
        error: action.payload.error,
        loading: false,
        loaded: true,
      }
    case FETCH_AGGREGATION_RESET:
      return {
        ...state,
        questions: [],
        selectedQuestions: {},
        totalQuestion: [],
        loading: false,
        loaded: false,
      }
    default:
      return state
  }
}

const destructurePayload = (state, action, property) => {
  let { items, ...others } = action.payload.data
  return {
    ...state,
    loading: false,
    loaded: true,
    [property]: items,
    ...others
  }
}
