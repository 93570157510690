import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const propTypes = {
  success: PropTypes.object,
  eror: PropTypes.object,
}

const useStyles = makeStyles({
  // to insert table style
  table: {
  },
  header: {
  },
  tableRow: {
  }
});

const ReportTable = ({ data, withHeader }) => {
  const { table, header, tableRow } = useStyles();

  if (!!!data || data.length === 0) {
    return <></>
  }

  return (
    <Paper>
      <Table className={table} size="small">
        {withHeader && Array.isArray(data[0]) && <TableHead className={header}>
          <TableRow className={tableRow}>
            {data[0].map((item, i) => <TableCell key={i}>{item}</TableCell>)}
          </TableRow>
        </TableHead>}
        <TableBody>
          {data.slice(withHeader ? 1 : 0).map((row, i) => (
            <TableRow key={i} className={tableRow}>
              {row.map((cell, j) => <TableCell key={j} component="th" scope="row">{cell}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

const parseError = error => {
  let errors = []
  if (error && +(error.status) === 422 && Array.isArray(error.detail)){
    errors = error.detail.map(item => [item[0]].concat(item[1]))
  }
  return errors
}

const ImportReport = ({ success, error }) => {
  const data = !!success ? success.preview_messages : parseError(error)
  const withHeader = !!success

  return <>
    {error && <div style={{ color: "red" }}>
      <p>{error.status} {error.statusText}</p>
    </div>}
    {success && <div style={{ color: "green" }}>
      <p>{success.count} {success.preview_messages.length > 0 ? "件をインポートします" : "件のインポートに成功しました"}</p>
    </div>}
    <ReportTable data={data} withHeader={withHeader}/>
  </>
}

ImportReport.propTypes = propTypes
export default ImportReport
