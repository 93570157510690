const initialState = {
  visibleDrawer: false,
  drawerWidth: window.innerWidth >= 1280 ? 240 : 98,
  isMobile: window.innerWidth < 640
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {

    case "TOGGLE_DRAWER": return { ...state, visibleDrawer: !state.visibleDrawer }

    case "TOGGLE_MENU_SIZE": return {
      ...state,
      drawerWidth: state.drawerWidth === 240 ? 98 : 240
    }

    case "UPDATE_WINDOW_SIZE": return {
      ...state,
      isMobile: action.payload.innerWidth < 640,
      drawerWidth: action.payload.innerWidth >= 1280 ? 240 : 98,
    }

    default:
      return state;
  }
};

export default commonReducer;
