import { API_URL } from '../../constants';

export const PAGINATION_PER_PAGE = 20;
export const API_MODEL_TYPE_USER = 'user';
export const USERS_API_PATH = `${API_URL}/users`;

export const GetAuthority = (authority) => {
  return (AUTHORITIES.find(item => item.value === authority) || {}).label
};

export const AUTHORITIES_FOR_ADMIN = [
  { value: 'staff', label: "スタッフ" },
  { value: 'manager', label: "店長" },
  { value: 'admin', label: "管理者" },
];

export const AUTHORITIES = [
  ...AUTHORITIES_FOR_ADMIN,
  { value: 'super_admin', label: "スーパー管理者" }
];

export const USER_LIST_COLUMN_NAMES = [
  { code: "is_active", label: "公開"},
  { code: "ucode", label: "スタッフ番号" },
  { code: "login_id", label: "ログインID" },
  { code: "name", label: "スタッフ名" },
  { code: "shop_names", label: "所属店舗" },
  { code: "authority", label: "操作権限" },
  { code: "viewing_target_names", label: "閲覧範囲" },
  { code: "is_personal_info_viewable", label: "権限" }
];
