export const surveyList = theme => ({
  loading: {
    width: "200px",
    height: "200px",
    margin: "0 auto",
    paddingTop: "75px",
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    background: '#fafafa',
  },
  table: {
    minWidth: 750,
    background: '#fff',
    margin: '35px 0',
    tableLayout: 'fixed',
    '& .MuiTableHead-root': {
      boxShadow: '0px 1px 3px #00000029',
      transform: 'scale(1)',
      '-webkit-transform': 'scale(1)',
      '-moz-transform': 'scale(1)'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  tableWrapper: {
    width: '100%',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    height: 64,
    background: '#f7f7f7',
    "&:hover": {
      backgroundColor: "#f7f7f7 !important"
    }
  },
  tableCellHeader: {
    background: '#F2EEEC',
    height: 36,
    fontSize: 12,
    letterSpacing: 0,
    color: '#1A0707',
    opacity: 1,
    borderBottom: 0,
    borderRight: '1px solid #B7B7B7',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    padding: '0 15px',
  },
  tableSelectedWrapper: {
    height: 64,
    width: 65,
    display: 'flex',
    alignItems: 'center',
    background: '#B7B7B7',
    position: 'relative',
    borderRadius: '5px 0 0 5px',
    marginTop: 10,
  },
  dateCell: {
    height: 64,
    display: 'flex',
    background: '#fff',
    borderTop: '1px solid #EFEFEF',
    borderRight: '1px solid #EFEFEF',
    borderBottom: '1px solid #EFEFEF',
    padding: '0 20px',
    fontSize: 12,
    marginTop: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  cellHeaderEnd: {
    borderRadius: '0 5px 0 0',
  },
  cellHeaderFirst: {
    borderRadius: '5px 0 0 0',
  },
  tableCellBody: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    borderTop: '1px solid #EFEFEF',
    borderRight: '1px solid #EFEFEF',
    borderBottom: '1px solid #EFEFEF',
    padding: '0 20px',
    fontSize: 12,
    marginTop: 10,
  },
  dateHourCell: {
    fontFize: 10,
    color: '#939393',
  },
  cellBodyEnd: {
    borderRadius: '0 5px 5px 0',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableSelected: {
    top: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '5px 0 0 5px',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  closeMark: {
    background: '#B7B7B7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
  },
  checkMark: {
    background: '#EC681A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
  },
  undoMark: {
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#b7b7b7',
    border: '1px solid #EFEFEF'
  },
  moreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '18px 40px 14px 30px',
    height: 64,
    [theme.breakpoints.down('md')]: {
      padding: '18px 13px 14px',
    },
    '& > .MuiButtonBase-root:nth-child(2)': {
      marginLeft: 10,
      height: 32,
      lineHeight: "32px",
      padding: "0 15px",
    },
    '& > .MuiButtonBase-root:nth-child(3)': {
      marginLeft: 10,
      border: "2px solid #F02544",
      '& i': {
        color: "#F02544",
      },
      '& .MuiButton-label': {
        color: "#F02544",
        font: "500 10px/24px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.25px"
      },
      '&:hover': {
        background: "#F2EEEC 0% 0% no-repeat padding-box",
      }
    },
  },
  button: {
    height: 32,
    lineHeight: "32px",
    padding: "0 15px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #939393",
    borderRadius: 5,
    '& i': {
      fontSize: 16,
      color: "#1A0707"
    },
    '& .MuiButton-label': {
      font: "400 12px/21px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.3px",
      color: "#1A0707"
    },
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 15
    },
    '&:hover': {
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      border: "1px solid #EC681A",
      '& i': {
        color: "#EC681A"
      },
      '& .MuiButton-label': {
        color: "#EC681A"
      }
    }
  },
  morePopover: {
    '& > div.MuiPopover-paper': {
      boxShadow: 'none',
      background: '#EFEFEF',
      borderRadius: 0,
    },
  },
  moreActive: {
    background: '#EFEFEF',
    '& >svg': {
      color: '#EC681A',
    },
  },
  addServeyButton: {
    background: "#EC681A",
    color: "#fff",
    width: 123,
    height: 36,
    border: "5px",
    "& span": {
      fontSize: 14,
      letterSpacing: "0.35px",
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    borderTop: '2px solid #EC681A',
    backgroundColor: "#FFFFFF",
    padding: 38,
    width: 600,
    height: 359,
  },
  modalDivider: {
    background: "#B7B7B7",
    height: 1,
    marginTop: 20,
  },
  modalBody: {
    marginTop: 20,
    padding: "0 5px"
  },
  modalHeaderLabel: {
    color: "#1A0707",
    fontSize: 14,
    letterSpacing: "0.35px",
  },
  formSection: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
    display: 'inline-block',
    '& label.anKetRadioButton:nth-child(1)': {
      marginLeft: 0,
    },
    '& label.anKetRadioButton:nth-child(2)': {
      marginLeft: 10,
    },
  },
  formLabel: {
    color: '#1A0707',
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 9,
    display: 'flex',
  },
  modalBtnFooter: {
    display: "flex",
    justifyContent: "center",
    "& button:nth-child(2)": {
      marginLeft: 10
    }
  },
  modalTextfield: {
    height: 36,
  },
  no_pd_bt: {
    padding: 0,
    borderBottom: 'none'
  },
  width_65: {
    width: 65,
  },
  width_102: {
    width: 102,
  },
  width_80: {
    width: 80,
  },
  size_24: {
    fontSize: 24,
  },
  printIcon: {
    "& i": {
      fontSize: 16,
      color: '#F02544',
      marginLeft: 15
    }
  },
  hideTextOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    lineHeight: '64px',
    textAlign: 'left',
  }
});

export const themeSurveyListToolsStyles = theme => ({
  buttonShowAcActive: {
    border: "1px solid #1A0707 !important",
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    "& >div": {
      border: "1px solid #000 !important",
      "& >div": {
        background: "#EC681A 0% 0% no-repeat padding-box",
        [theme.breakpoints.down("sm")]: {
          background: "#EC681A 0% 0% no-repeat padding-box !important",
        }
      },
    },
    "&:hover": {
      color: "#EC681A !important",
      border: "1px solid #EC681A !important",
      background:"#F2EEEC !important"
    },
  },
  buttonShowStore: {
    alignItems: "center",
    textAlign: "right",
    width: "auto",
    padding: "8px 15px",
    borderRadius: "5px",
    cursor: "pointer",
    font: "500 10px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.25px",
    color: "#1A0707",
    opacity: 1,
    display: "inline-block",
    background: "#EFEFEF 0% 0% no-repeat padding-box",
    border: "1px solid #B7B7B7",
    "&:hover": {
      width: "auto",
      border: "1px solid #EC681A",
      borderRadius: "5px",
      cursor: "pointer",
      color: "#fff",
      background:"#EC681A",
    },
    "&:hover .circle": {
      border: "1px solid #EC681A",
    },
    "& >div": {
      width: "16px",
      height: "16px",
      border: "1px solid #B7B7B7",
      borderRadius: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "inline-flex",
      marginRight: "15px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      Border: "1px solid #B7B7B7",
      opacity: 1,
      "& >div": {
        width: "8px",
        height: "8px",
        borderRadius: "100%",
        display: "inline-block",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center"
      }
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      float: "right",
      margin: "0px",
      padding: "5px",
      "& >div": {
        width: "16px",
        height: "16px",
        border: "1px solid #000",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        marginRight: "15px",
        "& >div": {
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center"
        }
      }
    }
  },
  buttonShowAc: {
    marginLeft: "10px",
    display: "inline-block",
    "& >div": {
      display: "inline-flex",
      alignItems: "center",
      textAlign: "right",
      border: "1px solid #F02544",
      padding: "8px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      font: "500 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#F02544",
      opacity: 1,
      "&:hover": {
        opacity: 0.6
      },
      "& >div": {
        width: "16px",
        height: "16px",
        border: "1px solid #F02544",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        marginRight: "15px",
        "& >div": {
          background: "#EC681A 0% 0% no-repeat padding-box",
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center"
        }
      }
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px 0px",
      textAlign: "right",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "right",
      "& >div": {
        padding: "5px",
        font: "500 10px/16px Noto Sans JP, Hiragino Sans",
        width: "auto",
        "&:hover": {
          opacity: 0.6
        },
        "& >div": {
          width: "14px",
          height: "14px",
          marginRight: "10px",
          "& >div": {
            width: "8px",
            height: "8px"
          }
        }
      }
    }
  },
  activeButtonArchive: {
    marginLeft: "10px",
    display: "inline-block",
    "& >div": {
      display: "inline-flex",
      alignItems: "center",
      textAlign: "right",
      border: "2px solid #EC681A",
      padding: "8px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      font: "500 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#fff",
      background: "#EC681A",
      opacity: 1,
      "&:hover": {
        opacity: 0.6
      },
      "& >div": {
        width: "16px",
        height: "16px",
        border: "3px solid #fff",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        marginRight: "15px",
        "& >div": {
          background: "#fff 0% 0% no-repeat padding-box",
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center"
        }
      },
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px 0px",
      textAlign: "center",
      "& >div": {
        padding: "5px",
        font: "500 10px/16px Noto Sans JP, Hiragino Sans",
        width: "auto",
        "&:hover": {
          opacity: 0.6
        },
        "& >div": {
          width: "14px",
          height: "14px",
          marginRight: "10px",
          "& >div": {
            width: "8px",
            height: "8px"
          }
        }
      }
    }
  },
  root: {
    position: "relative",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    flexGrow: 1,
    height: 52,
    borderRadius: 0,
    display: 'flex',
    margin: '20px 0',
  },
  left: {
    position: 'absolute',
    left: 0,
    width: 52,
    height: '100%',
    borderRight: '2px solid #F7F7F7',
  },
  leftIcon: {
    width: 16,
    height: 16,
    margin: 18,
    cursor: "pointer"
  },
  center: {
    height: '100%',
    paddingLeft: 54,
    paddingRight: 64,
    paddingTop: 7,
    paddingBottom: 7,
    display: 'flex',
  },
  selectBox: {
    width: 210,
    marginLeft: 20,
    marginRight: 0,
  },
  input: {
    '& input': {
      padding: '9px 12px',
      border: '1px solid #ccc',
      borderRadius: 4,
      width: 210,
      height: '38px',
      boxSizing: 'border-box',
      opacity: '1 !important',

      '&:-ms-input-placeholder': {
        color: '#9f9797 !important',
      },

      '&::-ms-clear': {
        display: 'none',
      },

      '&:hover': {
        borderColor: '#1A0707'
      },

      '&:focus': {
        border: '2px solid #EC681A',
      }
    },

    '& input[value]:not([value=""])': {
      border: '2px solid #EC681A !important',
    },

    '& fieldset': {
      display: 'none',
    }
  },
  radioArchive: {
    color: '#EC681A',
    '& svg': {
      width: '16px',
      height: '16px'
    }
  },
  radioArchivePrivate: {
    '& svg': {
      color: '#B7B7B7',
      width: '16px',
      height: '16px'
    },
    '& svg.PrivateRadioButtonIcon-layer-340': {
      color: '#FFFFF',
      width: '16px',
      height: '16px'
    }
  },
  radioArchiveActive: {
    color: '#FFFFFF !important',
    '& svg': {
      width: '16px',
      height: '16px'
    },
    '& svg.PrivateRadioButtonIcon-layer-340': {
      color: '#EC681A',
      width: '16px',
      height: '16px'
    }
  },
  radioArchiveActivePrivate: {
    '& svg': {
      color: '#000',
      width: '16px',
      height: '16px'
    },
    '& svg.PrivateRadioButtonIcon-layer-340': {
      color: '#EC681A',
      width: '16px',
      height: '16px'
    }
  },
  button: {
    fontSize: 10,
    width: 160,
    height: 32,
    justifyContent: 'left',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #1A0707',
    borderRadius: '5px',
    marginRight: '30px',
  },
  buttonDisable: {
    fontSize: 10,
    width: 160,
    height: 32,
    justifyContent: 'left',
    background: '#EFEFEF 0% 0% no-repeat padding-box',
    border: '1px solid #B7B7B7',
    borderRadius: '5px',
    marginRight: '30px',
  },
  buttonArchive: {
    fontSize: 10,
    width: 160,
    height: 32,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EC681A',
    borderRadius: '5px',
  },
  buttonArchiveActive: {
    backgroundColor: '#EC681A',
    width: 160,
    height: 32,
    justifyContent: 'left',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EC681A',
    borderRadius: '5px',
  },
  right: {
    position: 'absolute',
    right: 60,
    top: 10,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "grid",
    }
  },
  buttonLabel: {
    fontSize: 10,
  },
  labelArchive: {
    fontSize: 10,
    color: '#EC681A',
  },
  labelArchiveActive: {
    color: '#FFFFFF',
    fontSize: 10,
  },
  searchButton: {
    '& > span:first-of-type': {
      fontSize: 10,
      margin: '3px 5px',
      width: 32,
      height: 32,
      border: '1px solid #939393',
      borderRadius: '5px',
      cursor: "pointer",
      "& i": {
        fontSize: "20px"
      },
      "&:hover": {
        background: "#EC681A 0% 0% no-repeat padding-box",
        border: "1px solid #EC681A",
        "& i": {
          color: "white"
        },
      },
      "&:active": {
        opacity: 0.8
      }
    },

    '& > span:last-of-type': {
      display: 'none',
    },

    background: "none !important",
  },
  h30: {
    height: 30,
  }
});

export const surveyDetailStyles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: 360,
    padding: '30px 20px',
    backgroundColor: '#F7F7F7',
    '& .MuiBox-root': {
      background: '#EFEFEF'
    }

  },
  loadingRoot: {
    width: "100%",
    opacity: 0.5,
    overflowX: "auto",
    position: 'absolute',
    top: '0px'
  },
  topContent: {
    '& > hr': {
      color: '#B7B7B7',
      margin: '20px 0'
    },
    '& > div': {
      padding: '0 20px',
      fontFamily: "'Noto Sans JP', sans-serif !important",
      fontWeight: 'bold'
    }
  },
  loading: {
    width: "500px",
    height: "1000px",
    margin: "auto auto",
    padding: "225px",
  },
  indicator: {
    backgroundColor: "#EC681A",
    height: 0,
    color: "#EC681A",
  },
  anketTabDetail: {
    boxShadow: 'none',
    background: '#F7F7F7',
    '& span.MuiTab-wrapper': {
      fontSize: 12,
      letterSpacing: 0.3,
    },
    '& > div > div': {
      width: 'fit-content',
    },
    '& button.Mui-selected': {
      backgroundColor: 'white'
    },
    '& button.Mui-selected span.MuiTab-wrapper': {
      fontSize: 12,
      color: '#EC681A',
    },
    "& .Mui-selected": {
      color: "#EC681A",
      backgroundColor: "white",
      borderBottom: "4px solid #EC681A"      
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent"
    }
  },
  tabContainer: {
    background: "#EFEFEF"
  },
  tabHeader: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 3px 6px #B7B7B729',
    padding: '5px 10px',
    borderRadius: 0,
    marginBottom: '20px',
    '& button': {
      marginRight: 10
    }
  },
  left: {
    position: 'absolute',
    left: 5,
    top: 10,
    width: 34,
    borderRight: '2px solid #F7F7F7',
  },
  leftIcon: {
    fontSize: 24,
    marginRight: 14,
  },
  linkButton: {
    color: '#EC681A',
    padding: '0 20px',
    marginLeft: 34,
    textDecoration: 'underline',
  },
  buttonFill: {
    color: 'white',
    background: '#EC681A',
    '&:hover': {
      color: 'white',
      background: '#EC681A',
    }
  },
  buttonOutline: {
    color: '#EC681A',
    background: 'white',
    border: '1px solid #EC681A'
  },
  buttonGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: 100,
    alignItems: 'center',
    '& > button': {
      height: 36,
      width: 125,
      fontSize: 14,
      padding: '3px 10px',
      marginLeft: 15,
      marginRight: 15,
    }
  },
  icons: {
    fontSize: 24,
    margin: 0,
  },
  largeIcon: {
    width: 20,
    height: 20,
    margin: 0,
  },
  formField: {
    marginLeft: 35
  },
  formLabel: {
    color: '#1A0707',
    fontWeight: 'bold',
    fontSize: 12,
    fontFamily: "'Noto Sans JP', sans-serif !important",
    marginBottom: 9,
    display: 'flex',
  },
  formSection: {
    width: '100%',
    '& label.anKetRadioButton:nth-child(1)': {
      marginLeft: 0,
    },
    '& label.anKetRadioButton:nth-child(2)': {
      marginLeft: 10,
    },
    '& p': {
      margin: '0 0 15px'
    }
  },
  formInputBase: {
    height: 42,
    width: '100%',
    padding: '14px 0',
    display: 'flex',
    marginBottom: 15,
    '& > input': {
      font: "500 12px/18px 'Noto Sans JP', sans-serif !important",
      boxSizing: 'border-box',
      height: 42
    }
  },
  tab: {
    color: "#B7B7B7",
    borderBottom: "4px solid #B7B7B7",
    font: "12px/21px Noto Sans JP, Hiragino Sans",
    "&:hover": {
      backgroundColor: "white",
      borderBottom: "4px solid #939393",
      color: "#939393"
    }
  },
  tabActived: {
    backgroundColor: "white",
    borderBottom: "4px solid #EC681A",
    font: "12px/21px Noto Sans JP, Hiragino Sans",
    "&:hover": {
      backgroundColor: "white",
      borderBottom: "4px solid #939393",
      color: "#939393"
    }
  },
  iconInput: {
    position: 'absolute',
    right: 10,
    fontSize: 20,
    color: '#ccc',
    top: 37
  },
  snackbarAnchor: {
    right: "0 !important"
  },
  snackbarRoot: {
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    boxShadow: "-2px 3px 6px #B7B7B799",
    borderLeft: "solid 4px #EC681A",
    padding: "32px 26px !important"
  },
  snackbarRootError: {
    borderLeft: "solid 4px #F02544",
  },
  snackbarContainer: {
    display: "flex",
    alignItems: "center", 
    width: "100%",
  },
  checkIcon: {
    color: "#EC681A",
    fontSize: 32
  },
  errorIcon: {
    color: "#F02544",
    fontSize: 32
  },
  closeIcon: {
    color: "#939393",
    fontSize: 14,
    cursor: "pointer",
    marginLeft: 20
  },
  snackbarRightContent: {
    marginLeft: 20,
    paddingRight: 20,
    borderRight: "solid 1px #F2EEEC"
  },
  labelTitle: {
    font: "400 16px/27px 'Noto Sans JP', sans-serif",
    letterSpacing: "1.92px",
    color: "#1A0707",
    margin: "0"
  },
  labelContent: {
    font: "400 12px/27px 'Noto Sans JP', sans-serif",
    letterSpacing: "1.44px",
    color: "#796255",
    margin: 0
  }
});

export const surveyDetailHeaderStyles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
  },
  previewBtn: {
    color: "white",
    backgroundColor: "#B7B7B7",
    padding: '8px 20px',
    marginRight: 10,
  },
  savedBtn: {
    color: "#EC681A",
    backgroundColor: "white",
    padding: '8px 20px'
  },
  txtName:{
    display: "flex",
    position: "relative",
    padding: "0 10px 0 0",
    alignItems: "center",
    width: "255px",
    justifyContent: "space-between",
    "& > *": {
      width: "inherit"
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& input":{
      border: "none",
      font: "400 14px/24px Noto Sans JP, Hiragino Sans",
      color: "#1A0707",
      letterSpacing: "0.35px",
      width: "100%"
    },
    "& input::-webkit-input-placeholder":{
      color: "#B7B7B7",
      letterSpacing: "0.35px",
    },
    "& .buttonShow":{
      border: 0,
      margin: 0,
      padding: 0,
      width: "auto",
      outline: "none",
      background: "transparent",
      marginLeft: "5px",
      top: 0,
      left: 0,
      "& i":{
        fontSize: "20px",
        color: "#B7B7B7",
        top: 0,
        float: "right",
        position: "relative"
      }
    },
    "& .btnClose":{
      border: 0,
      margin: 0,
      padding: 0,
      width: "auto",
      outline: "none",
      background: "transparent",
      position: "absolute",
      right: 0,
      "& i":{
        fontSize: "20px",
        color: "#B7B7B7",
        marginLeft: "50px",
        cursor: "pointer",
        top: 0,
        float: "right",
        right: "-10px",
        position: "relative"
      }
    }
  },
});
export const QRCodeModalStyles = theme => ({
  buttonDown: {
    font: "400 12px/21px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #939393",
    borderRadius: "5px",
    opacity: 1,
    padding: "8px 15px",
    cursor: "pointer",
    "& >i": {
      fontSize: "16px",
      marginRight: "15px"
    },
    "&:hover": {
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      border: "1px solid #EC681A",
      borderRadius: "5px",
      opacity: 1,
      color: "#EC681A"
    }
  },
  opacity05:{
    opacity: 0.5
  },
  buttonDel: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #F02544",
    borderRadius: "5px",
    font: "500 10px/24px Noto Sans JP, Hiragino Sans",
    letterSpacing: " 0.25px",
    color: "#F02544",
    opacity: 1,
    padding: "8px 15px",
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    "& >i": {
      fontSize: "16px",
      marginRight: "15px"
    },
    "&:hover": {
      background: "#F2EEEC 0% 0% no-repeat padding-box"
    }
  },
  buttonEdit: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #939393",
    borderRadius: "5px",
    padding: "8px 15px",
    font: "400 12px/21px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    opacity: 1,
    /* display: flex; */
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
    marginRight: "10px",
    cursor: "pointer",
    "& >i": {
      fontSize: "16px",
      marginRight: "15px"
    },
    "&:hover": {
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      border: "1px solid #EC681A",
      borderRadius: "5px",
      opacity: 1,
      color: "#EC681A"
    }
  },
  QrCodeModal: {
    "& >.underlinedTop": {
      background: "#EC681A 0% 0% no-repeat padding-box",
      height: "2px"
    },
    "& >.QrCodeModalContent": {
      padding: "22px 30px",
      "& >.header": {
        borderBottom: "1px solid #B7B7B7",
        "& >p": {
          font: "600 14px/21px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.35px",
          color: "#1A0707",
          opacity: 1,
          display: "inline-block"
        },
        "& >button": {
          background: "#B7B7B7 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          font: "600 10px/24px Noto Sans JP, Hiragino Kaku Gothic Pro",
          letterSpacing: "0.25px",
          color: "#FFFFFF",
          opacity: 1,
          padding: "8px 20px",
          float: "right",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "& >i": {
            fontSize: "20px",
            marginLeft: "10px"
          },
          "&:hover":{
            background: "#EC681A 0% 0% no-repeat padding-box",
            color: "#FFF",
            border: "1px solid #EC681A"
          }
        }
      },
      "& >.blockDownload": {
        borderTop: "1px solid #B7B7B7",
        padding: "20px"
      },
      "& >.content": {
        display: "flex",
        padding: "20px 32px 20px 20px",
        "& >.qrCode": {
          display: "inline-block",
          background: "#F7F7F7 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          padding: 24,
          maxHeight: 153
        },
        "& >.url": {
          marginLeft: 30,
          "& >p": {
            font: "600 12px/21px Noto Sans JP, Hiragino Sans",
            letterSpacing: "0.3px",
            color: "#1A0707",
            opacity: 1,
            margin: 0
          },
          "& >.link": {
            font: "400 12px/21px Noto Sans JP, Hiragino Sans",
            letterSpacing: "0.3px",
            color: "#EC681A",
            opacity: 1,
            margin: 0
          },
          "& >div": {
            marginTop: 20,
            display: "flex"
          },
          "& >.blockEdit": {
            marginTop: 20,
            display: "block",
            "& >.blockInput": {
              "& >p": {
                font: "300 10px/21px Noto Sans JP, Hiragino Sans",
                letterSpacing: "0.25px",
                color: "#1A0707",
                opacity: 1
              },
              "& >div": {
                width: "100%",
                "& >div": {
                  "& >input": {
                    padding: "9px 15px",
                    font: "400 12px/21px Noto Sans JP, Hiragino Sans",
                    letterSpacing: "0.3px",
                    color: "#1A0707",
                    opacity: 1
                  }
                }
              }
            },
            "& >.blockDel": {
              marginTop: 10
            }
          }
        }
      }
    }
  },
  shopName: {
    font: "600 12px/21px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#1A0707",
    opacity: 1,
    margin: 0,
    marginLeft: 30,
  },
  feedbackLink: {
    font: "400 12px/21px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.3px",
    color: "#EC681A",
    opacity: 1,
    margin: 0,
    marginLeft: 30,
  }
})
