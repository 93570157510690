import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import NewItemModal from 'components/NewItemModal';
import SurveyDetail from './SurveyDetail';
import { UPDATE_SURVEY_ITEM } from "./redux/actions";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  addBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
  modalRoot: {
    position: 'absolute',
    width: 600,
    [theme.breakpoints.down('md')]: {
      width: '95%'
    }
  }
}));

const propTypes = {
  itemSubmitSuccess: PropTypes.bool.isRequired,
  updateSurveyItem: PropTypes.func.isRequired,
};

const SurveyAdd = ({
  itemSubmitSuccess,
  updateSurveyItem,
}) => {
  const classes = useStyles();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (itemSubmitSuccess) {
      setModalIsOpen(false);
    }

    // eslint-disable-next-line
  }, [itemSubmitSuccess]);

  const onOpenCreateModal = () => {
    updateSurveyItem({});
    setModalIsOpen(true);
  }

  return (
    <>
      <div className={classes.root}>
        <Button
          variant="contained"
          className={classes.addBtn}
          startIcon={<AddIcon />}
          onClick={onOpenCreateModal}
        >
          新規追加
        </Button>
      </div>
      <NewItemModal onClose={() => setModalIsOpen(false)} open={modalIsOpen} className={classes.modalRoot}>
        <SurveyDetail isCreateAction closeModal={() => setModalIsOpen(false)} />
      </NewItemModal>
    </>
  );
};

SurveyAdd.propTypes = propTypes;

export default connect(
  ({
    surveys: { modalOpened, itemSubmitSuccess },
  }) => ({
    modalOpened,
    itemSubmitSuccess,
  }),
  dispatch => ({
    updateSurveyItem: (item) => dispatch({ type: UPDATE_SURVEY_ITEM, payload: { item } } ),
  })
)(SurveyAdd);
