export const styles = {
  root: {
    borderTop: '4px solid #EC681A'
  },
  btnOk: {
    backgroundColor: "#EC681A",
    color: "white",
    minWidth: "120px"
  },
  btnClose: {
    border: "1px solid #EC681A",
    color: "#EC681A",
    minWidth: "120px"
  },
  btnContainer: {
    margin: "40px auto"
  },
  title: {
    marginBottom: "40px",
    borderBottom: " 1px solid #B7B7B7",
    padding: "10px 10px 20px 10px",
    fontWeight: "bold",
    font: "600 14px/21px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.35px",
    color: "#1A0707",
    opacity: 1
  },
  input: {
    "& >div":{
        "& >input":{
          font: "500 12px/18px Noto Sans JP, Hiragino Sans",
          letterSpacing: 0,
          color: "#1A0707",
          opacity: 1,
          "&::-webkit-input-placeholder": { /* Chrome/Opera/Safari */
            color: "#B7B7B7",
          },
          "&::-moz-placeholder": { /* Firefox 19+ */
            color: "#B7B7B7",
          },
          "&:-ms-input-placeholder": { /* IE 10+ */
            color: "#B7B7B7",
          },
          "&:-moz-placeholder": { /* Firefox 18- */
            color: "#B7B7B7"
          }
        },
        "& >div":{
          "& >i":{
            color: "#B7B7B7"
          }
        }
    },
    padding: "0 10px",
  }
};
