export const themeRankStyles = theme => ({
  mobileContainer: {
    backgroundColor: "#F7F7F7",
    padding: '5px 5px 0 5px'
  },
  tabs: {
    width: '100%',
    marginBottom: 10,
    marginTop: 20,
    '& .Mui-selected': {
      backgroundColor: 'white',
      borderBottom: '4px solid #EC681A',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
  },
  tab: {
    color: '#ABABAB',
    borderBottom: '4px solid #B7B7B7',
    fontSize: 12,
    fontWeight: 600,
    minWidth: '50%',
    '&:hover': {
      backgroundColor: 'white',
      borderBottom: '4px solid #939393',
      color: '#939393',
    },
    [theme.breakpoints.down('xs')]: {
      '&:hover': {
        borderBottom: '4px solid #B7B7B7',
        color: '#B7B7B7',
      },
    },
  },
  tabActive: {
    color: '#6c6b6b',
    fontSize: 12,
    fontWeight: 600,
    backgroundColor: 'white',
    borderBottom: '4px solid #EC681A',
    minWidth: '50%',
  },
})
