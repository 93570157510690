import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import {
  Radio,
  FormControlLabel,
  makeStyles
} from '@material-ui/core';
import QuestionList from './QuestionList';
import ImportDialog from 'common/csv/ImportDialog';
import { thunkFetchSurvey } from 'modules/surveys/redux/thunk';
import { thunkExportItems } from 'common/csv/redux/thunk';
import { ExportButton, ImportButton } from "common/csv/CsvButton";
import { themedQuestionsStyles } from "./styles"

const useStyles = makeStyles(themedQuestionsStyles);

const propTypes = {
  surveyId: PropTypes.string,
  fetchSurvey: PropTypes.func.isRequired,
  exportCsv: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
}

const Questions = ({
  surveyId,
  fetchSurvey,
  exportCsv,
  errors,
  area,
}) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={classes.root}>
      <div className={classes.headerButton}>
        <div className={classes.buttonGroup}>
          <FormControlLabel
            className={classes.anketRadioButton}
            control={
              <Radio
                checked={showAll}
                onClick={toggleShowAll}
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label="非公開の質問を表示"
            labelPlacement="end"
          />
        </div>
        <div className={classes.buttonGroup}>
          <ExportButton text="回答ページエクスポート" onClick={() => exportCsv(surveyId, { showAll })} />
          <ImportDialog path={`surveys/${surveyId}/questions`} onSuccess={() => fetchSurvey(surveyId)} title='回答ページインポート（CSV）'>
            <ImportButton text="回答ページインポート（CSV）"/>
          </ImportDialog>
        </div>
      </div>

      <QuestionList errors={errors} showAll={showAll} area={area}/>
    </div>
  )
}

Questions.propTypes = propTypes;

export default connect(
  ({ surveys: { item: { id } } }) => ({
    surveyId: id,
  }),
  dispatch => ({
    fetchSurvey: surveyId => dispatch(thunkFetchSurvey(surveyId)),
    exportCsv: (surveyId, { showAll=true }) => {
      dispatch(thunkExportItems(`surveys/${surveyId}/questions`, showAll ? { status: "with_inactive" } : {}));
    },
  }),
)(Questions);
