import styled from 'styled-components'

export const LimitedLines = styled.div`
  overflow-y: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  max-width: 100%;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.line || 1};
  -webkit-box-orient: vertical;
`

export const customStyles = theme => ({
  warningContainer: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down('xs')]: {
      left: 0,
    },
  },
  warningContentBox: {
    maxWidth: 700,
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    flexDirection: 'column',
    margin: 32,
    position: 'relative',
    overflowY: 'auto',
    color: '#1A0707',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#fff',
    borderRadius: 4,
  },
})
