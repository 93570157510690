import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { CircularProgress, Box, Grid, Paper } from '@material-ui/core';
import Pagination from 'components/Pagination';
import { PAGINATION_PER_PAGE } from '../constants';
import { makeStyles } from '@material-ui/core/styles';
import { themedStyles } from "./styles";
import { useGAEffect } from '../../../ga';


const useStyles = makeStyles(themedStyles);

const propTypes = {
  itemSubmitSuccess: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  items: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  ga: PropTypes.object.isRequired,
}

const ShopList = ({
  itemSubmitSuccess,
  pagination,
  items,
  loading,
  page,
  setPage,
  openModal,
  ga,
}) => {
  const classes = useStyles();

  const handleClick = (v) => {
    setPage(v)
  };

  useGAEffect(ga, null, "店舗管理")

  if (loading) {
    return (
      <Paper className={classes.root}>
        <div className={classes.loading}>
          <CircularProgress disableShrink />
        </div>
      </Paper>
    );
  }

  return (
    <div className={classes.root}>
       <Grid item>
        <Box display={{ sm: "block", xs: "block", md: "block", lg: "block" }}>
          <Grid container item className={classes.thTable} sm={12} md={12}>
            <Grid sm={1} md={1} xs={1} className={classes.clNumber} item>
              <Box className={classes.Thborder}>公開</Box>
            </Grid>
            <Grid sm={2} md={2} xs={2} item className={classes.clShop}>
              <Box className={classes.Thborder}>店舗コード</Box>
            </Grid>
            <Grid sm={3} md={3} xs={3} item className={classes.clBody}>
              <Box className={classes.Thborder}>店舗名</Box>
            </Grid>
            <Grid sm={2} md={2} xs={2} item className={classes.clDate}>
              <Box className={classes.Thborder}>所属エリア</Box>
            </Grid>
            <Grid sm={2} md={2} xs={2} item className={classes.clDate}>
              <Box className={classes.Thborder}>所属グループ</Box>
            </Grid>
            <Grid sm={2} md={2} xs={2} item className={classes.clDate}>
              <Box className={clsx(classes.Thborder, classes.noneBorder)}>スタッフ数</Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {(items || []).map(item => (
        <Grid
          container
          item
          className={classes.tdTable}
          sm={12}
          display={{ sm: "none", xs: "none", md: "block", lg: "block" }}
          key={item.id} onClick={() => {openModal(item)}}
        >
          <Grid sm={1} xs={1} md={1} item className={item.active ? classes.trNumberActived : classes.trNumber}>
            <Box className={`${classes.Tdborder}`}>
              {item.active ? (
                <i className="material-icons">check</i>
              ) : (
                <i className="material-icons">close</i>
              )}
            </Box>
          </Grid>
          <Grid sm={2} xs={2} md={2} item className={classes.paddingCollum}>
            <Box className={classes.Tdborder}>{item.uid}</Box>
          </Grid>
          <Grid sm={3} md={3} xs={3} className={classes.paddingCollum} item>
            <Box className={classes.Tdborder}>{item.name}</Box>
          </Grid>
          <Grid sm={2} xs={2} md={2} className={classes.paddingCollum} item>
            <Box className={classes.Tdborder}>{item.areas}</Box>
          </Grid>
          <Grid sm={2} xs={2} md={2} className={classes.paddingCollum} item>
            <Box className={classes.Tdborder}>{item.groups}</Box>
          </Grid>
          <Grid sm={2} xs={2} md={2} className={classes.paddingCollum} item>
            <Box className={classes.Tdborder}>{item.user_count}</Box>
          </Grid>
        </Grid>
      ))}
      <Pagination
        activePage={+page}
        itemsCountPerPage={+pagination.perPage}
        pageRangeDisplayed={pagination.offset}
        totalItemsCount={+pagination.total}
        offset={pagination.offset}
        onChange={handleClick}
      /> 
    </div>
  );
};

ShopList.propTypes = propTypes;

export default connect(
  ({ shops: { itemSubmitSuccess, items, loading, itemsLoadingError, pagination }, ga }) => ({
    itemSubmitSuccess,
    items,
    loading,
    itemsLoadingError,
    pagination: !!pagination
      ? pagination
      : {
        offset: 0,
        total: PAGINATION_PER_PAGE,
        perPage: PAGINATION_PER_PAGE,
      },
    ga
  }),
)(ShopList);
