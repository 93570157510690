import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import LikeIcon from '../../common/icon/LikeIcon'
import { getNpsColor, sign } from './utils'
import ElementIcon from '../../common/icon/ElementIcon'

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 100,
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      paddingTop: 5,
    },
  },
  item: {
    flex: 1,
    fontSize: '10px',
    textAlign: 'center',
    '& div>svg>path': {
      fill: '#EC681A',
    },
  },
  number: {
    font: '26px Noto Sans JP',
    fontWeight: 'bold',
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 27,
      margin: '0 5px',
    },
  },
  text: {
    font: '500 12px/14px Noto Sans JP, Hiragino Sans',
    fontWeight: 'W6',
    color: '#ABABAB',
    margin: '5px 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  delta: {
    font: '14px Noto Sans JP',
    fontWeight: 'bold',
    color: '#1FA371',
  },
}))

const LegendText = ({ text, icon, value, delta }) => {
  const classes = useStyles()
  return (
    <div className={classes.item}>
      <div>
        {icon}
        <span className={classes.number}>{value}</span>
      </div>
      <div className={classes.text}>{text}</div>
      <div className={classes.delta} style={{ color: getNpsColor(delta >= 0) }}>
        {sign(delta)}
      </div>
    </div>
  )
}

const LikeInfo = ({
  likes_count,
  comment_likes_count,
  comments_count,
  delta_likes_count,
  delta_comment_likes_count,
  delta_comments_count,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <LegendText
        icon={<LikeIcon size={30} />}
        text="フィードバックいいね"
        value={likes_count}
        delta={delta_likes_count}
      />
      <LegendText
        icon={<LikeIcon size={30} />}
        text="コメントいいね"
        value={comment_likes_count}
        delta={delta_comment_likes_count}
      />
      <LegendText
        icon={<ElementIcon style={{ color: '#EC681A', fontSize: 34 }} name={`chat-dot-round`} />}
        text="コメント"
        value={comments_count}
        delta={delta_comments_count}
      />
    </div>
  )
}

export default connect(
  ({ dashboard: { nps_satisfactions } }) => ({ ...nps_satisfactions }),
  null
)(LikeInfo)
