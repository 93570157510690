import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore, compose } from "redux";
import { Provider } from "react-redux";
import thunk from 'redux-thunk';
import * as serviceWorker from "./serviceWorker";

import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';//Main MaterialUI theme Settings
import "./index.css";
import App from "./App";
import rootReducer from "./redux/reducers";
import './axiosInterceptors';
import { USE_DEBUG } from './constants';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

//  USE_DEBUGは環境変数REACT_APP_DEBUGを参照
// const middlewares = applyMiddleware(thunk)

const middlewares = USE_DEBUG === 'true' ? 
    applyMiddleware(thunk)
  :
    applyMiddleware(thunk)

// rootにあたるグローバルなstore定義
const store = createStore(
  rootReducer,
  composeEnhancers(middlewares),
);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
