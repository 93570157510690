export const FETCH_SHOPS_BEGIN   = 'FETCH_SHOPS_BEGIN';
export const FETCH_SHOPS_SUCCESS = 'FETCH_SHOPS_SUCCESS';
export const FETCH_SHOPS_FAILURE = 'FETCH_SHOPS_FAILURE';
export const FETCH_ALL_SHOPS_BEGIN   = 'FETCH_ALL_SHOPS_BEGIN';
export const FETCH_ALL_SHOPS_SUCCESS = 'FETCH_ALL_SHOPS_SUCCESS';
export const FETCH_ALL_SHOPS_FAILURE = 'FETCH_ALL_SHOPS_FAILURE';
export const OPEN_SHOP_FORM_MODAL = 'OPEN_SHOP_FORM_MODAL';
export const CLOSE_SHOP_FORM_MODAL = 'CLOSE_SHOP_FORM_MODAL';

export const SAVE_SHOP_BEGIN = 'SAVE_SHOP_BEGIN';
export const SAVE_SHOP_SUCCESS = 'SAVE_SHOP_SUCCESS';
export const SAVE_SHOP_FAILURE = 'SAVE_SHOP_FAILURE';
export const UPDATE_SHOP_ITEM = "UPDATE_SHOP_ITEM";
export const CHANGE_SHOP_ITEM_STATE = "CHANGE_SHOP_ITEM_STATE";

export const FETCH_SHOPS_RESET = 'FETCH_SHOPS_RESET';
