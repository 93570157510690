
export const parseGroup = ({ id, name, display_order, shops, children, parentId, parentName }, withShops=false) => ({
  id,
  name,
  display_order,
  shops: withShops ? shops.data || [] : (shops.data || []).map(({ id }) => id),
  children,
  parentId,
  parentName,
});

export const parseGroupWithShopForSelect = ({ id, name, display_order, shops, children, parentId, parentName, checkedIds, hideGroupSelector }) => ({
  type: 'group',
  label: name,
  value: +id,
  display_order,
  disabled: hideGroupSelector,
  children: [
    ...(shops || []).map(shop => {
      const checked = checkedIds
        .filter(item => item.type === 'shop' && (+item.owner_group_id === +id || (!!!item.owner_group_id && !visitedNodes.includes(+shop.id))))
        .map(item => +item.value)
        .includes(+shop.id);

      if (checked) {
        visitedNodes.push(+shop.id);
      }

      return {
        type: "shop",
        owner_group_id: +id,
        label: shop.attributes.name,
        value: +shop.id,
        checked,
        disabled: false
      }
    }),
    ...children
  ],
  parentId,
  parentName,
  checked: checkedIds.filter(item => item.type === 'group').map(item => +item.value).includes(+id),
  expanded: 'expanded',
});

export const parseGroupForSelect = ({ id, name, display_order, children, parentId, parentName, checkedIds }) => ({
  label: name,
  value: id,
  display_order,
  children,
  parentId,
  parentName,
  checked: checkedIds.includes(id),
});

export const getGroupFromIncluded = ({ id, included }, parseFunc=parseGroup) => {
  let itemData = null;
  
  for (let item of included) {
    if (item.id === id) {
      itemData = item;
      break;
    }
  }

  return itemData === null ? {} : parseFunc(itemData);
};

let visitedNodes = [];

export const parseTreeForSelect = (allItems, checkedIds, { withShops = false, hideGroupSelector = false }) => {
  visitedNodes = [];
  const parser = withShops ? traverseParseGroupWithShopsForSelect : traverseParseForSelect;
  return [...allItems].map(item => parser(item, null, 'root', checkedIds, hideGroupSelector));
};

export const traverseParseForSelect = (parentBranch, parentBranchId, parentName, checkedIds) => {
  parentBranch.children = parentBranch.children.map(branch =>
    traverseParseForSelect(branch, parentBranch.id, parentBranch.name, checkedIds)
  );
  return parseGroupForSelect({ ...parentBranch, parentId: parentBranchId, parentName: parentName, checkedIds });
};

export const traverseParseGroupWithShopsForSelect = (parentBranch, parentBranchId, parentName, checkedIds, hideGroupSelector) => {
  parentBranch.children = parentBranch.children.map(branch =>
    traverseParseGroupWithShopsForSelect(branch, parentBranch.id, parentBranch.name, checkedIds, hideGroupSelector)
  );
  return parseGroupWithShopForSelect({ ...parentBranch, parentId: parentBranchId, parentName, checkedIds, hideGroupSelector });
};

export const traverseParse = (parentBranch, parentBranchId, parentName, withShops=false) => {
  parentBranch.children = parentBranch.children.map(branch =>
    traverseParse(branch, parentBranch.id, parentBranch.name, withShops)
  );
  return parseGroup({ ...parentBranch, parentId: parentBranchId, parentName }, withShops);
};

export const traverseFind = (groups, checkedIds) => {
  return groups.map(item => {
    let results = [];

    if (checkedIds.map(groupId => +groupId).includes(+item.id)) {
      results.push(item);
    }

    const foundChildren = traverseFind(item.children, checkedIds);
    
    if (foundChildren.length > 0) {
      results = results.concat(foundChildren);
    }

    return results;
  }).filter(el => el != null).flat()
}
