import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Snackbar, Slide } from '@material-ui/core';
import clsx from 'clsx';
import { themedStyles } from './styles';
import { PUBLIC_SURVEY_NEXT_PAGE, PUBLIC_SURVEY_PREV_PAGE } from 'modules/public/redux/actions';


const colorComon = "#EC681A";
const useStyles = makeStyles(themedStyles);

const propTypes = {
  goToNextPage: PropTypes.func.isRequired,
  goToPrevPage: PropTypes.func.isRequired,
  setToAutovalidate: PropTypes.func.isRequired,
  currentPageErrors: PropTypes.object.isRequired,
  autovalidate: PropTypes.bool.isRequired,
};

const ProgressionButtons = ({
  goToNextPage,
  goToPrevPage,
  setToAutovalidate,
  currentPageErrors,
  autovalidate,
}) => {
  const hasErrors = Object.keys(currentPageErrors).length > 0;
  const [visible, setVisible] = useState(false);
  const [transition, setTransition] = useState(null);

  const styleProps = { color: colorComon };
  const classes = useStyles(styleProps);

  const TransitionRight = props => {
    return <Slide {...props} direction="left" />;
  }

  const handleNotification = () => {
    setTransition(() => TransitionRight);
    setVisible(true);
  }

  const closeSnackbar = () => setVisible(false);

  const contentSnackbar = (
    <div className={classes.snackbarContainer}>
      <span className={classes.errorIcon}><i className="el-icon-close"></i></span>
      <div className={classes.snackbarRightContent}>
        <p className={classes.labelTitle}>入力内容に不備があります。</p>
      </div>
      <span className={classes.closeIcon} onClick={closeSnackbar}><i className=" el-icon-close"></i></span>          
    </div>
  );

  const validateQuestions = () => {
    if (hasErrors) {
      handleNotification();
      setToAutovalidate();
    }

    if (!hasErrors) {
      goToNextPage();
    }
  };

  return (
    <>
      <div className={classes.buttonFixed}>
        <button
          className={classes.buttonHandlePrev}
          onClick={goToPrevPage}>
          <i className="fa fa-caret-left"></i>
          前のページへ
        </button>
        <button
          className={classes.buttonHandleNext}
          style={autovalidate && hasErrors ? {background: "#B7B7B7 0% 0% no-repeat padding-box"}: {}}
          onClick={() => { (!autovalidate || (autovalidate && !hasErrors)) && validateQuestions(true) }}
        >
          <i className="fa fa-caret-right"></i>
          次のページへ
        </button>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        key="bottom_right"
        open={visible}
        ContentProps={{
          classes: {
            root: clsx(classes.snackbarRoot, classes.snackbarRootError)
          }
        }}
        classes={{ anchorOriginBottomRight: classes.snackbarAnchor }}
        autoHideDuration={6000}
        TransitionComponent={transition}
        onClose={closeSnackbar}
        message={contentSnackbar}
      />
    </>
  )
}

ProgressionButtons.propTypes = propTypes;

export default connect(
  ({ public: { autovalidate, currentPage } }) => ({
    autovalidate,
    currentPage,
  }),
  dispatch => ({
    goToNextPage: () => dispatch({ type: PUBLIC_SURVEY_NEXT_PAGE }),
    goToPrevPage: () => dispatch({ type: PUBLIC_SURVEY_PREV_PAGE }),
    setToAutovalidate: validatedPage => dispatch({ type: 'SET_TO_AUTOVALIDATE', payload: { validatedPage } }),
  }),
)(ProgressionButtons);
