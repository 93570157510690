import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import { DeleteOutlined as DeleteOutlinedIcon } from '@material-ui/icons';
import HtmlRender from './HtmlRender';


const useStyles = makeStyles(theme => ({
  text: {
    color: "#000",
    display: "block",
    margin: "0 0 10px 0",
    fontWeight: 'bold',
    paddingTop: '20px',
    fontSize: '16px',
    fontFamily: "'Noto Sans JP', sans-serif",
    whiteSpace: 'pre-line',
      "& .required": {
      height: "1.5rem",
      lineHeight: "1.5rem",
      display: "inline-block",
      background: "rgb(236, 104, 26)",
      color: "#ffffff",
      fontSize: "10px",
      padding: "0px 5px",
      letterSpacing: "0.1rem",
      verticalAlign: "top",
      transform: "scale(0.9)",
      marginLeft: "5px",
      borderRadius: "2px"
    }

  },
  inpufile: {
    display: "none",
  },
  labelInput: {
    textAlign: "center",
    cursor: "pointer",
    background: "#efefef",
    border: "dashed 1px #b3b3b3",
    display: "block",
    padding: "15px 0",
    borderRadius: 5,
    "& i": {
      display: "block",
      textAlign: "center",
      color: "#b7b7b7",
      fontSize: 18,
      marginBottom: 10,
    },
    "& span": {
      display: "block",
      textAlign: "center",
      color: "#b7b7b7",
      fontSize: 10,
    }
  },
  image: {
    maxWidth: '100%',
    height: 'auto'
  },
  deleteImageBtn: {
    marginTop: 20
  },
  img: {
    textAlign: 'center',
    "& img": {
      maxWidth: '100%'
    },
  },
}));

const propTypes = {
  questionItem: PropTypes.object.isRequired,
  onInputValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  answer: PropTypes.object,
};

const QuestionTypeImageUpload = ({
  questionItem,
  onInputValue,
  errors,
  answer,
}) => {
  const [image, setImage] = useState(answer.answer_image || { data: '' });
  const classes = useStyles();
  const hasError = !!errors.answer_image;

  const getImage = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        "filename": file.name,
        "data": reader.result,
        "content_type": file.type
      });
    };

    reader.onerror = function (error) {
      console.error('Error: ', error);
      reject(error)
    };
  })

  const onUploadImage = async (event) => {
    event.preventDefault();

    const [file] = event.target.files;

    if (file) {
      const image = await getImage(file)
      setImageCallback(image);

    } else {
      console.error('File uploaded is not valid.');
    }
  }

  const setImageCallback = (image) => {
    setImage(image)
    onInputValue(questionItem, { answer_image: image })
  }

  return (
    <div className={classes.uploadImg}>
      <p className={classes.text}>
        <HtmlRender
          value = {questionItem.body}
        />
        {questionItem.is_required && (
          <span className="required">必須</span>
        )}
      </p>
      {!!questionItem.question_image_url && (
        <div className={classes.img}>
          <img src={questionItem.question_image_url} alt={''}/>
        </div>
      )}
      {
        !image.data && (
          <>
            <input
              type="file"
              id="file"
              className={classes.inpufile}
              accept=".jpg, .jpeg, .png"
              onChange={onUploadImage}
            />
            <label htmlFor="file" className={classes.labelInput}><i className="fa fa-picture-o" aria-hidden="true"></i>
              <span>画像アップロード</span>
            </label>
            {hasError && (
              <FormHelperText error={true}>{errors.answer_image.map(err => err)}</FormHelperText>
            )}
          </>
        )
      }

      {
        !!image.data && (
          <Grid container direction="column" justify="center" alignItems="center">
            <img className={classes.image} src={image.data} alt="" />
            <Button
              startIcon={<DeleteOutlinedIcon />}
              color="secondary" variant="outlined"
              onClick={() => setImageCallback({})}
              className={classes.deleteImageBtn}
            >
              削除
            </Button>
          </Grid>
        )
      }
    </div>
  );
}

QuestionTypeImageUpload.propTypes = propTypes;

export default QuestionTypeImageUpload;
