import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setQuestion } from './redux/actions';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, IconButton, Divider } from '@material-ui/core';
import Select from 'react-select';
import { parseQuestions } from './utils';
import { themeSelectQuestionStyles } from "./styles";
import { renderHtmlWithoutTags } from '../../../utils';
import { replaceParams } from 'modules/utils';

const propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  selectedQuestionId: PropTypes.string, 
  selectQuestion: PropTypes.func,
  icon: PropTypes.object,
  text: PropTypes.string,
};

const useStyles = makeStyles(themeSelectQuestionStyles);

const SelectQuestion = ({ questions, selectedQuestionId='', selectQuestion, icon, text, rotate }) => {
  const { root, selectBox, iconButton, divider, ul, txtText } = useStyles();
  const [value, setValue] = useState(selectedQuestionId);

  useEffect(() => {
    setValue(selectedQuestionId)
  }, [selectedQuestionId])

  const handleChange = e => {
    selectQuestion(questions.find(({ id }) => id === e.id))
    setValue(e.id)
  };

  const formatOptionLabel = ({ body }) => (
    <div className={ul}>
      <div>{renderHtmlWithoutTags(replaceParams(body))}</div>
    </div>
  );

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ?  "#fff" : "#fff", 
      "&:hover": { 
        backgroundColor: "#fff", 
        font: "600 10px/16px Noto Sans JP, Hiragino Sans",
        color: "#EC681A !important",
        cursor: "pointer"
      }
    })
  };

  return (
    <Paper className={root}>
      <IconButton className={`${iconButton} ${rotate}`} aria-label="filter">{icon}</IconButton>
      <Divider className={divider} orientation="vertical" />
      <div className={txtText}>{text}</div>
      <div className={selectBox}>
        <Select
          classNamePrefix="select"
          onChange={handleChange}
          value={questions.find(item => item.id === value) || {}}
          formatOptionLabel={formatOptionLabel}
          name="color"
          styles={customStyles}
          options={questions}
          isSearchable={false}
          menuPlacement='auto'
        />
      </div>
    </Paper>
  );
}

SelectQuestion.propTypes = propTypes;

export default connect(
  ({ aggregation }, ownProps) => ({
    questions: parseQuestions(aggregation.questions),
    selectedQuestionId: (aggregation.selectedQuestions[ownProps.tag] || {}).id,
  }),
  (dispatch, ownProps) => ({
    selectQuestion: question => dispatch(setQuestion(ownProps.tag, question))
  })
)(SelectQuestion)
