import {
  FETCH_ANALYSIS_BEGIN,
  FETCH_PERIODS,
  FETCH_TIME_SERIES,
  FETCH_CORRELATIONS,
  FETCH_FAILURE,
  SET_ACTIVE_CHART,
  FETCH_ANALYSIS_RESET,
} from './actions'

const initialState = {
  feedback_count: 0,
  nps_score: "0.0",
  promoters: "0.0",
  passives: "0.0",
  detractors: "0.0",
  periods: [],
  time_series: [],
  correlations: [],
  activeChart: null,
  loading: false,
  loaded: false,
  error: null,
}

export default function analysisReducer(state=initialState, action) {
  switch(action.type) {
    case FETCH_ANALYSIS_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        activeChart: null,
      }
    case SET_ACTIVE_CHART:
      return {
        ...state,
        activeChart: action.payload.chart,
      }
    case FETCH_PERIODS:
      return destructurePayload(state, action, "periods")
    case FETCH_TIME_SERIES:
      return destructurePayload(state, action, "time_series")
    case FETCH_CORRELATIONS:
      return destructurePayload(state, action, "correlations")
    case FETCH_FAILURE:
      return {
        ...initialState,
        error: action.payload.error,
        loading: false,
        loaded: true,
      }
    case FETCH_ANALYSIS_RESET:
      return {
        ...state,
        loading: false,
        loaded: false,
      }
    default:
      return state
  }
}

const destructurePayload = (state, action, property) => {
  if (!!!action.payload.data){
    return state
  }
  let { items, ...others } = action.payload.data
  return {
    ...state,
    loading: false,
    loaded: true,
    [property]: items || [],
    ...others
  }
}
