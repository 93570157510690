import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography, Dialog, Slide, CircularProgress } from '@material-ui/core/';
import {
  CloudUpload as CloudUploadIcon,
  FolderOpen as FolderOpenIcon,
  Close as CloseIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';
import { DialogContent, DialogActions } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { thunkUploadFile } from './redux/thunk';
import { openModal, closeModal } from './redux/actions';
import ImportReport from './ImportReport';
import ButtonWithIcon from '../buttons/ButtonWithIcon';

const propTypes = {
  path: PropTypes.string.isRequired,
  uploadFile: PropTypes.func.isRequired,
  success: PropTypes.object,
  eror: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func, 
  onSuccess: PropTypes.func,
  modalOpen: PropTypes.bool,
  loading: PropTypes.bool,
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { label, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{label}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const ImportDialog = ({ path, uploadFile, success, error, openModal, closeModal, modalOpen, loading, onSuccess, children, onClose, title }) => {
  const [inputValue, setInputValue] = useState("")
  const [hasUploaded, setHasUploaded] = useState(false)
  const [file, setFile] = useState(null)
  const readyToUpload = file && !hasUploaded && !!!error && !loading

  useEffect(() => setFile(null), [modalOpen])

  useEffect(() => {
    // When following conditions are met, onSuccess given from caller will be triggered
    // - file is selected and processing finished and its was successfull and
    // preview_messages is empty meaning that it is not preview api call (actual import call)
    // more than 0 number of records are imported 
    if (!loading && file && !!success && success.preview_messages.length === 0 && success.count > 0){
      onSuccess && onSuccess()
    }
  }, [loading, success, file, onSuccess])

  const handleClick = () => setInputValue("")

  const handleFileSelect = ({ target: { files }}) => {
    setFile(files[0])
    uploadFile(path, files[0], { preview: 1})
    setHasUploaded(false)
  };

  const close = () => {
    closeModal && closeModal()
    onClose && onClose()
  }

  const handleFileupload = () => {
    if (readyToUpload){
      uploadFile(path, file)
      setHasUploaded(true)
    } else {
      close()
    }
  }

  return <>
    <Dialog open={modalOpen} TransitionComponent={Transition} keepMounted onClose={close} maxWidth={"sm"} fullWidth>
      <DialogTitle onClose={close} label={`${title} ${!!file ? file.name : ''}`} />
      <DialogContent dividers>
        <div>
          {!!!file && "アップロードするファイルを選択してください"}
        </div>
        <div style={{ color: 'red' }}>VOC通知メールの設定がOFFになっているかご確認ください</div>
        <div>
          {loading && <CircularProgress disableShrink />}
          <ImportReport success={success} error={error}/>
        </div>
      </DialogContent>
      <DialogActions>
        <input
            accept=".csv"
            style={{ display: 'none' }}
            id="outlined-button-file"
            type="file"
            onChange={handleFileSelect}
            onClick={handleClick}
            value={inputValue}
          />
        <label htmlFor="outlined-button-file">
          <ButtonWithIcon component="span" variant="outlined" startIcon={<FolderOpenIcon />} text="ファイルを選択" />
        </label>
        <ButtonWithIcon autoFocus variant="outlined" onClick={handleFileupload} 
          startIcon={readyToUpload ? <CloudUploadIcon/> : <CancelIcon/>}
          text={readyToUpload ? "アップロードする" : "閉じる"} color={readyToUpload ? "primary" : "default"}/>
      </DialogActions>
    </Dialog>
    <span style={{ display: 'inline-block' }} onClick={openModal}>
      {children}
    </span>
  </>
}

ImportDialog.propTypes = propTypes;

export default connect(
  ({ upload }) => ({ ...upload }),
  dispatch => ({
    openModal: () => dispatch(openModal()),
    closeModal: () => dispatch(closeModal()),
    uploadFile: (path, file, params) => dispatch(thunkUploadFile(path, file, params))
  }),
)(ImportDialog);
