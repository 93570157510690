import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  FormControlLabel,
  Radio,
  TextField,
  Paper,
  Input,
  Button,
  makeStyles,
} from "@material-ui/core";
import TabPanel from "./TabPanel";
import { FormField, TextFieldWithError } from "../components";
import AddImage from '../components/AddImage';
import ElementIcon from "../../../common/icon/ElementIcon";
import clsx from 'clsx';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '770px',
    height: 'auto',
    margin: '0 auto',
    [theme.breakpoints.down("md")]: {
      width: "auto",
    }
  },
  button: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #B7B7B7',
    borderRadius: '5px',
    opacity: 1,
    color: '#939393',
    padding: '0 12px',
    minWidth: '180px',
    marginRight: 10
  },
  form: {
    width: '100%',
    padding: '30px',
    margin: '10px 0',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderLeft: '4px solid #B7B7B7',
    borderRadius: 0,
    boxShadow: '0px 5px 6px #B7B7B733',
    opacity: 1,
    "& ul": {
      listStyleType: 'none',
      padding: 0
    }
  },
  htmlInput: {
    background: '#4681D3 0% 0% no-repeat padding-box',
    padding: '60px 30px',
    paddingRight: '25%',
    borderRadius:"3px",
    border: "1px solid #B7B7B7",
    opacity: 1,
    "& .MuiInput-underline:before":{
      borderBottom: "1px solid #FFFFFF",
    },
    "& .MuiInput-underline:hover:before":{
      borderBottom: "1px solid #FFFFFF",
    },
    "& .MuiInput-underline:after":{
      borderBottom: "2px solid #FFFFFF",
    },
    [theme.breakpoints.down("xs")]: {
      padding: '10px 20px',
    }
  },
  radioGroup: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  editIcon: {
    color: '#FFFFFF',
    fontSize: '24px',
  },
  input: {
    color: "#FFFFFF",
    font: "14px/21px 'Noto Sans JP', sans-serif",
    fontWeight: 400,
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    borderColor: '#fafafa',
    '&:before': {
      borderColor: '#fafafa',
    },
    '&:after': {
      borderColor: '#fafafa',
    },
    '&:-ms-input-placeholder': {
      color: '#92b5e5',
      fontWeight: 600,
    }
  },
  formInput: {
    margin: '25px 0',
    padding: '10px 0',
    '& legend': {
      fontWeight: 'bold',
    },
    '& input': {
      height: '36px',
      boxSizing: 'border-box',
    }
  },
  formInputURL: {
    margin: '25px 0',
    padding: '10px 0',
    '& legend': {
      fontWeight: 'bold',
      color: 'black',
      paddingBottom: "20px"
    },
    '& input': {
      height: '36px',
      boxSizing: 'border-box',
    }
  },
  startSurvey: {
    padding: 10,
    width: '100%',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    border: '1px solid #B7B7B7',
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  surveyButton: {
    width: 240,
    textAlign: 'center',
    letterSpacing: '0.3px',
    color: '#FFFFFF',
    opacity: 1,
    backgroundColor: '#4681D3',
    padding: '15px 45px',

    '& > span': {
      '-ms-transform': 'translateY(3px)',
      wordBreak: 'break-all',
      textTransform: 'none',
    }
  },
  textField: {
    '& div': {
      width: '100%',
    },

    '& input': {
      border: '1px solid #ccc !important',
      borderRadius: 4,
      height: '36px',
      boxSizing: 'border-box',
      opacity: '1 !important',
      padding: '9px 12px',

      '&:-ms-input-placeholder': {
        color: '#ccc !important',
      },

      '&:hover': {
        border: '1px solid #1A0707 !important',
      },

      '&:focus': {
        border: '2px solid #EC681A !important',
      }
    },
    
    '& textarea': {
      border: '1px solid #ccc !important',
      borderRadius: 4,
      boxSizing: 'border-box',
      opacity: '1 !important',
      padding: '9px 12px',
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',

      '&:-ms-input-placeholder': {
        color: '#ccc !important',
      },


      '&:hover': {
        border: '1px solid #1A0707 !important',
      },

      '&:focus': {
        border: '2px solid #EC681A !important',
      }
    },

    '& fieldset': {
      display: 'none',
    }
  },
}));

const propTypes = {
  activeTab: PropTypes.number.isRequired,
  use_start_page_html: PropTypes.bool,
  setUseStartPageHtml: PropTypes.func.isRequired,
  start_page_main_image: PropTypes.object,
  onLoadStartPageMainImage: PropTypes.func.isRequired,
  onDeleteStartPageMainImage: PropTypes.func.isRequired,
  start_page_main_text: PropTypes.string,
  start_page_html: PropTypes.string,
  start_page_custom_css: PropTypes.string,
  start_page_button_name: PropTypes.string,
  errors: PropTypes.object.isRequired,
};

const StartPageSettings = ({
  activeTab,
  use_start_page_html,
  setUseStartPageHtml,
  start_page_main_image,
  onLoadStartPageMainImage,
  onDeleteStartPageMainImage,
  start_page_main_text,
  start_page_html,
  start_page_custom_css,
  start_page_button_name,
  errors,
}) => {

  const classes = useStyles();
  const [text, setText] = useState(start_page_button_name);
  
  return (
    <TabPanel value={activeTab} index={1}>
      <div className={classes.root}>
        <div className={classes.radioGroup}>
          <FormGroup className={classes.button}>
            <FormControlLabel
              control={
                <Radio
                  checked={use_start_page_html}
                  onClick={() => setUseStartPageHtml(!use_start_page_html)}
                />
              }
              name="use_start_page_html"
              label="HTMLを使用"
            />
          </FormGroup>
        </div>

        {!use_start_page_html && (
        <AddImage
          image={start_page_main_image}
          setImage={onLoadStartPageMainImage}
          onDelete={onDeleteStartPageMainImage}
        />
        )}

        <Paper className={classes.form}>
          {!use_start_page_html && (
            <FormField component="fieldset" fullWidth className={classes.htmlInput}>
              <Input
                placeholder="ここにメインテキストを書いてください。"
                fullWidth
                name="start_page_main_text"
                multiline
                defaultValue={start_page_main_text}
                classes={{
                  input: classes.input,
                }}
                endAdornment={<ElementIcon name='edit-outline' className={classes.editIcon} />}
              />
            </FormField>
          )}

          {use_start_page_html && (
            <FormField component="fieldset" label={"HTML入力エリア"} fullWidth className={clsx(classes.formInput, classes.textField)} error={!!errors.start_page_html}>
              <TextField
                name="start_page_html"
                placeholder="HTML入力エリア"
                multiline
                defaultValue={start_page_html}
                rows={10}
                variant="outlined"
                helperText="以下のタグが利用可能です。 h3, h4, h5, h6, blockquote, p, a, ul, ol, nl, li, b, i, strong, em, strike, code, hr, br, div, table, thead, caption, tbody, tr, th, td, pre, iframe"
              />
            </FormField>
          )}

          <FormField className={clsx(classes.formInput, classes.textField)} component="fieldset" fullWidth label={"カスタムCSS"}>
            <TextField
              name="start_page_custom_css"
              placeholder="CSSを入力ください。"
              multiline
              defaultValue={start_page_custom_css}
              rows={10}
              variant="outlined"
            />
          </FormField>

          <FormField className={clsx(classes.formInput, classes.textField)} component="fieldset" fullWidth label={"開始ボタン"}>
            <TextFieldWithError
              name={"start_page_button_name"}
              fieldLabel={"開始ボタン"}
              placeholder={'アンケートを開始する'}
              variant={"outlined"}
              onChange={(e) => {setText(e.target.value)}}
              defaultValue={start_page_button_name}
              errors={errors.start_page_button_name}
            />
          </FormField>

          <div className={classes.startSurvey}>
            <Button
              variant="contained"
              className={classes.surveyButton}
            >
              {text || 'アンケートを開始する'}
            </Button>
          </div>
        </Paper>
      </div>
    </TabPanel>
  );
};

StartPageSettings.propTypes = propTypes;

export default StartPageSettings;
