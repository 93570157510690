import React, { useEffect } from "react";
import renderHTML from 'react-render-html';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

const propTypes = {
    selectedParams: PropTypes.object,
    value: PropTypes.string,
};

const HtmlRender = ({value, isEndPage = false, doc, selectedParams}) => {
    useEffect(() => {
        const renderedImgElements = document.getElementsByClassName("product_image")
        Array.prototype.forEach.call(renderedImgElements, function(element){
            if(!element.getAttribute('onError')){
                element.addEventListener('error', function(e){
                    e.target.src = `${process.env.PUBLIC_URL}/noimg.png`
                })
            }
        })
    }, []);
    // 初回ロード時は店舗選択前の為、doc(ローカルストレージ), SelectedParamsが存在しない
    const SELECTED_NAME = '{%SELECTED_NAME%}'
    const SELECTED_IMAGE = '{%SELECTED_IMAGE%}'
    const noImgUrl =`${process.env.PUBLIC_URL}/noimg.png`
    if(doc && !isEndPage){
        // 確認画面で値にnullが渡される可能性がある為if分岐（未回答設問の回答等）
        if(value){
            const name = doc.shop.name
            // name（店舗/商品名）は必須項目、imageUrl(店舗/商品画像)は任意項目の為判定
            const imgUrl = doc.shop.image_url? doc.shop.image_url : noImgUrl
            
        
            // 文字列置換を行う為に一度Stringに置換する必要がある為、Eventやrefで関数を定義する事が出来ません
            // また動的なHTMLソースである為、ユニークなIDも付与が出来ません。
            // 代替対応としてuseEffectで画面読み込み後にDOM操作でonErrorイベントを追加し画像に無効URLが指定されている場合にnoImgを表示します
            const imgTag = <img src={imgUrl} className="product_image" alt="product_image" />
            const imgString = ReactDOMServer.renderToString(imgTag)
            const htmlSrc = value.replace(SELECTED_NAME, name).replace(SELECTED_IMAGE, imgString)
            return(
                <>
                    {renderHTML(htmlSrc)}
                </>
            )
        }else{
            return <></>
        }
    }else if(isEndPage){
        // 終了ページ
        // stateに値が残っている場合は置換処理を行う,残っていなければ空文字、noImage画像に置換
        if(value){
            const name = selectedParams.name? selectedParams.name : ''
            // name（店舗/商品名）は必須項目、imageUrl(店舗/商品画像)は任意項目の為判定
            const imgUrl = selectedParams.imageUrl? selectedParams.imageUrl : noImgUrl
            
        
            // 文字列置換を行う為に一度Stringに置換する必要がある為、Eventやrefで関数を定義する事が出来ません
            // また動的なHTMLソースである為、ユニークなIDも付与が出来ません。
            // 代替対応としてuseEffectで画面読み込み後にDOM操作でonErrorイベントを追加し画像に無効URLが指定されている場合にnoImgを表示します
            const imgTag = <img src={imgUrl} className="product_image" alt="product_image" />
            const imgString = ReactDOMServer.renderToString(imgTag)
            const htmlSrc = value.replace(SELECTED_NAME, name).replace(SELECTED_IMAGE, imgString)
            return(
                <>
                    {renderHTML(htmlSrc)}
                </>
            )
        }else{
            return <></>
        }
    
    }else{
        if(value){
            return <>{renderHTML(value)}</>
        }
        return <></>
    }
}

HtmlRender.propTypes = propTypes;

export default connect(({public: {doc, selectedParams}})=> {
    return {
      doc : doc,
      selectedParams: selectedParams,
    }
  })(HtmlRender)