// 2023年次改良：フィードバック一覧ヘッダー配置用にFont設定したトグルボタンを定義

import React from "react";
import { FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import AntSwitch from "common/buttons/AntSwitch";

const useStyles = makeStyles(theme => ({
  switch: {
    paddingLeft: 10,
    width: "auto",
    minWidth: "150px",
    height: "42px",
    marginRight: 0,
    marginLeft: "20px",
    backgroundColor: "#EFEFEF",
    borderRadius: "5px",
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white"
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#EC681A"
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 5px"
    }
  },
  label:{
    padding: '10px 5px',
    fontWeight: "bold",
    fontSize:"12px",
    lineHeight:"16px",
    display: 'flex',
    alignItems: 'center',
  },
  bgcolor_unset: {
    marginLeft: 0,
    paddingLeft: 0,
    backgroundColor: "transparent"
  }
}));

const SwitchBtnWithoutFont = props => {
  const classes = useStyles();
  const { label, backgroundColor, value, onChange, checked, ...others } = props;
  return (
    <FormControlLabel
      control={
        <AntSwitch
          checked={checked}
          value={value}
          onChange={onChange}
        />
      }
      label={
        <span className={classes.label}>
          {label}
        </span>
      }
      className={classNames(
        classes.switch,
        backgroundColor ? "" : classes.bgcolor_unset
      )}
      {...others}
    />
  );
};
export default SwitchBtnWithoutFont;
