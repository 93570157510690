import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import SettingsIcon from "@material-ui/icons/Settings";
import { Popover } from "@material-ui/core";


const useStyles = makeStyles(theme => {
  return{
    itemInner: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    },
    smallItem: {
      flexDirection: "column !important",
      alignItems: "center",
      justifyContent: "center"
    },
    activeRoute: {
      backgroundColor: theme.palette.brown.Light,
      color: theme.palette.primary.main,
      '& $subNavText':{
        '& .MuiTypography-colorTextSecondary':{
          fontWeight: 700,
        },
      }
    },
    subNav: props=>({
      display: props.isMobile ? 'none': 'block'
    }),
    miniSub: {
      minHeight: 0,
    },
    rootItem: {
      height: "96px",
      padding: '0px',
    },
    subButton:{
      padding: '16px 16px 16px 25px',
    },
    subOpen: {
      color: theme.palette.brown.Dark,
      backgroundColor: theme.palette.grayscale[100],
    },
    subItem: {
      padding: '16px 0 16px 40px',
      '&:hover':{
        backgroundColor: theme.palette.grayscale[100],
      },
    },
    miniSubItem: {},
    subNavText: {},
    smallText: {
      marginBottom: 0,
      textAlign: "center",
      "& span": {
        fontSize: "12px",
        fontWeight: 500
      }
    },
    centerIcon: {
      display: "flex",
      justifyContent: "center",
      '& svg':{
        color: theme.palette.primary.main,
      }
    },
    dropdown: {
    }
  }
});

const MenuManager = ({ collapsed, location, goToPage, items, isMobile }) => {
  const classes = useStyles({isMobile});

  const [visible, setVisible] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleVisible = () => setVisible(!visible);

  const handleOpen = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const onClickManager = event => {
    if (!collapsed) toggleVisible();
    else handleOpen(event);
  };

  const renderArrow = () => {
    return visible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  };

  const onClickSubMenu = href => {
    if (!anchorEl) goToPage(href);
    else {
      goToPage(href);
      handleClose();
    }
  };

  const renderSubMenu = sub => {
    const { href, short } = sub;
    const active = location.pathname === `/admin${href}`;
    return (
      <ListItem
        key={href}
        button
        onClick={() => onClickSubMenu(href)}
        className={clsx(
          classes.subItem,
          collapsed && classes.miniSubItem,
          active && classes.activeRoute)}
        disableGutters={true}
      >
        <ListItemText primary={short} className={classes.subNavText} />
      </ListItem>
    );
  };

  return (
    <div className={ clsx(classes.subNav, collapsed && classes.miniSub)}>
      <ListItem
        button
        onClick={onClickManager}
        divider={collapsed}
        className={clsx(
          !collapsed && classes.subButton,
          collapsed && classes.rootItem,
          visible && classes.subOpen)}
        disableGutters={true}
      >
        <div
          className={clsx(classes.itemInner, collapsed && classes.smallItem)}
        >
          {collapsed && (
            <ListItemIcon 
            className= {
              collapsed && classes.centerIcon}>
              <SettingsIcon />
            </ListItemIcon>
          )}
          <ListItemText
            primary={"管理機能"}
            className={clsx(collapsed && classes.smallText)}
          />
          {!collapsed && renderArrow()}
        </div>
      </ListItem>

      {/* Dropdown sub menu */}
      {!collapsed && visible && (
        <div className={classes.dropdown}>
          {items.map(sub => renderSubMenu(sub))}
        </div>
      )}

      {/* Popover sub menu */}
      <Popover
        id={"sub-menu-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        {items.map(sub => renderSubMenu(sub))}
      </Popover>
    </div>
  );
};

export default MenuManager;
