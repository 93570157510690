import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  fieldLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  variant: PropTypes.string,
  error: PropTypes.array,
}
const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#EC681A',
      },
    },
  },
})(TextField);

const TextFieldWithError = ({ fieldLabel='', name, label, variant='standard', errors=[], ...otherParams }) => {
  return (
    <CssTextField
      name={name}
      label={label || ''}
      variant={variant}
      error={errors.length > 0}
      helperText={Object.keys(errors).length > 0 && `${fieldLabel}${errors[0]}`}
      {...otherParams}
    />
  );
}

TextFieldWithError.propTypes = propTypes;

export default TextFieldWithError;
