export const themeNpsScoreBarChartStyles = theme => ({
  legendItem: {
    display: "flex",
    marginRight: 0,
    alignItems: "center",
    "& >.blockColor": {
      height: 42,
      width: 12,
      borderRadius: 5,
      display: "inline-block"
    },
    "& >.titleChart": {
      display: "inline-block",
      marginLeft: 2,
      fontSize: 16,
      paddingLeft: 10,
      "& >.label": {
        font: "300 16px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.8px",
        color: "#1A0707",
        opacity: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        [theme.breakpoints.down("xs")]: {
          font: "300 14px/28px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.7px",
          marginBottom: "-5px"
        }
      },
      "& >.value": {
        font: "300 16px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.8px",
        color: "#796255",
        opacity: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        [theme.breakpoints.down("xs")]: {
          font: "300 14px/28px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.7px",
          marginBottom: "-5px"
        }
      }
    }
  },
  legendGroup: {
    marginTop: 5,
    display: "flex"
  }
});
export const themeTimeSeriesTooltipStyles = theme => ({
  blockTooltip: {
    background: "white",
    padding: 8,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
    "& >div": {
      margin: 10,
      marginTop: 0
    },
    "& >p": {
      margin: 0,
      padding: 0
    }
  },
  itemColor: {
    color: "#C69075"
  }
});
