import {makeStyles} from "@material-ui/core/styles";

export const useLikeButtonStyle = makeStyles(theme => ({
  buttonHeader: {
    borderRadius: 25,
    color: 'rgb(26, 7, 7)',
    marginRight: 10,
    padding: '8px 10px',
    width: '97px',
    '& span': {
      font: '10px/12px Noto Sans JP, Hiragino Sans',
      letterSpacing: '0.25px',
      color: '#1A0707',
      opacity: 1,
      '& i': {
        fontSize: '16px',
        color: 'rgb(183, 183, 183)',
        paddingLeft: '5px',
      }
    },
    "&:hover":{
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      border: "1px solid #EC681A"
    },
    "&:hover >span":{
      color: "#EC681A"
    },
    "&:hover >span>i":{
      color: "#EC681A"
    },
    "&:hover >span>span":{
      color: "#EC681A"
    },
    "&:hover span>svg>path":{
      fill: "#ec681a",
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0px',
      width: 77,
      border: '1px solid #B7B7B7',
      borderRadius: 16,
      '& span': {
        font: '600 10px/12px Noto Sans JP, Hiragino Sans',
        textAlign: 'left',
        letterSpacing: '0.25px',
        color: '#1A0707',
        '& i': {
          fontSize: '16px !important',
          paddingLeft: '3px !important'
        }
      }
    }
  },
  hiddenMobile: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  hover: {
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: '#EC681A 0% 0% no-repeat padding-box',
        border: '1px solid #EC681A',
      },
      '&:hover >span>svg>path': {
        fill: 'white',
        stroke: 'white',
      },
      '&:hover >span>div': {
        background: '#fff 0% 0% no-repeat padding-box',
      },
    },
  }
}))