import { parseComment } from '../../utils'

export const FETCH_COMMENTS_BEGIN   = 'FETCH_COMMENTS_BEGIN'
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS'
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE'
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const FETCH_COMMENT_LIKES = 'FETCH_COMMENT_LIKES'
export const FETCH_COMMENT_LIKES_BEGIN = 'FETCH_COMMENT_LIKES_BEGIN'
export const LIKE_COMMENT_BEGIN = 'LIKE_COMMENT_BEGIN'
export const LIKE_COMMENT_FAILURE = 'LIKE_COMMENT_FAILURE'
export const LIKE_FEEDBACK_COMMENT = 'LIKE_FEEDBACK_COMMENT'
export const DISLIKE_FEEDBACK_COMMENT = 'DISLIKE_FEEDBACK_COMMENT'

export const fetchCommentsBegin = () => ({
  type: FETCH_COMMENTS_BEGIN
})

export const fetchCommentsSuccess = ({data, included}, paginationParams) => ({
  type: FETCH_COMMENTS_SUCCESS,
  payload: {
    comments: data.map(i => parseComment(i, included)),
    pagination: paginationParams
  }
})

export const fetchCommentsFailure = error => ({
  type: FETCH_COMMENTS_FAILURE,
  payload: {
    error
  }
})

export const createComment = ({ data, included }) => ({
  type: CREATE_COMMENT,
  payload: { 
    comment: parseComment(data, included)
  }
})

export const deleteComment = commentId => ({
  type: DELETE_COMMENT,
  payload: {
    commentId
  }
})

export const fetchCommentLikesBegin = () => ({
  type: FETCH_COMMENT_LIKES_BEGIN
})

export const fetchCommentLikes = ({ data: { id }, included }) => ({
  type: FETCH_COMMENT_LIKES,
  payload: {
    comment_id: id,
    users: included.filter(i => i.type === 'user').map(user => user.attributes.name)
  }
})

export const likeComment = ({ data: { id }}, comment_id) => ({
  type: LIKE_FEEDBACK_COMMENT,
  payload: {
    comment_id,
    feedback_comment_like_id: id,
  }
})

export const dislikeComment = comment_id => ({
  type: DISLIKE_FEEDBACK_COMMENT,
  payload: {
    comment_id,
    feedback_comment_like_id: null,
  }
})

export const likeCommentFailure = error => ({
  type: LIKE_COMMENT_FAILURE,
  payload: {
    error
  }
})