import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend
} from 'recharts';
import { NPS_COLORS } from 'modules/aggregation/constants';
import { makeStyles } from '@material-ui/core/styles';
import { themeNpsScoreBarChartStyles } from "./styles";

const propTypes = {
  promoters: PropTypes.string.isRequired,
  passives: PropTypes.string.isRequired,
  detractors: PropTypes.string.isRequired,
};
const useStyles = makeStyles(themeNpsScoreBarChartStyles);

const LegendItem = ({ color, label, value, position, widthAuto }) => {
  const { legendItem } = useStyles();
  const widthLegend = value + "%";
  return value <= 0 ? <></> :
    <div style={{ left: position, width: widthAuto ? "auto" : widthLegend, minWidth: 80 }} className={legendItem}>
      <div style={{
        background: color,
      }} className="blockColor" />
      <div className="titleChart">
        <div className="label">{label}</div>
        <div className="value">{value}%</div>
      </div>
    </div>
}

const CustomLegend = ({ promoters, passives, detractors, width }) => {
  const { legendGroup } = useStyles();
  const delta = Math.min(70, width)

  const getPassivePosition = () => {
    const relative = promoters * width / 100
    const possibleMax = Math.min(width - 2 * delta, relative)
    return Math.max(delta * +(promoters > 0), possibleMax)
  }

  const getDetractPosition = () => {
    const relative = (promoters + passives) * width / 100
    const possibleMax = Math.min(width - delta, relative)
    return Math.max(getPassivePosition() + delta * +(passives > 0), possibleMax)
  }
  
  return (
    <div className={legendGroup}>
      <LegendItem color={NPS_COLORS.detractor} label="批判者" value={detractors} position={0} widthAuto={false}/>
      <LegendItem color={NPS_COLORS.passive} label="中立者" value={passives} position={getPassivePosition()} widthAuto={false}/>
      <LegendItem color={NPS_COLORS.promoter} label="推奨者" value={promoters} position={getDetractPosition()} widthAuto={true}/>
    </div>
  )
}

const NpsScoreBarChart = (values) => {
  const [data, setData] = useState({})

  useEffect(() => {
    setData({ name: '割合', ...values })
  }, [values])

  return (
    <ResponsiveContainer width='100%' height={100}>
      <BarChart data={[data]} layout="vertical"
        margin={{ top: 10, right: 15, left: 15, bottom: 10 }} >
        <XAxis type="number" hide={true} />
        <YAxis dataKey="name" type="category" hide={true} />
        <Tooltip cursor={{ fill: 'none', fontSize: 11 }}/>
        <Legend height={36} content={<CustomLegend {...data} />} />
        <Bar dataKey="detractors" stackId="a" name="批判者" fill={NPS_COLORS.detractor} />
        <Bar dataKey="passives" stackId="a" name="中立者" fill={NPS_COLORS.passive} />
        <Bar dataKey="promoters" stackId="a" name="推奨者" fill={NPS_COLORS.promoter} />
      </BarChart>
    </ResponsiveContainer>
  )
}

NpsScoreBarChart.propTypes = propTypes;

export default NpsScoreBarChart;
