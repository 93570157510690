import { default as axios } from 'axios';
import {
  FETCH_ALL_COMPANIES_BEGIN,
  FETCH_ALL_COMPANIES_SUCCESS,
  FETCH_ALL_COMPANIES_FAILURE,
  FETCH_COMPANIES_BEGIN,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  SAVE_COMPANY_BEGIN,
  SAVE_COMPANY_SUCCESS,
  saveCompanyFailure,
  OPEN_COMPANY_FORM_MODAL,
} from './actions';
import { PAGINATION_PARAM_NO_PAGINATION } from '../../../constants';
import { getRequestUrl, handleError } from 'utils';
import { COMPANIES_API_PATH } from '../constants';
import { Storage } from "../../../utils";

// Update use_dashboard of selected company from company fresh list from backend
function updateSelectedCompanyFromList({data}, dispatch) {
  const { value, use_dashboard } = Storage.getSelectedCompany()
  const selectedCompany = data.find(i => i.id === value)
  if (selectedCompany) {
    const { attributes } = selectedCompany
    if (attributes.use_dashboard !== use_dashboard)
      Storage.setSelectedCompany(
          { value, label: attributes.name, use_dashboard: attributes.use_dashboard , survey_type:attributes.survey_type},
          dispatch
      )
  }
}

export const thunkFetchCompanies = (
  page=1,
  searchParams,
  actions={
    actionBegin: () => ({ type: FETCH_COMPANIES_BEGIN }),
    actionSuccess: (items, pagination) => ({ type: FETCH_COMPANIES_SUCCESS, payload: { items, pagination } }),
    actionFailure: errors => ({ type: FETCH_COMPANIES_FAILURE, payload: { errors } }),
  }) => {
  searchParams = !!searchParams ? searchParams : {};

  // dispatchを実行する関数を返している
  return dispatch => {
    dispatch(actions.actionBegin());

    // promiseをreturnする目的が分からない。関数の目的がstateの更新であればreturnは不要と思われる。
    // 成功/失敗を返したい？
    return axios.get(getRequestUrl(COMPANIES_API_PATH, page, searchParams))
      .then(({ data, headers }) => {
        updateSelectedCompanyFromList(data, dispatch)
        dispatch(
          actions.actionSuccess(data, {
            perPage: headers['page-items'],
            total: headers['total-count'],
            offset: (+headers['current-page'] - 1) * +headers['page-items']
          })
        );
      })
      .catch(handleError(dispatch, actions.actionFailure));
  };
};

export const thunkFetchAllCompanies = (searchParams) => thunkFetchCompanies(
  PAGINATION_PARAM_NO_PAGINATION,
  searchParams,
  {
    actionBegin: () => ({ type: FETCH_ALL_COMPANIES_BEGIN }),
    actionSuccess: (items) => ({ type: FETCH_ALL_COMPANIES_SUCCESS, payload: { items } }),
    actionFailure: (error) => ({ type: FETCH_ALL_COMPANIES_FAILURE, payload: { error } }),
  }
);

export const thunkSaveCompany = ({ id, ...params }) => (dispatch) => {
  dispatch({ type: SAVE_COMPANY_BEGIN });

  if (!!id) {
    axios.patch(`${COMPANIES_API_PATH}/${id}`, { company: params })
      .then(({ data: { data } }) => {
        dispatch(({ type: SAVE_COMPANY_SUCCESS, payload: { data } }))
        if (+Storage.getSelectedCompany().value === +data.id)
          Storage.setSelectedCompany({ value: data.id, label: data.attributes.name, use_dashboard: data.attributes.use_dashboard }, dispatch)
      })
      .catch(handleError(dispatch, saveCompanyFailure));
  } else {
    axios.post(COMPANIES_API_PATH, { company: params })
      .then(({ data: { data } }) => dispatch(({ type: SAVE_COMPANY_SUCCESS, payload: { data } })))
      .catch(handleError(dispatch, saveCompanyFailure));
  }
};

export const thunkFetchCompany = id => {
  const url = `${COMPANIES_API_PATH}/${id}`
  return dispatch => {
    axios.get(url)
    .then(response => {
      const company = (response.data || {}).data
      dispatch({ type: FETCH_COMPANIES_SUCCESS, payload: {
        items: {
          data: [company]
        },
        pagination: {
          total: 1
        }
      }})
      dispatch({ type: OPEN_COMPANY_FORM_MODAL, payload: {
        company: { id: company.id, ...company.attributes }
      }})
    })
    .catch(handleError(dispatch, saveCompanyFailure));
  }
};
