import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from 'react-device-detect';
import { connect, useDispatch } from "react-redux";
import Select, { components } from "react-select";
import { Storage } from "../../../utils";
import ElementIcon from '../../../common/icon/ElementIcon';
import { loadGroups, loadProjects, loadShops, loadSurveys } from './utils'
import { RESET_FEEDBACK_QUESTIONS } from '../../../modules/feedbacks/redux/actions/feedback'

const useStyles = makeStyles(theme => ({
  inputs: {
    width: "70%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column"
    }
  },
  input: {
    width: "24%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "10px"
    }
  }
}));

const propTypes = {
  global: PropTypes.object.isRequired,
};

const extractAll = (global = {}) => {
  const extract = name => {
    const { label, value } = global[name] || {};
    return label && value ? global[name] : '';
  }
  return {
    company: extract('selectedCompany'),
    project: extract('projectData'),
    survey: extract('surveyData'),
    group: extract('groupData'),
    shop: extract('shopData'),
  }
}

const HeaderInputs = ({ global }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { company, project, survey, group, shop } = extractAll(global)
  const [loadingProjects, setLoadingProjects] = React.useState(false)
  const [loadingSurveys, setLoadingSurveys] = React.useState(false)
  const [loadingGroups, setLoadingGroups] = React.useState(false)
  const [loadingShops, setLoadingShops] = React.useState(false)
  const [projects, setProjects] = React.useState([])
  const [surveys, setSurveys] = React.useState([])
  const [groups, setGroups] = React.useState([])
  const [shops, setShops] = React.useState([])
  const groupWasCleared = React.useRef(false)
  const shopWasCleared = React.useRef(false)
  const didMount = React.useRef(false)

  React.useEffect(() => {
    didMount.current = true
    fetchAllProjectsSync()
    fetchAllGroupsSync()
    return () => {
      didMount.current = false
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setProjects([])
    setSurveys([])
    setGroups([])
    setShops([])
  }, [company.value])

  const fetchAllProjectsSync = async () => {
    setLoadingProjects(true)
    const items = await loadProjects() || []
    if (!didMount.current) return null
    setProjects(items)
    setLoadingProjects(false)
  }

  const fetchAllSurveysSync = async () => {
    if (project.value) {
      setLoadingSurveys(true)
      const items = await loadSurveys({ no_paging: 1, open_status: "with_inactive", project_id: project.value }) || []
      setSurveys(items)
      setLoadingSurveys(false)
    } else {
      setSurveys([])
    }
  }

  const fetchAllGroupsSync = async () => {
    setLoadingGroups(true)
    const items = await loadGroups({ no_paging: 1, v: 1 }) || []
    if (!didMount.current) return null
    if (items.length === 1 && !groupWasCleared.current) {
      if (group.value !== items[0].value) {
        Storage.setGroupData(items[0], dispatch)
        fetchAllShopsSync(items[0].value)
      }
    }
    setGroups(items)
    setLoadingGroups(false)
  }

  const loadShopsSynchronously = async (group_id, refresh) => {
    setLoadingShops(true)
    const query_params = {
      project_id: project.value,
      survey_id: survey.value,
      group_id,
      no_paging: 1,
      v: 1,
    }
    const items = await loadShops(query_params) || []
    if (items.length === 1 && !shopWasCleared.current && (shop || {}).value !== items[0].value) {
      Storage.setShopData(items[0], refresh ? dispatch : null)
    }
    setShops(items)
    setLoadingShops(false)
  }

  const fetchAllShopsSync = async (group_id) => {
    loadShopsSynchronously(group_id, true)
  }

  const isMobileOrTablet = isMobile;
  const onGroupChange = async (e = {}, { action }) => {
    if (action === 'clear') {
      groupWasCleared.current = true;
      setShops([])
      Storage.setGroupData({})
      Storage.removeShopData(dispatch);
    } else {
      if (e.value !== group.value) {
        shopWasCleared.current = false;
        Storage.removeShopData()
        await loadShopsSynchronously(e.value, false)
        Storage.setGroupData(e, dispatch)
      }
    }
  }

  const onShopChange = (v, { action }) => {
    if (action === 'clear') {
      shopWasCleared.current = true;
    }
    const { label, value } = v || {};
    Storage.setShopData({ label, value }, dispatch);
  };

  const onProjectChange = v => {
    const { label, value } = v || {};
    Storage.setProjectData({ label, value });
    Storage.removeSurveyData(dispatch);
  };

  const onSurveyChange = v => {
    const { label, value } = v || {};
    Storage.setSurveyData({ label, value }, dispatch);
    dispatch({ type: RESET_FEEDBACK_QUESTIONS })
  };

  const selectMenuStyle = (provided) => {
    const menuStyle =  {
      ...provided,
      position: 'unset',
      borderRadius: 0,
      boxShadow: 'unset',
      marginBottom: 0,
      marginTop: 0,
      paddingRight: 10,
      '& > div': {
        padding: '4px 0 4px 10px',
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }
    }
    return isMobileOrTablet ? menuStyle : { ...provided }
  }

  const selectOptionStyle = (provided, {isSelected, isFocused}) => {
    const avoidOverflow = {
      alignItems: 'center',
      overflow: 'hidden',
      wordBreak: 'break-all'
    }
    const optionStyle = {
      ...provided,
      color: isSelected ? '#EC681A' : 'black',
      backgroundColor: isFocused ? '#F2EEEC !important' : 'unset',
      padding: 10,
      borderBottom: '1px solid #CCC',
      display: 'block',
      ...avoidOverflow,
      ':active': {
        backgroundColor: '#F2EEEC !important'
      }
    }
    return isMobileOrTablet ? optionStyle : { ...provided, ...avoidOverflow }
  }

  const customStyles = {
    control: (base, state) => {
      const { hasValue, menuIsOpen } = state
      const { webStyle, mobileStyle } = hasValue ? 
      {
        webStyle: { ...base,
          background: '#EC681A',
          borderColor: 'white',
          color: 'white'
        },
        mobileStyle: {
          ...base,
          borderRadius: 0,
          borderStyle: 'none !important',
          borderBottom: menuIsOpen ? '1px solid #EC681A !important' : '1px solid #1A0707 !important',
          boxShadow: 'none !important',
          color: menuIsOpen ? '#EC681A' : '#1A0707'
        }
      }
      :
      {
        webStyle: {
          ...base,
          color: '#ccc',
          '&:hover': { background: '#EFEFEF' },
          '& > div': { height: 38 }
        },
        mobileStyle: {
          ...base,
          borderRadius: 0,
          borderStyle: 'none !important',
          borderBottom: '1px solid #ccc !important',
          boxShadow: 'none !important',
          color: '#ccc'
        }
      }

      return isMobileOrTablet ? mobileStyle : webStyle
    },
    container: (provided) => ( isMobileOrTablet ? { ...provided, position: 'unset' } : { ...provided } ),
    singleValue: (provided) => ({ ...provided, color: 'inherit' }),
    clearIndicator: (provided) => ({ ...provided, color: 'inherit' }),
    dropdownIndicator: (provided) => ({ ...provided, color: 'inherit' }),
    indicatorSeparator: (provided) => ( isMobileOrTablet ? { display: 'none' } : { ...provided } ),
    menu: selectMenuStyle,
    option: selectOptionStyle
  };

  const DropdownIndicator = props => {
    const { DropdownIndicator } = components
    return (
      <DropdownIndicator {...props}>
        { isMobileOrTablet && <ElementIcon name='caret-bottom'/> }
      </DropdownIndicator>
    )
  };

  const CustomComponents = loading => ({
    DropdownIndicator,
    NoOptionsMessage: () => (
        <div style={{ color: 'grey', textAlign: 'center' }}>
          <p>{loading ? 'Loading' : 'No options'}</p>
        </div>
    ),
  })
   // 商品スコア実装の為、メニュー出し分けのフラグを追加(boolean)survey_type=="1" =>商品スコアアカウント
   const isProductsScoreCompany = Storage.getSelectedCompany().survey_type==="1"
  return (
    <div className={classes.inputs}>
      <div className={classes.input}>
        <Select
            components={CustomComponents(loadingProjects)}
            isSearchable={true}
            label="Project"
            options={projects || []}
            styles={customStyles}
            placeholder="プロジェクトを選択"
            value={project}
            onChange={onProjectChange}
            onFocus={fetchAllProjectsSync}
            maxMenuHeight={200}
        />
      </div>
      <div className={classes.input}>
        <Select
            components={CustomComponents(loadingSurveys)}
            isSearchable={true}
            label="Survey"
            options={surveys || []}
            styles={customStyles}
            placeholder="アンケートを選択"
            value={survey}
            isClearable={survey}
            onChange={onSurveyChange}
            onFocus={fetchAllSurveysSync}
        />
      </div>
      <div className={classes.input}>
        <Select
            components={CustomComponents(loadingGroups)}
            isSearchable={true}
            label="Group"
            options={groups || []}
            styles={customStyles}
            placeholder="グループを選択"
            value={group}
            isClearable={group}
            onChange={onGroupChange}
            onFocus={fetchAllGroupsSync}
        />
      </div>
      <div className={classes.input}>
        <Select
            components={CustomComponents(loadingShops)}
            isSearchable={true}
            styles={customStyles}
            label="Shop"
            placeholder={isProductsScoreCompany?"商品を選択":"店舗を選択"}
            options={shops}
            value={shop}
            isClearable={shop}
            onChange={onShopChange}
            onFocus={() => fetchAllShopsSync(group.value)}
        />
      </div>
    </div>
  );
};

HeaderInputs.propTypes = propTypes;

export default connect(({ global }) => ({ global }))(HeaderInputs);
