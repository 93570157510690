import React from "react"
import PropTypes from "prop-types"
import VerificationDialog from "./VerificationDialog"

const propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.any,
  classes: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const UnSavedWarningDialog = props => {
  return (
    <VerificationDialog {...props} okText={'変更を保存せずに閉じる'} nokText={'いいえ、編集画面に戻る'} >
      <p>変更内容が保存されていません。</p>
      <p>保存せずに編集画面を閉じますか。</p>
    </VerificationDialog>
  )
}

UnSavedWarningDialog.propTypes = propTypes

export default UnSavedWarningDialog
