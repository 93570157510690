import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import CustomAppBar from 'common/CustomAppBar'

const useStyles = makeStyles(theme => ({
  header: {
    width: "100%",
    backgroundColor: "white",
    position: "fixed", // デフォルトはスマートフォン用
    top: 0,
    zIndex: 100,
    [theme.breakpoints.between("sm", "md")]: {
      backgroundColor: "#F7F7F7",
      position: "fixed", // スマートフォン用
    },
    [theme.breakpoints.up("md")]: {
      position: "sticky", // PC用
    },
  }
}));

const Header = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const updateSize = () => {
    const { innerWidth } = window;
    dispatch({ type: "UPDATE_WINDOW_SIZE", payload: { innerWidth } });
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  });

  const isFeedbackAnswerDetail = /\/admin\/feedbacks\/\d+\/answers/.test(pathname)

  return (
    <div className={classes.header}>
      {!isFeedbackAnswerDetail && <CustomAppBar />}
    </div>
  );
};

export default Header;
