import React from "react";
import clsx from "clsx";
import { makeStyles, Modal, Button } from "@material-ui/core";

const top = 50;
const left = 50;

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`
};

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    backgroundColor: "white",
    padding: "44px 20px",
    borderTop: "2px solid #EC681A"
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    padding: "0 10px 10px 10px",
    borderBottom: "1px solid #B7B7B7",
    marginBottom: 40
  },
  note: {
    textAlign: "center",
    fontWeight: "300",
    letterSpacing: "0.35px",
    color: "#1A0707"
  },
  buttonZone: {
    marginTop: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  deleteBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
  cancelBtn: {
    color: "white",
    backgroundColor: "#EC681A"
  },
  sizeBtn: {
    margin: "0 10px",
    width: 123
  }
}));

const DeleteItemModal = ({ open, onClose, onDelete, name, parentName }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle} className={classes.root}>
        <div className={classes.title}>削除確認</div>

        <div className={classes.note}>本当に削除しますか ?</div>
        <div className={classes.note}>
          {`${parentName}を削除した場合、下部${name}も共に削除されます。`}
        </div>

        <div className={classes.buttonZone}>
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.sizeBtn)}
            onClick={() => {
              onDelete && onDelete()
              onClose && onClose()
            }}
          >
            削除
          </Button>

          <Button
            variant="contained"
            onClick={onClose}
            className={clsx(classes.sizeBtn, classes.cancelBtn)}
          >
            キャンセル
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteItemModal;
