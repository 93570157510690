import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import qs from 'query-string';
import renderHTML from 'react-render-html';
import { themedStyles } from './styles';
import { useGAEffect } from '../../../../ga';
import { thunkSaveFeedback } from '../../redux/thunk';
import {
  QUESTION_TYPE_SELECT,
  QUESTION_TYPE_RADIO_BUTTON,
  QUESTION_TYPE_SELECT_BUTTON,
  QUESTION_TYPE_NPS_QUESTION,
  QUESTION_TYPE_SELECT_USER,
  QUESTION_TYPE_CHECK_BOX,
  QUESTION_TYPE_DATE,
  QUESTION_TYPE_FILE_UPLOAD,
} from 'modules/surveys/form/questions/constants';
import { PUBLIC_SAVE_FEEDBACK_SUCCESS } from 'modules/public/redux/actions';
import { htmlCss } from "../utils";
import SurveyFooter from "../SurveyPage/SurveyFooter";
import HtmlRender from '../components/HtmlRender';

const useStyles = makeStyles(themedStyles);

const cssCommon = `
  .textHeader {
    background-color: #EC681A;
    padding: 5px 0;
    font-size: 13px;
  }
`;

const cssSettingEndPage = `
  .textHeader {
    background-color: #EC681A;
    padding: 5px 0;
    font-size: 13px;
  }
`;

const propTypes = {
  survey: PropTypes.object.isRequired,
  doc: PropTypes.object,
  saveFeedback: PropTypes.func.isRequired,
  fakeSaveFeedback: PropTypes.func.isRequired,
  visitedPages: PropTypes.array.isRequired,
  companyUrlText: PropTypes.string.isRequired,
  shortUrl: PropTypes.string.isRequired,
  public_ga: PropTypes.object.isRequired,
  dataState: PropTypes.string.isRequired,
}

const EndPage = ({
  survey,
  doc,
  saveFeedback,
  fakeSaveFeedback,
  visitedPages,
  companyUrlText,
  shortUrl,
  public_ga,
  dataState,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes('/s/preview/');
  const paramData = useRef({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const url_params = window.location.search.indexOf('?') !== -1 ? window.location.search.split('?')[1] : '';
    const { area, shop, user, meta, autovalidate, ...params } = doc;

    const area_id = area.id;
    const shop_id = shop.id;
    let user_id = '';
    let user_ucode = '';
    let is_blank_user = false;

    if (!!user) {
      user_id = user.id;
      user_ucode = user.name;
      is_blank_user = user_id === "";
    }

    params.answers_attributes = mergeWithBlankAnswers();
    const q = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    const feedbackDoc = {
      companyUrlText,
      shortUrl,
      register_number: q.pos_id,
      url_params,
      ...params,
      area_id,
      shop_id,
      user_id,
      is_blank_user,
    };

    paramData.current = {
      shop_id: shop.uid,
      staff_id: user_ucode,
      pos_id: q.pos_id,
      purchase_date: q.purchase_date,
      deal_id: q.deal_id,
      customer_id: q.customer_id,
      custom01: q.p1,
      custom02: q.p2,
    };

    if (!isPreviewMode) {
      saveFeedback(feedbackDoc);
    } else {
      fakeSaveFeedback();
    }
    // eslint-disable-next-line
  }, [isPreviewMode]);

  useGAEffect(public_ga, "submit", "回答送信", null)

  const mergeWithBlankAnswers = () => {
    const answers_attributes = [];
    let answeredQuestionIds = [];

    visitedPages
      .filter(item => item !== "start" && item !== "end" && item !== "finish")
      .forEach(item => {

        const questionItems = ((survey || {}).survey_pages_attributes || [])[+item].question_items.filter(item => item.question_type !== "survey_heading");

        const questionIds = questionItems.map(item => +item.id);
        answeredQuestionIds = answeredQuestionIds.concat(questionIds);
      });

    survey.survey_pages_attributes
      .forEach(surveyPage => {
        surveyPage.question_items.filter(item => item.question_type !== "survey_heading").forEach(questionItem => {
          const answer_attributes = (doc.answers_attributes || []).filter(item => answeredQuestionIds.includes(+item.question_id))
          let answer = answer_attributes.find(item => +item.question_id === +questionItem.id);

          if (!!!answer) {
            if ([
              QUESTION_TYPE_SELECT,
              QUESTION_TYPE_RADIO_BUTTON,
              QUESTION_TYPE_SELECT_BUTTON,
              QUESTION_TYPE_NPS_QUESTION
            ].includes(questionItem.question_type)) {
              answer = {
                answer_entry: '',
                question_id: questionItem.id,
                answer_options_attributes: [],
              };
            } else if (questionItem.question_type === QUESTION_TYPE_SELECT_USER) {
              answer = {
                question_id: questionItem.id,
                user_id: null,
              };

            } else if (questionItem.question_type === QUESTION_TYPE_CHECK_BOX) {
              answer = {
                answer_entry: '',
                question_id: questionItem.id,
                answer_options_attributes: []
              };

            } else if (questionItem.question_type === QUESTION_TYPE_DATE) {
              answer = {
                answer_date: null,
                answer_entry: '',
                question_id: questionItem.id,
              };

            } else if (questionItem.question_type === QUESTION_TYPE_FILE_UPLOAD) {
              answer = {
                answer_entry: '',
                question_id: questionItem.id,
                answer_image: null,
              };

            } else {
              answer = {
                answer_entry: '',
                question_id: questionItem.id,
              };
            }
          }

          answers_attributes.push(answer);
        });
      });

    return answers_attributes;
  }

  const renderBody = () => {
    if (!survey.use_end_page_html) {
      return (
        <>
          <div>
            <pre className={classes.txtEndContent}>
            <HtmlRender
                value = {survey.end_page_main_text}
                isEndPage = {true}
            />
            </pre>
          </div>
          {renderHTML(htmlCss(survey.end_page_custom_css || cssSettingEndPage))}
        </>
      )
    } else {
      return (
        <>
          <HtmlRender
            value = {survey.end_page_html}
            isEndPage = {true}
          />
          {renderHTML(htmlCss(survey.end_page_custom_css || cssSettingEndPage))}
        </>
      );
    }
  }

  const { use_redirect } = survey;

  if (use_redirect === true && dataState === 'saved') {
    let { redirect_url } = survey;

    Object.keys(paramData.current).forEach(key => {
      redirect_url = redirect_url.replace('<%=' + key + '=%>', paramData.current[key] || '');
    });

    window.location = redirect_url;
    return null;
  }

  if (!!!use_redirect) {
    return (
      <div className={classes.root}>
        <div className={classes.contentTitle}>
          {renderHTML(survey.common_header)}
          {renderHTML(htmlCss(survey.common_css || cssCommon))}
        </div>

        {!survey.use_end_page_html && !!survey.end_page_main_image_url && (
          <div className={classes.imgStyle}>
            <img className={classes.img} src={survey.end_page_main_image_url} alt={""} />
          </div>
        )}

        <div className={classes.contentTitle}>
          <h2>{survey.page_title}</h2>
        </div>

        <div className={classes.content}>
          <h2 className="textEnd txtEndsubHeader">ありがとうございます。</h2>
          {renderBody()}
        </div>
        <SurveyFooter classes={classes} survey={survey} defaultCss={cssCommon}/>
      </div>
    );
  } else {
    return null;
  }
}

EndPage.propTypes = propTypes;

export default connect(({ public: { survey, dataState, visitedPages, doc }, public_ga }) => (
  {
    survey,
    dataState,
    public_ga,
    visitedPages,
    doc,
  }),
  (dispatch) => ({
    saveFeedback: ({ companyUrlText, shortUrl, ...params }) => {
      dispatch(thunkSaveFeedback({
        companyUrlText,
        shortUrl,
        params
      }));
    },
    fakeSaveFeedback: () => {
      dispatch({ type: PUBLIC_SAVE_FEEDBACK_SUCCESS })
    }
  })
)(EndPage);
