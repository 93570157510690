export const themedStyles = theme => ({
  root: {
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    border: "1px solid #B7B7B7",
    '& >*': {
      fontFamily: "'Noto Sans JP', sans-serif !important"
    }
  },
  input: {
    marginLeft: 10,
    flex: 1,
    font: "300 13px/16px 'Noto Sans JP', sans-serif",
    color: "#1A0707",
    letterSpacing: "0.25px",
    '& .MuiInputBase-input': {
      cursor: 'pointer'
    }
  },
  iconButton: {
    padding: 0,
    "& i": {
      color: "#1A0707",
      fontSize: 13,
      margin: 6
    }
  },
  divider: {
    height: 20,
    margin: "4px 0",
    background: "#1A0707"
  },
  rootAnswer: {
    backgroundColor: "#F7F7F7",
    fontSize: 13,
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100vh - 20px) !important',
    fontFamily: "'Noto Sans JP', sans-serif !important",
    MsOverflowStyle: 'none'
  },
  headerPaper: {
    position: "fixed",
    top: 0,
    zIndex: 10,
    width: "100%",
    minHeight: "auto",
    padding: "10px 24px",
    display: "flex",
    justifyContent: "center"
  },
  footer: {
    position: "fixed",
    bottom: 0,
    zIndex: 10,
    width: "100%",
    minHeight: "auto",
    padding: "10px 24px",
    display: "flex",
    justifyContent: "center"
  },
  headerContent: {
    width: "559px",
    wordBreak: "break-all",
  },
  textBox: {
    backgroundColor: 'white',
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  pageTitle: {
    minHeight: 470,
    backgroundColor: 'white', 
  },
  text: {
    width: 559,
    marginLeft: '30px',
    marginRight: '30px',
    font: "600 10px/20px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.25px",
    color: "#1A0707",
    maxWidth: 559,
    "& >div >pre": {
      font: "600 10px/20px 'Noto Sans JP', sans-serif"
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
      padding: "10px 1px"
    }
  },
  preText: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      padding: "0px 24px"
    }
  },
  bnAnswer: {
    fontFamily: "'Noto Sans JP', sans-serif",
    border: '1px solid #EC681A',
    borderRadius: '5px',
    backgroundColor: '#EC681A',
    color: "#FFFFFF",
    padding: "10px 30px 10px 30px",
    font: "500 12px/18px 'Noto Sans JP', sans-serif",
    width: 300,
    '& > span > div': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textDecoration: 'none',
    }
  },
  selectAnswer: {
    width: '300px',
    margin: 'auto',
    padding: '0 0 20px'
  },
  ul: {
    "& >div": {
      font: "300 10px/16px 'Noto Sans JP', sans-serif",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      borderBottom: "0.6000000238418579px solid #C2C2C2",
      padding: "14px 5px",
      "&:hover": {
        font: "300 10px/16px 'Noto Sans JP', sans-serif",
        color: "#1A0707 !important",
        background: "#fdf0e8 0% 0% no-repeat padding-box",
        cursor: "pointer"
      }
    },
  },
  slAnswer: {
    background: "#F7F7F7",
    padding: "30px 0 20px",
    [theme.breakpoints.down('sm')]: {
      margin: 'auto'
    },
    "& .basic-single": {
      "& >div": {
        "& >.select__indicators": {
          "& >span": {
            background: "#1A0707"
          },
          "& >div": {
            "& .el-icon-caret-bottom": {
              color: "#1A0707"
            }
          }
        },
        "& >.select__menu-list": {
          borderTop: "2px solid #EC681A"
        }
      }
    }
  },
  centerPage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  slAnswerEmptyTitle: {
    marginTop: "25%",
    transform: "translateY(-50%)"
  },
  button: {
    marginBottom: '100px',
    padding: '50px 0 50px',
    textAlign: 'center',
    '& :hover': {
      backgroundColor: '#EC681A',
    }
  },
  img: {
    maxWidth: "559px",
    height: 'auto',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  },
  imgStyle: {
    textAlign: 'center'
  },
  textBegin: {
    fontSize: '14px',
    font: "bold 14px/24px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.35px",
    color: "#1A0707",
    wordBreak: "break-word",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 24px"
    }
  },
  textBegin1: {
    fontSize: '11px',
    paddingBottom: "10px",
    whiteSpace: "pre-line",
  },
  textBegin2: {
    fontSize: 11,
    paddingBottom: "5px",
  },
  txtLabel: {
    fontFamily: "'Noto Sans JP', sans-serif",
    fontSize: 12,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  selectTree: {
    width: 'auto',
    marginRight: 10,
    paddingTop: 6
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      borderRadius: 0,
      width: 295,
      maxHeight: 500
    },
    "& .MuiDialogTitle-root": {
      padding: "20px 10px 7px 10px"
    },
    "& .MuiDialogContent-root": {
      padding: "0 15px 40px 15px",
      cursor: 'pointer'
    }
  },
  titleDialog: {
    font: "600 13px/21px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.25px",
    color: "#EC681A",
    borderBottom: "solid 1px #EC681A",
    margin: 0,
    padding: "10px 15px",
  },
  optionItem: {
    font: "400 13px/16px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.25px",
    color: "#1A0707",
    borderBottom: "0.6000000238418579px solid #C2C2C2",
    margin: 0,
    padding: "11px 2px",
    '&:hover': {
      background: "#F2EEEC 0% 0% no-repeat padding-box"
    }
  },
  productOptionItem: {
    font: "400 13px/16px 'Noto Sans JP', sans-serif",
    letterSpacing: "0.25px",
    color: "#1A0707",
    borderBottom: "0.6000000238418579px solid #C2C2C2",
    margin: 0,
    padding: "11px 2px",
    '&:hover': {
      background: "#F2EEEC 0% 0% no-repeat padding-box"
    },
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center',
    "& >img" : {
      width: '100%'
    }
  },
  activedItem: {
    color: "#EC681A"
  },
  activedProductItem: {
    "& >div" : {
      color: "#EC681A"
    }
  },
});

export const customSelectStyles = {
  control: (base, state) => ({
    ...base,
    outline: state.isFocused ? "none" : null,
    boxShadow: 'none',
    border: "1px solid #B7B7B7",
    '&:hover': {
      border: "1px solid #B7B7B7",
      outline: "none",
    },
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "#fff" : "#fff",
  })
};
