export const themeUserSelectBarStyles = theme => ({
  '@global': {
    '.MuiAutocomplete-option[data-focus="true"]': {
        background: 'white'
    },
    '.MuiAutocomplete-paper': {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 5px #B7B7B799",
      border: "1px solid #B7B7B7",
      minWidth: 294
    },
    '.MuiAutocomplete-option': {
      flexDirection: "column",
      padding: "0 16px"
    }
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    flex: "0 0 100%",
    "& >.MuiAutocomplete-option": {
      padding: 0
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 4px",
      marginBottom: 20
    }
  },
  iconButton: {
    padding: 6,
    "&:hover": {
      background: "none"
    }
  },
  filterIcon: {
    width: '16px !important'
  },
  divider: {
    height: 47,
    margin: "0 0 0 4px",
    color: "#f7f7f7",
    background: "#f7f7f7",
    [theme.breakpoints.down("xs")]: {
      height: 52,
      margin: "0 0 0 4px"
    }
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      marginLeft: 0
    }
  },
  searchbox: {
    minWidth: 171,
    width: '100%',
    "& .MuiOutlinedInput-root": {
      padding: "0",
      border: "1px solid #B7B7B7",
      borderRadius: "5px",
      minHeight: "32px",
      lineHeight: "1",
      fontSize: "10px",
      color: "#B7B7B7",
      "& i": {
        fontSize: "16px",
        marginRight: "10px"
      },
    },
    "& .MuiInputBase-root":{
      overflow:"auto",
      maxHeight:"30px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiAutocomplete-tag": {
      background: "#9A877D 0% 0% no-repeat padding-box",
      border: "1px solid #9A877D",
      borderRadius: "10px !important",
      height: "20px !important",
      minHeight: "20px !important",
      font: "600 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#FFFFFF !important",
      margin: "6px -5px 6px 10px",
      "& i": {
        color: "#FFFFFF",
        fontSize: "10px !important",
        width: 10,
        height: 10,
        marginLeft: 2
      }
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
      paddingRight: 39
    },
    "& .MuiAutocomplete-popupIndicatorOpen": {
      transform: 'rotate(0deg)'
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      color: "#1A0707"
    },
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "100%",
      justifyContent: "center",
      display: "inline-block",
      maxWidth: 600,
      "& div": {
        "& div": {
          "& div:nth-child(1)": {
            "& input": {
              font: "10px/21px Noto Sans JP, Hiragino Sans",
              letterSpacing: "0.25px",
              color: "#B7B7B7",
              opacity: 1
            }
          }
        }
      }
    },
    "& > * + *": {
      marginTop: theme.spacing(3)
    }
  },
  headerSearch: {
    padding: "10px",
    "& button": {
      "& span": {
        "& svg": {
          width: "16px",
          height: "16px"
        }
      }
    }
  },
  searchIcon: {
    color: "#B7B7B7"
  },
  optionItemContainer: {
    width: "calc(100% + 32px)",
    padding: "0 16px",
    "&:hover": {
      background: "white"
    },
    "& >.MuiAutocomplete-option[data-focus=\"true\"]": {
      background: "white !important"
    }
  },
  disableOption: {
    pointerEvents: "none"
  },
  optionItem: {
    borderBottom: "0.6000000238418579px solid #B7B7B7",
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    padding: "6px 0",
    minHeight: 48,
    "&:hover": {
      background: "#F2EEEC 0% 0% no-repeat padding-box"
    }
  },
  optionContentItem: {
    width: "100%",
  },
  optionLabel: {
    font: "300 10px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.25px",
    color: "#1A0707",
    margin: 0,
    marginBottom: 4
  },
  activeLabel: {
    color: "#EC681A"
  },
  optionEmail: {
    font: "300 8px/16px Noto Sans JP, Hiragino Sans",
    letterSpacing: "0.2px",
    color: "#796255",
    margin: 0
  },
  optionChecked: {
    width: 16,
    height: 16,
    color: "#EC681A"
  },
  selectedActived: {
    "& .MuiOutlinedInput-root": {
      border: "1px solid #EC681A"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
  },
  selectOption: {
    position: 'relative',
    height: 38,
    width: '102%',
    borderBottom: '1px solid #B7B7B7',
    display: 'flex',
    alignItems: 'center',
    
    '& > p': {
      fontSize: 8,
      letterSpacing: 0.2,
      color: '#796255',
      margin: 0,
      width: '90%',
      textAlign: 'left',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '& > strong.selected': {
        color: '#EC681A'
      },
      
      '& > strong': {
        fontSize: 10,
        letterSpacing: 0.25,
        color: '#1A0707',
        margin: 0
      }
    },

    '& > i': {
      color: '#EC681A'
    }
  },
  customList: {
    width: '100%',
    height: '38px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    borderBottom: '1px solid #B7B7B7',
    margin: '0 4px',
    
    '& > p': {
      fontSize: '10px',
      letterSpacing: '0.25px',
      color: '#939393',
      margin: 0
    }
  }
});
export const UserListStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: "0 20px",
  },
  flex:{
    display: 'flex'
  },
  loading: {
    width: "200px",
    height: "200px",
    margin: "0 auto",
    paddingTop: "75px",
  },
  table: {
    minWidth: 650,
    "& td": {
      borderRight: "1px solid #ddd7d77a",
      padding: "0 16px",
      maxWidth: "200px",
      whiteSpace: 'nowrap',
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "& th": {
      wordBreak: "break-word"
    },
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    backgroundColor: "rgb(247, 247, 247)"
  },
  headerTable: {
    background: "#F2EEEC 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000029",
    borderRadius: "5px 5px 0px 0px",
    "& th": {
      borderRight: "2px solid #d2d1d0",
      padding: "12px"
    }
  },
  bodyCheck: {
    backgroundColor: "#EC681A",
    borderRadius: " 5px 0px 0px 5px"
  },
  bodyClose: {
    backgroundColor: "#B7B7B7",
    borderRadius: " 5px 0px 0px 5px"
  },
  check: {
    width: "64px"
  },
  is_active: {
    width: 64
  },
  ucode: {
    width: "11%",
    minWidth: 124,
    borderRight: "1px solid #ddd7d77a",
    maxWidth: 132,
    whiteSpace: 'nowrap',
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  login_id: {
    width: "16.7%",
  },
  name: {
    width: "16.9%"
  },
  shop_names: {
    width: "auto",
    maxWidth: "100px !important"
  },
  authority: {
    width: "12%",
    maxWidth: "144px !important"
  },
  viewing_target_names: {
    width: "16.9%"
  },
  is_personal_info_viewable: {
    width: "auto",
    minWidth: "102px",
    borderRight: "none !important",
    maxWidth: "144px !important"
  },
  bg_color_white: {
    backgroundColor: "white"
  },
  color_white: {
    color: "white"
  },
  bg_color_green: {
    backgroundColor: "#1FA371"
  },
  bg_color_red: {
    backgroundColor: "#CB4944"
  },
  btnCheck: {
    width: "auto",
    height: "21px",
    padding: "2px 11px",
    borderRadius: "10px",
    font: "10px/18px Noto Sans JP, Hiragino Kaku Gothic Pro",
    color: "white",
    marginRight: "8px",
    whiteSpace: "nowrap"
  },
  rows: {
    minWidth: 1130
  }
});

export const usersThemedStyles = theme => ({
  toolsContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  buttonShowAc: {
    background: "#fff",
    padding: "0x",
    margin: "20px 0",
    width: "100%",
    height: 52,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "& >.blockSearch": {
      height: 52,
      display: "inline-flex",
      "& >.icon": {
        height: 52,
        width: 52,
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        borderRight: "2px solid #F7F7F7",
        "& >i": {
          color: "#B7B7B7"
        }
      },
      "& >.formSearch": {
        display: "flex",
        alignItems: "center",
        padding: "0 10px",
        "& >div": {
          display: "inline-block",
          "& >div": {
            verticalAlign: "middle",
            "& >div": {
              "& >input": {
                padding: "10px 15px",
                textAlign: "left",
                font: "500 14px/21px Noto Sans JP, Hiragino Sans",
                letterSpacing: "0.25px",
                border: "1px solid #939393",
                borderRadius: "5px",
                color: "#1A0707",
                opacity: 1
              }
            }
          }
        },
        "& >.formIcon": {
          border: "1px solid #939393",
          height: 37,
          width: 37,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: "5px",
          marginLeft: 10,
          cursor: "pointer",
          "& >i": {
            fontSize: "16px"
          },
          "&:hover": {
            background: "#EC681A 0% 0% no-repeat padding-box",
            border: "solid 1px #EC681A",
            "& >i": {
              color: "white"
            },
          }
        }
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        "& >.icon": {
          padding: "10px"
        },
        "& >.formSearch": {
          padding: "5px",
          display: "flex",
          "& >div": {
            display: "inline-block",
            "& >div": {
              "& >div": {
                "& >input": {
                  padding: "10px 5px",
                  textAlign: "left",
                  font: "500 14px/21px Noto Sans JP, Hiragino Sans",
                  letterSpacing: "0.25px",
                  border: "1px solid #939393",
                  borderRadius: "5px",
                  color: "#B7B7B7",
                  opacity: 1
                }
              }
            }
          },
          "& >.formIcon": {
            display: "inline-block",
            border: "1px solid #939393",
            borderRadius: "5px",
            padding: "8px",
            margin: 0,
            lineHeight: 0,
            position: "relative",
            top: "0 !important",
            left: "5px",
            cursor: "pointer",
            "& >i": {
              fontSize: "16px"
            },
            "&:hover": {
              background: "#EC681A 0% 0% no-repeat padding-box",
              border: "solid 1px #EC681A",
              "& >i": {
                color: "white"
              },
            }
          }
        }
      }
    },
    "& >.buttonShowStore": {
      alignItems: "center",
      textAlign: "right",
      width: "auto",
      padding: "8px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      font: "500 10px/16px Noto Sans JP, Hiragino Sans",
      letterSpacing: "0.25px",
      color: "#1A0707",
      opacity: 1,
      display: "inline-block",
      float: "right",
      marginRight: "15px",
      background: "#EFEFEF 0% 0% no-repeat padding-box",
      border: "1px solid #B7B7B7",
      "&:hover": {
        width: "auto",
        border: "1px solid #EC681A",
        borderRadius: "5px",
        cursor: "pointer",
        color: "#fff",
        background:"#EC681A"
      },
      "& >div": {
        width: "16px",
        height: "16px",
        border: "1px solid #B7B7B7",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        marginRight: "15px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        Border: "1px solid #B7B7B7",
        opacity: 1,
        "& >div": {
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center"
        }
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        float: "right",
        margin: "0px 15px",
        textAlign: "center",
        padding: "8px 10px",
        "& >div": {
          width: "16px",
          height: "16px",
          border: "1px solid #000",
          borderRadius: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "inline-flex",
          marginRight: "15px",
          "& >div": {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            width: "8px",
            height: "8px",
            borderRadius: "100%",
            display: "inline-block",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            alignSelf: "center"
          }
        }
      }
    },
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      textAlign: "right",
      margin: "20px 0",
      display: "flex",
      padding: 0,
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0",
      padding: 0,
      background: "#fff",
      textAlign: "right",
      display: "flex",
      alignItems: "center"
    }
  },
  buttonShowAcActive: {
    "& >.buttonShowStore": {
      background: "#fff 0% 0% no-repeat padding-box",
      "& >div": {
        border: "1px solid #000",
        "& >div": {
          background: "#EC681A 0% 0% no-repeat padding-box",
          [theme.breakpoints.down("xs")]: {
            background: "#EC681A 0% 0% no-repeat padding-box",
          }
        },
        },
      "&:hover": {
        color: "#EC681A !important",
        border: "1px solid #EC681A !important",
        background:"#F2EEEC"
      },
    }
  },
  blockTotal: {
    padding: "0 40px",
    display: "block",
    width: '100%',
    "& >.txtTotal": {
      display: "inline-block",
      "& >p": {
        textAlign: "left",
        font: "600 16px/29px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.8px",
        color: "#1A0707",
        opacity: 1
      }
    },
    "& >.buttons": {
      display: "inline-block",
      float: "right",
      margin: "10px 0",
      "& > button": {
        font: "500 10px/16px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.25px",
        color: "#1A0707",
        borderRadius: "18px",
        opacity: 1,
        padding: "10px 15px",
        marginLeft: "10px",
        "& >span": {
          "& >i": {
            color: "#B7B7B7",
            fontSize: "16px",
            marginLeft: "15px"
          }
        },
      },
      [theme.breakpoints.down("xs")]: {
        "& > button": {
          font: "500 8px/10px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.25px",
          color: "#1A0707",
          border: "1px solid #B7B7B7",
          borderRadius: "18px",
          opacity: 1,
          padding: "8px 5px",
          marginLeft: "10px",
          "& >span": {
            "& >i": {
              color: "#B7B7B7",
              fontSize: "16px",
              marginLeft: "5px !important"
            }
          }
        }
      }
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px"
    },
    [theme.breakpoints.down("xs")]: {
      "& >.txtTotal": {
        display: "inline-block",
        "& >p": {
          textAlign: "left",
          font: "600 13px/23px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.8px",
          color: "#1A0707",
          opacity: 1
        }
      }
    }
  },
  filterIcon: {
    height: 16,
    cursor: "pointer"
  },
  textBox: {
    '& input': {
      padding: '0 15px !important',
      height: 35,

      '&:-ms-input-placeholder': {
        color: '#9f9797 !important',
      },

      '&::-ms-clear': {
        display: 'none',
      },

      '&:hover': {
        border: '1px solid #1A0707 !important'
      },

      '&:focus': {
        border: '2px solid #EC681A !important'
      },
    },

    '& input[value]:not([value=""])': {
      border: '2px solid #EC681A !important',
    },

    '& input ~ fieldset': {
      display: 'none'
    }
  }
});

export const customSelectorStyles = (isMobileOrTablet) => ({
  multiValue: (styles) => {
    return {
      ...styles,
      background: "#9A877D",
      borderRadius: "10px",
      opacity: 1,
      display: "flex",
      alignItems: "center",
      padding: "0px 10px",
      marginRight: "5px",
      color: "#fff",
      "& :hover": {
        backgroundColor: "#9A877D !important",
      },
      "& > div": {
        font: "600 10px/16px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.25px",
        color: "#FFFFFF",
        opacity: 1,
        "& > div":{
          "& > div":{
            "& > .text":{
              "& > p:nth-of-type(1)":{
                margin: 0,
                color: "#fff"
              },
              "& > p:nth-of-type(2)":{
                display: "none"
              },
            },
            "& svg":{
              display: "none"
            }
          },
          "& > svg": {
            fontSize: "15px",
            color: "#fff"
          }
        }
      },
    }
  },
  option: styles => ({
    ...styles,
    padding: '0 16px',
    backgroundColor: '#fff !important',

    '& :hover': {
      backgroundColor: '#f2eeec !important',
      cursor: 'pointer'
    }
  }),
  menuList: styles => ({
    ...styles,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      webkitAppearance: 'none',
      display: isMobileOrTablet ? 'none' : 'normal',
      width: "2px",
      background: "#F2EEEC 0% 0% no-repeat padding-box",
      borderRadius: "1px",
      opacity: 1,
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: "1px",
      background: "#B7B7B7 0% 0% no-repeat padding-box",
      opacity: 1,
    }
  }),
  menu: styles => ({
    ...styles,
    width: '100%',
    zIndex: 1100,
    padding: "10px 10px 10px 0"
  })
})
