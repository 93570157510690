export const themeCompaniesStyles = theme => ({
  blockTotal: {
    padding: "0 40px",
    display: "block",
    width: '100%',
    "& >.txtTotal": {
      display: "inline-block",
      "& >p": {
        textAlign: "left",
        font: "600 16px/29px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.8px",
        color: "#1A0707",
        opacity: 1
      }
    },
    "& >.buttons": {
      display: "inline-block",
      float: "right",
      margin: "10px 0",
      "& > button": {
        font: "500 10px/16px Noto Sans JP, Hiragino Sans",
        letterSpacing: "0.25px",
        color: "#1A0707",
        borderRadius: "18px",
        opacity: 1,
        padding: "10px 15px",
        marginLeft: "10px",
        "& >span": {
          "& >i": {
            color: "#B7B7B7",
            fontSize: "16px",
            marginLeft: "15px"
          }
        },
      },
      [theme.breakpoints.down("xs")]: {
        "& > button": {
          font: "500 8px/10px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.25px",
          color: "#1A0707",
          border: "1px solid #B7B7B7",
          borderRadius: "18px",
          opacity: 1,
          padding: "8px 5px",
          marginLeft: "10px",
          "& >span": {
            "& >i": {
              color: "#B7B7B7",
              fontSize: "16px",
              marginLeft: "5px !important"
            }
          }
        }
      }
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px"
    },
    [theme.breakpoints.down("xs")]: {
      "& >.txtTotal": {
        display: "inline-block",
        "& >p": {
          textAlign: "left",
          font: "600 13px/23px Noto Sans JP, Hiragino Sans",
          letterSpacing: "0.8px",
          color: "#1A0707",
          opacity: 1
        }
      }
    }
  },
	txtTotal :{
		font: "600 22px/29px Noto Sans JP, Hiragino Sans",
		letterSpacing: "1.1px",
		color: "#1A0707",
		opacity: 1
	  },
	  buttonShowAc: {
		height: 52,
		background: "#fff",
		padding: "0x",
		margin: "20px 0",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width: "100%",
		"& >.blockSearch": {
		  display: "inline-flex",
		  "& >.icon": {
			borderRight: "2px solid #F7F7F7",
			padding: "18px",
			"& >i": {
			  color: "#B7B7B7"
			}
		  },
		  "& >.formSearch": {
			display: "inline-block",
			verticalAlign: "middle",
			alignItems: "center",
			padding: "10px",
			lineHeight: "0",
			"& >div": {
			  display: "inline-block",
			  "& >div": {
				verticalAlign: "middle",
				"& >div": {
				  "& >input": {
					padding: "10px 15px",
					textAlign: "left",
					font: "500 10px/21px Noto Sans JP, Hiragino Sans",
					letterSpacing: "0.25px",
					border: "1px solid #939393",
					borderRadius: "5px",
					color: "#B7B7B7",
					opacity: 1
				  }
				}
			  }
			},
			"& >.formIcon": {
			  display: "inline-block",
			  border: "1px solid #939393",
			  borderRadius: "5px",
			  padding: "8px",
			  margin: 0,
			  lineHeight: 0,
			  position: "relative",
			  top: "4px",
			  left: "5px",
			  "& >i": {
				fontSize: "16px"
			  }
			}
		  },
		  [theme.breakpoints.down("xs")]: {
			display: "flex",
			"& >.icon": {
			  padding: "10px"
			},
			"& >.formSearch": {
			  padding: "5px",
			  display: "flex",
			  "& >div": {
				display: "inline-block",
				"& >div": {
				  "& >div": {
					"& >input": {
					  padding: "10px 5px",
					  textAlign: "left",
					  font: "500 10px/21px Noto Sans JP, Hiragino Sans",
					  letterSpacing: "0.25px",
					  border: "1px solid #939393",
					  borderRadius: "5px",
					  color: "#B7B7B7",
					  opacity: 1
					}
				  }
				}
			  },
			  "& >.formIcon": {
				display: "inline-block",
				border: "1px solid #939393",
				borderRadius: "5px",
				padding: "8px",
				margin: 0,
				lineHeight: 0,
				position: "relative",
				top: "0 !important",
				left: "5px",
				"& >i": {
				  fontSize: "16px"
				}
			  }
			}
		  }
		},
		"& >.buttonShowStore": {
		  alignItems: "center",
		  textAlign: "right",
		  width: "auto",
		  padding: "10px 20px",
		  border: "1px solid #B7B7B7",
		  borderRadius: "5px",
		  cursor: "pointer",
		  font: "500 10px/16px Noto Sans JP, Hiragino Sans",
		  letterSpacing: "0.25px",
		  color: "#1A0707",
		  opacity: 1,
		  display: "inline-block",
		  float: "right",
		  marginRight: "15px",
		  background: "#EFEFEF 0% 0% no-repeat padding-box",
		  "& >div": {
			width: "16px",
			height: "16px",
			background: "#FFFFFF 0% 0% no-repeat padding-box",
			border: "1px solid #B7B7B7",
			borderRadius: "100%",
			justifyContent: "center",
			alignItems: "center",
			display: "inline-flex",
			marginRight: "15px",
			"& >div": {
			  width: "8px",
			  height: "8px",
			  borderRadius: "100%",
			  display: "inline-block",
			  justifyContent: "center",
			  alignItems: "center",
			  textAlign: "center",
			  alignSelf: "center"
			}
		  },
			"&:hover": {
				width: "auto",
				border: "1px solid #EC681A",
				borderRadius: "5px",
				cursor: "pointer",
				color: "#fff",
				background:"#EC681A",
			},
		  [theme.breakpoints.down("xs")]: {
			display: "flex",
			float: "right",
			marginLeft: "20px",
			padding: "8px 10px",
			"& >div": {
			  width: "16px",
			  height: "16px",
			  border: "1px solid #B7B7B7",
			  borderRadius: "100%",
			  justifyContent: "center",
			  alignItems: "center",
			  display: "inline-flex",
			  marginRight: "15px",
			  "& >div": {
				background: "#FFFFFF 0% 0% no-repeat padding-box",
				width: "8px",
				height: "8px",
				borderRadius: "100%",
				display: "inline-block",
				justifyContent: "center",
				alignItems: "center",
				textAlign: "center",
				alignSelf: "center"
			  }
			}
		  }
		},
		[theme.breakpoints.down("sm")]: {
		  background: "#fff",
		  textAlign: "right",
		  margin: "20px 0",
		  display: "flex",
		  padding: 0,
		  justifyContent: "space-between",
		},
		[theme.breakpoints.down("xs")]: {
		  margin: "20px 0",
		  padding: 0,
		  background: "#fff",
		  textAlign: "right",
		  display: "flex",
		  alignItems: "center"
		},
	  },
	  buttonShowAcActive: {
		"& >.buttonShowStore": {
		  border: "1px solid #1A0707",
		  background: "#FFFFFF 0% 0% no-repeat padding-box",
		  "&:hover": {
			border: "1px solid #E3782A",
			background: "#F2EEEC 0% 0% no-repeat padding-box",
			color: "#EC681A",
		  },
		  "& >div": {
			border: "1px solid #1A0707",
			"& >div": {
			  background: "#EC681A 0% 0% no-repeat padding-box",
			}
		  }
		}
	  },
	  blockList:{
		padding: "0 40px",
		width: "100%"
	  }
	});
