import React, {useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

const UnPinMessage = () => (
  <DialogContentText>
    <span>このフィードバックのピン留めを解除してよろしいでしょうか。</span>
    <br />
    <span>ピン留めが解除されたフィードバックは閲覧権限の無いスタッフには表示されなくなります</span>
  </DialogContentText>
)

const PinMessage = () => (
  <DialogContentText>
    <span>このフィードバックにピン留めを設定してよろしいでしょうか。</span>
    <br />
    <span>
      ピン留めが設定されたフィードバックはダッシュボードのVOC項目にて全てのスタッフに表示されます。
    </span>
  </DialogContentText>
)

const PinDialog = ({ pinned, className, onSubmit, children }) => {
  const [show, setShow] = useState(false)

  const onClick = e => {
    e.stopPropagation()
    setShow(prevState => !prevState)
  }

  const onOk = () => {
    onSubmit && onSubmit()
  }

  return (
    <div className={className} onClick={onClick}>
      {children}
      <Dialog open={show}>
        <DialogContent>{pinned ? <UnPinMessage /> : <PinMessage />}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onOk} color="secondary">
            OK
          </Button>
          <Button variant="outlined" onClick={onClick}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PinDialog
